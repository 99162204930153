import moment from "moment";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../images/svglist";
import { customDataTableStyles, DATE_FORMAT_MOMENT, defaultPageLimit, defaultRowsPerPageOptions } from "../Helper/Constants";
import { CallApiGet } from "../Helper/serviceApi";
import { Tooltip } from "react-tooltip";

const DiscountList = (props) => {
  const { discountRows, getDiscountDetails } = props;

  const customValidFromDateSort = (rowA, rowB) => {
    const dtA = rowA.validFrom
    const dtB = rowB.validFrom
    return moment(dtA).diff(moment(dtB))
  }
  const customValidToDateSort = (rowA, rowB) => {
    const dtA = rowA.validTo
    const dtB = rowB.validTo
    return moment(dtA).diff(moment(dtB))
  }
  const columns = [
    {
      name: "Discount Name",
      selector: (row) => row.discountName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Discount Type",
      selector: (row) => row.discountTypeValue,
      sortable: true,
      filterable: true,
    },
    {
      name: "Valid From",
      selector: (row) => moment(row.validFrom).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortFunction: customValidFromDateSort
    },
    {
      name: "Valid to",
      selector: (row) => moment(row.validTo).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortFunction: customValidToDateSort
    },
    {
      name: "Action",
      cell: (row) => (
        <>
        <div
          className="me-3"
          data-bs-toggle="modal"
          data-bs-target="#edit-button-modal"
          data-tooltip-id={"edit-" + row.id}
          onClick={() => getDiscountDetails(row)}
        >
          {GetSvgIcon("EditPencil")}
        </div>
        <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  
  return (
    <div>
      <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={discountRows}

          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          defaultSortAsc={false}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </div>
  );
};

export default DiscountList;

import React from 'react'
import GetSvgIcon from '../../../images/svglist'
import moment from 'moment'
import { DATE_FORMAT_MOMENT } from '../../Helper/Constants'

const DateAvlSpaceSlider = (props) => {
    const { handler, allSla, midSla, selectedPallets, isLeftClick, handleLeftClick, isRightClick, handleRightClick, errorMsg, selectedShipDate } = props

    const sliderBox = (item) => {
        const isHoliday = item.holiday
        const isOptHoliday = item.holidayFee > 0
        const price = isOptHoliday ? item.shipmentPrice : item.finalUnitPrice

        // const spaceClassName = isHoliday
        //     ? "mt-1 text-center invisible"
        //     : "mt-1 text-center"
        const spaceData = isHoliday
            ? (
                <div className="m-auto align-center font-12 mt-1 fw-normal holiday-card">{item?.holidayName}</div>
            )
            : (
                <>
                    Avl. Pallet Space:
                    <span className="fw-bold ms-1 font-18">
                        {item.freeSpace}
                    </span>
                </>
            )

        const priceClassName = isOptHoliday
            ? "mt-1 fw-bold text-center font-16"
            : "mt-1 fw-bold text-center font-18"
        return (
            <>
                <div className="text-center fw-bold">
                    {moment(item.shipOn).format(DATE_FORMAT_MOMENT)}
                </div>
                <div className={priceClassName}>
                    {
                        isHoliday
                            ? <> HOLIDAY</>
                            : <>
                                <span className='font-12 fw-normal'>from</span>{" RM " + price}
                                <span className="font-12 fw-normal">/plt</span>
                            </>
                    }
                    <br />
                    {
                        isOptHoliday &&
                        <>
                            Holiday Surcharge:
                            <span className="fw-bold ms-1 font-12">
                                RM{item.holidayFee}
                            </span>
                        </>
                    }
                </div>
                <div className="mt-1 text-center">
                    {/* Avl. Pallet Space:
                    <span className="fw-bold ms-1 font-18">
                        {item.freeSpace}
                    </span> */}
                    {spaceData}
                </div>
            </>
        )
    }

    return (
        <>
            {allSla?.sevenDayPrice?.length > 0 ? (
                <div className="justify-content-between d-flex m-3 position-relative">
                    <div
                        className={
                            !isLeftClick
                                ? "cursor-pointer position-absolute top-50 start-0 translate-middle previous-slide-date shadow h-100 d-flex px-1 rounded-start disabled disabled"
                                : "cursor-pointer position-absolute top-50 start-0 translate-middle previous-slide-date shadow h-100 d-flex px-1 rounded-start"
                        }
                        onClick={handleLeftClick}
                    >
                        <span className="align-self-center theme-violet-txt">
                            {GetSvgIcon("caretLeft")}
                        </span>
                    </div>
                    <div
                        className={
                            allSla.sevenDayPrice[0].holiday === true
                                ? "font-12 border align-self-center p-3 col rounded-start inactive-date"
                                : allSla.sevenDayPrice[0].holidayFee > 0
                                    ? "font-12 border align-self-center col cursor-pointer optional-date rounded-start"
                                    : allSla.sevenDayPrice[0].freeSpace < selectedPallets
                                        ? "font-12 border align-self-center p-3 col rounded-start inactive-date"
                                        : selectedShipDate === allSla.sevenDayPrice[0].shipOn
                                            ? "cursor-pointer font-12 border align-self-center p-3 col theme-blue rounded-start"
                                            : "font-12 border align-self-center p-3 col cursor-pointer theme-violet rounded-start"
                        }
                        onClick={() => handler(allSla.sevenDayPrice[0])}
                    >
                        {sliderBox(allSla.sevenDayPrice[0])}
                    </div>

                    {midSla.map((item) => (
                        <div
                            className={
                                item.holiday === true
                                    ? "font-12 border align-self-center p-3 col inactive-date"
                                    : item.holidayFee > 0
                                        ? "font-12 border align-self-center col cursor-pointer optional-date"
                                        : item.freeSpace < selectedPallets
                                            ? "font-12 border align-self-center p-3 col inactive-date"
                                            : selectedShipDate === item.shipOn
                                                ? "cursor-pointer font-12 border align-self-center p-3 col theme-blue"
                                                : "font-12 border align-self-center p-3 col cursor-pointer theme-violet"
                            }
                            onClick={() => handler(item)}
                        >
                            {sliderBox(item)}
                        </div>
                    ))}

                    {allSla.sevenDayPrice.length > 1 && (
                        <div
                            className={
                                allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1]
                                    .holiday === true
                                    ? "font-12 border align-self-center p-3 col inactive-date "
                                    : allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1].holidayFee > 0
                                        ? "font-12 border align-self-center col cursor-pointer optional-date rounded-end"
                                        : allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1]
                                            .freeSpace < selectedPallets
                                            ? "font-12 border align-self-center p-3 col inactive-date "
                                            : selectedShipDate ===
                                                allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1].shipOn
                                                ? "cursor-pointer font-12 border align-self-center p-3 col theme-blue rounded-end"
                                                : "font-12 border align-self-center p-3 col cursor-pointer theme-violet rounded-end"
                            }
                            onClick={() =>
                                handler(
                                    allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1]
                                )
                            }
                        >
                            {sliderBox(allSla.sevenDayPrice[allSla.sevenDayPrice.length - 1])}
                        </div>
                    )}
                    <div
                        className={
                            !isRightClick
                                ? "cursor-pointer position-absolute top-50 start-100 translate-middle next-slide-date shadow h-100 d-flex px-1 rounded-end"
                                : "cursor-pointer position-absolute top-50 start-100 translate-middle next-slide-date shadow h-100 d-flex px-1 rounded-end disabled"
                        }
                        onClick={handleRightClick}
                    >
                        <span className="align-self-center theme-violet-txt">
                            {GetSvgIcon("caretRight")}
                        </span>
                    </div>
                </div >
            ) : (
                errorMsg && <div className="m-2 alert alert-danger">{errorMsg}</div>
            )}
        </>
    )
}

export default DateAvlSpaceSlider
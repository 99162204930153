export const initValIsr = {
    name: '',
    phone: '',
    email: '',
    postCode: '',
    state: '',
	city: '',
	address: '',
	isrIc: '',
	bankName: '',
	acNumber: '',
	beneficiaryName: '',
	isrIcImg: {
        photo: '',
        photoFileType: '',
        photoBucketUrl: '',
        photoFileName: ''
    },
    bankStatement: {
        photo: '',
        photoFileType: '',
        photoBucketUrl: '',
        photoFileName: ''
    },
    isrPrflImg: {
        photo: '',
        photoFileType: '',
        photoBucketUrl: '',
        photoFileName: ''
    }
}
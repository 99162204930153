import React, { useCallback, useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import {
  decodeUrlData,
  downloadFiles,
  getActionTitle,
  permission,
  toBase64,
} from "../Helper/CommonMethods";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  CLAIM_ACTION,
  CLAIM_ASSIGN_TYPE,
  CLAIM_ATTACHMENT,
  DATE_FORMAT_MOMENT,
  FILE_UPLOAD_TEXT,
  NUMBER_NINE_DECIMAL,
  NUMBER_TWO_DECIMAL,
  PTRN_ERR_MSG,
  REQUIRED_ERR_MSG,
  SERVER_ERR_MSG,
  TEXT_AREA_REGEX,
  TWO_DECIMAL,
} from "../Helper/Constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import GetSvgIcon, { GetCashStack, GetSvgUpload } from "../../images/svglist";
import moment from "moment";
import ClaimHistory from "./ClaimHistory";
import { Alert } from "react-bootstrap";
import {
  ACCEPTCLAIM,
  ACKCLAIM,
  APPEALCLAIMAMT,
  APPROVECLAIM,
  ASSIGNOP,
  CUSTPARTFAULT,
  DWNINCRPT,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  PROCESSINS,
  PROCESSPYMNT,
  RAISECLAIM,
  REJECTCLAIM,
  REQINFO,
  SETCLAIMAMT,
} from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import ClaimAction from "./ClaimAction";
import { Tooltip } from "react-tooltip";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";

const initAlertConfig = {
  actionCode: 0,
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initWaybillCustData = {
  bankName: null,
  bankAcct: null,
  benifName: null,
  waybillNo: null,
  // orderNo: null,
  // lastArrLoc: null,
  // lastDelEvent: null,
  // shipDate: null,
  // estDlv: null,
};

const initOrderData = {
  claimId: 0,
  claimNo: "",
  orderId: 0,
  orderNo: null,
  origin: null,
  destination: null,
  shipDt: null,
  estDt: null,
  ordSts: null,
  financeAttch: "[]",
  isClaimAck: false,
  hasCustAppealed: false,
  isClaimUpdAllowed: false,
};

const initFileObj = {
  photo: null,
  photoFileName: null,
  photoFileType: CLAIM_ATTACHMENT.GENERAL,
  photoBucketUrl: null,
};

const initErrorObj = {
  errClaimType: { value: false, message: "" },
  errClmAmt: { value: false, message: "" },
  errDtlsOdDmg: { value: false, message: "" },
  errDescOfOcc: { value: false, message: "" },
};

const ClaimRaise = (props) => {
  // const { claimId, orderId, palletId, waybilNo } = props
  const userType = getUserInfo("userType");
  const userRoles = JSON.parse(getUserInfo("userRoles"));
  const template = require(`../../assets/templates/Power_of_Attorney_Letter_2021_V1.0.pdf`);

  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [attachment, setAttachment] = useState([]);
  const [claimTypeList, setClaimTypeList] = useState([]);
  const [claimType, setClaimType] = useState(undefined);
  const [detailsOfDamage, setDetailsOfDamage] = useState(undefined);
  const [descOfOccured, setDescOfOccured] = useState(undefined);
  const [claimAmt, setClaimAmt] = useState(undefined);
  const [message, setMessage] = useState(
    JSON.parse(JSON.stringify(initErrorObj))
  );
  const [waybillDtls, setWaybillDtls] = useState(
    JSON.parse(JSON.stringify(initWaybillCustData))
  );
  const [orderData, setOrderData] = useState(
    JSON.parse(JSON.stringify(initOrderData))
  );
  const [genDocList, setGenDocList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [lastLogDetails, setLastLogDetails] = useState(null);
  const [action, setAction] = useState(null);
  const [claimLogModal, setClaimLogModal] = useState({
    size: "lg",
    show: false,
    title: "Claim History",
  });
  const [claimDetails, setClaimDetails] = useState(null);
  const [insData, setInsData] = useState();
  const [isIns, setIsIns] = useState(false);
  const [actionModalConfig, setActionModalConfig] = useState({
    size: "md",
    show: false,
    title: "",
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [confirmConfig, setConfirmConfig] = useState(initAlertConfig);
  const [actionTypeCode, setActionTypeCode] = useState();

  const resetMessage = () => {
    setTimeout(
      () => setMessage(JSON.parse(JSON.stringify(initErrorObj))),
      5000
    );
  };

  useEffect(() => {
    getClaimTypes();
    if (!!searchParams && !!searchParams?.get("data")) {
      let decodedData = decodeUrlData(searchParams?.get("data"));
      loadData(
        decodedData?.orderId,
        decodedData?.claimId,
        decodedData?.claimNo,
        decodedData?.waybillNo
      );
    }
    if (!!state) {
      patchClaimData(state);
    }
    // setGenDocList([...genDocList, initFileObj]);
  }, []);

  const downFinAttach = useCallback((data) => {
    if (data.length > 0) {
      downloadFiles(data);
    }
  }, []);

  const patchClaimData = (argData) => {
    setClaimDetails(argData);
    setClaimType(argData?.claimType);
    setClaimAmt(argData?.custAmt);
    setDetailsOfDamage(argData?.claimDtls);
    setDescOfOccured(argData?.claimReason);
    if (!!argData?.attachments) {
      const attch = JSON.parse(argData?.attachments);
      setAttachment(
        attch?.filter((a) => a.photoFileType !== CLAIM_ATTACHMENT.GENERAL)
      );
      setGenDocList(
        attch?.filter((a) => a.photoFileType === CLAIM_ATTACHMENT.GENERAL)
      );
    }
  };

  const fetchWaybillDtlsAndBankDtls = (
    resp,
    argOrderId,
    argClaimId,
    argClaimNo,
    argWaybillNo
  ) => {
    setSpinner(true);
    // let resp = await CallApiPost("GetWaybillAndBankDtlsForClaim", {
    //     "waybillNo": argWaybillNo,
    //     "orderId": +argOrderId
    // })
    if (resp.respCode === 200 && !!resp.respData) {
      setWaybillDtls({
        bankName: resp.respData?.bankName,
        bankAcct: resp.respData?.bankAcct,
        benifName: resp.respData?.benifName,
      });
      setOrderData({
        claimId: argClaimId,
        claimNo: argClaimNo,
        orderId: resp.respData?.orderId,
        palletId: resp.respData?.palletId,
        orderNo: resp.respData?.orderNo,
        origin: resp.respData?.origin,
        destination: resp.respData?.destination,
        shipDt: moment(resp.respData?.shipDate).format(DATE_FORMAT_MOMENT),
        estDt: moment(resp.respData?.estDlv).format(DATE_FORMAT_MOMENT),
        ordSts: resp.respData?.ordSts,
        financeAttch: resp.respData?.financeAttch,
        isClaimAck: resp.respData?.isClaimAck,
        hasCustAppealed: resp.respData?.hasCustAppealed,
        isClaimUpdAllowed: resp.respData?.isClaimUpdAllowed,
      });
    } else if (resp.respCode === 401) {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "UNAUTHORIZED",
        description: "Sorry, you are unauthorized!!!",
      });
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    }
    setSpinner(false);
  };

  const getClaimTypes = (data) => {
    // let data = await CallApiPost("GetDropdownOptions", { argClaimType: 1 });
    if (!!data && data.respCode === 200) {
      setClaimTypeList(data?.respData?.claimType);
    }
  };

  const patchLogData = (data) => {
    if (!!data && !!data?.respData && Array.isArray(data?.respData)) {
      setLastLogDetails(data?.respData[0]);
      setHistoryList(data?.respData);
    }
  };

  const loadData = (argOrderId, argClaimId, argClaimNo, argWaybillNo) => {
    setSpinner(true);
    Promise.all([
      CallApiPost("GetWaybillAndBankDtlsForClaim", {
        waybillNo: argWaybillNo,
        orderId: +argOrderId,
        claimId: +argClaimId,
      }),
      CallApiPost("GetDropdownOptions", { argClaimType: 1 }),
      CallApiGet(`ClaimLogList?claimId=${argClaimId}`),
      CallApiGet(`getInsuranceByOrdId?orderId=${+argOrderId}`),
    ])
      .then(([respOrdDtls, respDrpDown, respLog, resInsDt]) => {
        if (
          respOrdDtls.respCode === 401 ||
          respDrpDown.respCode === 401 ||
          respLog.respCode === 401
        ) {
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "UNAUTHORIZED",
            description: "Sorry, you are unauthorized!!!",
          });
          setSpinner(false);
        } else if (
          respOrdDtls.respCode === 500 ||
          respDrpDown.respCode === 500 ||
          respLog.respCode === 500 ||
          resInsDt.respCode === 500
        ) {
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "ERROR",
            description: SERVER_ERR_MSG,
          });
          setSpinner(false);
        } else if (
          respOrdDtls.respCode === 200 ||
          respDrpDown.respCode === 200 ||
          respLog.respCode === 200 ||
          resInsDt.respCode === 200
        ) {
          getClaimTypes(respDrpDown);
          fetchWaybillDtlsAndBankDtls(
            respOrdDtls,
            argOrderId,
            argClaimId,
            argClaimNo,
            argWaybillNo
          );

          if (resInsDt.respData.length > 0) {
            setInsData(resInsDt.respData[0]);
            setIsIns(true);
          }
          patchLogData(respLog);
          setSpinner(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setSpinner(false);
      });
  };

  const reloadLogs = async () => {
    setSpinner(true);
    let result = await CallApiGet(`ClaimLogList?claimId=${orderData?.claimId}`);
    if (result.respCode === 200) {
      patchLogData(result);
      setSpinner(false);
    } else if (result.respCode === 401) {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "UNAUTHORIZED",
        description: "Sorry, you are unauthorized!!!",
      });
      setSpinner(false);
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
      setSpinner(false);
    }
  };

  const handelFileSelect = async (
    event,
    attachementType,
    argIndex,
    argCode
  ) => {
    try {
      setSpinner(true);
      const file = event.target.files;
      const argFileName = file[0].name;
      var ext = argFileName.substr(argFileName.lastIndexOf(".") + 1);
      const fileName = argCode + "_" + moment().milliseconds() + "." + ext;

      if (file.length > 0) {
        let photo = "";
        photo = await toBase64(file[0]);
        if (!!photo) {
          let fileObj = {
            photo: photo,
            photoFileName: fileName,
            photoFileType: attachementType,
            photoBucketUrl: null,
          };

          if (attachementType === 491) {
            let currGenDocList = [...genDocList];
            let updtDocList = currGenDocList.map((obj, index) => {
              if (argIndex === index) {
                return fileObj;
              } else {
                return obj;
              }
            });
            setGenDocList([...updtDocList]);
          } else {
            let updatedList = [...attachment];
            let checkExists = updatedList.filter(
              (item) => item.photoFileType === attachementType
            );
            if (checkExists.length > 0) {
              updatedList.forEach((item) => {
                if (item.photoFileType === attachementType) {
                  item.photo = fileObj?.photo;
                  item.photoFileName = fileObj?.photoFileName;
                  item.photoBucketUrl = fileObj?.photoBucketUrl;
                }
              });
            } else {
              updatedList = [...attachment, fileObj];
            }
            // setImages(state => ({ ...state, [attachementType]: "data:image/jpeg;base64," + photo }))
            setAttachment(updatedList);
          }

          setSpinner(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSpinner(false);
    }
  };

  const validateClaim = () => {
    let returnValue = true;
    let tempErr = { ...message };
    if (claimType == null) {
      returnValue = false;
      tempErr.errClaimType = { value: true, message: REQUIRED_ERR_MSG };
    }
    if (!!claimAmt && !TWO_DECIMAL.test(claimAmt)) {
      returnValue = false;
      tempErr.errClmAmt = {
        value: true,
        message: "Input amount doesn't satisfy the confition",
      };
    }
    if (
      detailsOfDamage === undefined ||
      detailsOfDamage === null ||
      detailsOfDamage?.length === 0
    ) {
      returnValue = false;
      tempErr.errDtlsOdDmg = { value: true, message: REQUIRED_ERR_MSG };
    } else if (!TEXT_AREA_REGEX.test(detailsOfDamage)) {
      returnValue = false;
      tempErr.errDtlsOdDmg = { value: true, message: PTRN_ERR_MSG };
    }
    if (
      descOfOccured === null ||
      descOfOccured === undefined ||
      descOfOccured?.length === 0
    ) {
      returnValue = false;
      tempErr.errDescOfOcc = { value: true, message: REQUIRED_ERR_MSG };
    } else if (!TEXT_AREA_REGEX.test(descOfOccured)) {
      returnValue = false;
      tempErr.errDescOfOcc = { value: true, message: PTRN_ERR_MSG };
    } else if (isIns === true) {
      const fileName = attachment.find(
        (a) =>
          //  a.photoFileName.split("_").includes("POWEROFATTORNEY")
          a.photoFileType === 495
      );
      if (!fileName) {
        returnValue = false;
        tempErr.errPowerAtt = { value: true, message: FILE_UPLOAD_TEXT };
      }
    }
    if (!waybillDtls.bankName) {
      returnValue = false;
      tempErr.errBnkNm = { value: true, message: REQUIRED_ERR_MSG };
    } else if (!ALPHANUM_SPACE_SPCL_CHAR.test(waybillDtls.bankName)) {
      returnValue = false;
      tempErr.errBnkNm = { value: true, message: PTRN_ERR_MSG };
    }
    if (!waybillDtls.bankAcct) {
      returnValue = false;
      tempErr.errBnkAcc = { value: true, message: REQUIRED_ERR_MSG };
    } else if (!ALPHANUM_SPACE_SPCL_CHAR.test(waybillDtls.bankAcct)) {
      returnValue = false;
      tempErr.errBnkAcc = { value: true, message: PTRN_ERR_MSG };
    }
    if (!waybillDtls.benifName) {
      returnValue = false;
      tempErr.errBnkBn = { value: true, message: REQUIRED_ERR_MSG };
    } else if (!ALPHANUM_SPACE_SPCL_CHAR.test(waybillDtls.benifName)) {
      returnValue = false;
      tempErr.errBnkBn = { value: true, message: PTRN_ERR_MSG };
    }
    setMessage(tempErr);
    // } else if (
    //     !!claimType &&
    //     attachment.find((e) => e.photoFileType === CLAIM_ATTACHMENT.DAMAGED) ===
    //     undefined
    // ) {
    //     returnValue = false;
    //     setMessage("Please upload damage image");
    //     setSpinner(false);
    // }
    resetMessage();
    return returnValue;
  };

  const submitClaim = async () => {
    setSpinner(true);
    if (validateClaim()) {
      let payload = {
        claimId: orderData?.claimId,
        claimNo: !!orderData?.claimNo ? orderData?.claimNo : null,
        orderId: orderData?.orderId,
        palletId: orderData?.palletId,
        actionCode: CLAIM_ACTION.ClaimRaised,
        claimType: claimType,
        attachments: [...attachment, ...genDocList],
        claimDetail: detailsOfDamage,
        claimReason: descOfOccured,
        claimCustAmt: claimAmt,
        /* The above code is declaring a variable named "bankDetails" in JavaScript. */
        bankDetails: waybillDtls,
      };
      let data = await CallApiPost("Claim", payload);
      if (!!data && data?.respCode === 200) {
        showAlert({
          isSuccess: true,
          size: "md",
          show: true,
          title: "",
          description: data?.respMessage,
        });
      } else if (data?.respCode === 401) {
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "ERROR",
          description: "Sorry, you don't have the write permission!!",
        });
      } else if (data?.respCode === 500) {
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "",
          description: SERVER_ERR_MSG,
        });
      } else {
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "ERROR",
          description: data?.respMessage,
        });
      }
    }
    setSpinner(false);
  };

  const removeDoc = (index) => {
    let currGenDocList = [...genDocList];
    currGenDocList.splice(index, 1);
    setGenDocList([...currGenDocList]);
  };

  const appendDoc = () => {
    setGenDocList([...genDocList, initFileObj]);
  };

  const renderFileName = (attchType, argCode) => {
    let currAttch = attachment?.find((e) => e.photoFileType === attchType);
    let fileName = !!currAttch?.photo
      ? currAttch?.photoFileName
      : currAttch?.photoBucketUrl?.split("/")?.pop();
    return !!fileName ? fileName : "Upload";
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    if (alertConfig?.isSuccess) {
      navigate("/claim");
    }
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const showClaimLogs = () => {
    setClaimLogModal({
      size: "xl",
      show: true,
      title: "Claim History",
    });
  };

  const closeClaimLogs = () => {
    setClaimLogModal({
      size: "xl",
      show: false,
      title: "",
    });
  };

  const showActionForm = (actionType, argActBy) => {
    setAction(actionType);
    const config = {
      size: "md",
      show: true,
      title: getActionTitle(actionType),
      actionBy: !!argActBy ? argActBy : 0,
    };
    setActionModalConfig(config);
  };

  const closeActionForm = () => {
    setActionModalConfig({
      size: "md",
      show: false,
      title: "",
    });
    // reloadLogs(orderData?.claimId)
  };
  const hideConfirmAlert = () => {
    setConfirmConfig({
      actionCode: 0,
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const onCustomerConfirm = async (argMsg, argActionCode) => {
    hideConfirmAlert();

    let payload = {
      claimId: claimDetails?.claimId,
      orderId: claimDetails?.orderId,
      palletId: claimDetails?.palletId,
      actionCode: !!argActionCode ? argActionCode : actionTypeCode,
      claimType: claimDetails?.claimType,
      attachments: [],
      claimDetail: claimDetails?.claimDtls,
      claimReason: claimDetails?.claimReason,
      assignSbu: 0,
      assignToStaff: 0,
      remarks: !!argMsg ? argMsg : "",
      claimLhAmt: claimDetails?.lhAmt,
      claimCustAmt: claimDetails?.custAmt,
      claimNo: claimDetails?.claimNo,
    };

    let data = await CallApiPost("Claim", payload);
    if (!!data && data?.respCode === 200) {
      showAlert({
        isSuccess: true,
        size: "md",
        show: true,
        title: "",
        description: data?.respMessage,
      });
      // handleModalClose();
      setSpinner(false);
      reloadLogs();
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: data?.respMessage,
      });
      setSpinner(false);
    }
  };

  const onAppealOrAccept = (code) => {
    setActionTypeCode(code);
    setConfirmConfig({
      actionCode: code,
      isSuccess: false,
      size: "md",
      show: true,
      title: "Confirm",
      description: "Confirm",
    });
  };

  const downloadFileUsingURL = (url) => {
    window.open(url, "_blank");
  };

  const navigateToAckPage = () => {
    const payload = {
      claimId: orderData?.claimId,
      claimNo: orderData?.claimNo,
    };
    navigate("/claimAck", { state: payload });
  };

  const incidentReportDownload = async (claimId) => {
    setSpinner(true);
    const response = await CallApiGet(`incidentReportDownload/${claimId}`);
    setSpinner(false);
    if (response.respCode === 200) {
      window.open(response.respData, "_blank");
    }
  };

  const claimFooterControls = (
    <>
      {permission(DWNINCRPT)?.allowedWrite &&
        orderData?.isClaimAck &&
        !LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (
          <button
            type="button"
            className="btn btn-outline-secondary theme-violet me-1"
            onClick={() => incidentReportDownload(orderData?.claimId)}
          >
            Download Incident Report
          </button>
        )}
      {permission(RAISECLAIM)?.allowedWrite &&
        // (userRoles.some((el) => el.roleId === lastLogDetails?.assignedToRole) ||
        (orderData?.claimId === 0 ||
          (lastLogDetails?.assignedToRole === null &&
            lastLogDetails?.action_code === CLAIM_ACTION.InformationRequest)) &&
        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (
          // !orderData?.isClaimAck &&
          // orderData?.isClaimUpdAllowed &&
          <>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet me-1"
              onClick={() => submitClaim()}
            >
              {orderData?.claimId > 0 ? "Update" : "Submit"}
            </button>
          </>
        )}

      {orderData?.claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Customer &&
        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (
          <>
            {/* {!!lastLogDetails &&
              lastLogDetails?.action_code === CLAIM_ACTION.InformationRequest &&
              permission(REQINFO)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationSubmitted)
                  }
                >
                  Submit Required Information
                </button>
              )} */}
            {!!lastLogDetails &&
              lastLogDetails?.action_code === CLAIM_ACTION.FixClaimAmount && (
                <>
                  {permission(APPEALCLAIMAMT)?.allowedWrite &&
                    !orderData?.hasCustAppealed && (
                      <button
                        type="button"
                        className="btn btn-outline-secondary theme-violet me-1"
                        onClick={
                          () =>
                            showActionForm(CLAIM_ACTION.AppealForClaimAmount)
                          // onAppealOrAccept(CLAIM_ACTION.AppealForClaimAmount)
                        }
                      >
                        Appeal
                      </button>
                    )}
                  {permission(ACCEPTCLAIM)?.allowedWrite && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={() =>
                        // showActionForm(CLAIM_ACTION.AcceptClaimAmount)
                        // onAppealOrAccept(CLAIM_ACTION.AcceptClaimAmount)
                        onCustomerConfirm(
                          "Customer accepted the claim amount",
                          CLAIM_ACTION.AcceptClaimAmount
                        )
                      }
                    >
                      Accept
                    </button>
                  )}
                </>
              )}
          </>
        )}
      {
        //IO Buttons
        orderData?.claimId > 0 &&
          !!lastLogDetails &&
          lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.IO &&
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          ) && (
            <>
              {permission(REJECTCLAIM).allowedWrite && (
                <button
                  type="button"
                  className="btn btn-danger me-1"
                  onClick={() => showActionForm(CLAIM_ACTION.RejectClaim)}
                >
                  Reject
                </button>
              )}
              {permission(REQINFO)?.allowedWrite && !orderData?.isClaimAck && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationRequest, -9)
                  }
                >
                  Request More Info From Customer
                </button>
              )}
              {permission(ASSIGNOP)?.allowedWrite && !orderData?.isClaimAck && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.AssignedToOperation)
                  }
                >
                  Assign to Operations
                </button>
              )}
              {permission(ASSIGNOP)?.allowedWrite && orderData?.isClaimAck && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() => showActionForm(CLAIM_ACTION.ReqMoreInfoToOp)}
                >
                  Request More Info From Operation
                </button>
              )}

              {permission(APPROVECLAIM)?.allowedWrite &&
                orderData?.isClaimAck && (
                  <button
                    type="button"
                    className="btn btn-success me-1"
                    onClick={() => showActionForm(CLAIM_ACTION.ApproveClaim)}
                  >
                    Approve
                  </button>
                )}
            </>
          )
      }
      {
        //Operator Buttons
        orderData?.claimId > 0 &&
          !!lastLogDetails &&
          lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Operation &&
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          ) && (
            <>
              {permission(ACKCLAIM)?.allowedWrite && !orderData?.isClaimAck && (
                <button
                  type="button"
                  className="btn btn-success me-1"
                  // onClick={() =>
                  //     showActionForm(CLAIM_ACTION.AcknowledgeAndFillIncidentReport)
                  // }
                  onClick={navigateToAckPage}
                >
                  Acknowledgement
                </button>
              )}
              {permission(CUSTPARTFAULT)?.allowedWrite &&
                !orderData?.isClaimAck && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet me-1"
                    onClick={() =>
                      showActionForm(CLAIM_ACTION.MentionCustomerPartnerFault)
                    }
                  >
                    Other Party Fault
                  </button>
                )}
              {!!lastLogDetails &&
                lastLogDetails?.action_code === CLAIM_ACTION.ReqMoreInfoToOp &&
                permission(REQINFO)?.allowedWrite &&
                orderData?.isClaimAck && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet me-1"
                    // onClick={() =>
                    //   showActionForm(CLAIM_ACTION.InformationSubmitted)
                    // }
                    onClick={navigateToAckPage}
                  >
                    Update Incident Report
                  </button>
                )}
            </>
          )
      }
      {
        //Claim Type User Buttons
        orderData?.claimId > 0 &&
          !!lastLogDetails &&
          lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Claims &&
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          ) && (
            <>
              {permission(REJECTCLAIM).allowedWrite && (
                <button
                  type="button"
                  className="btn btn-danger me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.RejectByClaimOfficer)
                  }
                >
                  Return to IO
                </button>
              )}
              {permission(REQINFO)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationRequest)
                  }
                >
                  Request More Info From Customer
                </button>
              )}
              {!!claimDetails?.insurance && claimDetails?.insurance > 0
                ? permission(APPROVECLAIM) && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-1"
                      onClick={() =>
                        showActionForm(CLAIM_ACTION.ApproveByClaimOfficer)
                      }
                    >
                      Approve
                    </button>
                  )
                : permission(SETCLAIMAMT) && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-1"
                      onClick={() =>
                        showActionForm(CLAIM_ACTION.FixClaimAmount)
                      }
                    >
                      Update Claim Amount
                    </button>
                  )}
            </>
          )
      }

      {
        //Insurance User Buttons
        orderData?.claimId > 0 &&
          !!lastLogDetails &&
          lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Insurance &&
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          ) && (
            <>
              {permission(REQINFO)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationRequest)
                  }
                >
                  Request More Info From Customer
                </button>
              )}
              {permission(PROCESSINS)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={() => showActionForm(CLAIM_ACTION.ProcessInsurance)}
                >
                  Approve
                </button>
              )}
            </>
          )
      }

      {
        //Finance User Buttons
        orderData?.claimId > 0 &&
          !!lastLogDetails &&
          lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Finance &&
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          ) && (
            <>
              {permission(REQINFO)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet me-1"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationRequest)
                  }
                >
                  Request More Info From Customer
                </button>
              )}
              {permission(PROCESSPYMNT)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={() => showActionForm(CLAIM_ACTION.ProcessPayment)}
                >
                  Process Claim Amount
                </button>
              )}
            </>
          )
      }
    </>
  );

  const updateBankDtls = (type, value) => {
    let bankDtls = waybillDtls;
    bankDtls[type] = value;
    setWaybillDtls(JSON.parse(JSON.stringify(bankDtls)));
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="d-flex flex-row justify-content-between section-title border-bottom pb-1 mb-3 mt-1">
          <span>Claim Details</span>
          {!LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
            getUserInfo("userType")
          ) && (
            <button
              className="btn btn-outline-secondary theme-violet text-nowrap"
              onClick={showClaimLogs}
            >
              Claim History {GetSvgIcon("History")}
            </button>
          )}
        </div>
        {/* {!!lastLogDetails?.remarks &&
        (LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ||
          userRoles.some(
            (el) => el.roleId === lastLogDetails?.assignedToRole
          )) && (
          <div className="row">
            <div className="col-md-12">
              <Alert variant="warning" dismissible>
                {lastLogDetails?.remarks.replace("#$", "\n")}
              </Alert>
            </div>
          </div>
        )} */}
        {!!state && state?.claimId > 0 && (
          <div className="bg-white border border-2 rounded-2 mb-3">
            <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
              Claim Details
            </div>
            <div className="p-2">
              <div className="d-flex justify-content-start align-item-center">
                <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Claim No</div>
                  <div className="theme-blue-txt">{state.claimNo}</div>
                </div>
                <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Claim Status</div>
                  <div className="theme-blue-txt">
                    {LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)
                      ? state?.statusCustDesc
                      : state?.statusLhDesc}
                  </div>
                </div>
                <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Outcome of investigation</div>
                  <div className="theme-blue-txt">
                    {!!lastLogDetails?.remarks
                      ? lastLogDetails?.remarks.replace("#$", "\n")
                      : "N/A"}
                  </div>
                </div>
                {!!orderData?.financeAttch &&
                  Array.isArray(JSON.parse(orderData?.financeAttch)) &&
                  JSON.parse(orderData?.financeAttch).length > 0 && (
                    <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">
                        Download Finance Attachments
                      </div>
                      <>
                        <div
                          data-tooltip-id="fin-attch"
                          className="modal-links fw-bold"
                          onClick={() =>
                            downFinAttach(JSON.parse(orderData?.financeAttch))
                          }
                        >
                          {GetSvgIcon("BiArrowDownCircleFill")}
                        </div>
                        <Tooltip
                          id="fin-attch"
                          content="Downoad all the attachments"
                          place="left"
                        />
                      </>
                    </div>
                  )}
                {!!lastLogDetails?.attachments &&
                  Array.isArray(JSON.parse(lastLogDetails?.attachments)) &&
                  JSON.parse(lastLogDetails?.attachments).length > 0 && (
                    <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">
                        Download Lastest Attachments
                      </div>
                      <>
                        <div
                          data-tooltip-id="fin-attch"
                          className="modal-links fw-bold"
                          onClick={() =>
                            downFinAttach(
                              JSON.parse(lastLogDetails?.attachments)
                            )
                          }
                        >
                          {GetSvgIcon("BiArrowDownCircleFill")}
                        </div>
                        <Tooltip
                          id="fin-attch"
                          content="Downoad all the attachments"
                          place="left"
                        />
                      </>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}

        <div className="bg-white border border-2 rounded-2 mb-3">
          <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
            Order Details
          </div>
          <div className="p-2">
            <div className="d-flex justify-content-between align-item-center">
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Order Number</div>
                <div className="theme-blue-txt">{orderData?.orderNo}</div>
              </div>
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Origin</div>
                <div className="theme-blue-txt">{orderData?.origin}</div>
              </div>
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Destination</div>
                <div className="theme-blue-txt">{orderData?.destination}</div>
              </div>
              {/* <div className="col grd-bg p-2 p my-2 rounded-3">
                            <div className="fw-bold">Last Event</div>
                            <div className="theme-blue-txt">{waybillDtls?.lastDelEvent}</div>
                        </div> */}
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Shipment Date</div>
                <div className="theme-blue-txt">{orderData?.shipDt}</div>
              </div>
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Estimated Delivery</div>
                <div className="theme-blue-txt">{orderData?.estDt}</div>
              </div>
              {/* <div className="col grd-bg p-2 p my-2 rounded-3">
                            <div className="fw-bold">Waybill Status</div>
                            <div className="theme-blue-txt">{waybillDtls?.palletStatus}</div>
                        </div> */}
              <div className="col grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Order Status</div>
                <div className="theme-blue-txt">{orderData?.ordSts}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white border border-2 rounded-2 mb-3">
          <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
            Customer Bank Details
          </div>
          <div className="p-2">
            <div className="d-flex justify-content-start align-item-center">
              <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Bank Name</div>
                <div>
                  <div className="input-group mb-2">
                    <span className="input-group-text inputsty" id="">
                      {GetSvgIcon("Claim")}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      step=".01"
                      onChange={(e) =>
                        updateBankDtls("bankName", e.target.value)
                      }
                      value={waybillDtls?.bankName}
                      disabled={
                        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)
                          ? false
                          : true
                      }
                      // maxlength="9"
                    />

                    {/* <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setClaimAmt(e.target.value)}
                        value={claimAmt}
                        pattern="/^[0-9]{9}.[0-9]{2}$/"
                        maxlength="12"
                      /> */}
                  </div>
                  {!!message?.errBnkNm?.value && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {message?.errBnkNm?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Bank Account</div>
                <div>
                  <div className="input-group mb-2">
                    <span className="input-group-text inputsty" id="">
                      {GetSvgIcon("Claim")}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      step=".01"
                      onChange={(e) =>
                        updateBankDtls("bankAcct", e.target.value)
                      }
                      value={waybillDtls?.bankAcct}
                      disabled={
                        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)
                          ? false
                          : true
                      }
                      // maxlength="9"
                    />

                    {/* <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setClaimAmt(e.target.value)}
                        value={claimAmt}
                        pattern="/^[0-9]{9}.[0-9]{2}$/"
                        maxlength="12"
                      /> */}
                  </div>
                  {!!message?.errBnkAcc?.value && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {message?.errBnkAcc?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-3 grd-bg p-2 p my-2 rounded-3">
                <div className="fw-bold">Bank Beneficiary Name </div>
                <div>
                  <div className="input-group mb-2">
                    <span className="input-group-text inputsty" id="">
                      {GetSvgIcon("Claim")}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      step=".01"
                      onChange={(e) =>
                        updateBankDtls("benifName", e.target.value)
                      }
                      value={waybillDtls?.benifName}
                      disabled={
                        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)
                          ? false
                          : true
                      }
                      // maxlength="9"
                    />

                    {/* <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setClaimAmt(e.target.value)}
                        value={claimAmt}
                        pattern="/^[0-9]{9}.[0-9]{2}$/"
                        maxlength="12"
                      /> */}
                  </div>
                  {!!message?.errBnkBn?.value && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {message?.errBnkBn?.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isIns === true && (
          <div className="bg-white border border-2 rounded-2 mb-3">
            <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
              Insurance Info
            </div>
            <div className="p-2">
              <div className="d-flex justify-content-start align-item-center">
                <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Package Id</div>
                  <div className="theme-blue-txt">{insData?.package_id}</div>
                </div>
                <div className="col-2 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Coverage Upto</div>
                  <div className="theme-blue-txt">{insData?.coverage}</div>
                </div>
                <div className="col-3 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Insurance Fee</div>
                  <div className="theme-blue-txt">{insData?.charge}</div>
                </div>
                <div className="col-3 grd-bg p-2 p my-2 rounded-3">
                  <div className="fw-bold">Insurance Declared Price</div>
                  <div className="theme-blue-txt">
                    {insData?.ins_declared_price}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white border border-2 rounded-2 mb-3">
          <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
            Claim Information
          </div>
          <div className="p-2">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-12">
                      {!!claimDetails?.insurance &&
                        claimDetails?.insurance > 0 && (
                          <>
                            <span className="ms-1">
                              {GetSvgIcon("ToolTip")}{" "}
                            </span>
                            <label
                              className="form-label font-12 text-danger"
                              htmlFor=""
                            >
                              This order is covered under insurance scheme
                            </label>
                          </>
                        )}
                    </div>
                    <div className="col-md-4 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label fw-bold"
                      >
                        Type of Claim{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <div className="input-group mb-2">
                        <span className="input-group-text inputsty">
                          {GetSvgIcon("Claim")}
                        </span>
                        <Select
                          options={claimTypeList}
                          placeholder="Select Claim Type"
                          value={
                            claimType === ""
                              ? ""
                              : claimTypeList.find(
                                  (opt) => opt.value === claimType
                                )
                          }
                          onChange={(d) => setClaimType(d ? d.value : d)}
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                          isDisabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        />
                      </div>
                      {!!message?.errClaimType?.value && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {message?.errClaimType?.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label fw-bold"
                      >
                        Claim Amount Request
                      </label>
                      <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
                      <label
                        className="form-label font-12 text-danger"
                        htmlFor=""
                      >
                        maxlength 11 with a value of two decimals
                      </label>
                      <div className="input-group mb-2">
                        <span className="input-group-text inputsty" id="">
                          {GetCashStack({ className: "bi bi-cash-stack" })}
                        </span>
                        {/* <input
                          type="number"
                          className="form-control"
                          step=".01"
                          onChange={(e) => setClaimAmt(e.target.value)}
                          value={claimAmt}
                          disabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        /> */}

                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setClaimAmt(e.target.value)}
                          value={claimAmt}
                          pattern={NUMBER_NINE_DECIMAL}
                          maxLength={12}
                          disabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        />
                      </div>
                      {!!message?.errClmAmt?.value && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {message?.errClmAmt?.message}
                          </span>
                        </div>
                      )}
                    </div>
                    {orderData?.claimId > 0 && (
                      <div className="col-md-4 mb-2">
                        <label
                          htmlFor="floatingInputGrid"
                          className="form-label fw-bold"
                        >
                          Approved Claim Amount
                        </label>
                        <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
                        <label
                          className="form-label font-12 text-danger"
                          htmlFor=""
                        >
                          readOnly
                        </label>

                        <div className="input-group mb-2">
                          <span className="input-group-text inputsty" id="">
                            {GetCashStack({ className: "bi bi-cash-stack" })}
                          </span>
                          <input
                            type="number"
                            readOnly
                            className="form-control"
                            step=".01"
                            value={claimDetails?.lhAmt}
                          />
                        </div>
                      </div>
                    )}

                    <div className="clearfix"></div>
                    <div className="col-md-6 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label fw-bold"
                      >
                        Details of Loss or Damage{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <div className="input-group mb-2">
                        <span className="input-group-text inputsty">
                          {GetSvgIcon("Description")}
                        </span>
                        <textarea
                          // disabled={claimId > 0}
                          type="text"
                          onChange={(e) => setDetailsOfDamage(e.target.value)}
                          className="form-control txt-area-resize-0"
                          placeholder="Details of Loss or Damage"
                          value={detailsOfDamage}
                          aria-label="input example"
                          maxlength="200"
                          disabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        ></textarea>
                      </div>
                      {!!message?.errDtlsOdDmg?.value && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {message?.errDtlsOdDmg?.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label fw-bold"
                      >
                        Description of How it Ocurred{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <div className="input-group mb-2">
                        <span className="input-group-text inputsty">
                          {GetSvgIcon("Description")}
                        </span>
                        <textarea
                          // disabled={claimId > 0}
                          type="text"
                          onChange={(e) => setDescOfOccured(e.target.value)}
                          className="form-control txt-area-resize-0"
                          placeholder="Description of How it Ocurred"
                          value={descOfOccured}
                          aria-label="input example"
                          maxlength="200"
                          disabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        ></textarea>
                      </div>
                      {!!message?.errDescOfOcc?.value && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {message?.errDescOfOcc?.message}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 mb-2">
                      <div>
                        <label className="form-label fw-bold mb-1" htmlFor="">
                          Upload Invoice
                        </label>
                      </div>
                      <div className="Upload-Photo mb-2 w-100">
                        <input
                          type="file"
                          accept="application/pdf, image/jpeg, image/jpg"
                          onChange={(e) =>
                            handelFileSelect(
                              e,
                              CLAIM_ATTACHMENT.INVOICE,
                              0,
                              "INVOICE"
                            )
                          }
                          id={`attachement-${CLAIM_ATTACHMENT.INVOICE}`}
                          disabled={
                            orderData?.isClaimAck &&
                            !orderData?.isClaimUpdAllowed
                          }
                        />
                        <div className="uploaded-text ms-2 w-100 d-flex flex-row justify-content-between">
                          <span>
                            {GetSvgUpload({ required: false })}
                            {renderFileName(
                              CLAIM_ATTACHMENT.INVOICE,
                              "INVOICE"
                            )}
                          </span>
                          {!!attachment?.find(
                            (atch) =>
                              atch.photoFileType === CLAIM_ATTACHMENT.INVOICE
                          )?.photoBucketUrl && (
                            <span
                              onClick={() =>
                                downloadFileUsingURL(
                                  attachment.find(
                                    (atch) =>
                                      atch.photoFileType ===
                                      CLAIM_ATTACHMENT.INVOICE
                                  )?.photoBucketUrl
                                )
                              }
                              className="me-3"
                            >
                              {GetSvgIcon("BiArrowDownCircleFill")}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {isIns === true && (
                      <div className="col-md-6 mb-2">
                        <div>
                          <label
                            className="form-label fw-bold mb-1 float-start"
                            htmlFor=""
                          >
                            Upload Power of Attorney
                          </label>
                          <span className="float-end">
                            <span className="font-12 text-danger me-1">
                              Download the Power of Attorney Template
                            </span>
                            <a
                              type="button"
                              className="btn btn-default p-0"
                              href={template}
                              download="Power_of_Attorney_Template"
                              data-tooltip-id="download-vch"
                            >
                              {GetSvgIcon("IconDownload")}
                              <Tooltip
                                id="download-vch"
                                content="Download Power_of_Attorney_Template"
                                place="left"
                                style={{ zIndex: 900 }}
                              />
                            </a>
                          </span>
                          {/* <label
                        className=" float-start form-label font-12 text-danger"
                        htmlFor=""
                      >
                        Download the Power of Attorney Template
                      </label> */}
                          <div className="clearfix"></div>
                        </div>
                        <div className="Upload-Photo mb-2 w-100">
                          <input
                            type="file"
                            accept="application/pdf, image/jpeg, image/jpg"
                            onChange={(e) =>
                              handelFileSelect(
                                e,
                                CLAIM_ATTACHMENT.POWEROFATTORNEY,
                                0,
                                "POWEROFATTORNEY"
                              )
                            }
                            id={`attachement-${CLAIM_ATTACHMENT.POWEROFATTORNEY}`}
                            disabled={
                              orderData?.isClaimAck &&
                              !orderData?.isClaimUpdAllowed
                            }
                          />
                          {/* <div className="uploaded-text ms-2 w-100">
                        {GetSvgUpload({ required: false })}
                        {renderFileName(
                          CLAIM_ATTACHMENT.POWEROFATTORNEY,
                          "POWEROFATTORNEY"
                        )}
                      </div> */}
                          <div className="uploaded-text ms-2 w-100 d-flex flex-row justify-content-between">
                            <span>
                              {GetSvgUpload({ required: false })}
                              {renderFileName(
                                CLAIM_ATTACHMENT.POWEROFATTORNEY,
                                "POWEROFATTORNEY"
                              )}
                            </span>
                            {!!attachment?.find(
                              (atch) =>
                                atch.photoFileType ===
                                CLAIM_ATTACHMENT.POWEROFATTORNEY
                            )?.photoBucketUrl && (
                              <span
                                onClick={() =>
                                  downloadFileUsingURL(
                                    attachment.find(
                                      (atch) =>
                                        atch.photoFileType ===
                                        CLAIM_ATTACHMENT.POWEROFATTORNEY
                                    )?.photoBucketUrl
                                  )
                                }
                                className="me-3"
                              >
                                {GetSvgIcon("BiArrowDownCircleFill")}
                              </span>
                            )}
                          </div>
                        </div>
                        {!!message?.errPowerAtt?.value && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {message?.errPowerAtt?.message}
                            </span>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="clearfix"></div>
                    <div className="col-md-6 mb-2">
                      <div>
                        <label className="form-label fw-bold" htmlFor="">
                          Image References & Other Documents
                        </label>
                        <span
                          className="ms-3 cursor-pointer"
                          onClick={() => appendDoc()}
                        >
                          {GetSvgIcon("PlusCircle")}
                        </span>
                      </div>

                      {!!genDocList &&
                        genDocList.length > 0 &&
                        genDocList.map((ele, index) => (
                          <div className="d-flex mb-2" key={index}>
                            <div
                              className="col-auto Upload-Photo w-100"
                              key={`G-${index}`}
                            >
                              <input
                                type="file"
                                accept="application/pdf, image/jpeg, image/jpg"
                                onChange={(e) =>
                                  handelFileSelect(
                                    e,
                                    CLAIM_ATTACHMENT.GENERAL,
                                    index,
                                    "GENDOC"
                                  )
                                }
                                id={`attachement-${CLAIM_ATTACHMENT.GENERAL}-${index}`}
                              />
                              <div className="uploaded-text ms-2 w-100 d-flex flex-row justify-content-between mb-2 me-5">
                                <span>
                                  {GetSvgUpload({ required: false })}
                                  {!!ele?.photo
                                    ? ele?.photoFileName
                                    : !!ele?.photoBucketUrl
                                    ? ele?.photoBucketUrl.split("/").pop()
                                    : "Upload"}
                                </span>
                                {!!ele?.photoBucketUrl && (
                                  <span
                                    onClick={() =>
                                      downloadFileUsingURL(ele?.photoBucketUrl)
                                    }
                                    className="me-3"
                                  >
                                    {GetSvgIcon("BiArrowDownCircleFill")}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="col-auto align-self-center">
                              <button
                                type="button"
                                className="border-0 bg-white cursor-pointer"
                                onClick={() => removeDoc(index)}
                              >
                                {GetSvgIcon("Removecircle")}
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* <div className="col-md-6 mb-2">
                    <label className="form-label fw-bold" htmlFor="">
                      Other Uploaded Attachments
                    </label>
                    {attachment
                      .filter((atch) => atch.type === CLAIM_ATTACHMENT.GENERAL)
                      .map((atch, index) => (
                        <div
                          className="d-flex flex-row justify-content-between mb-2"
                          key={index}
                        >
                          <span>{atch?.filename.split("/").pop()}</span>
                          <span
                            onClick={() => downloadFileUsingURL(atch?.filename)}
                          >
                            {GetSvgIcon("BiArrowDownCircleFill")}
                          </span>
                        </div>
                      ))}
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end m-2">
          {![414, 422].includes(state?.statusLh) &&
            ![429, 437].includes(state?.statusCust) &&
            claimFooterControls}
          <button
            type="button"
            className="btn btn-default border ms-1"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </button>
        </div>

        <ClaimHistory
          historyList={historyList}
          modalConfig={claimLogModal}
          handleModalClose={closeClaimLogs}
        />
        <ClaimAction
          reloadClaims={reloadLogs}
          viewMode={false}
          actionType={action}
          claimDetails={claimDetails}
          setSpinner={setSpinner}
          showAlert={showAlert}
          setAction={setAction}
          // cancelClaimForm={cancelClaimForm}
          closeActionForm={closeActionForm}
          modalConfig={actionModalConfig}
          claimLogDetails={lastLogDetails}
        />
      </Layout>
      <ModalConfirmAlert
        confirmAlertConfig={confirmConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={onCustomerConfirm}
      >
        {confirmConfig.actionCode === CLAIM_ACTION.AppealForClaimAmount ? (
          <div>
            Do you want to appeal for more compensation? It may take 1-7 days to
            review{" "}
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-2">
                <label className="form-label theme-label">
                  Requested Claim Amount
                </label>
                <div>{claimDetails?.custAmt?.toFixed(2)} RM</div>
              </div>
              <div className="col-md-6 mb-2">
                <label className="form-label theme-label">
                  Approved Claim Amount
                </label>
                <div>{claimDetails?.lhAmt?.toFixed(2)} RM</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                Are you sure you want to proceed?{" "}
              </div>
            </div>
          </div>
        )}
      </ModalConfirmAlert>
    </>
  );
};

export default ClaimRaise;

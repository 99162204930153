import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toBase64 } from "../Helper/CommonMethods";
import GetSvgIcon from "../../images/svglist";
import { CLAIM_ATTACHMENT, CLAIM_ACTION } from "../Helper/Constants";
import { CallApiPost, CallApiGet } from "../Helper/serviceApi";
import ModalLayout from "../Layout/ModalLayout";
import "../Vehicle/VehicleStyle.css";
import moment from "moment";
const faultList = [
  {
    value: 1,
    label: "Customer",
  },
  {
    value: 2,
    label: "Driver",
  },
  {
    value: 3,
    label: "Other SBU",
  },
];
const errObj = {
  faultStatus: false,
  partnerStatus: false,
  claimAmtSts: false,
  claimAttchErr: false,
};

const initialAttachFields = {
  photo: null,
  photoFileName: null,
  photoFileType: null,
  photoBucketUrl: null,
};

const ClaimAction = (props) => {
  const {
    actionType,
    claimDetails,
    setSpinner,
    showAlert,
    setAction,
    cancelClaimForm,
    claimLogDetails,
    viewMode,
    reloadClaims,
    closeActionForm,
    modalConfig,
  } = props;
  // const [modalConfig, setModalConfig] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [currAttch, setCurrAttch] = useState(
    JSON.parse(JSON.stringify(initialAttachFields))
  );
  const [sbus, setSbus] = useState([]);
  const [sbu, setSbu] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [staff, setStaff] = useState(null);
  const [remark, setRemark] = useState(null);
  const [inputFields, setInputFields] = useState([]);
  const [claimAmt, setClaimAmt] = useState(null);
  const [fault, setFault] = useState();
  const [partnerList, setPartnerList] = useState([]);
  const [partner, setPartner] = useState(null);
  const [error, setError] = useState(errObj);
  const [showRemark, setShowRemark] = useState(true);
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());

  useEffect(() => {
    if (!!actionType) {
      getSbus();
      // showActionForm();
      // getSbuStaff(sbu);
    }
    if (!!claimLogDetails) {
      patchClaimAttachement();
    }
  }, [actionType, claimLogDetails]);

  const patchClaimAttachement = () => {
    if (
      !!claimLogDetails?.attachments &&
      claimLogDetails?.attachments.length > 0
    ) {
      let parsedAttachments = JSON.parse(claimLogDetails?.attachments);
      setAttachment(parsedAttachments);
    }
  };
  const getSbus = async () => {
    setSpinner(true);
    let data = await CallApiGet(`OrderStops?orderId=${claimDetails?.orderId}`);
    if (!!data && data.respCode === 200) {
      setSbus(data?.respData);
      setSbu(data?.respData[0]?.value);
    } else {
      setSbus([]);
      setSbu(null);
    }
    setSpinner(false);
  };
  // const getSbuStaff = async (sbuId) => {
  //   let data = await CallApiGet(`GetHubDepotRoles`);
  //   if (!!data && data.respCode === 200) {
  //     setStaffs(data?.respData);
  //     setStaff(data?.respData[0]?.value);
  //   } else {
  //     setStaffs([]);
  //     setStaff(null);
  //   }
  // };
  const handelFileSelect = async (event, attachementType, argCode) => {
    setSpinner(true);
    try {
      const file = event.target.files;
      // const file_type = argCode + "_" + attachementType;
      const argFileName = file[0].name;
      var ext = argFileName.substr(argFileName.lastIndexOf(".") + 1);
      const fileName = argCode + "_" + moment().milliseconds() + "." + ext;
      if (file.length > 0) {
        let photo = "";
        photo = await toBase64(file[0]);
        if (!!photo) {
          let fileObj = {
            photo: photo,
            photoFileName: fileName,
            photoFileType: attachementType,
            photoBucketUrl: "",
          };

          // let updatedList = [...attachment];
          // let checkExists = updatedList.filter(
          //   (item) => item.photoFileType === attachementType
          // );
          // if (checkExists.length > 0) {
          //   updatedList.forEach((item) => {
          //     if (item.photoFileType === attachementType) {
          //       item.photo = fileObj?.photo;
          //       item.photoFileName = fileObj?.photoFileName;
          //     }
          //   });
          // } else {
          //   updatedList = [...attachment, fileObj];
          // }
          // setAttachment(updatedList);

          // let updatedList = [...inputFields, fileObj];
          // setInputFields(updatedList)
          setCurrAttch(fileObj);
          setSpinner(false);
        }
      }
    } catch (error) {
      setSpinner(false);
      console.error(error);
    }
  };

  const removeAttchement = (type) => {
    let updatedList = [...attachment];
    updatedList.splice(
      attachment.findIndex((item) => item.photoFileType === type),
      1
    );
    setAttachment(updatedList);
    document.getElementById(`attachement-${type}`).value = "";
  };

  // const showActionForm = () => {
  //   setModalConfig({
  //     size: "md",
  //     show: true,
  //     title: getActionTitle(actionType),
  //   });
  // };
  const handleModalClose = () => {
    closeActionForm();
    setAction(null);
    setInputFields([]);
    setFileInputDummyKey(Date.now());
    setCurrAttch(JSON.parse(JSON.stringify(initialAttachFields)));
    // cancelClaimForm();
  };
  const submitAction = async () => {
    const isFormValid = validateActionForm();
    if (!isFormValid) return;
    setSpinner(true);

    let payload = {
      claimId: claimDetails?.claimId,
      orderId: claimDetails?.orderId,
      palletId: claimDetails?.palletId,
      actionCode: actionType,
      claimType: claimDetails?.claimType,
      attachments: inputFields,
      claimDetail: claimDetails?.claimDtls,
      claimReason: claimDetails?.claimReason,
      assignSbu: CLAIM_ACTION.AssignedToOperation === actionType ? sbu : 0,
      assignToStaff: [
        CLAIM_ACTION.AssignedToOperation,
        CLAIM_ACTION.OtherOperationTeamFault,
      ].includes(actionType)
        ? staff
        : 0,
      remarks: remark,
      claimLhAmt:
        CLAIM_ACTION.FixClaimAmount === actionType ||
        CLAIM_ACTION.ProcessInsurance === actionType
          ? claimAmt
          : 0,
      claimCustAmt: claimDetails?.custAmt,
      // CLAIM_ACTION.AppealForClaimAmount === actionType ? claimAmt : 0,
      claimNo: claimDetails?.claimNo,
      actionBy: modalConfig?.actionBy,
    };
    if (!!partner) {
      const tempPartner = partnerList.find((pr) => pr.value === partner);
      payload.remarks = tempPartner.label + "#$" + payload.remarks;
    }

    let data = await CallApiPost("Claim", payload);
    if (!!data && data?.respCode === 200) {
      showAlert({
        isSuccess: true,
        size: "md",
        show: true,
        title: "",
        description: data?.respMessage,
      });
      // handleModalClose();
      setSpinner(false);
      reloadClaims();
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: data?.respMessage,
      });
      setSpinner(false);
    }
    handleModalClose();
  };

  const addMoreAttachement = () => {
    setInputFields([...inputFields, currAttch]);
    setCurrAttch(JSON.parse(JSON.stringify(initialAttachFields)));
    setFileInputDummyKey(Date.now());
  };

  const removeAttachement = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const actionFooterControls = (
    <>
      <button
        type="button"
        className="btn btn-default border"
        onClick={() => handleModalClose()}
      >
        Close
      </button>
      {!viewMode && (
        <button
          disabled={remark === null || remark.length === 0 ? true : false}
          type="button"
          className="btn btn-outline-secondary theme-violet"
          onClick={() => submitAction()}
        >
          Submit
        </button>
      )}
    </>
  );

  const downloadFileUsingURL = (url) => {
    window.open(url, "_blank");
  };

  const getDataByFault = (val) => {
    if (val === 2) {
      // setShowRemark(false)
      setFault(val);
      setSpinner(true);
      getPartnerList().then((resp) => {
        setSpinner(false);
      });
    } else {
      // setShowRemark(true)
      setFault(val);
      setPartner(null);
    }
    if (!!val) {
      setError({
        ...error,
        faultStatus: false,
      });
    }
  };

  const getPartnerList = async () => {
    let data = await CallApiGet(
      `partnerList?palletId=${claimDetails?.orderId}`
    );
    if (!!data && data.respCode === 200) {
      setPartnerList(data?.respData);
      setPartner(data?.respData[0].value);
    } else {
      setPartnerList([]);
      setPartner(null);
    }
  };

  const validateActionForm = () => {
    let isValid = true;
    // if (actionType === CLAIM_ACTION.MentionCustomerPartnerFault) {
    //   if (!!fault) {
    //     if (fault === 2) {
    //       if (typeof partner === "undefined" || partner === null) {
    //         setError({
    //           ...error,
    //           partnerStatus: true,
    //         });
    //         isValid = false;
    //       }
    //     }
    //   } else {
    //     isValid = false;
    //     setError({
    //       ...error,
    //       faultStatus: true,
    //     });
    //   }
    // } else
    if (
      (actionType === CLAIM_ACTION.ProcessInsurance ||
        actionType === CLAIM_ACTION.FixClaimAmount) &&
      (!!!claimAmt || +claimAmt <= 0)
    ) {
      setError({
        ...error,
        claimAmtSts: true,
      });
      isValid = false;
    }

    if (currAttch.photo !== null) {
      setError({
        ...error,
        claimAttchErr: true,
      });
      isValid = false;
    }
    resetErr();
    return isValid;
  };

  const handleClaimAmt = (argClaimAmt) => {
    setClaimAmt(argClaimAmt);
    if (error?.claimAmtSts === true) {
      setError({
        ...error,
        claimAmtSts: false,
      });
    }
  };
  const resetErr = () => {
    setTimeout(() => setError(JSON.parse(JSON.stringify(errObj))), 5000);
  };
  return (
    <>
      {!!modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
          footer={actionFooterControls}
        >
          {/* CUSTOMER REQUIREMENT TO REMOVE ASSIGN TO OPERATION FOR MentionCustomerPartnerFault */}
          {/* {!viewMode &&
            CLAIM_ACTION.MentionCustomerPartnerFault === actionType && (
              <div className="row">
                <>
                  <div className="col-md-12 mb-2">
                    <label className="form-label theme-label">
                      Assign To <span className="text-danger">&#9733;</span>
                    </label>
                    <div>
                      <Select
                        options={faultList}
                        placeholder="Select Customer/Driver/Other SBU"
                        value={
                          fault === ""
                            ? ""
                            : faultList.find((opt) => opt.value === fault)
                        }
                        onChange={(d) => getDataByFault(d ? d.value : d)}
                        isSearchable={true}
                        // isClearable={true}
                        defaultValue={faultList[0]}
                        className="form-control  p-0"
                      />
                    </div>
                    {!!error && error?.faultStatus && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!
                        </span>
                      </div>
                    )}
                  </div>
                  {fault === 2 && (
                    <div className="col-md-12 mb-2">
                      <label className="form-label theme-label">
                        Driver <span className="text-danger">&#9733;</span>
                      </label>
                      <div>
                        <Select
                          options={partnerList}
                          placeholder="Select Driver"
                          value={
                            partner === ""
                              ? ""
                              : partnerList.find((opt) => opt.value === partner)
                          }
                          onChange={(d) => {
                            setPartner(d ? d.value : d);
                            setError({ ...error, partnerStatus: false });
                            // setRemark(d ? "Partners Fault: " + d.label : "N/A")
                          }}
                          isSearchable={true}
                          // isClearable={true}
                          defaultValue={partnerList[0]}
                          className="form-control  p-0"
                        />
                      </div>
                      {!!error && error?.partnerStatus && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field is required!!
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              </div>
            )} */}
          {!viewMode &&
            !!sbus &&
            sbus.length > 0 &&
            (CLAIM_ACTION.AssignedToOperation === actionType ||
              (CLAIM_ACTION.MentionCustomerPartnerFault === actionType &&
                fault === 3)) && (
              // [
              //   CLAIM_ACTION.AssignedToOperation,
              //   CLAIM_ACTION.MentionCustomerPartnerFault,
              //   CLAIM_ACTION.OtherOperationTeamFault,
              // ].includes(actionType)
              <div className="row">
                <div className="col-md-12 mb-2">
                  <label className="form-label theme-label">
                    SBU
                    <span className="text-danger">&#9733;</span>
                  </label>
                  <div>
                    <Select
                      options={sbus}
                      placeholder="Select SBU"
                      value={
                        sbu === "" ? "" : sbus.find((opt) => opt.value === sbu)
                      }
                      onChange={(d) => setSbu(d ? d.value : d)}
                      isSearchable={true}
                      // isClearable={true}
                      defaultValue={sbus[0]}
                      className="form-control  p-0"
                    />
                  </div>
                </div>
              </div>
            )}
          {/* {!viewMode &&
            [
              CLAIM_ACTION.AssignedToOperation,
              // CLAIM_ACTION.MentionCustomerPartnerFault,
              CLAIM_ACTION.OtherOperationTeamFault,
            ].includes(actionType) &&
            (!!staffs && staffs.length > 0 ? (
              <div className="row">
                <div className="col-md-12 mb-2">
                  <label className="form-label theme-label">
                    Assign To <span className="text-danger">&#9733;</span>
                  </label>
                  <div>
                    <Select
                      options={staffs}
                      placeholder="Select Role"
                      value={
                        staff === ""
                          ? ""
                          : staffs.find((opt) => opt.value === staff)
                      }
                      onChange={(d) => setStaff(d ? d.value : d)}
                      isSearchable={true}
                      // isClearable={true}
                      defaultValue={staffs[0]}
                      className="form-control  p-0"
                    />
                  </div>
                </div>
              </div>
            ) : (
              !!sbu && <Badge bg="danger">No Operator Available</Badge>
            ))} */}

          {/* {[
            CLAIM_ACTION.InformationSubmitted,
          ].includes(actionType) && (
              <>
                <Row>
                  <Col><label className="form-label theme-label">Requested Attachments</label></Col>
                </Row>
                {
                  attachment.map((attch, index) => (
                    <Row>
                      <div className="d-flex flex-row justify-content-between mb-2" key={index}>
                        <span>{attch?.filename.split('/').pop()}</span>
                        <span onClick={() => downloadFileUsingURL(attch?.filename)}>
                          {GetSvgIcon("BiArrowDownCircleFill")}</span>
                      </div>
                    </Row>
                  ))
                }
              </>
              )} */}

          {[
            CLAIM_ACTION.AcceptClaimAmount,
            CLAIM_ACTION.AppealForClaimAmount,
            CLAIM_ACTION.FixClaimAmount,
            CLAIM_ACTION.ProcessInsurance,
            CLAIM_ACTION.ProcessPayment,
          ].includes(actionType) && (
            <>
              <div className="row">
                {!!claimDetails?.lhAmt && claimDetails?.lhAmt > 0 && (
                  <div className="col-md-6 mb-2">
                    <label className="form-label theme-label">
                      Offer Claim Amount
                    </label>
                    <div>{(claimDetails?.lhAmt).toFixed(2)} RM</div>
                  </div>
                )}
                {!!claimDetails?.custAmt && claimDetails?.custAmt > 0 && (
                  <div className="col-md-6 mb-2">
                    <label className="form-label theme-label">
                      Requested Claim Amount
                    </label>
                    <div>{(claimDetails?.custAmt).toFixed(2)} RM</div>
                  </div>
                )}
                {CLAIM_ACTION.AppealForClaimAmount === actionType && (
                  <div className="col-md-12 mb-1 fw-medium text-center">
                    Do you want to appeal for more compensation?
                    <br />
                    It may take 1-7 days to review{" "}
                  </div>
                )}
              </div>
              {!viewMode &&
                [
                  // CLAIM_ACTION.AppealForClaimAmount,
                  CLAIM_ACTION.FixClaimAmount,
                  CLAIM_ACTION.ProcessInsurance,
                ].includes(actionType) && (
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <label className="form-label theme-label">
                        Approved Claim Amount{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handleClaimAmt(e.target.value)}
                        />
                      </div>
                      {!!error && error?.claimAmtSts && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field is required!!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </>
          )}

          {!viewMode && showRemark && (
            <>
              {CLAIM_ACTION.AppealForClaimAmount !== actionType && (
                <>
                  <div className="row">
                    <div>
                      <label className="form-label theme-label">
                        Upload Attachment
                      </label>
                      <div className="clearfix"></div>
                      <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
                      <label
                        className="form-label font-12 text-danger"
                        htmlFor=""
                      >
                        To upload a file for submission, choose it and click the
                        add button.
                      </label>
                    </div>
                    <div className="input-group">
                      <label className="form-control attachment-label">
                        <span className=" InputTypeFile">
                          {!!currAttch.photoFileName
                            ? currAttch?.photoFileName
                            : "Choose a file..."}
                        </span>
                        <input
                          type="file"
                          readOnly={viewMode}
                          name="attachDetails"
                          className="UploadFilesty"
                          aria-describedby="button-addon2"
                          accept="application/pdf, image/jpeg, image/jpg"
                          onChange={(e) =>
                            handelFileSelect(e, CLAIM_ATTACHMENT.GENERAL, "GEN")
                          }
                          key={fileInputDummyKey}
                        />
                      </label>
                      <button
                        className="btn btn-outline-secondary theme-violet"
                        type="button"
                        onClick={() => addMoreAttachement()}
                        disabled={!!!currAttch.photoFileName}
                      >
                        ADD
                      </button>
                    </div>
                    {!!error && error?.claimAttchErr && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; Please Click On The Add Button!!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="row mt-1">
                    {!!inputFields &&
                      Array.isArray(inputFields) &&
                      inputFields.length > 0 &&
                      inputFields.map((inp, index) => (
                        <div
                          className="d-flex flex-row justify-content-between mb-2"
                          key={index}
                        >
                          <span>{inp?.photoFileName}</span>
                          <span onClick={() => removeAttachement(index)}>
                            {GetSvgIcon("Removecircle")}
                          </span>
                        </div>
                      ))}
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-md-12 mb-2">
                  <label className="form-label theme-label">
                    {CLAIM_ACTION.AppealForClaimAmount === actionType
                      ? "Reason For Appeal"
                      : "Remarks"}{" "}
                    <span className="text-danger">&#9733;</span>
                  </label>
                  <div>
                    <textarea
                      className="form-control"
                      onChange={(e) => setRemark(e.target.value)}
                      maxlength="200"
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalLayout>
      )}
    </>
  );
};
export default ClaimAction;

import React, { useState } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  DATE_FORMAT_CLIENT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import { Tooltip } from "react-tooltip";
import GetSvgIcon, { GetSvgTruck } from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  CHARTERED_RATE_WEIGHT_ID,
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_STAFF_USER_TYPE,
  SCHCHROUTESCHEDULE,
} from "../../../ConfigConstants/ConfigConstants";
import { permissionCheck } from "../../Helper/CommonMethods";
import moment from "moment";

const invalidDataArr = [null, undefined, "", "null", "undefined"];

const ScheduleList = ({
  scheduleList,
  onSubmit,
  searchParams,
  handleChange,
  openRouteDetls,
  handleBtnClick,
  openModalForWgt,
}) => {
  const userType = getUserInfo("userType");
  const BookBtn = ({ row }) => (
    <>
      <div
        data-tooltip-id={"plan-" + row?.planId}
        // onClick={() => handlePlanBtnClick(row)}
        onClick={() => handleBtnClick(row)}
        className="theme-violet-txt"
      >
        <span className="text-success">Confirm {GetSvgIcon("IconTruck")}</span>
      </div>
      <Tooltip
        id={"plan-" + row?.planId}
        content={"Chartered Booking"}
        place="left"
      />
    </>
  );

  const AddWeightBtn = ({ row }) => {
    const isOrdConfirm = row?.isConfirm;
    return (
      <>
        {!isOrdConfirm && (
          <>
            <div
              data-tooltip-id={"plan-" + row?.planId}
              onClick={() => openModalForWgt(row)}
              className="theme-violet-txt"
            >
              {GetSvgIcon("BasketFill")}
            </div>
            <Tooltip
              id={"plan-" + row?.planId}
              content={"Add Weight"}
              place="left"
            />
          </>
        )}
      </>
    );
  };

  //Add Truck Button
  const AddTruckBtn = ({ row }) => {
    return (
      <>
        {[LINEHAUL_ADMIN_USER_TYPE, LINEHAUL_STAFF_USER_TYPE].includes(
          userType
        ) &&
          row.isTruckAdded === false &&
          row.isSecondary === false && (
            <>
              <div
                data-tooltip-id={"add-plan-" + row?.planId}
                onClick={() => openRouteDetls(row, "A")}
                className="theme-violet-txt ps-2"
              >
                {GetSvgTruck(row)}
              </div>
              <Tooltip
                id={"add-plan-" + row?.planId}
                content={"Add Truck"}
                place="left"
              />
            </>
          )}
      </>
    );
  };

  const ConditionalBtn = ({ row }) => {
    let btnHtml = <></>;
    const today = moment().startOf("day");
    const isPlanInPast = moment(
      row?.tripStart,
      "DD-MM-YYYY HH:mm",
      true
    ).isAfter(today);
    if (
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) &&
      !!row.rateCode &&
      !row.order_id &&
      row?.status === 572
      && isPlanInPast
    ) {
      btnHtml = <BookBtn row={row} />;
    }

    if (
      [LINEHAUL_ADMIN_USER_TYPE, LINEHAUL_STAFF_USER_TYPE].includes(userType) &&
      row.rateType === CHARTERED_RATE_WEIGHT_ID &&
      row?.status !== 574
    ) {
      btnHtml = <AddWeightBtn row={row} />;
    }
    return btnHtml;
  };

  const RouteCodeBtn = ({ data }) => {
    let retHtml = <></>;
    if (permissionCheck(SCHCHROUTESCHEDULE, "allowedWrite")) {
      retHtml = (
        <div
          onClick={() => openRouteDetls(data, "E")}
          className="modal-links fw-bold"
        >
          {data?.routeCode || <span className="fw-bold">No Route Code</span>}
        </div>
      );
    } else {
      retHtml = <span className="fw-bold">{data?.routeCode}</span>;
    }
    return retHtml;
  };

  const columns = [
    {
      name: "Route Code",
      selector: (row) => row?.routeCode,
      cell: (row) => <RouteCodeBtn data={row} />,
      sortable: true,
      filterable: true,
    },
    {
      name: "Departure",
      selector: (row) => row?.tripStart,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Dedicated To",
    //   selector: (row) => row?.acctCode,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Customer",
      selector: (row) =>
        invalidDataArr.includes(row?.bussName)
          ? `${row?.custName} (${row?.acctCode})`
          : `${row?.bussName} (${row?.acctCode})`,
      sortable: true,
      filterable: true,
    },
    {
      name: "Rate Type",
      selector: (row) => row?.rateTypeTxt,
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle Type (Ton)",
      selector: (row) =>
        row?.vhclType + (!!row?.vhclWeight ? ` (${row?.vhclWeight})` : ""),
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row?.vhclNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Weight",
      selector: (row) =>
        row.rateType === CHARTERED_RATE_WEIGHT_ID &&
        !!row.serviceValue &&
        +row.serviceValue > 0
          ? row.serviceValue
          : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "Man Power",
      selector: (row) =>
        !!row.manpowerSvcVal && +row.manpowerSvcId > 0
          ? row.manpowerSvcVal
          : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "Drivers",
      selector: (row) =>
        !!row?.driver1 && !!row?.driver2
          ? row?.driver1 + "/" + row?.driver2
          : row?.driver1 || row?.driver2,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Ton",
    //   selector: (row) => (!!row?.vhclWeight ? row?.vhclWeight : ""),
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Booking Status",
      selector: (row) => (!!row.order_id ? "BOOKED" : ""),
      cell: (row) =>
        !!row.order_id && <span className="fw-bold text-success">BOOKED</span>,
      sortable: true,
      filterable: true,
    },
    {
      name: "Plan Status",
      selector: (row) => row?.statusTxt,
      sortable: true,
      filterable: true,
    },
    {
      name: "Order No",
      selector: (row) => row?.orderNo,
      sortable: true,
      filterable: true,
    },

    {
      name: "Remarks",
      selector: (row) => row?.remarks,
      sortable: true,
      filterable: true,
      cell: (row) => <code className="address-style p-1">{row?.remarks}</code>,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <ConditionalBtn row={row} />
          <AddTruckBtn row={row} />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "60px",
    },
    // {
    //   name: "",
    //   cell: (row) => <AddTruckBtn row={row} />,
    //   allowOverflow: true,
    //   button: true,
    //   width: "30px",
    // },
  ];

  return (
    <>
      <div className="col-md-12">
        <div className="row">
          <div className="col-auto">
            <label htmlFor="" className="form-label theme-label">
              Trip From
            </label>
            <div>
              <ReactDatePicker
                className="form-control"
                selected={
                  searchParams?.dateFrom
                    ? new Date(searchParams?.dateFrom)
                    : searchParams?.dateFrom
                }
                onChange={(d) => handleChange("dateFrom", d)}
                dateFormat={DATE_FORMAT_CLIENT}
                placeholderText="Select From"
                maxDate={searchParams?.dateTo && new Date(searchParams?.dateTo)}
              />
            </div>
          </div>

          <div className="col-auto">
            <label htmlFor="" className="form-label theme-label">
              Trip To
            </label>
            <div>
              <ReactDatePicker
                className="form-control"
                selected={
                  !!searchParams?.dateTo
                    ? new Date(searchParams?.dateTo)
                    : searchParams?.dateTo
                }
                onChange={(d) => handleChange("dateTo", d)}
                dateFormat={DATE_FORMAT_CLIENT}
                placeholderText="Select To"
                minDate={
                  searchParams?.dateFrom
                    ? new Date(searchParams?.dateFrom)
                    : new Date()
                }
              />
            </div>
          </div>
          <div className="col-md-1">
            <div className="mt-1">
              <label htmlFor="" className="form-label theme-label"></label>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={onSubmit}
              disabled={
                invalidDataArr.includes(searchParams?.dateFrom) ||
                invalidDataArr.includes(searchParams?.dateTo)
              }
            >
              Search{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={scheduleList}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
        />
      </div>
    </>
  );
};

export default ScheduleList;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import Header from "../Layout/Header";
import AddDiscount from "./AddDiscount";
import DiscountList from "./DiscountList";
import { initValDiscounts } from "./initValDiscount";
import Popup from "../Layout/Popup";
import EditDiscount from "./EditDiscount";

const Discount = () => {
  const modalRef = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [discountRows, setDiscountRows] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([
    {
      id: 0,
      code: "",
    },
  ]);
  const initialThreshold = [
    {
      id: 0,
      discountId: 0,
      limitFrom: "",
      limitTo: "",
      discount: "",
    },
  ];
  const initThresholdRowError = {
    limitFrom: false,
    limitTo: false,
    discount: false,
  };
  const initError = {
    required: {},
    fieldVaidation: {},
  };
  const [thresholdRowsData, setThresholdRowsData] = useState(initialThreshold);

  const [discount, setDiscount] = useState(initValDiscounts);

  const [errorMsgs, setErrorMsgs] = useState(initError);
  useEffect(() => {
    fetchAllDiscountList();
    fetchDiscountTypes();
  }, []);

  const fetchAllDiscountList = async () => {
    let data = await CallApiGet("list-discount");
    setDiscountRows(data.respData ? data.respData : []);
  };
  const fetchDiscountTypes = async () => {
    let discoutTypeArr = [];
    let data = await CallApiPost("SysOptionGetByCode", ["DISCOUNT_TYPE"]);
    data.respData.forEach((element) => {
      discoutTypeArr.push({
        value: element.id,
        label: element.optionValue,
      });
    });
    setDiscountTypes(discoutTypeArr);
  };
  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
    fetchAllDiscountList();
  };

  const getDiscountDtls = async (data) => {
    data = await fetchThreshold(data);
    setDiscount(data);
  };

  const fetchThreshold = async (data) => {
    let res = await CallApiGet("list-discount-rates?discountId=" + data.id);

    if (res.respCode === 0) {
      data["threshold"] = res.respData;

      console.log("Data length: ", data.length);
    } else {
      data["threshold"] = [];
    }
    return data;
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Discount Configuration</div>
              </div>
              <div className="float-end ">
                <div>
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#button-modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>{" "}
                    ADD DISCOUNT
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <div>
              <DiscountList
                getDiscountDetails={getDiscountDtls}
                discountRows={discountRows}
              />

              <AddDiscount
                discountTypes={discountTypes}
                discount={discount}
                rowsData={thresholdRowsData}
                setRowsData={setThresholdRowsData}
                setDiscount={setDiscount}
                setErrorMsgs={setErrorMsgs}
                initError={initError}
                initialThreshold={initialThreshold}
                initThresholdRowError={initThresholdRowError}
                onSuccess={onSuccess}
              />
              <EditDiscount
                discountTypes={discountTypes}
                discount={discount}
                initialThreshold={initialThreshold}
                initThresholdRowError={initThresholdRowError}
                setErrorMsgs={setErrorMsgs}
                initError={initError}
                onSuccess={onSuccess}
                setDiscountData={setDiscount}
              />
            </div>
          </main>
        </div>
        <Popup isSuccess={isSuccess} title={title} description={description} />
      </div>
    </>
  );
};

export default Discount;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  monthDropdown
} from "../Helper/Constants";
import { Card } from "react-bootstrap";
// import CommissionSearch from "./CommissionSearch";


const IsrCommissionDetailsModal = (props) => {
  const { columns, commissionData, isIsr } = props;

  return (
    <div className="">
      {!isIsr && (
        <Card className="theme-violet" >
          <Card.Body>
            <div className="container text-center">
              <div className="row">
                <div className="col-md-3 ">ISR : <span className="fw-bold ">{commissionData?.isr}</span></div>
                <div className="col-md-3">Month : <span className="fw-bold ">{monthDropdown.find(mon => mon.value === commissionData?.month)?.label}</span></div>
                <div className="col-md-3">Year : <span className="fw-bold ">{commissionData?.year}</span></div>
                <div className="col-md-3">Total Commission : <span className="fw-bold ">{commissionData?.totalComm} RM</span></div>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
      
      <div className="col-md-12 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={commissionData?.details}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
        />
      </div>
    </div>
  );
};

export default IsrCommissionDetailsModal;

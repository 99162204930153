import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { BTN_TYPE_ADD, REQUIRED_ERR_MSG } from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
import FeatureList from "./FeatureList";

const RolesSave = (props) => {
  const {
    sbuTypeList,
    features,
    onSuccess,
    setShow,
    onError,
    role,
    btnType,
    clear,
    roleOptions,
    unauthorized,
  } = props;

  const {
    register,
    control,
    setValue,
    reset,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    role.permissions = features;
    reset(role);
  }, [features, role]);

  const { fields: permissionsArr } = useFieldArray({
    control,
    name: "permissions",
  });

  const formError = (err) => {
    console.log("===ERROR===");
    console.log(err);
  };

  const onSubmit = async (formData) => {
    setShow(true);
    const data = await CallApiPost("save-sys-role", formData);
    if (data.respCode === 200) {
      setShow(false);
      clear();
      onSuccess("Roles saved successfully !!!");
    } else if (data.respCode === 401) {
      unauthorized("Sorry, you don't have the write permission!!");
    } else {
      clear();
      setShow(false);
      onError();
    }
  };

  const resetRole = () => {
    reset(role);
  };

  return (
    <div
      className="modal fade"
      id="add-role"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Add Roles
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clear}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-md-4 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Role Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("roleName", {
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          maxLength: {
                            value: 41,
                            message: "Limit: 41 characters",
                          },
                        })}
                      />
                      {errors.roleName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.roleName.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Sbu Type
                      </label>
                      <Controller
                        name="sbuTypeId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={sbuTypeList}
                            placeholder="Select Sbu Type"
                            value={
                              value === ""
                                ? ""
                                : sbuTypeList.find((opt) => opt.value === value)
                            }
                            onChange={(d) => onChange(d ? d.value : d)}
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                          />
                        )}
                      />
                      {errors.sbuTypeId && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.sbuTypeId.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Role Creator
                      </label>
                      <Controller
                        name="roleCreator"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={roleOptions}
                            placeholder="Select Role Creator"
                            value={roleOptions.filter((opt) =>
                              value.includes(opt.value)
                            )}
                            onChange={(d) =>
                              onChange(d ? d.map((opt) => opt.value) : [])
                            }
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                            className="form-control  p-0"
                          />
                        )}
                      />
                      {errors.roleCreator && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.roleCreator.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Remarks
                      </label>
                      <textarea
                        name="remarks"
                        className="form-control"
                        {...register("remarks", {
                          maxLength: {
                            value: 101,
                            message: "Limit: 101 characters",
                          },
                        })}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="fw-bold mt-4 theme-violet-txt border-bottom">
                      Features And permissions
                    </div>
                    {/* <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Module</th>
                          <th scope="col">Feature</th>
                          <th scope="col">Read</th>
                          <th scope="col">Write</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody className="overflow-auto">
                        {permissionsArr.map((item, index) => (
                          <tr key={index}>
                            <td>{item.module}</td>
                            <td>{item.featureName}</td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions.${index}.allowRead`}
                                {...register(`permissions.${index}.allowRead`)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions.${index}.allowWrite`}
                                {...register(`permissions.${index}.allowWrite`)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions.${index}.allowDelete`}
                                {...register(
                                  `permissions.${index}.allowDelete`
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>  */}
                    <FeatureList
                      register={register}
                      permissionsArr={permissionsArr}
                      setValue={setValue}
                      watch={watch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              onClick={btnType === BTN_TYPE_ADD ? clear : resetRole}
            >
              {btnType === BTN_TYPE_ADD ? "Clear" : "Reset"}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(onSubmit, formError)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesSave;

import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import GetSvgIcon from "../../images/svglist";
import { CallApiPost } from "../Helper/serviceApi";
import moment from "moment";
import { DATE_FORMAT_SERVER, DATE_FORMAT_CLIENT, shipmentViewStatus } from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";

const ShipmentViewReassignVehicle = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let { waitingList, vehicleList, shipmentList, origin, tripDate } =
    location?.state;
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const pageTitle = `Vehicle Reassignment`;
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [waybills, setWaybills] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [vehciles, setVehciles] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [selectedWaybills, setSelectedWaybills] = useState([]);
  const SHIP_STATUS_DEL_PLANNED = "Delivery Planned";
  const SHIP_STATUS_DEL_WAITING = "Waiting";
  const [tripDateRP, setTripDateRP] = useState(new Date());
  const ROUTE_TYPES = "27,28,29";
  const [vlenth, setVlenth] = useState();
  const [stops, setStops] = useState();

  useEffect(() => {
    getVehiclesByDate(tripDateRP);
  }, []);

  useEffect(() => {
    if (!!vehicleList && vehicleList.length > 0) {
      setVehciles(vehicleList);
    }
    if (!!waitingList && waitingList.length > 0) {
      setWaybills(waitingList);
    }
    if (!!shipmentList && shipmentList.length > 0) {
      setShipments(shipmentList);
    }
    setVlenth(vehicleList.length);
    setStops(vehicleList[vehicleList.length - 1]);
  }, [vehicleList, waitingList, shipmentList]);

  const handelOnSelect = (event, item) => {
    var updatedList = [...selectedWaybills];
    if (event.target.checked) {
      updatedList = [...selectedWaybills, item.waybill_no];
    } else {
      updatedList.splice(selectedWaybills.indexOf(item.waybill_no), 1);
    }
    setSelectedWaybills(updatedList);
  };

  const selectAll = (event) => {
    if (event.target.checked) {
      setSelectedWaybills(waybills.map((item) => item.waybill_no));
    } else {
      setSelectedWaybills([]);
    }
  };

  const resetSelection = () => {
    setVehicle(null);
    setSelectedWaybills([]);
    vehciles.forEach((item) => {
      item.waybills = item.waybills.filter(
        (el) => el.status_id === shipmentViewStatus.planned
      );
      item.calculatedBooked = item.waybills.length;
    });
    setVehciles(vehciles);
    setWaybills(waitingList);
  };

  const handleReassign = async () => {
    let reAssignedData = [];
    if (!!vehciles && vehciles.length > 0) {
      vehciles.forEach((item) => {
        item?.waybills.forEach((el) => {
          if (el.status_id === shipmentViewStatus.waiting) {
            el["n_plan_id"] = item.plan_id;
            reAssignedData.push(el);
          }
        });
      });
    }
    let payload = [...reAssignedData].map((item) => {
      return {
        pallet_id: item.pallet_id,
        plan_id: item.plan_id,
        n_plan_id: item.n_plan_id,
        originid: item.originid,
        destinid: item.destinid,
        route_id: item.route_id,
      };
    });
    setSpinner(true);
    if (reAssignedData.length > 0) {
      let data = await CallApiPost("reAssignVehicle", payload);
      if (!!data && (data.respCode === 200 || data.respCode === 0)) {
        showAlert({
          isSuccess: true,
          title: "",
          description: "Vehicle Reassigned Successfully",
        });
        setSpinner(false);
        setTripDateRP(new Date(tripDate));
        getShipmentList();
        setTimeout(() => {
          getShipmentList();
        }, 10000);
      } else if (data.respCode === 500) {
        showAlert({
          isSuccess: false,
          title: "",
          description: "!!Something went wrong",
        });
        setSpinner(false);
      } else {
        showAlert({
          isSuccess: false,
          title: "",
          description: data.respData[0]?.RetMessage,
        });
        setSpinner(false);
      }
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: "No Data For Reassignment",
      });
      setSpinner(false);
    }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const navigatBack = () => {
    navigate("/shipmentView");
  };
  const removeItem = (element) => {
    vehicle.waybills = [...vehicle.waybills].filter(
      (item) => item.waybill_no !== element.waybill_no
    );
    vehicle.calculatedBooked = vehicle.waybills.length;
    setVehciles(
      [...vehciles].map((item) =>
        item.plan_id === vehicle.plan_id ? (item = vehicle) : item
      )
    );
    setWaybills([...waybills, element]);
  };
  const assignItem = () => {
    if (validateRoute()) {
      vehicle.waybills = [...shipments]
        .filter((item) => selectedWaybills.includes(item.waybill_no))
        .concat(vehicle.waybills);
      vehicle.calculatedBooked = vehicle.waybills.length;
      setVehciles(
        [...vehciles].map((item) =>
          item.plan_id === vehicle.plan_id ? (item = vehicle) : item
        )
      );
      setWaybills([
        ...waybills.filter((el) => !selectedWaybills.includes(el.waybill_no)),
      ]);
      setSelectedWaybills([]);
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: "Invalid Route",
      });
    }
  };
  const validateRoute = () => {
    // let unmatchedOAD = 0
    // unmatchedOAD = [...shipments].filter(item => selectedWaybills.includes(item.waybill_no))
    //                              .filter(el=> el?.origin!==vehicle?.origin
    //                                 || el?.destination!==vehicle?.destination).length
    // return unmatchedOAD===0?true:false
    return true;
  };
  const toggleVehicle = (item) => {
    if (item?.plan_id !== vehicle?.plan_id) {
      setVehicle(item);
    } else {
      setVehicle(null);
    }
  };

  const getShipmentList = async () => {
    let payload = {
      origin: origin,
      tripDate: moment(tripDate).format(DATE_FORMAT_SERVER),
      fromDate: moment(tripDate).format(DATE_FORMAT_SERVER),
      toDate: moment(tripDate).format(DATE_FORMAT_SERVER),
      routeType: ROUTE_TYPES,
    };
    setSpinner(true);
    let data = await CallApiPost("getShipmentsByOriginTrackStartDate", payload);
    if (!!data && data.respCode === 200) {
      if (
        data.respData.filter(
          (item) =>
            item.status_id === shipmentViewStatus.waiting && item.originid == origin
        ).length === 0
      ) {
        navigatBack();
      }
      setShipments(data.respData.filter((el) => (el.originid = origin)));
      setWaybills(
        data.respData.filter(
          (item) =>
            item.status_id === shipmentViewStatus.waiting && item.originid == origin
        )
      );
      setVehciles(
        [...vehciles].map((item) => {
          return {
            ...item,
            calculatedBooked: data.respData.filter(
              (el) =>
                el.plan_id === item.plan_id &&
                el.status_id === shipmentViewStatus.planned
            ).length,
            waybills: data.respData.filter(
              (el) =>
                el.plan_id === item.plan_id &&
                el.status_id === shipmentViewStatus.planned
            ),
          };
        })
      );
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const getVehiclesByDate = async (replanDate) => {
    let payload = {
      origin: origin,
      fromDate: moment(replanDate).format(DATE_FORMAT_SERVER),
      toDate: moment(replanDate).format(DATE_FORMAT_SERVER),
      tripDate: moment(replanDate).format(DATE_FORMAT_SERVER),
      routeType: ROUTE_TYPES,
    };
    setSpinner(true);
    let data = await CallApiPost("getShipmentsByOriginTrackStartDate", payload);

    if (!!data && data.respCode === 200) {
      getPlannedVehicles(data.respData, payload);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const getPlannedVehicles = async (shipmentList, payload) => {
    let shipmentStatus = "Delivery Planned";
    setSpinner(true);
    let data = await CallApiPost("GetVehicleListByTripDateAndOrigin", payload);
    if (!!data && data.respCode === 200) {
      let mVehicleList = data.respData.map((item) => {
        return {
          ...item,
          calculatedBooked: shipmentList.filter(
            (el) => item.plan_id === el.plan_id && el.status_id === shipmentViewStatus.planned
          ).length,
          waybills: shipmentList.filter(
            (el) => item.plan_id === el.plan_id && el.status_id === shipmentViewStatus.planned
          ),
        };
      });
      setVehciles(mVehicleList);
      setVlenth(mVehicleList.length);
      setStops(mVehicleList[mVehicleList.length - 1]);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const handleTripDate = (tDate) => {
    setWaybills(waitingList);
    setVehicle(null);
    setTripDateRP(tDate !== null ? new Date(tDate) : "");
    getVehiclesByDate(tDate);
  };

  const validateReAssignData = () => {
    let reAssignedData = [];
    vehciles.forEach((item) => {
      item?.waybills.forEach((el) => {
        if (el.status_id === shipmentViewStatus.waiting) {
          reAssignedData.push(el);
        }
      });
    });
    return reAssignedData.length > 0 ? true : false;
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <div className="col-xl-12 mt-3 pb-2">
          <>
            <div className="d-flex">
              <div className="col-4 border border-2 rounded p-0">
                <div className="theme-blue fw-bold fs-5 border border-bottom-1 p-2 rounded-top">
                  Waybills
                </div>
                {!!waybills && waybills.length > 0 ? (
                  <div className="overflow-auto min-ht-60 max-ht-60 p-3">
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                waybills.length === selectedWaybills.length
                              }
                              onChange={(e) => selectAll(e)}
                            />
                          </th>
                          <th scope="col">Waybill No</th>
                          <th scope="col">Weight</th>
                          <th scope="col">Origin</th>
                          <th scope="col">Destination</th>
                        </tr>
                      </thead>
                      <tbody>
                        {waybills.map((item, wbIndex) => (
                          <tr key={wbIndex}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedWaybills.includes(
                                  item.waybill_no
                                )}
                                onChange={(e) => handelOnSelect(e, item)}
                              />
                            </td>
                            <td>{item.waybill_no}</td>
                            <td>{item.weight}</td>
                            <td>{item.origin}</td>
                            <td>{item.destination}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="noData">No Waybills Available</div>
                )}
              </div>
              <div className="col-1 align-self-center text-center">
                <button
                  className="btn btn-outline-secondary theme-violet"
                  type="button"
                  disabled={!(!!vehicle && selectedWaybills.length > 0)}
                  onClick={() => assignItem()}
                >
                  {GetSvgIcon("ArrowRight")}
                </button>
              </div>
              <div className="col border border-2 rounded p-0">
                <div className="theme-blue fw-bold fs-5 border border-bottom-1 p-2 rounded-top">
                  Vehicles
                </div>
                <div className="p-3">
                  <b>Trip Date </b> :&nbsp;
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    onFocus={(e) => e.target.blur()}
                    selected={tripDateRP}
                    minDate={new Date()}
                    onChange={(d) => handleTripDate(d)}
                    onSelect={(d) => handleTripDate(d)}
                  />
                </div>
                <div className="p-3 overflow-auto min-ht-60 max-ht-60">
                  {vehciles.length > 0 ? (
                    vehciles.map(
                      (item, vIndex) =>
                        vlenth - 1 > vIndex && (
                          <div key={vIndex}>
                            <div className="border border-2 rounded-2 mb-3">
                              <div
                                className={
                                  !!vehicle && vehicle?.plan_id === item.plan_id
                                    ? "wt-tbl p-2"
                                    : "p-2"
                                }
                              >
                                <div
                                  className="d-flex w-100 justify-content-between align-items-center cursor-pointer"
                                  onClick={() => toggleVehicle(item)}
                                >
                                  <div className="col-2 align-self-center text-truncate">
                                    {GetSvgIcon("Truck")}{" "}
                                    <span className="fw-bold d-inline">
                                      {item.vhcl_no}
                                    </span>
                                    <br />
                                    {GetSvgIcon("History")}{" "}
                                    <span className="font-12">
                                      {item.trip_time}
                                    </span>
                                  </div>
                                  {/* <div className="col-2 align-self-center">{GetSvgIcon("History")} <span className="fw-bold">{item.trip_time}</span></div> */}
                                  <div className="col-3 align-self-center">
                                    {GetSvgIcon("BiGeoAltFillInfo")}{" "}
                                    <span className="fw-bold">
                                      {item.route_code}
                                    </span>
                                  </div>
                                  <div className="col-4 align-self-center">
                                    {GetSvgIcon("BiGeoAltFillInfo")}{" "}
                                    <span className="fw-bold">
                                      {stops[item.route_id]}
                                    </span>
                                  </div>
                                  <div className="col-2 text-end">
                                    <span className="fw-bold">
                                      {item.booked}/{item.capacity}
                                    </span>
                                  </div>
                                  {/* <div className="col-2 align-self-center">{GetSvgIcon("Booked")} Booked<span className="fw-bold">({item.calculatedBooked})</span></div> */}
                                  <div className="col-auto text-end theme-blue-txt fw-bold">
                                    {!!vehicle &&
                                    vehicle?.plan_id === item.plan_id
                                      ? GetSvgIcon("Checkborderedcircle")
                                      : GetSvgIcon("Borderedcircle")}
                                  </div>
                                </div>
                              </div>

                              {item.waybills.length > 0 &&
                                vehicle?.plan_id === item.plan_id && (
                                  <div className="d-flex w-100 justify-content-between p-3">
                                    <table className="table table-responsive table-striped table-hover">
                                      <thead>
                                        <tr>
                                          <th
                                            colSpan={5}
                                            className="ps-0 theme-blue-txt"
                                          >
                                            Assigned Waybills
                                          </th>
                                        </tr>
                                        <tr>
                                          <th scope="col">Sl</th>
                                          <th scope="col">Waybill No</th>
                                          <th scope="col">Weight</th>
                                          <th scope="col">Destination</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.waybills.map((el, elIndex) => (
                                          <tr key={elIndex}>
                                            <td>{elIndex + 1}</td>
                                            <td>{el.waybill_no}</td>
                                            <td>{el.weight}</td>
                                            <td>{el.destination}</td>
                                            <td className="text-danger">
                                              {el.status_id !==
                                                shipmentViewStatus.planned && (
                                                <div
                                                  onClick={() => removeItem(el)}
                                                >
                                                  {GetSvgIcon(
                                                    "DashCircleNoFill"
                                                  )}
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                            </div>
                          </div>
                        )
                    )
                  ) : (
                    <div class="d-grid gap-5">
                      <div class="px-5 p-2 bg-light border">
                        <strong>No Route Available</strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="my-3 text-end">
                  <button
                    className="btn btn-default border me-2"
                    type="button"
                    onClick={() => navigatBack()}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-default border me-2"
                    type="button"
                    onClick={() => resetSelection()}
                    disabled={!validateReAssignData()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-outline-secondary theme-violet px-4"
                    type="button"
                    onClick={() => handleReassign()}
                    disabled={!validateReAssignData()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </>
        </div>
      </Layout>
    </>
  );
};

export default ShipmentViewReassignVehicle;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  ALPHANUM,
  COUNTRY_CODES,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  COMPANY_REGD_NO_REGEX,
  INVALID_COMPANY_REGD_NO_ERROR_MSG,
  PHONE_NUM,
  NAME_REGEX,
  INVALID_NAME,
} from "../../Helper/Constants";
import { CallApiGet } from "../../Helper/serviceApi";
import Select from "react-select";
import { GetSvgMail2, GetSvgUpload } from "../../../images/svglist";
import { toBase64 } from "../../Helper/CommonMethods";

const partnerPersonal = {
  name: "",
  driverLicense: "",
  driverLicenseExpiryDate: "",
  password: "",
  pwdSalt: "",
  ic: "",
};

const PartnerBusiness = ({ partnerTypeData, saveData }) => {
  const [stateCity, setStateCity] = useState();
  const [cityListData, setCityListData] = useState();
  const [photoImg, setPhotoImg] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const {
    register,
    clearErrors,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchStatesAndCityList();
  }, []);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsPrivacyPolicyAccepted(event.target.checked);
  };

  const dummyAttachment = {
    photo: "",
    photoFileName: "",
    photoFileType: "",
    photoBucketUrl: "",
  };

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
    setCityListData(cityArrList);
  };

  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });
      setCityListData(cityArrList);
    } else {
      setCityListData(cityData);
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    } else {
      setValue("state", "");
    }
  };

  const onSubmit = async (data) => {
    data.isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
    data.phone = data.countryCode + data.phone;
    delete data.countryCode;
    data.driverLicenseImage = dummyAttachment;
    data.roadTaxImage = dummyAttachment;
    data.puspakomImage = dummyAttachment;
    data.insuranceImage = dummyAttachment;
    data.vehiclePhotoFront = dummyAttachment;
    data.vehiclePhotoBack = dummyAttachment;
    data.vehiclePhotoLeft = dummyAttachment;
    data.vehiclePhotoRight = dummyAttachment;
    const reqData = {
      ...data,
      ...partnerPersonal,
      partnerTypeId: partnerTypeData.id,
      partnerType: partnerTypeData.optionValue,
    };
    console.log(JSON.stringify(reqData));
    saveData(reqData);
  };

  /*const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };*/
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      if (key === "profilePhoto") setPhotoImg(file[0]);
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };

  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const ssmDocPlaceHolder = watch(
    "ssmDoc.photoFileName",
    "Upload SSM Document"
  );

  return (
    <div
      className="accordion form-register"
      id="accordionPanelsStayOpenExample"
    >
      <div>
        <h6 className="signin-link mb-0">Company Details</h6>
        <div className="clearfix"></div>

        <div className="px-0 py-3">
          <div class="row">
            <div class="col">
              <div className="row">
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty mndtryInputSvg"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-buildings-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control  border-end-0 pe-1"
                      placeholder="Company Name"
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                      {...register("companyName", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: INVALID_NAME,
                        },
                      })}
                      maxLength="200"
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.companyName && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.companyName.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty mndtryInputSvg"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control border-end-0 pe-1"
                      placeholder="Contact Person Name"
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                      name="contactPersonName"
                      {...register("contactPersonName", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NAME_REGEX,
                          message: INVALID_NAME,
                        },
                      })}
                      maxLength="200"
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.contactPersonName && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.contactPersonName.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-telephone-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                        />
                      </svg>
                    </span>
                    <select
                      name="countryCode"
                      {...register("countryCode")}
                      defaultValue="+60"
                      className="form-control country-code-customize"
                    >
                      {COUNTRY_CODES.map((cc, index) => (
                        <option key={index} value={cc.value}>
                          {cc.label}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      className="form-control border-end-0 pe-1"
                      placeholder="Phone"
                      aria-label="Phone"
                      aria-describedby="basic-addon1"
                      maxLength="11"
                      name="phone"
                      {...register("phone", {
                        required: true,
                        pattern: {
                          value: PHONE_NUM,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.phone && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; Missing or Invalid Data!
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      {GetSvgMail2({ required: false })}
                    </span>
                    <Controller
                      name="city"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={cityListData}
                          placeholder="Select City"
                          value={
                            value
                              ? cityListData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterState(d ? d.value : d)
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          className="form-control border-end-0 pe-1  p-0"
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      }}
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.city && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.city.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-building-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V16h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H3Zm1 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control border-end-0 pe-1"
                      placeholder="Company Address"
                      aria-label="Address"
                      maxLength="1000"
                      name="companyAddress"
                      {...register("companyAddress", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      })}
                    ></input>
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.companyAddress && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.companyAddress.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="col">
              <div className="row">
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-buildings-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control border-end-0 pe-1"
                      placeholder="Company Regd Number"
                      aria-label="Phone"
                      aria-describedby="basic-addon1"
                      name="registrationNo"
                      {...register("registrationNo", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        // pattern: {
                        //   value: COMPANY_REGD_NO_REGEX,
                        //   message: INVALID_COMPANY_REGD_NO_ERROR_MSG,
                        // },
                      })}
                      maxLength="30"
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>

                  {errors.registrationNo && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888;{errors.registrationNo.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-envelope-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                      </svg>
                    </span>
                    <input
                      type="email"
                      className="form-control border-end-0 pe-1"
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                      })}
                      maxLength="200"
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.email && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; Missing or Invalid Data!
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      {GetSvgMail2({ required: false })}
                    </span>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={stateData}
                          placeholder="Select State"
                          value={
                            value
                              ? stateData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterCity(d ? d.value : d)
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          className="form-control border-end-0 pe-1  p-0"
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      }}
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.state && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.state.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-mailbox2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                        <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control border-end-0 pe-1"
                      placeholder="Postal Code"
                      aria-describedby="basic-addon1"
                      name="postcode"
                      {...register("postcode", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="20"
                    />
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.postcode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.postcode.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <div className="input-group">
                    <span
                      className="input-group-text inputsty"
                      id="basic-addon1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-building-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V16h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H3Zm1 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="form-control border-end-0 pe-1"
                      placeholder="Contact Address"
                      aria-label="Address"
                      maxLength="1000"
                      name="address"
                      {...register("address", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      })}
                    ></input>
                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                      *
                    </span>
                  </div>
                  {errors.address && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888;{errors.address.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div className="col w-100 mb-2">
                <div
                  className="mb-1 file-placeholder w-100 d-flex justify-content-center"
                  {...register("profilePhoto")}
                >
                  <input
                    type="file"
                    accept="image/png, image/jpeg "
                    onChange={(e) => selectFile("profilePhoto", e)}
                  />
                  {photoImg ? (
                    <img src={URL.createObjectURL(photoImg)} alt="img" />
                  ) : (
                    <div>
                      {GetSvgUpload({ required: false })}
                      Upload Photo{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** Bank Details */}
      <div>
        <h6 className="signin-link mb-0" id="panelsStayOpen-headingThree">
          Bank Details
        </h6>
        <div className="clearfix"></div>
        <div className="accordion-body px-0">
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="input-group">
                <span className="input-group-text inputsty" id="basic-addon1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-person-fill"
                  >
                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control border-end-0 pe-1"
                  placeholder="Bank Name"
                  aria-label=""
                  aria-describedby="basic-addon1"
                  name="bankName"
                  {...register("bankName", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: NAME_REGEX,
                      message: INVALID_NAME,
                    },
                  })}
                  maxLength="200"
                />
                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                  *
                </span>
              </div>
              {errors.bankName && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.bankName.message}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <div className="input-group">
                <span className="input-group-text inputsty" id="basic-addon1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-person-fill"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control border-end-0 pe-1"
                  placeholder="Beneficiary Name"
                  aria-label=""
                  aria-describedby="basic-addon1"
                  name="beneficiaryName"
                  {...register("beneficiaryName", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: NAME_REGEX,
                      message: INVALID_NAME,
                    },
                  })}
                />
                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                  *
                </span>
              </div>
              {errors.beneficiaryName && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{errors.beneficiaryName.message}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <div className="input-group">
                <span className="input-group-text inputsty" id="basic-addon1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-person-fill"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control border-end-0 pe-1"
                  placeholder="Account Number"
                  aria-label=""
                  aria-describedby="basic-addon1"
                  name="bankAccount"
                  {...register("bankAccount", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: ALPHANUM,
                      message: PATTERN_ERR_MSG,
                    },
                  })}
                  maxLength="200"
                />
                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                  *
                </span>
              </div>
              {errors.bankAccount && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.bankAccount.message}
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-6 mb-2">
              <div
                className="Upload-Photo w-100 mb-2"
                {...register("bankStatement", {
                  validate: (v) =>
                    v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              >
                <input
                  type="file"
                  onChange={(e) => selectFile("bankStatement", e)}
                />
                <div className="uploaded-text ms-2">
                  {GetSvgUpload({ required: false })}
                  {bankStatementPlaceHolder
                    ? bankStatementPlaceHolder
                    : "Upload Bank Statement"}
                </div>
              </div>
              {errors.bankStatement && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-6 mb-2">
              <div
                className="Upload-Photo w-100 mb-2"
                {...register("ssmDoc", {
                  validate: (v) =>
                    v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              >
                <input type="file" onChange={(e) => selectFile("ssmDoc", e)} />
                <div className="uploaded-text ms-2">
                  {GetSvgUpload({ required: false })}
                  {ssmDocPlaceHolder
                    ? ssmDocPlaceHolder
                    : "Upload SSM Document"}
                </div>
              </div>
              {errors.ssmDoc && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={isPrivacyPolicyAccepted}
            onChange={handleCheckboxChange}
          />
          <label class="form-check-label" for="flexCheckDefault">
            <a href="/privacyPolicy" target="_blank" className="text-white">
              Please read and accept the privacy policy
            </a>
          </label>
        </div>
      </div>
      <div className="clearfix col-md-4 m-auto mt-3">
        <button
          className="btn-primary-search w-100 rounded-pill theme-violet"
          role="button"
          onClick={handleSubmit(onSubmit)}
          disabled={!isPrivacyPolicyAccepted}
        >
          Register
        </button>
        <div className="text-center my-2 signin-link">
          Already Registered? {/* <a href="Login.html">Sign in here</a> */}
          <Link to="/login">Sign In here</Link>
        </div>
      </div>
    </div>
  );
};

export default PartnerBusiness;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";

const MultiRouteList = (props) => {
  const {
    tableData,
    handleEditClick,
    handleActiveDeactive,
    toggleReset,
    setRouteId,
  } = props;
  // const getFullAddress = (row) => {
  //   return `Address: ${row?.origin},\nState: ${origin?.originState}\nPostCode: ${row?.originPostcode}`;
  // };
  const getFullAddress = (row, type) => {
    if (type === "Origin") {
      return `Address: ${row?.origin},\nState: ${row?.orgState}\nPostCode: ${row?.orgPostcode}`;
    } else if (type === "destination") {
      return `Address: ${row?.destination},\nState: ${row?.destState}\nPostCode: ${row?.destPostcode}`;
    }
    return "";
  };
  const columns = [
    {
      name: "Route Code",
      selector: (row) => row.routeCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Rate Type",
      selector: (row) => row.rateTypeTxt,
      sortable: true,
      filterable: true,
    },
    {
      name: "Origin Address",
      selector: (row) => row.orgAddr,
      cell: (row) => <code className="address-style p-1">{row?.orgAddr}</code>,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Origin Sbu",
    //   selector: (row) => row.originSbuCode,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Destination Address",
      selector: (row) => row.destAddr,
      cell: (row) => <code className="address-style p-1">{row?.destAddr}</code>,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Destination Sbu",
    //   selector: (row) => row.destinationSbuCode,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Customer",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "Yes" : "No"),
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row.id}
            onClick={() => handleEditClick(row)}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "40px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"Dynamic" + row.id}
            onClick={() => {
              handleActiveDeactive(row);
              setRouteId(row.id);
              toggleReset(true);
            }}
          >
            {row.active === true
              ? GetSvgIcon("BixCircle")
              : GetSvgIcon("BiCheck2Circle")}
          </div>
          <Tooltip
            id={"Dynamic" + row.id}
            content={
              row.active === true
                ? "De-Activate " + row?.routeCode
                : "Activate " + row?.routeCode
            }
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <div className="col-md-12 customized-data-table position-relative">
      {
        <DataTable
          columns={columns}
          data={tableData}
          noDataComponent="There are no Routes Found!!"
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      }
    </div>
  );
};

export default MultiRouteList;

import React from "react";
import GetSvgIcon from "../../../images/svglist";
import ProgressBarGraph from "./ProgressBarGraph";

const InvoiceWidget = (props) => {
    return (
        <div className="col">
            
            <div className="card mb-4 shadow">
                <div className="card-header h6">
                    <span className="float-start">My Invoice</span>
                    {/* <span className="float-end">{GetSvgIcon('FullscreenWindow')}</span> */}
                </div>
                <div className="card-body ht-48">
                    {
                        !!props?.data?.gData && props?.data?.gData.length > 0 &&
                        props?.data?.gData.map((item, index) => (
                            <ProgressBarGraph data={item} key={index} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default InvoiceWidget;
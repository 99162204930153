import React from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GetSvgIcon from '../../../images/svglist'
import { getUserInfo } from '../../AuthUtils/AuthUtils'
import { BTN_TYPE_ADD } from '../../Helper/Constants'
import { CallApiGet, CallApiPost } from '../../Helper/serviceApi'
import Header from '../../Layout/Header'
import Popup from '../../Layout/Popup'
import Spinner from '../../Layout/Spinner'
import RolesList from './RolesList'
import RolesSave from './RolesSave'

const initSbuType = [
    {
        label: "Headquarter",
        value: 5
    },
    {
        label: "Region",
        value: 6
    },
    {
        label: "Hub",
        value: 7
    },
    {
        label: "Depot",
        value: 8
    },
]

const initRole = {
    userId: getUserInfo("userId"),
    roleId: 0,
    roleName: "",
    sbuTypeId: "",
    roleCreator: [],
    remarks: "",
    permissions: []
}

const RolesMain = () => {

    const [sbuTypeList, setSbuTypeList] = useState(initSbuType)
    const [role, setRole] = useState(JSON.parse(JSON.stringify(initRole)))
    const [roleOptions, setRoleOptions] = useState([])
    const [btnType, setBtnType] = useState('')
    const [roleList, setRoleList] = useState([])
    const [features, setFeatures] = useState([])
    const [show, setShow] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false);
    const [description, setDescription] = useState();
    const [title, setTitle] = useState();
    const modalRef = useRef();
    const navigate = useNavigate()

    useEffect(() => {
        loadFeatures(0)
        loadSbuType()
        fetchRoles()
    }, [])

    const loadSbuType = async () => {
        const url = "SysOptionGetByCode";
        const data = await CallApiPost(url, ["SBU_TYPE"]);
        if (data.respCode === 200 || data.respCode === 0) {
            let sbuTypeOpt = []
            data.respData.forEach(element => {
                sbuTypeOpt.push({
                    label: element.optionValue,
                    value: element.id
                })
            })
            setSbuTypeList(sbuTypeOpt)
        } else {
            onError()
        }
    }

    const loadFeatures = async (roleId) => {
        const url = "get-role-permissions?roleId=" + roleId
        const data = await CallApiGet(url)
        if (data.respCode === 200) {
            setFeatures(data.respData)
        } else {
            onError()
        }
    }

    const fetchRoles = async () => {
        setShow(true)
        let data = await CallApiGet("get-sys-roles")
        if (data.respCode === 200) {
            setShow(false)
            const roles = data.respData
            const options = []
            setRoleList(roles)
            roles.map(element => options.push({
                label: element.roleName,
                value: element.roleId
            }))
            setRoleOptions(options)
        } else {
            setShow(false)
            onError()
        }
    }

    const handleOnClick = (rowData) => {
        let copyRole = { ...role }
        copyRole.roleId = rowData.roleId
        copyRole.roleName = rowData.roleName
        copyRole.sbuTypeId = rowData.sbuTypeId ? rowData.sbuTypeId : 0
        copyRole.roleCreator = rowData.roleCreator ? rowData.roleCreator : []
        copyRole.remarks = rowData.remarks
        loadFeatures(rowData.roleId)
        setRole(copyRole)
    }

    const callback = () => {
        fetchRoles()
    }

    const onSuccess = (msg) => {
        setIsSuccess(true)
        setTitle("Congratulations")
        setDescription(msg)
        modalRef.current.click()
    }

    const onError = () => {
        setIsSuccess(false)
        setTitle("Error")
        setDescription("Something went wrong. Please try after sometime!!!")
        modalRef.current.click()
    }

    const unauthorized = (msg) => {
        setIsSuccess(false)
        setTitle("Error")
        setDescription(msg)
        modalRef.current.click()
    }

    const clear = () => {
        setRole(JSON.parse(JSON.stringify(initRole)))
        loadFeatures(0)
    }
    return (
        <body>
            <Header />
            <div className='container-fluid min-ht-100'>
                <div className='row'>
                    <main className="col-md-12 p-3">
                        <div className="clearfix border-bottom pb-2">
                            <div className="float-start">
                                <div className="section-title mt-1">Security Roles</div>
                            </div>
                            <div className="float-end d-flex">
                                <div className="float-end mt-1">
                                    <button className="btn btn-outline-secondary theme-violet text-nowrap ms-2" type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-role"
                                        onClick={() => setBtnType(BTN_TYPE_ADD)}
                                    >
                                        {GetSvgIcon('PlusCircle')} Add Role
                                    </button>
                                </div>
                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#success-modal"
                                    id="modalPopup"
                                    className="d-none"
                                    ref={modalRef}
                                />
                            </div>
                        </div>
                        <RolesList
                            roleList={roleList}
                            handleOnClick={handleOnClick}
                            setBtnType={setBtnType}
                        />
                    </main>
                </div>
            </div>
            <RolesSave
                sbuTypeList={sbuTypeList}
                features={features}
                onSuccess={onSuccess}
                setShow={setShow}
                onError={onError}
                role={role}
                initRole={initRole}
                btnType={btnType}
                clear={clear}
                roleOptions={roleOptions}
                unauthorized={unauthorized}
            />
            <Popup
                isSuccess={isSuccess}
                title={title}
                description={description}
                handler={callback}
            />
            <Spinner show={show} />
        </body>
    )
}

export default RolesMain
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  ALPHANUM,
  COUNTRY_CODES,
  EMAIL,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL, PHONE_NUM,
  PATTERN_ERR_MSG,
  SERVER_ERR_MSG,
  ALPHANUM_SPACE_SPCL_CHAR, DATE_FORMAT_MOMENT
} from "../Helper/Constants";
import { CallApiGet, CallApiPut } from "../Helper/serviceApi";
import Header from "../Layout/Header";
import Popup from "../Layout/Popup";
import Spinner from "../Layout/Spinner";
import ApprovalLogList from "../ApprovalWorkFlow/ApprovalLogList";
import {
  APVLLOG,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
  LINEHAUL_CUSTOMER_BUSINESS_PARTNERS
} from "../../ConfigConstants/ConfigConstants";
import { approvalHistory, permission, toBase64 } from "../Helper/CommonMethods";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import MultipleFileUpload from "../Utilities/MultipleFileUpload";
import moment from "moment";

const initCustProfile = {
  // userId: 62,
  profileId: 0,
  custType: 0,
  acctCode: "",
  custName: "",
  businessName: "",
  businessRegno: "",
  businessNature: "",
  businessAddr: "",
  custEmail: "",
  custPhone: "",
  custAddr: "",
  postcode: "",
  custWebsite: "",
  custIc: "",
  pickupAddr: "",
  pickupPerson: "",
  pickupPhone: "",
  bankName: "",
  bankAcct: "",
  benificiaryName: "",
  appliedBy: "",
  picSales: 0,
  picSalesStaffId: "",
  picSalesStaffName: "",
  picCredit: 0,
  picCreditStaffId: "",
  picCreditStaffName: "",
  creditLimit: 0,
  reqCreditLimit: 0,
  creditTerms: 0,
  debtorCode: "",
  ctosCheck: "",
  status: 0,
  noPayStatus: 0,
  registrationDate: "",
  commitedPallet: 0,
  discount: 0,
  rateChartUrl: "",
  commission: 0.0,
  generalDocList: [],
};
const customCss = {
  main: "w-100",
  header: {
    levelColor: "fc-black mb-0",
  },
  comp: {
    mainDiv: "row mt-3",
    innerDiv: "col-lg-4 mb-2",
    innerLevel: "uploaded-text ms-2",
  },
};
const customData = {
  headerLevel: "Additional Document",
  defaultName: "Additional Document",
};

const CustomerProfile = () => {
  const [show, setShow] = useState(false);
  const [custPrflDetails, setCustPrflDetails] = useState(
    JSON.parse(JSON.stringify(initCustProfile))
  );
  const [reload, setReload] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [isUserActive, setIsUserActive] = useState(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const [disableCompanyDtls, setDisableCompanyDtls] = useState(false);
  const modalRef = useRef();
  const [isDisabled, setDisabled] = useState({});

  const navigate = useNavigate();
  const initPhotoFile = {
    photo: "",
    photoFileType: "",
    photoBucketUrl: "",
    photoFileName: "",
  };

  useEffect(() => {
    setDisableCompanyDtls(
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType"))
    );
    setIsUserActive(getUserInfo("userActive"));
    fetchCustDetails();
  }, [reload]);

  useEffect(() => {
    if (custPrflDetails.user_picture && custPrflDetails.user_picture !== "") {
      setImageData(custPrflDetails.user_picture);
    } else {
      setImageData("");
    }
  }, [custPrflDetails]);
  const [photoImg, setPhotoImg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [imageData, setImageData] = useState(initPhotoFile);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: custPrflDetails,
  });
  const {
    fields: generalDocList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "generalDocList",
  });
  const fetchCustDetails = async () => {
    setShow(true);
    let uri = `get-cust-prfl?profileId=${getUserInfo("acctId")}`;
    let data = await CallApiGet(uri);

    if (data.respCode === 200 || data.respCode === 0) {
      let respDatas = data.respData[0];
      if (respDatas?.genDocList?.length > 0) {
        let jsonString = respDatas.genDocList;
        const arrayOfObjects = JSON.parse(jsonString);
        respDatas.genDocList = arrayOfObjects;
      }
      setCustPrflDetails(respDatas);
      let custPrflDetails = respDatas;
      Object.entries(custPrflDetails).forEach(([key, val]) => {
        if (typeof val !== "undefined" && val !== "null" && val !== null) {
          switch (key) {
            case "picSalesStaffName":
              let sales = val + "( " + custPrflDetails.picSalesStaffId + " )";
              setValue(key, sales);
              break;
            case "custPhone":
              setValue("custPhoneCode", val.substr(0, 3));
              setValue("custPhone", val.substr(3));
              break;
            case "pickupPhone":
              setValue("pickupPhoneCode", val.substr(0, 3));
              setValue("pickupPhone", val.substr(3));
              break;
            case "bankStatement":
              setValue(
                "bankStatement.photoFileName",
                respDatas?.bankStatement?.split("/").pop()
              );
              setValue(
                "bankStatement.photoBucketUrl",
                respDatas?.bankStatement
              );
              break;
            case "ssmDoc":
              setValue(
                "ssmDoc.photoFileName",
                respDatas?.ssmDoc?.split("/").pop()
              );
              setValue("ssmDoc.photoBucketUrl", respDatas?.ssmDoc);
              break;
            default:
              setValue(key, val);
              break;
          }
        } else {
          setValue(key, "");
        }
      });
      setShow(false);
    } else {
      setShow(false);
      navigate(-1);
    }
  };

  const onSubmit = async (data) => {
    setShow(true);
    data.custPhone = data.custPhoneCode + data.custPhone;
    data.pickupPhone = data.pickupPhoneCode + data.pickupPhone;

    delete data.custPhoneCode;
    delete data.pickupPhoneCode;
    delete data.picSalesStaffId;
    delete data.picSalesStaffName;
    delete data.picCreditStaffId;
    delete data.picCreditStaffName;
    delete data.rateChartUrl;

    data["rateChart"] = null;
    data["updatedBy"] = getUserInfo("userId");

    if (data?.profilePhoto?.length === 0) {
      data.profilePhoto.photoBucketUrl = data.user_picture;
    }
    const uri = "update-cust-profile";
    let resp = await CallApiPut(uri, data);

    if (resp.respCode === 200 || resp.respCode === 0) {
      reset(initCustProfile);
      setShow(false);
      onSuccess("Profile details updated successfully !!!");
    } else if (data.respCode === 400 || data.respCode === 401) {
      setShow(false);
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the write permission!!");
      modalRef.current.click();
    } else {
      setShow(false);
      onFailure();
    }
  };

  const onSuccess = (sucMsg) => {
    setIsSuccess(true);
    setTitle("Congratulations");
    setDescription(sucMsg);
    modalRef.current.click();
  };

  const onFailure = () => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(SERVER_ERR_MSG);
    modalRef.current.click();
  };

  const callback = () => {
    setReload((reload) => reload + 1);
  };

  const onError = (error) => {
    console.error(error);
  };

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(
      custPrflDetails.profileId,
      custPrflDetails.custType
    );
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/login");
    }
  };
  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
    } else {
      var fileName = file[0].name;
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      // clearErrors("profileDoc");
      // setPhotoImgErrMsg();
    }
  };
  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const ssmDocPlaceHolder = watch(
    "ssmDoc.photoFileName",
    "Upload SSM Document"
  );
  const selectBankAndSSMFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const download = (fileName) => {
    window.open(fileName, "_blank");
  };
  const watchCustEmail=watch("custEmail");
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Profile Details</div>
              </div>
            </div>
            <div className="alert alert-info my-4">
              <div>
                <div className="float-start">
                  <h5 className="card-title">Current Approval Status:</h5>
                  <p className="card-text">{custPrflDetails.approvalStatus}</p>
                </div>
                {permission(APVLLOG).allowedWrite && (
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet me-1 float-end"
                    onClick={getApprovalHistory}
                    data-bs-toggle="modal"
                    data-bs-target="#approval-history-modal"
                  >
                    Approval History
                  </button>
                )}
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-2 d-none">
                <nav className="nav flex-column profile-action" id="nav-tab">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </nav>
              </div>
              <div className="col-md-12 mt-2">
                <div className="row" id="nav-tabContent">
                  <div className="mb-4">
                    <div
                      className="d-block show border border-2 p-2"
                      id="nav-Personal"
                      role="tabpanel"
                      aria-labelledby="nav-Personal-tab"
                    >
                      <h5 className="theme-violet-txt mb-3">
                        Personal Details
                      </h5>
                      <div className="row">
                        <input type="hidden" {...register("profileId")} />
                        <div className="col-md-3">
                          <div
                            className="mb-2 file-placeholder thumbnail-pic d-flex justify-content-center"
                            {...register("profilePhoto")}
                          >
                            <input
                              type="file"
                              accept="image/png, image/jpeg "
                              onChange={selectPhotoFile}
                            />
                            {photoImg ? (
                              <img
                                src={URL.createObjectURL(photoImg)}
                                alt="img"
                              />
                            ) : imageData !== "" ? (
                              <img
                                src={imageData !== "" ? imageData : ""}
                                alt="img"
                              />
                            ) : (
                              <div>
                                {/* {GetSvgUpload({ required: false })} */}
                                Upload Photo{" "}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("custName")}
                          />
                          {errors.custName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.custName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Phone{" "}
                          </label>
                          <div className="input-group">
                            <select
                              name="custPhoneCode"
                              {...register("custPhoneCode")}
                              defaultValue="+60"
                              className="form-control country-code-customize"
                            >
                              {COUNTRY_CODES.map((cc, index) => (
                                <option key={index} value={cc.value}>
                                  {cc.label}
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="11"
                              {...register("custPhone", {
                                pattern: {
                                  value: PHONE_NUM,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                            />
                          </div>
                          {errors.custPhone && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.custPhone.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Email{" "}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            maxLength="200"
                            {...register("custEmail", {
                              pattern: {
                                value: EMAIL,
                                message: "Incorrect Data",
                              },
                            })}
                            disabled={watchCustEmail !== undefined && watchCustEmail !== ""}
                          />
                          {errors.custEmail && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.custEmail.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            {LINEHAUL_AGENT_CUSTOMER_USER_TYPE === getUserInfo("userType") ? "Sales Partner IC" : "Customer IC"}{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="50"
                            {...register("custIc", {
                              pattern: {
                                value: ALPHANUM,
                                message: "Accepts Alphanumeric values!",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            State{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            {...register("state")}
                          />
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            City{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            {...register("city")}
                          />
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Postcode{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            {...register("postcode", {
                              pattern: {
                                value: NUMBER_REGEX,
                                message: "Accepts Numeric values!",
                              },
                            })}
                          />
                          {errors.postcode && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.postcode.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Address{" "}
                          </label>
                          <textarea
                            className="form-control"
                            maxLength="200"
                            {...register("custAddr", {
                              pattern: {
                                value: ALPHANUM_SPACE_SPCL_CHAR,
                                message: PATTERN_ERR_MSG,
                              },
                              onChange: (e) =>
                                setValue("pickupAddr", e.target.value),
                            })}
                          ></textarea>
                          {/* <input
                            type="text"
                            className="form-control"
                            maxLength="200"
                            {...register("custAddr", {
                              onChange: (e) => setValue("pickupAddr", e.target.value),
                            })}
                          ></input> */}
                          {errors.custAddr && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.custAddr.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                  {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custPrflDetails?.custType) && (
                    <>
                    <div className="mb-4">
                      <div
                        className="d-block show border border-2 p-2"
                        id="nav-Company"
                        role="tabpanel"
                      >
                        <h5 className="theme-violet-txt">Company Details</h5>
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Company Name{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="200"
                              disabled={disableCompanyDtls}
                              {...register("businessName")}
                            />
                            {errors.businessName && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.businessName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Company Regd Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={disableCompanyDtls}
                              {...register("businessRegno", {})}
                              maxLength="30"
                            />
                            {errors.businessRegno && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.businessRegno.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Sales Representative{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("picSalesStaffName")}
                              disabled
                            />
                          </div>
                          <input type="hidden" {...register("businessAddr")} />
                          {/* <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Company Address{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="100"
                            disabled={disableCompanyDtls}
                            {...register("businessAddr")}
                          />
                          {errors.businessAddr && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.businessAddr.message}
                              </span>
                            </div>
                          )}
                        </div> */}
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div
                        className="d-block show border border-2 p-2"
                        id="nav-Company"
                        role="tabpanel"
                      >
                        <h5 className="theme-violet-txt">SST Exemption </h5>
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              SST Exempted{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="200"
                              disabled={disableCompanyDtls}
                              value={custPrflDetails.sstExempt===true?"YES":"NO"}
                            />
                            {errors.businessName && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.businessName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              SST Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={disableCompanyDtls}
                              value={custPrflDetails.sstNumber}
                            />
                            {errors.businessRegno && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.businessRegno.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              SST Exceeded Date{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={!!custPrflDetails.sstExemptStartDate?moment(custPrflDetails.sstExemptStartDate).format(DATE_FORMAT_MOMENT):""}
                              disabled
                            />
                          </div>
                          <input type="hidden" {...register("businessAddr")} />
                          
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    </>
                  )}
                  <div className="col-md-12 mb-4">
                    <div
                      className="d-block show border border-2 p-2"
                      id="nav-Bank"
                      role="tabpanel"
                    >
                      <h5 className="theme-violet-txt">Bank Details</h5>
                      <div className="row">
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Bank Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="200"
                            {...register("bankName")}
                          />
                          {errors.bankName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Bank Account{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="200"
                            {...register("bankAcct", {
                              pattern: {
                                value: ALPHANUM,
                                message: "Accepts Alphanumeric values!",
                              },
                            })}
                          />
                          {errors.bankAcct && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankAcct.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-3 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Benificiary Name{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="200"
                            {...register("benificiaryName")}
                          />
                          {errors.benificiaryName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.benificiaryName.message}
                              </span>
                            </div>
                          )}
                        </div>

                        {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custPrflDetails?.custType) && (
                          <>
                            <div className="col-lg-3 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                title="This field is mandatory"
                              >
                                Requested Credit Amount{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="200"
                                {...register("reqCreditLimit", {
                                  pattern: {
                                    value: NUMBER_TWO_DECIMAL,
                                    message:
                                      "Accepts numeric value with2 Decimal!",
                                  },
                                  setValueAs: (v) => {
                                    const val = parseFloat(v);
                                    return isNaN(val) ? v : val;
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-3 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                title="This field is mandatory"
                              >
                                Bank Statement{" "}
                              </label>

                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("bankStatement")}
                              >
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    selectBankAndSSMFile("bankStatement", e)
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {bankStatementPlaceHolder
                                    ? bankStatementPlaceHolder
                                    : "Upload Bank Statement"}
                                </div>
                                <button
                                  type="button"
                                  style={{
                                    marginTop: "-30px",
                                    marginRight: "-13px",
                                  }}
                                  className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `bankStatement.photoBucketUrl` +
                                        "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    download(
                                      watch("bankStatement.photoBucketUrl")
                                    )
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                title="This field is mandatory"
                              >
                                SSM Document{" "}
                              </label>

                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("ssmDoc")}
                              >
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    selectBankAndSSMFile("ssmDoc", e)
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {ssmDocPlaceHolder
                                    ? ssmDocPlaceHolder
                                    : "Upload SSM Document"}
                                </div>
                                <button
                                  type="button"
                                  style={{
                                    marginTop: "-30px",
                                    marginRight: "-13px",
                                  }}
                                  className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `ssmDoc.photoBucketUrl` + "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    download(watch("ssmDoc.photoBucketUrl"))
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custPrflDetails?.custType) && (
                    <>
                      <div className="col-md-12 mb-4">
                        <div
                          className="d-block show border border-2 p-2"
                          id="nav-Bank"
                          role="tabpanel"
                        >
                          <h5 className="theme-violet-txt">General Document</h5>
                          <div className="row">
                            {custPrflDetails?.genDocList?.map((item, index) => (
                              <>
                                <div
                                  className="col-md-4 mb-2 position-relative"
                                  key={index}
                                >
                                  <div className="Upload-Photo w-100">
                                    <div
                                      className="uploaded-text ms-2"
                                      disabled
                                    >
                                      {item.photoFileName.split("/").pop()}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    style={{ marginTop: "-28px" }}
                                    className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                    onClick={() => download(item.photoFileName)}
                                  >
                                    {GetSvgIcon("IconFiledownload")}
                                  </button>
                                </div>
                              </>
                            ))}
                          </div>
                          <MultipleFileUpload
                            customData={customData}
                            customCss={customCss}
                            register={register}
                            clearErrors={clearErrors}
                            setValue={setValue}
                            watch={watch}
                            control={control}
                            remove={remove}
                            append={append}
                            generalDocList={generalDocList}
                          />
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="col-md-6 mb-4">
                    <div
                      className="tab-pane d-block show border border-2 p-2"
                      id="pickup-details"
                      role="tabpanel"
                    >
                      <h5 className="theme-violet-txt">Pickup Details</h5>
                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Pickup Attention To
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="200"
                            {...register("pickupPerson")}
                          />
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Pickup Attention Phone
                          </label>
                          <div className="input-group">
                            <select
                              name="pickupPhoneCode"
                              {...register("pickupPhoneCode")}
                              defaultValue="+60"
                              className="form-control country-code-customize"
                            >
                              {COUNTRY_CODES.map((cc, index) => (
                                <option key={index} value={cc.value}>
                                  {cc.label}
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="20"
                              {...register("pickupPhone", {
                                pattern: {
                                  value: PHONE_NUM,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                            />
                          </div>
                          {errors.pickupPhone && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.pickupPhone.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Pickup Address
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="1000"
                            {...register("pickupAddr")}
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div> */}
                  <div className="clearfix"></div>
                  <div className="w-100 text-end">
                    {isUserActive === "true" && (
                      <button
                        type="button"
                        className="btn btn-default border me-2"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ApprovalLogList data={approvalHistoryData} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
    </div>
  );
};

export default CustomerProfile;

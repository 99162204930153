import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  ALPHANUM_NAME,
  DATE_FORMAT_CLIENT,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { CallApiPost } from "../Helper/serviceApi";
import { initValDiscounts } from "./initValDiscount";


const AddDiscount = (props) => {
  const {
    discountTypes,
    discount,
    setRowsData,
    rowsData,
    onSuccess,
    initThresholdRowError,
    setDiscount,
    setErrorMsgs,
    initError,
    initialThreshold,
  } = props;
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const [thresholdRowErrors, setThresholdRowErrors] = useState([
    initThresholdRowError,
  ]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: discount });

  const dateFromWatch = watch("validFrom");
  const dateToWatch = watch("validTo");

  const addTableRows = () => {
    const rowsInput = {
      id: 0,
      discountId: discount.id || 0,
      limitFrom: "",
      limitTo: "",
      discount: "",
    };

    setRowsData([...rowsData, rowsInput]);

    const newThresholdRowError = {
      limitFrom: false,
      limitTo: false,
      discount: false,
    };
    setThresholdRowErrors([...thresholdRowErrors, newThresholdRowError]);
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    console.log("Name: " + name + ", Index: " + index + ", value: " + value);
    console.log(rowsData);
    const rows = [...rowsData];
    rows[index][name] = value;
    setRowsData(rows);

    clearThresholdError(name, index);
  };
  const clearThresholdError = (key, index) => {
    let err = [...thresholdRowErrors];
    err[index][key] = false;
    setThresholdRowErrors([...err]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    console.log(rows);
    setRowsData([...rows]);

    let errRows = [...thresholdRowErrors];
    errRows.splice(index, 1);
    setThresholdRowErrors([...errRows]);
  };

  let isDataValid = true;
  const validateThresholdData = () => {

    var thresholdErr = JSON.parse(JSON.stringify(thresholdRowErrors));
    rowsData.map((row, index) => {
      Object.entries(row).forEach(([key, value]) => {
        if (typeof value === "number") {
          value = value.toString();
        }
        switch (key) {
          case "limitFrom":
            if (value === "" || !NUMBER_REGEX.test(value)) {
              thresholdErr[index][key] = true;
              isDataValid = false;
            }
            break;
          case "limitTo":
            if (value === "" || !NUMBER_REGEX.test(value)) {
              thresholdErr[index][key] = true;
              isDataValid = false;
            }
            break;
          case "discount":
            if (value === "" || !NUMBER_TWO_DECIMAL.test(value)) {
              thresholdErr[index][key] = true;
              isDataValid = false;
            }
            break;
          default:
            break;
        }
      });
    });
    setThresholdRowErrors(thresholdErr);
    return isDataValid;
  };

  const saveDiscount = async (data) => {
    if (isDataValid) {
      data.threshold = rowsData;
      const uri = "save-discount";
      let resp = await CallApiPost(uri, data);
      if (resp.respCode === 0) {
        clearData();
        onSuccess(resp.respMessage);
      } else if (resp.respCode === 500) {
        setSubmitStatusFail(true);
        setFailMsg(resp.respMessage);
        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 10000);
        // onSuccess(resp.respMessage);
      }
    }
  };
  const clearData = () => {
    reset();
    setDiscount(initValDiscounts);
    setErrorMsgs(initError);
    setThresholdRowErrors([initThresholdRowError]);
    setRowsData(initialThreshold);
  };
  return (
    <div
      className="modal fade"
      id="button-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Add/Edit DISCOUNT
            </h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => clearData()}
            ></button>
          </div>
          <div className="modal-body modal-scroll overflow-auto">
            <div className="row">
              <div>
                {submitStatusFail && (
                  <div id="errorMsg" className="alert alert-danger" role="alert">
                    {" "}
                    {failMsg} <br />
                  </div>
                )}
                <div className="w-100">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="This field is mandatory"
                      >
                        Discount Type{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <Controller
                        name="discountTypeId"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={discountTypes}
                            placeholder="Select Discount Type"
                            value={value ? value.value : value}
                            onChange={(d) => onChange(d ? d.value : d)}
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                          />
                        )}
                        rules={{ required: true }}
                      />
                      {errors.discountTypeId && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field is required!!
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Discount Name{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <input
                        id="discountName"
                        type="text"
                        placeholder="Discount Name"
                        className="form-control"
                        {...register("discountName", {
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: ALPHANUM_NAME,
                            message: PATTERN_ERR_MSG,
                          },
                        })}
                      />
                      {errors.discountName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.discountName.message}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Valid From <span className="text-danger">&#9733;</span>
                      </label>
                      <Controller
                        control={control}
                        name="validFrom"
                        rules={{
                          required: { value: true, message: REQUIRED_ERR_MSG },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <ReactDatePicker
                            placeholderText="Select Date"
                            className="form-control"
                            dateFormat={DATE_FORMAT_CLIENT}
                            selected={value ? new Date(value) : value}
                            showTimeSelect={false}
                            onChange={(d) => {
                              onChange(
                                d !== null ? moment(d).format("YYYY-MM-DD") : ""
                              );
                            }}
                            minDate={new Date()}
                            maxDate={dateToWatch && new Date(dateToWatch)}
                            showYearDropdown
                          />
                        )}
                      />
                      {errors.validFrom && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field is required!!
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Valid To <span className="text-danger">&#9733;</span>
                      </label>
                      <Controller
                        control={control}
                        name="validTo"
                        rules={{
                          required: { value: true, message: REQUIRED_ERR_MSG },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <ReactDatePicker
                            placeholderText="Select Date"
                            className="form-control"
                            dateFormat={DATE_FORMAT_CLIENT}
                            selected={value ? new Date(value) : value}
                            showTimeSelect={false}
                            onChange={(d) => {
                              onChange(
                                d !== null ? moment(d).format("YYYY-MM-DD") : ""
                              );
                            }}
                            minDate={new Date(dateFromWatch)}
                            //minDate={new Date()}
                            showYearDropdown
                          />
                        )}
                      />
                      {errors.validTo && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field is required!!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <table className="table mt-3">
                      <thead>
                        <tr>
                          <th scope="col">
                            Threshold From{" "}
                            <span className="text-danger">&#9733;</span>
                          </th>
                          <th scope="col">
                            Threshold To{" "}
                            <span className="text-danger">&#9733;</span>
                          </th>
                          <th scope="col">
                            Discount %{" "}
                            <span className="text-danger">&#9733;</span>
                          </th>
                          <th className="w-25">
                            <div className="px-2 text-success">
                              <div onClick={addTableRows}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-plus-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowsData &&
                          rowsData.map((data, index) => {
                            let { limitFrom, limitTo, discount } = data;

                            return (
                              // <>
                              <tr key={index}>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange(index, evnt)
                                    }
                                    value={limitFrom}
                                    name="limitFrom"
                                    className="form-control"
                                  />
                                  {thresholdRowErrors[index].limitFrom && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Accepts Numeric values!
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange(index, evnt)
                                    }
                                    value={limitTo}
                                    name="limitTo"
                                    className="form-control"
                                  />
                                  {thresholdRowErrors[index].limitTo && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Accepts Numeric values!
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange(index, evnt)
                                    }
                                    value={discount}
                                    name="discount"
                                    className="form-control"
                                    placeholder="%"
                                  />
                                  {thresholdRowErrors[index].discount && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Accepts Numeric values!
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td className="w-25">
                                  <div
                                    className="px-2 text-danger"
                                    onClick={() => deleteTableRows(index)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-dash-circle mt-2"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              onClick={() => {
                clearData();
                //  clearRegisterData()
              }}
            >
              Clear
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => {
                validateThresholdData();
                handleSubmit(saveDiscount)();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDiscount;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-bootstrap";
import { permission } from "../Helper/CommonMethods";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const AddressBookList = ({ handleAction, addrBook }) => {
  const userType = getUserInfo("userType");
  const columns = [
    {
      name: "Customer",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
      omit: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      filterable: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
      filterable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      filterable: true,
    },
    {
      name: "Postcode",
      selector: (row) => row.postcode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row}
            onClick={() => handleAction(row, "EDIT ADDRESS")}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];
  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={addrBook}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default AddressBookList;

import React, { useEffect } from "react";
import ModalLayout from "../../Layout/ModalLayout";
import { useFieldArray, useForm } from "react-hook-form";
import GetSvgIcon from "../../../images/svglist";
import { NET_ERR_MSG, REQUIRED_ERR_MSG } from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
export default function SLADynamicRate({
  closeDynRateModal,
  modalConfig,
  setSpinner,
  rowDataModel,
  callback,
  showAlert,
}) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const {
    fields: rateModelArr,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "dyRateArr",
  });
  useEffect(() => {
    reset(rowDataModel);
  }, [rowDataModel]);
  const handleMoalClose = () => {
    closeDynRateModal();
  };
  const saveDynamicRate = async (fromData) => {
    setSpinner(true);
    let data = await CallApiPost("SaveSLADynamicRate", fromData.dyRateArr);
    closeDynRateModal();
    switch (data.respCode) {
      case 200:
        showAlert(true, "SUCCESS", data.respMessage);
        callback();
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", data.respMessage);
        break;
    }
    setSpinner(false);
  };

  const header = (
    <h5 className="modal-title section-title">Dynamic Rate Configuration</h5>
  );

  const footer = (
    <button
      className="btn btn-outline-secondary theme-violet text-nowrap mr-5"
      type="button"
      onClick={handleSubmit(saveDynamicRate)}
    >
      Submit
    </button>
  );
  const generateRowDataModel = () => {
    return {
      loadFrom: 0,
      loadTo: 0,
      rate: 0,
      dynPriceId: 0,
      remark: "",
      serviceId: rowDataModel.serviceId,
      percentage: "true",
    };
  };

  return (
    <>
      {!!modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          header={header}
          footer={footer}
          handleMoalClose={handleMoalClose}
        >
          {/* {JSON.stringify(rateModelArr)} */}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Filled Capacity Above(%)</th>
                <th scope="col">Filled Capacity Upto(%)</th>
                <th scope="col">Price(%)</th>
                <th className="w-25">
                  <div
                    className="px-2 text-success"
                    onClick={() => append(generateRowDataModel())}
                  >
                    {GetSvgIcon("PlusCircleNoFill")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {rateModelArr.map((data, index) => {
                const { id, loadFrom, loadTo, rate } = data;
                return (
                  <tr key={id}>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        name={`dyRateArr.${index}.loadFrom`}
                        {...register(`dyRateArr.${index}.loadFrom`, {
                          defaultValue: loadFrom === 0 ? "" : loadFrom,
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                        })}
                        min={1}
                        max={100}
                      />
                      {!!errors?.dyRateArr &&
                        !!errors?.dyRateArr[index]?.loadFrom && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888;{" "}
                              {errors?.dyRateArr[index]?.loadFrom.message}
                            </span>
                          </div>
                        )}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        name={`dyRateArr.${index}.loadTo`}
                        {...register(`dyRateArr.${index}.loadTo`, {
                          defaultValue: loadTo,
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                        })}
                        min={1}
                        max={100}
                      />
                      {!!errors?.dyRateArr &&
                        !!errors?.dyRateArr[index]?.loadTo && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors?.dyRateArr[index]?.loadTo.message}
                            </span>
                          </div>
                        )}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control "
                        name={`dyRateArr.${index}.rate`}
                        {...register(`dyRateArr.${index}.rate`, {
                          defaultValue: rate,
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                        })}
                        min={1}
                      />
                      {!!errors?.dyRateArr &&
                        !!errors?.dyRateArr[index]?.rate && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors?.dyRateArr[index]?.rate.message}
                            </span>
                          </div>
                        )}
                    </td>
                    <td className="w-25">
                      {index === 0 ? (
                        ""
                      ) : (
                        <div
                          className="px-2 text-danger"
                          onClick={() => remove(index)}
                        >
                          {GetSvgIcon("DashCircleNoFill")}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ModalLayout>
      )}
    </>
  );
}

import React from 'react'
import DataTable from 'react-data-table-component-with-filter'
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../../Helper/Constants'

const CreditNoteSummaryList = ({cnColumns,cnData}) => {
  return (
    <div>
        <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
        <DataTable
          columns={cnColumns}
          data={cnData}
          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </div>
  )
}

export default CreditNoteSummaryList
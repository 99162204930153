import React from 'react'
import DataTable from 'react-data-table-component-with-filter'
import GetSvgIcon from '../../../images/svglist'
import { BTN_TYPE_EDIT, customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../../Helper/Constants'
import { Tooltip } from 'react-tooltip'

const RolesList = (props) => {
  const { roleList, handleOnClick, setBtnType } = props

  const columns = [
    {
      name: 'Role Name',
      selector: row => row.roleName,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Sbu Type',
      selector: row => row.sbuType,
      sortable: true,
      filterable: true,
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
      sortable: true,
      filterable: true,
    },
    {
      name: '',
      cell: row => (
        <>
        <div className="me-3" data-bs-toggle="modal" data-bs-target="#add-role" data-tooltip-id={"edit-" + row.roleId}
          onClick={() => {
            setBtnType(BTN_TYPE_EDIT)
            handleOnClick(row)
          }}
        >
          {GetSvgIcon("EditPencil")}
        </div>
        <Tooltip id={"edit-" + row.roleId} content={"Action"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: '56px',
    },
  ] 

  return (
    <div className="col-md-12 mt-3 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={roleList}

        pagination
        striped
        highlightOnHover
        customStyles={customDataTableStyles}
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
      />
    </div>
  )
}

export default RolesList
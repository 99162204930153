import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import ClaimList from "./ClaimList";
import ClaimAddEdit from "./ClaimAddEdit";
import ClaimHistory from "./ClaimHistory";
import { CallApiGet } from "../Helper/serviceApi";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
const ClaimMain = () => {
  const acctId = getUserInfo("acctId");
  const userType = getUserInfo("userType");
  let pageTitle = "CLAIM LIST";
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [claimList, setClaimList] = useState([]);
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [claimPalletId, setClaimPalletId] = useState(null);
  const [claimId, setClaimId] = useState(0);
  const [claimDetails, setClaimDetails] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [lastLogDetails, setLastLogDetails] = useState(null);
  const navigate = useNavigate();

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const getAllClaims = async () => {
    setSpinner(true);

    let data = await CallApiGet("ClaimList");
    if (!!data && data?.respCode === 200) {
      let filteredData = [];
      if (LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)) {
        filteredData = [...data?.respData].filter(
          (item) => item.custId === parseInt(acctId)
        );
      } else {
        filteredData = [...data?.respData];
      }
      setClaimList(filteredData);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAllClaims();
  }, []);

  // const showClaimForm = async (data) => {

  //     if (!!data?.claimId && !!data?.claimId > 0) {
  //         let result = await CallApiGet(`ClaimLogList?claimId=${data?.claimId}`)
  //         if (!!result && result.respCode === 200) {
  //             setLastLogDetails(result?.respData[0])
  //             setClaimOrderId(data?.orderId);
  //             setClaimPalletId(data?.palletId);
  //             setClaimId(!!data?.claimId ? data?.claimId : 0);
  //             setClaimDetails(data)
  //         }
  //     } else {
  //         setClaimOrderId(data?.orderId);
  //         setClaimPalletId(data?.palletId);
  //         setClaimId(!!data?.claimId ? data?.claimId : 0);
  //         setClaimDetails(data)
  //     }
  //     document.body.click()

  // }

  const showClaimForm = (argData) => {
    let encoded = Buffer.from(
      JSON.stringify({
        claimId: argData?.claimId,
        claimNo: argData?.claimNo,
        orderId: argData?.orderId,
      })
    ).toString("base64");
    // window.open(`/raiseClaim?data=${encoded}`, "_blank");
    navigate(`/raiseClaim?data=${encoded}`, { state: argData });
  };

  const cancelClaimForm = () => {
    setClaimOrderId(null);
    setClaimPalletId(null);
    setClaimDetails(null);
  };

  const getClaimLogs = async (claimId) => {
    let result = await CallApiGet(`ClaimLogList?claimId=${claimId}`);
    if (!!result && result.respCode === 200) {
      if (LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)) {
        let filteredData = result?.respData.filter((el) =>
          [462, 474, 469, 470, 471].includes(el.action_code)
        );
        setHistoryList(filteredData);
      } else {
        setHistoryList(result?.respData);
      }
    }
    setSpinner(false);
  };

  const showClaimHistory = (row) => {
    setSpinner(true);
    getClaimLogs(row?.claimId);
    setClaimDetails(row);
  };

  const reloadClaims = () => {
    getAllClaims();
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        {!!claimList && claimList.length > 0 ? (
          <ClaimList
            claimList={claimList}
            showClaimForm={showClaimForm}
            showClaimHistory={showClaimHistory}
          />
        ) : (
          <div className="noData">No Data Available</div>
        )}
      </Layout>
      {!!claimOrderId && !!claimPalletId && (
        <ClaimAddEdit
          reloadClaims={reloadClaims}
          claimId={claimId}
          claimDetails={claimDetails}
          claimOrderId={claimOrderId}
          claimPalletId={claimPalletId}
          cancelClaimForm={cancelClaimForm}
          showAlert={showAlert}
          setSpinner={setSpinner}
          lastLogDetails={lastLogDetails}
        />
      )}
      {!!historyList && historyList.length > 0 && (
        <ClaimHistory
          reloadClaims={reloadClaims}
          claimDetails={claimDetails}
          setClaimDetails={setClaimDetails}
          historyList={historyList}
          setHistoryList={setHistoryList}
          cancelClaimForm={cancelClaimForm}
          setSpinner={setSpinner}
          showAlert={showAlert}
        />
      )}
    </>
  );
};

export default ClaimMain;

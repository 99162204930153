import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { GetSvgDownload } from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";

const DeliveryEventLog = (props) => {

    const columns = [
        {
            name: "Date & Time",
            selector: (row) => row.event_time,
            sortable: true,
            filterable: true,
        },
        {
            name: "Event",
            selector: (row) => row.event_type,
            sortable: true,
            filterable: true,
        },
        {
            name: "User",
            selector: (row) => row.event_user,
            sortable: true,
            filterable: true,
        },
        {
            name: "SBU",
            selector: (row) => row.event_sbu || "NA",
            sortable: true,
            filterable: true,
        },
        {
            name: "Vehicle No",
            selector: (row) => row.vhcl_no || "NA",
            sortable: true,
            filterable: true,
        },
        {
            name: "Route Code",
            selector: (row) => row.route_code || "NA",
            sortable: true,
            filterable: true,
        },
        {
            name: "Latitude",
            selector: (row) => row.latitude || "NA",
            sortable: true,
            filterable: true,
        },
        {
            name: "Longitude",
            selector: (row) => row.longitude || "NA",
            sortable: true,
            filterable: true,
        },
        {
            name: "Description",
            selector: (row) => row.event_desc,
            sortable: true,
            filterable: true,
            width: '200px'
        },
        {
            name: "Attachment",
            selector: (row) => row.event_attachment,
            cell: (row) =>
                (row.event_attachment && (row.event_attachment.length > 0)) ? (
                    <>
                        <span onClick={() => downloadAttachment(row.event_attachment)}>
                            {GetSvgDownload({ required: false })}
                        </span>
                    </>
                ) : (
                    "NA"
                ),
        }
    ];

    const downloadAttachment = (attachments) => {
        for(let i = 0; i < attachments.length; i++) {
            DownloadFile(attachments[i]);
        }
    }


    return (
        <div
            className="modal fade"
            id="delivery-event-log-modal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title section-title" id="exampleModalLabel">
                            Delivery Event Log
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body overflow-auto">
                        <div className="row">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="clearfix"></div>
                                        <div className="col-md-12 mt-1 popup-data-table position-relative">
                                            <DataTable
                                                columns={columns}
                                                data={props.data}
                                                pagination
                                                striped
                                                highlightOnHover
                                                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                                                paginationPerPage={defaultPageLimit}
                                                customStyles={customDataTableStyles}
                                                defaultSortAsc={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default border me-2" data-bs-dismiss="modal">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeliveryEventLog
import React, { useState } from "react";
import Layout from "../Layout/Layout";
import PromotionAdd from "./PromotionAdd";
import PromotionList from "./PromotionList";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../images/svglist";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { useForm } from "react-hook-form";
import { Badge } from "react-bootstrap";
import moment from "moment";
import { DATE_FORMAT_MOMENT } from "../Helper/Constants";
import ModalPromotion from "../Layout/ModalPromotion";

const ORIGIN_STATE = "Origin City";
const DESTINATION_STATE = "Destination City";
const CUSTOMER_ID = "Customer Id";
const PAYMENT_BY = "Payment by Card/UPI Name";
const ORDER_VALUE = "Order Value";
const OUTBOUND = "Internationa Outbound";
const NEWCUST = "First Orders";
const PICKUP = 9;
const DELIVERY = 148;
const PACKAGING = 10;
const HANDLING = 149;

const initData = {
  id: 0,
  promoType: "Promotion",
  pType: "discount",
  promoCode: "",
  promoName: "",
  promotionStartDate: "",
  promotionEndDate: "",
  discount: "",
  maxDiscount: "",
  destination: "",
  origin: "",
  isRegion: "",
  firstOrderPallets: "",
  isFirstOrder: "",
  pTypeCredit: "",
  pTypeDebit: "",
  pTypeWallet: "",
  pTypeCash: "",
  pickupKm: "",
  deliveryKm: "",
  minOrderAmt: "",
  pTypesCash: "",
  cardName: "",
  pTypesCredit: "",
  customersList: [],
  criteria: "eligibility",
  discType: "percent",
  discAmt: "",
  outbound: "",
  isNewCust: "",
  custBookLimit: "",
};
const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const PromotionMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);
  const [sbuList, setSbuList] = useState([]);
  const [promoData, setPromoData] = useState();
  const [formIntitData, setFormInitData] = useState();
  const [pcodeMap, setPcodeMap] = useState(new Map());
  const [pcodeMap2, setPcodeMap2] = useState(new Map());
  const [masterCodeMap1, setMasterCodeMap1] = useState(new Map());
  const [masterCodeMap2, setMasterCodeMap2] = useState(new Map());
  const [payModeMap, setPayModeMap] = useState(new Map());
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [allCustomers, setAllCustomers] = useState();
  const [allCustomers2, setAllCustomers2] = useState();
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [isSubmited, setIsSubmited] = useState(false);
  const [ptatus, setPstatus] = useState("Draft");
  const [isactive, setIsactive] = useState(true);
  const [custList, setCustList] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const watchFirstOrder = watch("isFirstOrder");
  const watchId = watch("id");
  const watchFreeService = watch("fservice");
  const watchFreeServiceType = watch("freeServiceType");
  const watchPayMode = watch("pTypesCash");
  const watchPtypeCredit = watch("pTypesCredit");
  const watchCriteria = watch("criteria");
  const watchDiscType = watch("discType");
  const watchPickup = watch("servicePickup");
  const watchDelivery = watch("serviceDelivery");
  const watchPackage = watch("servicePackage");
  const watchHandel = watch("serviceHandel");

  useEffect(() => {
    setSpinner(true);
    getSbuData();
    getInitData();
    setFormInitData(JSON.parse(JSON.stringify(initData)));
  }, []);

  const customDateSort = (rowA, rowB) => {
    const dtA = rowA.startDate;
    const dtB = rowB.startDate;
    return moment(dtA).diff(moment(dtB));
  };
  const customDateSort2 = (rowA, rowB) => {
    const dtA = rowA.endDate;
    const dtB = rowB.endDate;
    return moment(dtA).diff(moment(dtB));
  };

  const columns = [
    // {
    //   name: "Campaign Type",
    //   width: "20%",
    //   selector: (row) => row.promoType,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Code",
      selector: (row) => row.promoCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Campaign Start Date",
      selector: (row) => moment(row.startDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortFunction: customDateSort,
    },
    {
      name: "Campaign End Date",
      selector: (row) => moment(row.endDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortFunction: customDateSort2,
    },
    {
      name: "Discount or Service",
      selector: (row) => row.discountService,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.bcolor === "danger" ? "Deactive" : row.status),
      cell: (row) => (
        <Badge bg={row.bcolor}>
          {row.bcolor === "danger" ? "Deactive" : row.status}
        </Badge>
      ),
      sortable: true,
      filterable: true,
    },

    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => {
              //   setBtnType(BTN_TYPE_EDIT)
              getSelectedRow(row);
            }}
            data-tooltip-id={"edit-" + row.id}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
    },
  ];

  const handelOnSelect = (event, rowData) => {
    var updatedList = [...selectedCustomers];
    if (event.target.checked) {
      updatedList = [...selectedCustomers, rowData.id];
    } else {
      updatedList.splice(selectedCustomers.indexOf(rowData.id), 1);
    }
    setSelectedCustomers(updatedList);
  };

  const custColumns = [
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <input
            type="checkbox"
            checked={selectedCustomers.includes(row.id)}
            onChange={(e) => handelOnSelect(e, row)}
            disabled={ptatus === "Published"}
          />
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row) => row.custType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Code",
      selector: (row) => row.accCode,
      sortable: true,
      filterable: true,
    },
  ];

  const getSelectedRow = async (data) => {
    let resps = await CallApiGet("getPromotionById?id=" + data.id);
    if (resps.respCode === 200) {
      setSelectedPromotion(resps.respData);
      setIsactive(resps.respData[0].active);
      setPstatus(resps.respData[0].statusVal);

      const promoData = {
        id: resps.respData[0].id,
        promoType: pcodeMap2.get(resps.respData[0].promoType),
        promoCode: resps.respData[0].promoCode,
        promoName: resps.respData[0].promoName,
        promotionStartDate: resps.respData[0].promotionStartDate,
        promotionEndDate: resps.respData[0].promotionEndDate,
        discount: resps.respData[0].percentage
          ? resps.respData[0].discount
          : "",
        discAmt: !resps.respData[0].percentage
          ? resps.respData[0].discount
          : "",
        maxDiscount: resps.respData[0].maxDiscount,
        pType:
          resps.respData[0].discountService === "Discount"
            ? "discount"
            : "frService",
        discType: resps.respData[0].percentage ? "percent" : "amt",
        // freeServiceType: pcodeMap2.get(resps.respData[0].freeServiceId),
      };

      updateRuleData(
        updateServiceValue(resps.respData[0], promoData),
        resps.respData[0]
      );

      setModalData();
    }
  };

  const updateServiceValue = (data, promoData) => {
    const serviceData = JSON.parse(data.promtionServices);

    serviceData.forEach((service) => {
      switch (service.serviceId) {
        case PICKUP:
          promoData.pickupKm = service.freeLimit;
          promoData.servicePickup = "pickUp";
          break;
        case DELIVERY:
          promoData.deliveryKm = service.freeLimit;
          promoData.serviceDelivery = "delivery";
          break;
        case PACKAGING:
          promoData.packagePallets = service.freeLimit;
          promoData.servicePackage = "packaging";
          break;
        case HANDLING:
          promoData.handelPallets = service.freeLimit;
          promoData.serviceHandel = "handling";
          break;

        default:
          break;
      }
    });

    return promoData;
  };

  const updateRuleData = (formData, data) => {
    const ruleData = JSON.parse(data.promtionRule);
    let cList = [];

    let eligible = true;
    let originArr = [];
    let destArr = [];
    ruleData.map((e) => {
      switch (e.ruleCode) {
        case "DSTN":
          formData.isRegion = "isRegion";
          // formData.destination = e.ruleValue;
          destArr.push(e.ruleValue);
          break;
        case "ORGN":
          // formData.origin = e.ruleValue;
          originArr.push(e.ruleValue);
          break;
        case "EXPT":
          formData.outbound = "outbound";
          break;

        case "PYBY":
          formData.cardName = e.ruleValue;
          break;
        case "ORVL":
          formData.minOrderAmt = e.ruleValue;
          break;
        case "FRST":
          formData.custBookLimit = e.ruleValue;
          formData.isNewCust = "newCust";
          break;
        case "CUST":
          eligible = false;
          cList.push(
            e.ruleValue === "ALL" ? e.ruleValue : parseInt(e.ruleValue)
          );
          break;
        case "BKMD":
          switch (e.ruleValue) {
            case "CASH":
              formData.pTypesCash = "cash";
              break;

            case "CREDIT":
              formData.pTypesCredit = "credit";
              break;

            default:
              break;
          }

          break;

        default:
          break;
      }
      eligible
        ? (formData.criteria = "eligibility")
        : (formData.criteria = "customer");
      let selectedCusts = [];
      let notSelectedCusts = [];

      if (cList[0] === "ALL") {
        setAllCustomers2(allCustomers);
      } else {
        allCustomers?.forEach((e) => {
          cList.includes(e.id)
            ? selectedCusts.push(e)
            : notSelectedCusts.push(e);
        });
        let custs = selectedCusts.concat(notSelectedCusts);
        setAllCustomers2(custs);
      }
      return true;
    });
    formData.origin = originArr;
    formData.destination = destArr;
    setCustList(cList);
    fillPreSelectedCustomers(cList);
    setFormInitData(formData);
  };

  const getInitData = async () => {
    let resps = await CallApiGet("getAllPromotions");

    const allCustResp = await CallApiGet("get-cust-data");
    if (resps.respCode === 200) {
      setPromoData(resps.respData);
    }
    if (allCustResp.respCode === 200) {
      setAllCustomers(allCustResp.respData);
      setAllCustomers2(allCustResp.respData);
    }
    setSpinner(false);
  };

  const handelTypeChange = (e) => {
    formIntitData.promoType = e;
    reset(formIntitData);
  };

  const getSbuData = async () => {
    let sbuData = await CallApiGet("getAllState");

    let ruleData = await CallApiGet("get-promotion-rule-master");
    let codes = await CallApiPost("SysOptionGetByCode", [
      "PROMO_TYPE",
      "PROMO_STATUS",
      "PROMO_RULE",
      "SBU_SERVICE",
    ]);

    let sbuArr = [{}];
    sbuData.respData.forEach((element) => {
      sbuArr.push({
        value: element.map_state,
        label: element.map_state,
      });
    });
    setSbuList(sbuArr);

    codes.respData?.forEach((element) => {
      pcodeMap.set(element.optionValue, element.id);
      pcodeMap2.set(element.id, element.optionValue);
    });

    ruleData.respData?.forEach((element) => {
      masterCodeMap1.set(element.ruleCode, element.ruleName);
      masterCodeMap2.set(element.ruleName, element.ruleCode);
      if (element.ruleOption === "CASH")
        payModeMap.set("cash", element.ruleCode);
      else if (element.ruleOption === "Credit")
        payModeMap.set("credit", element.ruleCode);
    });
  };

  const validate = (data) => {
    let valid = false;

    if (data.destination && data.origin) {
      if (data.destination === data.origin) {
        showAlert({
          isSuccess: false,
          title: "Duplicate",
          description: "Origin and Destination should not be same",
        });
        valid = false;
      } else valid = true;
    } else valid = true;

    return valid;
  };

  const onSubmit = async (data) => {
    if (validate(data)) {
      let promoErrMsg =
        data.promoType === "Promotion"
          ? "PromoCode already exists. Please change"
          : "VoucherCode already exists. Please change";
      const submitData = {
        promotionDto: {
          id: data.id,
          promoCode: data.promoCode,
          promoName: data.promoName,
          promoType: pcodeMap.get(data.promoType),
          promotionStartDate: data.promotionStartDate,
          promotionEndDate: data.promotionEndDate,
          status: pcodeMap.get(data.status),
          percentage: data.discType === "percent" ? true : false,
          discount:
            data.discType === "percent"
              ? parseFloat(data.discount)
              : parseFloat(data.discAmt),
          maxDiscount: parseFloat(data.maxDiscount),
        },
        promotionRulesDtos: getRulesData(data),
        freeServices: getServicesData(data),
      };

      setSpinner(true);
      let saveResponse = await CallApiPost("savePromotion", submitData);

      if (
        saveResponse.respCode === 200 &&
        saveResponse.respData[0].RetCode > 0
      ) {
        handleModalClose();
        let statusMsg = data.status === "Draft" ? "Drafted" : data.status;

        showAlert({
          isSuccess: true,
          title: "",
          description: data.promoType + " " + statusMsg + " Successfully",
        });
        getInitData();
      } else if (
        saveResponse.respCode === 409 &&
        saveResponse.respData[0].RetCode === 0
      ) {
        showAlert({
          isSuccess: false,
          title: "Duplicate",
          description: promoErrMsg,
        });
        setIsSubmited(false);
      } else {
        //error msg
      }
      setSpinner(false);
    }
  };

  const deactivate = async (id) => {
    setSpinner(true);
    let de = await CallApiGet("deactivePromo?id=" + id);
    if (de.respCode === 200) {
      handleModalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Deactivated Successfully",
      });
      getInitData();
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: "Something went wrong",
      });
    }
    getInitData();
    setSpinner(false);
  };
  const activate = async (id) => {
    setSpinner(true);
    let de = await CallApiGet("activatePromo?id=" + id);
    if (de.respCode === 200) {
      handleModalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Activated Successfully",
      });
      getInitData();
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: "Something went wrong",
      });
    }
    getInitData();
    setSpinner(false);
  };

  const getServicesData = (data) => {
    let servicesData = [];

    if (data.servicePickup) {
      servicesData.push({
        promotionId: 0,
        servicesId: pcodeMap.get("Pickup Services"),
        serviceLimit: parseInt(data.pickupKm),
      });
    }
    if (data.serviceDelivery) {
      servicesData.push({
        promotionId: 0,
        servicesId: pcodeMap.get("Delivery Services"),
        serviceLimit: parseInt(data.deliveryKm),
      });
    }
    if (data.servicePackage) {
      servicesData.push({
        promotionId: 0,
        servicesId: pcodeMap.get("Packing Service"),
        serviceLimit: parseInt(data.packagePallets),
      });
    }
    if (data.serviceHandel) {
      servicesData.push({
        promotionId: 0,
        servicesId: pcodeMap.get("Handling Service"),
        serviceLimit: parseInt(data.handelPallets),
      });
    }

    return servicesData;
  };

  const getRulesData = (data) => {
    let rulesData = [];
    // if (data.criteria === "eligibility") {
    if (data.pTypesCash) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: payModeMap.get("cash"),
        ruleValue: "CASH",
      });
    }

    if (data.pTypesCredit) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: payModeMap.get("cash"),
        ruleValue: "CREDIT",
      });
    }

    if (data.origin) {
      // rulesData.push({
      //   id: 0,
      //   promotionId: 0,
      //   ruleId: masterCodeMap2.get(ORIGIN_STATE),
      //   ruleValue: data.origin,
      // });
      data.origin.map((item) =>
        rulesData.push({
          id: 0,
          promotionId: 0,
          ruleId: masterCodeMap2.get(ORIGIN_STATE),
          ruleValue: item,
        })
      );
    }

    if (data.destination) {
      // rulesData.push({
      //   id: 0,
      //   promotionId: 0,
      //   ruleId: masterCodeMap2.get(DESTINATION_STATE),
      //   ruleValue: data.destination,
      // });
      data.destination.map((item) =>
        rulesData.push({
          id: 0,
          promotionId: 0,
          ruleId: masterCodeMap2.get(DESTINATION_STATE),
          ruleValue: item,
        })
      );
    }

    if (data.minOrderAmt) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: masterCodeMap2.get(ORDER_VALUE),
        ruleValue: data.minOrderAmt,
      });
    }

    if (data.cardName) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: masterCodeMap2.get(PAYMENT_BY),
        ruleValue: data.cardName,
      });
    }
    if (data.outbound) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: masterCodeMap2.get(OUTBOUND),
        ruleValue: data.outbound,
      });
    }

    if (data.custBookLimit) {
      rulesData.push({
        id: 0,
        promotionId: 0,
        ruleId: masterCodeMap2.get(NEWCUST),
        ruleValue: data.custBookLimit,
      });
    }
    // }
    // let selectedCustomers = data.selectedCustomers;
    let selectedCustomer = selectedCustomers;
    if (selectedCustomer && selectedCustomer.length > 0) {
      if (selectedCustomer.length === allCustomers.length) {
        rulesData.push({
          id: 0,
          promotionId: 0,
          ruleId: masterCodeMap2.get(CUSTOMER_ID),
          ruleValue: "ALL",
        });
      } else {
        selectedCustomer.forEach((e) => {
          rulesData.push({
            id: 0,
            promotionId: 0,
            ruleId: masterCodeMap2.get(CUSTOMER_ID),
            ruleValue: e,
          });
        });
      }
    }

    return rulesData;
  };


  const rowSelectCritera = (row) => {
    if (custList.includes("ALL")) {
      return true;
    } else if (custList.includes(row.id)) {
      return true;
    } else {
      return false;
    }
  };

  const onError = (data) => {
    console.log("Error: " + data);
  };

  const setModalData = () => {
    setModalConfig({
      size: "xl",
      show: true,
      title: "",
    });
    // setAllCustomers2(allCustomers)
  };

  const handleModalClose = (reload = false) => {
    setModalConfig({
      size: "xl",
      show: false,
      title: "",
    });

    setIsactive(true);
    setPstatus("Draft");
    reset(JSON.parse(JSON.stringify(initData)));
    setFormInitData(JSON.parse(JSON.stringify(initData)));
    setAllCustomers2(allCustomers);
    setCustList([]);
  };

  const handelClear = () => {
    reset(formIntitData);
    fillPreSelectedCustomers(custList);
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const fillPreSelectedCustomers = (cList) => {
    setSelectedCustomers(cList);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">Promotion Management </div>
          </div>
          <div className="float-end">
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={setModalData}
            >
              Add Promotion
            </button>
          </div>
        </div>
        <PromotionList columns={columns} data={promoData} />
      </Layout>

      {modalConfig && (
        <ModalPromotion
          moadlConfig={modalConfig}
          handleModalClose={handleModalClose}
          handleMoalClose={handleModalClose}
          handelTypeChange={handelTypeChange}
          register={register}
          getValues={getValues}
        >
          <PromotionAdd
            formIntitData={formIntitData}
            sbuList={sbuList}
            watch={watch}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            onError={onError}
            onSubmit={onSubmit}
            handelClear={handelClear}
            watchFirstOrder={watchFirstOrder}
            watchId={watchId}
            watchFreeService={watchFreeService}
            errors={errors}
            watchFreeServiceType={watchFreeServiceType}
            custColumns={custColumns}
            allCustomers={allCustomers2}
            clearErrors={clearErrors}
            rowSelectCritera={rowSelectCritera}
            watchPayMode={watchPayMode}
            setError={setError}
            isactive={isactive}
            ptatus={ptatus}
            deactivate={deactivate}
            watchPtypeCredit={watchPtypeCredit}
            setIsSubmited={setIsSubmited}
            isSubmited={isSubmited}
            watchCriteria={watchCriteria}
            watchDiscType={watchDiscType}
            watchHandel={watchHandel}
            watchPackage={watchPackage}
            watchDelivery={watchDelivery}
            watchPickup={watchPickup}
            activate={activate}
          />
        </ModalPromotion>
      )}
    </>
  );
};

export default PromotionMain;

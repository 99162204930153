import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  NON_EMPTY_NUMBER_REGEX,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL,
  TWO_DECIMAL,
} from "../Helper/Constants";

const CharteredRateAddEdit = ({
  modalConfig,
  custList,
  rateType,
  onHide,
  selRate,
  onSubmit,
}) => {
  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm();

  const watchRateId = watch("rateId");
  const watchRateType = watch("rateTypeId");

  const handleClose = () => {
    reset();
    onHide();
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "label",
  });

  useEffect(() => {
    reset(selRate);
  }, [selRate]);

  const ratePerTrip = (
    <Row>
      <Col>
        <label className="form-label theme-label">
          Base Charge <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="baseCharge"
          {...register("baseCharge", {
            required: {
              value: watchRateType === 765 ? true : false,
              message: "This field is required!!",
            },
            pattern: {
              value: NUMBER_TWO_DECIMAL,
              message: "Decimal value is accepted up to 2 place!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 999999999.99,
              message: "Value must less than or equal to 999999999.99",
            },
          })}
        />
        {errors?.baseCharge && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.baseCharge?.message}
            </span>
          </div>
        )}
      </Col>
      {/* <Col md={6}>
        <label className="form-label theme-label">
          surcharge <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="surcharge"
          {...register("surcharge", {
            required: {
              value: watchRateType === 765 ? true : false,
              message: "This field is required!!",
            },
            pattern: {
              value: NON_EMPTY_NUMBER_REGEX,
              message: "Decimal value is not accepted!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 100,
              message: "Value must less than or equal to 100.",
            },
          })}
        />
        {errors?.surcharge && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.surcharge?.message}
            </span>
          </div>
        )}
      </Col> */}
      {/* <Col>
        <label className="form-label theme-label">
          Threshold <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="threshold"
          {...register("threshold", {
            required: {
              value: watchRateType === 765 ? true : false,
              message: "This field is required!!",
            },
            pattern: {
              value: NON_EMPTY_NUMBER_REGEX,
              message: "Decimal value is not accepted!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 100,
              message: "Value must less than or equal to 100.",
            },
          })}
        />
        {errors?.threshold && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.threshold?.message}
            </span>
          </div>
        )}
      </Col> */}
    </Row>
  );

  const rateByWeight = (
    <Row>
      <Col>
        <label className="form-label theme-label">
          Unit Price <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="unitPrice"
          {...register("unitPrice", {
            required: {
              value: watchRateType === 766 ? true : false,
              message: "This field is required!!",
            },
            pattern: {
              value: NUMBER_TWO_DECIMAL,
              message: "Decimal value is accepted!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 999999999.99,
              message: "Value must less than or equal to 999999999.99",
            },
          })}
        />
        {errors?.unitPrice && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.unitPrice?.message}
            </span>
          </div>
        )}
      </Col>
      {/* <Col md={6}>
        <label className="form-label theme-label">
          Threshold <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="threshold"
          {...register("threshold", {
            required: {
              value: watchRateType === 766 ? true : false,
              message: "This field is required!!",
            },
            pattern: {
              value: NON_EMPTY_NUMBER_REGEX,
              message: "Decimal value is not accepted!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 100,
              message: "Value must less than or equal to 100.",
            },
          })}
        />
        {errors?.threshold && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.threshold?.message}
            </span>
          </div>
        )}
      </Col> */}
    </Row>
  );

  const renderConfig = () => {
    switch (watchRateType) {
      case 765:
        return ratePerTrip;
      case 766:
        return rateByWeight;
      default:
        break;
    }
  };
  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title section-title">{modalConfig?.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <input type="hidden" name="rateId" {...register("rateId")} />
            <Row>
              <Col md={6}>
                <label className="form-label theme-label">
                  Customer
                  <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  name="custId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!!",
                    },
                    min: { value: 1, message: "This field is required!!" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Select Customer"
                      isSearchable={true}
                      isClearable={true}
                      className="form-control p-0"
                      options={custList}
                      value={
                        !!value
                          ? custList?.find((opt) => opt.value === value)
                          : value
                      }
                      onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                    />
                  )}
                />
                {errors?.customer && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; This field is mandatory
                    </span>
                  </div>
                )}
              </Col>
              <Col md={6}>
                <label className="form-label theme-label">
                  Rate Type <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  name="rateTypeId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!!",
                    },
                    min: { value: 1, message: "This field is required!!" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Select Rate Type"
                      isSearchable={true}
                      isClearable={true}
                      className="form-control p-0"
                      options={rateType}
                      value={
                        !!value
                          ? rateType?.find((opt) => opt.value === value)
                          : value
                      }
                      onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                      isDisabled={watchRateId > 0}
                    />
                  )}
                />
              </Col>
            </Row>
            {renderConfig()}
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="theme-violet"
            disabled={+watchRateId > 0 && !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CharteredRateAddEdit;

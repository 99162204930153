import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions
} from "../Helper/Constants";

const CustomerOrderShipmentViewList = ({
  shipmentList,
  handelOnSelect,
  changeStatus,
  mergedList,
  onFilterData,
  columns,
}) => {
  
  return (
    <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={shipmentList}
        style={`table table-striped table-hover`}
        customStyles={customDataTableStyles}
        pagination
        striped
        highlightOnHover
        defaultSortFieldId={1}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        onFilter={(e) => onFilterData(e)}
        onChange={(e) => onFilterData(e)}
      />
    </div>
  );
};

export default CustomerOrderShipmentViewList;

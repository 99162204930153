import React from "react";
import Modal from "react-bootstrap/Modal";

const ChartedBookConformAlert = ({
  children,
  confirmAlertConfig,
  hideConfirmAlert,
  confirmAlert,
}) => {
  return (
    <>
      <Modal
        size={confirmAlertConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmAlertConfig.show}
        onHide={hideConfirmAlert}
      >
        <Modal.Header closeButton>
          <Modal.Title>{confirmAlertConfig.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{children}</div>
        </Modal.Body>
        <Modal.Footer>
         
          <button
            type="button"
            className="btn btn-success theme-violet"
            onClick={confirmAlert}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChartedBookConformAlert;

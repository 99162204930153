import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-bootstrap";

const VasChargeList = ({ handleAction, vasChargeList }) => {
  const columns = [
    {
      name: "Sales Partner",
      selector: (row) => row.customer,
      sortable: true,
      filterable: true,
    },
    {
      name: "Charge (P)",
      selector: (row) => row.pickupCharge,
      sortable: true,
      filterable: true,
    },
    {
      name: "Extra Charge (P)",
      selector: (row) => row.pickupExtraCharge,
      sortable: true,
      filterable: true,
    },
    {
      name: "Subbreak Fee (P)",
      selector: (row) => row.pickupSubBreakFee,
      sortable: true,
      filterable: true,
    },
    {
      name: "Charge (D)",
      selector: (row) => row.deliveryCharge,
      sortable: true,
      filterable: true,
    },
   
    {
      name: "Extra Charge (D)",
      selector: (row) => row.deliveryExtraCharge,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Subbreak-TH (P)",
    //   selector: (row) => row.pickupSubBreakThreshold,
    //   sortable: true,
    //   filterable: true,
    // },
    // {
    //   name: "Subbreak-TH (D)",
    //   selector: (row) => row.deliverySubBreakThreshold,
    //   sortable: true,
    //   filterable: true,
    // },
    
    {
      name: "Subbreak Fee (D)",
      selector: (row) => row.deliverySubBreakFee,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row}
            onClick={() => handleAction(row, "EDIT Rate")}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  const data = [
    {
      customer: "Ayan",
      pickupCharge: 10,
      deliveryCharge: 20,
    },
  ];
  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={vasChargeList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default VasChargeList;

import React from "react";
import GetSvgIcon from "../../../images/svglist";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const OrderDetailWidget = (props) => {

    const OrderDetailsLabel = (props) => {
        return (
            <>
            <div className="col">
                <h4 className="small font-weight-bold my-3">
                    Orders In Transit{" "}<span className="float-end"><strong>{props?.data?.ordersInTransit}</strong></span>
                </h4>
                <h4 className="small font-weight-bold my-3">
                    Orders Delivered{" "}<span className="float-end"><strong>{props?.data?.ordersDelivered}</strong></span>
                </h4>
                <h4 className="small font-weight-bold my-3">
                    Problem Shipment{" "}<span className="float-end"><strong>{props?.data?.problemShipment}</strong></span>
                </h4>
                <h4 className="small font-weight-bold my-3">
                    Total Revenue{" "}<span className="float-end"><strong>RM {props?.data?.totalRevenue.toFixed(2)}</strong></span>
                </h4>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="col">
                <div className="card mb-4 shadow">
                    <div className="card-header h6">
                        <span className="float-start">
                            {
                                props?.type === 'A' ? 'Order Details (Last Three Months)' : 'My Orders'
                            }
                        </span>
                        {/* <span className="float-end">{GetSvgIcon('FullscreenWindow')}</span> */}
                    </div>
                    <div className="card-body ht-48">
                        {
                            props?.type === 'A' ?
                                <>
                                    <div className="row">
                                        <div className="col ht-48">
                                            <Pie data={props?.gData} />
                                        </div>
                                        <div className="col py-5">
                                            <OrderDetailsLabel data={props?.data} />
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div style={{ width: '100%', height: '100%' }}>
                                        <Pie data={props?.gData} />
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderDetailWidget;
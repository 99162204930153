import React, { useEffect, useState } from "react";
import CustomNotificationAdd from "./CustomNotificationAdd";
import ModalLayout from "../../Layout/ModalLayout";
import Layout from "../../Layout/Layout";
import { useForm } from "react-hook-form";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import { DATE_FORMAT_MOMENT } from "../../Helper/Constants";
import moment from "moment";
import { customDateSort } from "../../Helper/CommonMethods";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initData = {
  id: 0,
  visibility: "all",
  notification: "",
  date: new Date(),
  role: "0",
  sbuType: "0",
  sbuCode: "0",
  submitType: "",
};
const CustomNotificationMain = () => {
  const [modalConfig, setModalConfig] = useState(null);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [spinner, setSpinner] = useState(false);
  const [formIntitData, setFormInitData] = useState();
  const [roleOptions, setRoleOptions] = useState();
  const [sbuOptions, setSbuOptions] = useState();
  const [sbuType, setSbuType] = useState();
  const [allSbu, setAllSbu] = useState([]);

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    // clearErrors,
    setValue,
    getValues,
    // setError,
    formState: { errors },
  } = useForm({ defaultValues: initData });

  const watchVisibility = watch("visibility");

  useEffect(() => {
    setFormInitData(JSON.parse(JSON.stringify(initData)));
    getInitDatas();
  }, []);

  const getInitDatas = async () => {
    setSpinner(true);
    let allRolesResp = await CallApiGet("get-sys-roles");
    let allSbuResp = await CallApiGet("getAllSbuList");
    let sbuTypedata = await CallApiPost("SysOptionGetByCode", ["SBU_TYPE"]);
    let roleOpt = [
      {
        value: 0,
        label: "ALL",
      },
    ];

    let sbuTyp = [
      {
        value: 0,
        label: "ALL",
      },
    ];
    if (allRolesResp.respCode === 200) {
      allRolesResp.respData.forEach((element) => {
        roleOpt.push({
          value: element.roleId,
          label: element.roleName,
        });
      });
    }

    if (sbuTypedata.respCode === 200 || sbuTypedata.respCode === 0) {
      sbuTypedata.respData.forEach((element) => {
        sbuTyp.push({
          value: element.id,
          label: element.optionValue,
        });
      });
    }

    if (allSbuResp.respCode === 200 || allSbuResp.respCode === 0) {
      setAllSbu(allSbuResp.respData);
      updateSubOptions(allSbuResp.respData);
    }

    setRoleOptions(roleOpt);
    setSbuType(sbuTyp);

    setSpinner(false);
  };

  // const setModalData = () => {
  //   setModalConfig({
  //     size: "md",
  //     show: true,
  //     title: "Custom Notification",
  //   });
  //   // setAllCustomers2(allCustomers)
  // };

  // const handleModalClose = (reload = false) => {
  //   setModalConfig({
  //     size: "md",
  //     show: false,
  //     title: "",
  //   });
  //   reset(initData);
  // };
  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const onError = (data) => {
    console.log("Error: " + data);
  };
  const handelClear = () => {
    reset(formIntitData);
  };

  const onSubmit = async (data) => {
    console.log(data);
    let payload = { notification: data.notification };
    if (
      (typeof data.role === "string" && data.role !== "0") ||
      (typeof data.role === "object" && !data.role.includes(0))
    )
      payload.role = data.role;
    if (
      (typeof data.sbuCode === "string" && data.sbuCode !== "0") ||
      (typeof data.sbuCode === "object" && !data.sbuCode.includes(0))
    )
      payload.sbuCode = data.sbuCode;
    if (
      (typeof data.sbuType === "string" && data.sbuType !== "0") ||
      (typeof data.sbuType === "object" && !data.sbuType.includes(0))
    )
      payload.sbuType = data.sbuType;

    setSpinner(true);
    let saveResponse = await CallApiPost("saveNotification", payload);
    setSpinner(false);
    if (saveResponse.respCode === 200) {
      showAlert({
        isSuccess: true,
        title: "",
        description: " Successfully",
      });
      reset(initData)
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: saveResponse.respMessage,
      });
    }
  };

  const updateSubOptions = (allSbus) => {
    let sbuOpt = [
      {
        value: 0,
        label: "ALL",
      },
    ];

    allSbus.forEach((element) => {
      sbuOpt.push({
        value: element.id,
        label: element.code,
      });
    });

    setSbuOptions(sbuOpt);
  };

  const filterForSelectedSbuType = () => {
    const sbuTypes = getValues("sbuType");
    let sbus = [];

    if (sbuTypes.length === 0 || sbuTypes.includes(0)) {
      sbus = allSbu;
    } else {
      sbuTypes.forEach((typeId) => {
        let dt = allSbu.filter((e) => e.sbuType === typeId);
        sbus = [...sbus, ...dt];
      });
    }

    updateSubOptions(sbus);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">
              Custom Notification Management{" "}
            </div>
          </div>
          {/* <div className="float-end">
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={setModalData}
            >
              Add Notification
            </button>
          </div> */}
        </div>
        {/* <CustomNotificationList 
        columns={columns}
        data = {allNotification}
        /> */}
        <CustomNotificationAdd
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          handelClear={handelClear}
          onError={onError}
          onSubmit={onSubmit}
          errors={errors}
          sbuType={sbuType}
          sbuOptions={sbuOptions}
          roleOptions={roleOptions}
          getValues={getValues}
          setValue={setValue}
          watchVisibility={watchVisibility}
          filterForSelectedSbuType={filterForSelectedSbuType}
        />
      </Layout>

      {/* {modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
        >
          <CustomNotificationAdd
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            handelClear={handelClear}
            onError={onError}
            onSubmit={onSubmit}
            errors={errors}
            sbuType={sbuType}
            sbuOptions={sbuOptions}
            roleOptions={roleOptions}
            getValues={getValues}
            setValue={setValue}
            watchVisibility={watchVisibility}
            filterForSelectedSbuType={filterForSelectedSbuType}
          />
        </ModalLayout>
      )} */}
    </>
  );
};

export default CustomNotificationMain;

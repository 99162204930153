import React, { useEffect, useRef, useState } from "react";
import { CallApiGet } from "../Helper/serviceApi";
import Header from "../Layout/Header";
import Popup from "../Layout/Popup";
import Spinner from "../Layout/Spinner";
import ContentDocAdd from "./ContentDocAdd";
import ContentDocList from "./ContentDocList";
import { NET_ERR_MSG, SERVER_ERR_MSG } from "../Helper/Constants";

const initDoc = {
  shipId: 0,
  documentId: 0,
  documentName: "",
  documentRemarks: "",
};

const intiShipmentData = {
  shipmentId: 0,
  countryCode: "",
  contentType: "",
  hubId: "",
  remarks: "",
  contentDocs: [initDoc],
};

export default function ShipmentContentDocMgm() {
  const [show, setShow] = useState();
  const [country, setCountry] = useState([]);
  const [deliveryPort, setDeliveryPort] = useState([]);
  const [contentDocMgmList, setContentDocList] = useState([]);
  const [shipment, setShipment] = useState(
    JSON.parse(JSON.stringify(intiShipmentData))
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();

  useEffect(() => {
    getAllCountryList();
    getAllPortList();
    getShipmetList();
  }, []);

  const getAllCountryList = async () => {
    const data = await CallApiGet("getAllCountry");
    if (data.respCode === 200 || data.respCode === 0) {
      setCountry(data.respData);
    } else {
      console.error("==INTERNAL SERVER ERROR WHILE FETCHING COUNTRIES==");
    }
  };

  const getAllPortList = async () => {
    const data = await CallApiGet("getAllPort");
    if (data.respCode === 200 || data.respCode === 0) {
      setDeliveryPort(data.respData);
    } else {
      console.error("==INTERNAL SERVER ERROR WHILE FETCHING PORTS==");
    }
  };

  const getShipmetList = async () => {
    setShow(true);
    const data = await CallApiGet("viewShipment");
    switch (data.respCode) {
      case 200:
      case 0:
        setContentDocList(data.respData);
        break;
      case 500:
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        openPopup(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        openPopup(false, "ERROR", data.respMessage);
        break;
    }
    setShow(false);
  };

  const openPopup = (boolData, title, desc) => {
    setIsSuccess(boolData);
    setTitle(title);
    setDescription(desc);
    modalRef.current.click();
  };

  const callback = () => {
    getShipmetList();
  };

  const clearShipment = () => {
    setShipment(JSON.parse(JSON.stringify(intiShipmentData)));
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="px-2 mt-2">
              <div className="clearfix border-bottom pb-2">
                <div className="float-start">
                  <div className="section-title p-2 mt-1">
                    Shipment Content and Document Management
                  </div>
                </div>
                <div className="float-end"></div>
                <div className="float-end d-flex">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                  <div className="float-end">
                    <button
                      className="btn btn-outline-secondary theme-violet text-nowrap"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#shipment-modal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>{" "}
                      ADD Shipment Content
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ContentDocList
              contentDocMgmList={contentDocMgmList}
              setShipment={setShipment}
              openPopup={openPopup}
              setShow={setShow}
            />
          </main>
        </div>
        <ContentDocAdd
          country={country}
          deliveryPort={deliveryPort}
          contentData={shipment}
          openPopup={openPopup}
          intiShipmentData={intiShipmentData}
          initDoc={initDoc}
          clearShipment={clearShipment}
        />
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
    </>
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPersonalId,
  getSessionInfo,
  getUserInfo,
} from "../../AuthUtils/AuthUtils";
import {
  ALPHANUM,
  ALPHANUM_SPACE_REGEX,

  NUMBER_TWO_DECIMAL,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";

import GetSvgIcon from "../../../images/svglist";

const initUserSession = {
  userId: 0,
  sessionId: 0,
  personalId: 0,
};
const axiosHeadersInit = {
  headers: {
    sessionId: 0,
    userId: 0,
  },
};



const InsuranceTaxConfig = () => {
  // const insuranceDataModel = {packageId:"",packageName:"", valueFrom: "", valueTo: "", price: "", percentage:""};
  const insuranceDataModel = {
    insuranceId: 0,
    packageId: "",
    packageName: "",
    coverage: "",
    price: "",
    tax: "",
    active: true,
  };
  const taxDataModel = { country: "", name: "", rate: "" };
  const initialInsuranceError = {
    packageId: false,
    packageName: false,
    coverage: false,
    price: false,
    tax: false,
  };
  const initialTaxError = { country: false, name: false, rate: false };
  const [insData, setInsData] = useState([insuranceDataModel]);
  const [taxData, setTaxData] = useState([taxDataModel]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [saveFail, setSaveFail] = useState(false);
  const [insErrors, setInsErrors] = useState([
    JSON.parse(JSON.stringify(initialInsuranceError)),
  ]);
  const [taxErrors, setTaxErrors] = useState([
    JSON.parse(JSON.stringify(initialTaxError)),
  ]);
  const [userSession, setUserSession] = useState(initUserSession);
  const [axiosHeaders, setAxiosHeaders] = useState(axiosHeadersInit);
  const navigate = useNavigate();
  const [countryCodes,setCountryCodes]=useState([]);


  useEffect(() => {
    const userData = {
      userId: getUserInfo("userId"),
      sessionId: getSessionInfo(),
      personalId: getPersonalId(),
    };

    const axiosHeadersModel = {
      headers: {
        sessionId: getSessionInfo(),
        userId: getUserInfo("userId"),
      },
    };
    setAxiosHeaders(axiosHeadersModel);
    setUserSession(userData);

    getAllData(getUserInfo("userId"), getSessionInfo(), getPersonalId());
  }, []);
  const addInsTableRows = () => {
    const rowsInput = {
      insuranceId: 0,
      packageId: "",
      packageName: "",
      coverage: "",
      price: "",
      tax: "",
      active: true,
    };
    setInsData([...insData, rowsInput]);
    console.log('===========', insData);
    const newInsErrorInput = {
      insuranceId: false,
      packageId: false,
      packageName: false,
      //valueFrom: false,
      coverage: false,
      price: false,
      tax: false,
    };
    const currentInsErrors = [...insErrors];
    currentInsErrors.push(newInsErrorInput);
    setInsErrors(currentInsErrors);
  };

  const deleteInsTableRows = (index) => {
    const rows = [...insData];
    rows[index].active = false;
    setInsData(rows);
    console.log('===Delete Insur====');
    console.log(insData);

    const insErr = [...insErrors];
    insErr.splice(index, 1);
    setInsErrors(insErr);
  };

  const handleInsChange = (index, evnt) => {
    let { name, value } = evnt.target;
    const newInsEr = [...insErrors];
    if (name === "packageId") {
      if (ALPHANUM.test(value)) {
        const updatedInsErr = { ...insErrors[index], [name]: false };
        newInsEr[index] = updatedInsErr;
      } else {
        const updatedInsErr = { ...insErrors[index], [name]: true };
        newInsEr[index] = updatedInsErr;
      }
    }
    if (name === "packageName") {
      if (ALPHANUM_SPACE_REGEX.test(value)) {
        const updatedInsErr = { ...insErrors[index], [name]: false };
        newInsEr[index] = updatedInsErr;
      } else {
        const updatedInsErr = { ...insErrors[index], [name]: true };
        newInsEr[index] = updatedInsErr;
      }
    }
    if (name === "price") {
      if (NUMBER_TWO_DECIMAL.test(value)) {
        const updatedInsErr = { ...insErrors[index], [name]: false };
        newInsEr[index] = updatedInsErr;
      } else {
        const updatedInsErr = { ...insErrors[index], [name]: true };
        newInsEr[index] = updatedInsErr;
      }
    }
    setInsErrors(newInsEr);
    const updatedRow = { ...insData[index], [name]: value };
    const rowsInput = [...insData];
    rowsInput[index] = updatedRow;
    setInsData(rowsInput);
  };

  const addTaxTableRows = () => {
    const rowsInput = {
      country: "MY",
      name: "",
      rate: "",
    };
    setTaxData([...taxData, rowsInput]);

    const newTaxErrorInput = {
      country: false,
      name: false,
      rate: false,
    };
    const currentTaxErrors = [...taxErrors];
    currentTaxErrors.push(newTaxErrorInput);
    setTaxErrors(currentTaxErrors);
  };

  const deleteTaxTableRows = (index) => {
    const rows = [...taxData];
    rows.splice(index, 1);
    setTaxData(rows);

    const taxErr = [...taxErrors];
    taxErr.splice(index, 1);
    setTaxErrors(taxErr);
  };

  const handleTaxChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const taxEr = [...taxErrors];
    switch (name) {
      case "rate":
        if (NUMBER_TWO_DECIMAL.test(value)) {
          const updatedTaxEr = { ...taxErrors[index], [name]: false };
          taxEr[index] = updatedTaxEr;
        } else {
          const updatedTaxEr = { ...taxErrors[index], [name]: true };
          taxEr[index] = updatedTaxEr;
        }
        break;
      case "name":
        if (ALPHANUM.test(value)) {
          const updatedTaxEr = { ...taxErrors[index], [name]: false };
          taxEr[index] = updatedTaxEr;
        } else {
          const updatedTaxEr = { ...taxErrors[index], [name]: true };
          taxEr[index] = updatedTaxEr;
        }
        break;
      default:
        const updatedTaxEr = { ...taxErrors[index], [name]: false };
        taxEr[index] = updatedTaxEr;
        break;
    }
    setTaxErrors(taxEr);

    const rowsInput = [...taxData];
    rowsInput[index][name] = value;
    setTaxData(rowsInput);
  };

  const saveIns = async () => {

    //validateRowData
    if (insData.length > 0 && customInsValidation(insData)) {
      if (!validateIsuranceData(insData)) {
        setSaveFail(true);
        setFailMsg("Package Id or Coverage Upto should not be duplicate");
        setTimeout(() => {
          setSaveFail(false);
          setFailMsg();
        }, 5000);
      } else {
        await CallApiPost("saveInsuranceConfig", insData)
          .then(
            (res) => {
              if (res.respCode === 200) {
                setSaveSuccess(true);
                setSuccessMsg("Insurance has been saved successfully");
                setTimeout(() => {
                  setSaveSuccess(false);
                  setSuccessMsg();
                }, 5000);
              } else if (
                res.respCode === 401
              ) {
                setSaveFail(true);
                setFailMsg("Sorry, you don't have the write operation!!");
                setTimeout(() => {
                  setSaveFail(false);
                  setFailMsg();
                }, 5000);
              } else {
                setSaveFail(true);
                setFailMsg(SERVER_ERR_MSG);
                setTimeout(() => {
                  setSaveFail(false);
                  setFailMsg();
                }, 5000);
              }
            },
            (error) => {
              setSaveFail(true);
              setFailMsg(SERVER_ERR_MSG);
              setTimeout(() => {
                setSaveFail(false);
                setFailMsg();
              }, 5000);
            }
          );
      }
    }
  };

  const validateIsuranceData = (data) => {
    let packageIdArr = [];
    let convrageUptoArr = [];
    data.map((insDt, index) => {
      packageIdArr.push(insDt.packageId);
      convrageUptoArr.push(insDt.coverage);
    });
    let packageSet = new Set(packageIdArr);
    let covrageSet = new Set(convrageUptoArr);
    if (
      Array.from(packageSet).length === packageIdArr.length &&
      Array.from(covrageSet).length === convrageUptoArr.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveTax = async () => {
    if (taxData.length > 0 && customTaxValidation()) {
      await CallApiPost("saveTaxConfig", taxData)
        .then(
          (res) => {
            if (res.respCode === 200) {
              setSaveSuccess(true);
              setSuccessMsg("Tax has been saved successfully");
              setTimeout(() => {
                setSaveSuccess(false);
                setSuccessMsg();
              }, 5000);
            } else {
              setSaveFail(true);
              // setDynamicRowValidation();
              setFailMsg(res.respMessage);
              setTimeout(() => {
                setSaveFail(false);
                setFailMsg();
              }, 5000);
            }
            // setDynamicRowValidation();
          },
          (error) => {
            setSaveFail(true);
            // setDynamicRowValidation();
            setFailMsg(error.respMessage);
            setTimeout(() => {
              setSaveFail(false);
              setFailMsg();
            }, 5000);
          }
        );
    }
  };

  const customInsValidation = () => {
    let isValid = true;
    insData.map((insDt, index) => {
      // if (insDt.valueFrom === "") {
      //   const rows = [...insErrors];
      //   rows[index].valueFrom = true;
      //   setInsErrors(rows);
      //   isValid = false;
      // }
      if (insDt.valueTo === "") {
        const rows = [...insErrors];
        rows[index].valueTo = true;
        setInsErrors(rows);
        isValid = false;
      }
      if (insDt.price === "") {
        const rows = [...insErrors];
        rows[index].price = true;
        setInsErrors(rows);
        isValid = false;
      }
    });

    return isValid;
  };

  const customTaxValidation = () => {
    let isValid = true;
    taxData.map((taxDt, index) => {
      if (taxDt.country === "" || taxDt.country.length > 2) {
        const rows = [...taxErrors];
        rows[index].country = true;
        setTaxErrors(rows);
        isValid = false;
      }

      if (taxDt.name === "") {
        const rows = [...taxErrors];
        rows[index].name = true;
        setTaxErrors(rows);
        isValid = false;
      }
      if (taxDt.rate === "") {
        const rows = [...taxErrors];
        rows[index].rate = true;
        setTaxErrors(rows);
        isValid = false;
      }
    });
    return isValid;
  };

  const getAllData = async (uId, sId, pId) => {
    await CallApiGet("getAllTaxConfigData")
      .then(
        (response) => {
          if (response.respCode === 200) {
            let data = response.respData;
            setInsData(data.insuranceData);
            setTaxData(data.taxData);
            addInitialErrorRows(data.insuranceData, data.taxData);
          } else if (response.respCode === 401) {
            setInsData(insuranceDataModel);
            setTaxData(taxDataModel);
            setSaveFail(true);
            setFailMsg("UNAUTHORIZED");
            setTimeout(() => {
              setSaveFail(false);
              setFailMsg();
            }, 5000);
          } else {
            setSaveFail(true);
            setFailMsg(SERVER_ERR_MSG);
            setTimeout(() => {
              setSaveFail(false);
              setFailMsg();
            }, 5000);
          }
        },
        (error) => {
          setSaveFail(true);
          setFailMsg("Unable Fetch Data From Server");
          setTimeout(() => {
            setSaveFail(false);
            setFailMsg();
          }, 5000);
        }
      );
  };

  const addInitialErrorRows = (insDatas, taxDatas) => {
    let taxInitError = [];
    let insInitError = [];

    for (let index = 0; index < insDatas.length; index++) {
      insInitError.push(initialInsuranceError);
    }
    setInsErrors(insInitError);

    for (let index = 0; index < taxDatas.length; index++) {
      taxInitError.push(initialTaxError);
    }
    setTaxErrors(taxInitError);
  };
  useEffect(()=>{
    getCountryList()
  },[taxData])
const getCountryList = async () => {
  let countryArr = [];
  let data = await CallApiPost("SysOptionGetByCode", ["COUNTRY_CODE"]);
  if (data.respCode === 0) {
    data.respData.forEach((element) => {
      countryArr.push({
        value: element.optionValue,
        code: element.optionValue,
      });
    });
    setCountryCodes(countryArr);
  }
};
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">
                  Insurance and Tax Configuration
                </div>
              </div>
              <div className="float-end d-flex"></div>
            </div>
            {saveSuccess && (
              <div className="alert alert-success mt-1" role="alert">
                {" "}
                {successMsg}
              </div>
            )}
            {saveFail && (
              <div id="errorMsg" className="alert alert-danger" role="alert">
                {" "}
                {failMsg}{" "}
              </div>
            )}
            <div className="col-md-12 my-3">
              <div className="row">
                <div className="col-md-7">
                  <div className="border border-3 rounded">
                    <h5 className="p-2">Insurance Premium Charges</h5>
                    <table className="table table-striped table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th scope="col">Package Id</th>
                          <th scope="col">Package Name</th>
                          <th scope="col">Coverage Upto</th>
                          <th scope="col">Insurance Charge</th>
                          <th scope="col">Tax</th>
                          <th className="col-1">
                            <div
                              className="px-2 text-success"
                              onClick={addInsTableRows}
                            >
                              {GetSvgIcon("PlusCircle")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {insData.map((data, index) => {
                          let {
                            insuranceId,
                            packageId,
                            packageName,
                            coverage,
                            price,
                            tax,
                            active
                          } = data;
                          if(active )
                            return (
                              <tr>
                                <td>
                                  <input type="hidden" name="insuranceId" value={insuranceId} />
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={packageId}
                                    onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }
                                    name="packageId"
                                  />
                                  {insErrors[index] &&
                                    insErrors[index].packageId && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; Invalid Data!
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={packageName}
                                    onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }
                                    name="packageName"
                                  />
                                  {insErrors[index] &&
                                    insErrors[index].packageName && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; Invalid Data!
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={coverage}
                                    onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }
                                    name="coverage"
                                  />
                                  {insErrors[index] &&
                                    insErrors[index].coverage && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; Invalid Data!
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={price}
                                    onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }
                                    name="price"
                                  />
                                  {insErrors[index] && insErrors[index].price && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Invalid Data!
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={tax}
                                    onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }
                                    name="tax"
                                  />
                                  {insErrors[index] && insErrors[index].tax && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Invalid Data!
                                      </span>
                                    </div>
                                  )}
                                </td>                              {/* <td>
                                  <select className="form-control w-75" name="percentage" onChange={(evnt) =>
                                      handleInsChange(index, evnt)
                                    }>
                                    {
                                      percentageOpt.map((opt,index)=>{
                                        if (opt.value === percentage) {
                                          return <option value={opt.value} selected>{opt.label}</option>
                                        } else {
                                          return <option value={opt.value}>{opt.label}</option>
                                        }
                                      })
                                    }
                                  </select>
                                </td> */}
                                <td className="col-1">
                                  <div
                                    className="px-2 text-danger"
                                    onClick={() => deleteInsTableRows(index)}
                                  >
                                    {GetSvgIcon("DashCircleNoFill")}
                                  </div>
                                </td>
                              </tr>
                            );
                        })}
                      </tbody>
                    </table>
                    <div className="clearfix col-md-2 m-auto my-3">
                      <button
                        className="btn btn-outline-secondary theme-violet"
                        onClick={saveIns}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="border border-3 rounded">
                    <h5 className="p-2">International Tax Configuration</h5>
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Country</th>
                          <th scope="col">Name</th>
                          <th scope="col">Rate %</th>
                          <th className=" col-1">
                            <div
                              className="px-2 text-success"
                              onClick={addTaxTableRows}
                            >
                              {GetSvgIcon("PlusCircle")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxData.map((data, index) => {
                          let { country, name, rate } = data;
                          return (
                            <tr>
                              <td>
                                <select
                                  className="form-control"
                                  name="country"
                                  onChange={(evnt) =>
                                    handleTaxChange(index, evnt)
                                  }
                                >
                                  {countryCodes.map((opt) => {
                                    if (opt.code === country) {
                                      return (
                                        <option value={opt.code} selected>
                                          {opt.value}
                                        </option>
                                      );
                                    } else {
                                      return (
                                        <option value={opt.code}>
                                          {opt.value}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                                {taxErrors[index] &&
                                  taxErrors[index].country && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Invalid Data!
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={name}
                                  onChange={(evnt) =>
                                    handleTaxChange(index, evnt)
                                  }
                                  name="name"
                                />
                                {taxErrors[index] && taxErrors[index].name && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; Invalid Data!
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={rate}
                                  onChange={(evnt) =>
                                    handleTaxChange(index, evnt)
                                  }
                                  name="rate"
                                />
                                {taxErrors[index] && taxErrors[index].rate && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; Invalid Data!
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td className="col-1">
                                <div
                                  className="px-2 text-danger"
                                  onClick={() => deleteTaxTableRows(index)}
                                >
                                  {GetSvgIcon("DashCircleNoFill")}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="clearfix col-md-2 m-auto my-3">
                      <button
                        className="btn btn-outline-secondary theme-violet"
                        onClick={saveTax}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InsuranceTaxConfig;

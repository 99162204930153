import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { customReactSelectMenuStyle } from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import Select from "react-select";
import GetSvgIcon from "../../images/svglist";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { CallApiPost } from "../Helper/serviceApi";

const initObj = {
  origin: "",
  originId: 0,
  destId: 0,
  destination: "",
  vehicleId: 0,
  driver1: 0,
  driver2: 0,
  sta: "",
  std: "",
};

const CharteredPlanning = ({
  show,
  hide,
  dropDownOpts,
  selOrder,
  handleRespPostApiCall,
  setShowSpinner,
}) => {
  const [seqCnt, setSeqCnt] = useState(0);
  const {
    control,
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      itinArr: [initObj],
    },
  });

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "itinArr",
  });

  useEffect(() => {
    reset(selOrder);
  }, [selOrder]);

  const handleClose = () => {
    reset();
    hide();
  };

  const handleAdd = () => {
    let locObj = Object.assign({}, initObj);
    let locItinArr = getValues("itinArr");
    const currLastIndex = locItinArr.length - 1;
    locObj.destination = locItinArr[currLastIndex].destination;
    locObj.vehicleId = locItinArr[currLastIndex].vehicleId;
    locObj.driver1 = locItinArr[currLastIndex].driver1;
    locObj.driver2 = locItinArr[currLastIndex].driver2;
    locItinArr[currLastIndex].destination = "";
    locItinArr.push({ ...locObj });
    replace([...locItinArr]);
  };

  const handleRemove = (index) => {
    let locItinArr = getValues("itinArr");
    const itinSize = locItinArr.length;
    const prevIndex = index - 1;
    const prevToPrevIndex = prevIndex - 1;
    let prevRow = locItinArr[prevIndex];
    let prevToPrevRow = locItinArr[prevToPrevIndex];
    let currRow = getValues(`itinArr.${index}`);
    if (index === itinSize - 1) {
      prevRow.destId = 0;
      prevRow.destination = currRow.destination;
    } else {
      prevRow.destId = currRow.destId;
    }
    if (prevIndex !== 0) {
      prevRow.originId = prevToPrevRow.destId;
    }
    locItinArr.splice(index, 1);
    locItinArr[prevIndex] = { ...prevRow };
    replace([...locItinArr]);
  };

  const setPrevDestAndUpdCurrOrigin = (argIndex, argValue) => {
    const prevRowIndex = argIndex - 1;
    let locItinArr = getValues("itinArr");
    locItinArr[prevRowIndex].destId = argValue;
    locItinArr[argIndex].originId = argValue;
    replace([...locItinArr]);
  };

  const setNextOriginAndUpdCurrDest = (argIndex, argValue) => {
    const nextRowIndex = argIndex + 1;
    let locItinArr = getValues("itinArr");
    locItinArr[nextRowIndex].originId = argValue;
    locItinArr[argIndex].destId = argValue;
    replace([...locItinArr]);
  };

  // const generateItinObj = (
  //   currIndex,
  //   lastIndex,
  //   element,
  //   itinMap,
  //   key,
  //   stopsCntMap
  // ) => {
  //   let stopsCnt = stopsCntMap.get(key);
  //   if (currIndex === 0) {
  //     const itinObjOrg = {
  //       stopsCnt: stopsCnt + 1,
  //       planCode: key,
  //       stopSeq: stopsCnt,
  //       stopId: 0,
  //       originAddr: getValues("orgAddr"),
  //       originCity: getValues("orgCity"),
  //       originState: getValues("orgState"),
  //       originPostcode: getValues("orgPostcode"),
  //       sta: "",
  //       std: element.std,
  //     };
  //     itinMap.set(key + "-" + getValues("origin") + "-o", itinObjOrg);
  //     stopsCnt = stopsCnt + 1;
  //   } else {
  //     let orgItin = itinMap.get(key + "-" + element.originId);
  //     if (!!orgItin) {
  //       orgItin.std = element.std;
  //       itinMap.set(key + "-" + element.originId, orgItin);
  //     } else {
  //       const itinObjOrg = {
  //         stopsCnt: stopsCnt + 1,
  //         planCode: key,
  //         stopSeq: stopsCnt,
  //         stopId: element.originId,
  //         sta: "",
  //         std: element.std,
  //       };
  //       itinMap.set(key + "-" + element.originId, itinObjOrg);
  //       stopsCnt = stopsCnt + 1;
  //     }
  //   }

  //   if (currIndex === lastIndex) {
  //     const itinObjDest = {
  //       stopsCnt: stopsCnt + 1,
  //       planCode: key,
  //       stopSeq: stopsCnt,
  //       stopId: 0,
  //       destAddr: getValues("destAddr"),
  //       destCity: getValues("destCity"),
  //       destState: getValues("destState"),
  //       destPostcode: getValues("destPostcode"),
  //       sta: element.sta,
  //       std: "",
  //     };
  //     itinMap.set(key + "-" + getValues("destination") + "-d", itinObjDest);
  //     stopsCnt = stopsCnt + 1;
  //   } else {
  //     const itinObjDest = {
  //       stopsCnt: stopsCnt + 1,
  //       planCode: key,
  //       stopSeq: stopsCnt,
  //       stopId: element.destId,
  //       sta: element.sta,
  //       std: "",
  //     };
  //     itinMap.set(key + "-" + element.destId, itinObjDest);
  //     stopsCnt = stopsCnt + 1;
  //   }
  //   stopsCntMap.set(key, stopsCnt);
  // };

  // Function to create an itinerary object
  const createItinObj = (
    stopId,
    isOrigin,
    isFillAddr,
    key,
    stopsCnt,
    std,
    sta
  ) => ({
    stopsCnt: stopsCnt + 1,
    planCode: key,
    stopSeq: stopsCnt,
    stopId: stopId,
    sta: sta || "",
    std: std || "",
    ...(isFillAddr
      ? {
          stopAddr: isOrigin ? getValues("orgAddr") : getValues("destAddr"),
          stopCity: isOrigin ? getValues("orgCity") : getValues("destCity"),
          stopState: isOrigin ? getValues("orgState") : getValues("destState"),
          stopPostcode: isOrigin
            ? getValues("orgPostcode")
            : getValues("destPostcode"),
        }
      : {
          stopAddr: "",
          stopCity: "",
          stopState: "",
          stopPostcode: "",
        }),
  });

  {
    /*
     * @param {number} currIndex - The current index in the loop.
     * @param {number} lastIndex - The last index in the loop.
     * @param {Object} element - The current row of data being processed.
     * @param {Map} itinMap - The map storing itinerary data.
     * @param {string} key - The unique key for the current plan.
     * @param {Map} stopsCntMap - The map storing the count of stops for each plan.
     */
  }
  const generateItinObj = (
    currIndex,
    lastIndex,
    element,
    itinMap,
    planMap,
    key
  ) => {
    let planObj = planMap.get(key);
    let stopsCnt = planObj.stopsCnt;

    if (currIndex === 0) {
      //=== First row has origin so insert the Origin to itin
      const itinObjOrg = createItinObj(
        0,
        true,
        true,
        key,
        stopsCnt,
        element.std
      );
      itinMap.set(key + "-" + getValues("origin") + "-o", itinObjOrg);
      stopsCnt += 1;
    } else {
      //=== If it is not the first index then insert the origin id as these we get from dropdown sbu
      //=== Always insert the departure time of origin
      const itinKey = key + "-" + element.originId;
      let orgItin = itinMap.get(itinKey);

      if (!!orgItin) {
        //===If origin already exists for a specific plan and stop then update the departure time
        orgItin.std = element.std;
      } else {
        //===If stop exists but the plan has changed then insert a new key and value
        orgItin = createItinObj(
          element.originId,
          false,
          false,
          key,
          stopsCnt,
          element.std
        );
        stopsCnt += 1;
      }
      itinMap.set(itinKey, orgItin);
    }

    if (currIndex === lastIndex) {
      const itinObjDest = createItinObj(
        0,
        false,
        true,
        key,
        stopsCnt,
        "",
        element.sta
      );
      itinMap.set(key + "-" + getValues("destination") + "-d", itinObjDest);
      stopsCnt += 1;
    } else {
      const itinObjDest = createItinObj(
        element.destId,
        false,
        false,
        key,
        stopsCnt,
        "",
        element.sta
      );
      itinMap.set(key + "-" + element.destId, itinObjDest);
      stopsCnt += 1;
    }

    planObj.stopsCnt = stopsCnt;
    planMap.set(key, planObj);
  };

  {
    /*
     * This function will loop over the incoming data and tranform the each row of UI into API Request
     * Three map is taken into account: planMap, itinMap, StopsCntMap
     * planMap stores the unique vehicle-driver1-driver1 combinaton
     * itinMap stores the unique vehicle-driver1-driver2-(origin_addr/destination_addr/sbu_id)
     * stopsCntMap stores the count of stops for each plan (vehicle-driver1-driver2)
     * Logic:
     * 1. Iterate over the incoming data.
     * 2. If the current index is 0, store origin details in itinMap.
     * 3. If the current index is the last, store destination details in itinMap.
     * 4. For intermediate indices, update origin departure time and insert destination arrival time.
     * 5. If the plan changes, insert a new entry in itinMap without updating the origin departure time.
     */
  }
  const generatePayload = (data) => {
    const acctCode = getValues("acctCode");
    const orderNo = getValues("orderNo");
    const userCode = getUserInfo("accountCode");
    const planMap = new Map();
    const itinMap = new Map();
    const routeCodePrefix = "RC" + acctCode;
    const today = moment().format("YYYYMMDDHHmmSSSS");
    data.itinArr.forEach((element, index) => {
      const key =
        element.vehicleId + "-" + element.driver1 + "-" + element.driver2;

      if (!planMap.has(key)) {
        //=== Add unique plan against the key
        planMap.set(key, {
          stopsCnt: 0,
          planCode: key,
          planId: 0,
          routeCode: routeCodePrefix + "-" + today,
          tripStart: element.std,
          tripDesc: "Trip for order - " + orderNo,
          vehicleId: element.vehicleId,
          driver1: element.driver1,
          driver2: element.driver2,
          status: 572,
          remarks:
            "Manual trip assigned by " +
            userCode +
            " for " +
            acctCode +
            " of " +
            orderNo,
          isTruckAdded: false,
          planType: 657,
        });
      }
      generateItinObj(
        index,
        data?.itinArr.length - 1,
        element,
        itinMap,
        planMap,
        key
      );
    });
    const planArr = planMap.values().toArray();
    let itinArr = itinMap.values().toArray();

    return {
      planArr: planArr,
      itinArr: itinArr,
      routeArr: [
        {
          routeType: 31,
          routeCode: routeCodePrefix + "-" + today,
          active: true,
          originAddr: getValues("orgAddr"),
          originCity: getValues("orgCity"),
          originState: getValues("orgState"),
          originPostcode: getValues("orgPostcode"),
          destAddr: getValues("destAddr"),
          destCity: getValues("destCity"),
          destState: getValues("destState"),
          destPostcode: getValues("destPostcode"),
          customerId: getValues("customerId"),
          shareSpace: false,
          remarks: "Route for customer " + getValues("custName"),
        },
      ],
    };
  };

  const onSubmit = async (data) => {
    setShowSpinner(true);
    let payload = generatePayload(data);
    payload.orderId = getValues("orderId");
    const resp = await CallApiPost("SaveAdhocPlan", payload);
    console.log(payload);
    resp.respMessage =
      "Adhoc Planning successfully done for order " +
      (getValues("orderNo") || "");
    handleClose();
    handleRespPostApiCall(resp);
    setShowSpinner(false);
  };

  return (
    <div>
      <Modal fullscreen show={show} onHide={handleClose}>
        <Modal.Header closeButton className="theme-header">
          <Modal.Title>ORDER PLANNING</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <label className="form-label fw-bold">Origin</label>
              <div className="grd-bg p-2 rounded-3">{watch("origin")}</div>
            </Col>
            <Col md={4}>
              <label className="form-label fw-bold">Destination</label>
              <div className="grd-bg p-2 rounded-3">{watch("destination")}</div>
            </Col>
            <Col md={3}>
              <label className="form-label fw-bold">Shipment Date</label>
              <div className="grd-bg p-2 rounded-3">
                {watch("shipmentDate")}
              </div>
            </Col>
            <Col
              md={1}
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                className="rounded-circle theme-violet"
                onClick={handleAdd}
              >
                Add Stop
              </Button>
            </Col>
          </Row>
          <div className="my-2 px-4 route-line ">
            <Row className="mt-3">
              {fields.map((row, index) => (
                <Card key={row.id} className="p-0 shadow-lg mb-1">
                  <Card.Header className="theme-header">
                    <div className="d-flex justify-content-between">
                      <span>ADD DETAILS</span>
                      {index !== 0 && (
                        <span onClick={() => handleRemove(index)}>
                          {GetSvgIcon("Cross")}
                        </span>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body className="p-2">
                    <Row>
                      <Col md={4}>
                        <label className="form-label theme-label">Origin</label>
                        <div>
                          {index === 0 ? (
                            <textarea
                              {...register(`itinArr.${index}.origin`)}
                              className="form-control"
                              placeholder="Disabled Origin"
                              disabled
                            ></textarea>
                          ) : (
                            <Controller
                              name={`itinArr.${index}.originId`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={dropDownOpts?.sbuArr}
                                  value={dropDownOpts?.sbuArr.find(
                                    (el) => el.value === value
                                  )}
                                  onChange={(opt) => {
                                    // onChange(!!opt ? opt.value : 0);
                                    // setPrevDest(index, !!opt ? opt.value : 0);
                                    setPrevDestAndUpdCurrOrigin(
                                      index,
                                      !!opt ? opt.value : 0
                                    );
                                  }}
                                  placeholder="Select Sbu"
                                  isSearchable={true}
                                  isClearable={true}
                                  className="form-control p-0"
                                  menuPortalTarget={document.body}
                                  styles={customReactSelectMenuStyle}
                                />
                              )}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        <label className="form-label theme-label">
                          Destination
                        </label>
                        <div>
                          {index === fields.length - 1 ? (
                            <textarea
                              {...register(`itinArr.${index}.destination`)}
                              className="form-control"
                              placeholder="Disabled Destination"
                              disabled
                            ></textarea>
                          ) : (
                            <Controller
                              name={`itinArr.${index}.destId`}
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={dropDownOpts?.sbuArr}
                                  value={dropDownOpts?.sbuArr.find(
                                    (el) => el.value === value
                                  )}
                                  onChange={(opt) => {
                                    // onChange(!!opt ? opt.value : 0);
                                    // setNextOrigin(index, !!opt ? opt.value : 0);
                                    setNextOriginAndUpdCurrDest(
                                      index,
                                      !!opt ? opt.value : 0
                                    );
                                  }}
                                  placeholder="Select Sbu"
                                  isSearchable={true}
                                  isClearable={true}
                                  className="form-control p-0"
                                  menuPortalTarget={document.body}
                                  styles={customReactSelectMenuStyle}
                                />
                              )}
                            />
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        <label className="form-label theme-label">
                          Vehicle
                        </label>
                        <div>
                          <Controller
                            name={`itinArr.${index}.vehicleId`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={dropDownOpts?.vhclArr}
                                value={dropDownOpts?.vhclArr.find(
                                  (el) => el.value === value
                                )}
                                onChange={(opt) =>
                                  !!opt ? onChange(opt.value) : onChange(0)
                                }
                                placeholder="Select Vehicle"
                                isSearchable={true}
                                isClearable={true}
                                className="form-control p-0"
                                menuPortalTarget={document.body}
                                styles={customReactSelectMenuStyle}
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label className="form-label theme-label">
                          Departure
                        </label>
                        <div>
                          <Controller
                            name={`itinArr.${index}.std`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <ReactDatePicker
                                placeholderText="Trip Date"
                                wrapperClassName="w-100"
                                className="form-control"
                                dateFormat="dd-MM-yyyy hh:mm aa"
                                selected={value ? new Date(value) : value}
                                minDate={new Date()}
                                onChange={(d) =>
                                  onChange(
                                    !!d
                                      ? moment(d).format("YYYY-MM-DD HH:mm")
                                      : d
                                  )
                                }
                                showTimeInput
                                showYearDropdown
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <label className="form-label theme-label">
                          Arrival
                        </label>
                        <div>
                          <Controller
                            name={`itinArr.${index}.sta`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <ReactDatePicker
                                placeholderText="Trip Date"
                                wrapperClassName="w-100"
                                className="form-control"
                                dateFormat="dd-MM-yyyy hh:mm aa"
                                selected={value ? new Date(value) : value}
                                minDate={new Date()}
                                onChange={(d) =>
                                  onChange(
                                    !!d
                                      ? moment(d).format("YYYY-MM-DD HH:mm")
                                      : d
                                  )
                                }
                                showTimeInput
                                showYearDropdown
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <label className="form-label theme-label">
                          Driver 1
                        </label>
                        <div>
                          <Controller
                            name={`itinArr.${index}.driver1`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={dropDownOpts?.drArr}
                                value={dropDownOpts?.drArr.find(
                                  (el) => el.value === value
                                )}
                                onChange={(opt) =>
                                  !!opt ? onChange(opt.value) : onChange(0)
                                }
                                placeholder="Select Driver"
                                isSearchable={true}
                                isClearable={true}
                                className="form-control p-0"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col md={2}>
                        <label className="form-label theme-label">
                          Driver 2
                        </label>
                        <div>
                          <Controller
                            name={`itinArr.${index}.driver2`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                options={dropDownOpts?.drArr}
                                value={dropDownOpts?.drArr.find(
                                  (el) => el.value === value
                                )}
                                onChange={(opt) =>
                                  !!opt ? onChange(opt.value) : onChange(0)
                                }
                                placeholder="Select Driver"
                                isSearchable={true}
                                isClearable={true}
                                className="form-control p-0"
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="save-btn"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CharteredPlanning;

import React from "react";
import "./Popup.css";
import GetSvgIcon from "../../images/svglist";

const Popup = (props) => {
  // console.log(props);
  let { isSuccess, description, title, requestId,handler } = props;

  const callback =()=>{
    if(isSuccess && !!handler){
      handler();
    }
  }
  return (
    <div
      className="modal fade"
      id="success-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog">
        <div className="modal-content">

          <div className="modal-body modal-scroll overflow-auto">
            {isSuccess ? (
              <div className="pop-up">
                {GetSvgIcon("TickMark")}
                <h1 className="text-success">{title}</h1>
                <p>{description} </p>
                {!!requestId && <p className="fs-6">Request-Id: {requestId}</p>}
              </div>
            ) : (
              <div className="pop-up">
                {GetSvgIcon("ExclamationTriangleFill")}
                <h1 className="text-danger">{title}</h1>
                <p>{description}</p>
                {!!requestId && <p className="fs-6">Request-Id: {requestId}</p>}
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn col-md-2 m-auto mb-3 theme-violet"
            data-bs-dismiss="modal"
            onClick={callback}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;

import React, { useEffect, useState } from "react";
import GetSvgIcon, { GetSvgHistory } from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import LonghaulHistory from "./LonghaulHistory";
import { CallApiGet } from "../../Helper/serviceApi";

const LonghaulCommissionList = (props) => {
  const {
    handleAction,
    lhComm,
    handleActiveDeactive,
    toggleAlert,
    // status,
  } = props;

  const [showHistory, setShowHistory] = useState(false);
  const [selHistory, setSelHistory] = useState([]);

  const handleCLose = () => {
    setShowHistory(false);
  };

  const handleHistoryBtnClick = async (row) => {
    const uri = "GetLongHaulCommLog/" + row.id;
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setSelHistory(data.respData);
      setShowHistory(true);
    } else if (data.respCode === 401) {
      console.error(data);
    } else {
      console.error(data);
    }
  };

  const columns = [
    {
      name: "Vehicle Type",
      selector: (row) => row.vhclType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Hub/Depot",
      selector: (row) => row.sbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "1st Point Charge",
      selector: (row) => row.baseCharge,
      sortable: true,
      filterable: true,
    },
    {
      name: "Drop Point Charge",
      selector: (row) => row.subBreakCharge,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => (row?.isActive ? "Active" : "In-Active"),
    //   cell: (row) => <span>{row?.isActive ? "Active" : "In-Active"}</span>,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            // className="me-3"
            data-tooltip-id={"edit-" + row.id}
            onClick={() => handleAction(row)}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "40px",
    },

    {
      name: "",
      cell: (row) => (
        <>
          <div
            // className="me-3"
            data-tooltip-id={"history" + row.id}
            onClick={() => handleHistoryBtnClick(row)}
          >
            {GetSvgHistory("History")}
          </div>
          <Tooltip id={"history" + row.id} content={"History"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "40px",
    },
    // {
    //   name: "",
    //   cell: (row) => (
    //     <>
    //       <div
    //         data-tooltip-id={"vhcl-" + row.id}
    //         onClick={() => {
    //           handleActiveDeactive(row);
    //           toggleAlert(true);
    //         }}
    //       >
    //         {row.isActive === true
    //           ? GetSvgIcon("BixCircle")
    //           : GetSvgIcon("BiCheck2Circle")}
    //       </div>
    //       <Tooltip
    //         id={"vhcl-" + row.id}
    //         content={row.isActive === true ? "Deactivate " : "Activate "}
    //         place="left"
    //       />
    //     </>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: "30px",
    // },
  ];

  return (
    <>
      <div className="col-md-12 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={lhComm}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
        />
      </div>
      <LonghaulHistory
        show={showHistory}
        handleCLose={handleCLose}
        data={selHistory}
      />
    </>
  );
};

export default LonghaulCommissionList;

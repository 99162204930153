import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import { CallApiGet } from "../Helper/serviceApi";
import InvoiceDetails from "./InvoiceDetails";
import {
  LINEHAUL_CUSTOMER_BUSINESS_PARTNERS,
  UNPAID_INV_STAT_ID,
} from "../../ConfigConstants/ConfigConstants";

export const custConditionalStyle = [
  {
    when: (row) => row.invoiceAmount === row.paymentAmount,
    style: {
      color: "#43cb43",
      fontWeight: 500,
    },
  },
  {
    when: (row) => row.invoiceAmount !== row.paymentAmount,
    style: {
      color: "red",
      fontWeight: 500,
    },
  },
];

export const custConditionalStyle2 = [
  {
    when: (row) => row.stat_id === 100,
    style: {
      color: "#43cb43",
      fontWeight: 500,
    },
  },
  {
    when: (row) => row.stat_id === 178,
    style: {
      color: "red",
      fontWeight: 500,
    },
  },
  {
    when: (row) => row.stat_id === 710 || row.stat_id === 711,
    style: {
      color: "#964b00",
      fontWeight: 500,
    },
  },
];

export const invColumns = [
  {
    name: "Trans Date",
    selector: (row) => moment(row.trans_date).format(DATE_FORMAT_MOMENT),
    // selector: (row) => new Date(row.trans_date),
    cell: (row) => moment(row.trans_date).format(DATE_TIME_FORMAT_MOMENT),
    // sortable: true,
    filterable: true,
    // omit: hideColums,
    // sortType: (a, b) => {
    //   return new Date(b.values.trans_date) - new Date(a.values.trans_date);
    // },
  },
  {
    name: "Inv/Payment No",
    selector: (row) => row.ref_no,
    sortable: true,
    filterable: true,
    // omit: hideColums,
    cell: (row) => (
      <>
        <div>
          {row.ref_no}
          {!!row.ref_no && row.ref_no.includes("MTR") && (
            <span
              data-tooltip-id={"msg-" + row.ref_no}
              className="mx-2 my-3 theme-violet-txt position-absolute top-0 end-0"
            >
              {GetSvgIcon("ToolTip")}
            </span>
          )}
        </div>
        <Tooltip
          id={"msg-" + row.ref_no}
          content={
            !!row.ref_no && row.ref_no.includes("MTR") ? row.mp_note : ""
          }
          place="right"
          style={{ zIndex: "100" }}
        />
      </>
    ),
  },
  {
    name: "Transaction Detail",
    selector: (row) =>
      row.particulars + (!!row.mp_note ? " (" + row.mp_note + ")" : ""),

    sortable: true,
    filterable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    filterable: true,
  },
  {
    name: "Debit",
    filterable: true,
    selector: (row) => row.dr_amt.toFixed(2),
    conditionalCellStyles: custConditionalStyle2,
  },
  {
    name: "Credit",
    selector: (row) => row.cr_amt.toFixed(2),
    filterable: true,
    conditionalCellStyles: custConditionalStyle,
  },
];

export default function InvoiceList(props) {
  const {
    invoiceDataList,
    paymentStatusBtn,
    setInvoiceDistributionList,
    setTotalaBillAmount,
    customerDtls,
    fetchAllInvoiceList,
    unBilledInvArr,
    dnCnList,
    setSpinner,
    latestInvAmt,
    cnAmt,
    openCustCn,
    avlCreditLimit,
    clearSelectedRows,
  } = props;

  useEffect(() => {
    // const permissionVal = permissionCheck(INVS, "allowedRead")?.allowedRead;
    // setHideColums(permissionVal ? false : true);
  }, []);
  const lcInvColumns = [
    ...invColumns,
    {
      name: "",
      cell: (row) =>
        row.inv_id > 0 && !row?.ref_no?.includes("-DN-") ? (
          <>
            <div
              data-tooltip-id={"inv-" + row?.ref_no + row?.trans_date}
              className="me-3"
              onClick={() => invoiceDownload(row)}
            >
              {GetSvgIcon("IconFiledownload")}
            </div>
            <Tooltip
              id={"inv-" + row.ref_no}
              content="Download Invoice"
              place="left"
            />
          </>
        ) : (
          ""
        ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  /**
   * The function `invoiceDownload` generates and downloads an invoice PDF based on the provided order
   * data.
   * @param orderData - The `orderData` parameter is an object that contains information about an order.
   * It likely includes properties such as `invoiceNo` (the invoice number) and `customerType` (the type
   * of customer, which can be either "CREDIT" or something else).
   */
  const invoiceDownload = async (orderData) => {
    props.setSpinner(true);
    let custType = LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(
      String(orderData.cust_type)
    )
      ? true
      : false;
    if (orderData.ref_no.includes("-II-")) {
      custType = false;
    }
    const response = await CallApiGet(
      `generateAllInvoicePdf/${orderData.ref_no}/${custType}`
    );
    setSpinner(false);
    if (response.respCode === 200) {
      window.open(response.respData, "_blank");
    }
  };

  /**
   * The function `selectInvoice` takes an array of selected rows, calculates the total amount for each
   * row, updates the invoice distribution list, and calculates the total bill amount.
   */
  const selectInvoice = ({ selectedRows }) => {
    let dataList = [];
    selectedRows.forEach((v, i) => {
      dataList.push({
        invoiceId: v.inv_id,
        invoiceAmount: v.dr_amt,
        invoiceNo: v.ref_no,
      });
    });
    setInvoiceDistributionList(dataList);
    let totalAmount = 0.0;
    dataList.forEach((v, k) => {
      totalAmount = totalAmount + v.invoiceAmount;
    });
    setTotalaBillAmount(totalAmount);
  };
  const rowDisabledCriteria = (row) => row.stat_id !== UNPAID_INV_STAT_ID;
  return (
    <>
      {/* {paymentStatusBtn ? <InvoiceDetails customerDtls={customerDtls} /> : ""} */}
      <InvoiceDetails
        customerDtls={customerDtls}
        fetchAllInvoiceList={fetchAllInvoiceList}
        unBilledInvArr={unBilledInvArr}
        dnCnList={dnCnList}
        latestInvAmt={latestInvAmt}
        cnAmt={cnAmt}
        openCustCn={openCustCn}
        avlCreditLimit={avlCreditLimit}
      />
      <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
        <DataTable
          columns={lcInvColumns}
          data={invoiceDataList}
          customStyles={customDataTableStyles}
          selectableRows={paymentStatusBtn}
          onSelectedRowsChange={selectInvoice}
          selectableRowDisabled={rowDisabledCriteria}
          clearSelectedRows={clearSelectedRows}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </>
  );
}

import React from "react";
import Layout from "../Layout/Layout";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-tooltip";

const CharteredRateList = ({ handleAction, charteredRateList }) => {
  const columns = [
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
      filterable: true,
    },
    {
      name: "Rate Type",
      selector: (row) => row.label,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Threshold Type",
    //   selector: (row) => row.thresholdType,
    //   sortable: true,
    //   filterable: true,
    // },
    // {
    //   name: "Threshold",
    //   selector: (row) => row.threshold,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Base Charge",
      selector: (row) =>
        !!row.baseCharge && row.baseCharge > 0 ? row.baseCharge : "NA",
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Surcharge",
    //   selector: (row) => row.surcharge,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Unit Price",
      selector: (row) =>
        !!row.unitPrice && row.unitPrice > 0 ? row.unitPrice : "NA",
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row?.rateId}
            onClick={() => handleAction(row, "EDIT Rate")}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row?.rateId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={charteredRateList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default CharteredRateList;

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import GetSvgIcon from "../../images/svglist";
import { CallApiGet } from "../Helper/serviceApi";
import moment from "moment/moment";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  LINEHAUL_CUSTOMER_USER_TYPE_LIST
} from "../../ConfigConstants/ConfigConstants";

function NotificationBell(props) {
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const [notifiList, setNotifiList] = useState([]);
  const userType = getUserInfo("userType");
  const userActive = getUserInfo("userActive");

  useEffect(() => {
    getNotificationCounts();
  }, []);

  const getNotificationCounts = async () => {
    let data = await CallApiGet("notificationCount");
    if (data.respCode === 200 && !!data.respData) {
      setNotificationCount(data.respData[0]?.alertCount);
    }
  };

  const notificationList = async () => {
    let data = await CallApiGet("notificationList");
    if (data.respCode === 200) {
      let notiData = data.respData;
      setNotifiList([...notiData]);
    }
  };

  const viewNotification = async (noti) => {
    if (noti.newNote === 1) {
      let noteUpdates = await CallApiGet(
        "viewNotification?noteId=" + noti.notiId
      );
      await notificationList();
      if (noteUpdates.respCode === 0 || noteUpdates.respCode === 200) {
        let uri = noti.refLink.split("\n")[0]
        if (!!noti.refParam) {
          uri += noti.refParam
        }
        navigate(uri, {
          replace: true,
        });
        navigate(0);
      }
    } else {
      // restrict popover close
    }
  };
  const callHome = () => {
    navigate("/", { replace: true });
  };
  return (
    <>
      <div
        className="float-end mt-3 me-3 position-relative"
        onClick={notificationList}
      >
        <span
          className="notification-bell "
          id="dropdownNoti"
          data-bs-toggle="dropdown"
        >
          <span className="position-absolute badge rounded-pill theme-blue">
            {notificationCount}
            <span className="visually-hidden">unread messages</span>
          </span>
          {GetSvgIcon("IconHeaderBell")}{" "}
        </span>

        <ul
          className="dropdown-menu notification-popover"
          aria-labelledby="dropdownNoti"
        >
          <div className="px-3">
            <div className="mb-3 theme-blue-txt align-items-center row">
              <div className="col">
                <strong>Notification</strong>{" "}
              </div>
              <div className="col font-12 text-end">
                <strong>{notificationCount} Unread Msg(s)</strong>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="msg-body overflow-auto">
              {notifiList.length > 0 ? (
                notifiList.map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.newNote === 1
                        ? "alert alert-primary me-2"
                        : "alert alert-dark opacity-50 me-2 alert-old"
                    }
                    role="alert"
                    onClick={() => viewNotification(item)}
                  >
                    <div className="row align-items-center">
                      <div className="col">{item.note}</div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="font-12 text-end mt-2">
                      <span>
                        {moment(item.createdOn).format("dddd h:mm A")}
                      </span>{" "}
                      |{" "}
                      <span>
                        {moment(item.createdOn).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="msg-body overflow-auto">
                  <div className="alert alert-primary" role="alert">
                    <div className="row align-items-center">
                      <div className="col">No Notifications</div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ul>
      </div>
      {!props.isHome && LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (userActive === "true") ? (
        <div className="float-end mt-3 me-4">
          <button
            className="btn btn-outline-secondary  theme-violet px-4"
            type="button"
            onClick={callHome}
          >
            Book Now
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NotificationBell;

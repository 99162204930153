import React from "react";
import {
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import moment from "moment";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";

const AdvertisementList = (props) => {
  const { tableData, handleEditClick, handleActiveDeactive } = props;
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      filterable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      filterable: true,
    },
    {
      name: "Valid From",
      selector: (row) =>
        !!row.validFrom
          ? moment(row.validFrom, DATE_FORMAT_SERVER, true).format(
              DATE_FORMAT_MOMENT
            )
          : "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Valid To",
      selector: (row) =>
        !!row.validTo
          ? moment(row.validTo, DATE_FORMAT_SERVER, true).format(
              DATE_FORMAT_MOMENT
            )
          : "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "YES" : "NO"),
      filterable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
          },
        },
      ],
      width: "10%",
      // width: "56px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            // className="me-3"
            data-tooltip-id={"edit-" + row.id}
            onClick={() => handleEditClick(row)}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "40px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"Adv-" + row.id}
            onClick={() => {
              handleActiveDeactive(row);
              // toggleAlert(true);
            }}
          >
            {row.active === true
              ? GetSvgIcon("BixCircle")
              : GetSvgIcon("BiCheck2Circle")}
          </div>
          <Tooltip
            id={"Adv-" + row.id}
            content={
              row.active === true
                ? "Deactivate " + row.title
                : "Activate " + row.title
            }
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <div className="col-md-12 customized-data-table position-relative">
      {
        <DataTable
          columns={columns}
          data={tableData}
          noDataComponent="There are no Advertisement"
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      }
    </div>
  );
};

export default AdvertisementList;

import React, { useRef, useState } from "react";
import { Badge, Popover } from "react-bootstrap";
import GetSvgIcon from "../../../images/svglist";
import { LINEHAUL_CUSTOMER_BUSINESS_PARTNERS } from "../../../ConfigConstants/ConfigConstants";

const FareSummaryComponent = (props) => {
  const {
    totalCost,
    bookingCost,
    pickupCost,
    deliveryCost,
    handlingAmount,
    packagingCost,
    insuranceCost,
    taxAmount,
    SurchargeAmount,
    discountAmount,
    iscalculatedOnce,
    activeBlock,
    register,
    watch,
    setValue,
    applyPromoCode,
    promoAmount,
    custType,
    BUSHINESS_USER_TYPE,
    ISR_USER_TYPE,
    availableCredits,
    creditAvl,
    customerId,
    modalRef,
    PERSONAL_USER_TYPE,
    payByCredit,
    payByCreditVal,
    setPromoModalData,
    clearPromoCode,
    sstPercent,
    applyCredit,
    appCreditLimit,
    creditNote,
    creditDisp,
    debitNote,
  } = props;

  const handleClick = (event) => {
    // setPromoOverlay(!isPromoOverlay);
    // setTarget(event.target);
    setPromoModalData();
  };

  return (
    <div className="filled-view-blue">
      {(LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) || custType === ISR_USER_TYPE) &&
        activeBlock === 4 && (
          <>
            <table className="w-100">
              <tr>
                <td className="px-1 subheadingtext">Available Credit:</td>
                <td>
                  <input
                    className="form-control text-end"
                    type="text"
                    value={creditDisp < 0 ? 0 : creditDisp.toFixed(2)}
                    disabled
                  />
                </td>
                <td className="text-end  fw-bold">RM</td>
              </tr>
            </table>
            <hr />
          </>
        )}

      <div className="subheadingtext mb-2 d-flex justify-content-between align-item-center">
        <div>Fare Summary</div>

        {/* <div>
          <span id="fare-reload">{GetSvgIcon("ArrowRepeat")}</span>
          <Tooltip
            anchorId="fare-reload"
            content={
              "Re-Calculate"
            }
            place="top"
          />
        </div> */}
      </div>
      <table className="w-100">
        <tbody>
          {(LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) || custType === ISR_USER_TYPE) &&
            activeBlock === 4 && (
              <>
                <tr>
                  <td className="px-1 text-nowrap font-12">Payment Type:</td>
                  <td className="pt-1" colSpan={2}>
                    <div className="form-check form-check-inline g-col-2 ms-2">
                      <input
                        className="form-check-input formsty"
                        type="radio"
                        name="paymentBy"
                        id="inlineRadio1"
                        value="CASH"
                        {...register("paymentBy")}
                        disabled={!creditAvl || !!customerId}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Cash
                      </label>
                    </div>
                    <div className="form-check form-check-inline g-col-2">
                      <input
                        className="form-check-input formsty"
                        type="radio"
                        name="paymentBy"
                        id="inlineRadio1"
                        value="CREDIT"
                        {...register("paymentBy")}
                        disabled={!creditAvl}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Credit
                      </label>
                    </div>
                  </td>
                </tr>
              </>
            )}
          <tr>
            <td className="px-1 font-12 text-nowrap">Booking Cost:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={bookingCost}
                disabled
              />
            </td>
            <td className="text-end fw-bold">RM</td>
          </tr>
          <tr>
            <td className="px-1 font-12">Pickup Cost:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={pickupCost}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td className="px-1 font-12">Delivery Cost:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={deliveryCost}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td className="px-1 font-12">Handling Cost:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={handlingAmount}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td className="px-1 font-12">Packing Cost:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={packagingCost}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td className="px-1 font-12">Protection Fee:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={insuranceCost}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td colSpan="3" className="py-1"></td>
          </tr>
          {/* <tr>
            <td className="px-1 font-12">Tax:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={taxAmount}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr> */}
          <tr>
            <td className="px-1 font-12">Holiday Surcharge:</td>
            <td className="book-width">
              <input
                className="form-control text-end"
                type="text"
                value={SurchargeAmount}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>

          <tr>
            <td colSpan="3" className="py-1"></td>
          </tr>

          <tr>
            <td className="px-1 font-12">Discount:</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={discountAmount}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>

          {iscalculatedOnce === true && activeBlock === 4 && (
            <>
              <tr>
                <td className="">Promo Code:</td>
                <td colSpan="2" className="position-relative py-3">
                  {watch("isPromoApplied") && (
                    <span
                      className="position-absolute fw-bold close-promocode cursor-pointer"
                      onClick={clearPromoCode}
                    >
                      &#10005;
                    </span>
                  )}
                  <div className="d-flex align-item-center">
                    <div className="me-1 col-auto align-self-center">
                      {GetSvgIcon("SlaPremiumTag")}
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control pe-4"
                        placeholder="#TRYR0987654"
                        {...register("promoCode")}
                        disabled
                      />
                      <button
                        className="btn btn-outline-secondary theme-violet"
                        type="button"
                        id="button-addon2"
                        // onClick={applyPromoCode}
                        onClick={handleClick}
                        disabled={watch("isPromoApplied")}
                      >
                        Choose
                      </button>
                    </div>
                  </div>
                </td>
                {/* <td ref={prormoRef}>
                  <span id="promo-info" className="ms-1 cursor-pointer" onClick={handleClick}>
                    {GetSvgIcon("InfoSquare")}
                  </span>
                   <Overlay
                    show={isPromoOverlay}
                    container={prormoRef}
                    target={target}
                    placement="bottom"
                    rootClose={true}
                  >
                    {popover}
                  </Overlay> 
                  <Tooltip
                    anchorId="promo-info"
                    content={
                      "Click to reveal promo code."
                    }
                    place="top"
                  />
                </td> */}
              </tr>
              <tr>
                <td className="px-1 font-12">Promo Discount:</td>
                <td className="">
                  <input
                    className="form-control text-end"
                    type="text"
                    value={promoAmount}
                    disabled
                  />
                </td>
                <td className="text-end  fw-bold">RM</td>
              </tr>
            </>
          )}
          {custType === PERSONAL_USER_TYPE && debitNote > 0 && (
            <>
              <tr>
                <td></td>
                <td colSpan={2} className="text-danger">
                  you have unpaid amount from previous order
                </td>
              </tr>
              <tr>
                <td className="px-1 font-12 ">Outstanding:</td>
                <td className="">
                  <input
                    className="form-control text-end text-danger"
                    type="text"
                    value={Math.abs(debitNote)}
                    disabled
                  />
                </td>
                <td className="text-end  fw-bold">RM</td>
              </tr>
            </>
          )}

          <tr>
            <td colspan="3">
              <hr />
            </td>
          </tr>
          <tr>
            <td className="px-1">
              <div>
                <strong>Sub Total</strong>
              </div>
              <div className="font-12">(Excluding SST)</div>
            </td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                // value={costExTax}
                value={
                  custType === PERSONAL_USER_TYPE && debitNote > 0
                    ? (totalCost - taxAmount + debitNote).toFixed(2)
                    : (totalCost - taxAmount).toFixed(2)
                }
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            {/* @ {sstPercent}  */}
            <td className="px-1 font-12">SST :</td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={taxAmount}
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          <tr>
            <td colSpan="3" className="py-1"></td>
          </tr>
          <tr>
            <td className="px-1">
              <div>
                <strong>Total Cost </strong>
              </div>
              <div className="font-12">(Including SST)</div>
            </td>
            <td className="">
              <input
                className="form-control text-end"
                type="text"
                value={
                  custType === PERSONAL_USER_TYPE && debitNote > 0
                    ? +totalCost + debitNote
                    : totalCost
                }
                disabled
              />
            </td>
            <td className="text-end  fw-bold">RM</td>
          </tr>
          {activeBlock === 4 &&
            (custType === PERSONAL_USER_TYPE ||
              (LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) && appCreditLimit === 0)) &&
            creditNote > 0 && (
              <>
                <tr>
                  <td colspan="3">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td className="px-1">
                    <strong>Available Credit: </strong>
                  </td>
                  <td className="px-1">
                    <strong className="px-1 text-nowrap font-20">
                      {creditNote} RM
                    </strong>
                  </td>
                  <td className="text-end  fw-bold">
                    <input
                      className="form-check-input p-0 me-2"
                      type="checkbox"
                      onChange={(e) => payByCredit(e.target.checked)}
                      checked={payByCreditVal}
                    />
                    Apply
                  </td>
                </tr>
                {applyCredit && (
                  <tr>
                    <td className="px-1">
                      <div>
                        <strong>Final Cost </strong>
                      </div>
                      <div className="font-12">(Consuming Credit)</div>
                    </td>
                    <td className="">
                      <input
                        className="form-control text-end"
                        type="text"
                        value={(totalCost >= creditNote
                          ? totalCost - creditNote
                          : 0
                        ).toFixed(2)}
                        disabled
                      />
                    </td>
                    <td className="text-end  fw-bold">RM</td>
                  </tr>
                )}
              </>
            )}
        </tbody>
      </table>

      <button
        data-bs-toggle="modal"
        data-bs-target="#success-modal"
        id="modalPopup"
        className="d-none"
        ref={modalRef}
      />
    </div>
  );
};

export default FareSummaryComponent;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  BTN_TYPE_ADD,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
import { useEffect } from "react";

export default function RateCodeAddEdit(props) {
  const { rateCode, openPopup, btnType } = props;
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: rateCode });
  useEffect(() => {
    reset(rateCode);
  }, [rateCode]);
  const handelOnCancel = () => {
    if (btnType === BTN_TYPE_ADD) {
      reset();
      clearErrors();
      props.clearData();
    } else {
      reset();
    }
  };
  const afterSuccess = () => {
    reset();
    clearErrors();
    props.clearData();
  };
  const saveRateCode = async (fromData) => {
    setShow(true);
    const data = await CallApiPost("SaveRateCode", fromData);
    setShow(false);
    if (data.respCode === 0) {
      afterSuccess();
      openPopup(
        true,
        "SUCCESS",
        fromData.rateCodeId > 0
          ? "Rate Code updated successfully!!"
          : "Rate Code save successfully!!"
      );
    } else if (data.respCode === 401) {
      setSubmitStatusFail(true);
      setFailMsg("Sorry, you don't have the write permission!!");
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else if (data.respCode === 409) {
      setSubmitStatusFail(true);
      setFailMsg(data.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else {
      setSubmitStatusFail(true);
      setFailMsg(SERVER_ERR_MSG);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    }
  };
  return (
    <div
      className="modal fade"
      id="button-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              {rateCode.rateCodeId === 0 ? <> ADD</> : <>EDIT</>} Rate Code
            </h5>
            <button
              id="closeButton"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handelOnCancel}
            ></button>
          </div>
          {submitStatusFail && (
            <div id="errorMsg" className="alert alert-danger" role="alert">
              {failMsg}{" "}
            </div>
          )}
          <div className="modal-body modal-scroll overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <input type="hidden" {...register("rateCodeId")} />

                    <div className="col-md-4 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Rate Code <span className="text-danger">&#9733;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="rateCode"
                        {...register("rateCode", {
                          required: {
                            value: true,
                            message: "This Field cannot be blank!!",
                          },
                          maxLength: {
                            value: 21,
                            message: "Max limit: 21 characters!!!",
                          },
                          pattern: {
                            value: ALPHANUM_SPACE_SPCL_CHAR,
                            message: "Incorrect Data!!!",
                          },
                        })}
                      />
                      {errors.rateCode && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.rateCode.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Description{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="rateCodeDesc"
                        {...register("rateCodeDesc", {
                          pattern: {
                            value: ALPHANUM_SPACE_SPCL_CHAR,
                            message: "Incorrect Data!!!",
                          },
                          maxLength: {
                            value: 50,
                            message: "Max limit: 50 characters!!!",
                          },
                        })}
                      />
                      {errors.rateCodeDesc && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.rateCodeDesc.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              onClick={handelOnCancel}
            >
              {btnType === BTN_TYPE_ADD ? "Clear" : "Reset"}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(saveRateCode)}
            >
              Save
              {/* {slaDataId > 0 ? "Revise" : "Save"} */}
            </button>
          </div>
          {/* <Tooltip
            id={"download-tip"}
            content={"Download Template"}
            place="left"
          /> */}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import { customDataTableStyles } from "../../Helper/Constants";
import { useForm } from "react-hook-form";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";

const AWFList = (props) => {
  const { approvalList, fetchApprovalStepsById } = props;

  const {
    formState: { errors },
  } = useForm();

  const columns = [
    {
      name: "Type of User",
      selector: (row) => row.userTypeValue,
      sortable: true,
      filterable: true,
    },
    {
      name: "Created By",
      selector: (row) => row.initUserTypeText,
      sortable: true,
      filterable: true,
    },
    {
      name: "No. of Steps",
      selector: (row) => row.noOfSteps,
      sortable: true,
      filterable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row.remarks,
    },
    {
      name: "",
      cell: (row) => (
        <>
        <div
          className="me-3"
          onClick={() => handleClick(row)}
          data-bs-toggle="modal"
          data-bs-target="#button-modal"
          data-tooltip-id={"edit-" + row.id}
        >
          {GetSvgIcon("EditPencil")}
        </div>
        <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const handleClick = (item) => {
    let currApproval = {
      id: item.id,
      userType: item.userTypeId,
      assignedTo: item.roleId,
      initUserType: item.initUserType,
      remarks: item.remarks,
      approvalSteps: [],
    };
    fetchApprovalStepsById(item.id);
    props.setApproval(currApproval);
  };

  return (
    <div>
      <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={approvalList}

          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </div>
    </div>
  );
};

export default AWFList;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../images/svglist";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";

const RateList = ({ tableData, handleBtnClick }) => {
  const tblColumns = [
    {
      name: "Customer",
      selector: (row) => row?.custDtls,
      sortable: true,
      filterable: true,
    },
    {
      name: "Rate Type",
      selector: (row) => row?.rateType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Threshold Type",
      selector: (row) => row?.thresholdTypeValue,
      sortable: true,
      filterable: true,
    },
    {
      name: "Threshold",
      selector: (row) => row?.threshold,
      sortable: true,
      filterable: true,
    },
    {
      name: "Base Charge",
      selector: (row) =>
        !!row?.baseCharge && row?.baseCharge > 0 ? row?.baseCharge : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "Surcharge",
      selector: (row) =>
        !!row?.surcharge && row?.surcharge > 0 ? row?.surcharge : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "Unit Price",
      selector: (row) =>
        !!row?.unitPrice && row?.unitPrice > 0 ? row?.unitPrice : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row?.remarks,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            onClick={() => handleBtnClick(row, "EDIT RATE", 1)}
            data-tooltip-id={"edit-" + row?.rateId}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row?.rateId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "5%",
    },
  ];
  return (
    <div className="col-md-12 table-responsive customized-data-table position-relative ">
      <DataTable
        columns={tblColumns}
        data={tableData}
        customStyles={customDataTableStyles}
        pagination
        striped
        highlightOnHover
        fixedHeader
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
      />
    </div>
  );
};

export default RateList;

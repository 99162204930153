import React, { useState } from 'react';
import Header from "../Layout/Header";

export default function PrivacyPolicy() {
    return (
        <>
            <Header />
            <div className="container-fluid min-ht-100 termss">
                <div className="row">
                    <main className="col-md-12 p-3">
                        <div className="clearfix border-bottom pb-2">
                            <div className="float-start">
                                <div className="section-title mt-1">Privacy Policy</div>
                            </div>
                        </div>
                        <div className='mx-4'>
                            <div className="col-md-12 mt-3">
                                <p>
                                    LINE HAUL SDN BHD (Registration <b>201901042436(1351766-K)</b>) ("we," "our," or "us") is committed to
                                    protecting your privacy and ensuring the security of your personal data. This Privacy Policy outlines
                                    how we collect, use, disclose, and protect your personal information in accordance with the
                                    Malaysia Personal Data Protection Act (PDPA) and the requirements of the Google Play Store and
                                    Apple iOS Store.
                                </p>
                                <p>
                                    <ol>
                                        <li>
                                            <b>1. Collection of Personal Data</b>
                                            <dl>
                                                <dt>We may collect the following types of personal data when you use our system:</dt>
                                                <dd>
                                                    <ul className='ml-2'>
                                                        <li>&bull; Name, email address, and other contact information when you register for our system.</li>
                                                        <li>&bull; Payment information for processing transactions related to our services.</li>
                                                        <li>&bull; Usage data, including device information, IP addresses, and browser type.</li>
                                                        <li>&bull; Any other data you provide to us voluntarily.</li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <b>2. Use of Personal Data</b>
                                            <dl>
                                                <dt>We will use your personal data for the following purposes:</dt>
                                                <dd>
                                                    <ul className='ml-2'>
                                                        <li>&bull; Providing and maintaining our system and services.</li>
                                                        <li>&bull; Processing payments and transactions.</li>
                                                        <li>&bull; Sending important service-related communications and updates.</li>
                                                        <li>&bull; Analyzing system usage patterns to improve and optimize our services.</li>
                                                        <li>&bull; Sharing data with third parties as described in Section 3.</li>
                                                    </ul>
                                                </dd>
                                                <dd>
                                                    Rest assured that we maintain strict control and oversight over any third-party entities with access
                                                    to your data, and they are contractually bound to keep your personal information secure and
                                                    confidential.
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <b>3. Data Sharing with Third Parties</b>
                                            <p>
                                                We may share your personal data with third-party service providers to improve your user experience
                                                when using our system. These third parties may include analytics providers, customer support
                                                platforms, and payment processors. We ensure that any third parties with whom we share your data
                                                adhere to strict confidentiality and security measures in compliance with the Malaysia Personal Data
                                                Protection Act (PDPA).
                                            </p>
                                            <p>
                                                The data shared with third parties will be used solely for the purposes of enhancing our services,
                                                providing better customer support, and analyzing system usage patterns to optimize user
                                                experience. We do not sell, rent, or trade your personal data to third parties for marketing purposes.
                                            </p>
                                        </li>
                                        <li>
                                            <b>4. Data Retention</b>
                                            <p>
                                                We will retain your personal data only for as long as necessary to fulfil the purposes outlined in this
                                                Privacy Policy or as required by law. Once your personal data is no longer required, we will securely
                                                delete or anonymize it.
                                            </p>
                                        </li>
                                        <li>
                                            <b>5. User Right</b>
                                            <dl>
                                                <dt>You have the following rights regarding your personal data:</dt>
                                                <dd>
                                                    <ul className='ml-2'>
                                                        <li>&bull; The right to access the personal data we hold about you.</li>
                                                        <li>&bull; The right to request correction of any inaccurate or incomplete personal data.</li>
                                                        <li>&bull; The right to request the erasure of your personal data if you unsubscribe from our system.</li>
                                                        <li>&bull; The right to object to the processing of your personal data for certain purposes.</li>
                                                    </ul>
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <b>6. Request for Data Deletion</b>
                                            <dl>
                                                <dt>If you wish to request the deletion of your personal data, you can do so by contacting us through the following channels:</dt>
                                                <dd>
                                                    <ul className='ml-2'>
                                                        <li>&bull; Live chat on our website.</li>
                                                        <li>&bull; Email: support@linehaul.com.my</li>
                                                    </ul>
                                                </dd>
                                                <dd>
                                                    We will respond to your request 30 business days and proceed with the data deletion process as per the PDPA guidelines.
                                                </dd>
                                            </dl>
                                        </li>
                                        <li>
                                            <b>7. Updates to the Privacy Policy</b>
                                            <p>
                                                We may update this Privacy Policy from time to time. Any changes will be effective when we post
                                                the updated version on our website.
                                            </p>
                                        </li>
                                        <li>
                                            <b>8. Contact Information</b>
                                            <p>
                                                If you have any questions or concerns about this Privacy Policy or the handling of your personal data,
                                                please contact us at livechat or support@linehaul.com.my
                                            </p>
                                        </li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

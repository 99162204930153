import React, { useState, useEffect } from "react";
import { permission, toBase64 } from "../Helper/CommonMethods";
import GetSvgIcon, { GetSvgUpload, GetSvgDownload } from "../../images/svglist";
import noImage from "../../images/no-image.png";
import {
  CLAIM_ATTACHMENT,
  CLAIM_ACTION,
  CLAIM_ASSIGN_TYPE,
  SERVER_ERR_MSG,
} from "../Helper/Constants";
import { CallApiPost } from "../Helper/serviceApi";
import Select from "react-select";
import ModalLayout from "../Layout/ModalLayout";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  ACCEPTCLAIM,
  ACKCLAIM,
  APPEALCLAIMAMT,
  APPROVECLAIM,
  ASSIGNOP,
  CUSTPARTFAULT, LINEHAUL_CUSTOMER_USER_TYPE_LIST, PROCESSINS,
  PROCESSPYMNT,
  RAISECLAIM,
  REJECTCLAIM,
  REQINFO,
  SETCLAIMAMT
} from "../../ConfigConstants/ConfigConstants";
import ClaimAction from "./ClaimAction";



const ClaimAddEdit = (props) => {
  const {
    claimId,
    claimOrderId,
    claimPalletId,
    cancelClaimForm,
    claimDetails,
    setSpinner,
    showAlert,
    reloadClaims,
    lastLogDetails,
  } = props;
  const userType = getUserInfo("userType");
  const [userRoles, setUserRoles] = useState([])
  const [modalConfig, setModalConfig] = useState(null);
  const [showPowerOfAttorney, setShowPowerOfAttorney] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [images, setImages] = useState({ 492: "", 493: "", 494: "" })
  const [claimTypeList, setClaimTypeList] = useState([]);
  const [claimType, setClaimType] = useState(null);
  const [detailsOfDamage, setDetailsOfDamage] = useState("");
  const [descOfOccured, setDescOfOccured] = useState("");
  const [message, setMessage] = useState(null);
  const resetMessage = () => {
    setTimeout(() => setMessage(""), 5000);
  };
  const [action, setAction] = useState(null);

  useEffect(() => {
    const userRoles = getUserInfo("userRoles")
    setUserRoles(JSON.parse(userRoles))
    if (!!claimOrderId && !!claimPalletId) {
      getClaimTypes();
      showAddClaimForm();
    }
    if (!!claimDetails) {
      patchData();
    }
  }, [claimId, claimOrderId, claimPalletId]);

  const patchData = () => {
    setSpinner(true);
    setClaimType(claimDetails?.claimType);
    setDetailsOfDamage(claimDetails?.claimDtls);
    setDescOfOccured(claimDetails?.claimReason);
    let parsedAttachments = JSON.parse(claimDetails?.attachments);
    if (!!parsedAttachments && parsedAttachments.length > 0) {
      let mappedAttachements = parsedAttachments.map((item, index) => {
        const fileType = item.type
        const data = item.filename
        setImages(state => ({ ...state, [fileType]: data }))
        return {
          photo: "",
          photoFileName: item.filename,
          photoFileType: item.type,
          photoBucketUrl: "",
        };
      });
      setAttachment(mappedAttachements);

      if (!!claimDetails && parseInt(claimDetails?.insurance) > 0) {
        setShowPowerOfAttorney(true);
      }
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };
  const getClaimTypes = async () => {
    let data = await CallApiPost("GetDropdownOptions", { argClaimType: 1 });
    if (!!data && data.respCode === 200) {
      setClaimTypeList(data?.respData?.claimType);
    }
  };

  const handelFileSelect = async (event, attachementType) => {
    try {
      setSpinner(true);
      const file = event.target.files;
      if (file.length > 0) {
        let photo = "";
        photo = await toBase64(file[0]);
        if (!!photo) {
          let fileObj = {
            photo: photo,
            photoFileName: file[0].name,
            photoFileType: attachementType,
            photoBucketUrl: "",
          };

          let updatedList = [...attachment];
          let checkExists = updatedList.filter(
            (item) => item.photoFileType === attachementType
          );
          if (checkExists.length > 0) {
            updatedList.forEach((item) => {
              if (item.photoFileType === attachementType) {
                item.photo = fileObj?.photo;
                item.photoFileName = fileObj?.photoFileName;
              }
            });
          } else {
            updatedList = [...attachment, fileObj];
          }
          setImages(state => ({ ...state, [attachementType]: "data:image/jpeg;base64," + photo }))
          setAttachment(updatedList);
          setSpinner(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeAttchement = (type) => {
    let updatedList = [...attachment];
    updatedList.splice(
      attachment.findIndex((item) => item.photoFileType === type),
      1
    );
    setAttachment(updatedList);
    document.getElementById(`attachement-${type}`).value = "";
  };

  const showAddClaimForm = () => {
    setModalConfig({
      size: "lg",
      show: true,
      title: "CLAIM DETAILS",
    });
  };

  const handleModalClose = () => {
    cancelClaimForm();
    setModalConfig({
      size: "md",
      show: false,
      title: "",
    });
  };

  const validateClaim = () => {
    let returnValue = true;
    if (claimType == null) {
      returnValue = false;
      setMessage("Please select claim type");
      setSpinner(false);
    } else if (
      !!claimType &&
      attachment.find((e) => e.photoFileType === CLAIM_ATTACHMENT.DAMAGED) ===
      undefined
    ) {
      returnValue = false;
      setMessage("Please upload damage image");
      setSpinner(false);
    }
    resetMessage();
    return returnValue;
  };

  const submitClaim = async () => {
    setSpinner(true);
    if (validateClaim()) {
      let payload = {
        claimId: claimId,
        orderId: claimOrderId,
        palletId: claimPalletId,
        actionCode: CLAIM_ACTION.ClaimRaised,
        claimType: claimType,
        attachments: attachment,
        claimDetail: detailsOfDamage,
        claimReason: descOfOccured,
      };
      let data = await CallApiPost("Claim", payload);
      if (!!data && data?.respCode === 200) {
        showAlert({
          isSuccess: true,
          size: "md",
          show: true,
          title: "",
          description: data?.respMessage,
        });
        handleModalClose();
      } else if (data?.respCode === 401) {
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "ERROR",
          description: "Sorry, you don't have the write permission!!",
        });
        handleModalClose();
      } else {
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "",
          description: SERVER_ERR_MSG,
        });
      }
    }
    setSpinner(false);
  };

  const onSuccess = (title, body) => {
    console.log("title", title, "body", body);
  };

  const onError = (errMsg) => {
    console.log("errMsg", errMsg);
  };

  const downloadPowerOfAttorney = () => {
    let powTemplate = attachment.filter((el) => el.photoFileType === 495)[0];
    // DownloadFile(powTemplate.photoFileName, onSuccess, onError);
    window.open(powTemplate.photoFileName, '_blank');
  };

  const showActionForm = (actionType) => {
    setAction(actionType);
    setModalConfig({
      size: "md",
      show: false,
      title: "",
    });
  };

  const claimFooterControls = (
    <>
      <button
        type="button"
        className="btn btn-default border"
        onClick={() => handleModalClose()}
      >
        Close
      </button>
      {(permission(RAISECLAIM)) &&
        claimId === 0 && (
          <>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => submitClaim()}
            >
              Submit
            </button>
          </>
        )}

      {
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Customer &&
        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) &&
        (
          <>
            {!!lastLogDetails &&
              lastLogDetails?.action_code ===
              CLAIM_ACTION.InformationRequest && permission(REQINFO)?.allowedWrite && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={() =>
                    showActionForm(CLAIM_ACTION.InformationSubmitted)
                  }
                >
                  Submit Required Information
                </button>
              )}
            {!!lastLogDetails &&
              lastLogDetails?.action_code === CLAIM_ACTION.FixClaimAmount && (
                <>
                  {permission(APPEALCLAIMAMT)?.allowedWrite && (<button
                    type="button"
                    className="btn btn-outline-secondary theme-violet"
                    onClick={() =>
                      showActionForm(CLAIM_ACTION.AppealForClaimAmount)
                    }
                  >
                    Appeal
                  </button>)}
                  {permission(ACCEPTCLAIM)?.allowedWrite && (<button
                    type="button"
                    className="btn btn-outline-secondary theme-violet"
                    onClick={() =>
                      showActionForm(CLAIM_ACTION.AcceptClaimAmount)
                    }
                  >
                    Accept
                  </button>)}
                </>
              )}
          </>
        )}
      {
        //IO Buttons
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.IO &&
        userRoles.some(el => el.roleId === lastLogDetails?.assignedToRole) &&
        (
          <>
            {
              permission(REJECTCLAIM).allowedWrite &&
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => showActionForm(CLAIM_ACTION.RejectClaim)}
              >
                Reject
              </button>
            }
            {
              permission(REQINFO)?.allowedWrite &&
              (<button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                onClick={() =>
                  showActionForm(CLAIM_ACTION.InformationRequest)
                }
              >
                Request More Info
              </button>)
            }
            {permission(ASSIGNOP)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.AssignedToOperation)}
            >
              Assign to Operations
            </button>)}
            {permission(APPROVECLAIM)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.ApproveClaim)}
            >
              Approve
            </button>)}
          </>
        )
      }
      {
        //Operator Buttons
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Operation &&
        userRoles.some(el => el.roleId === lastLogDetails?.assignedToRole) &&
        (
          <>
            {permission(ACKCLAIM)?.allowedWrite && (<button
              type="button"
              className="btn btn-success"
              onClick={() =>
                showActionForm(CLAIM_ACTION.AcknowledgeAndFillIncidentReport)
              }
            >
              Acknowledgement
            </button>)}
            {permission(CUSTPARTFAULT)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() =>
                showActionForm(CLAIM_ACTION.MentionCustomerPartnerFault)
              }
            >
              Customer or Partner Fault
            </button>)}
            {permission(ASSIGNOP)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() =>
                showActionForm(CLAIM_ACTION.OtherOperationTeamFault)
              }
            >
              Assign to Other Operation
            </button>)}
          </>
        )
      }
      {
        //Claim Type User Buttons
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Claims &&
        userRoles.some(el => el.roleId === lastLogDetails?.assignedToRole) &&
        (
          <>
            {permission(REQINFO)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.InformationRequest)}
            >
              Request More Info
            </button>)}
            {
              permission(SETCLAIMAMT) &&
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                onClick={() => showActionForm(CLAIM_ACTION.FixClaimAmount)}
              >
                Update Claim Amount
              </button>
            }
          </>
        )
      }

      {
        //Insurance User Buttons
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Insurance &&
        userRoles.some(el => el.roleId === lastLogDetails?.assignedToRole) &&
        (
          <>
            {permission(REQINFO)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.InformationRequest)}
            >
              Request More Info
            </button>)}
            {permission(PROCESSINS)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.ProcessInsurance)}
            >
              Process Insurance
            </button>)}
          </>
        )
      }

      {
        //Finance User Buttons
        claimId > 0 &&
        !!lastLogDetails &&
        lastLogDetails?.assignment_type === CLAIM_ASSIGN_TYPE.Finance &&
        userRoles.some(el => el.roleId === lastLogDetails?.assignedToRole) &&
        (
          <>
            {permission(REQINFO)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.InformationRequest)}
            >
              Request More Info
            </button>)}
            {permission(PROCESSPYMNT)?.allowedWrite && (<button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={() => showActionForm(CLAIM_ACTION.ProcessPayment)}
            >
              Process Claim Amount
            </button>)}
          </>
        )
      }
    </>
  );

  return (
    <>
      {!!modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
          footer={claimFooterControls}
        >
          <div className="row col-md-12 g-2 mb-2 claim-images-wrapper">
            <div className="col-md-4 mt-3 text-center claim-image">
              {!!attachment &&
                attachment.length > 0 &&
                !!attachment.find(
                  (e) => e.photoFileType === CLAIM_ATTACHMENT.DAMAGED
                ) ? (
                <>
                  {LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) &&
                    claimId === 0 && (
                      <span
                        className="remove-circle text-danger"
                        onClick={() =>
                          removeAttchement(CLAIM_ATTACHMENT.DAMAGED)
                        }
                      >
                        {GetSvgIcon("Removecircle")}
                      </span>
                    )}
                  <img
                    src={images[CLAIM_ATTACHMENT.DAMAGED]}
                    height="100px"
                    width="100px"
                    alt="img"
                    className="rounded-circle"
                  />
                </>
              ) : (
                <img
                  src={noImage}
                  height="100px"
                  width="100px"
                  className="rounded-circle"
                  alt=""
                />
              )}
              {claimId === 0 && (
                <div className="my-2 file-placeholder m-auto">
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) =>
                      handelFileSelect(e, CLAIM_ATTACHMENT.DAMAGED)
                    }
                    id={`attachement-${CLAIM_ATTACHMENT.DAMAGED}`}
                  />
                  <div>
                    {GetSvgUpload({ required: false })} Upload image of damage
                  </div>
                </div>
              )}
              {!!claimType &&
                attachment.find(
                  (e) => e.photoFileType === CLAIM_ATTACHMENT.DAMAGED
                ) === undefined && (
                  <span className="text-danger">{message}</span>
                )}
            </div>
            <div className="col-md-4 mt-3 text-center claim-image">
              {!!attachment &&
                attachment.length > 0 &&
                !!attachment.find(
                  (e) => e.photoFileType === CLAIM_ATTACHMENT.BEFOREPICKUP
                ) ? (
                <>
                  {LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) &&
                    claimId === 0 && (
                      <span
                        className="remove-circle text-danger"
                        onClick={() =>
                          removeAttchement(CLAIM_ATTACHMENT.BEFOREPICKUP)
                        }
                      >
                        {GetSvgIcon("Removecircle")}
                      </span>
                    )}
                  <img
                    src={images[CLAIM_ATTACHMENT.BEFOREPICKUP]}
                    height="100px"
                    width="100px"
                    alt="img"
                    className="rounded-circle"
                  />
                </>
              ) : (
                <img
                  src={noImage}
                  height="100px"
                  width="100px"
                  className="rounded-circle"
                  alt=""
                />
              )}
              {claimId === 0 && (
                <div className="my-2 file-placeholder m-auto">
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) =>
                      handelFileSelect(e, CLAIM_ATTACHMENT.BEFOREPICKUP)
                    }
                    id={`attachement-${CLAIM_ATTACHMENT.BEFOREPICKUP}`}
                  />
                  <div>
                    {GetSvgUpload({ required: false })} Upload Before Pickup
                    image
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4 mt-3 text-center claim-image">
              {!!attachment &&
                attachment.length > 0 &&
                !!attachment.find(
                  (e) => e.photoFileType === CLAIM_ATTACHMENT.AFTERDELIVERY
                ) ? (
                <>
                  {LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) &&
                    claimId === 0 && (
                      <span
                        className="remove-circle text-danger"
                        onClick={() =>
                          removeAttchement(CLAIM_ATTACHMENT.AFTERDELIVERY)
                        }
                      >
                        {GetSvgIcon("Removecircle")}
                      </span>
                    )}
                  <img
                    src={images[CLAIM_ATTACHMENT.AFTERDELIVERY]}
                    height="100px"
                    width="100px"
                    alt="img"
                    className="rounded-circle"
                  />
                </>
              ) : (
                <img
                  src={noImage}
                  height="100px"
                  width="100px"
                  className="rounded-circle"
                  alt=""
                />
              )}
              {claimId === 0 && (
                <div className="my-2 file-placeholder m-auto">
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) =>
                      handelFileSelect(e, CLAIM_ATTACHMENT.AFTERDELIVERY)
                    }
                    id={`attachement-${CLAIM_ATTACHMENT.AFTERDELIVERY}`}
                  />
                  <div>
                    {GetSvgUpload({ required: false })} Upload after delivery
                    image
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <label htmlFor="floatingInputGrid" className="form-label theme-label">
                Type of Claim
              </label>
              <div className="input-group mb-2">
                <span className="input-group-text inputsty">
                  {GetSvgIcon("Claim")}
                </span>
                <Select
                  options={claimTypeList}
                  placeholder="Select Claim Type"
                  value={
                    claimType === ""
                      ? ""
                      : claimTypeList.find((opt) => opt.value === claimType)
                  }
                  onChange={(d) => setClaimType(d ? d.value : d)}
                  isSearchable={true}
                  isClearable={true}
                  className="form-control  p-0"
                  isDisabled={claimId > 0}
                />
              </div>
              {!!message && claimType == null && (
                <span className="text-danger">{message}</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-2">
              <label htmlFor="floatingInputGrid" className="form-label theme-label">
                Details of Loss or Damage
              </label>
              <div className="input-group mb-2">
                <span className="input-group-text inputsty">
                  {GetSvgIcon("Description")}
                </span>
                <textarea
                  disabled={claimId > 0}
                  type="text"
                  onChange={(e) => setDetailsOfDamage(e.target.value)}
                  className="form-control txt-area-resize-0"
                  placeholder="Details of Loss or Damage"
                  value={detailsOfDamage}
                  aria-label="input example"
                ></textarea>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <label htmlFor="floatingInputGrid" className="form-label theme-label">
                Description of How it Ocurred
              </label>
              <div className="input-group mb-2">
                <span className="input-group-text inputsty">
                  {GetSvgIcon("Description")}
                </span>
                <textarea
                  disabled={claimId > 0}
                  type="text"
                  onChange={(e) => setDescOfOccured(e.target.value)}
                  className="form-control txt-area-resize-0"
                  placeholder="Description of How it Ocurred"
                  value={descOfOccured}
                  aria-label="input example"
                ></textarea>
              </div>
            </div>
          </div>
          {!!showPowerOfAttorney && showPowerOfAttorney && (
            <div className="row">
              <div className="col-md-6 mb-2">
                <button
                  className="btn btn-default border w-100"
                  type="button"
                  onClick={() => downloadPowerOfAttorney()}
                >
                  {GetSvgDownload({ required: false })} Download Power of
                  Attorney Form
                </button>
              </div>
              <div className="col-md-6 mb-2">
                <div className="Upload-Photo w-100 claim-image">
                  <input
                    type="file"
                    onChange={(e) =>
                      handelFileSelect(e, CLAIM_ATTACHMENT.POWEROFATTORNEY)
                    }
                    id={`attachement-${CLAIM_ATTACHMENT.POWEROFATTORNEY}`}
                  />
                  <div className="uploaded-text ms-2" disabled>
                    {!!attachment &&
                      attachment.length > 0 &&
                      !!attachment.find(
                        (e) =>
                          e.photoFileType === CLAIM_ATTACHMENT.POWEROFATTORNEY
                      ) ? (
                      <>
                        <span>
                          {
                            attachment.find(
                              (e) =>
                                e.photoFileType ===
                                CLAIM_ATTACHMENT.POWEROFATTORNEY
                            ).photoFileName
                          }
                        </span>
                      </>
                    ) : (
                      <>{GetSvgUpload({ required: false })} Upload Form</>
                    )}
                  </div>
                  {!!attachment &&
                    attachment.length > 0 &&
                    !!attachment.find(
                      (e) =>
                        e.photoFileType === CLAIM_ATTACHMENT.POWEROFATTORNEY
                    ) && (
                      <>
                        <span
                          className="remove-circle text-danger"
                          onClick={() =>
                            removeAttchement(CLAIM_ATTACHMENT.POWEROFATTORNEY)
                          }
                        >
                          {GetSvgIcon("Removecircle")}
                        </span>
                      </>
                    )}
                </div>
              </div>
            </div>
          )}
        </ModalLayout>
      )}

      {!!action && (
        <ClaimAction
          reloadClaims={reloadClaims}
          viewMode={false}
          actionType={action}
          claimDetails={claimDetails}
          setSpinner={setSpinner}
          showAlert={showAlert}
          setAction={setAction}
          cancelClaimForm={cancelClaimForm}
        />
      )}
    </>
  );
};
export default ClaimAddEdit;

import moment from "moment/moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import GetSvgIcon, { GetSvgHistory } from "../../../../images/svglist";
import { DATE_FORMAT_MOMENT } from "../../../Helper/Constants";
import { CallApiGet, CallApiPost } from "../../../Helper/serviceApi";
import Footer from "../../../Layout/Footer";
import Header from "../../../Layout/Header";
import Popup from "../../../Layout/Popup";
import Spinner from "../../../Layout/Spinner";
import IsrActivationDetails from "./IsrActivationDetails";
import IsrActivationList from "./IsrActivationList";
import IsrReassign from "./IsrReassign";
import ApprovalLogList from "../../ApprovalLogList";
import {
  approvalHistory,
  exportToExcel,
  permission,
} from "../../../Helper/CommonMethods";
import {
  APVLLOG,
  ISRREASSIGN,
  ISRREG,
} from "../../../../ConfigConstants/ConfigConstants";

const isrDtlModel = {
  acNumber: "",
  address: "",
  bankName: "",
  benificiaryName: "",
  email: "",
  isrIc: "",
  name: "",
  phone: "",
  postCode: "",
  userId: 0,
  active: false,
  staffId: "",
};

const IsrActivationMain = () => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [allData, setAllData] = useState();
  const [isrDtls, setIsrDtls] = useState(isrDtlModel);
  const [costumerList, setCostumerList] = useState([]);
  const [currentIsr, setCurrentIsr] = useState(0);
  const modalRef = useRef();
  const navigate = useNavigate();
  const [stateCity, setStateCity] = useState();
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const columns = [
    {
      name: "ISR ID",
      selector: (row) => (row.staffId !== "0" ? row.staffId : ""),
      sortable: true,
      filterable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) =>
        !!row.date ? moment(row.date).format(DATE_FORMAT_MOMENT) : "",
      filterable: true,
      sortable: true,
      sortType: (a, b) => {
        return new Date(b.values.date) - new Date(a.values.date);
      },
    },
    {
      name: "Status",
      selector: (row) =>
        row.active
          ? row.status === "APPROVED" || row.status === "DEACTIVATION REJECTED"
            ? "ACTIVE"
            : row.status
          : row.status === "REJECT"
          ? "REJECTED"
          : row.status === "DEACTIVATION APPROVED"
          ? "DEACTIVATED"
          : row.status,
      filterable: true,
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "YES" : "NO"),
      filterable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
            fontWeight: "bold",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
            fontWeight: "bold",
          },
        },
      ],
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            onClick={() => getSelectedRow(row)}
            data-bs-toggle="modal"
            data-bs-target="#button-modal"
            data-tooltip-id={"rowactionedit-" + row.id}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"rowactionedit-" + row.id} content="Edit" place="left" />
        </>
      ),
      omit: permission(ISRREG)?.allowedRead ? false : true,
      width: "56px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            onClick={() => setCurrentIsr(row.id)}
            data-bs-toggle="modal"
            data-bs-target="#isr-reassign-modal"
            data-tooltip-id={"reassign-" + row.id}
          >
            {GetSvgIcon("Reassign")}
          </div>
          <Tooltip
            id={"reassign-" + row.id}
            content={"Reassign Customer(s)"}
            place="left"
          />
        </>
      ),
      omit: permission(ISRREASSIGN)?.allowedRead ? false : true,
      width: "56px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            onClick={() => getApprovalHistory(row.id, row.staffType)}
            data-bs-toggle="modal"
            data-bs-target="#approval-history-modal"
            data-tooltip-id={"approvalhistorytooltip-" + row.id}
          >
            {GetSvgHistory({ required: false })}
          </div>
          <Tooltip
            id={"approvalhistorytooltip-" + row.id}
            content={"Approval History"}
            place="left"
          />
        </>
      ),
      omit: permission(APVLLOG)?.allowedRead ? false : true,
      width: "56px",
    },
  ];

  useEffect(() => {
    getInitialData();
    fetchStatesAndCityList();
  }, []);

  const getApprovalHistory = async (staffId, staffType) => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(staffId, staffType);
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const getInitialData = async () => {
    setShow(true);
    let allIsrData = await CallApiGet("isr-list-for-assignee");
    setShow(false);
    if (allIsrData.respCode === 200) {
      let data = allIsrData.respData;
      setAllData(data);
    } else if (allIsrData.respCode === 500) {
      onFailure();
    } else {
      setAllData([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
  };

  const getSelectedRow = async (row) => {
    setShow(true);
    let isrDtl = await CallApiPost("isr-details", { isrUserId: row.id });
    let custList = await CallApiGet("customer-assignTo-isr?id=" + row.id);
    setShow(false);
    if (isrDtl.respCode === 200) {
      setStatus(row.status);
      setIsrDtls(isrDtl.respData);
    }

    if (custList.respCode === 200) {
      setCostumerList(custList.respData);
    }
  };

  const onSuccess = (isSuccess, title, description) => {
    setIsSuccess(isSuccess);
    setTitle(title);
    setDescription(description);
    modalRef.current.click();
  };

  const clear = () => {
    setIsrDtls(JSON.parse(JSON.stringify(isrDtlModel)));
  };

  const handelRegistration = () => {
    navigate("/isrRegistration");
  };

  const onFailure = () => {
    setIsSuccess(false);
    setTitle("ERROR");
    setDescription("Something Went Wrong. Please try after sometime!!!");
    modalRef.current.click();
  };

  const exportData = () => {
    exportToExcel(allData, columns, "Isr_list.csv");
  };

  return (
    <>
      <Header />
      <div className="container-fluid  min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">ISR List</div>
              </div>
              <div className="float-end">
                <button
                  className="btn btn-outline-secondary theme-violet text-nowrap"
                  type="button"
                  onClick={handelRegistration}
                >
                  {GetSvgIcon("PlusCircle")} Register ISR
                </button>
              </div>
            </div>
            <div className="export-isr mt-2 mb-2">
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  text-end "
                onClick={exportData}
                data-tooltip-id="csv-export"
              >
                {/* Download */}
                {GetSvgIcon("SpreadSheet")}
              </button>
            </div>
            {/* here can add filter */}

            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />

            <IsrActivationList columns={columns} data={allData} />
          </main>
        </div>
      </div>
      <IsrActivationDetails
        onSuccess={onSuccess}
        isrDtls={isrDtls}
        clear={clear}
        status={status}
        costumerList={costumerList}
        stateList={stateData}
        cityList={cityData}
        stateCityData={stateCity}
      />
      <IsrReassign
        onSuccess={onSuccess}
        onFailure={onFailure}
        currentIsr={currentIsr}
      />
      <ApprovalLogList data={approvalHistoryData} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={() => getInitialData()}
      />
      <Spinner show={show} />

      <Footer />
    </>
  );
};

export default IsrActivationMain;

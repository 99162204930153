import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import LastMilePlanningList from "./LastMilePlanningList";
import {
  DATE_FORMAT_SERVER,
  SERVER_ERR_MSG,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
} from "../Helper/Constants";
import moment from "moment";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import LastMileBidModal from "./LastMileBidModal";
import LastMileRePlanningModal from "./LastMileRePlanningModal";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import ReactDatePicker from "react-datepicker";
import GetSvgIcon from "../../images/svglist";
import { exportToExcel } from "../Helper/CommonMethods";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import { Tooltip } from "react-tooltip";

export default function LastMilePlanningMain() {
  const pageTitle = `Last Mile Planning`;
  const [spinner, setSpinner] = useState(false);
  const [planningList, setPlanningList] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [modalConfig, setModalConfig] = useState();
  const [vhclTypes, setVhclTypes] = useState([]);
  const [initBidData, setInitBidData] = useState(null);
  const [replanningModalConfig, setReplanningModalConfig] = useState();
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };

  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).add(7, "days").format(DATE_FORMAT_SERVER)
  );
  const [tripDate, setTripDate] = useState(null);
  const [tripDate2, setTripDate2] = useState(null);
  const [originList, setOriginList] = useState([]);
  const [origin, setOrigin] = useState(null);
  const [origin2, setOrigin2] = useState(null);
  const [mergedList, setMergedList] = useState([]);
  const [hideColumns, setHideColumns] = useState(true);
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(initAlertConfig);
  const [selectedTripData, setSelectedTripData] = useState();
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [bidData, setBidData] = useState();
  const PICKUP_SERVICE_ID = 9;
  const DELIVERY_SERVICE_ID = 148;

  const columns = [
    {
      name: "Order No.",
      selector: (row) => row.order_no,
    },
    {
      name: "Service Type",
      selector: (row) => row.service_desc,
    },
    {
      name: "SLA",
      selector: (row) => row.sla_name,
    },
    {
      name: "Sbu",
      selector: (row) => row.sbu_name,
    },
    {
      name: "Pallets",
      selector: (row) => row.pallets,
    },
    {
      name: "Address",
      selector: (row) => getFullAddress(row),
    },
    {
      name: "Vehicle No.",
      selector: (row) => row.vhcl_no,
    },
    {
      name: "Driver",
      selector: (row) => row.dr1_name + " (" + row.dr1_code + ")",
    },
    {
      name: "Trip Date",
      selector: (row) => moment(row.trip_date).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Est. Delivery Date",
      selector: (row) => moment(row.est_delivery).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Order Status",
      selector: (row) => row.order_status,
    },
    {
      name: "Bid Status",
      selector: (row) => row.order_status,
    },
  ];

  const getFullAddress = (rowData) => {
    if (rowData.service_id === 9)
      // Pickup
      return `Name: ${rowData.pickup_name}\nAddress: ${rowData.pickup_address}, ${rowData.pickup_city}, ${rowData.pickup_state}\nPostCode: ${rowData.pickup_postcode}\nPhone: ${rowData.pickup_phone}`;
    else if (rowData.service_id === 148)
      // Delivery
      return `Name: ${rowData.deliver_name}\nAddress: ${rowData.deliver_address}, ${rowData.deliver_city}, ${rowData.deliver_state}\nPostCode: ${rowData.deliver_postcode}\nPhone: ${rowData.deliver_phone}`;
    else return ``;
  };

  useEffect(() => {
    loadData();
    loadVhclTypes();
    getDropdownData();
  }, []);

  // useEffect(() => {
  //   if (!!origin && !!tripDate) {
  //     loadDataBySbuAndTripDate();
  //   }
  // }, [tripDate, origin]);

  useEffect(() => {
    if (!!fromDate && !!toDate) {
      loadDataBySbuAndTripDate();
    }
  }, [fromDate, toDate]);

  const loadData = async () => {
    setHideColumns(true);
    setMergedList([]);
    setSelectedRoute(0);
    let roles = JSON.parse(getUserInfo("userRoles"));
    setOrigin(roles[0]?.sbuId > 0 ? roles[0]?.sbuId : "");
    let payload = {
      origin: roles[0]?.sbuId > 0 ? roles[0]?.sbuId : "",
      fromDate: fromDate,
      toDate: toDate,
    };
    setTripDate("");
    setSpinner(true);
    const data = await CallApiPost("getLastMilePlanningList", payload);
    if (data.respCode === 200) {
      data.respData.forEach((item) => {
        item["vhcl_no"] = !!item["vhcl_no"] ? item["vhcl_no"] : "Not Assigned";
        item["dr1_name"] = !!item["dr1_name"]
          ? item["dr1_name"]
          : "Not Assigned";
      });
      setPlanningList(data.respData);
      setSpinner(false);
    } else {
      setPlanningList([]);
      setSpinner(false);
    }
  };
  const loadDataBySbuAndTripDate = async () => {
    setMergedList([]);
    setHideColumns(false);
    setSelectedRoute(0);
    // let payload = {
    //   origin: origin,
    //   fromDate: moment(tripDate).format(DATE_FORMAT_SERVER),
    //   toDate: moment(tripDate).format(DATE_FORMAT_SERVER),
    // };
    let payload = {
      origin: origin,
      fromDate: moment(fromDate).format(DATE_FORMAT_SERVER),
      toDate: moment(toDate).format(DATE_FORMAT_SERVER),
    };
    setSpinner(true);
    const data = await CallApiPost("getLastMilePlanningList", payload);
    if (data.respCode === 200) {
      data.respData.forEach((item) => {
        item["vhcl_no"] = !!item["vhcl_no"] ? item["vhcl_no"] : "Not Assigned";
        item["dr1_name"] = !!item["dr1_name"]
          ? item["dr1_name"]
          : "Not Assigned";
      });
      setPlanningList(data.respData);
      setSpinner(false);
    } else {
      setPlanningList([]);
      setSpinner(false);
    }
  };
  const getDriverAndVehicleByOrigin = (origin, bidId) => {
    let payload = { origin: origin };
    setSpinner(true);
    setVehicles([]);
    setDrivers([]);
    Promise.all([
      CallApiPost("getVehicleListByOrigin", payload),
      CallApiPost("getDriversByOrigin", payload),
    ])
      .then(([vehicleResp, driverResp]) => {
        let vlc = [];
        let drv = [];
        if (!!vehicleResp?.respData) {
          // setVehicles(
          //   [...vehicleResp?.respData].map((item) => {
          //     return {
          //       label: `${item.vhclNo} (Capacity-${item.vCapacity})`,
          //       value: item.id,
          //     };
          //   })
          // );
          vlc = [...vehicleResp?.respData].map((item) => {
            return {
              label: `${item.vhclNo} (Capacity-${item.vCapacity})`,
              value: item.id,
            };
          });
        }
        if (!!driverResp?.respData) {
          // setDrivers(
          //   [...driverResp?.respData].map((item) => {
          //     return {
          //       label: item.staff_name + " (" + item.staff_id + ")",
          //       value: item.userId,
          //     };
          //   })
          // );
          drv = [...driverResp?.respData].map((item) => {
            return {
              label: item.staff_name + " (" + item.staff_id + ")",
              value: item.userId,
            };
          });
        }
        if (!!bidId) getBidDriverVehicleData(bidId, vlc, drv);
        else setDriverVehicleData(drv, vlc);
        setSpinner(false);
      })
      .catch((error) => {
        console.log("Error===>", error);
        setSpinner(false);
      });
  };
  const reloadData = () => {
    loadData();
    setDrivers([]);
    setVehicles([]);
    setMergedList([]);
    setSelectedRoute(0);
  };

  const setDriverVehicleData = (drv, vlc) => {
    setVehicles([...vlc]);
    setDrivers([...drv]);
  };

  const getBidDriverVehicleData = async (bidId, vlc, drv) => {
    let respData = await CallApiGet("bidDriverVehicleData?bidId=" + bidId);

    if (respData.respCode === 200 && respData.respData.length > 0) {
      let data = respData.respData[0];
      setBidData(data);
      let bidDrv = {
        label: data.staff_name + " (" + data.acct_code + ")",
        value: data.drv_id,
      };
      let bidVlc = {
        label: `${data.v_no} (Capacity-${data.v_type})`,
        value: data.vid,
      };

      if (vlc.filter((e) => e.value === bidDrv.value).length === 0) {
        setVehicles([...vlc, bidVlc]);
      } else {
        setVehicles([...vlc]);
      }

      if (drv.filter((e) => e.value === bidDrv.value).length === 0) {
        setDrivers([...drv, bidDrv]);
      } else {
        setDrivers([...drv]);
      }
    } else {
      setDriverVehicleData(drv, vlc);
    }
  };

  const validateForBidDriverVehicle = (reVehicle, reDriver) => {
    let validDrv = true;
    let validVlc = true;
    if (bidData.vid === reVehicle) {
      if (bidData.drv_id !== reDriver) validVlc = false;
    }
    if (bidData.drv_id === reDriver) {
      if (bidData.vid !== reVehicle) validDrv = false;
    }
    if (validDrv && validVlc) return true;
    else return false;
  };

  const doRePlanning = (reVehicle, reDriver, reTripDate, reTripTime) => {
    // if (validateForBidDriverVehicle(reVehicle, reDriver)) {
    setSpinner(true);
    // let ordersData = [];
    let payload = {};
    // let sData = [];

    let payloadOrderData = [];
    if (mergedList.length > 0) {
      mergedList.forEach((item) => {
        let tempSplitArr = item.split("_");
        payloadOrderData.push({
          order_id: Number(tempSplitArr[0]),
          service_id: Number(tempSplitArr[1]),
        });
      });
    } else {
      payloadOrderData.push({
        order_id: selectedRowData.order_id,
        service_id: selectedRowData.service_id,
      });
    }

    payload = {
      route_id: selectedRoute ? selectedRoute : selectedRowData?.route,
      plan_date: moment(reTripDate + " " + reTripTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      vehicle_id: reVehicle,
      driver_id: reDriver,
      existing_plan_id: mergedList.length > 0 ? 0 : selectedRowData?.plan,
      order_data: payloadOrderData,
    };

    CallApiPost("lastmileAssignVehicle", payload)
      .then((data) => {
        closeReplanningModal();
        if (data.respCode === 200) {
          // reloadData();
          showAlert({
            isSuccess: true,
            size: "md",
            show: true,
            title: "",
            description: "Planned successfully",
          });
          window.location.reload();
        } else {
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "",
            description: "Some Error Occured!!",
          });
        }
        setSpinner(false);
      })
      .catch((error) => {
        closeReplanningModal();
        console.log("Exception==", error);
        showAlert({
          isSuccess: false,
          size: "md",
          show: true,
          title: "",
          description: "Some Error Occured!!",
        });
        setSpinner(false);
      });

    // } else {
    //   showAlert({
    //     isSuccess: false,
    //     size: "md",
    //     show: true,
    //     title: "",
    //     description: "can not change Bid vehicle and driver",
    //   });
    // }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };
  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };
  const openBidModal = (rowData) => {
    if (moment().format("HH:mm") < rowData?.bid_stop_tm) {
      setSelectedRowData(rowData);
      setInitBidData({
        bidId: 0,
        routeId: rowData?.route,
        // tripDate: moment().format(DATE_FORMAT_SERVER),
        // tripTime: moment().format(TIME_FORMAT_SERVER),
        sbuId: rowData?.sbu_id,
        vhclType: -1,
        orderId: rowData?.order_id,
        serviceId: rowData?.service_id,
        tripDuration: 0,
        pallets: rowData?.pallets,
        // tripPrice: 0.0,
        // remarks: getRemarks(rowData),
        remarks: "",
        planId: rowData?.plan,
      });
      setModalConfig({
        size: "lg",
        show: true,
        title: "Invite Bid",
      });
    } else {
      setAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: "Bid Cut-Off Time Reached",
      });
    }
  };
  const closeBidModal = () => {
    setModalConfig({
      size: "lg",
      show: false,
      title: "Invite Bid",
    });
    setSelectedRowData(null);
  };
  const openReplanningModal = (rowData) => {
    setSelectedRowData(rowData);
    getDriverAndVehicleByOrigin(rowData?.sbu_id, rowData?.bidId);
    setReplanningModalConfig({
      size: "lg",
      show: true,
      title: "Replanning",
    });
  };
  const closeReplanningModal = () => {
    setReplanningModalConfig({
      size: "lg",
      show: false,
      title: "Replanning",
    });
    setSelectedRowData(null);
  };
  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");

    if (data.respCode === 200 || data.respCode === 0) {
      if (!!data.respData && data.respData.length > 0) {
        data.respData.push({ id: -1, vhclType: "ALL" });
        setVhclTypes(data.respData);
      }
    } else if (data.respCode === 500) {
      setAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    } else {
      setAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: data.respMessage,
      });
    }
  };

  const printAllWaybill = async (data) => {
    setSpinner(true);

    const waybillData = {
      // waybillNo: data.waybill_no.split(","),
      // shipmentId: data?.order_id,
      shipmentIdList: [data?.order_id],
      palletIdList: [],
      isSearchByOrder: true,
    };

    let resp = await CallApiPost("printWaybill", waybillData);
    setSpinner(false);
    if (resp.respCode === 200) {
      window.open(resp.respData, "_blank");
    }
    // printWaybill(waybillData);
  };
  // const printAllWaybill = async (rowData) => {
  //   setSpinner(true);
  //   let data = await CallApiGet("getShipmentDetailsById/" + rowData.order_id);
  //   let waybillData = {};
  //   if (data.respCode === 200) {
  //     waybillData = {
  //       waybill_no: data?.respData?.shipmentPallets.map(
  //         (item) => item.waybillNo
  //       ),
  //       shipmentId: rowData.order_id,
  //     };
  //     printWaybill(waybillData);
  //     setSpinner(false);
  //   } else {
  //     setSpinner(false);
  //   }
  // };
  const getDropdownData = async () => {
    setSpinner(true);
    const dropdownModel = {
      argport: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      const respData = data.respData;
      setOriginList(respData.portArr);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };
  const handelOnSelect = (event, rowData) => {
    var updatedList = [...mergedList];
    let updatedOrderList = [...selectedData];
    if (event.target.checked) {
      updatedList = [
        ...mergedList,
        rowData.order_id + "_" + rowData.service_id,
      ];
      updatedOrderList = [...selectedData, rowData];
    } else {
      updatedList.splice(
        mergedList.indexOf(rowData.order_id + "_" + rowData.service_id),
        1
      );
      updatedOrderList.splice(mergedList.indexOf(rowData), 1);
    }
    setSelectedRoute(rowData.route);
    setMergedList(updatedList);
    setSelectedData(updatedOrderList);
  };

  const rePlanListData = () => {
    if (validateForOrigin()) {
      if (validateForSameDate()) {
        //sbu_id and trip_date are taken from local variables instead of state variables
        //due to the time taken to update state variables.
        let data = selectedData[0];
        if (!!data.sbu_id && !!data.trip_date) {
          getDriverAndVehicleByOrigin(data.sbu_id);
          setReplanningModalConfig({
            size: "lg",
            show: true,
            title: "Replanning",
          });
        }
      } else {
        let alerData = {
          isSuccess: false,
          title: "",
          description: "The selected Orders are not having same trip date",
        };
        showAlert(alerData);
      }
      // else{
      //   showAlert({
      //     isSuccess: false,
      //     size: "md",
      //     show: true,
      //     title: "",
      //     description: "Please choose one sbu and trip date",
      //   });
      // }
    } else {
      let alerData = {
        isSuccess: false,
        title: "",
        description: "selected waybills are not having same origin",
      };
      showAlert(alerData);
    }
  };

  const unassignTrip = async (rowData) => {
    const resp = await CallApiPost("unassignTrip", {
      orderId: rowData.order_id,
      serviceId: rowData.service_id,
      planId: rowData.plan,
    });
    if (resp.respCode === 200 || resp.respCode === 0) {
      reloadData();
      showAlert({
        isSuccess: true,
        size: "md",
        show: true,
        title: "",
        description: "Unassigned successfully",
      });
      setSpinner(false);
    } else {
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: "!!Some Error Occured",
      });
      setSpinner(false);
    }
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const showConfirmAlert = (tripData) => {
    setSelectedTripData(tripData);
    setConfirmAlertConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Create Bid",
      description: "Create Bid",
    });
  };

  const createBid = async () => {
    const resp = await CallApiPost("asd", { planId: "rowData.plan" });
    if (resp.respCode === 200 || resp.respCode === 0) {
      hideConfirmAlert();
      reloadData();
      showAlert({
        isSuccess: true,
        size: "md",
        show: true,
        title: "",
        description: "Bid created successfully",
      });
      setSpinner(false);
    } else {
      hideConfirmAlert();
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: "!!Some Error Occured",
      });
      setSpinner(false);
    }
  };

  const getRemarks = (rowData) => {
    let message;
    switch (rowData.service_id) {
      case PICKUP_SERVICE_ID:
        message = rowData.deliver_address + " to " + rowData.sbu_name;
        break;
      case DELIVERY_SERVICE_ID:
        message = rowData.pickup_address + " to " + rowData.sbu_name;
        break;

      default:
        message = "";
        break;
    }
    return message;
  };

  const validateForSameDate = () => {
    let tempTripDate = selectedData[0].trip_date;
    let isValid =
      selectedData.filter((item) => item.trip_date !== tempTripDate).length > 0
        ? false
        : true;
    setTripDate2(tempTripDate);
    return isValid;
  };

  const validateForOrigin = () => {
    console.log(selectedData);
    let tempOrigin = selectedData[0].sbu_id;

    let isValid =
      selectedData.filter((item) => item.sbu_id !== tempOrigin).length > 0
        ? false
        : true;

    setOrigin2(tempOrigin);
    return isValid;
  };
  const exportData = () => {
    exportToExcel(planningList, columns, "last-mile.xlsx");
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <>
          <div className="d-flex align-item-center justify-content-between py-3 m-1">
            <div className="col-auto">
              <div className="border rounded p-2 me-3 wt-tbl float-start">
                <div className="d-flex align-item-center">
                  <div className="col-auto align-self-center d-flex">
                    <label
                      htmlFor=""
                      className="form-label theme-label align-self-center me-2"
                      title="This field is mandatory"
                    >
                      From Date:
                    </label>
                    <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!fromDate ? new Date(fromDate) : fromDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setFromDate(d)}
                      onSelect={(d) => setFromDate(d)}
                      maxDate={new Date(toDate)}
                    />
                    {/* <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!tripDate ? new Date(tripDate) : tripDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setTripDate(d)}
                      onSelect={(d) => setTripDate(d)}
                    /> */}
                  </div>
                  <div className="col-auto align-self-center d-flex">
                    <label
                      htmlFor=""
                      className="form-label theme-label ms-3 align-self-center"
                      title="This field is mandatory"
                    >
                      To Date:
                    </label>
                    <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control "
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!toDate ? new Date(toDate) : toDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setToDate(d)}
                      onSelect={(d) => setToDate(d)}
                      minDate={new Date(fromDate)}
                    />
                    {/* <Select
                      options={originList}
                      placeholder="Choose One SBU"
                      value={
                        origin === ""
                          ? ""
                          : originList.find((opt) => opt.value === origin)
                      }
                      onChange={(e) => setOrigin(e ? e.value : e)}
                      isSearchable={true}
                      isClearable={true}
                      className="form-control-sm"
                    /> */}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet text-nowrap float-end mt-2"
                onClick={() => loadDataBySbuAndTripDate()}
                // disabled={!(!!tripDate && !!origin)}
                disabled={!(!!fromDate && !!toDate)}
              >
                {GetSvgIcon("Search")}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  mt-2 mb-2 ms-4"
                onClick={exportData}
                data-tooltip-id="csv-export"
              >
                {GetSvgIcon("SpreadSheet")}
              </button>
              <Tooltip
                id="csv-export"
                content="Download to Excel"
                place="top"
                style={{ zIndex: "100" }}
              />
            </div>
          </div>

          {planningList.length > 0 ? (
            <LastMilePlanningList
              selectedSbu={origin}
              planningList={planningList}
              openReplanningModal={openReplanningModal}
              openBidModal={openBidModal}
              selectedRowData={selectedRowData}
              printAllWaybill={printAllWaybill}
              mergedList={mergedList}
              handelOnSelect={handelOnSelect}
              hideColumns={hideColumns}
              unassignTrip={unassignTrip}
              showConfirmAlert={showConfirmAlert}
              getFullAddress={getFullAddress}
            />
          ) : (
            <div className="noData">No Data Available</div>
          )}
          <div className="row mt-2 border-top pt-2">
            <div className="col-lg-12 text-right">
              <button
                className="btn btn-outline-secondary text-nowrap me-2"
                type="button"
                onClick={() => loadData()}
              >
                Reset
              </button>
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() => rePlanListData()}
                disabled={!(!!mergedList && mergedList.length > 1)}
              >
                Bulk Plan
              </button>
            </div>
          </div>
        </>
      </Layout>
      {!!modalConfig?.show && (
        <LastMileBidModal
          closeBidModal={closeBidModal}
          modalConfig={modalConfig}
          vhclTypes={vhclTypes}
          initBidData={initBidData}
          showAlert={showAlert}
          setSpinner={setSpinner}
          reloadData={reloadData}
        />
      )}
      {!!replanningModalConfig?.show && (
        <LastMileRePlanningModal
          closeReplanningModal={closeReplanningModal}
          replanningModalConfig={replanningModalConfig}
          vehicles={vehicles}
          drivers={drivers}
          doRePlanning={doRePlanning}
          showAlert={showAlert}
          setSpinner={setSpinner}
          selectedRowData={selectedRowData}
        />
      )}

      <ModalConfirmAlert
        confirmAlertConfig={confirmAlertConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={createBid}
      >
        Are you sure you want to createBid
      </ModalConfirmAlert>
    </>
  );
}

import React from "react";
import Header from "../../Layout/Header";
import GetSvgIcon from "../../../images/svglist";
import { useRef } from "react";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import SLAAddEdit from "./SLAAddEdit";
import { useEffect } from "react";
import { useState } from "react";
import {
  initAlertConfig,
  initRateCode,
  initService,
  initServiceDetails,
} from "./initRateCode";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import ServiceList from "./ServiceList";
import { Tooltip } from "react-tooltip";
import SLADynamicRate from "./SLADynamicRate";
import ModalAlert from "../../Layout/ModalAlert";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";

export default function SlaServiceMain() {
  const [slaServiceRow, setSlaServiceRow] = useState([]);
  const [slaService, setSlaService] = useState(initService);
  const [rateCode, setRateCode] = useState(initRateCode);
  const [modalConfig, setModalConfig] = useState();
  const [rateCodeList, setRateCodeList] = useState([
    {
      label: "",
      value: 0,
    },
  ]);
  const [customerList, setCustomerList] = useState([
    {
      label: "",
      value: 0,
    },
  ]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [slaServiceDtls, setSlaServiceDtls] = useState(initServiceDetails);
  const [dynPrice, setDynPrice] = useState([]);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [showDefAlert, setShowDefAlert] = useState(false);

  const modalRef = useRef();
  useEffect(() => {
    getAllServiceList();
    getAllRestCodeList();
    getAllCustomerList();
  }, []);
  const getAllServiceList = async () => {
    let data = await CallApiPost("ListService", slaService);
    if (data.respCode === 0) {
      setSlaServiceRow(data.respData);
    }
  };
  const getAllRestCodeList = async () => {
    let rateArr = [];
    rateCode["rateCode"] = "";
    rateCode["rateCodeId"] = 0;
    rateCode["rateCodeDesc"] = "";
    const data = await CallApiPost("ListRateCode", rateCode);
    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        rateArr.push({
          label: element.rateCode,
          value: element.rateCodeId,
        });
      });
      setRateCodeList(rateArr);
    }
  };

  const getAllCustomerList = async () => {
    let custArr = [];
    const data = await CallApiGet("get-cust-data");
    if (data.respCode === 200) {
      data.respData.forEach((element) => {
        custArr.push({
          label: `${element.name} (${element.accCode})`,
          value: element.id,
        });
      });
      custArr.sort((a, b) => a.label.localeCompare(b.label));
      setCustomerList(custArr.sort((a, b) => a.label.localeCompare(b.label)));
    }
  };
  const clearService = () => {
    setSlaService(JSON.parse(JSON.stringify(initService)));
  };
  const toggleAlert = (boolData) => {
    setShowDefAlert(boolData);
  };
  const columns = [
    {
      name: "Service",
      selector: (row) => row.serviceName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Description",
      selector: (row) => row.serviceDesc,
      sortable: true,
      //filterable: true,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: true,
      filterable: true,
    },

    {
      name: "Active",
      selector: (row) => (row.active ? "YES" : "NO"),
      filterable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      name: "Default",
      sortable: false,
      filterable: false,
      cell: (row, index) => (
        <div className="toggleSwitchWrapper">
          <input
            name={"row_group_" + index}
            type="radio"
            className="btn-check"
            id={"planned_" + index}
            autoComplete="off"
            checked={row.isDefault === true ? true : false}
            onClick={() => {
              setSlaService(row);
              toggleAlert(true);
            }}
            disabled={row.isDefault === true}
          />
          <label
            className="btn btn-outline-success btn-sm tl-btn font-12"
            htmlFor={"planned_" + index}
          >
            Yes
          </label>

          <input
            name={"row_group_" + index}
            type="radio"
            className="btn-check"
            id={"Waiting_" + index}
            autoComplete="off"
            checked={row.isDefault === false ? true : false}
          />
          <label
            className="btn btn-outline-success btn-sm tr-btn font-12"
            htmlFor={"Waiting_" + index}
          >
            No
          </label>
        </div>
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => openDynRateModal(row)}
            data-tooltip-id={"DR-" + row.serviceId}
          >
            {GetSvgIcon("PaymentDetails")}
          </div>
          <Tooltip
            id={"DR-" + row.serviceId}
            content={"Dynamic Rate"}
            place="left"
          />
        </>
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => {
              handleAction(row);
            }}
            data-bs-toggle="modal"
            data-bs-target="#service-modal"
            data-tooltip-id={"edit-" + row.serviceId}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.serviceId} content={"Edit"} place="left" />
        </>
      ),
    },
  ];
  const openPopup = (bool, title, desc) => {
    setIsSuccess(bool);
    setTitle(title);
    setDescription(desc);
    modalRef.current.click();
  };
  const callback = () => {
    getAllServiceList();
  };
  const handleAction = (row) => {
    fetchServiceAndServiceDtlsList(row);
  };
  const openDynRateModal = (row) => {
    setSlaService(row);
    getDynamicRate(row);

    setModalConfig({
      size: "lg",
      show: true,
      title: "Dynamic Rate Configuration",
    });
  };

  const getDynamicRate = async (row) => {
    let dyRateArr = [];
    let data = await CallApiPost("getSLADynamicRate/" + row.serviceId, {});
    if (data.respCode === 0) {
      let dyDat = data.respData;
      if (dyDat.length > 0) {
        dyRateArr = dyDat;
        setDynPrice({
          serviceId: row.serviceId,
          dyRateArr: dyRateArr,
        });
      } else {
        setDynPrice({
          serviceId: row.serviceId,
          dyRateArr: [
            {
              loadFrom: 0,
              loadTo: 0,
              rate: 0,
              dynPriceId: 0,
              remark: "",
              serviceId: row.serviceId,
              percentage: "true",
            },
          ],
        });
      }
    } else {
      setDynPrice({
        serviceId: row.serviceId,
        dyRateArr: [
          {
            loadFrom: 0,
            loadTo: 0,
            rate: 0,
            dynPriceId: 0,
            remark: "",
            serviceId: row.serviceId,
            percentage: "true",
          },
        ],
      });
    }
  };
  const closeDynRateModal = () => {
    setModalConfig({
      size: "lg",
      show: false,
      title: "Dynamic Rate Configuration",
    });
  };
  const fetchServiceAndServiceDtlsList = async (row) => {
    let serviceArr = [];
    slaServiceDtls["serviceId"] = row.serviceId;
    let data = await CallApiPost("ListServiceDetail", slaServiceDtls);

    if (data.respCode === 0) {
      data.respData.map((e) => {
        // e.serviceTargetIds = e.serviceTargetIds.split(",")
        e.customerIds = e.customerIds.split(",").map((numStr) => +numStr);
        serviceArr.push(e);
      });
    }
    const serviceData = {
      serviceId: row.serviceId,
      serviceName: row.serviceName,
      serviceDesc: row.serviceDesc,
      active: row.active,
      international: false,
      priority: row.priority,
      allowPickup: row.allowPickup,
      allowDelivery: row.allowDelivery,
    };
    serviceData["serviceDtlsList"] = serviceArr;
    setSlaService(serviceData);
  };
  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (isSuccess, title, description) => {
    setAlertConfig({
      isSuccess: isSuccess,
      size: "md",
      show: true,
      title: title,
      description: description,
    });
  };
  const clearData = () => {
    setSlaService(JSON.parse(JSON.stringify(initService)));
  };
  const serviceMakeAsDefault = async () => {
    let resp = await CallApiPost(
      "updateSlaServiceAsDefault/" + slaService.serviceId,
      {}
    );
    switch (resp.respCode) {
      case 200:
      case 0:
        clearData();
        hideAlert();
        openPopup(
          true,
          "SUCCESS",
          slaService.serviceName + " Set As Default Service"
        );
        setShowDefAlert(false);
        getAllServiceList();
        break;
      default:
        clearData();
        getAllServiceList();
        break;
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">LIST OF Service</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-start me-2">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#service-modal"
                  >
                    {GetSvgIcon("PlusCircle")} ADD SLA
                  </button>
                </div>
                <div className="float-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <SLAAddEdit
              rateCodeList={rateCodeList}
              slaService={slaService}
              setSlaService={setSlaService}
              customerList={customerList}
              clearService={clearService}
              openPopup={openPopup}
              setSpinner={setShowSpinner}
            />
            <ServiceList data={slaServiceRow} columns={columns} />
            {/* <LhServiceAdd rowDataModel={dynPrice} slaService={slaService}/> */}
            {!!modalConfig?.show && (
              <SLADynamicRate
                rowDataModel={dynPrice}
                modalConfig={modalConfig}
                closeDynRateModal={closeDynRateModal}
                showAlert={showAlert}
                callback={callback}
                setSpinner={setShowSpinner}
              />
            )}
          </main>
        </div>
        <Popup
          isSuccess={isSuccess}
          title={title}
          description={description}
          handler={callback}
        />
        {!!alertConfig && (
          <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
            <p>{alertConfig.description}</p>
          </ModalAlert>
        )}
        {showDefAlert && (
          <CustomAlert
            toggleReset={toggleAlert}
            alertTitle="Default Service"
            alertBody={
              <p>
                Are you sure you want to set <b>{slaService.serviceName}</b>{" "}
                service as the default? If you do, the other SLA that is
                currently marked as default will be removed.
              </p>
            }
            callback={serviceMakeAsDefault}
          />
        )}
        <Spinner show={showSpinner} />
      </div>
    </>
  );
}

import React from "react";
import { Accordion } from "react-bootstrap";

const BookingPromoCodes = (props) => {
  const { register, promoCodeList, applyPromoCode, setValue, watch } = props;

  const applyPromo = (e) => {
    setValue("promoCode", e.promo_code);
  };

  return (
    <div>
      <div className="input-group mb-1">
        <input
          id="promoText"
          type="text"
          className="form-control "
          name=""
          placeholder="Enter Promocode"
          {...register("promoCode")}
        />
        <button
          type="button"
          onClick={applyPromoCode}
          className="btn btn-outline-secondary theme-violet"
        >
          APPLY
        </button>
      </div>
      {watch("promoErrorResp") && (
        <div class="font-12 text-danger mb-3">
          <i>{watch("promoErrorResp")}</i>
        </div>
      )}

      <div className="promocode-options mt-3">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div>VIEW MORE PROMOCODES </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="">
                {!!promoCodeList && promoCodeList.length > 1
                  ? promoCodeList.map((item) => (
                      <label className="p-2 d-block">
                        <input
                          className="form-check-input formsty"
                          type="radio"
                          name="promoCrd"
                          id="inlineRadio1"
                          value="CASH"
                          onChange={() => applyPromo(item)}
                        />
                        <label
                          className="form-check-label ms-3"
                          htmlFor="inlineRadio1"
                        >
                          <span className="theme-blue-txt fw-bold">
                            {" "}
                            {item.promo_name+"("+item.promo_code+")"}{" "}
                          </span>
                          {item.discountService === "Discount" ? (
                            <div className="font-12">
                              <i>
                                Discount:
                                {item.percentage === true ? (
                                  <span>
                                    {item.discount}% Max Discount :
                                    {item.max_discount}RM
                                  </span>
                                ) : (
                                  <span>{item.discount}RM</span>
                                )}
                              </i>
                            </div>
                          ) : (
                            <div className="font-12">
                              <i>Services: </i>
                              {JSON.parse(item.services).map(
                                (service, index) => (
                                  <i key={index}>
                                    {service?.serviceId === 9
                                      ? "Pickup(" + service.freeLimit + "KM) "
                                      : service?.serviceId === 148
                                      ? "Delivery(" + service.freeLimit + "KM) "
                                      : service?.serviceId === 10
                                      ? "Packaging(" +
                                        service.freeLimit +
                                        " Pallets) "
                                      : "Handling(" +
                                        service.freeLimit +
                                        " Pallets) "}
                                  </i>
                                )
                              )}
                            </div>
                          )}
                        </label>
                      </label>
                    ))
                  : ""}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default BookingPromoCodes;

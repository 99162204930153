import moment from "moment";
import React, { useEffect, useState } from "react";
import { CallApiPost } from "../../Helper/serviceApi";
import { permissionCheck } from "../../Helper/CommonMethods";
import { INV, INVS } from "../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import Layout from "../../Layout/Layout";
import ReactDatePicker from "react-datepicker";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  DATE_TIME_FORMAT_MOMENT,
} from "../../Helper/Constants";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import CreditNoteSummaryList from "./CreditNoteSummaryList";
const { Buffer } = require("buffer");

const CreditNoteSummary = () => {
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [spinner, setSpinner] = useState(false);
  const customerType = getUserInfo("customerType");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [cnData, setCnData] = useState();
  const custConditionalStyle2 = [
    {
      when: (row) => row.stat_id === 100,
      style: {
        color: "#43cb43",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.stat_id === 178,
      style: {
        color: "red",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.stat_id === 710,
      style: {
        color: "#964b00",
        fontWeight: 500,
      },
    },
  ];

  const cnColumns = [
    {
      name: "Customer",
      selector: (row) => row.business_name + "(" + row.acct_code + ")",
      sortable: true,
      filterable: true,
      // omit: hideColums,
      //
    },
    {
      name: "Total CN Amount",
      selector: (row) => !!row.cn_amount ? row.cn_amount?.toFixed(2) : "0.00",
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Utilized CN Amount",
      selector: (row) =>
        !!row.utilize_amount ? row.utilize_amount?.toFixed(2) : "0.00",

      sortable: true,
      filterable: true,
    },
    {
      name: "Balance CN Amount",
      selector: (row) => !!row.available_amount ? row.available_amount?.toFixed(2) : "0.00",
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"invDTLs-" + row.customer_id}
            className="me-3"
            onClick={() => {
              openCustCn(row);
            }}
          >
            {GetSvgIcon("PaymentDetails")}
          </div>
          <Tooltip
            id={"invDTLs-" + row.customer_id}
            content="View Details"
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  useEffect(() => {
    setToDate(new Date());
    setFromDate(new Date(moment().subtract(3, "M")));
    cnSummary(new Date(moment().subtract(3, "M")),new Date());
  }, []);

  const loadDta = ()=>{
    cnSummary(fromDate,toDate)
  }

  const cnSummary = async (fdate,tdate) => {
    setSpinner(false);
    
    const uri = "creditNoteSummary";
    let param = {
      dateFrom: moment(fdate).format(DATE_FORMAT_SERVER),
      dateTo: moment(tdate).format(DATE_FORMAT_SERVER),
    };
    let resp = await CallApiPost(uri, param);
    if (resp.respCode === 200) {
      setCnData(resp.respData);
    } else {
      setCnData([]);
    }

    setSpinner(false);
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };
  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const openCustCn = (data) => {
    const encodedData = Buffer.from(JSON.stringify({
      custId: data?.customer_id,
      fromDate:fromDate,
      toDate:toDate,
    })).toString("base64");
    const url = `/creditNoteDtls/${encodedData}`;
    window.open(url, "_blank");
  };

  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={hideAlert}
    >
      {/* <div>
        <div className="col-auto mt-2">
          <div className="border rounded p-2 me-3 wt-tbl float-start">
            <div className="d-flex align-item-center">
              <div className="col-auto align-self-center d-flex">
                <label
                  htmlFor=""
                  className="form-label theme-label align-self-center me-2"
                  title="This field is mandatory"
                >
                  From Date:
                </label>
                <ReactDatePicker
                  placeholderText="Select Trip Date"
                  className="form-control"
                  dateFormat={DATE_FORMAT_CLIENT}
                  selected={!!fromDate ? new Date(fromDate) : fromDate}
                  onFocus={(e) => e.target.blur()}
                  onChange={(d) => setFromDate(d)}
                  onSelect={(d) => setFromDate(d)}
                  maxDate={new Date(toDate)}
                  // minDate={new Date(moment(toDate).subtract(3,"months"))}
                />
              </div>
              <div className="col-auto align-self-center d-flex">
                <label
                  htmlFor=""
                  className="form-label theme-label ms-3 align-self-center"
                  title="This field is mandatory"
                >
                  To Date:
                </label>
                <ReactDatePicker
                  placeholderText="Select Trip Date"
                  className="form-control "
                  dateFormat={DATE_FORMAT_CLIENT}
                  selected={!!toDate ? new Date(toDate) : toDate}
                  onFocus={(e) => e.target.blur()}
                  onChange={(d) => setToDate(d)}
                  onSelect={(d) => setToDate(d)}
                  // maxDate={new Date()}
                  minDate={new Date(fromDate)}
                />
              </div>
              <button
            type="button"
            className="btn btn-outline-secondary theme-violet text-nowrap float-end ms-3"
            onClick={() => {
              loadDta();
            }}
          >
            {GetSvgIcon("Search")}
          </button>
            </div>
            
          </div>
          
        </div>
      </div> */}
      <CreditNoteSummaryList cnColumns={cnColumns} cnData={cnData} />
    </Layout>
  );
};

export default CreditNoteSummary;

import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select'
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import { NET_ERR_MSG, NUMBER_REGEX, NUMBER_TWO_DECIMAL, REQUIRED_ERR_MSG, SERVER_ERR_MSG } from "../../Helper/Constants";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import GetSvgIcon from "../../../images/svglist";

const rowDataModel = { bookingType: "", hourFrom: 0, hourTo: 0, charge: "", percent: true, isDeleted: false, id: 0 };
const rowErrorModel = {
  bookingType: {
    value: false,
    message: ""
  },
  hourTo: {
    value: false,
    message: ""
  },
  charge: {
    value: false,
    message: ""
  }
};
const ShipmentCancellationConfig = () => {
  const [show, setShow] = useState(false)
  const [rowsData, setRowsData] = useState([rowDataModel]);
  const [rowErrorData, setRowErrorData] = useState([rowErrorModel])
  const [isFormValid, setFormValid] = useState(true)

  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();

  const optionData = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const bookingTypeOpts = [
    { label: "Booking", value: "Booking" },
    { label: "Pre-Booking", value: "Pre-Booking" }
  ]

  useEffect(() => {
    getAllData();
  }, []);

  const addCancelTableRows = () => {
    setRowsData([...rowsData, JSON.parse(JSON.stringify(rowDataModel))]);
    setRowErrorData([...rowErrorData, JSON.parse(JSON.stringify(rowErrorModel))])
  };

  const deleteCancelTableRows = (index) => {

    const rows = [...rowsData]
    const rowErrs = [...rowErrorData]

    rows.splice(index, 1)
    setRowsData([...rows])

    rowErrs.splice(index, 1)
    setRowErrorData([...rowErrs])
  }

  const handleChange = (index, evnt) => {

    const { name, value } = evnt.target;

    if (name === "hourTo") {
      setHours(name, value, index)
    } else {
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      setRowsData(rowsInput);
    }
    validate(name, value, index)
  };

  const setHours = (name, value, index) => {
    const rowsInput = [...rowsData]
    if (!!value && value !== "") {
      rowsInput[index][name] = parseInt(value)
      rowsInput[index]["hourFrom"] = 0
    } else {
      rowsInput[index][name] = 0
      rowsInput[index]["hourFrom"] = 0
    }

    setRowsData(rowsInput);
  }

  const processData = (argRowsData) => {

    let prevBookObj = null
    const bookArr = argRowsData
      .filter(data => data.bookingType === "Booking")
      .sort((obj1, obj2) => obj1.hourTo - obj2.hourTo)
      .map(obj => {
        if (!!prevBookObj) {
          obj.hourFrom = prevBookObj.hourTo
        }
        prevBookObj = obj
        return obj
      })

    let prevPreBObj = null
    const preBookArr = argRowsData
      .filter(data => data.bookingType === "Pre-Booking")
      .sort((obj1, obj2) => obj1.hourTo - obj2.hourTo)
      .map(obj => {
        if (!!prevPreBObj) {
          obj.hourFrom = prevPreBObj.hourTo
        }
        prevPreBObj = obj
        return obj
      })

    return [...bookArr, ...preBookArr]
  }

  const validate = (name, value, index) => {
    const rowErr = [...rowErrorData]
    switch (name) {
      case "hourTo":
        if (NUMBER_REGEX.test(value)) {
          const bokngTyp = rowsData[index].bookingType
          if (value !== "" && bokngTyp !== null
            && rowsData.some((element, eleInd) => (
              element.bookingType === bokngTyp && element.hourTo === parseInt(value)

              && eleInd !== index
            ))
          ) {
            const errObj = { value: true, message: "Duplicate Type and Limit" }
            const updatedErr = { ...rowErr[index], [name]: errObj }
            rowErr[index] = updatedErr
            setFormValid(false)
          } else {
            const errObj = { value: false, message: "" }
            const updatedErr = { ...rowErr[index], [name]: errObj }
            rowErr[index] = updatedErr
            setFormValid(true)
          }

        } else {
          const errObj = { value: true, message: "Accepts numeric values" }
          const updatedErr = { ...rowErr[index], [name]: errObj }
          rowErr[index] = updatedErr
          setFormValid(false)
        }
        break;
      case "charge":
        if (NUMBER_TWO_DECIMAL.test(value)) {
          const errObj = { value: false, message: "" }
          const updatedErr = { ...rowErr[index], [name]: errObj }
          rowErr[index] = updatedErr
          setFormValid(true)
        } else {
          const errObj = { value: true, message: "Accepts 2 digit decimal numbers" }
          const updatedErr = { ...rowErr[index], [name]: errObj }
          rowErr[index] = updatedErr
          setFormValid(false)
        }
        break;
      default:
        break;
    }
    setRowErrorData(rowErr);
  }

  const handleBokngTyp = (opt, index) => {
    const inRows = [...rowsData]
    const inErrRows = [...rowErrorData]

    if (opt === null) {
      const updatedRow = { ...inRows[index], bookingType: opt.value }
      inRows[index] = updatedRow

      const updatedErr = {
        ...inErrRows[index], bookingType: {
          value: true,
          message: REQUIRED_ERR_MSG
        }
      }
      inErrRows[index] = updatedErr
      setFormValid(false)
    } else {
      const updatedRow = { ...inRows[index], bookingType: opt.value }
      inRows[index] = updatedRow
      const hourTo = inRows[index].hourTo
      if (hourTo !== 0 && inRows.some((row, rowIndex) => (index !== rowIndex && row.bookingType === opt.value && row.hourTo === hourTo))) {
        const updatedErr = {
          ...inErrRows[index], bookingType: {
            value: true,
            message: "Duplicate Type and Limit"
          }
        }
        inErrRows[index] = updatedErr
        setFormValid(false)
      } else {
        const updatedErr = {
          ...inErrRows[index], bookingType: {
            value: false,
            message: ""
          }
        }
        inErrRows[index] = updatedErr
        setFormValid(true)
      }

    }
    setRowsData(inRows)
    setRowErrorData(inErrRows)
  }

  const saveData = async () => {

    if (rowsData.length > 0 && customValidation() && isFormValid) {
      setShow(true)
      const payload = processData(rowsData)
      const data = await CallApiPost("saveCancellationConfig", payload)
      switch (data.respCode) {
        case 200: case 0:
          onSuccess()
          break;
        case 500:
          openPopup(false, "ERROR", SERVER_ERR_MSG)
          break;
        case 99:
          openPopup(false, "ERROR", NET_ERR_MSG)
          break;
        default:
          openPopup(false, "ERROR", data.respMessage)
          break;
      }
      setShow(false)
    }
  };

  const getAllData = async () => {
    setShow(true)
    let data = await CallApiGet("getAllCancellationConfig")
    switch (data.respCode) {
      case 200: case 0:
        setRowsData(data.respData);
        setRowErrorData(new Array(data.respData?.length).fill(rowErrorModel))
        break;
      case 500:
        openPopup(false, "ERROR", SERVER_ERR_MSG)
        break;
      case 99:
        openPopup(false, "ERROR", NET_ERR_MSG)
        break;
      default:
        openPopup(false, "ERROR", data.respMessage)
        break;
    }
    setShow(false)
  };

  const customValidation = () => {
    let isValid = true;
    const rows = [...rowErrorData]
    const errObj = {
      value: true,
      message: REQUIRED_ERR_MSG
    }
    for (let index = 0; index < rowsData.length; index++) {
      const element = rowsData[index];
      if (element.bookingType === "" || element.bookingType === null || element.bookingType === undefined || element.bookingType === "null") {
        const updatedRow = { ...rows[index], bookingType: errObj }
        rows[index] = updatedRow
        isValid = false;
      }
      if (element.charge === "") {
        const updatedRow = { ...rows[index], charge: errObj }
        rows[index] = updatedRow
        isValid = false;
      }
      if (element.hourTo === 0) {
        const updatedRow = { ...rows[index], hourTo: errObj }
        rows[index] = updatedRow
        isValid = false;
      }

    }
    setRowErrorData(rows);

    return isValid;
  };

  const callback = () => {
    getAllData()
  }

  const openPopup = (boolData, title, desc) => {
    setIsSuccess(boolData);
    setTitle(title);
    setDescription(desc);
    modalRef.current.click();
  }

  const onSuccess = () => {
    setIsSuccess(true)
    setTitle("Success!")
    setDescription("Cancellation configuration saved successfully !!!")
    modalRef.current.click()
  }

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">
                  Shipment Cancellation Configuration
                </div>
              </div>
              <div className="float-end d-flex"></div>
            </div>
            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
            <div className="col-md-8 mt-5 m-auto table-responsive minheight">
              <div className="border border-3 rounded">
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Booking Type</th>
                      <th>Hours Limit</th>
                      <th>Charge</th>
                      <th>Percentage</th>
                      <th >
                        <div
                          className="px-2 text-success"
                          onClick={() => addCancelTableRows()}
                        >
                          {GetSvgIcon("PlusCircleNoFill")}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData?.map((data, index) => {
                      let { bookingType, hourTo, charge, percent } = data;
                      return (

                        <tr key={index}>
                          <td>
                            <Select
                              name='bookingType'
                              className="form-control"
                              options={bookingTypeOpts}
                              onChange={opt => handleBokngTyp(opt, index)}
                              value={bookingTypeOpts.filter(opt => opt?.value === (bookingType ? bookingType : ""))}
                              placeholder="Select Booking Type"
                            />
                            {rowErrorData[index] &&
                              rowErrorData[index].bookingType?.value && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {rowErrorData[index].bookingType?.message}
                                  </span>
                                </div>
                              )}
                          </td>
                          <td >
                            <input
                              type="text"
                              className="form-control"
                              value={hourTo === 0 ? '' : hourTo + ''}
                              // onBlur={(evnt) => handleChange(index, evnt)}
                              onChange={(evnt) => handleChange(index, evnt)}
                              name="hourTo"
                            />
                            {rowErrorData[index] &&
                              rowErrorData[index].hourTo.value && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {rowErrorData[index].hourTo.message}
                                  </span>
                                </div>
                              )}
                          </td>

                          <td>
                            <input
                              type="text"
                              className="form-control"
                              value={charge}
                              onChange={(evnt) => handleChange(index, evnt)}
                              name="charge"
                            />
                            {rowErrorData[index] &&
                              rowErrorData[index].charge.value && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {rowErrorData[index].charge.message}
                                  </span>
                                </div>
                              )}
                          </td>

                          <td>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="percent"
                              onChange={(evnt) => handleChange(index, evnt)}
                            >
                              {optionData.map((i) => {
                                if (percent === i.value) {
                                  return (
                                    <option value={i.value} selected>
                                      {i.label}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={i.value}>{i.label}</option>
                                  );
                                }
                              })}
                            </select>
                          </td>
                          <td >
                            <div
                              className="px-2 text-danger"
                              onClick={() => deleteCancelTableRows(index)}
                            >
                              {GetSvgIcon("DashCircleNoFill")}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="clearfix col-md-1 m-auto my-3">
                  <button
                    className="btn btn-outline-secondary theme-violet"
                    onClick={saveData}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
    </>
  );
};

export default ShipmentCancellationConfig;

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  GetSvgBank,
  GetSvgCard,
  GetSvgMail,
  GetSvgMail2,
  GetSvgPerson,
  GetSvgPersonAdd,
  GetSvgPhone,
  GetSvgUpload,
} from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { toBase64 } from "../../Helper/CommonMethods";
import {
  ALPHANUM,
  ALPHANUM_NAME,
  COUNTRY_CODES,
  EMAIL,
  INVALID_NAME,
  NAME_REGEX,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  customReactSelectMenuStyle,
} from "../../Helper/Constants";
import { CallApiPost, CallApiGet } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import { initValIsr } from "./initValIsr";
import Select from "react-select";

const initStatementFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};
const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};

const initIcFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};

const initialFormData = {
  acNumber: "",
  address: "",
  bankName: "",
  beneficiaryName: "",
  email: "",
  isrIc: "",
  isrIcImg: "",
  isrPrflImg: "",
  name: "",
  phone: "",
  postCode: "",
  bankStatement: "",
};

const IsrRegistration = () => {
  const [statementFile, setStatementFile] = useState(initStatementFile);
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [icFile, setIcFile] = useState(initIcFile);
  const [statementFileLabel, setStatementFileLabel] =
    useState("Choose a File ...");

  const [photoImg, setPhotoImg] = useState();
  const [icImg, setIcImg] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [photoImgErrMsg, setPhotoImgErrMsg] = useState();
  const [icImgErrMsg, setIcImgErrMsg] = useState();
  const [statementImgErrMsg, setStatementImgErrMsg] = useState();
  const [show, setShow] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate();
  // const [selectedCity, setSelectedCity] = useState("");
  // const [selectedState, setSelectedState] = useState("");
  // const [selectedPostCode, setSelectedPostCode] = useState("");
  const [stateCity, setStateCity] = useState();
  const [cityListData, setCityListData] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: initValIsr });

  useEffect(() => {
    register("userId");
    setValue("userId", getUserInfo("userId"));
    fetchStatesAndCityList();
  }, []);

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
    setCityListData(cityArrList);
  };

  const selectStatementFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      setStatementFileLabel("Choose a File ...");
    } else {
      // if(file[0].size<2000000){
      var fileName = file[0].name;
      setStatementFileLabel(fileName);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";

      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setStatementFile(newAttachment);
      setValue("bankStatement", newAttachment);
      setStatementImgErrMsg();

      // }else{
      //   setStatementImgErrMsg("File Size Exceeded");
      // }
    }
  };

  const selectIcFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setIcImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setIcFile(newAttachment);
      setValue("isrIcImg", newAttachment);
      setIcImgErrMsg();
      // console.log(attachment);
    }
  };

  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("isrPrflImg", newAttachment);
      clearErrors("isrPrflImg");
      setPhotoImgErrMsg();
      // console.log(attachment);
    }
  };

  const saveData = async (data) => {
    if (validateAttachment()) {
      // console.log(data);
      setShow(true);

      data.phone = data.phoneCode + data.phone;
      delete data.phoneCode;

      let rmUserRoles = getUserInfo("userRoles");

      data.sbuId = JSON.parse(rmUserRoles)[0].sbuId;
      data.active = false;

      var apiData = await CallApiPost("register-isr", data);
      setShow(false);

      if (apiData.respCode === 200) {
        let rspData = apiData.respData;
        if (rspData.id !== 0) onSuccess();
        else onFailure();
      } else {
        if (apiData.respCode === 409) {
          setIsSuccess(false);
          setTitle("Not Allowed");
          setDescription(apiData.respMessage);
          modalRef.current.click();
        } else if (apiData.respCode === 400) {
          setIsSuccess(false);
          setTitle("Invalid Data");
          setDescription(apiData.respMessage);
          modalRef.current.click();
        } else {
          onFailure();
        }
      }
    }
  };

  const onSuccess = () => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription("ISR registration successful");
    modalRef.current.click();
  };

  const onFailure = () => {
    setIsSuccess(false);
    setTitle("ERROR");
    setDescription("Something Went Wrong. Please try after sometime!!!");
    modalRef.current.click();
  };

  const callback = () => {
    navigate("/isrActivation");
  };

  const clearData = () => {
    reset(initialFormData);
    setPhotoImg("");
    setIcImg("");
    setStatementFileLabel("Choose a File ...");
    navigate(-1);
  };

  const onError = (data) => {
    console.log(data);
  };

  const validateAttachment = () => {
    let flag = true;
    if (photoFile.photoFileName === "") {
      setPhotoImgErrMsg("Choose a Photo");
      flag = false;
    }
    if (icFile.photoFileName === "") {
      setIcImgErrMsg("Choose a IC");
      flag = false;
    }
    if (statementFile.photoFileName === "") {
      setStatementImgErrMsg("Choose a Bank Statement");
      flag = false;
    }

    return flag;
  };

  const filterCity = (state) => {
    let cityArrList = [];

    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });
      setCityListData(cityArrList);
    } else {
      setCityListData(cityData);
      setValue("city", "");
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    }
    // else{
    //   setValue("state", "")
    // }
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">ISR Registration</div>
              </div>
              <div className="float-end"></div>
            </div>
            <div className="col-md-8 mt-3 m-auto p-4 border rounded-3 wt-tbl">
              <div className="row clearfix">
                <div className="col-md-9 mb-2">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgPerson({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="Name"
                          aria-label="Name"
                          aria-describedby="basic-addon1"
                          {...register("name", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: NAME_REGEX,
                              message: INVALID_NAME,
                            },
                            maxLength: {
                              value: 40,
                              message: "Exceeded max limit!!!",
                            },
                          })}
                          // maxLength="40"
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.name && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.name.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgPhone({ required: false })}
                        </span>
                        <select
                          name="phoneCode"
                          {...register("phoneCode")}
                          defaultValue="+60"
                          className="form-control country-code-customize"
                        >
                          {COUNTRY_CODES.map((cc, index) => (
                            <option key={index} value={cc.value}>
                              {cc.label}
                            </option>
                          ))}
                        </select>
                        <input
                          type="tel"
                          className="form-control border-end-0 pe-0"
                          placeholder="Phone"
                          aria-label="Phone"
                          aria-describedby="basic-addon1"
                          {...register("phone", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            minLength: {
                              value: 8,
                              message: "Not a Phone Number!!!",
                            },
                            pattern: {
                              value: NUMBER_REGEX,
                              message: "Incorrect Phone Number!!!",
                            },
                            maxLength: {
                              value: 13,
                              message: "Field should contain 13 characters",
                            },
                          })}
                          // maxLength="20"
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.phone && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.phone.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgMail({ required: false })}
                        </span>
                        <input
                          type="email"
                          className="form-control border-end-0 pe-0"
                          placeholder="Email"
                          aria-label="Email"
                          aria-describedby="basic-addon1"
                          {...register("email", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: EMAIL,
                              message: "Incorrect Email!!!",
                            },
                          })}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.email && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.email.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgMail2({ required: false })}
                        </span>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={stateData}
                              placeholder="Select State"
                              value={
                                value
                                  ? stateData.filter((opt) =>
                                      value.includes(opt.value)
                                    )
                                  : value
                              }
                              onChange={(d) =>
                                onChange(
                                  d ? d.value : d,
                                  filterCity(d ? d.value : d)
                                )
                              }
                              isSearchable={true}
                              isClearable={true}
                              className="form-control border-end-0 p-0"
                              styles={customReactSelectMenuStyle}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                          }}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.state && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.state.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgMail2({ required: false })}
                        </span>
                        {/* <Controller
                          name="city"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={cityListData}
                              placeholder="Select City"
                              value={
                                value
                                  ? cityListData.filter((opt) =>
                                    value.includes(opt.value)
                                  )
                                  : value
                              }
                              onChange={(d) => onChange(d ? d.value : d, filterState(d ? d.value : d))}
                              isSearchable={true}
                              isClearable={true}
                              className="form-control border-end-0 p-0"
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            }
                          }}
                        /> */}
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="City"
                          aria-label="Name"
                          aria-describedby="basic-addon1"
                          {...register("city", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: NAME_REGEX,
                              message: INVALID_NAME,
                            },
                            maxLength: {
                              value: 40,
                              message: "Exceeded max limit!!!",
                            },
                          })}
                          // maxLength="40"
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.city && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.city.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgMail2({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          name="postCode"
                          placeholder="Post Code"
                          aria-label="Post Code"
                          aria-describedby="basic-addon1"
                          {...register("postCode", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            maxLength: {
                              value: 20,
                              message: "20 characters required!!",
                            },
                          })}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.postCode && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.postCode.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgMail2({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="Address"
                          aria-label="Address"
                          {...register("address", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            maxLength: {
                              value: 200,
                              message: "Input field takes 200 characters!!",
                            },
                          })}
                          // maxLength="200"
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.address && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.address.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgPerson({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="ISR IC"
                          aria-label="ISR IC"
                          aria-describedby="basic-addon1"
                          max="10"
                          {...register("isrIc", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: ALPHANUM_NAME,
                              message: REQUIRED_ERR_MSG,
                            },
                            maxLength: {
                              value: 50,
                              message: "50 characters required!!",
                            },
                          })}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.isrIc && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.isrIc.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgBank({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="Bank Name"
                          aria-label="Bank Name"
                          aria-describedby="basic-addon1"
                          max="100"
                          {...register("bankName", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: NAME_REGEX,
                              message: INVALID_NAME,
                            },
                            maxLength: {
                              value: 100,
                              message: "Input field contains 100 characters!!",
                            },
                          })}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.bankName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.bankName.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgCard({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="Account Number"
                          aria-label="Phone"
                          aria-describedby="basic-addon1"
                          max="50"
                          {...register("acNumber", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: ALPHANUM,
                              message: PATTERN_ERR_MSG,
                            },
                            maxLength: {
                              value: 100,
                              message: "Input field contains 100 characters!!",
                            },
                          })}
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.acNumber && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.acNumber.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mt-2">
                        <span
                          className="input-group-text inputsty"
                          id="basic-addon1"
                        >
                          {GetSvgPersonAdd({ required: false })}
                        </span>
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="Beneficiary Name"
                          aria-label="Phone"
                          aria-describedby="basic-addon1"
                          max="100"
                          {...register("beneficiaryName", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: NAME_REGEX,
                              message: INVALID_NAME,
                            },
                            maxLength: {
                              value: 40,
                              message: "Exceeded max limit!!!",
                            },
                            maxLength: {
                              value: 100,
                              message: "Input field contains 100 characters!!",
                            },
                          })}
                          // maxLength="40"
                        />
                        <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                          *
                        </span>
                      </div>
                      {errors.beneficiaryName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.beneficiaryName.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <div
                        className="mt-2 file-placeholder Statement w-100"
                        {...register("bankStatement", {
                          // required: {
                          //   value: true,
                          //   message: REQUIRED_ERR_MSG,
                          // },
                        })}
                      >
                        <input
                          type="file"
                          className="w-100 h-auto"
                          accept=".pdf"
                          onChange={selectStatementFile}
                          // style={{zIndex:99}}
                        />
                        {statementFileLabel === "Choose a File ..." ? (
                          <div>
                            {GetSvgUpload({ required: false })}
                            Upload Bank Statement
                          </div>
                        ) : (
                          <div className="w-100">{statementFileLabel}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-2">
                  <div
                    className="mb-1 file-placeholder"
                    {...register("isrPrflImg", {
                      // required: {
                      //   value: true,
                      //   message: "This Field cannot be blank!!",
                      // },
                    })}
                  >
                    <input
                      type="file"
                      accept="image/png, image/jpeg "
                      onChange={selectPhotoFile}
                    />

                    {photoImg ? (
                      <img
                        // className="file-placeholder"
                        src={URL.createObjectURL(photoImg)}
                        alt="img"
                      />
                    ) : (
                      <div>
                        {GetSvgUpload({ required: false })}
                        Upload Photo{" "}
                        {/* <span className="text-danger">&#9733;</span> */}
                      </div>
                    )}
                  </div>

                  {photoImgErrMsg && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {photoImgErrMsg}
                      </span>
                    </div>
                  )}
                  <div
                    className="mb-1 mt-3 file-placeholder"
                    {...register("isrIcImg", {
                      // required: {
                      //   value: true,
                      //   message: REQUIRED_ERR_MSG,
                      // },
                    })}
                  >
                    <input
                      type="file"
                      onChange={selectIcFile}
                      accept="image/png, image/jpeg "
                    />
                    {icImg ? (
                      <img
                        // className="file-placeholder"
                        src={URL.createObjectURL(icImg)}
                        alt="img"
                      />
                    ) : (
                      <div>
                        {GetSvgUpload({ required: false })}
                        Upload IC
                      </div>
                    )}

                    {errors.isrIcImg && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.isrIcImg.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-3 m-auto mt-3">
                  <button
                    type="button"
                    className="btn btn-default border"
                    onClick={clearData}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet  ms-3"
                    onClick={handleSubmit(saveData, onError)}
                  >
                    Request
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
    </>
  );
};

export default IsrRegistration;

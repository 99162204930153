import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { cleanNewLine } from "../../Helper/CommonMethods";

export const initNewsObj = {
  id: 0,
  bulletinTitle: "",
  bulletinDescription: "",
  active: true,
};
const NewsAndBulletinAddEdit = ({
  modalConfig,
  onHide,
  handlePostApi,
  selNews,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(Object.assign({}, selNews));
  }, [selNews]);

  const onSubmit = async (data) => {
    const uri = "SaveAndUpdateBulletin";
    if (!!data?.bulletinDescription) {
      data.bulletinDescription = cleanNewLine(data.bulletinDescription);
    }
    let newsData = await CallApiPost(uri, new Array(data));
    onHide();
    handlePostApi(newsData);
  };

  const handleCLose = () => {
    reset(Object.assign({}, initNewsObj));
    onHide();
  };

  const onErr = (err) => {
    console.log(err);
  };

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={handleCLose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title section-title">{modalConfig?.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              {/* <Col md={6}>
                <label className="form-label theme-label">Date</label>

                <Controller
                  control={control}
                  name="stampdate"
                  //   rules={{ validate: isValidTransferData }}
                  render={({ field: { onChange, value } }) => (
                    <div>
                      <ReactDatePicker
                        placeholderText="Select Date"
                        className="form-control"
                        dateFormat={DATE_FORMAT_CLIENT}
                        selected={value ? new Date(value) : value}
                        showTimeSelect={false}
                        onChange={(d) => {
                          onChange(
                            d !== null ? moment(d).format("YYYY-MM-DD") : ""
                          );
                        }}
                      />
                    </div>
                  )}
                />
              </Col> */}
              <Col md={12}>
                <label className="form-label theme-label">Title</label>
                <input
                  className="form-control"
                  name="bulletinTitle"
                  {...register("bulletinTitle", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: ALPHANUM_SPACE_SPCL_CHAR,
                      message: "field contains only numbers and characters",
                    },
                  })}
                />
                {errors?.bulletinTitle && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.bulletinTitle?.message}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            <Col md={12}>
              <label className="form-label theme-label">Description</label>
              <textarea
                className="form-control"
                name="bulletinDescription"
                {...register("bulletinDescription", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                  validate: (v) => (BLACK_SPCL_CHARS.test(v) ? false : true),
                })}
              />
              {errors?.bulletinDescription && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{" "}
                    {"Check for invalid characters: " + BLACK_SPCL_CHARS_STR}
                  </span>
                </div>
              )}
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="save-btn"
            onClick={handleSubmit(onSubmit, onErr)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewsAndBulletinAddEdit;

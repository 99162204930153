import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";

const DriverCommissionList = (props) => {
  const { columns, data } = props;

  return (
    <>
      <div className="col-md-12 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
        />
      </div>
    </>
  );
};

export default DriverCommissionList;

import logo from "../../../images/linehaul-logo.png";

function Inprogress() {
  return (

    //     <div className="banner banner0">
    //     <h2>Activation in Inprogress</h2>
    //     <p className="slogan">Activation completed soon.</p>
    // </div>

    <body className="backdrop-image-register">
      <div className="container-fluid mb-5">
        <div className="row">
          <main className="col-md-12 p-3">

            <div className="Signupbg Registerform m-auto mt-4 pb-4 col-md-4">
              <div className="text-center w-100 clearfix max-logo">
                <img src={logo} className="img-fluid mx-auto d-block" alt="LinehaulLogo" />
              </div>
              <div className="px-4">
                <div className="Form-title mb-3">Approval in Progress</div>

                <div className="clearfix col-md-6 m-auto mt-3">

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </body>
  )
}

export default Inprogress
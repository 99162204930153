import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  ALPHANUM,
  ALPHANUM_SPACE_SPCL_CHAR,
  COMPANY_REGD_NO_REGEX,
  COUNTRY_CODES,
  CUST_TYPE_BUSINESS,
  EMAIL,
  INVALID_COMPANY_REGD_NO_ERROR_MSG,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import Select from "react-select";
import { useState } from "react";
import { CallApiPost } from "../../Helper/serviceApi";
import { initValCustomer } from "./initValCustomer";
import Spinner from "../../Layout/Spinner";
import GetSvgIcon, {
  GetSvgBank,
  GetSvgBuilding,
  GetSvgMail2,
  GetSvgPerson,
  GetSvgPhone,
  GetSvgUpload,
} from "../../../images/svglist";
import { getFileName, toBase64 } from "../../Helper/CommonMethods";
import MultipleFileUpload from "../../Utilities/MultipleFileUpload";

const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};

const customCss = {
  main: "mb-3",
  header: {
    levelColor: "fc-black mb-0",
  },
  comp: {
    mainDiv: "row mt-3",
    innerDiv: "col-lg-6 mb-2",
    innerLevel: "uploaded-text ms-2",
  },
};
const customData = {
  headerLevel: "Upload General Document",
  defaultName: "Upload General Document",
};

export default function AddCustomerManagement(props) {
  const {
    stateData,
    stateCity,
    cityListData,
    setCityListData,
    cityData,
    onSuccess,
    type,
  } = props;
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());
  const [photoImg, setPhotoImg] = useState("");
  const [failMsg, setFailMsg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [photoImgErrMsg, setPhotoImgErrMsg] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const {
    fields: generalDocList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "generalDocList",
  });
  const [show, setShow] = useState(false);
  const watchRequestedCreditAmt = watch("requestedCreditAmt");

  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });
      setCityListData(cityArrList);
      setValue("city", "");
    } else {
      setCityListData(cityData);
      setValue("city", "");
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });
      if (flt.length === 1) setValue("state", flt[0].map_state);
      clearErrors("state");
    }
    // else {
    //   setValue("state", "");
    // }
  };
  const onSubmit = async (data) => {
    setShow(true);
    if (isNaN(data.requestedCreditAmt) || data.requestedCreditAmt === "") {
      data.requestedCreditAmt = 0;
    } else {
      data.requestedCreditAmt = parseFloat(data.requestedCreditAmt);
    }

    if (
      data.requestedCreditAmt > 0 &&
      (!data.bankStatement.photo || !data.ssmDoc.photo)
    ) {
      setShow(false);
      const msg =
        "Bank statement and SSM document are required to avail credit facility.";
      setSubmitStatusFail(true);
      setFailMsg(msg);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
      return;
    }

    if (data.phone !== "") {
      data.phone = data.personalPhoneCode + data.phone;
    }
    if (data.pickupAttentionPhone !== "") {
      data.pickupAttentionPhone =
        data.pickupPhoneCode + data.pickupAttentionPhone;
    }

    data.companyAddress = "";

    delete data.personalPhoneCode;
    delete data.pickupPhoneCode;

    console.log(data);

    let resp = await CallApiPost("register-customer", data);

    switch (resp.respCode) {
      case 99:
        clearData();
        onSuccess("Server is down", "Error", false);
        setShow(false);
        break;
      case 200:
        reset(initValCustomer);
        setPhotoImg("");
        setShow(false);
        const msg =
          "Customer account successfully created and waiting for approval.";
        onSuccess(msg, "Success");
        break;
      default:
        setShow(false);
        setSubmitStatusFail(true);
        setFailMsg(resp.respMessage);
        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 10000);
        break;
    }
  };
  const onErrors = (data) => {
    console.log(data);
  };
  const clearData = () => {
    reset(initValCustomer);
    setPhotoImg("");
    setValue("personalPhoneCode", "+60");
  };

  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      clearErrors("profilePhoto");
      setPhotoImgErrMsg();
      // console.log(attachment);
    }
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const ssmDocPlaceHolder = watch(
    "ssmDoc.photoFileName",
    "Upload SSM Document"
  );

  const watchIsSstExempted = watch("sstExempt");
  const onIsExemptChange = (e) => {
    if (e.target.value === "false" || e.target.value === false)
      setValue("sstNumber", "");
  };

  return (
    <div
      className="modal fade"
      id="button-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Add Customer Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => clearData()}
            ></button>
          </div>
          <div>
            {submitStatusFail && (
              <div
                id="errorMsg"
                className="alert alert-danger mt-1"
                role="alert"
              >
                {" "}
                {failMsg} <br />
              </div>
            )}
          </div>
          <div className="modal-body modal-scroll overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div>
                      <input
                        type="hidden"
                        name="registrationType"
                        id="registrationType2"
                        value={CUST_TYPE_BUSINESS}
                        {...register("customerType")}
                      />
                      <input
                        type="hidden"
                        {...register("password", { value: "" })}
                        value=""
                      />
                      <input
                        type="hidden"
                        {...register("pwdSalt", { value: "" })}
                        value=""
                      />
                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="file-placeholder thumbnail-pic"
                              {...register("profilePhoto")}
                            >
                              <input
                                type="file"
                                accept="image/png, image/jpeg "
                                onChange={selectPhotoFile}
                                key={fileInputDummyKey}
                              />
                              {photoImg ? (
                                <img
                                  src={URL.createObjectURL(photoImg)}
                                  alt="img"
                                />
                              ) : (
                                <div>
                                  {GetSvgUpload({ required: false })}
                                  Upload Photo{" "}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6 mb-2">
                                <div className="input-group">
                                  <span className="input-group-text inputsty">
                                    {GetSvgPerson({ required: false })}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control border-end-0 pe-0"
                                    placeholder="Name"
                                    aria-label="Name"
                                    aria-describedby="basic-addon1"
                                    name="name"
                                    {...register("name", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: ALPHANUM_SPACE_SPCL_CHAR,
                                        message: PATTERN_ERR_MSG,
                                      },
                                      maxLength: {
                                        value: 100,
                                        message: "Max limit: 100 character",
                                      },
                                    })}
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.name && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.name.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6 mb-2">
                                <div className="input-group">
                                  <span className="input-group-text inputsty">
                                    {GetSvgIcon("IconEmail")}
                                  </span>
                                  <input
                                    type="email"
                                    className="form-control border-end-0 pe-0"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    name="email"
                                    {...register("email", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: EMAIL,
                                        message: PATTERN_ERR_MSG,
                                      },
                                    })}
                                    maxLength="200"
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.email && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.email.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6 mb-2">
                                <div className="input-group">
                                  <span className="input-group-text inputsty">
                                    {GetSvgPhone({ required: false })}
                                  </span>
                                  <select
                                    name="personalPhoneCode"
                                    {...register("personalPhoneCode")}
                                    defaultValue="+60"
                                    className="form-control country-code-customize"
                                  >
                                    {COUNTRY_CODES.map((cc, index) => (
                                      <option key={index} value={cc.value}>
                                        {cc.label}
                                      </option>
                                    ))}
                                  </select>
                                  <input
                                    type="tel"
                                    className="form-control border-end-0 pe-0"
                                    placeholder="Phone"
                                    aria-label="Phone"
                                    aria-describedby="basic-addon1"
                                    maxLength="17"
                                    name="phone"
                                    {...register("phone", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: NUMBER_REGEX,
                                        message: PATTERN_ERR_MSG,
                                      },
                                      maxLength: {
                                        value: 15,
                                        message:
                                          "Field should contain 15 character",
                                      },
                                    })}
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.phone && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.phone.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-6 mb-2">
                                <div className="input-group">
                                  <span className="input-group-text inputsty">
                                    {GetSvgIcon("IconPersonlinesfill")}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control border-end-0 pe-0"
                                    placeholder="Customer IC"
                                    aria-label="Customer IC"
                                    aria-describedby="basic-addon1"
                                    name="ic"
                                    {...register("ic", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: ALPHANUM_SPACE_SPCL_CHAR,
                                        message: PATTERN_ERR_MSG,
                                      },
                                      maxLength: {
                                        value: 50,
                                        message:
                                          "Field should contain 50 characters!!",
                                      },
                                    })}
                                    // maxLength="50"
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.ic && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.ic.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <div className="row">
                          <div className="col-md-4 mb-2">
                            <div className="input-group mt-2">
                              <span className="input-group-text inputsty">
                                {GetSvgBuilding({ required: false })}
                              </span>
                              <input
                                type="text"
                                className="form-control border-end-0 pe-0"
                                placeholder="Company Name"
                                aria-label="Name"
                                aria-describedby="basic-addon1"
                                name="companyName"
                                {...register("companyName", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: ALPHANUM_SPACE_SPCL_CHAR,
                                    message: PATTERN_ERR_MSG,
                                  },
                                  maxLength: {
                                    value: 100,
                                    message:
                                      "Field should contain 100 characters!!",
                                  },
                                })}
                                // maxLength="200"
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.companyName && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.companyName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="input-group mt-2">
                              <span className="input-group-text inputsty">
                                {GetSvgBuilding({ required: false })}
                              </span>
                              <input
                                type="text"
                                className="form-control border-end-0 pe-0"
                                placeholder="Company Regd No."
                                aria-label="Phone"
                                aria-describedby="basic-addon1"
                                name="companyRegNo"
                                {...register("companyRegNo", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  // pattern: {
                                  //   value: COMPANY_REGD_NO_REGEX,
                                  //   message: INVALID_COMPANY_REGD_NO_ERROR_MSG,
                                  // },
                                  maxLength: {
                                    value: 30,
                                    message:
                                      "Field should contain 30 characters!!",
                                  },
                                })}
                                // maxLength="30"
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.companyRegNo && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.companyRegNo.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="input-group mt-2">
                              <span className="input-group-text inputsty">
                                {GetSvgMail2({ required: false })}
                              </span>
                              <Controller
                                name="state"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={stateData}
                                    placeholder="Select State"
                                    value={
                                      value
                                        ? stateData.filter((opt) =>
                                            value.includes(opt.value)
                                          )
                                        : value
                                    }
                                    onChange={(d) =>
                                      onChange(
                                        d ? d.value : d,
                                        filterCity(d ? d.value : d)
                                      )
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control border-end-0 p-0"
                                  />
                                )}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.state && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.state.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="input-group">
                              <span className="input-group-text inputsty">
                                {GetSvgMail2({ required: false })}
                              </span>
                              <Controller
                                name="city"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={cityListData}
                                    placeholder="Select City"
                                    value={
                                      value
                                        ? cityListData.filter((opt) =>
                                            value.includes(opt.value)
                                          )
                                        : value
                                    }
                                    onChange={(d) =>
                                      onChange(
                                        d ? d.value : d,
                                        filterState(d ? d.value : d)
                                      )
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control border-end-0 p-0"
                                  />
                                )}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.city && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.city.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="input-group">
                              <span className="input-group-text inputsty">
                                {GetSvgMail2({ required: false })}
                              </span>
                              <input
                                type="text"
                                className="form-control border-end-0 pe-0"
                                placeholder="Post Code"
                                aria-label="Post Code"
                                aria-describedby="basic-addon1"
                                name="postcode"
                                {...register("postcode", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: ALPHANUM,
                                    message: PATTERN_ERR_MSG,
                                  },
                                  maxLength: {
                                    value: 20,
                                    message:
                                      "Field should contain 20 characters!!",
                                  },
                                })}
                                // maxLength="50"
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.postcode && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.postcode.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-md-4 mb-2">
                            <div className="input-group">
                              <span className="input-group-text inputsty">
                                {GetSvgBuilding({ required: false })}
                              </span>
                              <input
                                type="text"
                                className="form-control border-end-0 pe-0"
                                placeholder="Address"
                                aria-label="Address"
                                maxLength="500"
                                name="address"
                                {...register("address", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: ALPHANUM_SPACE_SPCL_CHAR,
                                    message: PATTERN_ERR_MSG,
                                  },
                                  maxLength: {
                                    value: 200,
                                    message:
                                      "Field should contain 200 characters!!",
                                  },
                                  onChange: (e) => {
                                    setValue("pickupAddress", e.target.value);
                                    clearErrors("pickupAddress");
                                  },
                                })}
                              />
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            </div>
                            {errors.address && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; Missing or Invalid Data!
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {/** Business Details */}

                      <div className="mb-3">
                        <div className="row"></div>
                      </div>

                      {/** Bank Details */}
                      <div>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <div className="input-group">
                                <span className="input-group-text inputsty">
                                  {GetSvgIcon("IconCoin")}
                                </span>
                                <input
                                  type="text"
                                  className="form-control pe-0"
                                  placeholder="Requested Credit Amt."
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  name="requestedCreditAmt"
                                  {...register("requestedCreditAmt", {
                                    pattern: {
                                      value: NUMBER_TWO_DECIMAL,
                                      message: PATTERN_ERR_MSG,
                                    },
                                    maxLength: {
                                      value: 9,
                                      message:
                                        "Field should contain 9 characters!!",
                                    },
                                  })}
                                />
                                {/* <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span> */}
                              </div>
                              {errors.requestedCreditAmt && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.requestedCreditAmt.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text inputsty">
                                  {GetSvgBank({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control border-end-0 pe-0"
                                  placeholder="Bank Name"
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  name="bankName"
                                  {...register("bankName", {
                                    required: {
                                      value:
                                        watchRequestedCreditAmt !== "0" &&
                                        watchRequestedCreditAmt,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    pattern: {
                                      value: ALPHANUM_SPACE_SPCL_CHAR,
                                      message: PATTERN_ERR_MSG,
                                    },
                                    maxLength: {
                                      value: 100,
                                      message:
                                        "Field should contain 100 characters!!",
                                    },
                                  })}
                                  // maxLength="200"
                                />
                                {watchRequestedCreditAmt !== "0" &&
                                  watchRequestedCreditAmt && (
                                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                      *
                                    </span>
                                  )}
                              </div>
                              {errors.bankName && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.bankName.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text inputsty">
                                  {GetSvgPerson({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control border-end-0 pe-0"
                                  placeholder="Beneficiary Name"
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  name="beneficiaryName"
                                  {...register("beneficiaryName", {
                                    required: {
                                      value:
                                        watchRequestedCreditAmt !== "0" &&
                                        watchRequestedCreditAmt,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    pattern: {
                                      value: ALPHANUM_SPACE_SPCL_CHAR,
                                      message: "Wrong Input !!!",
                                    },
                                    maxLength: {
                                      value: 100,
                                      message:
                                        "Field should contain 100 characters!!",
                                    },
                                  })}
                                  // maxLength="200"
                                />
                                {watchRequestedCreditAmt !== "0" &&
                                  watchRequestedCreditAmt && (
                                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                      *
                                    </span>
                                  )}
                              </div>
                              {errors.beneficiaryName && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.beneficiaryName.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text inputsty">
                                  {GetSvgPerson({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control border-end-0 pe-0"
                                  placeholder="Account Number"
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  name="bankAccount"
                                  {...register("bankAccount", {
                                    required: {
                                      value:
                                        watchRequestedCreditAmt !== "0" &&
                                        watchRequestedCreditAmt,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    pattern: {
                                      value: ALPHANUM,
                                      message: PATTERN_ERR_MSG,
                                    },
                                  })}
                                  maxLength="200"
                                />
                                {watchRequestedCreditAmt !== "0" &&
                                  watchRequestedCreditAmt && (
                                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                      *
                                    </span>
                                  )}
                              </div>
                              {errors.bankAccount && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.bankAccount.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("bankStatement", {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "", //,
                                  // required: {
                                  //   value: true,
                                  //   message: REQUIRED_ERR_MSG,
                                  // },
                                })}
                              >
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    selectFile("bankStatement", e)
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {bankStatementPlaceHolder
                                    ? bankStatementPlaceHolder
                                    : "Upload Bank Statement"}
                                </div>
                              </div>
                              {errors.bankStatement && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {REQUIRED_ERR_MSG}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("ssmDoc", {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "", //,
                                  // required: {
                                  //   value: true,
                                  //   message: REQUIRED_ERR_MSG,
                                  // },
                                })}
                              >
                                <input
                                  type="file"
                                  onChange={(e) => selectFile("ssmDoc", e)}
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {ssmDocPlaceHolder
                                    ? ssmDocPlaceHolder
                                    : "Upload SSM Document"}
                                </div>
                              </div>
                              {errors.bankStatement && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {REQUIRED_ERR_MSG}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* SST Exemption */}
                      <div>
                        <h6 className="mb-0">
                          Do you want to avail for SST Exemption?{" "}
                        </h6>
                        <div className="clearfix"></div>
                        <div className="accordion-body px-0 ">
                          <div className="row">
                            <>
                              <div className="col-md-6 mb-2 ">
                                <div className="input-group ">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sstExempt"
                                      id="sstRadio1"
                                      value={true}
                                      checked={
                                        !!watchIsSstExempted
                                          ? watchIsSstExempted === true ||
                                            watchIsSstExempted === "true"
                                          : false
                                      }
                                      {...register("sstExempt")}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sstRadio1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sstExempt"
                                      id="sstRadio2"
                                      value={false}
                                      checked={
                                        !!watchIsSstExempted
                                          ? watchIsSstExempted === false ||
                                            watchIsSstExempted === "false"
                                          : true
                                      }
                                      {...register("sstExempt", {
                                        onChange: onIsExemptChange,
                                      })}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sstRadio2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                {errors.sstExempt && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.sstExempt.message}
                                    </span>
                                  </div>
                                )}
                              </div>

                              {(watchIsSstExempted === true ||
                                watchIsSstExempted === "true") && (
                                <div className="col-md-6 mb-2">
                                  <div className="input-group">
                                    <span
                                      className="input-group-text inputsty"
                                      id="basic-addon1"
                                    >
                                      {GetSvgPerson({ required: false })}
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control  border-end-0 pe-1"
                                      placeholder="SST Number"
                                      aria-label=""
                                      aria-describedby="basic-addon1"
                                      name="sstNumber"
                                      {...register("sstNumber", {
                                        required: {
                                          value: true,
                                          message: REQUIRED_ERR_MSG,
                                        },
                                        pattern: {
                                          value: ALPHANUM,
                                          message: PATTERN_ERR_MSG,
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Field should contain 30 characters!!",
                                        },
                                      })}
                                      // maxLength="200"
                                    />

                                    <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                      *
                                    </span>
                                  </div>
                                  {errors.sstNumber && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; {errors.sstNumber.message}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                      </div>

                      <div>
                        <MultipleFileUpload
                          customData={customData}
                          customCss={customCss}
                          register={register}
                          clearErrors={clearErrors}
                          setValue={setValue}
                          watch={watch}
                          control={control}
                          remove={remove}
                          append={append}
                          generalDocList={generalDocList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              onClick={clearData}
            >
              Clear
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(onSubmit, onErrors)}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <Spinner show={show} />
    </div>
  );
}

import React from "react";
import Header from "../Layout/Header";
import { useState } from "react";
import {CallApiPost } from "../Helper/serviceApi";
import { useEffect } from "react";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
  SERVER_ERR_MSG,
  customDataTableStyles,
} from "../Helper/Constants";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DataTable from "react-data-table-component-with-filter";
import Popup from "../Layout/Popup";
import { Table } from "react-bootstrap";
import GetSvgIcon from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";
import ModalLayout from "../Layout/ModalLayout";
import { Tooltip } from "react-tooltip";

export default function InspectionLogMain() {
  const [vehicle, setVehicle] = useState([]);
  const dropdownModel = { argVehicle: 1 };
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [inspectionRow, setInspectionRow] = useState([]);
  const [mainItem, setMainItem] = useState([]);
  const [modalConfig, setModalConfig] = useState(null);
  const sucs = () => {};
  const download = (fileName) => {
    if (fileName !== "") {
      DownloadFile(fileName, sucs, sucs);
    }
  };
  const processVhclInsDtls = (vhcDtls) => {
    const groupedData = vhcDtls.reduce((result, item) => {
      const mainItem = item.mainItem;

      if (!result[mainItem]) {
        result[mainItem] = [];
      }

      result[mainItem].push(item);

      return result;
    }, {});
    setMainItem(groupedData);
    openModal(groupedData);
  };
  const openModal = (data) => {
    setModalConfig({
      size: "md",
      show: true,
      title: "Details",
    });
  };
  const closeModal = () => {
    setMainItem([]);
    setModalConfig({
      size: "md",
      fullscreen: false,
      show: false,
      title: "",
    });
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.inspDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vchName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Inspected By",
      selector: (row) => row.inspBy,
      sortable: true,
      filterable: true,
    },

    {
      name: "Remark",
      selector: (row) => row.remarks,
      filterable: true,
    },
    // {
    //   name: "Attachment",
    //   selector: (row) => (
    //     <>
    //       <div className="Upload-Photo w-100 my-2 position-relative">
    //         <input disabled type="file" />
    //         <div className="uploaded-text ms-2">
    //           {watch(`row.attachment`) === null ||
    //           watch(`row.attachment`) === ""
    //             ? `Upload Image`
    //             : getFileName(watch(`row.attachment`))}
    //         </div>
    //         <button
    //           type="button"
    //           className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file top-0 end-0"
    //           onClick={() => download(row.attachment)}
    //         >
    //           {GetSvgIcon("IconFiledownload")}
    //         </button>
    //       </div>

    //     </>
    //   ),
    // },
    {
      name: "Details",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"invDTLs-" + row.cust_id}
            className="me-3"
            onClick={() => {
              processVhclInsDtls(JSON.parse(row.vhclInsDtls));
            }}
          >
            {GetSvgIcon("EyeFill")}
          </div>
          <Tooltip id={"invDTLs-" + row} content="View Details" place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    fetchVehicleList();
    setValue("fromDate", moment().format(DATE_FORMAT_SERVER));
    setValue("toDate", moment().endOf("month").format(DATE_FORMAT_SERVER));
  }, []);

  const fromdateWatch = watch("fromDate");
  const todateWatch = watch("toDate");

  const fetchVehicleList = async () => {
    let data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      setVehicle(data.respData.vhclNoArr);
    } else {
      openPopup(false, "ERROR", SERVER_ERR_MSG);
    }
  };
  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };
  const fetchInspectionList = async (data) => {
    const uri = "list-inspection";
    let resp = await CallApiPost(uri, data);
    setInspectionRow(resp.respData);
  };
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Inspection Log</div>
              </div>
              <div className="float-end d-flex">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#success-modal"
                  id="modalPopup"
                  className="d-none"
                  ref={modalRef}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row">
                <div className="col-md-2">
                  <label htmlFor="" className="form-label theme-label">
                    Vehicle No. <span className="text-danger">&#9733;</span>
                  </label>
                  <Controller
                    name="vehicleId"
                    control={control}
                    rules={{
                      required: { value: true, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={vehicle}
                        placeholder="Select Vehicle"
                        value={
                          value
                            ? vehicle.find((opt) => opt.value === value)
                            : value
                        }
                        onChange={(d) => onChange(d ? d.value : d)}
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                      />
                    )}
                  />
                  {errors.vehicleId && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.vehicleId.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-2">
                  <label htmlFor="" className="form-label theme-label">
                    Log From
                  </label>
                  <Controller
                    control={control}
                    name="fromDate"
                    rules={{
                      required: {
                        value: todateWatch ? true : false,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className="form-control"
                        selected={value ? new Date(value) : value}
                        onChange={(d) =>
                          onChange(
                            d !== null
                              ? moment(d).format(DATE_FORMAT_SERVER)
                              : ""
                          )
                        }
                        dateFormat={DATE_FORMAT_CLIENT}
                        placeholderText="Select From"
                        maxDate={new Date(todateWatch)}
                      />
                    )}
                  />
                  {errors?.dateFrom && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.dateFrom?.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-2">
                  <label htmlFor="" className="form-label theme-label">
                    Log To
                  </label>
                  <Controller
                    control={control}
                    name="toDate"
                    rules={{
                      required: {
                        value: fromdateWatch ? true : false,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        className="form-control"
                        selected={value ? new Date(value) : value}
                        onChange={(d) =>
                          onChange(
                            d !== null
                              ? moment(d).format(DATE_FORMAT_SERVER)
                              : ""
                          )
                        }
                        dateFormat={DATE_FORMAT_CLIENT}
                        placeholderText="Select To"
                        minDate={new Date(fromdateWatch)}
                      />
                    )}
                  />
                  {errors?.dateTo && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.dateTo?.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-2">
                  <div className="mt-1">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                    ></label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet"
                    onClick={handleSubmit(fetchInspectionList)}
                  >
                    {" "}
                    Search{" "}
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
                <DataTable
                  columns={columns}
                  data={inspectionRow}
                  customStyles={customDataTableStyles}
                  pagination
                  striped
                  highlightOnHover
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                />
              </div>
            </div>
          </main>
        </div>
        <Popup isSuccess={isSuccess} title={title} description={description} />

        {!!modalConfig && (
          <ModalLayout moadlConfig={modalConfig} handleMoalClose={closeModal}>
            <div>
              <Table hover>
                {" "}
                {Object.entries(mainItem).map(([k, val]) => (
                  <>
                    <thead>
                      <tr>
                        <th>{k}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Item</th>
                        <th>Status</th>
                      </tr>
                      {val.map((e) => (
                        <tr>
                          <td>{e.subItem}</td>
                          <td>{e.subItemSts}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ))}
              </Table>
            </div>
          </ModalLayout>
        )}
      </div>
    </>
  );
}

import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalPromotion = ({ children, moadlConfig, handleMoalClose,handelTypeChange,register,getValues }) => {
    return (
        <>
            <Modal
                size={moadlConfig.size}
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={moadlConfig.show}
                onHide={handleMoalClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <div className="form-check form-check-inline d-flex me-5">
                        <div className="align-self-center">
                        {/* <input
                            className="form-check-input"
                            type="radio"
                            name="promoType"
                            id="inlineRadio1"
                            value="Promotion"
                            {...register("promoType")}
                            onChange={()=>handelTypeChange("Promotion")}
                            disabled={getValues("id") > 0 ? true : false}
                        /> */}
                        </div>
                        <label className="align-self-center fs-5 theme-blue-txt" htmlFor="inlineRadio1">
                            Promotion Configuration
                        </label>
                    </div>
                    {/* <div className="form-check form-check-inline d-flex">
                        <div className="align-self-center">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="promoType"
                                id="inlineRadio2"
                                value="Voucher"
                                {...register("promoType")}
                                onChange={()=>handelTypeChange("Voucher")}
                                disabled={getValues("id") > 0 ? true : false}
                            />
                        </div>
                        <label className="align-self-center fs-5 theme-blue-txt" htmlFor="inlineRadio2">
                            Voucher
                        </label>
                    </div> */}
                </Modal.Header>
                <Modal.Body className="p-0">{children}</Modal.Body>
            </Modal>
        </>
    );
};

export default ModalPromotion;

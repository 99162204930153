import React from "react";
import Header from "../Layout/Header";
import Popup from "../Layout/Popup";
import { CallApiPost } from "../Helper/serviceApi";
import { useState } from "react";
import { useRef } from "react";
import { SERVER_ERR_MSG } from "../Helper/Constants";
import { useEffect } from "react";
import VehicleFuelLogList from "./VehicleFuelLogList";
import VehicleFuelLogAddEdit from "./VehicleFuelLogAddEdit";
import { initValFuelLog } from "./initValFuelLog";
import GetSvgIcon from "../../images/svglist";

export default function VehicleFuelLogMain() {
  const [vehicle, setVehicle] = useState([]);
  const [driver, setDriver] = useState([]);
  const [fuelLog, setFuelLog] = useState(initValFuelLog);
  const dropdownModel = {
    argVehicle: 1,
    argDriver: 1,
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  

  useEffect(() => {
    fetchVehicleAndDriverList();
  }, []);

  const fetchVehicleAndDriverList = async () => {
    let data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      setVehicle(data.respData.vhclNoArr);
      setDriver(data.respData.driverArr);
    } else {
      openPopup(false, "ERROR", SERVER_ERR_MSG);
    }
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
  };
  const onFailure = (msg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(msg);
    modalRef.current.click();
  };
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Vehicle Fuel Log</div>
              </div>
              <div className="float-end d-flex">
               
                <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    data-bs-toggle="modal" 
                    data-bs-target="#button-modal"
                  >
                    {GetSvgIcon("PlusCircle")} ADD
                  </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#success-modal"
                  id="modalPopup"
                  className="d-none"
                  ref={modalRef}
                />
              </div>
            </div>
            <VehicleFuelLogAddEdit
              driverList={driver}
              vehicleList={vehicle}
              setFuelLog={setFuelLog}
              fuelLog={fuelLog}
              onSuccess={onSuccess}
              onFailure={onFailure}
            />
            <VehicleFuelLogList vehicleList={vehicle} setFuelLog={setFuelLog}/>
          </main>
        </div>
        <Popup isSuccess={isSuccess} title={title} description={description} />
      </div>
    </>
  );
}

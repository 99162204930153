import React from "react";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { CallApiPost } from "../../Helper/serviceApi";
import { useState } from "react";
import Header from "../../Layout/Header";
import CustomInputWithChip from "../../Utilities/CustomInputWithChip";
import Spinner from "../../Layout/Spinner";
import { useRef } from "react";
import Popup from "../../Layout/Popup";
import {
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import "./TrackWaybill.css";
import moment from "moment";
import GetSvgIcon from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_STAFF_USER_TYPE,
} from "../../../ConfigConstants/ConfigConstants";
import { decodeUrlData } from "../../Helper/CommonMethods";

export default function TrackWaybill() {
  let location = useLocation();
  let data = location.state;
  const [userType, setUserType] = useState();
  const [shipmentData, setShipmentData] = useState([]);
  const [shipmentDeliveryList, setShipmentDeliveryList] = useState([]);
  const [wayBillNos, setWayBillNos] = useState([]); //WaybillNo - [{label: , value: }]
  const [waybillIndex, setWaybillIndex] = useState(0);
  const modalRef = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // const { Buffer } = require("buffer");

  useEffect(() => {
    if (data && data.length > 0) {
      getTrackByWayBills(data);
      setWayBillNos(data);
    }

    if (!!searchParams.get("data")) {
      let decodedData = decodeUrlData(searchParams.get("data"));
      getTrackByWayBills(decodedData);
      setWayBillNos(decodedData);
    }
    setUserType(getUserInfo("userType"));
  }, []);

  // const decodeUrlData = (encodedData) => {
  //   const decodedBuffer = Buffer.from(encodedData, "base64");
  //   const decodedString = decodedBuffer.toString("utf-8");
  //   return JSON.parse(decodedString);
  // };

  const getTrackByWayBills = async (argWayBill) => {
    setShowSpinner(true);
    let wayBillArr = [];
    argWayBill.forEach((element) => {
      wayBillArr.push(element.value);
    });
    if (wayBillArr.length > 0) {
      let resp = await CallApiPost("trackShipment", wayBillArr);

      if (resp.respCode === 200) {
        if (resp.respData.length > 0) {
          setShipmentData(resp.respData);
          setShipmentDeliveryList(resp.respData[0].deliveryList);
          setWaybillIndex(0);
        } else {
          setShipmentData([]);
          setShipmentDeliveryList([]);
          setWaybillIndex(0);
        }
      } else {
        console.log(resp.respCode);
        openPopup(false, "ERROR", SERVER_ERR_MSG);
      }
    }
    setShowSpinner(false);
  };

  const openPopup = (bool, title, desc) => {
    setIsSuccess(bool);
    setTitle(title);
    setDescription(desc);
    modalRef.current.click();
  };

  const callback = () => {};

  const handleCardClick = (data, index) => {
    setShipmentDeliveryList(data.deliveryList);
    setWaybillIndex(index);
  };

  const renderLastMile = (data, argLabel, doFirstMile) => (
    <li
      className={
        // (!!data?.ateDt && !!data?.ateT) ? "tl-item reached" : "tl-item planned"
        data?.isReached ? "tl-item reached" : "tl-item planned"
      }
    >
      <div className="timestamp">
        <div className="text-nowrap">
          <span className="fw-bold theme-blue-txt text-uppercase">
            {argLabel}
          </span>
          <br />
          <span className="fw-bold theme-blue-txt text-uppercase">
            Arrival/Departure
          </span>
        </div>
        {(LINEHAUL_STAFF_USER_TYPE === userType ||
          LINEHAUL_ADMIN_USER_TYPE === userType) && (
          <div className="d-flex w-100">
            {!!data?.tripDt ? (
              <span
                className="fw-bold text-secondary me-2 text-nowrap"
                title="ETS"
              >
                {data?.tripT} <br /> {data?.tripDt}
              </span>
            ) : (
              <span
                className="fw-bold text-secondary me-2 text-nowrap"
                title="UNPLANNED"
              >
                UNPLANNED{" "}
              </span>
            )}
          </div>
        )}
        <div className="d-flex w-100 mt-2">
          {!!data?.atsDt ? (
            <span className="fw-bold text-primary me-2 text-nowrap" title="ATS">
              {data?.atsT} <br /> {data?.atsDt}
            </span>
          ) : (
            <span className="fw-bold text-primary me-2 text-nowrap" title="ATS">
              &#8212;{" "}
            </span>
          )}
          {!!data?.ateDt ? (
            <span className="fw-bold text-primary ms-4 text-nowrap" title="ATE">
              {data?.ateT} <br /> {data?.ateDt}
            </span>
          ) : (
            <span className="fw-bold text-primary ms-4 text-nowrap" title="ATE">
              &#8212;{" "}
            </span>
          )}
        </div>
      </div>
      <div className="float-start">
        <div className="font-12 text-secondary  w-100">
          {Object.values(data?.address)
            .filter((v) => !!v)
            .join(", ")}
        </div>
      </div>
      {doFirstMile && (
        <>
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {
                  <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                    {GetSvgIcon("BiCursor")} {data?.routeCode}
                  </div>
                }
              </>
            )}
          </div>
          <br />
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {
                  <>
                    {!!data?.vhclNo && (
                      <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                        {GetSvgIcon("IconTruck")} {data?.vhclNo}
                      </div>
                    )}
                    {/* {
                      !!dvl.dr1Name &&
                      <div className="mx-3 theme-blue-txt align-self-center text-nowrap">
                        {GetSvgIcon("IconSystemconfig")}{" "}{dvl.dr1Name}
                      </div>
                    }
                    {
                      !!dvl.dr2Name &&
                      <div className="mx-3 theme-blue-txt align-self-center text-secondary text-nowrap">
                        {GetSvgIcon("IconSystemconfig")}{" "}{dvl.dr2Name}
                      </div>
                    } */}
                  </>
                }
              </>
            )}
          </div>
          <br />
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {
                  <>
                    {!!data?.dr1Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {data?.dr1Name}
                      </div>
                    )}
                    {!!data?.dr2Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-secondary text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {data?.dr2Name}
                      </div>
                    )}
                  </>
                }
              </>
            )}
          </div>
        </>
      )}
    </li>
  );

  const renderTimeline = (stop, outIndex, doLastMile, dlvrDtls) => {
    const stopDeliveryAction = shipmentDeliveryList.filter(
      (item) => item.sbuCode === stop
    );
    const estDept =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].etdT
        : stopDeliveryAction[0].etdT;
    const estDeptDt =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].etdD
        : stopDeliveryAction[0].etdD;
    const actualDept =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].atdT
        : stopDeliveryAction[0].atdT;
    const actualDeptDt =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].atdD
        : stopDeliveryAction[0].atdD;
    const estArr = stopDeliveryAction[0].etaT;
    const estArrDt = stopDeliveryAction[0].etaD;
    const actualArr = stopDeliveryAction[0].ataT;
    const actualArrDt = stopDeliveryAction[0].ataD;
    const dvl =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].drVhclDtls
        : stopDeliveryAction[0].drVhclDtls;
    const isLastStop = shipmentData[waybillIndex].stops.length - 1 === outIndex;
    const routeCode =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1]?.routeCode
        : stopDeliveryAction[0]?.routeCode;
    const isReached =
      stopDeliveryAction.length > 1
        ? stopDeliveryAction[1].reached
        : stopDeliveryAction[0].reached;
    return (
      <>
        <li
          key={outIndex}
          className={
            // (!!actualArr || !!actualDept) ? "tl-item reached" : "tl-item planned"
            isReached ? "tl-item reached" : "tl-item planned"
          }
        >
          <div className="timestamp">
            <div className="text-nowrap">
              <span className="fw-bold theme-blue-txt text-uppercase">
                Arrival/Departure
              </span>
            </div>
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <div className="d-flex w-100">
                {!!estArr ? (
                  <span
                    className="fw-bold text-secondary me-2 text-nowrap"
                    title="ETA"
                  >
                    {estArr} <br /> {estArrDt}
                  </span>
                ) : (
                  <span
                    className="fw-bold text-secondary me-2 text-nowrap"
                    title="ETA"
                  >
                    &#8212;{" "}
                  </span>
                )}
                {!!estDept ? (
                  <span
                    className="fw-bold text-secondary ms-4 text-nowrap"
                    title="ETD"
                  >
                    {estDept} <br /> {estDeptDt}
                  </span>
                ) : (
                  <span
                    className="fw-bold text-secondary ms-4 text-nowrap"
                    title="ETD"
                  >
                    &#8212;{" "}
                  </span>
                )}
              </div>
            )}
            <div className="d-flex w-100 mt-2">
              {!!actualArr ? (
                <span
                  className="fw-bold text-primary me-2 text-nowrap"
                  title="ATA"
                >
                  {actualArr} <br /> {actualArrDt}
                </span>
              ) : (
                <span
                  className="fw-bold text-primary me-2 text-nowrap"
                  title="ATA"
                >
                  &#8212;{" "}
                </span>
              )}
              {!!actualDept ? (
                <span
                  className="fw-bold text-primary ms-4 text-nowrap"
                  title="ATD"
                >
                  {actualDept} <br /> {actualDeptDt}
                </span>
              ) : (
                <span
                  className="fw-bold text-primary ms-4 text-nowrap"
                  title="ATD"
                >
                  &#8212;{" "}
                </span>
              )}
            </div>
          </div>
          <div className="float-start">
            <div className="item-title w-100">{stop}</div>
          </div>
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {!isLastStop ? (
                  <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                    {GetSvgIcon("BiCursor")} {routeCode}
                  </div>
                ) : (
                  <>
                    {doLastMile && !!dlvrDtls?.routeCode && (
                      <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                        {GetSvgIcon("BiCursor")} {dlvrDtls?.routeCode}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <br />
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {!isLastStop ? (
                  <>
                    {!!dvl && !!dvl.vhclNo && (
                      <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                        {GetSvgIcon("IconTruck")} {dvl.vhclNo}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {doLastMile && !!dlvrDtls?.vhclNo && (
                      <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
                        {GetSvgIcon("IconTruck")} {dlvrDtls.vhclNo}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <br />
          <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            {(LINEHAUL_STAFF_USER_TYPE === userType ||
              LINEHAUL_ADMIN_USER_TYPE === userType) && (
              <>
                {!isLastStop ? (
                  <>
                    {!!dvl && !!dvl.dr1Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {dvl.dr1Name}
                      </div>
                    )}
                    {!!dvl.dr2Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-secondary text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {dvl.dr2Name}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {doLastMile && !!dlvrDtls?.dr1Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {dlvrDtls?.dr1Name}
                      </div>
                    )}
                    {doLastMile && !!dlvrDtls?.dr2Name && (
                      <div className="mx-3 theme-blue-txt align-self-center text-secondary text-nowrap">
                        {GetSvgIcon("IconSystemconfig")} {dlvrDtls?.dr2Name}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </li>
      </>
    );
  };

  const openOrderDtls = (shipmentId) => {
    const url = `/orderDetails?data=${shipmentId}`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
            <div className="w-50 m-auto py-3 ">
              <div className="w-100 my-3">
                <div className="input-group">
                  <div className="w-75">
                    <CustomInputWithChip
                      value={wayBillNos}
                      setValue={setWayBillNos}
                      className="rounded-1s p-0"
                    />
                  </div>
                  <button
                    className="btn btn-outline-secondary theme-violet rounded-1s"
                    type="button"
                    id="button-addon2"
                    title="Track"
                    onClick={() => getTrackByWayBills(wayBillNos)}
                  >
                    Track Shipment {GetSvgIcon("ArrowRight")}
                  </button>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex">
                    <div className="col-md-5 colobg  overflow-auto me-3">
                      <div className="row mx-0">
                        {shipmentData.length > 0 ? (
                          shipmentData.map((item, index) => (
                            <div
                              className={
                                shipmentData.length > 1
                                  ? "col-xxl-6"
                                  : "col-xxl-12"
                              }
                            >
                              <div
                                key={index}
                                className={
                                  index === waybillIndex
                                    ? "col-md-12 each-wb-details mt-2 active"
                                    : "col-md-12 each-wb-details mt-2"
                                }
                                onClick={() => handleCardClick(item, index)}
                              >
                                <div className="border border-1 rounded bg-white p-2">
                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Waybill Number:
                                    </div>
                                    <div className="col theme-blue-txt text-end">
                                      {item.wayBillNo}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Order Number:
                                    </div>
                                    <div
                                      className="col modal-links fw-bold text-end"
                                      onClick={() =>
                                        openOrderDtls(item?.orderId)
                                      }
                                    >
                                      {item.orderNo}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Recipient Address:
                                    </div>
                                    <div className="col theme-blue-txt text-end">
                                      {item.receipientAddress}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Last Location:
                                    </div>
                                    {item.stops.includes(
                                      item.lastArrivalLocation
                                    ) ? (
                                      <div className="col theme-blue-txt text-end">
                                        {item.lastArrivalLocation}
                                      </div>
                                    ) : (
                                      <div className="col theme-red-txt text-end">
                                        {item.lastArrivalLocation}
                                      </div>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Last Event:
                                    </div>
                                    <div className="col text-warning text-end">
                                      {item.lastEvent}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Estimated Delivery:
                                    </div>
                                    <div className="col theme-blue-txt text-end">
                                      {moment(
                                        item.estDelivery,
                                        DATE_FORMAT_SERVER
                                      ).format(DATE_FORMAT_MOMENT)}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Shipment Date:
                                    </div>
                                    <div className="col theme-blue-txt text-end">
                                      {moment(
                                        item.shipmentDate,
                                        DATE_FORMAT_SERVER
                                      ).format(DATE_FORMAT_MOMENT)}
                                    </div>
                                  </div>
                                  {/* <div className="clearfix my-2"></div> */}

                                  <div className="row">
                                    <div className="col font-12 text-start">
                                      Status:
                                    </div>
                                    <div className="col theme-blue-txt text-end">
                                      {item.status}
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="border border-1 rounded bg-white shadow text-danger text-center py-5 mt-2">
                            No Waybill Data
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="end-0 h-100 overflow-auto">
                        <div className="ps-3 pb-5 mx-3 track-shipment-bg">
                          <div className="history-tl-container">
                            {shipmentData.length > 0 ? (
                              <ul className="tl">
                                {shipmentData[waybillIndex].doFirstMile ===
                                  true &&
                                  renderLastMile(
                                    shipmentData[waybillIndex].pickUpDtls,
                                    "Pick Up",
                                    shipmentData[waybillIndex].doFirstMile
                                  )}
                                {shipmentData[waybillIndex].stops.map(
                                  (stop, index) =>
                                    renderTimeline(
                                      stop,
                                      index,
                                      shipmentData[waybillIndex].doLastMile,
                                      shipmentData[waybillIndex].deliveryDtls
                                    )
                                )}
                                {shipmentData[waybillIndex].doLastMile ===
                                  true &&
                                  renderLastMile(
                                    shipmentData[waybillIndex].deliveryDtls,
                                    "Delivery"
                                  )}
                              </ul>
                            ) : (
                              <div className="text-danger text-center py-5">
                                No Timeline Data
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={showSpinner} />
    </div>
  );
}

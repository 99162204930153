import React, { useEffect } from "react";
import { Col, Modal } from "react-bootstrap";
import ModalLayout from "../../Layout/ModalLayout";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";

const ViewStaffList = ({ show, handleCLose, argData }) => {
  const columns = [
    {
      name: "Name",
      selector: (row) => row?.user_name,
    },
    {
      name: "Email",
      selector: (row) => row?.user_email,
    },
    {
      name: "Phone",
      selector: (row) => row?.user_phone,
    },
  ];
  return (
    <>
      <div>
        <Modal size="lg" show={show} onHide={handleCLose}>
          <Modal.Header className="modal-title section-title" closeButton>
            <Modal.Title>View Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body className="grid-example">
            <div className="col-md-12 customized-data-table position-relative mt-0">
              <DataTable
                columns={columns}
                data={argData}
                pagination
                striped
                highlightOnHover
                customStyles={customDataTableStyles}
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
              />
            </div>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};

export default ViewStaffList;

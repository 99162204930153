import React, { useEffect, useRef, useState } from "react";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { toBase64 } from "../Helper/CommonMethods";
import { DELIVERYSTATUSCODE } from "../Helper/Constants";
import { DownloadFile } from "../Helper/FileStoreService";
import { CallApiPost } from "../Helper/serviceApi";

const CustomerOrderAttachment = ({
  shipmentDtls,
  setSpinner,
  reloadShipmentData,
  message,
  setMessage,
  resetMessage,
  showAlert,
}) => {
  const [pickupFileName, setPickupFileName] = useState([]);
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [attachmentDesc, setAttachmentDesc] = useState([]);
  const [defaultFileName, setDefaultFileName] = useState("Upload Doc");
  const fileRef = useRef();
  const decpRef = useRef();

  useEffect(() => {
    if (!!shipmentDtls) {
      setOrderAttachments(shipmentDtls.attachments);
    }
  }, [shipmentDtls]);

  const handelFileSelect = (event) => {
    const file = event.target.files;
    if (file.length === 0) {
    } else {
      let fileName = file[0].name;
      setDefaultFileName(fileName);
    }
  };

  const handelOrderAttachment = async () => {
    const file = document.getElementById("attachmentFile").files;
    const desp = document.getElementById("decp").value;
    let fileNameArry = [...pickupFileName];
    let tempDescArr = [...attachmentDesc];
    if (file.length === 0) {
      showAlert({
        isSuccess: false,
        title: "",
        description:
          "Please upload document and click ADD to save the attachment",
      });
    } else {
      let fileName = file[0].name;
      fileNameArry.push(fileName);
      setPickupFileName([...fileNameArry]);
      tempDescArr.push(desp);
      setAttachmentDesc([...tempDescArr]);
      let photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        docBase64: photo,
        fileName: fileName,
        description: desp,
      };
      let attachment = orderAttachments;
      attachment.push(newAttachment);
      setOrderAttachments(attachment);
      document.getElementById("attachmentFile").value = "";
      document.getElementById("decp").value = "";
      setDefaultFileName("Upload Doc");
      let freshAttahcment = [];

      freshAttahcment.push(newAttachment);
      shipmentDtls.attachments = freshAttahcment;
      shipmentDtls.waybillList = [];

      setSpinner(true);
      let resp = await CallApiPost("addOrderAttachment", shipmentDtls);
      if (resp.respCode === 200) {
        setSpinner(false);
        setMessage("Document Uploaded Successfully");
        reloadShipmentData();
        resetMessage();
      } else {
        setSpinner(false);
        setMessage("!Some Error Occured");
        reloadShipmentData();
        resetMessage();
      }
      reloadShipmentData();
    }
  };

  const onSuccess = (title, body) => {
    console.log("title", title, "body", body);
  };

  const onError = (errMsg) => {
    console.log("errMsg", errMsg);
  };

  return (
    <>
      {/* {
        !!message && <div className="successMsg text-success">{message}</div>
      } */}
      <div className="bg-white border border-2 rounded-2 mb-3">
        <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
          Order Attachment
        </div>

        <div className="border border-2  p-2">
          <div className="row">
            <div className="col-md-12 mb-2">
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-5">
                  <div>
                    <label className="form-label theme-label" htmlFor="">
                      Select Doc
                    </label>
                  </div>
                  <div className="Upload-Photo mb-2 w-100">
                    <input
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg"
                      id="attachmentFile"
                      ref={fileRef}
                      onChange={(e) => handelFileSelect(e)}
                    />
                    <div className="uploaded-text ms-2 w-100">
                      {GetSvgUpload(true)}
                      {defaultFileName}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div>
                    <label className="form-label theme-label" htmlFor="">
                      Add description
                    </label>
                  </div>
                  <input
                    id="decp"
                    type="text"
                    className="form-control"
                    name="description"
                    ref={decpRef}
                  />
                </div>
                <div className="col-md-2 text-right">
                  <div className="mt-1">
                    <label
                      className="form-label theme-label"
                      htmlFor=""
                    ></label>
                  </div>

                  <button
                    className="btn btn-outline-secondary theme-violet px-4"
                    type="button"
                    onClick={handelOrderAttachment}
                  >
                    ADD
                  </button>
                </div>
              </div>

              <table className="table">
                <tbody>
                  {orderAttachments.map((item, index) => (
                    <tr key={index}>
                      <td className="col-md-5">
                        <div className="w-75 break-word">{item.fileName}</div>
                      </td>
                      <td className="col-md-5">
                        {!item.description || item.description !== "null"
                          ? item.description
                          : ""}
                      </td>
                      <td>
                        {!item.file && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet theme-violet-buttons float-end download-uploaded-file"
                            onClick={() =>
                              DownloadFile(item.fileName, onSuccess, onError)
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerOrderAttachment;

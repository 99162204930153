export const initValShipmentDtls = {
    shipmentId: '',
    customerId: '',
    userId: '',
    orderType: '',
    shipmentDate: '',
    estDeliveryDate: '',
    origin: '',
    destination: '',
    slaId: '',
    senderName: '',
    senderAddress: '',
    senderPostcode: '',
    senderPhone: '',
    recipientName: '',
    recipientAddress: '',
    recipientPostcode: '',
    recipientPhone: '',
    recipientIC: '',
    pallets: '',
    palletSpaceRequired: '',
    totalWeight: '',
    status: '',
    remarks: '',
    daysToDeliver: '',
    shipmentPallets: [
        {
            palletId: '',
            orderId: '',
            contentId: '',
            weight: '',
            width: '',
            height: '',
            length: '',
            waybillNo: '',
            contentName: '',
            contentDocs: ''

        }
    ],

    shipmentVas: [],
    shipmentBilling: [],
    waybillList: '',
    insuranceValue: '',
    packingType: '',
    bookedOn: '',
    originSbuCode: '',
    destinationSbuCode: '',
    slaName: '',
    statusText: '',
    doReturn: '',
    securedInfo: '',
    paymentGateway: '',
    creditLimit: '',
    orderNo: '',
    custAccountCode: '',
    international: '',
    specialInstruction: '',
    pickupTime: '',
    description: '',
    attachmentFile: ''

}
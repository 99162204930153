import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  CNCL_ORD_STS,
  CXLSHIP,
  DLV_ORD_STS, LINEHAUL_CUSTOMER_BUSINESS_PARTNERS, LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL,
  ORDBKNG,
  PLC_ORD_STS,
  PND_PYMT_ORD_STS,
  TRANSIT_ORD_STS,
  wt_ORD_STS
} from "../../ConfigConstants/ConfigConstants";
import { useEffect } from "react";
import { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  getKiplForm,
  getOrderStatusBadgeClass,
  trackWaybill,
  permissionCheck
} from "../Helper/CommonMethods";
import GetSvgIcon from "../../images/svglist";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import { Tooltip } from "react-tooltip";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
export default function CustomerOrderList(props) {
  const {
    orderList,
    showClaimForm,
    handelRepeatBooking,
    updateOrderBookingStatus,
    handelRtsClick,
    openOrderDtls,
    conformCancel,
    setSpinner,
    orderCancellationLog,
    loadData,
  } = props;
  const userType = getUserInfo("userType");
  const userRole = getUserInfo("userRoles");
  const [hideColums, setHideColums] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState(
    JSON.parse(JSON.stringify(initAlertConfig))
  );
  const [custType, setCustType] = useState();
  const [conformMsg, setConformMsg] = useState();
  const [creditData, setCreditData] = useState({});
  const [appCreditLimit, setAppCreditLimit] = useState(0);
  const [creditNote, setCreditNote] = useState(0);
  const [creditNoteApplied, seCreditNoteApplied] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);

  useEffect(() => {
    setHideColums(
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ? true : false
    );

    setCustType(getUserInfo("userType"));
  }, []);

  const makePayment = async (row) => {
    document.body.click();
    let invoiceResp = await CallApiGet(
      "getPaymentConformData?orderId=" + row.shipmentId
    );

    if (invoiceResp.respCode === 200) {
      setCreditNote(Math.abs(invoiceResp.respData.creditNote));
      setAppCreditLimit(invoiceResp.respData.appCreditLimit);
      setFinalAmount(invoiceResp.respData.billAmount)
      if (invoiceResp.respData.isInvoiceAvl) {
        setConformMsg("Do you want to proceed for payment?");
        setCreditData(invoiceResp.respData);
      } else {
        setConformMsg("Do you want to confirm the order?");
        setCreditData(invoiceResp.respData);
      }
      conformPay();
    }
  };

  const complitePayment = async () => {
    hideConfirmAlert();
    if (creditData.isInvoiceAvl) {
      if (creditNoteApplied) {
        const uri = "makePayment";
        let data = {
          paymentAmount: finalAmount,
          customerId: creditData.custId,
          invoiceDistributionList: [{invoiceId:creditData.invoiceId}],
          creditNoteApplied,
          orderId:creditData.shipmentId,
        };
        let resp = await CallApiPost(uri, data);
        if (resp.respCode === 200) {
          if (finalAmount > 0) {
            resp.respData.ord_customfield2 = "MYORDERS";
            
            document.getElementById("paymentForm").innerHTML = getKiplForm(
              resp.respData,
              creditData.shipmentId
            );
            document.getElementById("onlinepayment").submit();
          } else {
            loadData("ALL", true);
          }
        }
      } else {
        let payGatewayDtls = await CallApiGet(
          "get-pay-gateway?shipmentId=" + creditData.shipmentId
        );
        if (payGatewayDtls.respCode === 200) {debugger
          document.getElementById("paymentForm").innerHTML = getKiplForm(
            payGatewayDtls.respData.paymentGateway,
            creditData.shipmentId
          );
          document.getElementById("onlinepayment").submit();
        }
      }
    } else {
      //update order status to Order complited
      updateOrderBookingStatus(creditData.shipmentId);
    }
  };

  const printAllWaybill = async (data) => {
    setSpinner(true);
    const payload = {
      shipmentIdList: [data?.shipmentId],
      palletIdList: [],
      isSearchByOrder: true,
    };
    const resp = await CallApiPost("printWaybill", payload);
    setSpinner(false);
    if (resp.respCode === 200) {
      window.open(resp.respData, "_blank");
    }
  };

  const conformPay = () => {
    setConfirmConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Confirm Payment",
      description: "",
    });
    // setSelectedOrderId(shipmentId)
  };

  const hideConfirmAlert = () => {
    setConfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const payByCreditVal = (checked) => {
    seCreditNoteApplied(checked);
    if (checked) {
      let amt = creditData.billAmount - creditNote;
      if (amt > 0) {
        setFinalAmount(amt.toFixed(2));
      } else setFinalAmount(0);
    } else {
      setFinalAmount(creditData.billAmount);
    }
  };

  // const showClaimForm = (argData) => {
  //   let encoded = Buffer.from(
  //     JSON.stringify({
  //       claimId: 0,
  //       orderId: argData?.shipmentId,
  //       palletId: 0,
  //       orderNo: argData?.orderNo,
  //       origin: argData?.origin?.sbuCode,
  //       destination: argData?.destination?.sbuCode,
  //       shipDt: moment(argData?.shipmentDate).format(DATE_FORMAT_MOMENT),
  //       estDt: moment(argData?.estDeliveryDate).format(DATE_FORMAT_MOMENT),
  //       ordSts: argData?.statusText,
  //     })
  //   ).toString("base64");
  //   window.open(`/raiseClaim?data=${encoded}`, "_blank");
  // };

  const columns = [
    {
      name: "Account Code.",
      selector: (row) => row.custAccountCode,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Name",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Company Name",
      selector: (row) => row?.businessName,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    // {
    //   name: "Waybill No.",
    //   selector: (row) => row.waybill_no,
    //   cell: (row) => (
    //     <>
    //       <div
    //         onClick={() => setModalData("waybill", row)}
    //         className="modal-links fw-bold"
    //       >
    //         {row.contentType === "Motor Bike" && GetSvgIcon("Bike")}
    //         {row.contentType === "Car" && GetSvgIcon("Car")}
    //         {row.contentType === "Choper" && GetSvgIcon("Aircraft")}
    //         &nbsp;
    //         {row.waybill_no}
    //       </div>
    //     </>
    //   ),
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Order No.",
      selector: (row) => row.orderNo,
      cell: (row) => (
        <>
          <div
            onClick={() => openOrderDtls("order", row)}
            className="modal-links fw-bold"
          >
            {row.orderNo}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Booked On",
      selector: (row) => moment(row.bookedOn).format(DATE_TIME_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortType: (a, b) => {
        return new Date(b.values.bookedOn) - new Date(a.values.bookedOn);
      },
    },
    {
      name: "Origin",
      selector: (row) => row?.origin?.sbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Destination",
      selector: (row) => row?.destination?.sbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Package Type",
      selector: (row) => row.packingType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Service",
      selector: (row) => row.slaName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Space/Pallet",
      selector: (row) => row.palletSpaceRequired,
      cell: (row) => (
        <>
          <div>
            {row.palletSpaceRequired}/{row.pallets}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Shipment Date",
      selector: (row) => moment(row.shipmentDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Estimated Delivery",
      selector: (row) => moment(row.estDeliveryDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) => row.statusText,
      cell: (row) => (
        <>
          {row.status === 75 ? (
            <div
              className={getOrderStatusBadgeClass(row)}
              data-tooltip-id={"pay" + row.shipmentId}
            >
              {row.invoiceStatus === 710
                ? "Payment In Progress"
                : row.statusText}
            </div>
          ) : (
            <div className={getOrderStatusBadgeClass(row)}>
              {row.statusText}
            </div>
          )}
          <Tooltip
            id={"pay" + row.shipmentId}
            content="Updated payment status will be reflected within 2 Hours Max"
            place="left"
            style={{ zIndex: "100" }}
          />
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      width: "5%",
      cell: (row) =>
        row.status === CNCL_ORD_STS ? (
          permissionCheck(CXLSHIP, "allowedRead") && (
            <>
              <div
                data-tooltip-id={"ord-" + row.shipmentId}
                className="action-item-button action-item-button_icon"
                onClick={() => orderCancellationLog(row.shipmentId)}
                data-bs-toggle="modal"
                data-bs-target="#order_cancellation-log-modal"
              >
                {GetSvgIcon("BiBoxArrowInLeft")}
              </div>
              <Tooltip
                id={"ord-" + row.shipmentId}
                content={"Cancellation Details"}
                place="left"
              />
            </>
          )
        ) : (
          <>
            <OverlayTrigger
              trigger="click"
              key={row.id}
              placement="left"
              rootClose
              overlay={
                <Popover id={`popover-positioned-lefft`}>
                  <Popover.Body>
                    {![75, 77].includes(row.status) && (
                      <>
                        <div
                          className="action-item-button"
                          onClick={() => printAllWaybill(row)}
                        >
                          {GetSvgIcon("Print")} Print Waybills 
                        </div>
                        <div
                          className="action-item-button"
                          onClick={() => {
                            document.body.click();
                            trackWaybill({
                              waybill_no: JSON.parse(row.waybill_no).map(
                                (obj) => obj.bill_no
                              ),
                            });
                          }}
                        >
                          {GetSvgIcon("Geoalttrack")} Track Waybill
                        </div>
                      </>
                    )}
                    {/* {!PENDINGPAY_WAIT_CANCEL_ARR.includes(row.pallet_status) && (
                    <div
                      className="action-item-button"
                      onClick={() => trackWaybill(row)}
                    >
                      {GetSvgIcon("Geoalttrack")} Track Waybill
                    </div>
                  )} */}
                    {row.orderNo.substr(0, 3) !== "RTS" &&
                      permissionCheck(ORDBKNG, "allowedWrite") && (
                        <div
                          className="action-item-button"
                          onClick={() =>
                            handelRepeatBooking(row.shipmentId, row.customerId)
                          }
                        >
                          {GetSvgIcon("IconDiscount")} Repeat Booking
                        </div>
                      )}
                    {row.status === 75 &&
                      row.invoiceStatus !== 710 &&
                      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(custType) && (
                        <div
                          className="action-item-button"
                          onClick={() => makePayment(row)}
                        >
                          {GetSvgIcon("PaymentDetails")} Make Payment
                        </div>
                      )}
                    {![75, 77, 87, 88, 611].includes(row.status) &&
                      +userType !== 134 &&
                      !row.claimApplied && (
                        <div
                          className="action-item-button"
                          onClick={() => showClaimForm(row)}
                        >
                          {GetSvgIcon("PaymentDetails")} Claim
                        </div>
                      )}
                    {
                      // ['135', '136'].includes(custType)
                      // specialPrivilege used for Line Clear
                      // (row.specialPrivilege === true
                      //   ? row.status !== CNCL_ORD_STS &&
                      //     row.status !== DLV_ORD_STS
                      //   : row.status === PLC_ORD_STS ||
                      //     row.status === wt_ORD_STS ||
                      //     row.status === PND_PYMT_ORD_STS)
                      permissionCheck(CXLSHIP, "allowedWrite") &&
                      +userType === 134 &&
                      JSON.parse(userRole)[0].roleId === 1
                        ? row.status !== CNCL_ORD_STS &&
                          row.status !== DLV_ORD_STS && (
                            <div
                              className="action-item-button"
                              onClick={() => conformCancel(row)}
                            >
                              {GetSvgIcon("IconDiscount")} Cancel Order
                            </div>
                          )
                        : [wt_ORD_STS, PLC_ORD_STS, PND_PYMT_ORD_STS].includes(
                            row.status
                          ) && (
                            <div
                              className="action-item-button"
                              onClick={() => conformCancel(row)}
                            >
                              {GetSvgIcon("IconDiscount")} Cancel Order
                            </div>
                          )
                    }
                    {row.status === TRANSIT_ORD_STS &&
                      row.orderNo.substring(0, 3) !== "RTS" &&
                      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (
                        <div
                          className="action-item-button"
                          onClick={() => handelRtsClick(row?.shipmentId)}
                        >
                          {GetSvgIcon("IconDiscount")} Create RTS
                        </div>
                      )}
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="row-action-button">
                {GetSvgIcon("Trippleverticaldot")}
              </span>
            </OverlayTrigger>
          </>
        ),
    },
  ];
  return (
    <div>
      <div>
        <div className="col-md-12 table-responsive customized-data-table position-relative">
          <DataTable
            columns={columns}
            data={orderList}
            style={`table table-striped table-hover`}
            customStyles={customDataTableStyles}
            pagination
            striped
            highlightOnHover
            // defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        </div>
      </div>
      <div id="paymentForm"></div>
      <ModalConfirmAlert
        confirmAlertConfig={confirmConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={complitePayment}
      >
        <div>
          {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(+custType) &&
              appCreditLimit > 0 &&(
            <>
              <div className="font-18 theme-violet-txt ">
                <div>
                  Credit Available :{" "}
                  <span className="fw-bold">
                    RM {creditData.availableLimit}
                  </span>
                </div>
              </div>
              <div className="font-18 pb-2 text-danger">
                {" "}
                Bill Amount :{" "}
                <span className="fw-bold">RM {creditData.billAmount}</span>
              </div>
            </>
          )}

          {(custType === LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL ||
            (LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) &&
              appCreditLimit === 0)) &&
            creditNote > 0 && (
              <>
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label fs-5"
                    >
                      Bill Amount :
                    </label>
                  </div>
                  <div className="col-md-6 mb-2 text-start ">
                    <label className="form-label fs-4">
                      {creditData.billAmount.toFixed(2)} RM
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label fs-5"
                    >
                      Credit Note Available :
                    </label>
                  </div>
                  <div className="col-md-6 mb-2 text-start ">
                    <label className="form-label fs-4">
                      {creditNote.toFixed(2)} RM
                    </label>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 text-start "></div>
                  <div className="col-md-6 mb-2 text-start ">
                    <input
                      className="form-check-input p-0 me-2 "
                      type="checkbox"
                      // style={{background: "aliceblue"}}
                      onChange={(e) => payByCreditVal(e.target.checked)}
                      // checked={(e)=>payByCreditVal(e)}
                      disabled={+creditNote < 1}
                    />
                    Apply Credit Note
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label fs-5"
                    >
                      Final Amount :
                    </label>
                  </div>
                  <div className="col-md-6 mb-2 text-start ">
                    <label className="form-label fs-4">{finalAmount} RM</label>
                  </div>
                </div>
              </>
            )}

          <div className="font-18 fw-bold text-start">{conformMsg}</div>
        </div>
      </ModalConfirmAlert>
    </div>
  );
}

import React from "react";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { toBase64 } from "../Helper/CommonMethods";

export default function MultipleFileUpload(props) {
  const {
    register,
    clearErrors,
    setValue,
    watch,
    customData,
    customCss,
    generalDocList,
    remove,
    append,
  } = props;

  const uploadMultipleFile = async (key, event, index) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setUpPhotoData(key, newAttachment, index);
    clearErrors(key);
  };
  const setUpPhotoData = (key, argAttachment, index) => {
    switch (key) {
      case "generalDocList":
        setValue(`${key}.${index}.photo`, argAttachment.photo);
        setValue(`${key}.${index}.photoFileName`, argAttachment.photoFileName);
        setValue(`${key}.${index}.photoFileType`, argAttachment.photoFileType);
        setValue(
          `${key}.${index}.photoBucketUrl`,
          argAttachment.photoBucketUrl
        );
        break;

      default:
        setValue(`${key}.photo`, argAttachment.photo);
        setValue(`${key}.photoFileName`, argAttachment.photoFileName);
        setValue(`${key}.photoFileType`, argAttachment.photoFileType);
        setValue(`${key}.photoBucketUrl`, argAttachment.photoBucketUrl);
        break;
    }
  };
  return (
    <div className={customCss.main}>
      <div>
        <h6 className={customCss.header.levelColor}>
          {customData.headerLevel}
          <span onClick={() => append({})} className="ms-3">
            {GetSvgIcon("PlusCircle")}
          </span>
        </h6>
      </div>
      <div className={customCss.comp.mainDiv}>
        {!!generalDocList &&
          generalDocList?.map((field, index) => (
            <>
              <div className={customCss.comp.innerDiv}>
                <div
                  className="Upload-Photo w-100 mb-2"
                  {...register(`generalDocList.${index}`, {
                    validate: (v) =>
                      v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                    required: {
                      value: false,
                    },
                  })}
                >
                  <input
                    type="file"
                    onChange={(e) =>
                      uploadMultipleFile(`generalDocList`, e, index)
                    }
                  />
                  <div className={customCss.comp.innerLevel}>
                    {GetSvgUpload({ required: false })}

                    {!!watch(`generalDocList.${index}.photoFileName`)
                      ? watch(`generalDocList.${index}.photoFileName`)
                      : customData.defaultName}
                  </div>
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="border-0 bg-white "
                  >
                    {GetSvgIcon("Removecircle")}
                  </button>
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
}

import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const CustomToast = (props) => {
  const { showToast, closeToast, headerMsg, body } = props;
  return (
    <ToastContainer className="p-3" position="top-center">
      <Toast show={showToast} onClose={closeToast} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">{headerMsg}</strong>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default CustomToast;

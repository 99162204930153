import React, { useState, useRef } from 'react'
import { useEffect } from 'react';
import DataTable from 'react-data-table-component-with-filter';
import { getUserInfo } from '../../../AuthUtils/AuthUtils';
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../../../Helper/Constants';
import { CallApiGet, CallApiPost } from '../../../Helper/serviceApi';
import Select from "react-select";

const IsrReassign = (props) => {
    const { onFailure, onSuccess, currentIsr } = props
    const [selectedRows, setSelectedRows] = useState([])
    const [reassign, setReassign] = useState(null)
    const [custList, setCustList] = useState([])
    const [isrList, setIsrList] = useState([])
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const selectInputRef = useRef();
    const [submitStatus, setSubmitStatus] = useState({
        value: false,
        message: ""
    })
    const columns = [
        {
            name: "Customer ID",
            selector: (row) => row.acct_code,
            sortable: true,
            filterable: true,
        },
        {
            name: "Customer Name",
            selector: (row) => row.cust_name,
            sortable: true,
            filterable: true,
        },
        {
            name: "Assigned To ISR ID",
            selector: (row) => row.isr_staff_id,
            sortable: true,
            filterable: true,
        },
        {
            name: "Assigned To ISR Name",
            selector: (row) => row.isr_name,
            sortable: true,
            filterable: true,
        },

    ];

    useEffect(() => {
        fetchCustListForIsr(currentIsr)
        fetchIsrList(currentIsr)
        clearSelection()
    }, [currentIsr])

    const fetchCustListForIsr = async (isrId) => {
        const uri = "customer-assignTo-isr?id=" + isrId
        const data = await CallApiGet(uri)
        switch (data.respCode) {
            case 200: case 0:
                setCustList(data.respData)
                break;
            default:
                onFailure()
                break;
        }
    }

    const fetchIsrList = async (isrId) => {
        const uri = "list-isr-for-reassign?currentIsrId=" + isrId
        const data = await CallApiGet(uri)
        switch (data.respCode) {
            case 200: case 0:
                if (data.respData && data.respData.length > 0) {
                    let isrDropdownList = [];
                    data.respData.forEach(element => {
                        let isr = {
                            value: element.id,
                            label: `${element["staff_name"]} (${element["staff_id"]})`
                        };
                        isrDropdownList.push(isr);
                    });
                    setIsrList(isrDropdownList);
                }
                break;
            default:
                onFailure()
                break;
        }
    }

    const handleChange = ({ selectedRows }) => {
        console.log('Selected Rows: ', selectedRows);
        let data = []
        selectedRows?.map(row => {
            data.push(row.id)
        })
        setSelectedRows(data)
    }

    const handleReassign = (reassignIsr) => {
        if (reassignIsr)
            setReassign(reassignIsr.value);
    }

    const clearSelection = () => {
        if (selectInputRef.current) {
            selectInputRef.current.setValue(null);
        }
        setToggledClearRows(!toggledClearRows);
        // $("#isr-reassign-modal").modal('hide');
        document.getElementById("closeButton").click();
    }

    const handleSubmit = async () => {
        if (selectedRows.length === 0) {
            setSubmitStatus({
                value: true,
                message: "Sorry, You have not selected any customer to reassign."
            })
            setTimeoutForAlert()
            return
        } else {
            if (!reassign) {
                setSubmitStatus({
                    value: true,
                    message: "Sorry, You have not selected any ISR in dropdown to reassign."
                })
                setTimeoutForAlert()
                return
            } else {
                const model = {
                    customerIdList: selectedRows,
                    assignorUserId: getUserInfo("userId"),
                    isrId: reassign ? parseInt(reassign) : 0
                }
                const data = await CallApiPost("reassign-cust", model)
                switch (data.respCode) {
                    case 200: case 0:
                        setCustList([])
                        setIsrList([])
                        clearSelection()
                        onSuccess(true, "Success", "Customer(s) reassigned successfully !!!")
                        break;
                    default:
                        clearSelection()
                        onFailure()
                        break;
                }
            }
        }

    }

    const setTimeoutForAlert = () => {
        setTimeout(() => {
            setSubmitStatus({
                value: false,
                message: ""
            })
        }, 10000);
    }

    return (
        <div className="modal fade" id="isr-reassign-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title section-title" id="exampleModalLabel">Reassign ISR</h5>
                        <button type="button" className="btn-close" aria-label="Close"
                            onClick={clearSelection}
                        ></button>
                        <button id="closeButton" type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close"
                        ></button>
                    </div>
                    {submitStatus.value && (
                        <div id="errorMsg" className="alert alert-danger" role="alert">
                            {" "}
                            {submitStatus.message}{" "}
                        </div>
                    )}
                    <div className="modal-body overflow-auto modal-medium">
                        <div className="row">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="clearfix">

                                        </div>
                                        <div className="col-md-12 mt-1 popup-data-table position-relative">
                                            <DataTable
                                                columns={columns}
                                                data={custList}
                                                pagination
                                                striped
                                                highlightOnHover
                                                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                                                paginationPerPage={defaultPageLimit}
                                                customStyles={customDataTableStyles}
                                                selectableRows
                                                onSelectedRowsChange={handleChange}
                                                clearSelectedRows={toggledClearRows}
                                                fixedHeader
                                                fixedHeaderScrollHeight='400px'
                                            />
                                        </div>
                                        {custList.length > 0 && (<div className="row pe-0">
                                            <div className="col-md-5"></div>
                                            <div className="col-md-3 text-end px-0">
                                                <label className="form-label theme-label">
                                                    Reassign To:{" "}
                                                </label>
                                            </div>
                                            <div className="col-md-4 pe-0">
                                                <Select
                                                    options={isrList}
                                                    placeholder="Select ISR"
                                                    onChange={handleReassign}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    className="form-control  p-0"
                                                    ref={selectInputRef}
                                                    menuPosition="top"
                                                />
                                                {/* <select className="form-control font-12"
                                                    onChange={handleReassign}
                                                >
                                                    <option value={0}>Select ISR</option>
                                                    {
                                                        isrList.map(opt => (
                                                            <option value={opt.id}>{opt.staff_name}</option>
                                                        ))
                                                    }
                                                </select> */}
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet"
                            onClick={handleSubmit}
                            disabled={custList.length === 0}
                        >Reassign</button>
                        {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Deactivate</button> */}
                    </div>
                </div>

            </div>
        </div >
    )
}

export default IsrReassign
import React from "react";
import { Controller } from "react-hook-form";
import {
  DATE_FORMAT_CLIENT,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import DataTable from "react-data-table-component-with-filter";

const Promotion = (props) => {
  const {
    sbuList,
    register,
    watch,
    control,
    discountTypeChange,
    watchId,
    errors,
    clearField,
    watchPayMode,
    clearCreditCard,
    watchPtypeCredit,
    custColumns,
    allCustomers,
    rowSelectCritera,
    toggledClearRows,
    ptatus,
    watchDiscType,
    watchPickup,
    watchDelivery,
    watchPackage,
    watchHandel,
    amountTypeChange,
    clearErrors,
    clearLimitBox,
  } = props;

  const watchPtype = watch("pType");
  const watchStartDate = watch("promotionStartDate");
  const watchEndDate = watch("promotionEndDate");
  const watchIsNewCust = watch("isNewCust");

  const rowDisabledCriteria = (row) => {
    let status = ptatus === "Published" ? true : false;
    return status;
  };

  return (
    <>
      <div className="border border-1 rounded bg-white shadow mb-3">
        <div className="row mx-1 py-3 justify-content-between">
          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Promo Code
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                {...register("promoCode", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
                disabled={watchId > 0 ? true : false}
              />
              {errors.promoCode && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.promoCode.message}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Campaign Name
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                {...register("promoName", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
                disabled={watchId > 0 ? true : false}
              />
              {errors.promoName && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.promoName.message}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Campaign Start
            </label>
            <div>
              <Controller
                control={control}
                name="promotionStartDate"
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    placeholderText=" Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    disabled={ptatus === "Published" ? true : false}
                    minDate={new Date()}
                    maxDate={new Date(watchEndDate)}
                    selected={value ? new Date(value) : value}
                    onChange={(d) =>
                      onChange(d !== null ? moment(d).format("YYYY-MM-DD") : "")
                    }
                  />
                )}
              />
              {errors.promotionStartDate && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.promotionStartDate.message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Campaign End
            </label>
            <div>
              <Controller
                control={control}
                name="promotionEndDate"
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    placeholderText=" Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    disabled={ptatus === "Published" ? true : false}
                    minDate={
                      !!watchStartDate ? new Date(watchStartDate) : new Date()
                    }
                    selected={value ? new Date(value) : value}
                    onChange={(d) =>
                      onChange(d !== null ? moment(d).format("YYYY-MM-DD") : "")
                    }
                  />
                )}
              />
              {errors.promotionEndDate && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.promotionEndDate.message}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-1 rounded bg-white shadow p-3 mb-3">
        <div className="toggleSwitchWrapper">
          <input
            name="serviceTypeName"
            type="radio"
            className="btn-check"
            id="discountRadioOptions"
            autoComplete="off"
            value="discount"
            disabled={ptatus === "Published" ? true : false}
            {...register("pType", {
              onChange: (e) => discountTypeChange(e.target.value),
            })}
          />
          <label
            className="btn btn-outline-success tl-btn"
            htmlFor="discountRadioOptions"
          >
            Discount
          </label>

          <input
            name="serviceTypeName"
            type="radio"
            className="btn-check"
            id="freeServiceRadioOptions"
            autoComplete="off"
            value="frService"
            {...register("pType", {
              onChange: (e) => discountTypeChange(e.target.value),
            })}
            disabled={ptatus === "Published" ? true : false}
          />
          <label
            className="btn btn-outline-success tr-btn"
            htmlFor="freeServiceRadioOptions"
          >
            Free Service
          </label>
        </div>

        <div className="row mt-3" id="myDiV">
          <div className="w-100">
            {watchPtype === "discount" && (
              <div className="">
                {/* ------------------- */}
                <div className="d-flex justify-content-start my-3">
                  <div className="form-check form-check-inline d-flex me-5">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="discType"
                        id="inlineRadio101"
                        value="percent"
                        {...register("discType", {
                          onChange: (e) => amountTypeChange(e.target.value),
                        })}
                        disabled={ptatus === "Published" ? true : false}
                      />
                    </div>
                    <label
                      className="align-self-center fw-bold theme-label"
                      htmlFor="inlineRadio101"
                    >
                      Percentage
                    </label>
                  </div>

                  <div className="form-check form-check-inline d-flex">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="discType"
                        id="inlineRadio102"
                        value="amt"
                        {...register("discType", {
                          onChange: (e) => amountTypeChange(e.target.value),
                        })}
                        disabled={ptatus === "Published" ? true : false}
                      />
                    </div>
                    <label
                      className="align-self-center fw-bold theme-label"
                      htmlFor="inlineRadio102"
                    >
                      Amount
                    </label>
                  </div>
                </div>
                {/* ---------------------- */}
                <div className="row">
                  {watchDiscType === "percent" && (
                    <>
                      <div className="col-md-3">
                        <label
                          htmlFor="floatingInputGrid"
                          className="form-label theme-label"
                        >
                          Discount %
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register("discount", {
                              required: {
                                value: watchPtype === "discount" ? true : false,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: NUMBER_REGEX,
                                message: "Incorrect Data!!!",
                              },
                              // validate: {
                              //   value: isPercent,
                              //   message: "Invalid Percentage!!!",
                              // },
                            })}
                            disabled={ptatus === "Published" ? true : false}
                          />
                          {errors.discount &&
                            errors.discount.message !== "" && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.discount.message}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label
                          htmlFor="floatingInputGrid"
                          className="form-label theme-label"
                        >
                          Max. Discount Amount
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            {...register("maxDiscount", {
                              required: {
                                value: watchPtype === "discount" ? true : false,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: NUMBER_REGEX,
                                message: "Incorrect Data!!!",
                              },
                            })}
                            disabled={ptatus === "Published" ? true : false}
                          />
                          {errors.maxDiscount && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.maxDiscount.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {watchDiscType === "amt" && (
                    <div className="col-md-3">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Discount Amount
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          {...register("discAmt", {
                            required: {
                              value: watchPtype === "discount" ? true : false,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: NUMBER_TWO_DECIMAL,
                              message: "Incorrect Data!!!",
                            },
                          })}
                          disabled={ptatus === "Published" ? true : false}
                        />
                        {errors.discAmt && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.discAmt.message}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {watchPtype === "frService" && (
              <div>
                <div className="row justify-content-between">
                  <div className="col-md-3">
                    <label className="form-label theme-label" htmlFor="">
                      Pick-up
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          value="pickUp"
                          // name="freeServiceType"
                          name="servicePickup"
                          {...register("servicePickup", {
                            onChange: (e) =>
                              clearField("servicePickup", e.target.checked),
                          })}
                          disabled={ptatus === "Published" ? true : false}
                          aria-label="Checkbox for following text input"
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Free Pickup Within (KM)"
                        {...register("pickupKm", {
                          required: {
                            value: watchPickup === "pickUp" ? true : false,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Data!!!",
                          },
                        })}
                        disabled={watchPickup === "pickUp" ? false : true}
                      />
                    </div>
                    {errors.pickupKm && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupKm.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label theme-label" htmlFor="">
                      Delivery
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          value="delivery"
                          // name="freeServiceType"
                          name="serviceDelivery"
                          {...register("serviceDelivery", {
                            onChange: (e) =>
                              clearField("serviceDelivery", e.target.checked),
                          })}
                          disabled={ptatus === "Published" ? true : false}
                          aria-label="Checkbox for following text input"
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Free Delivery Within (KM)"
                        {...register("deliveryKm", {
                          required: {
                            value: watchDelivery === "delivery" ? true : false,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Data!!!",
                          },
                        })}
                        disabled={watchDelivery === "delivery" ? false : true}
                      />
                    </div>
                    {errors.deliveryKm && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryKm.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label theme-label" htmlFor="">
                      Packaging
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          value="packaging"
                          // name="freeServiceType"
                          name="servicePackage"
                          {...register("servicePackage", {
                            onChange: (e) =>
                              clearField("servicePackage", e.target.checked),
                          })}
                          disabled={ptatus === "Published" ? true : false}
                          aria-label="Checkbox for following text input"
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Number of Pallets"
                        {...register("packagePallets", {
                          required: {
                            value: watchPackage === "packaging" ? true : false,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Data!!!",
                          },
                        })}
                        disabled={watchPackage === "packaging" ? false : true}
                      />
                    </div>
                    {errors.packagePallets && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.packagePallets.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label theme-label" htmlFor="">
                      Handling
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-text">
                        <input
                          className="form-check-input mt-0"
                          type="checkbox"
                          value="handling"
                          // name="freeServiceType"
                          name="serviceHandel"
                          {...register("serviceHandel", {
                            onChange: (e) =>
                              clearField("serviceHandel", e.target.checked),
                          })}
                          disabled={ptatus === "Published" ? true : false}
                          aria-label="Checkbox for following text input"
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Number of Pallets"
                        {...register("handelPallets", {
                          required: {
                            value: watchHandel === "handling" ? true : false,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Data!!!",
                          },
                        })}
                        disabled={watchHandel === "handling" ? false : true}
                      />
                    </div>
                    {errors.handelPallets && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.handelPallets.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="border border-1 rounded bg-white shadow p-3 mt-3">
        <div className="d-flex justify-content-start">
          <div>
            <label
              className="align-self-center fw-bold theme-violet-txt"
              htmlFor="inlineRadio1"
            >
              Eligibility Criteria
            </label>
          </div>
        </div>

        {/* {watchCriteria === "eligibility" && ( */}
        <div className="d-block">
          <div className="row my-2 justify-content-start align-item-center">
            <div
              htmlFor="floatingInputGrid"
              className="me-3 col-xl-auto text-dark"
            >
              Payment By:
            </div>
            <div className="form-check form-check-inline col-xl-auto">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox1"
                value="cash"
                name="pTypes"
                {...register("pTypesCash", {
                  required: {
                    value:
                      watchPtypeCredit !== "credit" && watchPayMode !== "cash"
                        ? true
                        : false,
                    message: "Select one Payment Type",
                  },
                  onChange: (e) => clearCreditCard(e),
                })}
                disabled={ptatus === "Published" ? true : false}
              />
              <label
                className="form-check-label text-dark"
                htmlFor="inlineCheckbox1"
              >
                Cash{" "}
                <span className="radio-icon gy-1">
                  {GetSvgIcon("CashStack")}
                </span>
              </label>
            </div>
            

            <div className="form-check form-check-inline col-xl-auto ms-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                value="credit"
                name="pTypes"
                {...register("pTypesCredit", {
                  required: {
                    value:
                      watchPtypeCredit !== "credit" && watchPayMode !== "cash"
                        ? true
                        : false,
                    message: "Select one Payment Type",
                  },
                })}
                disabled={ptatus === "Published" ? true : false}
              />

              <label
                className="form-check-label text-dark align-self-center"
                htmlFor="inlineCheckbox2"
              >
                Credit{" "}
                <span className="radio-icon">{GetSvgIcon("IconCoin")}</span>
              </label>
            </div>
          </div>
          {errors.pTypesCredit && (
            <div>
              <span className="badge bg-danger">
                &#9888; {errors.pTypesCredit.message}
              </span>
            </div>
          )}

          <div className="row">
            <div className="col-md-3">
              <label htmlFor="" className="form-label theme-label">
                Origin{" "}
              </label>
              <Controller
                name="origin"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={sbuList}
                    placeholder="-Select-"
                    // value={
                    //   value ? sbuList.find((opt) => opt.value === value) : value
                    // }
                    value={sbuList.filter((opt) => value?.includes(opt.value))}
                    onChange={(d) =>
                      onChange(d ? d.map((opt) => opt.value) : [])
                    }
                    isSearchable={true}
                    isClearable={true}
                    isMulti={true}
                    className=""
                    // isDisabled={watchIsRegion ? false : true}
                    menuPlacement="auto"
                    isDisabled={ptatus === "Published" ? true : false}
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
            <div className="col-md-3">
              <label
                htmlFor=""
                className="form-label theme-label"
                title="This field is mandatory"
              >
                Destination
              </label>

              <Controller
                name="destination"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={sbuList}
                    placeholder="-Select-"
                    // value={
                    //   value ? sbuList.find((opt) => opt.value === value) : value
                    // }
                    value={sbuList.filter((opt) => value?.includes(opt.value))}
                    onChange={(d) =>
                      onChange(d ? d.map((opt) => opt.value) : [])
                    }
                    isSearchable={true}
                    isClearable={true}
                    isMulti={true}
                    className=""
                    // className="form-control"
                    // isDisabled={watchIsRegion ? false : true}
                    menuPlacement="auto"
                    isDisabled={ptatus === "Published" ? true : false}
                  />
                )}
                // rules={{ required: true }}
              />
            </div>
            <div className="col-md-3">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Min. Order Amount
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  {...register("minOrderAmt", {
                    required: {
                      value: false,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: NUMBER_REGEX,
                      message: "Incorrect Data!!!",
                    },
                  })}
                  disabled={ptatus === "Published" ? true : false}
                />
                {errors.minOrderAmt && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.minOrderAmt.message}
                    </span>
                  </div>
                )}
              </div>
            </div>

            
            <div className="col">
              <label className="form-label theme-label" htmlFor="">
                New Customer
              </label>

              <div className="input-group mb-3">
                <div className="input-group-text">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    value="newCust"
                    aria-label="Checkbox for following text input"
                    {...register("isNewCust", {
                      onChange: (e) => (
                        clearErrors("custBookLimit"),
                        clearLimitBox(e.target.checked)
                      ),
                    })}
                    disabled={ptatus === "Published" ? true : false}
                  />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Max Booking Limit"
                  {...register("custBookLimit", {
                    required: {
                      value: watchIsNewCust === "newCust" ? true : false,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: NUMBER_REGEX,
                      message: PATTERN_ERR_MSG,
                    },
                  })}
                  disabled={
                    watchIsNewCust !== "newCust" || ptatus === "Published"
                      ? true
                      : false
                  }
                />
              </div>
              {errors.custBookLimit && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.custBookLimit.message}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      <div className="border border-1 rounded bg-white shadow p-3 mt-3">
        <div className="d-block">
          <div className="fw-bold theme-violet-txt pb-3 border-bottom">
            Choose Specific Customer(s){" "}
          </div>

          <div className="overflow-auto max-ht-30">
            <div className="col-md-12  popup-data-table">
              <DataTable
                columns={custColumns}
                data={allCustomers}
                pagination
                striped
                highlightOnHover
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
                customStyles={customDataTableStyles}
                selectableRowSelected={rowSelectCritera}
                clearSelectedRows={toggledClearRows}
                selectableRowDisabled={rowDisabledCriteria}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promotion;

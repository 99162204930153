import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import GetSvgIcon from "../../../images/svglist";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CallApiPost } from "../../Helper/serviceApi";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  BLACK_SPCL_CHARS_STR,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";

const commObj = {
  RowIndex: 0,
  ErrorMessage: "",
  sbuId: 0,
  sbuCode: "",
  baseCharge: "",
  subBreakCharge: "",
};

export const initObj = {
  id: 0,
  vhclType: "",
  vhclTypeId: 0,
  remarks: "",
  commArr: [commObj],
};

const LonghaulAddEdit = ({
  show,
  onHide,
  vhclTypeOpts,
  hubAndDepos,
  sethubAndDepo,
  lhCommission,
  handlePostApi,
}) => {
  const [editComm, setEditComm] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: initObj,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commArr",
  });

  const watchId = watch("id");

  const onSubmit = async (data) => {
    // Transform the data structure to match the API output
    const apiPayload = data.commArr.map((item, index) => ({
      id: data.id,
      vhclType: data.vhclType,
      vhclTypeId: data.vhclTypeId,
      remarks: data.remarks,
      sbuId: item.sbuId,
      sbuCode: item.sbuCode,
      baseCharge: item.baseCharge,
      subBreakCharge: item.subBreakCharge,
      commissionType: 57,
      additionalCharge: 0.0,
      RowIndex: index,
      ErrorMessage: item.ErrorMessage,
    }));
    console.log(apiPayload);
    await editLhComm(apiPayload);
  };

  useEffect(() => {
    reset(lhCommission);
  }, [lhCommission]);

  const editLhComm = async (apiPayload) => {
    const uri = "SaveLongHaulCommCharge";
    let data = await CallApiPost(uri, apiPayload);
    onHide();
    handlePostApi(data);
  };

  const onErr = (err) => {
    console.log(err);
  };

  const handleCLose = () => {
    reset();
    onHide();
  };

  const disableSelectedOpt = (argOpt) => {
    if (!!argOpt) {
      let updHubDepos = [...hubAndDepos];
      const index = updHubDepos.findIndex((opt) => opt.value === argOpt.value);
      updHubDepos[index].isDisabled = true;
      sethubAndDepo([...updHubDepos]);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCLose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="modal-title section-title">
            {/* {vhclTypeId === 0 ? "Add" : "EDIT"} Longhaul Commission */}
            Longhaul Commission
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col md={6}>
              <label
                className="form-label theme-label"
                title="This field is mandatory"
              >
                Vehicle Type <span className="text-danger">&#9733;</span>
              </label>
            </Col>
            <Col md={6}>
              <label className="form-label theme-label">Remarks</label>
            </Col>

            <Col md={6}>
              {watchId > 0 ? (
                <div className="grd-bg p-2 rounded-3">
                  {
                    vhclTypeOpts.find(
                      (el) => el.value === getValues("vhclTypeId")
                    )?.label
                  }
                </div>
              ) : (
                <>
                  <Controller
                    name={"vhclTypeId"}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      min: { value: 1, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={vhclTypeOpts}
                        value={vhclTypeOpts.find((el) => el.value === value)}
                        onChange={(opt) =>
                          !!opt ? onChange(opt.value) : onChange(0)
                        }
                        placeholder="Select Vehicle Type"
                        isSearchable={true}
                        isClearable={true}
                        className="form-control p-0"
                      />
                    )}
                  />
                  {errors?.vhclTypeId && (
                    <div>
                      <span className="text-danger fw-bold fs-6">
                        &#9888; {errors?.vhclTypeId?.message}
                      </span>
                    </div>
                  )}
                </>
              )}
            </Col>
            <Col md={6}>
              <input
                className="form-control"
                name="remarks"
                {...register("remarks", {
                  pattern: {
                    value: ALPHANUM_SPACE_SPCL_CHAR,
                    message:
                      "Remove special character: " + BLACK_SPCL_CHARS_STR,
                  },
                })}
              />
              {errors?.remarks && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.remarks?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-1">
            <div className="tableData">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      Hub/Depot <span className="text-danger">&#9733;</span>{" "}
                    </th>
                    <th scope="col">
                      1st Point Charge{" "}
                      <span className="text-danger">&#9733;</span>{" "}
                    </th>
                    <th scope="col">
                      Drop Point Charge{" "}
                      <span className="text-danger">&#9733;</span>{" "}
                    </th>
                    <th className="col-1">
                      {watchId === 0 && (
                        <div
                          className="px-2 text-success"
                          onClick={() => {
                            append(Object.assign({}, commObj));
                          }}
                        >
                          {GetSvgIcon("PlusCircle")}
                        </div>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((row, index) => (
                    <tr key={row.id}>
                      <td>
                        {watchId > 0 ? (
                          <div className="grd-bg p-2 rounded-3">
                            {
                              hubAndDepos.find(
                                (el) =>
                                  el.value ===
                                  getValues(`commArr.${index}.sbuId`)
                              )?.label
                            }
                          </div>
                        ) : (
                          <>
                            <Controller
                              name={`commArr.${index}.sbuId`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: REQUIRED_ERR_MSG,
                                },
                                min: { value: 1, message: REQUIRED_ERR_MSG },
                              }}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={hubAndDepos}
                                  value={hubAndDepos.find(
                                    (el) => el.value === value
                                  )}
                                  onChange={(opt) => {
                                    onChange(!!opt ? opt.value : 0);
                                    disableSelectedOpt(opt);
                                  }}
                                  placeholder="Select SBU"
                                  isSearchable={true}
                                  isClearable={true}
                                  className="form-control p-0"
                                  isOptionDisabled={(opt) => opt.isDisabled}
                                />
                              )}
                            />
                            {!!errors?.commArr &&
                              errors?.commArr[index]?.sbuId && (
                                <div>
                                  <span className="text-danger fw-bold fs-6">
                                    &#9888;{" "}
                                    {errors?.commArr[index]?.sbuId?.message}
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name={`commArr.${index}.baseCharge`}
                          {...register(`commArr.${index}.baseCharge`, {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            min: {
                              value: 0.01,
                              message: "Enter a positive value",
                            },
                          })}
                        />
                        {!!errors?.commArr &&
                          errors?.commArr[index]?.baseCharge && (
                            <div>
                              <span className="text-danger fw-bold fs-6">
                                &#9888;{" "}
                                {errors?.commArr[index]?.baseCharge?.message}
                              </span>
                            </div>
                          )}
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          name={`commArr.${index}.subBreakCharge`}
                          {...register(`commArr.${index}.subBreakCharge`, {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            min: {
                              value: 0.01,
                              message: "Enter a positive value",
                            },
                          })}
                        />
                        {!!errors?.commArr &&
                          errors?.commArr[index]?.subBreakCharge && (
                            <div>
                              <span className="text-danger fw-bold fs-6">
                                &#9888;{" "}
                                {
                                  errors?.commArr[index]?.subBreakCharge
                                    ?.message
                                }
                              </span>
                            </div>
                          )}
                      </td>
                      {/* Delete button is only rendered for rows other than the first row */}
                      {index > 0 && (
                        <td className="col-1">
                          {watchId === 0 && (
                            <div
                              className="px-2 text-danger"
                              onClick={() => remove(index)}
                            >
                              {GetSvgIcon("DashCircleNoFill")}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* </div> */}
            </div>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <Button
          variant="primary"
          className="save-btn"
          onClick={handleSubmit(onSubmit, onErr)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LonghaulAddEdit;

import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalConfirmAlertCustom = ({
  children,
  confirmAlertConfig,
  hideConfirmAlert,
  confirmAlert,
  confirmBtnName,
  cancelBtnName,
  modalStyle,
}) => {
  return (
    <>
      <Modal
        size={confirmAlertConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmAlertConfig.show}
        onHide={hideConfirmAlert}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={modalStyle?.titelStyle}>{confirmAlertConfig.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">{children}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={hideConfirmAlert}
          >
            {cancelBtnName}
          </button>
          <button
            type="button"
            className="btn btn-primary theme-violet"
            onClick={confirmAlert}
          >
            {confirmBtnName}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirmAlertCustom;

import React from 'react'
import { Button, OverlayTrigger, Popover, Table } from 'react-bootstrap'

export default function BillingMouseOver(props) {
    const {popHeader,target,data,reference,appliedAmount}=props
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{popHeader}</Popover.Header>
            <Popover.Body>
                {
                    
                    data.length > 0
                        ?
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Invoice No.</th>
                                    <th>Invoice Amount</th>
                                    <th>Adjusted Amount</th>
                                  
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((ele, index) => (
                                        <tr key={index}>
                                            <td>{ele.invoiceNo}</td>
                                            <td>{ele.invoiceAmount}</td>
                                            <td>{ele.adjustedAmount}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        : <div className="d-flex justify-content-center">
                            <div className="spinner-border theme-violet-txt" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }
            </Popover.Body>
        </Popover>
    )
  return (
      <OverlayTrigger
            target={target} container={reference} placement="right"
            overlay={popover} 
        >
        <Button variant='light'>{appliedAmount}</Button>
        </OverlayTrigger>
  )
}

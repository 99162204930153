import React, { useState } from "react";
import ModalLayoutFullScreen from "../../Layout/ModalLayoutFullScreen";
import { Button, Col, Row } from "react-bootstrap";

const PlannedOrderSummary = ({ handleMoalClose, moadlConfig }) => {
  const header = (
    <>
      <h5 className="theme-violet-txt">ORDER SUMMARY</h5>
    </>
  );
  const footer = (
    <>
      <Button variant="secondary" onClick={handleMoalClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleMoalClose}>
        Save
      </Button>
    </>
  );
  return (
    <ModalLayoutFullScreen
      footer={footer}
      moadlConfig={moadlConfig}
      handleMoalClose={handleMoalClose}
      header={header}
    >
      <Row>
        <Col md={8}>
          <Row>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Trip Date</div>
                <div class="theme-blue-txt">Trip_Date</div>
              </div>
            </Col>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Vehicle Type</div>
                <div class="theme-blue-txt">Vehicle_Type</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Origin</div>
                <div class="theme-blue-txt">Trip_Date</div>
              </div>
            </Col>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Destination</div>
                <div class="theme-blue-txt">Trip_Date</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Trip Date</div>
                <div class="theme-blue-txt">Trip_Date</div>
              </div>
            </Col>
            <Col>
              <div class="col grd-bg p-2 p my-2 rounded-3">
                <div class="fw-bold">Trip Date</div>
                <div class="theme-blue-txt">Trip_Date</div>
              </div>
            </Col>
          </Row>
          {/* <CustomerOrderAttachment
            showAlert={showAlert}
            setShowSpinner={setShowSpinner}
          /> */}
        </Col>
        <Col md={4}>
          <div className="filled-view-blue bill-details-section ">
            <div className="subheadingtext mb-2 d-flex justify-content-between align-item-center">
              <div className="font-18">Fare Summary</div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-2">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Bill Number</td>
                      <td>abcdqwertyuiop</td>
                    </tr>
                    <tr>
                      <td>Billing Date</td>
                      <td>abcdqwertyuiop</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <span className="fw-bold">Sub Total </span>
                        <span className="font-12">(Excluding SST)</span>
                      </td>
                      <td>abcdqwertyuiop</td>
                    </tr>
                    <tr>
                      <td>SST </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Total Cost </span>
                        <span className="font-12 font-weight-normal">
                          (Including SST)
                        </span>
                      </td>
                      <td>abcdqwertyuiop</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </ModalLayoutFullScreen>
  );
};

export default PlannedOrderSummary;

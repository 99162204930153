import React, { useEffect, useState } from "react";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  COUNTRY_CODES,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import { Controller } from "react-hook-form";
import GetSvgIcon from "../../../images/svglist";
import Select from "react-select";
import {
  DELIVERY_SERVICE_ID,
  PICKUP_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";

const BookingAddress = (props) => {
  const {
    register,
    clearErrors,
    errors,
    control,
    stateData,
    filterCity,
    senderCityData,
    filterState,
    setModalData,
    trigger,
    saveAddressBook,
    avalServices,
    recipientCityData,
    bookingType,
  } = props;
  const [services, setServices] = useState({
    isPickup: false,
    isDelivery: false,
  });

  useEffect(() => {
    const selectedServices = {
      isPickup: avalServices?.find((srvc) => srvc.value === PICKUP_SERVICE_ID)
        ?.isChecked,
      isDelivery: avalServices?.find(
        (srvc) => srvc.value === DELIVERY_SERVICE_ID
      )?.isChecked,
    };

    setServices(selectedServices);
  }, [avalServices]);

  return (
    <>
      <div className="w-100 ">
        <div className="col-md-12 mb-3">
          <div className="bg-white border border-2 rounded-2 p-2">
            <div className="subheadingtext">Sender Details</div>
            <div className="row">
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Name
                </label>
                <div>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderName", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM_SPACE_SPCL_CHAR,
                        message: PATTERN_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderName"),
                    })}
                  />
                  {errors.senderName && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderName.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Phone
                </label>
                <div className="input-group">
                  <select
                    name="senderPhoneCode"
                    {...register("senderPhoneCode", {
                      onChange: () => clearErrors("senderPhoneCode"),
                    })}
                    defaultValue="+60"
                    className="form-control country-code-customize"
                  >
                    {COUNTRY_CODES.map((cc, index) => (
                      <option key={index} value={cc.value}>
                        {cc.label}
                      </option>
                    ))}
                  </select>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderPhone", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: "Incorrect Phone Number!!!",
                      },
                      onChange: () => clearErrors("senderPhone"),
                    })}
                  />
                </div>
                {errors.senderPhone && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.senderPhone.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("senderAddress", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    // pattern: {
                    //   value: ALPHANUM_SPACE_SPCL_CHAR,
                    //   message: PATTERN_ERR_MSG,
                    // },
                    onChange: () => clearErrors("senderAddress"),
                  })}
                />
                {errors.senderAddress && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.senderAddress.message}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  State
                </label>
                <div>
                  <Controller
                    name="senderState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={stateData}
                        placeholder="Select State"
                        value={
                          value
                            ? stateData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(
                            d ? d.value : d,
                            filterCity(d ? d.value : d, "senderCity")
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderState"),
                    }}
                  />
                  {errors.senderState && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderState.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  City
                </label>
                <div>
                  <Controller
                    name="senderCity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={senderCityData}
                        placeholder="Select City"
                        // value={value ? value.value : value}
                        value={
                          value
                            ? senderCityData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(
                            d ? d.value : d,
                            filterState(d ? d.value : d, "senderState")
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderCity"),
                    }}
                  />
                  {errors.senderCity && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderCity.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 my-2 ">
                <label htmlFor="" className="form-label theme-label">
                  Postcode
                </label>
                <div>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderPostcode", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: PATTERN_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderPostcode"),
                      // onBlur: (e) =>
                      //   getByPostCode(e.target.value),
                    })}
                  />
                  {errors.senderPostcode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderPostcode.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col text-end my-2">
              <button
                className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                type="button"
                onClick={() => setModalData("sender")}
              >
                {GetSvgIcon("Checkborderedcircle")} Address Book
              </button>
              <button
                className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={async () => {
                  const result = await trigger([
                    "senderPostcode",
                    "senderCity",
                    "senderState",
                    "senderAddress",
                    "senderPhone",
                    "senderName",
                  ]);
                  if (result) saveAddressBook("sender");
                }}
              >
                {GetSvgIcon("IconOrder")} Save Address
              </button>
            </div>
          </div>
        </div>

        {services.isPickup && (
          <div className="col-md-12 mb-3">
            <div className="bg-white border border-2 rounded-2 p-2">
              <div className="row">
                <div className="subheadingtext col">Pickup Details</div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Pickup Name
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("pickupName", {
                        required: {
                          value: services?.isPickup,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("pickupName"),
                      })}
                    />
                    {errors.pickupName && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupName.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Pickup Phone
                  </label>
                  <div className="input-group ">
                    <select
                      name="pickupPhoneCode"
                      {...register("pickupPhoneCode")}
                      defaultValue="+60"
                      className="form-control country-code-customize"
                    >
                      {COUNTRY_CODES.map((cc, index) => (
                        <option key={index} value={cc.value}>
                          {cc.label}
                        </option>
                      ))}
                    </select>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("pickupPhone", {
                        required: {
                          value: services?.isPickup,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: "Incorrect Phone Number!!!",
                        },
                        onChange: () => clearErrors("pickupPhone"),
                      })}
                    />
                  </div>
                  {errors.pickupPhone && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.pickupPhone.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Pickup Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("pickupAddress", {
                      required: {
                        value: services?.isPickup,
                        message: REQUIRED_ERR_MSG,
                      },
                      // pattern: {
                      //   value: ALPHANUM_SPACE_SPCL_CHAR,
                      //   message: PATTERN_ERR_MSG,
                      // },
                      onChange: () => clearErrors("pickupAddress"),
                    })}
                    readOnly
                  />
                  {errors.pickupAddress && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.pickupAddress.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    State
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("pickupState", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("pickupState"),
                      })}
                      readOnly
                    />
                    {errors.pickupState && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupState.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    City
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("pickupCity", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("pickupCity"),
                      })}
                      readOnly
                    />
                    {errors.pickupCity && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupCity.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <label htmlFor="" className="form-label theme-label">
                    Postcode
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("pickupPostcode", {
                        required: {
                          value: services?.isPickup,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("pickupPostcode"),
                      })}
                      readOnly
                    />
                    {errors.pickupPostcode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupPostcode.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col text-end my-2">
                {/* <button
                  className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                  type="button"
                  onClick={() => setModalData("pickup")}
                >
                  {GetSvgIcon("Checkborderedcircle")} Address Book
                </button> */}
                <button
                  className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                  type="button"
                  onClick={async () => {
                    const result = await trigger([
                      "pickupPostcode",
                      "pickupCity",
                      "pickupState",
                      "pickupAddress",
                      "pickupPhone",
                      "pickupName",
                    ]);
                    if (result) saveAddressBook("pickup");
                  }}
                >
                  {GetSvgIcon("IconOrder")} Save Address
                </button>
              </div>
            </div>
          </div>
        )}

        {!services?.isDelivery && (
          <div className="col-md-12 mb-3">
            <div className="bg-white border border-2 rounded-2 p-2">
              <div className="subheadingtext">Receiver Details</div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Recipient Name
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("recipientName", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("recipientName"),
                      })}
                    />
                    {errors.recipientName && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.recipientName.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Recipient Phone
                  </label>
                  <div className="input-group ">
                    <select
                      name="recipientPhoneCode"
                      {...register("recipientPhoneCode")}
                      defaultValue="+60"
                      className="form-control country-code-customize"
                    >
                      {COUNTRY_CODES.map((cc, index) => (
                        <option key={index} value={cc.value}>
                          {cc.label}
                        </option>
                      ))}
                    </select>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("recipientPhone", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: "Incorrect Phone Number!!!",
                        },
                        onChange: () => clearErrors("recipientPhone"),
                      })}
                    />
                  </div>
                  {errors.recipientPhone && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.recipientPhone.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Receiver Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("recipientAddress", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      // pattern: {
                      //   value: ALPHANUM_SPACE_SPCL_CHAR,
                      //   message: PATTERN_ERR_MSG,
                      // },
                      onChange: () => clearErrors("recipientAddress"),
                    })}
                  />
                  {errors.recipientAddress && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.recipientAddress.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    State
                  </label>
                  <div>
                    <Controller
                      name="recipientState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={stateData}
                          placeholder="Select State"
                          value={
                            value
                              ? stateData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterCity(d ? d.value : d, "recipientCity")
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                          menuPlacement="auto"
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("recipientState"),
                      }}
                    />
                    {errors.recipientState && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.recipientState.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    City
                  </label>
                  <div>
                    <Controller
                      name="recipientCity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={recipientCityData}
                          placeholder="Select City"
                          value={
                            value
                              ? recipientCityData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterState(d ? d.value : d, "recipientState")
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                          menuPlacement="auto"
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("recipientCity"),
                      }}
                    />
                    {errors.recipientCity && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.recipientCity.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <label htmlFor="" className="form-label theme-label">
                    Postcode
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("recipientPostcode", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("recipientPostcode"),
                      })}
                    />
                    {errors.recipientPostcode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.recipientPostcode.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Recipient IC
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("recipientIC", {
                        required: {
                          value: false,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("recipientIC"),
                      })}
                    />
                    {errors.recipientIC && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.recipientIC.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col text-end my-2 mt-5">
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                    type="button"
                    onClick={() => setModalData("recipient")}
                  >
                    {GetSvgIcon("Checkborderedcircle")} Address Book
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    onClick={async () => {
                      const result = await trigger([
                        "recipientPostcode",
                        "recipientCity",
                        "recipientState",
                        "recipientAddress",
                        "recipientPhone",
                        "recipientName",
                      ]);
                      if (result) saveAddressBook("recipient");
                    }}
                  >
                    {GetSvgIcon("IconOrder")} Save Address
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {services?.isDelivery && (
          <div className="col-md-12 mb-3">
            <div className="bg-white border border-2 rounded-2 p-2">
              <div className="row">
                <div className="subheadingtext col">Delivery Details</div>
              </div>

              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Delivery Name
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("deliveryName", {
                        required: {
                          value: services?.isDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("deliveryName"),
                      })}
                    />
                    {errors.deliveryName && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryName.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Delivery Phone
                  </label>
                  <div className="input-group ">
                    <select
                      name="deliveryPhoneCode"
                      {...register("deliveryPhoneCode")}
                      defaultValue="+60"
                      className="form-control country-code-customize"
                    >
                      {COUNTRY_CODES.map((cc, index) => (
                        <option key={index} value={cc.value}>
                          {cc.label}
                        </option>
                      ))}
                    </select>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("deliveryPhone", {
                        required: {
                          value: services?.isDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: "Incorrect Phone Number!!!",
                        },
                        onChange: () => clearErrors("deliveryPhone"),
                      })}
                    />
                  </div>
                  {errors.deliveryPhone && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.deliveryPhone.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    Delivery Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("deliveryAddress", {
                      required: {
                        value: services?.isDelivery,
                        message: REQUIRED_ERR_MSG,
                      },
                      // pattern: {
                      //   value: ALPHANUM_SPACE_SPCL_CHAR,
                      //   message: PATTERN_ERR_MSG,
                      // },
                      onChange: () => clearErrors("deliveryAddress"),
                    })}
                    readOnly
                  />
                  {errors.deliveryAddress && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.deliveryAddress.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    State
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("deliveryState", {
                        required: {
                          value: services?.isDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("deliveryState"),
                      })}
                      readOnly
                    />
                    {errors.deliveryState && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryState.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mt-2">
                  <label htmlFor="" className="form-label theme-label">
                    City
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("deliveryCity", {
                        required: {
                          value: services?.isDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("deliveryCity"),
                      })}
                      readOnly
                    />
                    {errors.deliveryCity && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryCity.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 my-2">
                  <label htmlFor="" className="form-label theme-label">
                    Postcode
                  </label>
                  <div>
                    <input
                      id=""
                      type="text"
                      className="form-control"
                      name=""
                      {...register("deliveryPostcode", {
                        required: {
                          value: services?.isDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: NUMBER_REGEX,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("deliveryPostcode"),
                      })}
                      readOnly
                    />
                    {errors.deliveryPostcode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryPostcode.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col text-end my-2">
                {/* <button
                  className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                  type="button"
                  onClick={() => setModalData("delivery")}
                >
                  {GetSvgIcon("Checkborderedcircle")} Address Book
                </button> */}
                <button
                  className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                  type="button"
                  onClick={async () => {
                    const result = await trigger([
                      "deliveryPostcode",
                      "deliveryCity",
                      "deliveryState",
                      "deliveryAddress",
                      "deliveryPhone",
                      "deliveryName",
                    ]);
                    if (result) saveAddressBook("delivery");
                  }}
                >
                  {GetSvgIcon("IconOrder")} Save Address
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {bookingType === "chartered" && (
        <div>
          <div className="w-100 d-block">
            <div className="bg-white border border-2 rounded-2 my-3">
              <div className="col-lg-12">
                <div className="subheadingtext p-3">Sepcial permission</div>

                <div className="row">
                  <div className="col">
                    <div className="col align-self-center mx-3 my-2 ">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input
                            className="form-check-input formsty"
                            type="checkbox"
                            name=""
                            id=""
                            value="specialPickup"
                            // value="option1"
                            {...register("specialPickup")}
                            // disabled={!avalServices?.pickup}
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value="Does the driver need special permission for pickup?"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="col align-self-center mx-3 my-2 ">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input
                            className="form-check-input formsty"
                            type="checkbox"
                            name=""
                            id=""
                            value="specialDelivery"
                            // value="option1"
                            {...register("specialDelivery")}
                            // disabled={!avalServices?.pickup}
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value="Does the driver need special permission for Delivary?"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingAddress;

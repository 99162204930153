import moment from "moment";
import GetSvgIcon, {
  GetSvgBuilding,
  GetSvgPerson,
  GetSvgPhone,
} from "../../../images/svglist";
import { DATE_FORMAT_MOMENT, DATE_FORMAT_SERVER } from "../../Helper/Constants";
import { useEffect, useState } from "react";
import {
  DELIVERY_SERVICE_ID,
  PICKUP_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";

const OrderSummary = (props) => {
  const {
    getValues,
    avalServices,
    onTermConditionChecked,
    orgDestAddrs,
    options,
    bookingType,
    ftlDatas,
  } = props;
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    const tempArr = avalServices
      ?.filter((obj) => obj.isChecked === true)
      ?.map((e) => e.label);
    setSelectedServices(tempArr?.join(", "));
  }, [avalServices]);

  return (
    <>
      <div className="container-fluid  px-0 wt-tbl">
        <div className="row pt-1 mx-0">
          <div className="col-md-12 px-0">
            <div className="text-danger fw-bold fst-italic">
              * Please make sure the order details are correct before{" "}
              {bookingType === "chartered"
                ? " proceeding"
                : " proceed for payment."}
            </div>
            {bookingType === "standard" && (
              <div className="bg-white border border-2 rounded-2 mb-3">
                <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                  Booking Details
                </div>
                <div className="p-2">
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Origin</div>
                      <div className="theme-blue-txt">
                        <span className="text-info">
                          {GetSvgIcon("BiGeoAltFillDanger")}
                        </span>{" "}
                        {getValues("origin")}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Destination</div>
                      <div className="theme-blue-txt">
                        <span className="text-success">
                          {GetSvgIcon("BiGeoAltFillSuccess")}
                        </span>{" "}
                        {getValues("destination")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Service</div>
                      <div className="theme-blue-txt">
                        {getValues("bkngService")}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Shipping Date</div>
                      <div className="theme-blue-txt">
                        {moment(getValues("shipmentDate")).format(
                          DATE_FORMAT_MOMENT
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Option</div>
                      <div className="theme-blue-txt">
                        {
                          options?.find(
                            (opt) => opt.value === getValues("option")
                          )?.label
                        }
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Pallets</div>
                      <div className="theme-blue-txt">
                        {getValues("pallets")}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Selected Services</div>
                      <div>
                        <span className="theme-blue-txt">
                          {selectedServices}
                        </span>
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Value Added Services</div>
                      <div>
                        <span className="theme-blue-txt">
                          {!!getValues("picHandlingPallets")
                            ? "Pickup Handling: " +
                              getValues("picHandlingPallets") +
                              " pallet(s)"
                            : "Pickup Handling: N/A"}
                          {""}
                          {!!getValues("delHandlingPallets")
                            ? ", Delivery Handling: " +
                              getValues("delHandlingPallets") +
                              " pallet(s)"
                            : ", Delivery Handling: N/A"}
                          {""}
                          {!!getValues("packagingPallets")
                            ? ", Packaging:  " +
                              getValues("packagingPallets") +
                              " pallet(s) "
                            : ", Packaging:  N/A"}{" "}
                          {!!getValues("dor") ? ", Delivery Order Return" : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {bookingType === "chartered" && (
              <div className="bg-white border border-2 rounded-2 mb-3">
                <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                  Vehicle{" "}
                </div>
                <div className="p-2">
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Shipping Date</div>
                      <div className="theme-blue-txt">
                        {ftlDatas.shipDate
                          ? moment(new Date(ftlDatas.shipDate)).format(
                              DATE_FORMAT_MOMENT
                            )
                          : ""}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Vehicle Type</div>
                      <div className="theme-blue-txt">
                        {ftlDatas.vehicleTypeName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white border border-2 rounded-2 mb-3">
              <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Address Info
              </div>
              <div className="p-2">
                <div className="d-flex justify-content-between align-item-center">
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Sender</div>
                    <div className="theme-blue-txt">
                      <span className="theme-blue-txt">
                        {GetSvgPerson(false)}
                      </span>{" "}
                      {getValues("senderName")}
                    </div>
                    <div className="theme-blue-txt font-12 mt-2">
                      <span className="theme-blue-txt me-2">
                        {GetSvgPhone(false)}
                      </span>
                      {getValues("senderPhoneCode") +
                        "-" +
                        getValues("senderPhone")}
                    </div>
                    <div className="theme-blue-txt d-flex mt-2">
                      <span className="theme-blue-txt me-2">
                        {GetSvgBuilding(false)}
                      </span>
                      <div className="font-12">
                        {getValues("senderAddress") +
                          ", " +
                          getValues("senderCity") +
                          ", " +
                          getValues("senderState") +
                          ", " +
                          getValues("senderPostcode")}
                      </div>
                    </div>
                  </div>
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Recipient</div>

                    {avalServices.find(
                      (obj) =>
                        obj.value === DELIVERY_SERVICE_ID && obj.isChecked
                    ) ? (
                      <>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt">
                            {GetSvgIcon("LocPin")}
                          </span>{" "}
                          {getValues("deliveryName")}
                        </div>
                        <div className="theme-blue-txt mt-2 font-12">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>
                          {getValues("deliveryPhoneCode") +
                            "-" +
                            getValues("deliveryPhone")}
                        </div>
                        <div className="theme-blue-txt d-flex mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {getValues("deliveryAddress") +
                              ", " +
                              getValues("deliveryCity") +
                              ", " +
                              getValues("deliveryState") +
                              ", " +
                              getValues("deliveryPostcode")}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt">
                            {GetSvgPerson(false)}
                          </span>{" "}
                          {getValues("recipientName")}
                        </div>
                        <div className="theme-blue-txt mt-2 font-12">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>
                          {getValues("recipientPhoneCode") +
                            "-" +
                            getValues("recipientPhone")}
                        </div>
                        <div className="theme-blue-txt d-flex mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {getValues("recipientAddress") +
                              ", " +
                              getValues("recipientCity") +
                              ", " +
                              getValues("recipientState") +
                              ", " +
                              getValues("recipientPostcode")}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {bookingType === "standard" &&
                  (avalServices.find(
                    (obj) =>
                      (obj.value === PICKUP_SERVICE_ID && obj.isChecked) ||
                      (obj.value === DELIVERY_SERVICE_ID && obj.isChecked)
                  ) ? (
                    <div className="text-danger fw-bold fst-italic mt-3">
                      * Please check the information carefully!!
                    </div>
                  ) : (
                    <div className="text-danger fw-bold fst-italic mt-3">
                      * You have selected Lodge in and Self collect. Please
                      check the information carefully!!
                    </div>
                  ))}

                <div className="d-flex justify-content-between align-item-center">
                  {avalServices.find(
                    (obj) => obj.value === PICKUP_SERVICE_ID && obj.isChecked
                  ) ? (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Pickup</div>
                      <div className="theme-blue-txt">
                        <span className="theme-blue-txt">
                          {GetSvgIcon("LocPin")}
                        </span>{" "}
                        {getValues("pickupName")}
                      </div>
                      <div className="theme-blue-txt font-12 mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgPhone(false)}
                        </span>
                        {getValues("pickupPhoneCode") +
                          "-" +
                          getValues("pickupPhone")}
                      </div>
                      <div className="theme-blue-txt d-flex mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgBuilding(false)}
                        </span>
                        <div className="font-12">
                          {getValues("pickupAddress") +
                            ", " +
                            getValues("pickupCity") +
                            ", " +
                            getValues("pickupState") +
                            ", " +
                            getValues("pickupPostcode")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div>
                        <span className="fw-bold me-3">
                          Lodge in{" "}
                          <span className="mx-2 text-danger">
                            HUB: {orgDestAddrs?.originCode}
                          </span>{" "}
                        </span>
                        <span className="fst-italic me-2">Lodge in before</span>
                        <span className="cursor-pointer badge rounded-pill bg-danger me-2">
                          {" "}
                          {!!getValues("lodgeIn")
                            ? moment(getValues("lodgeIn"), "HH:mm:ss").format(
                                "HH:mm"
                              )
                            : "-NA-"}{" "}
                        </span>
                        <span className="cursor-pointer badge rounded-pill bg-danger">
                          {moment(
                            getValues("shipmentDate"),
                            DATE_FORMAT_SERVER,
                            true
                          ).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div className="theme-blue-txt">
                        <span className="theme-blue-txt">
                          {GetSvgIcon("LocPin")}
                        </span>{" "}
                        {orgDestAddrs?.origingName}
                      </div>
                      <div className="theme-blue-txt font-12 mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgPhone(false)}
                        </span>
                        {orgDestAddrs?.origingPhone.substr(0, 3) +
                          "-" +
                          orgDestAddrs?.origingPhone.substr(3)}
                      </div>
                      <div className="theme-blue-txt d-flex mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgBuilding(false)}
                        </span>
                        <div className="font-12">
                          {orgDestAddrs?.origingAddress +
                            ", " +
                            orgDestAddrs?.origingCity +
                            ", " +
                            orgDestAddrs?.origingState +
                            ", " +
                            orgDestAddrs?.originPostCode}
                        </div>
                      </div>
                    </div>
                  )}
                  {avalServices.find(
                    (obj) => obj.value === DELIVERY_SERVICE_ID && obj.isChecked
                  ) ? (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Delivery</div>
                      <div className="theme-blue-txt">
                        <span className="theme-blue-txt">
                          {GetSvgIcon("LocPin")}
                        </span>{" "}
                        {getValues("deliveryName")}
                      </div>
                      <div className="theme-blue-txt mt-2 font-12">
                        <span className="theme-blue-txt me-2">
                          {GetSvgPhone(false)}
                        </span>
                        {getValues("deliveryPhoneCode") +
                          "-" +
                          getValues("deliveryPhone")}
                      </div>
                      <div className="theme-blue-txt d-flex mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgBuilding(false)}
                        </span>
                        <div className="font-12">
                          {getValues("deliveryAddress") +
                            ", " +
                            getValues("deliveryCity") +
                            ", " +
                            getValues("deliveryState") +
                            ", " +
                            getValues("deliveryPostcode")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div>
                        <span className="fw-bold me-3">
                          Self Collect{" "}
                          <span className="ms-2 text-danger">
                            HUB: {orgDestAddrs?.destinationCode}
                          </span>
                        </span>
                        <span
                          className="cursor-pointer badge rounded-pill bg-danger ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#button-modal"
                        >
                          {!!getValues("freeStrgDays")
                            ? "Within " + getValues("freeStrgDays") + " Days"
                            : "-NA-"}
                        </span>
                      </div>
                      <div className="theme-blue-txt">
                        <span className="theme-blue-txt">
                          {GetSvgIcon("LocPin")}
                        </span>{" "}
                        {orgDestAddrs?.destinationName}
                      </div>
                      <div className="theme-blue-txt font-12 mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgPhone(false)}
                        </span>
                        {orgDestAddrs?.destinationPhone.substr(0, 3) +
                          "-" +
                          orgDestAddrs?.origingPhone.substr(3)}
                      </div>
                      <div className="theme-blue-txt d-flex mt-2">
                        <span className="theme-blue-txt me-2">
                          {GetSvgBuilding(false)}
                        </span>
                        <div className="font-12">
                          {orgDestAddrs?.destinationAddress +
                            ", " +
                            orgDestAddrs?.destinationCity +
                            ", " +
                            orgDestAddrs?.destinationState +
                            ", " +
                            orgDestAddrs?.destinationPostCode}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {bookingType === "standard" && getValues("selectedInsurace") && (
              <div className="bg-white border border-2 rounded-2 mb-3">
                <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                  Protection Info
                </div>
                <div className="p-2">
                  <div className="d-flex justify-content-between align-item-center">
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Package Id</div>
                      <div className="theme-blue-txt">
                        {getValues("selectedInsurace")
                          ? getValues("selectedInsurace")[0]?.packageId
                          : ""}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Coverage Upto</div>
                      <div className="theme-blue-txt">
                        {getValues("selectedInsurace")
                          ? "RM " + getValues("selectedInsurace")[0]?.coverage
                          : ""}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Insurance Fee</div>
                      <div className="theme-blue-txt">
                        {getValues("selectedInsurace")
                          ? "RM " + getValues("selectedInsurace")[0]?.price
                          : ""}
                      </div>
                    </div>
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="fw-bold">Insurance Declared Price</div>
                      <div className="theme-blue-txt">
                        {getValues("insDeclaredPrice")
                          ? "RM " + getValues("insDeclaredPrice")
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="bg-white border border-2 rounded-2 p-2 mb-3">
              <div className="theme-blue-txt fw-bold mb-2">
                General Instruction
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 px-4">
            <div className="text-start text-danger">
              <input
                type="checkbox"
                id="term"
                onChange={(e) => onTermConditionChecked(e.target.checked)}
              />
              <label
                className="ms-2 theme-blue-txt"
                style={{ textDecoration: "none", color: "red" }}
              >
                <a
                  href="https://linehaul.com.my/doc/Terms_and_Conditions.pdf"
                  className="theme-blue-txt"
                  target="blank"
                >
                  Please Accept Terms and Conditions
                </a>
              </label>
            </div>
          </div>
        </div>

        {/* <div className="row">
            <div className='col-md-12 my-3'>
              <div className='text-center'>
              <button className="btn btn-lg btn-outline-secondary theme-violet text-nowrap" type="submit" >
              Proceed for Payment
              </button>
              </div>
            </div>
          </div> */}
      </div>
    </>
  );
};

export default OrderSummary;

import React from "react";
import "./CustomAlert.css";

const CustomAlert = ({ toggleReset, alertTitle, alertBody, callback }) => {
  return (
    <div className="alert-popup-box">
      <div className="alert-box">
        <div className="modal-content">
          <div className="modal-header header-style p-3">
            <h5 className="modal-title" id="alertLabel">
              {alertTitle}
            </h5>
            <button
              type="button"
              className="btn-close ms-auto"
              aria-label="Close"
              onClick={() => toggleReset(false)}
            ></button>
          </div>
          <div className="alert-content-body">
            <p className="text-center">{alertBody}</p>
          </div>
          <div className="modal-footer footer-style p-3">
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => toggleReset(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary theme-violet"
              onClick={callback}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;

import Barcode from 'react-barcode'
import React from 'react'

export default function BarCodeGenerator(props) {
  return (
    <div>
      <Barcode value={props.value} width={1} height={30}/>
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { toBase64 } from "../../Helper/CommonMethods";
import {
  DELIVERYSTATUSCODE,
  customDataTableStyles,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import { initValShipmentDtls } from "../../OrderManagement/initValShipmentDtls";
import Spinner from "../../Layout/Spinner";
import DataTable from "react-data-table-component-with-filter";
import { DownloadFile } from "../../Helper/FileStoreService";

const UploadBookingDoc = (props) => {
  const [specialContentAttachment, setSpecialContentAttachment] = useState([]);
  const [pickupFileName, setPickupFileName] = useState([]);
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [attachmentDesc, setAttachmentDesc] = useState([]);
  const [defaultFileName, setDefaultFileName] = useState("Upload Doc");
  const [show, setShow] = useState(false);

  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [waybills, setWaybills] = useState([]);
  const [shipPallets, setShipPallets] = useState([])
  const fileRef = useRef();
  const decpRef = useRef();
  const { Buffer } = require("buffer");
  const { onSuccess, shipmentDtls, setShipmentDtls } = props;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // console.log("orderId: " + props.orderId);
    getShipmetDtls(props.orderId);
    setValue("question1", "no");
    setValue("question2", "no");
    setValue("question3", "no");
  }, [props.orderId]);

  const columns = [
    {
      name: "Waybill Number",
      selector: (row) => row.waybillNo,
      // sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.contentDesc,
      // sortable: true,
    },
  ];

  const getShipmetDtls = async (shipmentId) => {
    // console.log("shipmentId", shipmentId);
    let data = await CallApiGet("getShipmentDetailsById/" + shipmentId);
    if (data.respCode === 200) {
      setShipmentDtls(data.respData);
      const shipPlts = data?.respData?.shipmentPallets.map(plt => ({
        ...plt,
        contentDesc: data.respData.packageDesc
      }))
      setShipPallets(shipPlts)
      getInitialData(data.respData);
    }
  };

  const getInitialData = async (bookingData) => {
    setOrderAttachments([...bookingData.attachments]);
  };



  const downloadWebBill = async () => { 
    setShow(true);
    if (waybills.length > 0) {
      const payload = {
        shipmentIdList: [props.orderId],
        palletIdList: waybills,
        isSearchByOrder: false
      }
      const resp = await CallApiPost("printWaybill", payload);
      setShow(false)
      if (resp.respCode === 200) {
        window.open(resp.respData, "_blank");
      }
    }
    
  }

  const handelOnSubmit = async (data) => {
    setShow(true);
    data.specialContentFiles = specialContentAttachment;
    data.orderAttachments = orderAttachments;
    data.pickupLodgein = getValues("pickupTime");
    data = updateData(data, shipmentDtls);
    shipmentDtls.orderInfos = data.orderInfo;
    delete shipmentDtls.waybillList


    let resp = await CallApiPost("updateShipmentDoc", shipmentDtls);
    setShow(false);

    if (resp.respCode === 200) {
      clearData();
      onSuccess("Booking details Created successfully");
    } else if (resp.respCode === 500) {
    }
  };
  const clearData = () => {
    reset(initValShipmentDtls);
  };
  const handelFileSelect = (event) => {
    const file = event.target.files;
    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      setDefaultFileName(fileName);
    }
  };

  const updateData = (data) => {
    let orderInnfo = [];

    orderInnfo.push({
      infoCode: 175,
      infoValue: getValues("question1"),
      docBase64: getValues("question1") === "yes" ? data.qstAttach1.docBase64 : "",
      description: data.qstDesp1,
      fileName: getValues("question1") === "yes" ? data.qstAttach1.fileName : "",
    });

    orderInnfo.push({
      infoCode: 176,
      infoValue: getValues("question2"),
      docBase64: getValues("question2") === "yes" ? data.qstAttach2.docBase64 : "",
      description: data.qstDesp2,
      fileName: getValues("question2") === "yes" ? data.qstAttach2.fileName : "",
    });

    orderInnfo.push({
      infoCode: 177,
      infoValue: getValues("question3"),
      docBase64: getValues("question3") === "yes" ? data.qstAttach3.docBase64 : "",
      description: data.qstDesp3,
      fileName: getValues("question3") === "yes" ? data.qstAttach3.fileName1 : "",
    });

    data.orderInfo = orderInnfo;
    //
    return data;
  };

  const handelOrderAttachment = async () => {
    const file = document.getElementById("attachmentFile").files;
    const desp = document.getElementById("decp").value;
    let fileNameArry = [...pickupFileName];
    let tempDescArr = [...attachmentDesc];

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      fileNameArry.push(fileName);
      setPickupFileName([...fileNameArry]);
      tempDescArr.push(desp);
      setAttachmentDesc([...tempDescArr]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        docBase64: photo,
        fileName: fileName,
        description: desp,
      };
      let attachment = orderAttachments;
      attachment.push(newAttachment);
      setOrderAttachments(attachment);
      document.getElementById("attachmentFile").value = "";
      document.getElementById("decp").value = "";
      setDefaultFileName("Upload Doc");
      let freshAttahcment = [];

      freshAttahcment.push(newAttachment);
      shipmentDtls.attachments = freshAttahcment;
      shipmentDtls.waybillList = []
      // console.log(shipmentDtls);

      let data = await CallApiPost("addOrderAttachment", shipmentDtls);

      // if (data.respCode === 200) {
      //   getShipmetDtls(props.orderId);
      // }
    }
  };

  const err = (errors) => {
    console.log(errors);
  };




  const handleChange = ({ selectedRows }) => {
    // console.log("Selected Rows: ", selectedRows);
    let data = [];
    selectedRows?.map((row) => {
      // data.push(row.waybillNo);
      data.push(row?.palletId)
    });
    // console.log("list", data);
    setWaybills(data);
  };
  const onError = (errMsg) => {
    console.log("errMsg", errMsg)
  };
  return (
    <div>
      {/* {shipmentDtls.shipmentVas.map(() => { */}
      <>
        <div className="wt-tbl border border-2 rounded-2 p-2 mb-3">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col">
                  <div className="float-end">
                    <button
                      className="btn btn-sm btn-outline-secondary theme-violet"
                      onClick={downloadWebBill}
                      disabled={waybills.length === 0 ? true : false}
                    >
                      {GetSvgIcon("Print")} Print WB
                    </button>
                  </div>
                  <div className="col-md-12  popup-data-table">
                    <DataTable
                      columns={columns}
                      // data={shipmentDtls.shipmentPallets}
                      data={shipPallets}
                      pagination
                      striped
                      highlightOnHover
                      customStyles={customDataTableStyles}
                      selectableRows
                      onSelectedRowsChange={handleChange}
                      clearSelectedRows={toggledClearRows}
                      fixedHeader
                      fixedHeaderScrollHeight="400px"
                      paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                      paginationPerPage={5}
                    />
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>

      </>

      <>
        <div className="mb-1 mt-3">Order Attachment </div>
        <div className="wt-tbl border border-2 rounded-2 p-2">
          <div className="row">
            <div className="col-md-12 mb-2">
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-md-5">
                  <div>
                    <label className="form-label theme-label" htmlFor="">
                      Select Doc
                    </label>
                  </div>
                  <div className="Upload-Photo mb-2 w-100">
                    <input
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg"
                      id="attachmentFile"
                      ref={fileRef}
                      onChange={(e) => handelFileSelect(e)}
                    />
                    <div className="uploaded-text ms-2 w-100">
                      {GetSvgUpload(true)}
                      {defaultFileName}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div>
                    <label className="form-label theme-label" htmlFor="">
                      Add description
                    </label>
                  </div>
                  <input
                    id="decp"
                    type="text"
                    className="form-control"
                    name="description"
                    ref={decpRef}
                  />
                </div>
                <div className="col-md-2">
                  <div className="mt-1">
                    <label className="form-label theme-label" htmlFor=""></label>
                  </div>
                  {shipmentDtls.status !== DELIVERYSTATUSCODE && (
                    <button
                      className="btn btn-outline-secondary theme-violet px-4"
                      type="button"
                      onClick={handelOrderAttachment}
                    >
                      ADD
                    </button>
                  )}
                </div>
              </div>

              <table className="table">
                <tbody>
                  {orderAttachments.map((item, index) => (
                    <tr key={index}>
                      <td className="col-md-5">
                        <div className="w-75 break-word">{item.fileName}</div>
                      </td>
                      <td className="col-md-5">
                        {item.description}
                      </td>
                      <td>
                        {!item.file && (
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet float-end download-uploaded-file"
                            onClick={() =>
                              DownloadFile(item.fileName, onSuccess, onError)
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </>
      {shipmentDtls.status !== DELIVERYSTATUSCODE && (
        <div className="my-3 text-end">
          <button
            className="btn btn-outline-secondary theme-violet me-2"
            type="button"
            onClick={() => {
              props.handler();
            }}
          >
            &larr; Go to My Orders
          </button>
          {/* <button
            className="btn btn-outline-secondary theme-violet px-4"
            type="button"
            onClick={handleSubmit(handelOnSubmit, err)}
          >
            Save
          </button> */}
        </div>
      )}
      <Spinner show={show} />
    </div>
  );
};

export default UploadBookingDoc;

export const initValDiscounts ={
    id: 0,
    discountName: "",
    discountTypeId: '',
    discountTypeValue: "",
    validFrom: "",
    validTo: "",
    parentId: 0,
    threshold : [{
        id: 0,
        discountId: 0,
        limitFrom: '',
        limitTo: '',
        discount: ''
      }]
}
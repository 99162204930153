import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { initValPartnerPrf } from "../../ApprovalWorkFlow/Partner/initValPartnerPrf";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { getFileName, toBase64, approvalHistory, permission } from "../../Helper/CommonMethods";
import {
  ALPHANUM,
  COUNTRY_CODES,
  PATTERN_ERR_MSG,
  COMPANY_REGD_NO_REGEX,
  REQUIRED_ERR_MSG,
  INVALID_COMPANY_REGD_NO_ERROR_MSG,
  PHONE_NUM,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import { DownloadFile } from "../../Helper/FileStoreService";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import ApprovalLogList from "../../ApprovalWorkFlow/ApprovalLogList";
import { APVLLOG } from "../../../ConfigConstants/ConfigConstants";
const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
  photoFileName: "",
};

export default function CompanyProfile() {
  const [companyProfile, setCompanyProfile] = useState(initValPartnerPrf);
  const [reload, setReload] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [isUserActive, setIsUserActive] = useState(false);
  const modalRef = useRef();
  const [show, setShow] = useState(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const [userType, setUserType] = useState()
  const [photoImg, setPhotoImg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [imageData, setImageData] = useState(initPhotoFile);
  const callback = () => {
    setReload((reload) => reload + 1);
  };
  const [isDisabled, setDisabled] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const userType = getUserInfo("userType")
    setIsUserActive(getUserInfo("userActive"));
    setUserType(userType)
    fetchCompanyDetails(userType);

  }, [reload]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: companyProfile,
  });

  const fetchCompanyDetails = async (userType) => {
    let uri = `GetPartnerProfile?profileId=${getUserInfo("acctId")}&roleId=0&userType=${userType}`;
    setShow(true);
    let data = await CallApiGet(uri);
    setShow(false);
    if (data.respCode === 200 || data.respCode === 0) {
      setCompanyProfile(data.respData);
      let partnerPrflDetails = data.respData;
      Object.entries(partnerPrflDetails).forEach(([key, val]) => {
        if (key !== "infoList") {
          if (typeof val !== "undefined" && val !== "null" && val !== null) {
            if (key === "phone") {
              setValue("phoneCode", val.substr(0, 3));
              setValue("phone", val.substr(3));
            } else {
              setValue(key, val);
            }
          } else {
            setValue(key, "");
          }
        }
      });
    } else {
    }
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const onSubmit = async (data) => {
    setShow(true);
    if (data.phone !== "") {
      data.phone = data.phoneCode + data.phone;
    }
    delete data.phoneCode;
    data?.userInfoList?.forEach((e)=>{
      if(e.infoCode===125)
      {
        if(data?.profilePhoto?.photo?.length>0)
        {
              e.infoAttachment=data.profilePhoto
         }
      }
    })
    const uri = `savePartnerProfile?userType=${userType}`;
    let resp = await CallApiPost(uri, data);
    setShow(false);
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess("Profile details updated successfully !!!");
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the write permission!!");
      modalRef.current.click();
    } else {
      onFailure();
    }

    console.log(data);
  };
  const onSuccess = (sucMsg) => {
    setIsSuccess(true);
    setTitle("Congratulations");
    setDescription(sucMsg);
    modalRef.current.click();
  };

  const onFailure = () => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(SERVER_ERR_MSG);
    modalRef.current.click();
  };
  const onError = (error) => {
    console.error(error);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(companyProfile.partnerId, companyProfile.approvalUserTypeId);
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };
  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
    } else {
      var fileName = file[0].name;
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      // clearErrors("profileDoc");
      // setPhotoImgErrMsg();
    }
  };
  useEffect(()=>{
    let profileUrl=companyProfile?.userInfoList.find((e)=>e.infoCode === 125)?.infoAttachment?.photoBucketUrl
    if (profileUrl && profileUrl !== "") {
      setImageData(profileUrl);
  } else {
    setImageData("");
  }
  },[companyProfile])
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="mt-2">
              <div className="col-md-2 d-none">
                <nav className="nav flex-column profile-action" id="nav-tab">
                  <button
                    className="nav-link active"
                    id="nav-RegisteredCustomer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-RegisteredCustomer"
                    type="button"
                    role="tab"
                    aria-controls="nav-RegisteredCustomer"
                    aria-selected="true"
                  >
                    Personal Details
                  </button>
                  <button
                    className="nav-link"
                    id="nav-ISR-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-ISR"
                    type="button"
                    role="tab"
                    aria-controls="nav-ISR"
                    aria-selected="false"
                  >
                    Company Details
                  </button>
                  <button
                    className="nav-link"
                    id="nav-Finance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-Finance"
                    type="button"
                    role="tab"
                    aria-controls="nav-Finance"
                    aria-selected="false"
                  >
                    Bank Details
                  </button>
                  <button
                    className="nav-link"
                    id="way-bill-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#way-bill-details"
                    type="button"
                    role="tab"
                    aria-controls="way-bill-details"
                    aria-selected="false"
                  >
                    Waybill Details
                  </button>
                  <button
                    className="nav-link"
                    id="pickup-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pickup-details"
                    type="button"
                    role="tab"
                    aria-controls="pickup-details"
                    aria-selected="false"
                  >
                    Pickup Details
                  </button>
                  <button
                    className="nav-link"
                    id="password-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#password-details"
                    type="button"
                    role="tab"
                    aria-controls="password-details"
                    aria-selected="false"
                  >
                    Password Details
                  </button>
                </nav>
              </div>
              <div className="col-md-12 ">
                <div className="alert alert-info">
                  <div>
                    <div className="float-start">
                      <h5 className="card-title">Current Approval Status:</h5>
                      <p className="card-text">{companyProfile.approveStatus}</p>
                    </div>
                    {permission(APVLLOG).allowedWrite && (<button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-1 float-end"
                      onClick={getApprovalHistory}
                      data-bs-toggle="modal"
                      data-bs-target="#approval-history-modal"
                    >
                      Approval History
                    </button>)}
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="row" id="nav-tabContent">
                  <div className="col-md-12 mb-4">
                    <div
                      className="tab-pane d-block show border border-2 p-2"
                      id="nav-RegisteredCustomer"
                      role="tabpanel"
                      aria-labelledby="nav-RegisteredCustomer-tab"
                    >
                      <h5 className="theme-violet-txt">Personal Details</h5>
                      <div className="row">
                      <div 
                      className="mb-1 file-placeholder thumbnail-pic"
                      {...register("profilePhoto")}
                    > 
                        <input
                          type="file"
                          accept="image/png, image/jpeg " 
                           onChange={selectPhotoFile}
                        />
                      {photoImg ? (
                        <img
                          src={URL.createObjectURL(photoImg)}
                          alt="img"
                        />
                     ) : imageData !== "" ? (
                        <img
                        src={
                          imageData !== ""
                            ?  imageData
                            : ""
                        }
                          alt="img"
                        />
                       ) : ( 
                        <div>
                          Upload Photo{" "}
                        </div>
                       )} 
                    </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Company Name <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            defaultValue={companyProfile.companyName}
                            {...register("companyName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                          />
                          {errors.companyName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.companyName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Company address <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            defaultValue={companyProfile.address}
                            {...register("address", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxlength="200"
                          />
                          {errors.address && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.address.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Regd Number <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyRegistrationNo"
                            defaultValue={companyProfile.companyRegistrationNo}
                            {...register("companyRegistrationNo", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              // pattern: {
                              //   value: COMPANY_REGD_NO_REGEX,
                              //   message: INVALID_COMPANY_REGD_NO_ERROR_MSG,
                              // },
                            })}
                            maxlength="30"
                          />
                          {errors.companyRegistrationNo && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.companyRegistrationNo.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Phone <span className="text-danger">&#9733;</span>
                          </label>
                          <div className="input-group">
                            <select
                              name="phoneCode"
                              {...register("phoneCode")}
                              defaultValue="+60"
                              className="form-control country-code-customize"
                            >
                              {COUNTRY_CODES.map((cc, index) => (
                                <option key={index} value={cc.value}>
                                  {cc.label}
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              // defaultValue={companyProfile.phone}
                              {...register("phone", {
                                required: {
                                  value: true,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: PHONE_NUM,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                              maxLength="11"
                            />
                            {errors.phone && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.phone.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Email <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            defaultValue={companyProfile.email}
                            {...register("email")}
                            disabled
                            maxlength="100"
                          />
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            State <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            defaultValue={companyProfile.state}
                            {...register("state")}
                            disabled
                            maxlength="100"
                          />
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            City <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            defaultValue={companyProfile.city}
                            {...register("city")}
                            disabled
                            maxlength="100"
                          />
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Postal Code <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postCode"
                            defaultValue={companyProfile.postCode}
                            {...register("postCode", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: ALPHANUM,
                                message: PATTERN_ERR_MSG,
                              },
                            })}
                            disabled
                            maxlength="100"
                          />
                          {errors.postCode && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.postCode.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-4 mb-2 position-relative">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Upload SSM Document <span className="text-danger">&#9733;</span>
                          </label>
                          <div
                            className="Upload-Photo w-100 mb-2"
                            {...register("ssmDoc", {
                              validate: (v) => v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                            })}
                          >
                            <input type="file" onChange={(e) => selectFile("ssmDoc", e)} />
                            <div className="uploaded-text ms-2">
                              {GetSvgUpload({ required: false })}
                              {watch("ssmDoc.photoBucketUrl", "Upload SSM Document") === null
                                ? "Upload SSM Document"
                                : watch("ssmDoc.photoBucketUrl", "Upload SSM Document")}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                            disabled={
                              isDisabled[`companyProfile.ssmDoc.photoBucketUrl` + "attachment"]
                            }
                            onClick={() =>
                              DownloadFile(getFileName(watch("ssmDoc.photoBucketUrl")))
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                          {errors.ssmDoc && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; This field is mandatory
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div
                      className="tab-pane d-block border border-2 p-2"
                      id="nav-Finance"
                      role="tabpanel"
                      aria-labelledby="nav-Finance-tab"
                    >
                      <h5 className="theme-violet-txt">Bank Details</h5>
                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Bank Name <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bankName"
                            defaultValue={companyProfile.bankName}
                            {...register("bankName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                          />
                          {errors.bankName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Bank Account <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bankAccount"
                            defaultValue={companyProfile.bankAccount}
                            {...register("bankAccount", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: ALPHANUM,
                                message: PATTERN_ERR_MSG,
                              },
                            })}
                          />
                          {errors.bankAccount && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankAccount.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Beneficiary name <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="beneficiaryName"
                            defaultValue={companyProfile.beneficiaryName}
                            {...register("beneficiaryName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                          />
                          {errors.beneficiaryName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.beneficiaryName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2 position-relative">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Upload Bank Statement <span className="text-danger">&#9733;</span>
                          </label>
                          <div
                            className="Upload-Photo w-100 mb-2"
                            {...register("bankDoc", {
                              validate: (v) => v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                            })}
                          >
                            <input type="file" onChange={(e) => selectFile("bankDoc", e)} />
                            <div className="uploaded-text ms-2">
                              {GetSvgUpload({ required: false })}
                              {watch("bankDoc.photoBucketUrl", "Upload Bank Statement") === null
                                ? "Upload Bank Statement"
                                : watch("bankDoc.photoBucketUrl", "Upload Bank Statement")}
                            </div>
                            {/* <div>Upload Bank Statement</div> */}
                          </div>
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                            disabled={
                              isDisabled[`companyProfile.bankDoc.photoBucketUrl` + "attachment"]
                            }
                            onClick={() =>
                              DownloadFile(getFileName(watch("bankDoc.photoBucketUrl")))
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                          {errors.bankDoc && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; This field is mandatory
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="w-100 text-end mt-2">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#success-modal"
                      id="modalPopup"
                      className="d-none"
                      ref={modalRef}
                    />
                    {isUserActive === "true" && (
                      <button
                        type="button"
                        className="btn btn-default border"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ApprovalLogList data={approvalHistoryData} />
      <Spinner show={show} />
      <Popup isSuccess={isSuccess} title={title} description={description} handler={callback} />
    </div>
  );
}

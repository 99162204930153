import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../../Helper/Constants";

const ApprovalTaskList = (props) => {
  return (
    <div className="col-md-12 mt-3 customized-data-table position-relative">
      <DataTable
        columns={props.columns}
        data={props.allData}
        pagination
        striped
        highlightOnHover
        customStyles={customDataTableStyles}
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
      />
    </div>
  );
};

export default ApprovalTaskList;

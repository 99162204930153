import React, { useEffect } from "react";
import GetSvgIcon from "../../../images/svglist";

const AWFAddEdit = (props) => {
  let {
    roles,
    userTypes,
    approval,
    approvalSteps,
    setApprovalSteps,
    error,
    approvalStepErr,
    setApprovalStepErr,
    deleteStepArr,
    setDeleteStepArr,
  } = props;

  useEffect(() => {
    props.addApprovalStepErr();
  }, [approvalSteps]);

  const addApprovalStep = () => {
    const newStep = {
      id: 0,
      approvalId: 0,
      seqNo: 0,
      seqName: "",
      roleId: "",
      approveBy: 0,
      checklist: "",
    };

    setApprovalSteps([...approvalSteps, newStep]);

    const newStepErr = {
      seqName: false,
      roleId: false,
      approveBy: false,
      checklist: false,
    };
    const currSteps = [...approvalStepErr];
    currSteps.push(newStepErr);
    setApprovalStepErr(currSteps);
  };

  const confirmDelete = (index) => {
    if (window.confirm("Are you sure you want to delete !!!")) {
      deleteApprovalStep(index);
    }
  };

  const deleteApprovalStep = (index) => {
    const deleteArr = [...deleteStepArr];
    deleteArr.push(approvalSteps[index].id);
    setDeleteStepArr(deleteArr);

    const steps = [...approvalSteps];
    steps.splice(index, 1);
    console.log(steps);
    setApprovalSteps([...steps]);

    const errSteps = [...approvalStepErr];
    errSteps.slice(index, 1);
    setApprovalStepErr(errSteps);
  };

  const handleOnchange = (index, name, value) => {
    console.log("Name: " + name + ", Index: " + index + ", value: " + value);
    const rows = [...approvalSteps];
    rows[index][name] = value;
    setApprovalSteps(rows);

    const stepErr = [...approvalStepErr];
    stepErr[index][name] = false;
    setApprovalStepErr(stepErr);
  };

  const moveUp = (index) => {
    const steps = [...approvalSteps];
    if (index - 1 < 0) {
      return;
    }
    let temp = steps[index];
    steps[index] = steps[index - 1];
    steps[index - 1] = temp;
    setApprovalSteps([...steps]);

    const errSteps = [...approvalStepErr];
    var tempErr = errSteps[index];
    errSteps[index] = errSteps[index - 1];
    errSteps[index - 1] = tempErr;
    setApprovalStepErr([...errSteps]);
  };

  // const moveDown = (index) => {
  //     console.log("Inside move down: ", approvalSteps.length);

  //     const steps = [...approvalSteps]
  //     if (index + 1 > steps.length - 1) {
  //         return
  //     }
  //     let temp = steps[index]
  //     steps[index] = steps[index + 1]
  //     steps[index + 1] = temp
  //     setApprovalSteps([...steps])
  // }

  return (
    <div
      className="modal fade"
      id="button-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Add/Edit Approval
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.clear}
            ></button>
          </div>
          {
            props.submitStatusFail && (
              // props.statusFail && (
              <div id="errorMsg" className="alert alert-danger" role="alert">
                {props.statusFail}
              </div>
            )}
          <div className="modal-body modal-scroll overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        User Type <span className="text-danger">&#9733;</span>
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="userType"
                        onChange={(e) =>
                          props.insertApproval(
                            e.target.id,
                            parseInt(e.target.value)
                          )
                        }
                      >
                        {
                          approval.userType === "0"
                            ? <option value="0" selected>--Select--</option>
                            : <option value="0" >--Select--</option>
                        }
                        {userTypes.map((type, index) => {
                          if (approval.userType === type.id) {
                            return (
                              <option key={index} value={type.id} selected>
                                {type.optionValue}
                              </option>
                            );
                          } else {
                            return (
                              <option key={index} value={type.id}>
                                {type.optionValue}
                              </option>
                            );
                          }
                        })}
                      </select>
                      {error.userType && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field can not be blank!
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-6 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Created By
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        id="initUserType"
                        onChange={(e) =>
                          props.insertApproval(
                            e.target.id,
                            parseInt(e.target.value)
                          )
                        }
                      >
                        <option value="0">--Select--</option>
                        {userTypes.map((role, index) => {
                          if (approval.initUserType === role.id) {
                            return (
                              <option key={index} value={role.id} selected>
                                {role.optionValue}
                              </option>
                            );
                          } else {
                            return (
                              <option key={index} value={role.id}>
                                {role.optionValue}
                              </option>
                            );
                          }
                        })}
                      </select>
                      {/* {error.assignedTo && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field can not be blank!
                          </span>
                        </div>
                      )} */}
                    </div>
                    <div className="col-md-6 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Remark
                      </label>
                      <textarea
                        type="text"
                        className="form-control txt-area-resize-0"
                        placeholder="Remark"
                        id="remarks"
                        value={approval.remarks}
                        onChange={(e) =>
                          props.insertApproval(e.target.id, e.target.value)
                        }
                      ></textarea>
                      {error.remarks && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; Alphanumerics only!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="col-md-1">
                            Seq No.
                          </th>
                          <th scope="col" >
                            Seq Name <span className="text-danger">&#9733;</span>
                          </th>
                          <th scope="col">
                            Approver Role <span className="text-danger">&#9733;</span>
                          </th>
                          <th scope="col">
                            Approved By{" "}
                            <span className="text-danger">&#9733;</span>
                          </th>
                          <th scope="col">Checklist</th>
                          <th scope="col">Up</th>
                          {/* <th scope="col">Down</th> */}
                          <th className="col-md-1">
                            <div
                              className="px-2 text-success"
                              onClick={addApprovalStep}
                            >
                              {GetSvgIcon("PlusCircle")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvalSteps.map((step, index) => {
                          let { seqName, roleId, checklist, approveBy } = step;

                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={index + 1}
                                  disabled
                                  name="seqNo"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={seqName}
                                  name="seqName"
                                  onChange={(event) => handleOnchange(index, event.target.name, event.target.value)}
                                />
                                {approvalStepErr[index] &&
                                  approvalStepErr[index].seqName && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; This Field can not be blank!
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td >
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="roleId"
                                  onChange={(evnt) =>
                                    handleOnchange(
                                      index,
                                      evnt.target.name,
                                      parseInt(evnt.target.value)
                                    )
                                  }
                                >
                                  <option value="0">--Select--</option>
                                  {roles.map((item, index) => {
                                    if (item.id === roleId) {
                                      return (
                                        <option
                                          key={index}
                                          value={item.id}
                                          selected
                                        >
                                          {item.roleName}
                                        </option>
                                      );
                                    } else {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.roleName}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                                {approvalStepErr[index] &&
                                  approvalStepErr[index].roleId && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; This Field can not be blank!
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="approveBy"
                                  onChange={(evnt) =>
                                    handleOnchange(
                                      index,
                                      evnt.target.name,
                                      parseInt(evnt.target.value)
                                    )
                                  }
                                >
                                  <option value="">--Select--</option>
                                  {props.approveBy.map((option, index) => {
                                    if (approveBy === option.id) {
                                      return (
                                        <option
                                          key={index}
                                          value={option.id}
                                          selected
                                        >
                                          {option.optionValue}
                                        </option>
                                      );
                                    } else {
                                      return (
                                        <option key={index} value={option.id}>
                                          {option.optionValue}
                                        </option>
                                      );
                                    }
                                  })}
                                </select>
                                {approvalStepErr[index] &&
                                  approvalStepErr[index].approveBy && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; This Field can not be blank!
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="checklist"
                                  className="form-control"
                                  value={checklist}
                                  name="checklist"
                                  onChange={(evnt) =>
                                    handleOnchange(
                                      index,
                                      evnt.target.name,
                                      evnt.target.value
                                    )
                                  }
                                />
                                {approvalStepErr[index] &&
                                  approvalStepErr[index].checklist && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; Alphanumeric Only!
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <div
                                  className="px-2 mt-1 text-success"
                                  name="moveUp"
                                  onClick={() => moveUp(index)}
                                >
                                  {GetSvgIcon("UpArrow")}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="px-2 text-danger"
                                  onClick={() => confirmDelete(index)}
                                >
                                  {GetSvgIcon("DashCircleNoFill")}

                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              data-bs-dismiss="modal"
              onClick={props.clear}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={props.saveApprovalWorkFlow}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWFAddEdit;

import moment from "moment";
import {
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
} from "../Helper/Constants";

export const getCurrentColumn = (statusType, hideColums) => {
  const stndBookingCol = [
    {
      name: "Account Code.",
      selector: (row) => row.custAccountCode,
      omit: hideColums,
    },
    {
      name: "Name",
      selector: (row) => row.custName,
      omit: hideColums,
    },
    {
      name: "Company Name",
      selector: (row) => row?.businessName,
      omit: hideColums,
    },
    {
      name: "Order No.",
      selector: (row) => row.orderNo,
    },
    {
      name: "Booked On",
      selector: (row) => moment(row.bookedOn).format(DATE_TIME_FORMAT_MOMENT),
    },
    {
      name: "Origin",
      selector: (row) => row?.origin?.sbuCode,
    },
    {
      name: "Destination",
      selector: (row) => row?.destination?.sbuCode,
    },
    {
      name: "Package Type",
      selector: (row) => row.packingType,
    },
    {
      name: "Service",
      selector: (row) => row.slaName,
    },
    {
      name: "Space(Pallet)",
      selector: (row) => row.palletSpaceRequired + "(" + row.pallets + ")",
    },
    {
      name: "Shipment Date",
      selector: (row) => moment(row.shipmentDate).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Estimated Delivery",
      selector: (row) => moment(row.estDeliveryDate).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Status",
      selector: (row) => row.statusText,
    },
  ];
  const ftlBookingCol = [
    {
      name: "Customer",
      selector: (row) => row.custAccountCode,
    },
    {
      name: "Name",
      selector: (row) => row.custName,

      omit: hideColums,
    },
    {
      name: "Order No.",
      selector: (row) => row.orderNo,
    },
    {
      name: "Booked On",
      selector: (row) => moment(row.bookedOn).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Shipment Date",
      selector: (row) => moment(row.shipmentDate).format(DATE_FORMAT_MOMENT),
    },
    {
      name: "Status",
      selector: (row) => row.statusText,
    },
  ];
  const probBookingCol = [
    {
      name: "Customer",
      selector: (row) => row.acct_code,
    },
    {
      name: "Name",
      selector: (row) => row.cust_name,
    },
    {
      name: "Waybill No.",
      selector: (row) => row.waybill_no,
    },
    {
      name: "Order No.",
      selector: (row) => row.order_no,
    },
    {
      name: "Problem Reason",
      selector: (row) => row.event_reason || row.problem_type,
    },
    {
      name: "Logged By",
      selector: (row) => row.event_staff_id,
    },
    {
      name: "Vehicle No.",
      selector: (row) => row.vhcl_no,
    },
    {
      name: "Last Location",
      selector: (row) => row.event_sbu,
    },
    {
      name: "Last Activity Time",
      selector: (row) => row.event_time, //moment(row.event_time).format(DATE_TIME_FORMAT_MOMENT),
    },
    {
      name: "Status",
      selector: (row) => row.problem_status,
    },
  ];

  let columns;
  switch (statusType) {
    case "ALL":
      columns = stndBookingCol;
      break;
    case "Delivered":
      columns = stndBookingCol;
      break;
    case "Inprogress":
      columns = stndBookingCol;
      break;
    case "ProblemShipment":
      columns = probBookingCol;
      break;
    case "FTL":
      columns = ftlBookingCol;
      break;
    default:
      break;
  }

  return columns;
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component-with-filter";
import { Link } from "react-router-dom";
import GetSvgIcon from "../../../images/svglist";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import { Tooltip } from "react-tooltip";
import { permissionCheck, writeLocalStorage } from "../../Helper/CommonMethods";
import ModalLayout from "../../Layout/ModalLayout";
import BookingSearchComponent from "../../OrderBooking/StandardBooking/BookingSearchComponent";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { CallApiGet } from "../../Helper/serviceApi";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import ViewStaffList from "./ViewStaffList";
import { LINEHAUL_CUSTOMER_BUSINESS_PARTNERS } from "../../../ConfigConstants/ConfigConstants";

const CustomerManagement = (props) => {
  const {
    custList,
    type,
    columns,
    modalConfig,
    handleModalClose,
    viewStaff,
    show,
    handleCLose,
  } = props;

  return (
    <>
      <div className="col-md-12 mt-5 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={custList}
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
        <ViewStaffList
          show={show}
          handleCLose={handleCLose}
          argData={viewStaff}
        />
        {modalConfig && (
          <ModalLayout
            moadlConfig={modalConfig}
            handleModalClose={handleModalClose}
            handleMoalClose={handleModalClose}
          >
            <BookingSearchComponent
              showToggler={true}
              showSlider={true}
              showSearchBtn={true}
              addressBook={null}
              isReadOnly={false}
              disableOpt={true}
              defaultOpt="FTL"
            />
          </ModalLayout>
        )}
      </div>
    </>
  );
};

export default CustomerManagement;

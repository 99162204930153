import { useEffect, useRef } from "react";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CallApiGet, CallApiPost, CallApiPut } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Select from "react-select";
import Popup from "../../Layout/Popup";
import { permissionCheck, toBase64 } from "../../Helper/CommonMethods";
import {
  ALPHANUM,
  NUMBER_REGEX,
  COUNTRY_CODES,
  NUMBER_FIVE_DECIMAL,
  SERVER_ERR_MSG,
  REQUIRED_ERR_MSG,
  ALPHANUM_SPACE_SPCL_CHAR,
  PATTERN_ERR_MSG,
  DATE_FORMAT_CLIENT,
} from "../../Helper/Constants";
import { getPersonalId, getUserInfo } from "../../AuthUtils/AuthUtils";
import Spinner from "../../Layout/Spinner";
import {
  APPROVAL_STATUS_PENDING,
  CMGDTLS,
  PROFILE_STATUS_REJECT,
  CUSTAPVLRESUB,
  LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL,
  MONTH_BILL_CYCLE,
  LINEHAUL_STAFF_USER_TYPE,
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_CUSTOMER_BUSINESS_PARTNERS,
} from "../../../ConfigConstants/ConfigConstants";
import ApprovalLogList from "../ApprovalLogList";
import { approvalHistory } from "../../Helper/CommonMethods";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import MultipleFileUpload from "../../Utilities/MultipleFileUpload";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const initSysData = [
  {
    id: 0,
    optionValue: "Default Value",
  },
];

const checkboxOptions = [
  {
    label: "Approve",
    value: "APPROVE",
  },
  {
    label: "Reject",
    value: "REJECT",
  },
];

const initCustProfile = {
  profileId: 37,
  custType: 0,
  acctCode: "",
  custName: "",
  businessName: "",
  businessRegno: "",
  businessNature: "",
  businessAddr: "",
  custEmail: "",
  custPhone: "",
  custAddr: "",
  postcode: "",
  custWebsite: "",
  custIc: "",
  pickupAddr: "",
  pickupPerson: "",
  pickupPhone: "",
  bankName: "",
  bankAcct: "",
  benificiaryName: "",
  appliedBy: "",
  picSales: 0,
  picSalesStaffId: "",
  picSalesStaffName: "",
  picCredit: 0,
  picCreditStaffId: "",
  picCreditStaffName: "",
  creditLimit: 0,
  reqCreditLimit: 0,
  creditTerms: 0,
  debtorCode: "",
  ctosCheck: "",
  status: 0,
  noPayStatus: 0,
  registrationDate: "",
  commitedPallet: 0,
  discount: 0,
  rateChartUrl: "",
  commission: 0.0,
  billingCycle: 0,
  bankStatement: {
    photoBucketUrl: "",
    photoFileName: "",
  },
  ssmDoc: "",
};

const initAttachment = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};
const customCss = {
  main: "w-100",
  header: {
    levelColor: "fc-black mb-0",
  },
  comp: {
    mainDiv: "row mt-3",
    innerDiv: "col-lg-4 mb-2",
    innerLevel: "uploaded-text ms-2",
  },
};
const customData = {
  headerLevel: "Additional Document",
  defaultName: "Additional Document",
};

const CustomerDetails = () => {
  const [custType, setCustType] = useState(initSysData);
  const [defaulterOptions, setDefaulterOptions] = useState(initSysData);
  const [custPrflDetails, setCustPrflDetails] = useState(initCustProfile);
  const [isrOptions, setIsrOptions] = useState([]);
  const [selectedIsr, setSelectedIsr] = useState();
  const [financeOptions, setFinanceOptions] = useState([]);
  const [selectedFinance, setSelectedFinance] = useState();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [rateChart, setRateChart] = useState(initAttachment);
  const [isRateSelected, setIsRateSelected] = useState(false);
  const [logAttachment, setLogAttachment] = useState(initAttachment);
  const [comment, setComment] = useState("");
  const [requestType, setRequestType] = useState("SAVE");
  const [show, setShow] = useState(false);
  const [fileData, setFileData] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const [billingCycleList, setBillingCycleList] = useState(initSysData);
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(null);
  const [selectedDefaulterAction, setSelectedDefaulterAction] = useState();
  const modalRef = useRef();
  const [custBussType, setCustBussType] = useState(false);
  const [ctosResult, setCtosResult] = useState([]);
  const [selectedCtos, setSelectedCtos] = useState();
  const [userType, setUserType] = useState();
  const navigate = useNavigate();
  const [isDisabled, setDisabled] = useState({});
  useEffect(() => {
    if (!location.state) {
      location.state = {
        from: "CUSTAT",
        type: searchParams.get("userType") == "141" ? "AC" : "ALL",
      };
    }
    fetchCustType();
    fetchSysOptions();
    fetchCustDetails();
    loadPicSales();
    loadPicCredit();
    setUserType(getUserInfo("userType"));
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: custPrflDetails,
  });
  const watchReqCreditLimit = watch("reqCreditLimit");
  const {
    fields: generalDocList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "generalDocList",
  });
  const watchIsSstExempted = watch("sstExempt");
  const fetchCustType = async () => {
    const uri = "list-cust-type";
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setCustType(data.respData);
    } else {
      console.error(data.respMessage);
    }
  };

  const fetchSysOptions = async () => {
    let data = await CallApiPost("SysOptionGetByCode", [
      "ACTION_ON_DEFAULTER",
      "BILLING_CYCLE",
      "CTOS_TYPE",
    ]);
    if (data.respCode === 200 || data.respCode === 0) {
      let defaulterActionList = [];
      let billCycleList = [];
      let ctosArr = [];
      data.respData.map((d) => {
        if (d.optionCode === "ACTION_ON_DEFAULTER")
          defaulterActionList.push({ label: d.optionValue, value: d.id });
        else if (d.optionCode === "BILLING_CYCLE")
          billCycleList.push({ label: d.optionValue, value: d.id });
        else if (d.optionCode === "CTOS_TYPE")
          ctosArr.push({ label: d.optionValue, value: d.id });
        return;
      });
      setDefaulterOptions(defaulterActionList);
      if (!!defaulterActionList && defaulterActionList.length > 0) {
        setSelectedDefaulterAction(defaulterActionList[0]);
      }
      setBillingCycleList(billCycleList);
      setCtosResult(ctosArr);
    } else {
      console.error(data.respMessage);
    }
  };

  const fetchCustDetails = async () => {
    setShow(true);
    var profileId = searchParams.get("profileId");
    let roles = JSON.parse(getUserInfo("userRoles"));
    let uri = `get-cust-details?profileId=${profileId}&roleId=${roles[0].roleId}`;
    let data = await CallApiGet(uri);

    if (data.respCode === 200 || data.respCode === 0) {
      setShow(false);

      let respDatas = data.respData[0];
      if (respDatas?.genDocList?.length > 0) {
        let jsonString = respDatas.genDocList;
        const arrayOfObjects = JSON.parse(jsonString);
        respDatas.genDocList = arrayOfObjects;
      }
      setCustPrflDetails(respDatas);
      let custPrflDetails = respDatas; //data.respData[0];
      Object.entries(custPrflDetails).map(([key, val]) => {
        if (typeof val !== "undefined" && val !== "null" && val !== null) {
          switch (key) {
            case "custPhone":
              setValue("custPhoneCode", val.substr(0, 3));
              setValue("custPhone", val.substr(3));
              break;
            case "pickupPhone":
              setValue("pickupPhoneCode", val.substr(0, 3));
              setValue("pickupPhone", val.substr(3));
              break;
            case "bankStatement":
              setValue(
                "bankStatement.photoFileName",
                respDatas?.bankStatement?.split("/").pop()
              );
              setValue(
                "bankStatement.photoBucketUrl",
                respDatas?.bankStatement
              );
              break;
            case "ssmDoc":
              setValue(
                "ssmDoc.photoFileName",
                respDatas?.ssmDoc?.split("/").pop()
              );
              setValue("ssmDoc.photoBucketUrl", respDatas?.ssmDoc);
              break;
            default:
              setValue(key, val);
              break;
          }
        } else {
          setValue(key, "");
        }
      });
      const picSalesOption = custPrflDetails.picSales
        ? {
            label:
              custPrflDetails.picSalesStaffName +
              " (" +
              custPrflDetails.picSalesStaffId +
              ")",
            value: custPrflDetails.picSales,
          }
        : "";
      setSelectedIsr(picSalesOption);

      const picCreditOption = custPrflDetails.picCredit
        ? {
            label:
              custPrflDetails.picCreditStaffName +
              " (" +
              custPrflDetails.picCreditStaffId +
              ")",
            value: custPrflDetails.picCredit,
          }
        : "";
      setSelectedFinance(picCreditOption);

      const billingCycleOption = custPrflDetails.billingCycle
        ? {
            label: custPrflDetails.billingCycleVal,
            value: custPrflDetails.billingCycle,
          }
        : "";
      setSelectedBillingCycle(billingCycleOption);

      const defaulterOption = custPrflDetails.noPayStatus
        ? {
            label: custPrflDetails.noPayStatusVal,
            value: custPrflDetails.noPayStatus,
          }
        : defaulterOptions[0];
      if (defaulterOption.value > 0) {
        setSelectedDefaulterAction(defaulterOption);
      }

      const ctosData = custPrflDetails?.ctosCheck
        ? {
            label: custPrflDetails.ctosCheckVal,
            value: custPrflDetails.ctosCheck,
          }
        : "";
      setCtosId(ctosData);

      setIsSuccess(true);
    } else {
      setShow(false);
      setIsSuccess(false);
    }
  };

  const setPicSalesId = (opt) => {
    if (opt === null) {
      setSelectedIsr("");
      setValue("picSales", 0);
    } else {
      setValue("picSales", parseInt(opt.value));
      setSelectedIsr(opt);
    }
  };

  const setPicCreditId = (opt) => {
    if (opt === null) {
      setSelectedFinance("");
      setValue("picCredit", 0);
    } else {
      setValue("picCredit", parseInt(opt.value));
      setSelectedFinance(opt);
    }
  };

  const loadPicSales = async () => {
    // perform a request
    var requestResults = [];

    const uri = `list-isr?value=&userId=`;
    const resp = await CallApiGet(uri);

    if (resp.respCode === 200 || resp.respCode === 0) {
      const data = resp.respData;
      data.map((d) =>
        requestResults.push({
          label: d.staffName + " (" + d.staffId + ")",
          value: d.id,
        })
      );
      setIsrOptions(requestResults);
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setIsrOptions(requestResults);
    } else {
      onError(SERVER_ERR_MSG);
    }
  };

  const loadPicCredit = async () => {
    // perform a request
    var requestResults = [];

    const uri = `list-finance?value=`;
    const resp = await CallApiGet(uri);

    if (resp.respCode === 200 || resp.respCode === 0) {
      const data = resp.respData;
      data.map((d) =>
        requestResults.push({
          label: d.staffName + " (" + d.staffId + ")",
          value: d.id,
        })
      );
      setFinanceOptions(requestResults);
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setFinanceOptions(requestResults);
    } else {
      onError(SERVER_ERR_MSG);
    }
  };

  const selectFile = async (id, event) => {
    const file = event.target.files;

    var fileName = file[0].name;
    var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    var data = "";
    try {
      data = await toBase64(file[0]);
    } catch (error) {
      console.error(error);
    }

    const newAttachment = {
      photo: data,
      photoFileType: ext,
      photoBucketUrl: "",
    };
    if (id === "rateChart") {
      setRateChart(newAttachment);
    } else {
      setLogAttachment(newAttachment);
    }
  };

  const isRateChartValid = () => {
    if (fileData && fileData.error.length !== 0) {
      console.log(fileData.error);
      document.getElementById("rateChart").value = "";
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async (data) => {
    if (data.custType !== 135) {
      if (isRateSelected && !isRateChartValid()) {
        return;
      }
      if (data.picSales === 0 && requestType !== "REJECT") {
        onError("Sales Representative is mandatory to proceed for approval");
        return;
      }
      if (requestType === "REJECT" && comment === "") {
        onError(
          `Please provide a valid comment as you are rejecting the ${
            (location.state && location.state.type === "AC") ||
            userType == "141"
              ? "sales partner"
              : "customer"
          }`
        );
        return;
      }
    }
    data.custPhone = data.custPhoneCode + data.custPhone;
    data.pickupPhone = data.pickupPhoneCode + data.pickupPhone;
    data.generalDocList = data.generalDocList ? data.generalDocList : [];

    delete data.custPhoneCode;
    delete data.pickupPhoneCode;
    delete data.picSalesStaffId;
    delete data.picSalesStaffName;
    delete data.picCreditStaffId;
    delete data.picCreditStaffName;
    delete data.rateChartUrl;

    data["updatedBy"] = getUserInfo("userId");

    setShow(true);
    const model = {
      approvalUserId: custPrflDetails.profileId,
      logText:
        comment === "" && requestType === "APPROVE" ? "Approved" : comment,
      logAttachment: logAttachment,
      personalId: getPersonalId(),
      logType: requestType,
      isCustomer: true,
      approvalUserTypeId: custPrflDetails.approvalUserTypeId,
    };
    const uri = "update-cust-profile";
    let resp = await CallApiPut(uri, data);

    if (resp.respCode === 200 || resp.respCode === 0) {
      if (requestType === "SAVE") {
        if (
          permissionCheck(CUSTAPVLRESUB, "allowedWrite") &&
          custPrflDetails.profileStatus === PROFILE_STATUS_REJECT
        ) {
          let resubResp = await CallApiPost("resubmit-customer", data);
          if (resubResp.respCode === 200 || resubResp.respCode === 0) {
            onSuccess(
              "SUCCESS",
              `${
                (location.state && location.state.type === "AC") ||
                userType == "141"
                  ? "Sales partner"
                  : "Customer"
              } resubmitted for approval.`
            );
          } else if (resubResp.respCode === 400 || resubResp.respCode === 401) {
            setShow(false);
            localStorage.clear();
            navigate("/home");
          } else {
            onError("Something went wrong!!!");
          }
        } else {
          onSuccess(
            "Congratulations",
            `${
              (location.state && location.state.type === "AC") ||
              userType == "141"
                ? "Sales partner"
                : "Customer"
            } details updated successfully`
          );
        }
      } else {
        let approvalResp = await CallApiPost("log-approval", model);
        if (approvalResp.respCode === 200 || approvalResp.respCode === 0) {
          switch (requestType) {
            case "APPROVE":
              onSuccess("APPROVED", "");
              break;
            case "REJECT":
              onSuccess("REJECTED", "");
              break;
            default:
              break;
          }
        } else if (approvalResp.respCode === 406) {
          onError(approvalResp.respMessage);
        } else if (
          approvalResp.respCode === 400 ||
          approvalResp.respCode === 401
        ) {
          setShow(false);
          onError("Sorry, you don't have the write permission!!");
        } else {
          onError(SERVER_ERR_MSG);
        }
      }
    } else {
      onError(SERVER_ERR_MSG);
    }
    setShow(false);
  };

  const onSuccess = (title, body) => {
    setShow(false);
    setIsSuccess(true);
    setTitle(title);
    setDescription(body);
    modalRef.current.click();
  };

  const onError = (errMsg) => {
    setShow(false);
    setIsSuccess(false);
    setTitle("Error");
    setDescription(errMsg);
    modalRef.current.click();
  };

  const callback = () => {
    if (location.state && location.state.from === "CUSTMGT")
      navigate(`/customerManagement?type=${location.state.type}`);
    else
      navigate(
        `/approvalTask?type=${
          location.state
            ? location.state.type
            : (location.state && location.state.type === "AC") || userType
        }`
      );
  };

  const handleRequestType = (event) => {
    if (event.target.checked) {
      setRequestType(event.target.value);
    } else {
      setRequestType("SAVE");
    }
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(
      custPrflDetails.profileId,
      custPrflDetails.custType
    );
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const onFailure = (errMsg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(errMsg);
    modalRef.current.click();
  };
  useEffect(() => {
    if (LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custPrflDetails.custType))
      setCustBussType(true);
    else setCustBussType(false);
  }, [custPrflDetails]);

  const setCtosId = (opt) => {
    if (opt === null) {
      setSelectedCtos("");
      setValue("ctosCheck", 0);
    } else {
      setValue("ctosCheck", parseInt(opt.value));
      setSelectedCtos(opt);
    }
  };
  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const ssmDocPlaceHolder = watch(
    "ssmDoc.photoFileName",
    "Upload SSM Document"
  );
  const selectBankAndSSMFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const download = (fileName) => {
    window.open(fileName, "_blank");
  };

  const formErr = (errors) => {
    console.error(errors);
  };
  const onIsExemptChange = (e) => {
    if (e.target.value === "false" || e.target.value === false) {
      setValue("sstNumber", "");
      setValue("sstExemptStartDate", null);
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="col-md-12 mt-3">
              {custPrflDetails.custType !== 143 &&
                ((!location.state || location.state.from !== "CUSTMGT") &&
                custPrflDetails.approveStatus === APPROVAL_STATUS_PENDING ? (
                  <div className="alert alert-info">
                    <div>
                      <div className="float-start">
                        <h5 className="card-title">Approval Checklist</h5>
                        <p className="card-text">
                          {custPrflDetails.approvalChecklist}
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary theme-violet me-1 float-end"
                        onClick={getApprovalHistory}
                        data-bs-toggle="modal"
                        data-bs-target="#approval-history-modal"
                      >
                        Approval History
                      </button>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                ) : (
                  <div className="float-end position-absolute end-0">
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-2"
                      onClick={getApprovalHistory}
                      data-bs-toggle="modal"
                      data-bs-target="#approval-history-modal"
                    >
                      Approval History
                    </button>
                  </div>
                ))}
              <div className="accordion form-register my-4" id="nav-tabContent">
                <div>
                  <h5 className="theme-violet-txt">Personal Details</h5>
                  <div className="w-100">
                    <div className="row">
                      <input type="hidden" {...register("profileId")} />
                      <input type="hidden" {...register("userId")} />
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Registration Type{" "}
                        </label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          {...register("custType", {
                            setValueAs: (v) => {
                              const val = parseInt(v);
                              return isNaN(val) ? v : val;
                            },
                          })}
                          disabled={true}
                          // disabled={custBussType}
                        >
                          {custType.map((type, index) => {
                            if (custPrflDetails.custType === type.id) {
                              return (
                                <option key={index} value={type.id} selected>
                                  {type.optionDesc}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={type.id}>
                                  {type.optionDesc}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="This field is mandatory"
                        >
                          Name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("custName")}
                          disabled={custBussType}
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Phone{" "}
                        </label>
                        <div className="input-group">
                          <select
                            name="custPhoneCode"
                            {...register("custPhoneCode")}
                            defaultValue="+60"
                            className="form-control country-code-customize"
                            disabled={custBussType}
                          >
                            {COUNTRY_CODES.map((cc, index) => (
                              <option key={index} value={cc.value}>
                                {cc.label}
                              </option>
                            ))}
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            {...register("custPhone")}
                            disabled={custBussType}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Email{" "}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          {...register("custEmail")}
                          disabled={custBussType}
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          {(location.state && location.state.type === "AC") ||
                          userType == "141"
                            ? "Sales Partner IC"
                            : "Customer IC"}{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("custIc")}
                          disabled={custBussType}
                        />
                      </div>
                      {custPrflDetails?.custType === 135 && (
                        <>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("state")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("city")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Postcode{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("postcode")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Address{" "}
                            </label>

                            <textarea
                              className="form-control"
                              {...register("custAddr")}
                            ></textarea>
                          </div>
                        </>
                      )}
                      {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(
                        custPrflDetails?.custType
                      ) && (
                        <>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Company Name{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("businessName")}
                              disabled={custBussType}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Company Regd Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("businessRegno")}
                              disabled={custBussType}
                            />
                          </div>

                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("state")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("city")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Postcode{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              {...register("postcode")}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Address{" "}
                            </label>

                            <textarea
                              className="form-control"
                              {...register("custAddr")}
                              disabled={custBussType}
                            ></textarea>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {custPrflDetails.custType !==
                  +LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL && (
                  <>
                    <div className="my-4">
                      <h5 className="theme-violet-txt">Contract</h5>
                      <div className="w-100">
                        <div className="row">
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              data-toggle="tooltip"
                              data-placement="right"
                              title="This field is mandatory"
                            >
                              Sales Person in Charge{" "}
                            </label>
                            <Select
                              name="picSales"
                              className="form-control p-0"
                              options={isrOptions}
                              onChange={(opt) => setPicSalesId(opt)}
                              value={selectedIsr}
                              placeholder="Select ISR"
                              isSearchable={false}
                              isDisabled={
                                !!custPrflDetails?.picSales &&
                                custPrflDetails?.picSales > 0
                                  ? true
                                  : false
                              }
                              isClearable={true}
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Committed Pallet{" "}
                              <span className="text-primary">
                                (&#9432; Per Month)
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("commitedPallet", {
                                pattern: {
                                  value: NUMBER_REGEX,
                                  message: "Accepts NUmbers Only",
                                },
                                setValueAs: (v) => {
                                  const val = parseInt(v);
                                  return isNaN(val) ? v : val;
                                },
                              })}
                            />
                            {errors.commitedPallet && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.commitedPallet.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Discount%{" "}
                              <span className="text-primary">
                                (&#9432; On Base Rate)
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("discount", {
                                pattern: {
                                  value: NUMBER_REGEX,
                                  message: "Accepts NUmbers Only",
                                },
                                setValueAs: (v) => {
                                  const val = parseInt(v);
                                  return isNaN(val) ? v : val;
                                },
                              })}
                            />
                            {errors.discount && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.discount.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Commission%
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("commission", {
                                pattern: {
                                  value: NUMBER_REGEX,
                                  message: "Accepts NUmbers Only",
                                },
                                setValueAs: (v) => parseFloat(v),
                              })}
                            />
                            {errors.commission && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.commission.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <h5 className="theme-violet-txt">Finance</h5>
                      <div className="w-100">
                        <div className="row">
                          {custPrflDetails.custType !== 143 && (
                            <>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Credit Person in Charge{" "}
                                </label>
                                <Select
                                  name="picCredit"
                                  className="form-control p-0"
                                  options={financeOptions}
                                  onChange={(opt) => setPicCreditId(opt)}
                                  value={selectedFinance}
                                  placeholder="Select Finanace"
                                  isSearchable={false}
                                  isDisabled={false}
                                  isClearable={true}
                                />
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Requested Credit Amount{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("reqCreditLimit", {
                                    pattern: {
                                      value: NUMBER_FIVE_DECIMAL,
                                      message:
                                        "Please provide value as 00000.00!",
                                    },
                                    setValueAs: (v) => {
                                      const val = parseFloat(v);
                                      return isNaN(val) ? v : val;
                                    },
                                  })}
                                />
                                {errors.reqCreditLimit && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.reqCreditLimit.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title="This field is mandatory"
                                >
                                  Approved Credit Limit{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("creditLimit", {
                                    pattern: {
                                      value: NUMBER_FIVE_DECIMAL,
                                      message:
                                        "Please provide value as 00000.00!",
                                    },
                                    setValueAs: (v) => {
                                      const val = parseFloat(v);
                                      return isNaN(val) ? v : val;
                                    },
                                  })}
                                />
                                {errors.creditLimit && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.creditLimit.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Approved Credit Terms{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("creditTerms", {
                                    pattern: {
                                      value: NUMBER_REGEX,
                                      message: "Accepts number only!",
                                    },
                                    setValueAs: (v) => {
                                      const val = parseInt(v);
                                      return isNaN(val) ? v : val;
                                    },
                                    // onChange: (v) => setValue("creditTerms", parseInt(v))
                                  })}
                                />
                                {errors.creditTerms && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.creditTerms.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Billing Cycle{" "}
                                </label>
                                <Select
                                  name="billingCycle"
                                  className="form-control p-0"
                                  default
                                  options={billingCycleList}
                                  onChange={(opt) => {
                                    setSelectedBillingCycle({
                                      value: opt.value,
                                      label: opt.label,
                                    });
                                    setValue(
                                      "billingCycle",
                                      parseInt(opt.value)
                                    );
                                    return;
                                  }}
                                  value={
                                    !!selectedBillingCycle
                                      ? selectedBillingCycle
                                      : billingCycleList?.find(
                                          (bcl) =>
                                            bcl.value === MONTH_BILL_CYCLE
                                        )
                                  }
                                  placeholder="Select Billing Cycle"
                                  isSearchable={false}
                                  isDisabled={false}
                                  isClearable={false}
                                />
                                {errors.billingCycle && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.billingCycle.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Autocount Debtor Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  {...register("debtorCode", {
                                    pattern: {
                                      value: ALPHANUM,
                                      message: "Accepts Alphanumeric values!",
                                    },
                                  })}
                                />
                                {errors.debtorCode && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.debtorCode.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  Payment Defaulter
                                </label>
                                <Select
                                  name="noPayStatus"
                                  className="form-control p-0"
                                  options={defaulterOptions}
                                  onChange={(opt) => {
                                    setSelectedDefaulterAction({
                                      value: opt.value,
                                      label: opt.label,
                                    });
                                    setValue(
                                      "noPayStatus",
                                      parseInt(opt.value)
                                    );
                                    return;
                                  }}
                                  value={selectedDefaulterAction}
                                  placeholder="Select Payment Defaulter"
                                  isSearchable={false}
                                  isDisabled={false}
                                  isClearable={false}
                                />
                              </div>
                              <div className="col-lg-4 mb-2">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                  title="This field is mandatory"
                                >
                                  CTOS Checking Result{" "}
                                </label>
                                <Select
                                  name="ctosCheck"
                                  className="form-control p-0"
                                  options={ctosResult}
                                  onChange={(opt) => setCtosId(opt)}
                                  value={selectedCtos}
                                  placeholder="Select CTOS Result"
                                  isSearchable={false}
                                  isDisabled={false}
                                  isClearable={true}
                                />

                                {/* {errors.ctosCheck && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.ctosCheck.message}
                                </span>
                              </div>
                            )} */}
                              </div>
                            </>
                          )}
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Bank Name{" "}
                              {/* {watchReqCreditLimit !== "0" &&
                                watchReqCreditLimit && (
                                  <span className="text-danger fw-bold">*</span>
                                )} */}
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              {...register("bankName", {
                                maxLength: {
                                  value: 200,
                                  message: "Exceeded limit size of 200",
                                },
                                required: {
                                  value:
                                    watchReqCreditLimit !== "0" &&
                                    watchReqCreditLimit,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: ALPHANUM_SPACE_SPCL_CHAR,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                            />
                            {errors.bankName && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.bankName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Bank Account{" "}
                              {
                                (watchReqCreditLimit !== "0") && watchReqCreditLimit ?
                                (<span className="text-danger fw-bold">*</span>) :
                                ""
                              }
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("bankAcct", {
                                pattern: {
                                  value: ALPHANUM,
                                  message: "Accepts Alphanumeric values!",
                                },
                                maxLength: {
                                  value: 200,
                                  message: "Exceeded max length of 200!!!",
                                },
                                required: {
                                  value:
                                    watchReqCreditLimit !== "0" &&
                                    watchReqCreditLimit,
                                  message: REQUIRED_ERR_MSG,
                                },
                              })}
                            />
                            {errors.bankAcct && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.bankAcct.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Benificiary Name{" "}
                              {
                                (watchReqCreditLimit !== "0") && watchReqCreditLimit ?
                                (<span className="text-danger fw-bold">*</span>) :
                                ""
                              }
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("benificiaryName", {
                                maxLength: {
                                  value: 200,
                                  message: "Exceeded max length of 200!!!",
                                },
                                required: {
                                  value:
                                    watchReqCreditLimit !== "0" &&
                                    watchReqCreditLimit,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: ALPHANUM_SPACE_SPCL_CHAR,
                                  message: "Wrong Input !!!",
                                },
                              })}
                            />
                            {errors.benificiaryName && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.benificiaryName.message}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Bank Statement{" "}
                            </label>

                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register("bankStatement")}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectBankAndSSMFile("bankStatement", e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {bankStatementPlaceHolder
                                  ? bankStatementPlaceHolder
                                  : "Upload Bank Statement"}
                              </div>
                              <button
                                type="button"
                                style={{
                                  marginTop: "-30px",
                                  marginRight: "-13px",
                                }}
                                className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                disabled={
                                  isDisabled[
                                    `bankStatement.photoBucketUrl` +
                                      "attachment"
                                  ]
                                }
                                onClick={() =>
                                  download(
                                    watch("bankStatement.photoBucketUrl")
                                  )
                                }
                              >
                                {GetSvgIcon("IconFiledownload")}
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              SSM Document{" "}
                            </label>

                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register("ssmDoc")}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectBankAndSSMFile("ssmDoc", e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {ssmDocPlaceHolder
                                  ? ssmDocPlaceHolder
                                  : "Upload SSM Document"}
                              </div>
                              <button
                                style={{
                                  marginTop: "-30px",
                                  marginRight: "-13px",
                                }}
                                type="button"
                                className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                disabled={
                                  isDisabled[
                                    `ssmDoc.photoBucketUrl` + "attachment"
                                  ]
                                }
                                onClick={() =>
                                  download(watch("ssmDoc.photoBucketUrl"))
                                }
                              >
                                {GetSvgIcon("IconFiledownload")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      // (permission(STNDBKNG).allowedRead )
                      (LINEHAUL_STAFF_USER_TYPE === userType ||
                        LINEHAUL_ADMIN_USER_TYPE === userType) && (
                        <div>
                          <h5 className="theme-violet-txt">SST Exemption</h5>
                          <div className="w-100">
                            <div className="row">
                              {custPrflDetails.custType !== 143 && (
                                <>
                                  <div className="col-lg-4 mb-2">
                                    <label
                                      htmlFor=""
                                      className="form-label theme-label"
                                      title="This field is mandatory"
                                    >
                                      SST Exempted{" "}
                                    </label>
                                    <div className="input-group ">
                                      {/*add css class here*/}
                                      {/* <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgPerson({ required: false })}
                                </span> */}
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="sstExempt"
                                          id="sstRadio1"
                                          value={true}
                                          checked={
                                            !!watchIsSstExempted
                                              ? watchIsSstExempted === true ||
                                                watchIsSstExempted === "true"
                                              : false
                                          }
                                          {...register("sstExempt", {
                                            onChange: onIsExemptChange,
                                          })}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="sstRadio1"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="sstExempt"
                                          id="sstRadio2"
                                          value={false}
                                          checked={
                                            !!watchIsSstExempted
                                              ? watchIsSstExempted === false ||
                                                watchIsSstExempted === "false"
                                              : true
                                          }
                                          {...register("sstExempt", {
                                            onChange: onIsExemptChange,
                                          })}
                                        />
                                        <label
                                          className="form-check-label "
                                          htmlFor="sstRadio2"
                                        >
                                          No
                                        </label>
                                      </div>

                                      {/* <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span> */}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 mb-2">
                                    <label
                                      htmlFor=""
                                      className="form-label theme-label"
                                      title="This field is mandatory"
                                    >
                                      SST Number{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register("sstNumber", {
                                        // pattern: {
                                        //   value: NUMBER_FIVE_DECIMAL,
                                        //   message:
                                        //     "Please provide value as 00000.00!",
                                        // },
                                        // setValueAs: (v) => {
                                        //   const val = parseFloat(v);
                                        //   return isNaN(val) ? v : val;
                                        // },
                                        required: {
                                          value:
                                            watchIsSstExempted === "true" ||
                                            watchIsSstExempted === true
                                              ? true
                                              : false,
                                          message: REQUIRED_ERR_MSG,
                                        },
                                        disabled:
                                          watchIsSstExempted === "false" ||
                                          watchIsSstExempted === false
                                            ? true
                                            : false,
                                      })}
                                    />
                                    {watchIsSstExempted &&
                                      watchIsSstExempted !== "false" &&
                                      errors.sstNumber && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888; {errors.sstNumber.message}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                  <div className="col-lg-4 mb-2">
                                    <label
                                      htmlFor=""
                                      className="form-label theme-label"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title="This field is mandatory"
                                    >
                                      SST Start Date{" "}
                                    </label>
                                    <div>
                                      <Controller
                                        control={control}
                                        name="sstExemptStartDate"
                                        rules={{
                                          required: {
                                            value:
                                              watchIsSstExempted === "true" ||
                                              watchIsSstExempted === true
                                                ? true
                                                : false,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <ReactDatePicker
                                            placeholderText="start Date"
                                            className="form-control"
                                            dateFormat={DATE_FORMAT_CLIENT}
                                            disabled={
                                              watchIsSstExempted === "false" ||
                                              watchIsSstExempted === false
                                                ? true
                                                : false
                                            }
                                            minDate={new Date()}
                                            // maxDate={new Date(watchEndDate)}
                                            selected={
                                              value ? new Date(value) : value
                                            }
                                            onChange={(d) =>
                                              onChange(
                                                d !== null
                                                  ? moment(d).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : ""
                                              )
                                            }
                                          />
                                        )}
                                      />
                                      {watchIsSstExempted &&
                                        watchIsSstExempted !== "false" &&
                                        errors.sstExemptStartDate && (
                                          <div>
                                            <span className="badge bg-danger">
                                              &#9888;{" "}
                                              {
                                                errors.sstExemptStartDate
                                                  .message
                                              }
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                    {errors.creditLimit && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; {errors.creditLimit.message}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <div>
                      <h5 className="theme-violet-txt">General Document</h5>
                      <div className="w-100">
                        <div className="row">
                          {custPrflDetails?.genDocList?.map((item, index) => (
                            <>
                              <div
                                className="col-md-4 mb-2 position-relative"
                                key={index}
                              >
                                <div className="Upload-Photo w-100">
                                  <div className="uploaded-text ms-2" disabled>
                                    {item.photoFileName.split("/").pop()}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  style={{ marginTop: "-28px" }}
                                  className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                                  onClick={() => download(item.photoFileName)}
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                        <MultipleFileUpload
                          customData={customData}
                          customCss={customCss}
                          register={register}
                          clearErrors={clearErrors}
                          setValue={setValue}
                          watch={watch}
                          control={control}
                          remove={remove}
                          append={append}
                          generalDocList={generalDocList}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="w-100 mt-2">
              {(!location.state || location.state.from !== "CUSTMGT") &&
                custPrflDetails.approveStatus === APPROVAL_STATUS_PENDING && (
                  <div className="float-start">
                    {checkboxOptions.map((opt, index) => (
                      <div className="form-check form-check-inline mt-2">
                        <input
                          className="form-check-input radiobtnsty"
                          type="checkbox"
                          name="requestType"
                          value={opt.value}
                          checked={opt.value === requestType}
                          onChange={(event) => handleRequestType(event)}
                          key={index}
                        />
                        <span>{opt.label}</span>
                      </div>
                    ))}
                  </div>
                )}
              <div className="float-end mt-2">
                <button
                  type="button"
                  className="btn btn-default border me-2"
                  onClick={callback}
                >
                  Cancel
                </button>
                {permissionCheck(CMGDTLS, "allowedWrite") &&
                  custPrflDetails.profileStatus !== PROFILE_STATUS_REJECT && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={handleSubmit(onSubmit, formErr)}
                    >
                      Save
                    </button>
                  )}
                {permissionCheck(CUSTAPVLRESUB, "allowedWrite") &&
                  custPrflDetails.profileStatus === PROFILE_STATUS_REJECT &&
                  custPrflDetails.createdBy > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Resubmit
                    </button>
                  )}
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#success-modal"
                  id="modalPopup"
                  className="d-none"
                  ref={modalRef}
                />
              </div>
              <div className="clearfix"></div>
              {(requestType === "APPROVE" || requestType === "REJECT") && (
                <>
                  <div className="float-start mb-3 w-25">
                    <textarea
                      className="form-control"
                      placeholder="Comment"
                      maxLength={500}
                      onChange={handleComment}
                    />
                  </div>
                  <div className="clearfix"></div>
                  <div className="input-group w-25">
                    <input
                      type="file"
                      className="form-control"
                      aria-describedby="button-addon2"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(event) => selectFile("logAttachment", event)}
                    />
                  </div>
                </>
              )}
            </div>
          </main>

          <ApprovalLogList data={approvalHistoryData} />

          <Popup
            isSuccess={isSuccess}
            title={title}
            description={description}
            handler={callback}
          />
          <Spinner show={show} />
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;

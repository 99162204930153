import React, { useEffect } from "react";
import { useState } from "react";
import Header from "../../Layout/Header";
import GetSvgIcon from "../../../images/svglist";
import ad_1 from "../../../images/Banner_8.jpg";
import ad_2 from "../../../images/ad_4.jpg";
import ad_3 from "../../../images/ad_3.jpg";
import CustomInputWithChip from "../../Utilities/CustomInputWithChip";
import BookingSearchComponent from "./BookingSearchComponent";
import { useNavigate } from "react-router-dom";
import { fetchAddressBook, permission } from "../../Helper/CommonMethods";
import { STNDBKNG } from "../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { CallApiGet } from "../../Helper/serviceApi";
import { Card, Carousel, Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MMM,
  DATE_FORMAT_MOMENT,
} from "../../Helper/Constants";

const initData = {
  notifArr: [],
  addvertArr: [],
  bulletinArr: [],
};
const Home = () => {
  const navigate = useNavigate();
  const [wayBillNos, setWayBillNos] = useState([]);
  const ISR_USER_TYPE = "139";
  const USER_TYPE = getUserInfo("userType");
  const [addrbook, setAddrBook] = useState([]);
  const [isUserActive, setIsUserActive] = useState(false);
  const [data, setData] = useState(Object.assign({}, initData));
  const [index, setIndex] = useState(0);
  const [isPausedCarousel, setPausedCarousel] = useState(false);

  const trackShipment = () => {
    navigate("/trackWaybill", { state: wayBillNos });
  };

  useEffect(() => {
    setIsUserActive(getUserInfo("userActive"));
    fetchHomePageData();
    fetchAddressBook()
      .then((res) => {
        setAddrBook(res?.addressBook);
      })
      .catch((err) => {
        setAddrBook([]);
      });
  }, []);

  const getData = (resp, tableName) => {
    const tableObj = resp.respData.find((obj) => obj.tableName === tableName);
    return tableObj ? JSON.parse(tableObj.resultJsonStr || "[]") : [];
  };

  const fetchHomePageData = async () => {
    try {
      const resp = await CallApiGet("GetCombineResponse");
      if (resp.respCode === 200 && resp.respData) {
        let locData = {
          notifArr: getData(resp, "notification"),
          addvertArr: getData(resp, "advertisement"),
          bulletinArr: getData(resp, "bulletin"),
        };

        setData(locData);
      }
    } catch (error) {
      console.error("Failed to fetch home page data:", error);
    }
  };
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Header isHome={true} />
      <div className="container-fluid p-0 min-ht-100 wt-tbl">
        {(USER_TYPE === null ||
          (permission(STNDBKNG).allowedRead &&
            USER_TYPE !== ISR_USER_TYPE)) && (
          <>
            {!isUserActive ? (
              <BookingSearchComponent
                showToggler={true}
                showSlider={true}
                showSearchBtn={true}
                addressBook={addrbook}
                isReadOnly={false}
                disableOpt={false}
                setPausedCarousel={setPausedCarousel}
              />
            ) : (
              isUserActive === "true" && (
                <BookingSearchComponent
                  showToggler={true}
                  showSlider={true}
                  showSearchBtn={true}
                  addressBook={addrbook}
                  isReadOnly={false}
                  disableOpt={false}
                  setPausedCarousel={setPausedCarousel}
                />
              )
            )}
            <div className="col-xl-7 col-lg-9 col-md-12 m-auto py-3 ">
              <div className="w-100 my-3">
                <div className="input-group me-3">
                  <div className="w-75 ms-3">
                    <CustomInputWithChip
                      value={wayBillNos}
                      setValue={setWayBillNos}
                      className="rounded-1s p-0"
                    />
                  </div>
                  <button
                    className="btn btn-outline-secondary theme-violet rounded-1s"
                    type="button"
                    id="button-addon2"
                    title="Track"
                    onClick={trackShipment}
                    style={{ zIndex: "0" }}
                  >
                    Track Shipment {GetSvgIcon("ArrowRight")}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row mt-4">
          <div className=" home-content m-2">
            <div className="col-md-12">
              <div
                className={
                  permission(STNDBKNG).allowedRead &&
                  USER_TYPE !== ISR_USER_TYPE
                    ? "row"
                    : "row mt-4 me-1"
                }
              >
                <div className="col-md-3">
                  <div className="card mb-4 shadow">
                    <div className="card-header h6">
                      <span className="float-start">Notifications </span>
                    </div>
                    {/* <div className="card-body min-ht align-center">
                    <img
                      src={ad_1}
                      className="d-block w-100"
                      alt="..."
                      style={{ height: "350px" }}
                    />
                  </div> */}
                    <div className="card-body min-ht card-info">
                      <div className="my-2 text-start small">
                        {data?.notifArr.map((not) => (
                          <div className="mt-2">
                            <h4 className="small font-weight-bold mb-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-bell-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                              </svg>
                              {not?.title}
                            </h4>
                            <div>
                              {/* <i className="text-info">&#9632;</i> */}
                              {not?.description}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card mb-4 shadow">
                    <div className="card-header h6">
                      <span className="float-start">Promotions and Ads</span>
                    </div>
                    <div className="card-body min-ht">
                      <Carousel
                        activeIndex={index}
                        onSelect={handleSelect}
                        interval={isPausedCarousel ? null : 2000}
                        className="fixed-height-carousel-item"
                        // prevIcon=""
                        // nextIcon=""
                        indicators={false}
                      >
                        {data?.addvertArr.map((ad, idx) => (
                          <Carousel.Item key={idx}>
                            <div className="image-container">
                              <img
                                src={ad?.attachment}
                                className="d-block w-100 carousel-image"
                                alt={ad?.title}
                              />
                            </div>
                            <Carousel.Caption>
                              {/* <h5>{ad?.title}</h5>
                              <p>{ad?.description}</p> */}
                            </Carousel.Caption>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card mb-4 shadow">
                    <div className="card-header h6">
                      <span className="float-start">News & Bulletin</span>
                    </div>
                    <div className="card-body min-ht card-info">
                      <div className="my-2 text-start small">
                        {data?.bulletinArr.map((bul) => (
                          <Card className="mb-2" border="info">
                            <Card.Header className="p-1">
                              <Card.Title>
                                <div>
                                  {GetSvgIcon("Newspaper")}
                                  <span className="ms-1 ">
                                    {bul?.bulletinTitle}
                                  </span>
                                </div>

                                {/* <Row>
                                  <Col md={8}></Col>
                                  <Col md={4}></Col>
                                </Row> */}
                              </Card.Title>
                            </Card.Header>
                            <Card.Body className="p-1">
                              <div>
                                <small
                                  className="text-muted"
                                  style={{ fontSize: "12px" }}
                                >
                                  {moment(bul?.stampdate).format(
                                    DATE_FORMAT_MOMENT
                                  )}
                                </small>
                              </div>
                              <blockquote className="blockquote mb-0 font-14">
                                <p>{bul?.bulletinDescription}</p>
                              </blockquote>
                              <div className="font-12">
                                {/* {GetSvgIcon("CalenderIcon")} */}
                              </div>
                            </Card.Body>
                            {/* <Card.Footer>
                              {GetSvgIcon("CalenderIcon")}
                              <small className="text-muted ms-2">
                                {moment(bul?.stampdate).format(
                                  DATE_FORMAT_MOMENT
                                )}
                              </small>
                            </Card.Footer> */}
                          </Card>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Spinner show={show} /> */}
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import {
  readLocalStorage,
  writeLocalStorage,
} from "../../Helper/CommonMethods";
import { getSessionInfo, getUserInfo } from "../../AuthUtils/AuthUtils";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import AddressSearch from "./AddressSearch";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  DATE_TIME_ZONE_MOMENT_FORMAT,
  INIT_FROM_SRVC,
  INIT_TO_SRVC,
  addrKeys,
  DATE_FORMAT_MOMENT,
  minBookingDate,
  NUMBER_REGEX,
} from "../../Helper/Constants";
import {
  DELIVERY_SERVICE_ID,
  PICKUP_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import Spinner from "../../Layout/Spinner";
import ModalAlert from "../../Layout/ModalAlert";
import DateAvlSpaceSlider from "./DateAvlSpaceSlider";
import Toggler from "../../Utilities/Toggler";

export const options = [
  { label: "Pallet Express", value: "PLT" },
  // { label: "Full Truck", value: "FTL" },
];
const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initAddressModalConfig = {
  show: false,
  title: "",
};

const initAddrModel = {
  name: null,
  phone: null,
  address: null,
  city: null,
  state: null,
  postcode: null,
};

const BookingSearchComponent = (props) => {
  const {
    initBkngData,
    handleOptionSelect,
    showToggler,
    showSlider,
    showSearchBtn,
    updateBkngData,
    updateServiceCode,
    addressBook,
    isReadOnly,
    disableOpt,
    defaultOpt,
    setPausedCarousel,
  } = props;

  const initialSearchModel = {
    shipDate: minBookingDate.format(DATE_FORMAT_SERVER),
  };

  const [allSla, setAllSla] = useState([]);
  const [currentBookingData, setCurrentBookingData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  // const [hubMap, setHubMap] = useState(new Map());
  // const [cityMap, setCityMap] = useState(new Map());
  const [cityState, setCityState] = useState();
  const [avlSla, setAvlSla] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);
  const [midSla, setMidSla] = useState([]);
  const [previousClickble, setPreviousClickble] = useState(false);
  const [nextClickble, setNextClickble] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [truckTypes, setTruckTypes] = useState([]);
  const [defaultService, setDefaultService] = useState();
  const [fromService, setOriginService] = useState(
    JSON.parse(JSON.stringify(INIT_FROM_SRVC))
  );
  const [toService, setToService] = useState(
    JSON.parse(JSON.stringify(INIT_TO_SRVC))
  );
  const [states, setStates] = useState([]);
  const [statesBak, setStatesBak] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesBak, setCitiesBak] = useState([]);
  const [initCities, setInitCities] = useState([]);
  const [initStates, setInitStates] = useState([]);
  const [addressModalConfig, setAddressModalConfig] = useState(
    JSON.parse(JSON.stringify(initAddressModalConfig))
  );
  const [selectedDate, setSelectedDate] = useState(null);
  const [geofence, setGeofence] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  // const [sbuList, setSbuList] = useState([]);
  let nextClicked = false;

  const {
    register: bkngRegister,
    handleSubmit: bkngHandleSubmit,
    setValue: bkngSetValue,
    getValues: bkngGetValues,
    control: bkngControl,
    reset: bkngReset,
    watch: bkngWatch,
    formState: { errors, isValid },
  } = useForm({ defaultValues: initialSearchModel });
  // } = useEffect();

  const watchOpt = bkngWatch("option");
  const watchService = bkngWatch("serviceCode");

  useEffect(() => {
    if (defaultOpt === "FTL") {
      checkForFtl({ value: "FTL" });
    }
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      getSbuData();
      getStatesAndCity();
      setLoaded(true);
    }

    if (!!initBkngData) {
      bkngReset(initBkngData);
      setSelectedDate(
        moment(
          initBkngData.shipDate,
          DATE_TIME_ZONE_MOMENT_FORMAT,
          true
        ).format(DATE_FORMAT_SERVER)
      );
      // bkngSetValue("sbuFrom", initBkngData.origin)
      // bkngSetValue("sbuTo", initBkngData.destination)
      if (!!initBkngData?.fromAdrData) {
        const fromAddrL = Object.entries(initBkngData?.fromAdrData)
          .filter(([k, v]) => addrKeys.includes(k))
          .map(([k, v]) => v);
        bkngSetValue("fromLabel", fromAddrL);
      }
      if (!!initBkngData?.toAdrData) {
        const toAddrL = Object.entries(initBkngData?.toAdrData)
          .filter(([k, v]) => addrKeys.includes(k))
          .map(([k, v]) => v);
        bkngSetValue("toLabel", toAddrL);
      }
      bkngSetValue("fromSbuId", initBkngData?.sbuFrom);
      bkngSetValue("toSbuId", initBkngData?.sbuTo);
      bkngSetValue("isSearchBtnClicked", false);
      setOriginService(initBkngData.fromService);
      setToService(initBkngData.toService);
      initBkngData.customerId = !!readLocalStorage("customerId")
        ? parseInt(readLocalStorage("customerId"))
        : 0;
      initBkngData.isSearchBtnClicked = false;
      fetchShipmentOptions(initBkngData);
    } else {
      bkngSetValue("fromAdrData", JSON.parse(JSON.stringify(initAddrModel)));
      bkngSetValue("toAdrData", JSON.parse(JSON.stringify(initAddrModel)));
    }

    if (!!defaultOpt) {
      bkngSetValue("option", defaultOpt);
    }

    // if (!!argAllSla) {
    //   postSearch(argAllSla, initBkngData)
    // }
  }, [initBkngData]);

  const getStatesAndCity = async () => {
    let cityArr = [];
    let stateArr = [];
    const stateSet = new Set();
    const citySet = new Set();
    let data = await CallApiGet("getStateAndCityList");
    let state, city;

    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        state = {
          label: element.map_state,
          value: element.map_state,
        };
        if (!stateSet.has(JSON.stringify(state))) {
          stateArr.push(state);
          stateSet.add(JSON.stringify(state));
        }
        city = {
          label: element.map_city,
          value: element.map_city,
        };
        if (!cityArr.includes(element.map_city)) {
          cityArr.push(city);
          citySet.add(JSON.stringify(city));
        }
      });
      setStates(stateArr);
      setCities(cityArr);
      setStatesBak(stateArr);
      setCitiesBak(cityArr);
      setInitCities(cityArr);
      setInitStates(stateArr);

      setGeofence(!!data?.respData ? data?.respData : []);
    } else {
      setStates(stateArr);
      setCities(cityArr);
      setInitCities(cityArr);
      setInitStates(stateArr);
      setGeofence([]);
    }
  };

  const getSbuData = async () => {
    const acctId = !!getUserInfo("acctId")
      ? parseInt(getUserInfo("acctId"))
      : 0;
    const storedCustId = !!readLocalStorage("customerId")
      ? parseInt(readLocalStorage("customerId"))
      : 0;
    const argCustId = storedCustId === 0 ? acctId : storedCustId;

    // let sbuData = await CallApiGet("get-sbu-for-dropdown");
    let cityResponse = await CallApiGet(
      "getBookingStateAndCity?argCustId=" + argCustId
    );

    if (cityResponse.respCode === 0 && !!cityResponse.respData) {
      setCityState(cityResponse?.respData);
    } else {
      setCityState([]);
    }
    let data = location.state;
    if (cityResponse.respCode === 200 && !!data) {
      bkngReset(data);
      searchSlas(data);
    }

    let slaListResp = await CallApiPost(
      "ListServiceForBooking?argCustId=" + argCustId,
      {}
    );
    let slaArr = [];
    if (slaListResp.respCode === 200) {
      slaListResp.respData.forEach((data) => {
        slaArr.push({
          label: data.serviceName + "(" + data.targetDays + " Days)",
          value: data.serviceCode,
          isPickup: data.isPickup,
          isDelivery: data.isDelivery,
        });
        if (data.serviceDefault === true) setDefaultService(data);
      });
      // console.log(slaArr);
    }
    setAvlSla(slaArr);
  };

  const searchSlas = async (data) => {
    if (watchOpt !== "FTL") {
      const isPickUp = fromService[0]?.isChecked;
      const isDelivery = toService[0]?.isChecked;
      let origin = isPickUp
        ? bkngGetValues("fromSbuCode")
        : cityState?.find((opt) => opt.value === data?.sbuFrom)?.label;
      let destination = isDelivery
        ? bkngGetValues("toSbuCode")
        : cityState?.find((opt) => opt.value === data?.sbuTo)?.label;

      if (isPickUp) {
        data.sbuFrom = bkngGetValues("fromSbuId");
      }
      if (isDelivery) {
        data.sbuTo = bkngGetValues("toSbuId");
      }
      // data.sbuFrom = isPickUp
      //   ? bkngGetValues("fromSbuId")
      //   : hubMap.get(cityMap.get(origin));
      // data.sbuTo = isDelivery
      //   ? bkngGetValues("toSbuId")
      //   : hubMap.get(cityMap.get(destination));
      data.origin = origin;
      data.destination = destination;

      data.customerId = !!readLocalStorage("customerId")
        ? parseInt(readLocalStorage("customerId"))
        : 0;

      data.fromService = fromService;
      data.toService = toService;
      data.bkngService = avlSla.find(
        (srvc) => srvc.value === data.serviceCode
      )?.label;
      data.isDelivery = isDelivery;
      data.isPickUp = isPickUp;
      data.shipDate = moment(data?.shipDate).format(DATE_FORMAT_SERVER);
      data.isSearchBtnClicked = true;

      await fetchShipmentOptions(data);
    } else {
      if (!!defaultService) {
        let truckName = truckTypes.filter(
          (e) => e.value === bkngGetValues("truckType")
        )[0].label;

        writeLocalStorage("FtlDate", bkngGetValues("shipDate"));
        writeLocalStorage("FtlTuck", bkngGetValues("truckType"));
        writeLocalStorage("FtlTuckName", truckName);
        writeLocalStorage("ServiceCode", defaultService.serviceCode);

        const session = getSessionInfo();
        if (session && session !== null) {
          navigate("/bookingChartered");
        } else {
          // localStorage.clear();
          writeLocalStorage("isLogout", true);
          navigate("/login");
        }
      } else {
        showAlert({
          isSuccess: false,
          title: "Service not available",
          description: "Default Service not available",
        });
      }
    }
  };

  const fetchShipmentOptions = async (argBkngData) => {
    setShow(true);
    let resp = await CallApiPost("getShipmentOptions", argBkngData);
    if (resp.respCode === 200 && !!resp?.respData) {
      argBkngData.cacheId = resp.respData?.cacheId;
      argBkngData.serviceCode = resp.respData?.serviceCode;
      bkngSetValue("serviceCode", resp.respData?.serviceCode);
      setCurrentBookingData(argBkngData);
      setSelectedDate(argBkngData?.shipDate);
      if (!!updateServiceCode) {
        updateServiceCode(argBkngData?.serviceCode);
      }
      if (!!updateBkngData && argBkngData?.isSearchBtnClicked) {
        updateBkngData(argBkngData);
      }
      await postSearch(resp?.respData, argBkngData, resp?.respMessage);
    } else {
      if (!nextClicked) {
        setAllSla([]);
        setMidSla([]);
        setErrorMsg(resp.respMessage);
      } else {
        bkngSetValue(
          "shipDate",
          moment(bkngGetValues("shipDate"))
            .subtract(2, "d")
            .format("yyyy-MM-DD")
        );

        showAlert({
          isSuccess: false,
          title: "",
          description: "No Further Data Available",
        });
      }
    }
    nextClicked = false;
    setShow(false);
  };

  const postSearch = async (argAllSla, argBkngData, argMsg) => {
    if (!!handleOptionSelect) {
      const selectedSla = argAllSla.sevenDayPrice.find(
        (ele) => ele.shipOn === argBkngData?.shipDate
      );
      handleOptionSelect(selectedSla, argBkngData?.pallets);
    }
    let midArr = [];
    setAllSla(argAllSla);
    if (argAllSla.sevenDayPrice.length === 1) setNextClickble(true);

    argAllSla.sevenDayPrice.forEach((e, i) => {
      if (i !== 0 && i !== argAllSla.sevenDayPrice.length - 1) {
        midArr.push(e);
      }
    });
    setMidSla(midArr);
    validateOptionLastDate(argAllSla.sevenDayPrice);
  };

  const logErr = (err) => {
    console.log(err);
  };

  const getSelectedSla = async (item) => {
    setSelectedDate(item.shipOn);
    if (!!handleOptionSelect) {
      bkngSetValue("shipDate", item.shipOn);
      handleOptionSelect(item, bkngGetValues("pallets"));
    } else {
      if (item.freeSpace >= bkngGetValues("pallets") && item.holiday !== true) {
        currentBookingData.shipDate = item.shipOn;
        writeLocalStorage(
          "initialBookingData",
          JSON.stringify(currentBookingData)
        );
        writeLocalStorage("BookingSla", JSON.stringify(item));
        writeLocalStorage("slaList", JSON.stringify(allSla));

        let session = getSessionInfo();

        if (session && session !== null) {
          navigate("/bookingStnd");
        } else {
          // localStorage.clear();
          writeLocalStorage("isLogout", true);
          navigate("/login");
        }
      }
    }
  };

  const handelOptionsNextButtonClick = () => {
    bkngSetValue(
      "shipDate",
      moment(bkngGetValues("shipDate")).add(2, "d").format("yyyy-MM-DD")
    );
    nextClicked = true;
    bkngHandleSubmit(searchSlas, logErr)();
  };

  const handelOptionsPreviousButtonClick = () => {
    if (previousClickble) {
      let daysDiff =
        (new Date(allSla.sevenDayPrice[0].shipOn).getTime() - new Date()) /
        (1000 * 3600 * 24);
      let daysToSearch = daysDiff > 2 ? 2 : daysDiff > 0 ? 1 : 0;
      // let d =moment(allSla.sevenDayPrice[0].shipOn).diff(new Date(),'days')
      // console.log("daysDiff--->",d)

      bkngSetValue(
        "shipDate",
        moment(bkngGetValues("shipDate"))
          .subtract(daysToSearch, "d")
          .format("yyyy-MM-DD")
      );

      bkngHandleSubmit(searchSlas, logErr)();
    }
  };

  const getTruckTypes = async () => {
    setShow(true);
    let resp = await CallApiGet("getAllVhclTypes");
    setShow(false);

    if (resp.respCode === 200 || resp.respCode === 0) {
      let data = [];
      resp.respData.forEach((e) => {
        if (e.isFullTruck) {
          data.push({
            value: e.id,
            label: e.vhclType,
          });
        }
      });
      setTruckTypes(data);
    }
  };

  const validateOptionLastDate = (slaData) => {
    setPreviousClickble(!minBookingDate.isSame(slaData[0].shipOn, "day"));
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const checkForFtl = (item) => {
    if (item && item.value === "FTL" && truckTypes.length === 0) {
      getTruckTypes();
    }
    if (item && item.value === "FTL") {
      bkngSetValue("sbuFrom", "");
      bkngSetValue("sbuTo", "");
      bkngSetValue("serviceCode", "");
      bkngSetValue("pallets", "");
    } else bkngSetValue("truckType", "");
  };

  const togglerFromHandler = (key, value) => {
    const tempOrgSrvc = [...fromService];
    const updatedSrvc = tempOrgSrvc.map((ele) => {
      if (ele.label === key) {
        return { ...ele, isChecked: true };
      } else {
        return { ...ele, isChecked: false };
      }
    });
    setOriginService(updatedSrvc);
    if (value === PICKUP_SERVICE_ID) {
      openAddressModal(
        "Pickup Address",
        PICKUP_SERVICE_ID,
        bkngGetValues("fromAdrData")
      );
    }
  };

  const togglerToHandler = (key, value) => {
    const tempToSrvc = [...toService];
    const updatedToSrvc = tempToSrvc.map((ele) => {
      if (ele.label === key) {
        return { ...ele, isChecked: true };
      } else {
        return { ...ele, isChecked: false };
      }
    });
    setToService(updatedToSrvc);
    if (value === DELIVERY_SERVICE_ID) {
      openAddressModal(
        "Delivery Address",
        DELIVERY_SERVICE_ID,
        bkngGetValues("toAdrData")
      );
    }
  };

  const openAddressModal = (argTitle, argServiceId, argAddrData) => {
    if (!!setPausedCarousel) {
      setPausedCarousel(true);
    }
    setStates(statesBak);
    setCities(citiesBak);
    setAddressModalConfig({
      show: true,
      title: argTitle,
      serviceId: argServiceId,
      argAddrData: argAddrData,
    });
  };

  const closeAddressModal = () => {
    setAddressModalConfig({
      show: false,
      title: "",
      serviceId: 0,
      argAddrData: JSON.parse(JSON.stringify(initAddrModel)),
    });
    if (!!setPausedCarousel) {
      setPausedCarousel(false);
    }
  };

  const checkForPickupDelivery = (serviceCode) => {
    const service = avlSla?.find((sla) => sla.value === serviceCode);
    bkngSetValue("isPickupToggler", service?.isPickup);
    bkngSetValue("isDeliveryToggler", service?.isDelivery);
    setOriginService(JSON.parse(JSON.stringify(INIT_FROM_SRVC)));
    setToService(JSON.parse(JSON.stringify(INIT_TO_SRVC)));
  };

  return (
    <>
      <div className="theme-blue-grd">
        <div className="px-3">
          <div className="row">
            <div className={watchOpt === "FTL" ? "col-2" : "col-auto"}>
              <label
                htmlFor="floatingSelectGrid"
                className="pb-2 text-white fs-6"
              >
                Option
              </label>
              <div className="form-floating custom-ht">
                <Controller
                  name="option"
                  control={bkngControl}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={options}
                      placeholder="-Select-"
                      value={
                        value
                          ? options.find((opt) => opt.value === value)
                          : value
                      }
                      onChange={(d) => {
                        onChange(d ? d.value : d);
                        if (!!d && d.value === "FTL") {
                          bkngSetValue("pallets", "1");
                        }
                        checkForFtl(d);
                      }}
                      // isSearchable={true}
                      isClearable={true}
                      className={
                        disableOpt
                          ? "form-control search-date-disabled"
                          : "form-control "
                      }
                      isDisabled={disableOpt}
                    />
                  )}
                  // rules={{ required: true }}
                />
                {errors.isExchange && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; This Field can not be blank!
                    </span>
                  </div>
                )}
              </div>
            </div>
            {
              <>
                {watchOpt !== "FTL" && (
                  <div className="col-2">
                    <label
                      htmlFor="floatingSelectGrid"
                      className="pb-2 text-white fs-6"
                    >
                      Service
                    </label>
                    <div className="form-floating custom-ht">
                      <Controller
                        name="serviceCode"
                        control={bkngControl}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={avlSla}
                            placeholder="-Select-"
                            value={
                              value
                                ? avlSla?.find(
                                    (opt) =>
                                      opt.value.split("-")[0] ===
                                      value.split("-")[0]
                                  )
                                : value
                            }
                            onChange={(d) => {
                              onChange(d ? d.value : d);
                              checkForPickupDelivery(d ? d.value : d);
                            }}
                            isClearable={true}
                            isDisabled={isReadOnly}
                            className={
                              isReadOnly
                                ? "form-control search-date-disabled"
                                : "form-control "
                            }
                          />
                        )}
                        rules={{ required: watchOpt === "FTL" ? false : true }}
                      />
                      {errors.serviceCode && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field can not be blank!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {watchOpt !== "FTL" && (
                  <div className={watchOpt === "FTL" ? "col-4" : "col"}>
                    <div className="d-flex align-item-center mb-1">
                      <label
                        htmlFor="floatingInputGrid"
                        className="pb-1 text-white fs-6"
                      >
                        At Origin
                      </label>
                      {!!showToggler && showToggler ? (
                        bkngWatch("isPickupToggler") === true ? (
                          <div className="ms-3">
                            <Toggler
                              btnName="btn_from"
                              isBtn1Checked={fromService[0]?.isChecked}
                              isBtn2Checked={fromService[1]?.isChecked}
                              btn1Value={fromService[0]?.value}
                              btn2Value={fromService[1]?.value}
                              btn1Label={fromService[0]?.label}
                              btn2Label={fromService[1]?.label}
                              handler={togglerFromHandler}
                            />
                          </div>
                        ) : !!watchService ? (
                          <div className="ms-3 rounded-pill theme-violet-lodgein font-12 py-1 px-3">
                            Lodge in
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-floating custom-ht ">
                      {fromService[0]?.isChecked ? (
                        <div className="input-group input-group-sm mb-3">
                          <textarea
                            name="fromLabel"
                            className={
                              isReadOnly
                                ? "form-control p-1 resize-none search-date-disabled"
                                : "form-control p-1 resize-none"
                            }
                            readOnly
                            {...bkngRegister("fromLabel")}
                          ></textarea>
                          {!isReadOnly && (
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-sm"
                              onClick={() =>
                                openAddressModal(
                                  "Pickup Address",
                                  PICKUP_SERVICE_ID,
                                  bkngGetValues("fromAdrData")
                                )
                              }
                            >
                              {GetSvgIcon("EditPencil")}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Controller
                          name="sbuFrom"
                          control={bkngControl}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={cityState}
                              placeholder="-Select-"
                              value={
                                value
                                  ? cityState?.find(
                                      (opt) => opt.value === value
                                    )
                                  : value
                              }
                              onChange={(d) => onChange(d ? d.value : d)}
                              isSearchable={true}
                              isClearable={true}
                              className={
                                isReadOnly
                                  ? "form-control search-date-disabled"
                                  : "form-control "
                              }
                              isDisabled={isReadOnly}
                            />
                          )}
                          rules={{
                            required: watchOpt === "FTL" ? false : true,
                          }}
                        />
                      )}

                      {errors.sbuFrom && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field can not be blank!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {watchOpt !== "FTL" && (
                  <div className="col-3">
                    <div className="d-flex align-item-center mb-1">
                      <label
                        htmlFor="floatingSelectGrid"
                        className="pb-1 text-white fs-6"
                      >
                        At Destination
                      </label>
                      {!!showToggler && showToggler ? (
                        bkngWatch("isDeliveryToggler") === true ? (
                          <div className="ms-3">
                            <Toggler
                              btnName="btn_to"
                              isBtn1Checked={toService[0]?.isChecked}
                              isBtn2Checked={toService[1]?.isChecked}
                              btn1Value={toService[0]?.value}
                              btn2Value={toService[1]?.value}
                              btn1Label={toService[0]?.label}
                              btn2Label={toService[1]?.label}
                              handler={togglerToHandler}
                            />
                          </div>
                        ) : !!watchService ? (
                          <div className="ms-3 rounded-pill theme-violet-lodgein font-12 py-1 px-3">
                            Self Collect
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-floating custom-ht">
                      {toService[0]?.isChecked ? (
                        <div className="input-group input-group-sm mb-3">
                          <textarea
                            name="toLabel"
                            className={
                              isReadOnly
                                ? "form-control p-1 resize-none search-date-disabled"
                                : "form-control p-1 resize-none"
                            }
                            readOnly
                            {...bkngRegister("toLabel")}
                          ></textarea>
                          {!isReadOnly && (
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-sm"
                              onClick={() =>
                                openAddressModal(
                                  "Delivery Address",
                                  DELIVERY_SERVICE_ID,
                                  bkngGetValues("toAdrData")
                                )
                              }
                            >
                              {GetSvgIcon("EditPencil")}
                            </span>
                          )}
                        </div>
                      ) : (
                        <Controller
                          name="sbuTo"
                          control={bkngControl}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={cityState}
                              placeholder="-Select-"
                              value={
                                value
                                  ? cityState?.find(
                                      (opt) => opt.value === value
                                    )
                                  : value
                              }
                              onChange={(d) => onChange(d ? d.value : d)}
                              isSearchable={true}
                              isClearable={true}
                              isDisabled={isReadOnly}
                              className={
                                isReadOnly
                                  ? "form-control search-date-disabled"
                                  : "form-control "
                              }
                            />
                          )}
                          rules={{
                            required: watchOpt === "FTL" ? false : true,
                          }}
                        />
                      )}

                      {errors.sbuTo && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; This Field can not be blank!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            }
            <div className={watchOpt !== "FTL" ? "col-1 mb-3" : "col-3 mb-3"}>
              <label className="pb-2 text-white fs-6">Date</label>
              <div
                className={
                  isReadOnly
                    ? "form-floating booking-date-custom search-date-disabled"
                    : "form-floating booking-date-custom"
                }
                style={{ background: "#fff" }}
              >
                <Controller
                  control={bkngControl}
                  name="shipDate"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Booking Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      minDate={new Date(minBookingDate)}
                      selected={
                        !!value
                          ? new Date(moment(value, DATE_FORMAT_SERVER, true))
                          : new Date(minBookingDate)
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format(DATE_FORMAT_SERVER) : d)
                      }
                      disabled={isReadOnly}
                    />
                  )}
                />
                {/* moment().add(1, 'days') */}
                {errors.isExchange && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; This Field can not be blank!
                    </span>
                  </div>
                )}
              </div>
            </div>

            {watchOpt !== "FTL" ? (
              <div className="col-1 mb-3 position-relative">
                <label
                  htmlFor="floatingSelectGrid"
                  className="pb-2 text-white fs-6"
                >
                  {watchOpt === "FTL" ? "Trucks" : "Pallet Space"}
                </label>
                <div className="form-floating g-2 custom-ht">
                  <input
                    type="number"
                    className="form-control ps-2"
                    placeholder=""
                    // value="2"
                    {...bkngRegister("pallets", {
                      required: watchOpt === "FTL" ? false : true,
                      pattern: NUMBER_REGEX,
                      min: 1,
                    })}
                    disabled={isReadOnly}
                  />
                  {errors.pallets && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; This Field can not be blank!
                      </span>
                    </div>
                  )}
                  <span
                    data-tooltip-id="palletTip"
                    className="mx-2 my-3 theme-violet-txt position-absolute top-0 end-0"
                  >
                    {GetSvgIcon("ToolTip")}
                  </span>
                </div>
                <Tooltip
                  id="palletTip"
                  // content="1 pallet space is 1.1m(L) x 1.1m(W) x 2.1m(H)"
                  place="left"
                  style={{ zIndex: "100" }}
                >
                  Pallet Dimension: 1.1m(L) x 1.1m(W) x 2.1m(H) <br />
                  Pallet Space should be greater than 0
                </Tooltip>
              </div>
            ) : (
              <div className="col-4 mb-3 position-relative">
                <label
                  htmlFor="floatingSelectGrid"
                  className="stypallet pb-2 text-white fs-6"
                >
                  Vehicle Type
                </label>
                <div className="form-floating g-2 custom-ht">
                  <Controller
                    name="truckType"
                    control={bkngControl}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={truckTypes}
                        placeholder="-Select-"
                        value={
                          !!value
                            ? truckTypes.find((opt) => opt.value === value)
                            : value
                        }
                        onChange={(d) => onChange(d ? d.value : d)}
                        // isSearchable={true}
                        isClearable={true}
                        className="form-control"
                      />
                    )}
                    rules={{ required: true }}
                  />
                  {errors.isExchange && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; This Field can not be blank!
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {!!showSearchBtn && showSearchBtn && (
              <div className="col-auto align-self-center mt-2">
                <button
                  className={
                    !isValid
                      ? "btn-primary w-100 rounded-pill font-18 py-1 not-allowed-cursor"
                      : "btn-primary-search rounded-pill theme-violet font-18 py-1"
                  }
                  type="button"
                  disabled={!isValid}
                  onClick={bkngHandleSubmit(searchSlas, logErr)}
                >
                  {watchOpt === "FTL" ? "Book Now" : "Search"}
                </button>
              </div>
            )}
          </div>

          {!!showSlider && showSlider && (
            <DateAvlSpaceSlider
              handler={getSelectedSla}
              allSla={allSla}
              midSla={midSla}
              selectedPallets={bkngGetValues("pallets")}
              isLeftClick={previousClickble}
              handleLeftClick={handelOptionsPreviousButtonClick}
              isRightClick={nextClickble}
              handleRightClick={handelOptionsNextButtonClick}
              errorMsg={errorMsg}
              selectedShipDate={selectedDate}
            />
          )}
        </div>
      </div>
      <Spinner show={show} />
      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}
      <AddressSearch
        config={addressModalConfig}
        geofence={geofence}
        initCities={initCities}
        handleClose={closeAddressModal}
        states={states}
        cities={cities}
        setter={bkngSetValue}
        showSpinner={setShow}
        setCities={setCities}
        addressBook={addressBook}
        setStates={setStates}
        initStates={initStates}
      />
      {/* <a href="/bookingStnd?orderId=81">quick book</a> */}
    </>
  );
};
export default BookingSearchComponent;

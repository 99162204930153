export const initValUser ={
    id : 0,
    accountId : '',
    userName : '',
    address : '',
    countryCode : "+60",
    email : '',
    phone : '',
    profilePhoto : '',
    userRoleId : [],
    sbuTypeId : '',
    sbuId : '',
    roleName : '',
    sbuName : '',
    status : '',
    userTypeId : '',
    postCode : '',
    city : '',
    state : '',
    userIdCard : '',
    accountCode : '',
    isActive : true,
    roleNames:'',
    secondarySbuIds:[],
    // userDetails : [{
    //     infoId : 0, 
    //     documentType : '',
    //     docNumber : '',
    //     expiresOn : '',
    //     remarks : '',
    //     infoAttachment : ''
    // }],
    remarks : ''
}
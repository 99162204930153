import React, { useState } from 'react'
import { useEffect } from 'react';
import {CallApiGet, ReactVersion } from '../Helper/serviceApi';
import Header from "../Layout/Header";

export default function AboutUs() {
const [version,setVersion]=useState([]);
const [reactVersion,setReactVersion]=useState('');
 
    useEffect(()=>{
        getApplicationVersion()
    },[])
    const getApplicationVersion = async () =>{
      setReactVersion("React Version :"+ReactVersion)
        let data = await CallApiGet("getAppVersion");
        if(data.respCode === 0){
          let springVersions=data.respData.split(",");
           setVersion(springVersions)
        }
    }
  return (
    <>
      <Header/>
      <div className="container-fluid min-ht-100">
        <div className="row">
        <main className="col-md-12 p-3">
        <div>{reactVersion}</div>
        { 
           version.map((iteam)=>(
           <div>{iteam}</div>
           ))
      }
      
      </main>
          </div>
          
      </div>
        
    </>
  )
}

import moment from "moment";
import {
  DELIVERY_SERVICE_ID,
  PICKUP_SERVICE_ID,
} from "../../ConfigConstants/ConfigConstants";

export const ALPHANUM_SPACE_REGEX = /^([ ]{0,1}[a-zA-Z0-9]+[ ]{0})*$/;
export const ALPHANUM_SPACE_SPCL_CHAR = /^[\w\-_(){}[\]\\/,.\s&]*$/;
export const TEXT_AREA_REGEX = /^[\w\-_(){}[\]\\/,.\s:&']*$/;
export const NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR = /^[\w\-_(){}[\]\\/,.\s&]+$/;
export const ALPHANUM_COMA_DOT_SLASH_HIPHEN_UNDERSCOE_REGEX =
  /^([ ]{0,1}[a-zA-Z0-9,./_-]+[ ]{0})*$/;
export const DD_MM_YYYY_REGEX =
  /^([0-2][0-9]|(3)[0-1])(-)((0)[0-9]|(1)[0-2])(-)\d{4}$/;
export const YYYY_MM_DD_REGEX =
  /^\d{4}(-)((0)[0-9]|(1)[0-2])(-)([0-2][0-9]|(3)[0-1])$/;
export const YYYY_MM_REGEX = /^\d{4}(-)((0)[0-9]|(1)[0-2])$/;
export const ALPHANUM_NAME = /^([ ]{0,1}[a-zA-Z0-9]+[ ]{0})*$/;
export const NAME_REGEX = /^[\w\-_(),.&\s]*$/;
export const PHONE = /^[+][0-9]{8,11}$/;
export const PHONE_NUM = /^[0-9]{8,11}$/;
export const DECIMAL_REGEX = /^\d+(\.\d+)?$/;
export const NUMBER_TWO_DECIMAL = /^\d*(\d{3})*(\.\d{1,2})?$/;
export const TWO_DECIMAL = /^(\d{1,9})(\.\d{1,2})?$/;
export const NUMBER_THREE_DECIMAL = /^\d{1,3}(?:\.\d{1,2})?$/;
export const NUMBER_FOUR_DECIMAL = /^\d{1,4}(?:\.\d{1,2})?$/;
export const NUMBER_FIVE_DECIMAL = /^\d{1,5}(?:\.\d{1,2})?$/;
export const NUMBER_NINE_DECIMAL = /^\d{1,9}(?:\.\d{1,2})?$/;
export const EMAIL = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const ALPHANUM_DOT_SLASH_HIPHEN_UNDERSCORE_REGEX =
  /^([ ]{0,1}[a-zA-Z0-9./_-]+[ ]{0})*$/;
export const ALPHABET_REGEX = /^[a-zA-Z0-9\s]*$/;
export const NUMBER_SPACE_REGEX = /^\s*\d+\s*$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const NON_EMPTY_NUMBER_REGEX = /^[0-9]+$/;
export const ALPHANUM = /^[a-zA-Z0-9]*$/;
export const ALPHANUM_SPACE_SLASH_REGEX = /^([ ]{0,1}[a-zA-Z0-9/]+[ ]{0})*$/;
export const URL_REGEX =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
export const ADDRESS = /^([ ]{0,1}[a-zA-Z0-9\r\n,./_-]+[ ]{0})*$/;
export const DATE_TIME_ZONE_MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";
export const DATE_FORMAT_MOMENT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT_MOMENT = "DD-MM-YYYY HH:mm";
export const DATE_FORMAT_CLIENT = "dd-MM-yyyy";
export const DATE_FORMAT_SERVER = "YYYY-MM-DD";
export const DATE_FORMAT_MMM = "DD-MMM-YY";
export const TIME_FORMAT_CLIENT = "h:mm aa";
export const TIME_FORMAT_SERVER = "HH:mm";
export const TIME_24_REGEX = /^([01]\d|2[0-3]):[0-5]\d$/;
export const REQUIRED_ERR_MSG = "This Field is required!!!";
export const PATTERN_ERR_MSG = "Incorrect Data!!!";
export const REQUIRED_ONE_ERR_MSG = "Chose one!!!";
export const VALID_PERCENT_ERR_MSG = "Invalid Percentage!!!";
export const VALID_LENGTH_ERR_MSG = "Invalid Size!!!";
export const POST_CODE = /^\\d{5}$/;
export const COMPANY_REGD_NO_REGEX = /\d{12}\([0-9]+-[A-Z]\)/;
export const TWO_DIGIT_DAY = /^([0-2]\d|[3][01])$/;
export const LATPATTERN = /^-?([1-8]?[0-9]\.{1}\d+|90\.{1}0+)$/;
export const LATPATTERN_5 = /^-?(?:90(?:\.0{1,5})?|[1-8]?\d(?:\.\d{1,5})?)$/;
export const LONGPATTERN = /^-?((1[0-7][0-9]|[1-9]?[0-9])\.{1}\d+|180\.{1}0+)$/;
export const LONGPATTERN_5 =
  /^-?(?:180(?:\.0{1,5})?|1[0-7]\d(?:\.\d{1,5})?|[1-9]?\d(?:\.\d{1,5})?)$/;
export const SBU_PHONE_NUM = /^[0-9-/ ]{8,30}$/;
export const CHIN_ENG_SPCL_CHAR = /^[\u4E00-\u9FFF \w+-_(){}[\]\\/,;.:*\s&]*$/;
export const BLACK_SPCL_CHARS = /[!#$%^?`~<>]/;
export const BLACK_SPCL_CHARS_STR = "!#$%^?`~<>";
export const TWO_THREE_DECIMAL = /^\d*(\d{3})*(\.\d{1,3})?$/;
export const WORD_REGEX = /^[a-zA-Z\s]*$/;
export const LAT_ERR_MSG =
  "A latitude should range from -90 to +90 and should have 5 decimal points!!!";
export const LONG_ERR_MSG =
  "A longitude should range from -180 to +180 and should have 5 decimal points!!!";

export const sizeLowerBound = 0;
export const sizeUpperBound = 500;
export const defaultPageLimit = "10";
export const defaultPageOffset = "0";
export const defaultRowsPerPageOptions = [10, 50, 100];
export const maxVhclSizeForHQ = 24;
export const CUST_TYPE_PERSONAL = "PERSONAL";
export const CUST_TYPE_BUSINESS = "BUSINESS";
export const CUST_TYPE_AGENT = "AGENT";
export const FILE_UPLOAD_TEXT = "Choose a File To Upload";
export const BTN_TYPE_EDIT = "EDIT";
export const BTN_TYPE_ADD = "ADD";
export const SERVER_ERR_MSG =
  "Something went wrong. Please try after sometime!!!";
export const NET_ERR_MSG = "Network Error!!!";
export const INVALID_COMPANY_REGD_NO_ERROR_MSG =
  "Please provide a valid value, e.g., 201901042436(1351766-K)";
export const EMPTY_PALLETS = "Please Enter Pallets";
export const EMPTY_AMOUNT = "Please Enter Amount";
export const INVALID_VALUE = "Please Enter a valid Value";
export const BOOKING_DATE_NOT_FOUND_MSG = "Please select a booking date";
export const MAX_REQ_CREDIT_AMOUNT = 999999999.99;
export const ROUTE_TYPE_FEEDEER = 27;
export const ROUTE_TYPE_LONG_HAUL = 30;
export const INVALID_NAME = "Contains invalid characters";
export const PTRN_ERR_MSG = "Field contains invalid character!!";
export const ROUTE_CODE_LENGTH = 100;
export const SBU_CODE_LENGTH = 20;
export const SST_APPLY_DATE = "2024-03-01";
export const COUNTRY_CODES = [
  { label: "+60", value: "+60" },
  { label: "+62", value: "+62" },
  { label: "+65", value: "+65" },
  { label: "+66", value: "+66" },
  { label: "+84", value: "+84" },
  { label: "+86", value: "+86" },
];

export const DELIVERYSTATUSCODE = 80;
export const PENDINGPAY_WAIT_CANCEL_ARR = [
  "Cancelled",
  "Waiting",
  "Incomplete Payment",
];

export const customDataTableStyles = {
  headCells: {
    style: {
      fontSize: "14px",
      borderBottom: "2px solid #38b1aa",
      height: "95px",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
    },
  },
};

export const customReactSelectMenuStyle = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (base) => ({ ...base, zIndex: 9999 }),
};

export const LASTMILE_PLANNING = {
  PICKUP: "Pickup",
  DELIVERY: "Delivery",
  PICKUP_STATUS_CODE: 90,
  DELIVERY_STATUS_CODE: 199,
  PICKUP_UN_PLANNED_TXT: "Ready for Pickup",
  PICKUP_PLANNED_TXT: "Pickup Planned",
  DELIVERY_UN_PLANNED_TXT: "Ready for Delivery",
  DELIVERY_PLANNED_TXT: "Delivery Planned",
};

export const CLAIM_ATTACHMENT = {
  GENERAL: 491,
  DAMAGED: 492,
  BEFOREPICKUP: 493,
  AFTERDELIVERY: 494,
  POWEROFATTORNEY: 495,
  INVOICE: 496,
  POLICE: 493,
  INCIDENT: 494,
};

export const CLAIM_ACTION = {
  ClaimRaised: 461,
  InformationRequest: 462,
  AssignedToOperation: 463,
  AcknowledgeAndFillIncidentReport: 464,
  MentionCustomerPartnerFault: 465,
  // OtherOperationTeamFault: 466,
  ReqMoreInfoToOp: 466,
  ApproveClaim: 467,
  RejectClaim: 468,
  FixClaimAmount: 469,
  AcceptClaimAmount: 470,
  AppealForClaimAmount: 471,
  ProcessInsurance: 472,
  ProcessPayment: 473,
  InformationSubmitted: 474,
  RejectByClaimOfficer: 476,
  ApproveByClaimOfficer: 477,
};

export const CLAIM_ASSIGN_TYPE = {
  Customer: 451,
  IO: 452,
  Operation: 453,
  Claims: 454,
  Insurance: 455,
  Finance: 456,
};

export const YEAR_LIST = [
  { label: "Jan 01 to Dec 31 2022", value: 2022 },
  { label: "Jan 01 to Dec 31 2023", value: 2023 },
  { label: "Jan 01 to Dec 31 2024", value: 2024 },
  { label: "Jan 01 to Dec 31 2025", value: 2025 },
  { label: "Jan 01 to Dec 31 2026", value: 2026 },
  { label: "Jan 01 to Dec 31 2027", value: 2027 },
  { label: "Jan 01 to Dec 31 2028", value: 2028 },
  { label: "Jan 01 to Dec 31 2029", value: 2029 },
  { label: "Jan 01 to Dec 31 2030", value: 2030 },
];

export const INIT_FROM_SRVC = [
  {
    value: PICKUP_SERVICE_ID,
    label: "Pick up",
    isChecked: false,
  },
  {
    value: 0,
    label: "Lodge in",
    isChecked: true,
  },
];
export const INIT_TO_SRVC = [
  {
    value: DELIVERY_SERVICE_ID,
    label: "Delivery",
    isChecked: false,
  },
  {
    value: 1,
    label: "Self Collect",
    isChecked: true,
  },
];

export const addrKeys = ["address", "city", "state", "postcode"];
export const minBookingDate = moment(); //.add(1, "days");

export const monthDropdown = [
  {
    label: "January",
    value: 0,
  },
  {
    label: "February",
    value: 1,
  },
  {
    label: "March",
    value: 2,
  },
  {
    label: "April",
    value: 3,
  },
  {
    label: "May",
    value: 4,
  },
  {
    label: "June",
    value: 5,
  },
  {
    label: "July",
    value: 6,
  },
  {
    label: "August",
    value: 7,
  },
  {
    label: "September",
    value: 8,
  },
  {
    label: "October",
    value: 9,
  },
  {
    label: "November",
    value: 10,
  },
  {
    label: "December",
    value: 11,
  },
];
const currDt = moment();
export const yearDropdown = [
  {
    label: currDt.year(),
    value: currDt.year(),
  },
  {
    label: currDt.subtract(1, "year").year(),
    value: currDt.year(),
  },
];
export const customerTye = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Business",
    value: "BC",
  },
  {
    label: "Personal",
    value: "PC",
  },
];

export const shipmentViewStatus = {
  planned: 90,
  waiting: 89,
};

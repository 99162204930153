import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../Layout/Header";
import UploadBookingDoc from "./UploadBookingDoc";
import { initValShipmentDtls } from "../../OrderManagement/initValShipmentDtls";
import { useRef } from "react";
import Popup from "../../Layout/Popup";

const PostBooking = () => {
  const [searchParams] = useSearchParams();
  const [orderId, setOrderId] = useState();
  const [shipmentDtls, setShipmentDtls] = useState(
    JSON.parse(JSON.stringify(initValShipmentDtls))
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const navigate = useNavigate();
  const modalRef = useRef();

  useEffect(() => {
    let id = searchParams.get("orderId");
    setOrderId(id);
  }, []);

  const handelCancel = () => {
    navigate("/customerOrder");
  };

  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
  };

  const handler = () => {
    navigate("/customerOrder");
  };

  return (
    <>
      <Header />
      <div className="container-fluid p-0 overflow-auto min-ht-100">
        <div>
          <main>
            <div className="booking-list px-3">
              <div className="w-50 m-auto">
                <div id="errorMsg" className="alert alert-success" role="alert">
                  OrderId : <span className="text-end">{shipmentDtls.orderNo}</span>
                </div>
                <UploadBookingDoc
                  orderId={orderId}
                  handler={handelCancel}
                  setShipmentDtls={setShipmentDtls}
                  shipmentDtls={shipmentDtls}
                  onSuccess={onSuccess}
                />
              </div>
            </div>
            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
          </main>
          <Popup
            isSuccess={isSuccess}
            title={title}
            description={description}
            handler={handler}
          />
        </div>
      </div>
    </>
  );
};

export default PostBooking;

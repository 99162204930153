import React, { useState } from "react";
import ModalLayout from "../../Layout/ModalLayout";
import { Button, Col, Row } from "react-bootstrap";

const initManpower = {
  isChecked: false,
  value: 0,
  error: false,
};

const getLabel = (stopSeq) => {
  switch (stopSeq) {
    case 0:
      return "Origin";
    case 99:
      return "Destination";
    default:
      return `Stop ${stopSeq}`;
  }
};

const StopsIterator = ({ selPlan }) => {
  const tStops =
    !!selPlan?.stops && typeof selPlan?.stops === "string"
      ? JSON.parse(selPlan?.stops)
      : [];

  return (
    <>
      {tStops.map((e, i) => (
        <Col key={i} md={6}>
          <div class="col grd-bg p-2 p my-2 rounded-3">
            <div class="fw-bold">{getLabel(e?.stopSeq)}</div>
            {/* <div class="theme-blue-txt">{`${e?.stopContactName} ${e?.stopAddress} ${e?.stopCity} ${e?.stopState} ${e?.stopPostcode} ${e?.stopContactPhone}`}</div> */}
            <pre className="box-text p-1">
              <span className="d-inline-block">
                {`Name: ${
                  !!e?.stopContactName ? e?.stopContactName : ""
                },\nAddress: ${e?.stopAddress},\nCity: ${e?.stopCity}, State: ${
                  e?.stopState
                }\nPostCode: ${e?.stopPostcode}\nPhone: ${
                  !!e?.stopContactPhone ? e?.stopContactPhone : ""
                }`}
              </span>
            </pre>
          </div>
        </Col>
      ))}
    </>
  );
};

const BookingModal = ({ handleCLose, bookModalConfig, onBook, selPlan }) => {
  const [manpower, setManpower] = useState(Object.assign({}, initManpower));

  const handleCloseEvent = () => {
    setManpower(Object.assign({}, initManpower));
    handleCLose();
  };
  const handleOnChecked = (event) => {
    const { checked } = event.target;
    setManpower({ ...manpower, isChecked: checked });
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    let copyManpower = Object.assign({}, manpower);
    if (copyManpower.error) {
      copyManpower.error = false;
    }
    copyManpower[name] = value;
    setManpower(copyManpower);
  };
  const header = (
    <>
      <h3 className="theme-violet-txt">CHARTERED BOOKING</h3>
    </>
  );

  const onSave = () => {
    if (manpower.isChecked && manpower.value <= 0) {
      setManpower({ ...manpower, error: true });
      return;
    }
    const payload = {
      customerId: selPlan?.customer_id,
      planId: selPlan?.planId,
      routeId: selPlan?.routeId,
      vas: [
        {
          serviceId: 767,
          value: manpower.value,
        },
      ],
    };
    onBook(payload);
  };
  const footer = (
    <>
      <Button variant="primary" className="theme-violet" onClick={onSave}>
        Book
      </Button>
    </>
  );
  return (
    <ModalLayout
      handleMoalClose={handleCloseEvent}
      moadlConfig={bookModalConfig}
      header={header}
      footer={footer}
    >
      <>
        <Row>
          {/* <Col md={12}>
            <div class="col grd-bg p-2 p my-2 rounded-3">
              <div class="fw-bold">Origin</div>
              <div class="theme-blue-txt">{`${selPlan?.orgAddr} ${selPlan?.orgCity} ${selPlan?.orgState} ${selPlan?.orgPostCode}`}</div>
            </div>
          </Col>
          <Col md={12}>
            <div class="col grd-bg p-2 p my-2 rounded-3">
              <div class="fw-bold">Destination</div>
              <div class="theme-blue-txt">{`${selPlan?.destAddr} ${selPlan?.destCity} ${selPlan?.destState} ${selPlan?.destPostCode}`}</div>
            </div>
          </Col> */}
          <StopsIterator selPlan={selPlan} />
          <Col md={6}>
            <div class="col grd-bg p-2 p my-2 rounded-3">
              <div class="fw-bold">Shipment Date</div>
              <div class="theme-blue-txt">{selPlan?.tripStart}</div>
            </div>
          </Col>
          <Col md={6}>
            <div class="col grd-bg p-2 p my-2 rounded-3">
              <div class="fw-bold">Ton</div>
              <div class="theme-blue-txt">{selPlan?.vhclWeight}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>
              {/* <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label="Do you need additional Manpower?"
              /> */}
              <div class="form-check form-switch">
                <label class="form-check-label" for="flexSwitchCheckChecked">
                  Do you need additional Manpower?
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={manpower.isChecked}
                  onChange={handleOnChecked}
                />
              </div>
            </span>
          </Col>
        </Row>
        {manpower.isChecked && (
          <Row>
            <Col>
              <input
                type="number"
                name="value"
                className="form-control"
                placeholder="Enter Manpower"
                min={0}
                value={manpower.value}
                onChange={handleOnChange}
              />
              {manpower.error && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; Please Insert Manpower!!
                  </span>
                </div>
              )}
            </Col>
          </Row>
        )}
      </>
    </ModalLayout>
  );
};

export default BookingModal;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CallApiGet } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import AddCustomerManagement from "./AddCustomerManagement";
import CustomerManagement from "./CustomerManagement";
import {
  CMGREG,
  LINEHAUL_CUSTOMER_BUSINESS_PARTNERS,
} from "../../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../../images/svglist";
import {
  exportToExcel,
  permission,
  writeLocalStorage,
} from "../../Helper/CommonMethods";
import moment from "moment";
import { DATE_FORMAT_MOMENT } from "../../Helper/Constants";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

export default function CustomerManagementMain({ type }) {
  // const userRole = JSON.parse(getUserInfo("userRoles"))[0].roleName;
  const modalRef = useRef();
  const [show, setShow] = useState(false);
  const [viewStaff, setViewStaff] = useState([]);
  const [modalConfig, setModalConfig] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [custList, setCustList] = useState();
  const [stateCity, setStateCity] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityListData, setCityListData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustList();
    fetchStatesAndCityList();
  }, []);
  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
    setCityListData(cityArrList);
  };
  const fetchCustList = async () => {
    let data = await CallApiGet(`list-cust/${type ? type : "ALL"}`);
    if (data.respCode === 200 || data.respCode === 0) {
      setCustList(data.respData ? data.respData : []);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the required permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
    }
  };

  const onSuccess = (msg, title, isSuccess = true) => {
    setIsSuccess(isSuccess);
    setTitle(title);
    setDescription(msg);
    modalRef.current.click();
    fetchCustList();
  };

  const exportData = () => {
    exportToExcel(custList, columns, "customer_list.csv");
  };

  const columns = [
    {
      name: "Account Code",
      selector: (row) => row.acctCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row?.businessName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Name",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row) => row.custType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.custEmail,
      sortable: true,
      filterable: true,
      width: "20%",
    },

    {
      name: "ISR",
      selector: (row) => row.isr,
      sortable: true,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) =>
        row.registrationDate
          ? moment(row.registrationDate).format(DATE_FORMAT_MOMENT)
          : " ",
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
    },

    {
      name: "",
      width: "3%",
      cell: (row) => (
        <OverlayTrigger
          trigger="click"
          key={row.profileId}
          placement="left"
          rootClose
          overlay={
            <Popover id={`popover-positioned-left-${row.profileId}`}>
              <Popover.Body>
                <div
                  className="action-item-button"
                  onClick={() =>
                    saveCustProfileId(row?.profileId, row?.custTypeId)
                  }
                >
                  <Link
                    to={"/customerDetails?profileId=" + row.profileId}
                    state={{ from: "CUSTMGT", type: type }}
                    data-tooltip-id={"edit-" + row.profileId}
                    className="text-decoration-none row-action-button"
                  >
                    {GetSvgIcon("EditPencil")} Edit
                  </Link>{" "}
                  <Tooltip
                    id={"edit-" + row.profileId}
                    content={"Edit"}
                    place="left"
                  />
                </div>
                {row.prflStatus === "ACTIVE" && (
                  <div className="action-item-button">
                    <Link
                      to={`/bookingStnd?customerId=${row.profileId}&type=ISR`}
                      data-tooltip-id={"book-" + row.profileId}
                      onClick={() =>
                        saveCustProfileId(row?.profileId, row?.custTypeId)
                      }
                      className="text-decoration-none row-action-button"
                    >
                      {GetSvgIcon("IconDiscountFill")} Create Booking
                    </Link>
                    <Tooltip
                      id={"book-" + row.profileId}
                      content={"Create Booking"}
                      place="left"
                    />
                  </div>
                )}
                {row.prflStatus === "ACTIVE" && (
                  <div className="action-item-button">
                    <div
                      onClick={() => {
                        setModalData();
                        saveCustProfileId(row?.profileId, row?.custTypeId);
                      }}
                      data-tooltip-id={"ftl-" + row.profileId}
                    >
                      {GetSvgIcon("BiTruckPlan")} Full Truck Booking
                    </div>
                    <Tooltip
                      id={"ftl-" + row.profileId}
                      content={"Create Full truck Booking"}
                      place="left"
                    />
                  </div>
                )}
                {LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(row.custTypeId) &&
                  row.prflStatus === "ACTIVE" && (
                    <div className="action-item-button">
                      <div
                        data-tooltip-id={"view-" + row.profileId}
                        onClick={() => handleOpen(row.profileId)}
                      >
                        {GetSvgIcon("EyeFill")} View Staff
                      </div>
                      <Tooltip
                        id={"view-" + row.profileId}
                        content={"View staff"}
                        place="left"
                      />
                    </div>
                  )}
              </Popover.Body>
            </Popover>
          }
        >
          <span
            id={`cust-action-btn-${row.profileId}`}
            className="row-action-button"
          >
            {GetSvgIcon("Trippleverticaldot")}
          </span>
        </OverlayTrigger>
      ),
      // allowOverflow: true,
      // button: true,
    },

    // {
    //   name: "View",
    //   cell: (row) => (
    //     <Link
    //       to={"/customerDetails?profileId=" + row.profileId}
    //       state={{
    //         from: "CUSTMGT",
    //       }}
    //     >
    //       {GetSvgIcon("EyeFill")}
    //     </Link>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: "56px",
    // },
  ];

  const saveCustProfileId = (prflId, custType) => {
    writeLocalStorage("customerId", prflId);
    writeLocalStorage("customerType", custType);
  };
  const handleModalClose = (reload = false) => {
    setModalConfig({
      size: "xl",
      show: false,
      title: "",
    });
    saveCustProfileId(null, null);
  };
  const setModalData = () => {
    setModalConfig({
      size: "xl",
      show: true,
      title: "Full Truck",
    });

    // setAllCustomers2(allCustomers)
  };

  const fetchCustomerUsers = async (id) => {
    let uri = "get-customer-users/" + id;
    let data = await CallApiGet(uri);
    setViewStaff(
      !!data.respData && Array.isArray(data.respData) ? data.respData : []
    );
  };

  const handleOpen = (id) => {
    document.getElementById(`cust-action-btn-${id}`).click();
    fetchCustomerUsers(id);
    setShow(true);
  };

  const handleCLose = () => {
    setShow(false);
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                {type === "AC" ? (
                  <div className="section-title mt-1">
                    Sales Partner Management
                  </div>
                ) : (
                  <div className="section-title mt-1">Customer Management</div>
                )}
              </div>
              {permission(CMGREG).allowedWrite && (
                <div className="float-end ">
                  {type !== "AC" && (
                    <div>
                      <button
                        className="btn btn-outline-secondary theme-violet text-nowrap"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#button-modal"
                      >
                        {GetSvgIcon("PlusCircle")}{" "}
                        {type === "AC" ? "Add Sales Partner" : "Add Customer"}
                      </button>
                    </div>
                  )}
                </div>
              )}
              <button
                data-bs-toggle="modal"
                data-bs-target="#success-modal"
                id="modalPopup"
                className="d-none"
                ref={modalRef}
              />
            </div>
            <div className="float-end d-flex align-items-center mt-1">
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  text-end "
                onClick={exportData}
                data-tooltip-id="csv-export"
              >
                {/* Download */}
                {GetSvgIcon("SpreadSheet")}
              </button>
            </div>
            <div>
              <CustomerManagement
                custList={custList}
                type={type}
                columns={columns}
                modalConfig={modalConfig}
                handleModalClose={handleModalClose}
                viewStaff={viewStaff}
                show={show}
                handleCLose={handleCLose}
              />

              <AddCustomerManagement
                stateData={stateData}
                stateCity={stateCity}
                cityListData={cityListData}
                setCityListData={setCityListData}
                cityData={cityData}
                onSuccess={onSuccess}
                type={type}
              />
            </div>
          </main>
        </div>
        <Popup isSuccess={isSuccess} title={title} description={description} />
      </div>
    </>
  );
}

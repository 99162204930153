import React from "react";
import { Col, Row, Tooltip } from "react-bootstrap";
import BankSlipInvoiceList from "./BankSlipInvoiceList";
import GetSvgIcon from "../../../images/svglist";
import { DownloadFile } from "../../Helper/FileStoreService";

const BankSlipDetailes = ({
  selectedBs,
  isAppovedClicked,
  invCols,
  invoiceList,
  finalAmount,
  payByCreditVal,
  isRemarkBlank,
  setIsRemarkBlank,
}) => {
  const onSuccess = (title, body) => {
    console.log("title", title, "body", body);
  };

  const onError = (errMsg) => {
    console.log("errMsg", errMsg);
  };

  const handelChangeData = (e) => {
    if (!!e) setIsRemarkBlank(false);
  };

  return (
    <div>
      <Row>
        <Row>
          <Col sm={2} className="text-start">
            <label className="form-label fs-6 theme-label">Customer :</label>
          </Col>
          <Col sm={4} className="overflow-auto">
            {selectedBs?.custName}
          </Col>
          <Col sm={2} className="text-end">
            <label className="form-label fs-6 theme-label">Bank Slip :</label>
          </Col>
          <Col sm={3} className="overflow-hidden">
            <span className="p-2 Form-Lable-link">{selectedBs?.bank_slip}</span>
          </Col>
          <Col sm={1} className="text-end">
            <>
              <div
                data-tooltip-id={"bs"}
                className="me-3"
                onClick={() =>
                  DownloadFile(selectedBs?.bank_slip, onSuccess, onError)
                }
              >
                {" "}
                {GetSvgIcon("IconFiledownload")}
              </div>
              <Tooltip id={"bs"} content="Download Invoice" place="left" />
            </>
          </Col>
        </Row>

        <Row className="p-1">
          <Col sm={2} className="text-start">
            <label className="form-label fs-6 theme-label ms-2">
              Remarks :
            </label>
          </Col>
          <Col>
            <textarea className="form-control" id="bs-remark" rows={1} disabled>
              {selectedBs?.remarks}
            </textarea>
          </Col>
        </Row>
        <Row>
          {/* <BankSlipInvoiceList invColumns={invCols} invList={invoiceList} /> */}
          <Col sm={2} className="text-start">
            <label className="form-label fs-6 theme-label">Invoices :</label>
          </Col>
          <Col sm={10} className="overflow-auto text-start">
            {selectedBs?.invoice_ids}
          </Col>
        </Row>

        {isAppovedClicked && (
          <>
            {/* <Row className="mt-2"></Row>className=" border border-2 border-info" */}
            <div>
              {/* <Row>
                <Col className="fs-4">CONFIRM PAYMENT</Col>
              </Row> */}
              <div className="row mt-2">
                <div className="col-md-2  text-start">
                  <label
                    htmlFor="floatingInputGrid"
                    className="form-label theme-label"
                  >
                    Invoice Amount :
                  </label>
                </div>

                <div className="col-md-8 mb-2 text-start">
                  <label className="form-label ">
                    <b>{selectedBs.totalInvoiceAmount?.toFixed(2)} RM</b>
                  </label>
                </div>
                <div className="col-md-2"></div>
              </div>
              {selectedBs.avlCnAmount > 0 && (
                <>
                  <div className="row">
                    <div className="col-md-2 text-start">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Credit Note :
                      </label>
                    </div>
                    <div className="col-md-8 mb-2 text-start">
                      <label className="form-label">
                        <b>{selectedBs.avlCnAmount?.toFixed(2)} RM</b>
                      </label>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <div className="row ">
                    <div className="col-md-2 text-start "></div>
                    <div className="col-md-8 mb-2 text-start ">
                      <input
                        className="form-check-input p-0 me-2 "
                        type="checkbox"
                        id="pay-by-cn"
                        // style={{background: "aliceblue"}}
                        onChange={(e) => payByCreditVal(e.target.checked)}
                        // checked={(e)=>payByCreditVal(e)}
                        disabled={selectedBs.avlCnAmount < 1}
                      />
                      Apply Credit Note
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-2 text-start">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Final Amount :
                      </label>
                    </div>
                    <div className="col-md-8 mb-2 text-start ">
                      <label className="form-label">
                        <b>{(+finalAmount).toFixed(2)} RM</b>
                      </label>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-md-3 text-start">
                  <label
                    htmlFor="floatingInputGrid"
                    className="form-label theme-label "
                  >
                    Payment Remark <span className="text-danger">&#9733;</span>
                  </label>
                </div>
                <div className="col-md-9 mb-2 text-start">
                  <textarea
                    className="form-control"
                    id="pay-remark"
                    rows={2}
                    onChange={(e) => handelChangeData(e.target.value)}
                  ></textarea>

                  {isRemarkBlank && (
                    <div style={{ textAlign: "left" }}>
                      <span className="badge bg-danger">
                        &#9888; This field is required.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Row>
    </div>
  );
};

export default BankSlipDetailes;

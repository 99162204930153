import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_TIME_ZONE_MOMENT_FORMAT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { DATE_FORMAT_MOMENT } from "../Helper/Constants";
import moment from "moment";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../images/svglist";
import { customDateSort } from "../Helper/CommonMethods";

const ClaimList = ({ claimList, showClaimForm, showClaimHistory }) => {
  const userType = getUserInfo("userType");
  const [hideColums, setHideColums] = useState(false);

  useEffect(() => {
    setHideColums(
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ? true : false
    );
  }, []);

  const openOrderDtls = (argOrderId) => {
    const url = `/orderDetails?data=${argOrderId}`;
    window.open(url, "_blank");
  };

  const columns = [
    {
      name: "Claim No",
      selector: (row) => row.claimNo,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div
            onClick={() => showClaimForm(row)}
            className="modal-links fw-bold"
          >
            {row.claimNo}
          </div>
        </>
      ),
    },
    {
      name: "Order No",
      selector: (row) => row.orderNo,
      cell: (row) => (
        <>
          <div
            onClick={() => openOrderDtls(row.orderId)}
            className="modal-links fw-bold"
          >
            {row.orderNo}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Claim On",
      selector: (row) =>
        moment(row.claimOn).format(DATE_FORMAT_MOMENT) +
        " " +
        moment(row.claimOnT, DATE_TIME_ZONE_MOMENT_FORMAT, true)
          .local()
          .format("HH:mm"),
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.claimOnT, rowB.claimOnT),
    },
    {
      name: "Customer Name",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Claim Amount",
      selector: (row) => row.custAmt,
      sortable: true,
      filterable: true,
      cell: (row) => <div>{row?.custAmt?.toFixed(2)} RM</div>,
    },
    {
      name: "Settled Amount",
      selector: (row) => row.lhAmt,
      sortable: true,
      filterable: true,
      cell: (row) => <div>{row?.lhAmt?.toFixed(2)} RM</div>,
    },
    {
      name: "Status",
      selector: (row) =>
        LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)
          ? row.statusCustDesc
          : row.statusLhDesc,
      sortable: true,
      filterable: true,
    },
    // {
    //     name: "",
    //     sortable: false,
    //     filterable: false,
    //     width: "5%",
    //     cell: (row) => (
    //         <>
    //             <div onClick={() => showClaimHistory(row)} className="modal-links fw-bold">
    //                 {GetSvgIcon('History')}
    //             </div>
    //         </>
    //     ),
    // }
  ];

  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={claimList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default ClaimList;

import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GetSvgIcon from "../../../../images/svglist";
import {
  getUserInfo,
} from "../../../AuthUtils/AuthUtils";
import { DATE_FORMAT_MOMENT } from "../../../Helper/Constants";
import { CallApiPost } from "../../../Helper/serviceApi";
import Footer from "../../../Layout/Footer";
import Header from "../../../Layout/Header";
import Spinner from "../../../Layout/Spinner";
import ApprovalTaskList from "./ApprovalTaskList";
import { Tooltip } from "react-tooltip";

const ApprovalTask = ({type}) => {
  const [allData, setAllData] = useState([])
  const [show, setShow] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      name: "Name",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) => row.registrationDate ? moment(row.registrationDate).format(DATE_FORMAT_MOMENT) : ' ',
      sortable: true,
      filterable: true,
    },

    {
      name: "Email",
      selector: (row) => row.custEmail,
      filterable: true,
    },
    // {
    //   name: "Phone",
    //   selector: (row) => row.custPhone,
    //   filterable: true,
    // },
    {
      name: "ISR",
      selector: (row) => row.isrName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status === 'REJECT' ? 'REJECTED' : row.status,
      sortable: true,
      filterable: true,
    },
    {
      name: "Assign To",
      selector: (row) => row.assignTo,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
        <Link
          to={"/customerDetails?profileId=" + row.custProfileId}
          state={{
            from: "CUSTAT",
            type: type
          }}
          data-tooltip-id={"details-" + row.custProfileId}
        >
          {GetSvgIcon("EditPencil")}
        </Link>
        <Tooltip id={"details-" + row.custProfileId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  useEffect(() => {
    getCustomers()
  }, [])

  const getCustomers = async () => {
    setShow(true)
    const userRoles = JSON.parse(getUserInfo("userRoles"))
    const payload = {
      "ApproverId": getUserInfo("userId"),
      "ApproverRoleId": userRoles[0].roleId,
      "ApproverType": getUserInfo("userType"),
      "ApprovalUserType" : type ? type : "BC"
    }

    let data = []
    let resp = await CallApiPost("pending-cust-list-for-approver", payload)
    if (resp.respCode === 200 || resp.respCode === 0) {
      setShow(false)
      data = resp.respData
    } else {
      setShow(false)
      data = [];
    }
    setAllData(data)
  }

  return (
    <>
      <Header />
      <div className="container-fluid  min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                {
                  (type === 'AC') ?
                  (<div className="section-title mt-1">Sales Partner Approval</div>) :
                  (<div className="section-title mt-1">Customer Approval</div>)
                }
              </div>
            </div>
            <ApprovalTaskList
              allData={allData}
              columns={columns}
            />
          </main>
        </div>
      </div>
      <Spinner show={show} />
      <Footer />
    </>
  );
};

export default ApprovalTask;

import React, { useEffect, useState } from "react";
import Promotion from "./Promotion";

const PromotionAdd = (props) => {
  const {
    sbuList,
    register,
    watch,
    clearErrors,
    reset,
    control,
    handleSubmit,
    formIntitData,
    handelClear,
    onSubmit,
    setValue,
    getValues,
    watchFirstOrder,
    watchId,
    watchFreeService,
    errors,
    watchFreeServiceType,
    custColumns,
    allCustomers,
    rowSelectCritera,
    watchPayMode,
    deactivate,
    ptatus,
    isactive,
    watchPtypeCredit,
    isSubmited,
    setIsSubmited,
    watchCriteria,
    watchDiscType,
    watchPickup,
    watchDelivery,
    watchPackage,
    watchHandel,
    activate,
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState();
  useEffect(() => {
    reset(formIntitData);
  }, [allCustomers]);

  const watchType = watch("promoType");
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const handelOnDraftClick = (data) => {
    data.status = "Draft";

    data.selectedCustomers = selectedCustomers;
    setIsSubmited(true);
    onSubmit(data);
  };
  const handelOnPublishClick = (data) => {
    data.status = "Published";

    data.selectedCustomers = selectedCustomers;
    setIsSubmited(true);
    onSubmit(data);
  };

  const onError1 = (err) => {
    console.log("Errors->", err);
  };
  const discountTypeChange = (e) => {
    if (e === "discount") {
      setValue("fservice", "");
      setValue("pickupKm", "");
      setValue("deliveryKm", "");

      setValue("servicePickup", "");
      setValue("serviceDelivery", "");
      setValue("servicePackage", "");
      setValue("serviceHandel", "");

      setValue("packagePallets", "");
      setValue("handelPallets", "");
      clearErrors("pickupKm");
      clearErrors("deliveryKm");
      clearErrors("fservice");
    } else {
      setValue("discount", "");
      setValue("maxDiscount", "");
      setValue("minOrderAmt", "");
      clearErrors("maxDiscount");
      clearErrors("discount");
      setValue("origin", "");
      setValue("destination", "");
    }
  };

  const amountTypeChange = (e) => {
    if (e === "percent") {
      setValue("discAmt", "");
      clearErrors("discAmt");
    } else {
      setValue("discount", "");
      setValue("maxDiscount", "");
      clearErrors("discount");
      clearErrors("maxDiscount");
    }
  };

  const clearLimitBox = (event) => {
    if (!event) {
      setValue("custBookLimit", "");
    }
  };

  const clearField = (id, checked) => {
    console.log(id, checked);

    switch (id) {
      case "servicePickup":
        if (!checked) setValue("pickupKm", "");
        clearErrors("pickupKm");
        break;
      case "serviceDelivery":
        if (!checked) setValue("deliveryKm", "");
        clearErrors("deliveryKm");
        break;
      case "servicePackage":
        if (!checked) setValue("packagePallets", "");
        clearErrors("packagePallets");
        break;
      case "serviceHandel":
        if (!checked) setValue("handelPallets", "");
        clearErrors("handelPallets");
        break;
      default:
        break;
    }
  };
  const clearCriteria = (e) => {
    // if (!e.target.checked){
    setValue("cardName", "");
    setValue("pTypesCash", "");
    setValue("pTypesCredit", "");
    clearErrors("cardName");
    setIsSubmited(false);
    setToggleClearRows(!toggledClearRows);

    // }
    clearErrors("pTypesCredit");
  };

  const clearCreditCard = (e) => {};

  const onDeactiveClicked = () => {
    deactivate(getValues("id"));
  };
  const onActivateClicked = () => {
    activate(getValues("id"));
  };

  return (
    <>
      <div className="theme-blue-popup p-3">
        {watchType === "Promotion" && (
          <Promotion
            sbuList={sbuList}
            watch={watch}
            register={register}
            control={control}
            clearErrors={clearErrors}
            discountTypeChange={discountTypeChange}
            watchFirstOrder={watchFirstOrder}
            watchId={watchId}
            watchFreeService={watchFreeService}
            errors={errors}
            watchFreeServiceType={watchFreeServiceType}
            clearCreditCard={clearCreditCard}
            watchPayMode={watchPayMode}
            clearField={clearField}
            watchPtypeCredit={watchPtypeCredit}
            custColumns={custColumns}
            allCustomers={allCustomers}
            rowSelectCritera={rowSelectCritera}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            isSubmited={isSubmited}
            watchCriteria={watchCriteria}
            clearCriteria={clearCriteria}
            toggledClearRows={toggledClearRows}
            ptatus={ptatus}
            watchDiscType={watchDiscType}
            watchHandel={watchHandel}
            watchPackage={watchPackage}
            watchDelivery={watchDelivery}
            watchPickup={watchPickup}
            amountTypeChange={amountTypeChange}
            clearLimitBox={clearLimitBox}
          />
        )}
        {/* {watchType === "Voucher" && (
          <Voucher
            watch={watch}
            register={register}
            control={control}
            watchId={watchId}
            errors={errors}
            custColumns={custColumns}
            allCustomers={allCustomers}
            // handleRowChange={handleRowChange}
            rowSelectCritera={rowSelectCritera}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            isCustSelected={isCustSelected}
            setIsCustSelected={setIsCustSelected}
            isSubmited={isSubmited}
          />
        )} */}
      </div>
      <div className="modal-footer border-0">
        {!!ptatus && isactive && ptatus === "Published" && (
          <button
            type="button"
            className="btn btn-default border"
            onClick={onDeactiveClicked}
          >
            Deactivate
          </button>
        )}
        { !isactive  && (
          <button
            type="button"
            className="btn btn-default border"
            onClick={onActivateClicked}
          >
            Activate
          </button>
        )}
        {ptatus !== "Published" && isactive && (
          <>
            <button
              type="button"
              className="btn btn-default border"
              onClick={() => (setIsSubmited(false), handelClear())}
            >
              {getValues("id") > 0 ? "Reset" : "Clear"}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(handelOnPublishClick, onError1)}
            >
              Publish
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(handelOnDraftClick, onError1)}
            >
              Save as Draft
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default PromotionAdd;

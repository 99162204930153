import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import SupplierList from "./SupplierList";
import GetSvgIcon from "../../../images/svglist";
import Layout from "../../Layout/Layout";
import SupplierAddEdit, { initObj } from "./SupplierAddEdit";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import { cleanNewLine, exportToExcel } from "../../Helper/CommonMethods";
import { Badge } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const SupplierRegistrationMain = () => {
  const initModalConfig = {
    show: false,
    title: "",
  };
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [suppList, setSuppList] = useState([]);
  const [selectSup, setSelectSup] = useState(Object.assign({}, initObj));
  const [spinner, setSpinner] = useState(false);
  const [prflId, setPrflId] = useState(0);

  const countryCodes = [
    { label: "+60", value: "+60" },
    { label: "+62", value: "+62" },
    { label: "+65", value: "+65" },
    { label: "+66", value: "+66" },
    { label: "+84", value: "+84" },
    { label: "+86", value: "+86" },
  ];

  const getInfo = (infoArr, key, argInfoCode) => {
    const infoObj = infoArr.find((info) => info.infoCode === argInfoCode);
    return !!infoObj && !!infoObj[key] ? infoObj[key] : null;
  };

  const handleBtnClick = (argData, argTitle) => {
    console.log(argData, argTitle);
    let localData = Object.assign({}, argData);
    localData.userInfo = JSON.parse(localData?.userInfo);
    const profilePhoto = {
      photo: null,
      photoFileType: null,
      photoUrl: getInfo(localData.userInfo, "attachment", 125),
    };
    localData.profilePhoto = profilePhoto;
    setSelectSup(Object.assign({}, localData));
    setModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setModalConfig({
      show: false,
      title: "",
    });
  };

  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);

  const hideAlert = async () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    setSpinner(true);
    await getSupplier();
    setSpinner(false);
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  useEffect(() => {
    setSpinner(true);
    Promise.all([getSupplier()])
      .then(() => setSpinner(false))
      .catch((error) => {
        console.error("Error:", error);
        setSpinner(false);
      });
  }, []);

  const getSupplier = async () => {
    const uri = "list-supplier?argPrflId=0&argStatus=0";
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setSuppList(data.respData);
    } else if (data.respCode === 401) {
      console.error(data);
    } else {
      console.error(data);
    }
  };

  const handlePostApi = (argResp) => {
    if (argResp?.respCode === 200) {
      showAlert({
        isSuccess: true,
        title: "SUCCESS",
        description: "Supplier data processed successfully!!",
      });
    } else if (argResp?.respCode === 409 || argResp?.respCode === 400) {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: argResp?.respMessage,
      });
    } else {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: "Something went wrong. Please try after sometime!!",
      });
    }
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    const uri = "saveSupplier";
    data.address = cleanNewLine(data.address);
    let supplierData = await CallApiPost(uri, data);
    handleCLose();
    setSpinner(false);
    handlePostApi(supplierData);
  };

  const columns = [
    {
      name: "Supplier Id",
      selector: (row) => row.supplierId,
      sortable: true,
      filterable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.active ? "Active" : "In-Active"),
      cell: (row) =>
        row?.active ? (
          <Badge bg="success">Active</Badge>
        ) : (
          <Badge bg="danger">In-Active</Badge>
        ),
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row.profileId}
            onClick={() => {
              handleBtnClick(row, "EDIT SUPPLIER");
              // setPrflId(row.profileId);
            }}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.profileId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  const exportData = () => {
    exportToExcel(suppList, columns, "Supplier_list.csv");
  };

  return (
    <div>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">Supplier Management</div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() => handleBtnClick(initObj, "REGISTER SUPPLIER")}
              >
                {GetSvgIcon("PlusCircle")} Register Supplier
              </button>
            </div>
          </div>
        </div>
        <div className="float-end d-flex align-items-center">
          <button
            type="button"
            className="btn btn-outline-secondary theme-violet  text-end "
            onClick={exportData}
            data-tooltip-id="csv-export"
          >
            {/* Download */}
            {GetSvgIcon("SpreadSheet")}
          </button>
        </div>

        <SupplierList
          suppList={suppList}
          onHide={handleCLose}
          // handleAction={handleBtnClick}
          // setPrflId={setPrflId}
          columns={columns}
        />

        <SupplierAddEdit
          modalConfig={modalConfig}
          onHide={handleCLose}
          selectedSup={selectSup}
          countryCodes={countryCodes}
          onSubmit={onSubmit}
        />
      </Layout>
    </div>
  );
};

export default SupplierRegistrationMain;

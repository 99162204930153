import React from "react";
import UploadDataFile from "../../Helper/DataUploadUtil";
import {
  ALPHANUM_NAME,
  ALPHANUM_SPACE_SPCL_CHAR,
  BLACK_SPCL_CHARS_STR,
  INVALID_VALUE,
  NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR,
  SERVER_ERR_MSG,
  TWO_DECIMAL,
  VALID_LENGTH_ERR_MSG,
} from "../../Helper/Constants";

const commCol = [
  "Vehicle Type",
  "Hub/Depot",
  "1st Point Charge",
  "Drop Point Charge",
];

const UploadLonghaulCommission = (props) => {
  const { openPopup } = props;
  const templateLongComm = require("../../../assets/templates/LonghaulCommissionUploadTemplate.xlsx");

  const validateCommissionData = (fileData) => {
    let isValid = true;
	let commArr = []
    fileData.forEach((row) => {
      let errMsg = [];
      commCol.forEach((col) => {
        validCommCol(col, errMsg, row);
      });
	  const vhclHub = row["Vehicle Type"] + "-" + row["Hub/Depot"]
	  if (commArr.includes(vhclHub)) {
		errMsg.push("Duplicate Vehicle Type and Hub/Depot in combination.")
	  } else {
		commArr.push(vhclHub)
	  }
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const validCommCol = (col, errMsg, row) => {
    switch (col) {
      case "Vehicle Type":
        if (!row.hasOwnProperty(col)) {
          errMsg.push(col + " is absent");
        } else if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
          errMsg.push(
            col +
              ": contains some special characters(" +
              BLACK_SPCL_CHARS_STR +
              ")"
          );
        }
        break;
      case "Hub/Depot":
        if (!row.hasOwnProperty(col)) {
          errMsg.push(col + " is absent");
        } else {
          const trimmedValue = row[col].trim(); // Trim leading whitespace
          if (!ALPHANUM_NAME.test(trimmedValue)) {
            errMsg.push(col + ": Please enter alphanumeric values ");
          }
        }
        break;
      case "1st Point Charge":
        if (!row.hasOwnProperty(col)) {
          errMsg.push(col + " is absent");
        } else if (!TWO_DECIMAL.test(row[col])) {
          errMsg.push(col + ": value not in acceptable format of xxxxxxxxx.xx");
        }
        break;
      case "Drop Point Charge":
        if (!row.hasOwnProperty(col)) {
          errMsg.push(col + " is absent");
        } else if (!TWO_DECIMAL.test(row[col])) {
          errMsg.push(col + ": value not in acceptable format of xxxxxxxxx.xx");
        }
        break;
      default:
        break;
    }
  };

  const postExcelFileRead = (fileData) => {
    const fileDataOut = [];
    fileData.map((row, index) => {
      let rowOut = {};
      rowOut.RowIndex = index;
      rowOut.ErrorMessage = "";

      rowOut.vhclType = row["Vehicle Type"];
      rowOut.vhclTypeId = 0;
      rowOut.sbuCode = row["Hub/Depot"];
      rowOut.sbuId = 0;
      rowOut.baseCharge = row["1st Point Charge"];
      rowOut.subBreakCharge = row["Drop Point Charge"];
      fileDataOut.push(rowOut);
      return row;
    });
    return fileDataOut;
  };

  const onSuccess = () => {
    openPopup(true, "SUCCESS", "LongHaul commission uploaded successfully!!");
  };
  const onError = (resp) => {
    let msg;
    if (
      resp.respMessage === undefined ||
      resp.respMessage === null ||
      resp.respMessage === "null"
    ) {
      msg = SERVER_ERR_MSG;
    } else {
      msg = resp.respMessage;
    }
    openPopup(false, "ERROR", msg);
  };

  return (
    <>
      <UploadDataFile
        dataUploadUrl="SaveLongHaulCommCharge"
        onSuccess={onSuccess}
        onError={onError}
        template={templateLongComm}
        downloadFileName="LonghaulCommissionUploadTemplate.xls"
        cbPreUpload={postExcelFileRead}
        cbValidateData={validateCommissionData}
      />
    </>
  );
};

export default UploadLonghaulCommission;

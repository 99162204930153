import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Tooltip } from "react-tooltip";
import dummyImg from "../../../../images/user-dummy.jpg";
import { DownloadFile } from "../../../Helper/FileStoreService";
import { CallApiPost } from "../../../Helper/serviceApi";
import Spinner from "../../../Layout/Spinner";
import { getPersonalId } from "../../../AuthUtils/AuthUtils";

const IsrDetails = (props) => {
  let { isrDtls, status } = props;
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const closeRef = useRef();
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    if (isrDtls.isrPrflImg && isrDtls.isrPrflImg.photoBucketUrl !== "") {
      setImageData(isrDtls.isrPrflImg.photoBucketUrl);
    } else {
      setImageData("");
    }
  }, [isrDtls]);

  const logIsrApproval = (logType) => (event) => {
    if (validateData()) approveIsrApiCall(logType);
  };


  const approveIsrApiCall = async (statusData) => {
    let isrDtl = await CallApiPost("log-approval", {
      approvalUserId: isrDtls.userId,
      logText:
        remarks === "" && (statusData === "APPROVE" || statusData === "DEACTIVATIONAPPROVE")
          ? "Approved"
          : remarks,
      logAttachment: {},
      personalId: getPersonalId(),
      logType: statusData,
      skipCompletionProcess: true,
      approvalUserTypeId: isrDtls.staffTypeId,
    });

    if (isrDtl.respCode === 200) {
      closeRef.current.click();
      props.onSuccess(statusData);
    } else if (isrDtl.respCode === 406) {
      closeRef.current.click();
      props.onSuccess("NOPRIVILEGE", isrDtl.respMessage);
    } else {
      setSubmitStatusFail(true);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    }
  };

  const validateData = () => {
    if (remarks === "") {
      setError(true);
      return false;
    } else return true;
  };

  const onCanceled = () => {
    setRemarks("");
  };

  const download = (file) => {
    if (file) {
      let fileName = file.photoBucketUrl !== "" ? file.photoBucketUrl : "";
      if (fileName !== "") {
        setShow(true);
        DownloadFile(fileName);
        setShow(false);
      }
    }
  };

  return (
    <>
      <div
        className="modal fade modal-lg"
        id="button-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title section-title" id="exampleModalLabel">
                ISR Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={onCanceled}
              ></button>
            </div>
            {submitStatusFail && (
              <div id="errorMsg" className="alert alert-danger" role="alert">
                Something Went Wrong
              </div>
            )}
            <div className="modal-body modal-scroll overflow-auto">
              <div className="row">
                <div>
                  <div className="w-100">
                    <div className="row col-md-12 g-2 mb-2">
                      <div className="col-md-6">
                        <img
                          src={imageData !== "" ? imageData : dummyImg}
                          height="100px"
                          width="100px"
                          className="rounded-circle"
                          alt="img"
                        />
                      </div>
                      <div className="col-md-3 mt-3 text-center">
                        <span
                          // href={
                          //   isrDtls.isrIcImg &&
                          //   isrDtls.isrIcImg.photoBucketUrl !== ""
                          //     ? isrDtls.isrIcImg.photoBucketUrl
                          //     : void 0
                          // }
                          onClick={() => download(isrDtls.isrIcImg)}
                        >
                          <span className="tooltip-customized theme-blue-txt">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-person-vcard-fill activation-details"
                              viewBox="0 0 16 16"
                              data-tooltip-id="ic-img"
                            >
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                            </svg>
                            {/* <span className="tooltiptext">View IC Image</span> */}

                            <Tooltip id="ic-img" content="View IC Image" place="left" />
                          </span>
                        </span>
                      </div>
                      <div className="col-md-3 mt-3 text-start">
                        <span
                          // href={
                          //   isrDtls.bankStatement &&
                          //   isrDtls.bankStatement.photoBucketUrl !== ""
                          //     ? isrDtls.bankStatement.photoBucketUrl
                          //     : void 0
                          // }
                          onClick={() => download(isrDtls.bankStatement)}
                        >
                          <span className="tooltip-customized theme-blue-txt">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-card-checklist activation-details"
                              viewBox="0 0 16 16"
                              data-tooltip-id="statement"
                            >
                              <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                              <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                            </svg>
                            {/* <span className="tooltiptext">
                            View Bank Statement
                          </span> */}
                            <Tooltip
                              id="statement"
                              content="View Bank Statement"
                              place="right"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                    {(status === "PENDING" || status === "DEACTIVATION REQUESTED") && (
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <div className="alert alert-info">
                            <div>
                              <div className="float-start">
                                <h5 className="card-title">Approval Checklist</h5>
                                <p className="card-text">{isrDtls.approvalChecklist}</p>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className=" mb-2">
                          <label className="form-label theme-label">Name </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.name}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">Email </label>
                          <input
                            type="email"
                            className="form-control"
                            value={isrDtls.email}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">City </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.city}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">ISR IC </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.isrIc}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className=" mb-2">
                          <label className="form-label theme-label">Phone </label>
                          <input
                            type="tel"
                            className="form-control"
                            value={isrDtls.phone}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">State </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.state}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">Postal Code </label>
                          <input
                            type="number"
                            className="form-control"
                            value={isrDtls.postCode}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                        <div className=" mb-2">
                          <label className="form-label theme-label">Beneficiary Name </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.benificiaryName}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className=" mb-2">
                          <label className="form-label theme-label">Bank Name </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.bankName}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className=" mb-2">
                          <label className="form-label theme-label">Account Number </label>
                          <input
                            type="text"
                            className="form-control"
                            value={isrDtls.acNumber}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className=" mb-2">
                          <label className="form-label theme-label">Address </label>
                          <textarea
                            type="text"
                            className="form-control txt-area-resize-0"
                            placeholder="Address"
                            value={isrDtls.address}
                            aria-label="Disabled input example"
                            disabled
                            readonly
                          ></textarea>
                        </div>
                      </div>

                      {status === "PENDING" || status === "DEACTIVATION REQUESTED" ? (
                        <div className="col-md-12">
                          <div className=" mb-2">
                            <label className="form-label theme-label">Remark </label>

                            <input
                              type="text"
                              className="form-control "
                              value={remarks}
                              aria-label="Disabled input example"
                              onChange={(e) => {
                                setError(false);
                                setRemarks(e.target.value);
                              }}
                            />
                            {error && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; This Field is required
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {status === "PENDING" ? (
                <div className="float-end">
                  <button
                    type="button"
                    className="btn btn-default border me-2"
                    onClick={logIsrApproval("REJECT")}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-success theme-violet"
                    onClick={logIsrApproval("APPROVE")}
                  >
                    Approve
                  </button>
                </div>
              ) : (
                ""
              )}
              {status === "DEACTIVATION REQUESTED" ? (
                <div className="float-end">
                  <button
                    type="button"
                    className="btn btn-default border me-2"
                    onClick={logIsrApproval("DEACTIVATIONREJECT")}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-success theme-violet"
                    onClick={logIsrApproval("DEACTIVATIONAPPROVE")}
                  >
                    Approve
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Spinner show={show} />
      </div>
    </>
  );
};

export default IsrDetails;

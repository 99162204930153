import React, { useRef } from "react";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CallApiPost } from "../../Helper/serviceApi";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import Popup from "../../Layout/Popup";
import GetSvgIcon from "../../../images/svglist.js";
import Spinner from "../../Layout/Spinner";

const GenerelConfig = () => {
  const [show, setShow] = useState(false);
  const [configData, setConfigData] = useState({});
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();

  useEffect(() => {
    getSysConfigList();
  }, []);

  const getSysConfigList = async () => {
    var apiData = await CallApiPost("getConfigData");
    //console.log('===Resp:', apiData);
    setConfigData(apiData.respData);
  };

  const validateData = () => {
    let status = true;
    for (let index = 0; index < configData.length; index++) {
      const element = configData[index];
      if (element.value === "") status = false;
    }
    return status;
  };

  const saveUpdateData = async () => {
    if (validateData()) {
      setShow(true);
      var apiData = await CallApiPost(
        "saveGlobalConfig",
        Object.values(configData).flat(1)
      );
      console.log("===Resp:", apiData);
      setShow(false);
      if (apiData.respCode === 200) {
        setIsSuccess(true);
        setTitle("Success");
        setDescription("Configuration data updated successfully.");
        modalRef.current.click();
      } else {
        setIsSuccess(false);
        setTitle("ERROR");
        setDescription(
          "Unauthorized Access Or Something went wrong. Please try after sometime!!"
        );
        modalRef.current.click();
      }
    }
  };

  const handleOnChange = (event, groupName, index, item) => {
    const temp = { ...configData };
    if (temp[groupName][index].dataType === "date") {
      temp[groupName][index].value = moment(event).format("YYYY-MM-DD");
    } else if (
      groupName === "Routing" &&
      item.configName === "Route Upload Cutoff Date" &&
      (event.target.value > 28 || event.target.value < 1)
    ) {
      event.target.value = item.value;
    }
    if (temp[groupName][index].dataType === "number") {
      if (!event.target.value) {
        event.target.value = 0;
        temp[groupName][index].value = 0;
      } else {
        event.target.value = +event.target.value;
        temp[groupName][index].value = +event.target.value;
      }
    } else {
      temp[groupName][index].value = event.target.value;
    }
    setConfigData(temp);
  };

  // const onSuccess = () => {
  //   getSysConfigList(); //(getUserInfo("userId"), getSessionInfo(), getPersonalId());
  // };

  const renderConfigData = () => {
    let accordions = [];
    let groups = Object.keys(configData);
    for (let index = 0; index < groups.length; index = index + 1) {
      let groupName = groups[index];
      let groupNameLower = groupName.toLowerCase();
      let rows = [];

      for (
        let subIndex = 0;
        subIndex < configData[groupName].length;
        subIndex = subIndex + 2
      ) {
        let item = configData[groupName][subIndex];
        let nextItem;
        if (subIndex + 1 < configData[groupName].length)
          nextItem = configData[groupName][subIndex + 1];
        const row = (
          <div className="row col-12" key={groupNameLower + subIndex}>
            <div className="col-md-5">
              <label htmlFor="floatingInputGrid" className="theme-label">
                {item.configName}
              </label>
              {item.dataType !== "date" && (
                <input
                  type={item.dataType}
                  className="form-control"
                  defaultValue={item.value}
                  //value={item.value}
                  onChange={(event) =>
                    handleOnChange(event, groupName, subIndex, item)
                  }
                  min={item.dataType === "number" ? "1" : ""}
                  max={
                    item.dataType === "number" &&
                    item.configName.toLowerCase().endsWith("date")
                      ? 28
                      : ""
                  }
                />
              )}
              {item.dataType === "date" && (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(item.value)}
                  showTimeSelect={false}
                  onChange={(event) =>
                    handleOnChange(event, groupName, subIndex, item)
                  }
                  className="form-control"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            </div>
            <div className="col-md-1 pt-4 mt-2 p-0">
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title={item.configDesc}
              >
                {GetSvgIcon("ToolTip")}
              </span>
            </div>

            <div className="col-md-5">
              {subIndex + 1 < configData[groupName].length && (
                <label htmlFor="floatingInputGrid" className="theme-label">
                  {nextItem.configName}
                </label>
              )}
              {subIndex + 1 < configData[groupName].length &&
                ((nextItem.dataType !== "date" && (
                  <input
                    type={nextItem.dataType}
                    className="form-control"
                    defaultValue={nextItem.value}
                    //value={nextItem.value}
                    onChange={(event) =>
                      handleOnChange(event, groupName, subIndex + 1, nextItem)
                    }
                    min={nextItem.dataType === "number" ? "1" : ""}
                    max={
                      nextItem.dataType === "number" &&
                      nextItem.configName.toLowerCase().endsWith("date")
                        ? 28
                        : ""
                    }
                  />
                )) ||
                  (item.dataType === "date" && (
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={new Date(item.value)}
                      showTimeSelect={false}
                      onChange={(event) =>
                        handleOnChange(event, groupName, subIndex + 1, item)
                      }
                      className="form-control"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )))}
            </div>
            <div className="col-md-1 pt-4 mt-2 p-0">
              {subIndex + 1 < configData[groupName].length && (
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title={nextItem.configDesc}
                >
                  {GetSvgIcon("ToolTip")}
                </span>
              )}
            </div>
          </div>
        );
        rows.push(row);
      }
      const element = (
        <div className="accordion-item" key={groupNameLower}>
          <h2 className="accordion-header" id={`heading${groupNameLower}`}>
            <button
              className={`accordion-button ${index > 0 ? "collapsed" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${groupNameLower}`}
              aria-expanded={index == 0}
              aria-controls={`collapse${groupNameLower}`}
            >
              <strong>{groupName}</strong>
            </button>
          </h2>
          <div
            id={`collapse${groupNameLower}`}
            className={`accordion-collapse collapse ${
              index == 0 ? "show" : ""
            }`}
            aria-labelledby={`heading${groupNameLower}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{ background: "#e5fbf4" }}>
              {rows}
            </div>
          </div>
        </div>
      );
      accordions.push(element);
    }

    return (
      <div className="accordion" id="accordionExample">
        {accordions}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Global Configuration</div>
              </div>
              {saveSuccess && (
                <div className="alert alert-success mt-1" role="alert">
                  Updated Successfully{" "}
                </div>
              )}
            </div>
            <div className="row">{renderConfigData()}</div>
            <div className="clearfix border-top mt-2 w-100"></div>
            {/* {JSON.stringify(configData)} */}
            <div className="float-end mt-3">
              {/* <button type="button" className="btn btn-default border" onClick={onCancel}>Cancel</button>&nbsp; */}
              <button
                type="button"
                className="btn btn-success theme-violet"
                onClick={saveUpdateData}
              >
                Save
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target="#success-modal"
                id="modalPopup"
                className="d-none"
                ref={modalRef}
              />
            </div>
          </main>
        </div>
      </div>
      <Popup isSuccess={isSuccess} title={title} description={description} />
      <Spinner show={show} />
      <Footer />
    </>
  );
};

export default GenerelConfig;

import React, { useState } from "react";
import DataTable from "react-data-table-component-with-filter";
import { DATE_TIME_FORMAT_MOMENT, customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { CANCELTYPE, CLAIMTYPE, REROUTETYPE } from "../../ConfigConstants/ConfigConstants";
import ProblemShipmentRerouteModal from "./ProblemShipmentRerouteModal";
import { getProblemStatusBadgeClass } from "../Helper/CommonMethods";
export default function ProblemShipmentList({ problemShipment, downloadAttachments, showClaimForm, actionOnOrder, showAlert, setSpinner, loadData }) {
  const [modalConfig, setModalConfig] = useState(null)
  const [rowData, setRowData] = useState(null)
  const showRerouteModal = (rowData) => {
    setRowData(rowData)
    setModalConfig({
      size: "lg",
      show: true,
      title: ""
    });
  }

  const closeModal = () => {
    setRowData(null)
    setModalConfig({
      size: "lg",
      show: false,
      title: ""
    });
  }

  const columns = [
    {
      name: "Customer",
      selector: (row) => row.acct_code,
      sortable: true,
      filterable: true,
    },
    {
      name: "Name",
      selector: (row) => row.cust_name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Waybill No.",
      selector: (row) => row.waybill_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Problem Reason",
      selector: (row) => row.event_reason || row.problem_type,
      sortable: true,
      filterable: true,
    },
    {
      name: "Logged By",
      selector: (row) => row.event_staff_id,
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle No.",
      selector: (row) => row.vhcl_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Last Location",
      selector: (row) => row.event_sbu,
      sortable: true,
      filterable: true,
    },
    {
      name: "Last Activity Time",
      selector: (row) => row.event_time,//moment(row.event_time).format(DATE_TIME_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.problem_status,
      cell: (row) => (
        <>
          <div className={getProblemStatusBadgeClass(row)}
            data-tooltip-id={"ps-status-" + row.id}
          >
            {row.problem_status}
          </div>
          <Tooltip id={"ps-status-" + row.id} content={row.remarks} place="left" />
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            data-tooltip-id={"ps-" + row.id}
            onClick={() => downloadAttachments(row.attachments)}
          >
            {GetSvgIcon("IconFiledownload")}
          </div>
          <Tooltip id={"ps-" + row.id} content={`${row.attachments ? row.attachments.length : 0} attachment(s)`} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    {
      name: "",
      width: "5%",
      cell: (row) => row.problem_status_code === 401 && (
        <>
          <OverlayTrigger
            trigger="click"
            key={row.id}
            placement="left"
            rootClose
            overlay={
              <Popover id={`popover-positioned-lefft`}>
                <Popover.Body>
                  {
                    REROUTETYPE.includes(row.del_event_type) &&
                    <div className="action-item-button" onClick={() => showRerouteModal(row)}>{GetSvgIcon("PaymentDetails")} Reroute</div>
                  }
                  <div className="action-item-button" onClick={() => actionOnOrder('resolve', row)}>{GetSvgIcon("PaymentDetails")} Resolve</div>
                  {
                    CANCELTYPE.includes(row.del_event_type) &&
                    <div className="action-item-button" onClick={() => actionOnOrder('cancel', row)}>{GetSvgIcon("PaymentDetails")} Cancel</div>
                  }
                  {
                    CLAIMTYPE.includes(row.del_event_type) && (row.is_claim_applied === false
                      ? (<div className="action-item-button" onClick={() => showClaimForm(row)}>{GetSvgIcon("PaymentDetails")} Raise Claim</div>)
                      : (<div className="action-item-button text-danger">Claim Raised</div>))
                  }
                </Popover.Body>
              </Popover>
            }
          >
            <span className="row-action-button">{GetSvgIcon("Trippleverticaldot")}</span>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={problemShipment}
          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          defaultSortAsc={false}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
      {!!modalConfig?.show && (
        <ProblemShipmentRerouteModal
          closeModal={closeModal}
          modalConfig={modalConfig}
          rowData={rowData}
          showAlert={showAlert}
          setSpinner={setSpinner}
          loadData={loadData}
        />
      )}
    </div>
  );
}


import React from "react";
import GetSvgIcon from "../../images/svglist";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  PENDINGPAY_WAIT_CANCEL_ARR,
  customDataTableStyles,
} from "../Helper/Constants";
import {
  getPalletStatusBadgeClass,
  permissionCheck,
  // printAWaybill,
  trackWaybill,
} from "../Helper/CommonMethods";
import DataTable from "react-data-table-component-with-filter";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { CallApiPost } from "../Helper/serviceApi";
import { CNCL_ORD_STS, DLVEVENT, CNCL_PLT_STS } from "../../ConfigConstants/ConfigConstants";

const OrderWaybillList = (props) => {
  const {
    shipmentPallets,
    showClaimForm,
    getDeliveryEventLog,
    orderStatus,
    setSpinner,
  } = props;
  const userType = getUserInfo("userType");

  const printWaybill = async (data) => {
    setSpinner(true);
    const payload = {
      shipmentIdList: [],
      palletIdList: [data?.palletId],
      isSearchByOrder: false,
    };
    const resp = await CallApiPost("printWaybill", payload);
    setSpinner(false);
    if (resp.respCode === 200) {
      window.open(resp.respData, "_blank");
    }
  };
  const columns = [
    {
      name: "Waybill Number",
      selector: (row) => row.waybillNo,
      sortable: true,
      // filterable: true,
      //   omit: hideColums,
    },
    {
      name: "Status",
      selector: (row) => row.palletDeliveryStatusText,
      cell: (row) => (
        <>
          <div className={getPalletStatusBadgeClass(row)}>
            {row.palletDeliveryStatusText}
          </div>
        </>
      ),
      sortable: true,
      // filterable: true,
    },
    {
      name: "",
      width: "5%",
      cell: (row) =>
        orderStatus !== 75 && (
          <>
            <OverlayTrigger
              trigger="click"
              key={row.id}
              placement="left"
              rootClose
              overlay={
                <Popover id={`popover-positioned-lefft`}>
                  <Popover.Body>
                    {row.palletDeliveryStatus !== CNCL_PLT_STS
                    && orderStatus !== CNCL_ORD_STS
                    && (
                      <div
                        className="action-item-button"
                        onClick={() => printWaybill(row)}
                      >
                        {GetSvgIcon("Print")} Print Waybill
                      </div>
                    )}
                    
                    {!PENDINGPAY_WAIT_CANCEL_ARR.includes(
                      row.palletDeliveryStatus
                    ) && (
                      <div
                        className="action-item-button"
                        onClick={() => {
                          document.body.click();
                          trackWaybill({ waybill_no: row.waybillNo });
                        }}
                      >
                        {GetSvgIcon("Geoalttrack")} Track Waybill
                      </div>
                    )}
                    {/* CLAIM CAN BE RAISED AGAINST AN ORDER */}
                    {/* {
                    // [79, 80].includes(row.status) &&
                    [79, 80].includes(orderStatus) &&
                    +userType !== 134 &&
                    row.claimApplied === false && (
                      <div
                        className="action-item-button"
                        onClick={() => showClaimForm(row)}
                      >
                        {GetSvgIcon("PaymentDetails")} Claim
                      </div>
                    )} */}

                    {permissionCheck(DLVEVENT, "allowedRead") && (
                      <div
                        className="action-item-button"
                        onClick={() => getDeliveryEventLog(row.waybillNo)}
                        data-bs-toggle="modal"
                        data-bs-target="#delivery-event-log-modal"
                      >
                        {GetSvgIcon("IconDiscount")} Delivery Log
                      </div>
                    )}
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="row-action-button">
                {GetSvgIcon("Trippleverticaldot")}
              </span>
            </OverlayTrigger>
          </>
        ),
    },
  ];
  return (
    <div className="bg-white border border-2 rounded-2 mb-3">
      <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
        Waybills
      </div>
      <div className="">
        <DataTable
          columns={columns}
          data={shipmentPallets}
          style={`table table-striped table-hover`}
          customStyles={customDataTableStyles}
          // pagination
          striped
          highlightOnHover
          // defaultSortFieldId={1}
          defaultSortAsc={false}
          // paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          // paginationPerPage={defaultPageLimit}
        />
      </div>
    </div>
  );
};

export default OrderWaybillList;

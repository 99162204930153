import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Container, Row } from "react-bootstrap";
import LonghaulCommissionList from "./LonghaulCommissionList";
import DataTable from "react-data-table-component-with-filter";
import {
  DATE_TIME_FORMAT_MOMENT,
  DATE_TIME_ZONE_MOMENT_FORMAT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import moment from "moment";

const LonghaulHistory = ({ show, handleCLose, data }) => {
  // const [lhCommList] = props;
  const columns = [
    {
      name: "Stamp User",
      selector: (row) => row?.stampuser,
    },
    {
      name: "Stamp Date",
      selector: (row) => row?.stampdate,
    },
    {
      name: "1st Point Charge",
      selector: (row) => row.base_charge,
    },
    {
      name: "Drop Point Charge",
      selector: (row) => row.sub_break_charge,
    },
  ];

  return (
    <>
      <Modal size="lg" show={show} onHide={handleCLose}>
        <Modal.Header className="theme-violet" closeButton>
          <Modal.Title>HISTORY</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div className="col-md-12 customized-data-table position-relative mt-0">
            <DataTable
              columns={columns}
              data={data}
              pagination
              striped
              highlightOnHover
              paginationRowsPerPageOptions={defaultRowsPerPageOptions}
              paginationPerPage={defaultPageLimit}
              customStyles={customDataTableStyles}
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default LonghaulHistory;

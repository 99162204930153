import CustomerDashboard from './Customer/CustomerDashboard';
import { CMGDSHB, ADMDSHB } from '../../ConfigConstants/ConfigConstants';
import AdminDashboard from './Admin/AdminDashboard';
import { permission } from '../Helper/CommonMethods';
import Unauthorized from '../AuthUtils/Unauthorized';

const DashboradMain = () => {
    var element;
    if (permission(CMGDSHB).allowedRead) {
        element = <CustomerDashboard />;
    } else if (permission(ADMDSHB).allowedRead) {
        element = <AdminDashboard />;
    } else {
        element = <Unauthorized />
    }

    return element
}

export default DashboradMain
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import AWFAddEdit from "./AWFAddEdit";
import AWFList from "./AWFList";
import { useNavigate } from "react-router-dom";

import {
  defaultPageLimit,
  defaultPageOffset,
  ALPHANUM_SPACE_SPCL_CHAR,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import Popup from "../../Layout/Popup";

const initialApprovalStep = [
  {
    id: 0,
    approvalId: 0,
    seqNo: 0,
    seqName: "",
    roleId: "",
    approveBy: 0,
    checklist: "",
  },
];

const initialApproval = {
  id: 0,
  userType: 0,
  assignedTo: "",
  initUserType: "0",
  remarks: "",
  approvalSteps: [],
};

const initialRole = {
  id: 0,
  roleName: "",
};

const initialUserType = {
  id: 0,
  optionValue: "",
};

const initApproveType = {
  id: 0,
  optionValue: "",
};

const initialApprovalList = [
  {
    id: 0,
    userTypeId: "",
    userTypeValue: "",
    roleId: "",
    roleName: "",
    remarks: "",
    noOfSteps: "",
  },
];

const initStepErr = {
  roleId: false,
  approveBy: false,
  checklist: false,
};

const initApprovalErr = {
  userType: false,
  assignedTo: false,
  remarks: false,
};

const AWFMain = () => {
  const [roles, setRoles] = useState([initialRole]);
  const [userTypes, setUserTypes] = useState([initialUserType]);
  const [approval, setApproval] = useState(
    JSON.parse(JSON.stringify(initialApproval))
  );
  const [approvalSteps, setApprovalSteps] = useState(JSON.parse(JSON.stringify(initialApprovalStep)));
  const [approvalList, setApprovalList] = useState(initialApprovalList);
  const [approvalError, setApprovalError] = useState(initApprovalErr);
  const [approvalStepErr, setApprovalStepErr] = useState([initStepErr]);
  const [approveBy, setApproveBy] = useState([initApproveType]);
  const [deleteStepArr, setDeleteStepArr] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [userTypeList] = useState([]);
  const [statusFail, setStatusFail] = useState();
  const [title, setTitle] = useState();
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const modalRef = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
    fetchUserTypes();
    fetchApproveType();
    fetchApprovalList();
  }, []);

  const fetchRoles = async () => {
    let data = await CallApiGet("getRoleList");
    if (data.respCode === 200 || data.respCode === 0) {
      setRoles(data.respData);
    } else {
      setRoles([])
    }
  };

  const fetchUserTypes = async () => {
    let data = await CallApiPost("SysOptionGetByCode", ["USER_TYPE"]);
    setUserTypes(data.respData);
  };

  const fetchApproveType = async () => {
    let data = await CallApiPost("SysOptionGetByCode", ["APPROVE_TYPE"]);
    setApproveBy(data.respData);
  };
  const fetchApprovalList = async () => {
    let data = await CallApiGet("list-approval");
    setApprovalList(data.respData);
  };

  const addApprovalStepErr = () => {
    var errSteps = [];
    for (let index = 0; index < approvalSteps.length; index++) {
      errSteps.push({
        seqName: false,
        roleId: false,
        approveBy: false,
      });
    }
    setApprovalStepErr(errSteps);
  };

  const isValidated = () => {
    var isValid = true;
    if (approval.userType === 0 || approval.userType === "") {
      setApprovalError((err) => ({
        ...err,
        userType: true,
      }));
      isValid = false;
    }

    // if (approval.assignedTo === 0 || approval.assignedTo === "") {
    //   setApprovalError((err) => ({
    //     ...err,
    //     assignedTo: true,
    //   }));
    //   isValid = false;
    // }

    if (!ALPHANUM_SPACE_SPCL_CHAR.test(approval.remarks)) {
      setApprovalError((err) => ({
        ...err,
        remarks: true,
      }));
      isValid = false;
    }

    approvalSteps.map((step, index) => {

      if (step.seqName === "") {
        const rows = [...approvalStepErr];
        rows[index].seqName = true;
        setApprovalStepErr(rows);
        isValid = false;
      }

      if (step.roleId === 0 || step.roleId === "") {
        const rows = [...approvalStepErr];
        rows[index].roleId = true;
        setApprovalStepErr(rows);
        isValid = false;
      }

      if (step.approveBy === 0 || step.approveBy === "") {
        const rows = [...approvalStepErr];
        rows[index].approveBy = true;
        setApprovalStepErr(rows);
        isValid = false;
      }

      if (!ALPHANUM_SPACE_SPCL_CHAR.test(step.checklist)) {
        const rows = [...approvalStepErr];
        rows[index].checklist = true;
        setApprovalStepErr(rows);
        isValid = false;
      }
    });

    return isValid;
  };

  const saveApprovalWorkFlow = async () => {
    if (!isValidated()) {
      console.log(approvalError);
      return;
    }

    approvalSteps.forEach((element, index) => {
      element.seqNo = index;
      if (index === 0) {
        approval.assignedTo = element.roleId
      }
    });
    approval.approvalSteps = approvalSteps;

    const awfSaveModel = {
      awfModel: approval,
      deleteAwfArr: deleteStepArr,
    };
    console.log(approval);

    await CallApiPost("save-approval", awfSaveModel).then(
      (res) => {
        if (res.respCode === 200) {
          // setApproval(JSON.parse(JSON.stringify(initialApproval)));
          // setApprovalSteps(JSON.parse(JSON.stringify(initialApprovalStep)));
          // window.location.reload()
          clear()
          approval.id > 0
            ? onSuccess("Approval Workflow updated successfully !!!")
            : onSuccess("Approval Workflow added successfully !!!");
        } else if (res.respCode === 500) {
          setSubmitStatusFail(true);
          setStatusFail(SERVER_ERR_MSG);
          setTimeout(() => {
            setSubmitStatusFail(false);
          }, 10000);
        } else {
          setSubmitStatusFail(true);
          setStatusFail(res.respMessage);
          setTimeout(() => {
            setSubmitStatusFail(false);
          }, 10000);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const insertApproval = (key, value) => {
    if (key === "userType") {
      if (!userTypeList.includes(value)) {
        setDropdownData(key, value);
      } else {
        setApproval((data) => ({
          ...data,
          [key]: "0",
        }));
        setStatusFail(true);
        setTimeout(() => {
          setStatusFail(false);
        }, 2000);
      }
    } else {
      setDropdownData(key, value);
    }
  };

  const setDropdownData = (key, value) => {
    setApproval((data) => ({
      ...data,
      [key]: value,
    }));

    setApprovalError((err) => ({
      ...err,
      [key]: false,
    }));
  };

  const clear = () => {
    setSubmitStatusFail(false);
    setApproval(JSON.parse(JSON.stringify(initialApproval)));
    setApprovalSteps([
      {
        id: 0,
        approvalId: 0,
        seqNo: 0,
        seqName: "",
        roleId: "",
        approveBy: "",
        checklist: "",
      },
    ]);
    setApprovalError(initApprovalErr);
    setApprovalStepErr([
      {
        seqName: false,
        roleId: false,
        approveBy: false,
      },
    ]);
  };

  const callback = () => {
    const initSearchData = {
      userType: "",
      assignedTo: "",
      noOfSteps: "",
      limit: defaultPageLimit,
      offset: defaultPageOffset,
    };
    fetchApprovalList(initSearchData);
  };

  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Congratulations");
    setDescription(msg);
    modalRef.current.click();
  };

  const fetchApprovalStepsById = async (id) => {
    await CallApiGet(`get-approval-steps?id=${id}`).then(
      (res) => {
        if (res.respCode === 200 || res.respCode === 0) {
          setApprovalSteps(res.respData);
        } else {
          setSubmitStatusFail(true);
          setStatusFail(SERVER_ERR_MSG);
          setTimeout(() => {
            setSubmitStatusFail(false);
          }, 10000);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Approval Process</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-end">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#button-modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>{" "}
                    ADD APPROVAL
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <AWFList
              approvalList={approvalList}
              approval={approval}
              setApproval={setApproval}
              fetchApprovalStepsById={fetchApprovalStepsById}
              userTypes={userTypes}
              roles={roles}
              fetchApprovalList={fetchApprovalList}
            />
          </main>
        </div>
        <AWFAddEdit
          roles={roles}
          userTypes={userTypes}
          approveBy={approveBy}
          approval={approval}
          approvalSteps={approvalSteps}
          setApprovalSteps={setApprovalSteps}
          insertApproval={insertApproval}
          saveApprovalWorkFlow={saveApprovalWorkFlow}
          clear={clear}
          setError={setApprovalError}
          error={approvalError}
          approvalStepErr={approvalStepErr}
          setApprovalStepErr={setApprovalStepErr}
          addApprovalStepErr={addApprovalStepErr}
          deleteStepArr={deleteStepArr}
          setDeleteStepArr={setDeleteStepArr}
          statusFail={statusFail}
          submitStatusFail={submitStatusFail}
          setSubmitStatusFail={setSubmitStatusFail}
        />
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
    </div>
  );
};

export default AWFMain;

import React, { Suspense, lazy } from "react";
// import Home from './Components/Home/Home';
import { Route, Routes, useLocation } from "react-router-dom";
// import SecurityRoles from './Components/SecurityRoles/SecurityRoles';
import UserManagement from "./Components/UserManagement/UserManagement";
import Service from "./Components/MasterConfig/LhService/Service";
import Discount from "./Components/Discount/Discount";
import AWFMain from "./Components/ApprovalWorkFlow/SysConfig/AWFMain";
import ShipmentContentDocMgm from "./Components/ContentDocumentMgm/ShipmentContentDocMgm";
import ShipmentCancellationConfig from "./Components/MasterConfig/ShipmentCancellation/ShipmentCancellationConfig";
import InsuranceTaxConfig from "./Components/MasterConfig/InsuranceTax/InsuranceTaxConfig";
import CustomerRegistration from "./Components/Registration/Customer/CustomerRegistration";
import Login from "./Components/Login/Login";
import PartnerMain from "./Components/Registration/Partner/PartnerMain";
import RateChat from "./Components/MasterConfig/RateChatView/RateChat";
import DashboradMain from "./Components/Dashboard/DashboradMain";
import GenerelConfig from "./Components/MasterConfig/GenerelConfig/GenerelConfig";

import CustomerDashboard from "./Components/Dashboard/Customer/CustomerDashboard";
import ApprovalTask from "./Components/ApprovalWorkFlow/Customer/ApprovalTask/ApprovalTask";
import CustomerDetails from "./Components/ApprovalWorkFlow/Customer/CustomerDetails";
import ResetPassword from "./Components/ForgotPassword/ResetPassword";
import AdminDashboard from "./Components/Dashboard/Admin/AdminDashboard";
import WorkInProgress from "./Components/WorkInProgress/WorkInProgress";
import Inprogress from "./Components/Registration/InProgress/Inprogress";
import IsrRegistration from "./Components/Registration/ISR/IsrRegistration";
import IsrApprovalMain from "./Components/ApprovalWorkFlow/ISR/IsrApproval/IsrApprovalMain";
import CustomerProfile from "./Components/Profile/CustomerProfile";
import PartnerApprovalMain from "./Components/ApprovalWorkFlow/Partner/PartnerApprovalMain";
import PartnerDetails from "./Components/ApprovalWorkFlow/Partner/PartnerDetails";
import IsrActivationMain from "./Components/ApprovalWorkFlow/ISR/IsrActivation/IsrActivationMain";
import DriverProfile from "./Components/Profile/Partner/DriverProfile";
import PartnerManagement from "./Components/Management/Partner/PartnerManagement";
import CompanyProfile from "./Components/Profile/Partner/CompanyProfile";
import StndBooking from "./Components/OrderBooking/StandardBooking/StndBooking";
import RolesMain from "./Components/Administration/SecurityRoles/RolesMain";
import PostBooking from "./Components/OrderBooking/StandardBooking/PostBooking";
import CustomerManagementMain from "./Components/Management/Customer/CustomerManagementMain";
import AboutUs from "./Components/AboutUs/AboutUs";
import InboundOrderMain from "./Components/Inbound Order/InboundOrderMain";
import CustomerOrderMain from "./Components/OrderManagement/CustomerOrderMain";
import CustomerOrderShipmentView from "./Components/OrderManagement/CustomerOrderShipmentView";
import InspectionLogMain from "./Components/Vehicle Log/InspectionLogMain";
import TrackWaybill from "./Components/OrderBooking/Track/TrackWaybill";
import WayBillGenerator from "./Components/Helper/WayBillGenerator";
import VehicleFuelLogMain from "./Components/Vehicle Log/VehicleFuelLogMain";
import Home from "./Components/OrderBooking/StandardBooking/Home";
import PromotionMain from "./Components/PromotionManagement/PromotionMain";
import InvoiceMgmMain from "./Components/BillingAndInvoice/InvoiceMgmMain";
import ShipmentViewReassignVehicle from "./Components/OrderManagement/ShipmentViewReassignVehicle";
import LastMilePlanningMain from "./Components/OrderManagement/LastMilePlanningMain";
import IsrCommissionConfig from "./Components/MasterConfig/IsrCommission/IsrCommissionConfig";
import CommissionMain from "./Components/Commission/CommissionMain";
import ClaimMain from "./Components/Claim/ClaimMain";
import StaffProfile from "./Components/UserManagement/StaffProfile";
import BillingList from "./Components/BillingAndInvoice/BillingList";
import SlaServiceMain from "./Components/MasterConfig/LhService/SlaServiceMain";
import AuthProvider from "./Components/AuthUtils/AuthProvider";
import {
  ABOUTUS,
  APVLWF,
  CANCEL,
  CLAIM,
  CMGLIST,
  CUSTAPVL,
  DIS,
  HOLIDAY,
  INBORDC,
  INSTAX,
  ISRAPVL,
  ISRCOMMCONFIG,
  ISRREG,
  LMP,
  PARTAPPRV,
  PMLIST,
  PROMO,
  RACH,
  ROLE,
  ROUT,
  RTPLN,
  RTPLNDTLS,
  SBU,
  SHIPVIEW,
  SLA,
  SYSCFG,
  USRMGM,
  VHCLTYPE,
  VEHICLE,
  CMGDTLS,
  CMGDSHB,
  ADMDSHB,
  PRFLCUST,
  PRFLSTAFF,
  ORDBKNG,
  ORDLIST,
  CONTDOC,
  PREBOOKC,
  ISRLIST,
  PRFLCOMP,
  LCOPRTN,
  INV,
  INSPLG,
  VFLG,
  REPORT_OPERATION,
  REPORT_FINANCE,
  CUSTOMER_USER_MANAGEMENT,
  HOMECONFIG,
  INBRATECONFIG,
  SUPPLIERMGM,
  SCHCHROUTESCHEDULE,
  SCHCHROUTEPLAN,
  SCHCHRATECONFIG,
  CHORDERLIST,
  ORDDTLS,
  CUSTADDRBOOK,
  BSAPPRV,
} from "./ConfigConstants/ConfigConstants";
import Unauthorized from "./Components/AuthUtils/Unauthorized";
import OrderSummary from "./Components/OrderBooking/StandardBooking/OrderSummary";
import CustomNotificationMain from "./Components/Notification/CustomNotification/CustomNotificationMain";
import ChartaredBooking from "./Components/OrderBooking/StandardBooking/ChartaredBooking";
import FtlLastMilePlanningMain from "./Components/OrderManagement/FtlLastMilePlanningMain";
import CustomerOrderDetails from "./Components/OrderManagement/CustomerOrderDetails";
import PrivacyPolicy from "./Components/Privacy/PrivacyPolicy";
import TermsConditions from "./Components/Privacy/TermsConditions";
import ClaimRaise from "./Components/Claim/ClaimRaise";
import ClaimAck from "./Components/Claim/ClaimAck";
import OperationalReports from "./Components/Reports/OperationalReports";
import FinancialReports from "./Components/Reports/FinancialReports";
import LonghaulCommission from "./Components/Commission/Longhaul/LonghaulCommission";
import { InbStateProvider } from "./Components/Inbound Order/InboundContext";
import { AdvertisementMain } from "./Components/HomeConfiguration/Advertisement/AdvertisementMain";
import NotificationMain from "./Components/HomeConfiguration/Notification/NotificationMain";
import RateMain from "./Components/InboundOrderConfig/RateMain";
import NewsAndBulletinMain from "./Components/HomeConfiguration/NewsAndBulletin/NewsAndBulletinMain";
import SupplierRegistrationMain from "./Components/Registration/Supplier/SupplierRegistrationMain";
import CharteredOrdersMain from "./Components/CharteredPlanning/CharteredOrdersMain";
import ScheduleMain from "./Components/CharteredRouteConfig/CharteredSchedule/ScheduleMain";
import MultiRouteMain from "./Components/CharteredRouteConfig/CharteredRoute/MultiRouteMain";
import CharteredOrderDetails from "./Components/OrderBooking/ChartaredOrderBooking/CharteredOrderDetails";
import CharteredRateConfigMain from "./Components/CharteredRateConfiguration/CharteredRateConfigMain";
import VasChargeMain from "./Components/RateChat/VasChargeConfig/VasChargeMain";
import CreditNoteRoute from "./Components/BillingAndInvoice/CreditNote/CreditNoteRoute";
import CreditNoteDetails from "./Components/BillingAndInvoice/CreditNote/CreditNoteDetails";
import AddressBookMain from "./Components/AddressBookManagement/AddressBookMain";
import BankSlipMain from "./Components/BillingAndInvoice/BankSlipPayment/BankSlipMain";

const Holiday = lazy(() => import("./Components/Holiday/Holiday"));
const Driver = lazy(() => import("./Components/Driver/Driver"));
const SbuManagement = lazy(() =>
  import("./Components/SbuManagement/SbuManagement")
);
const VehicleMain = lazy(() => import("./Components/Vehicle/VehicleMain"));
const RouteMain = lazy(() => import("./Components/Route/RouteMain"));
const PlanDetails = lazy(() => import("./Components/RoutePlan/PlanDetails"));
const VehicleTypeMain = lazy(() =>
  import("./Components/VehicleType/VehicleTypeMain")
);
const PreBooking = lazy(() =>
  import("./Components/OrderBooking/PreBooking/PreBooking")
);
// const StndBookingSearch = lazy(() => import('./Components/OrderBooking/StandardBooking/StndBookingSearch'));
const PlanList = lazy(() => import("./Components/RoutePlan/PlanList"));
const InboundOrderManifest = lazy(() =>
  import("./Components/Inbound Order/InboundOrderManifest")
);
const BidList = lazy(() => import("./Components/OpenBid/BidList"));
const DOMain = lazy(() => import("./Components/DO/DOMain"));
// const ClaimAck = lazy(() => import("./Components/Claim/ClaimAck"))
const CustomerUserMain = lazy(() =>
  import("./Components/Management/Customer/CustomerUser/CustomerUserMain")
);

function App() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/login" element={<Login />} />
        <Route path="/customerRegister" element={<CustomerRegistration />} />
        <Route path="/partnerRegister" element={<PartnerMain />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/dashboard" element={<DashboradMain />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsConditions />} />
        <Route path="/longhaul" element={<LonghaulCommission />} />
        <Route
          path="/charteredOrders"
          element={
            <AuthProvider argFeatCode={CHORDERLIST}>
              <CharteredOrdersMain />
            </AuthProvider>
          }
        />
        <Route
          path="/charteredRoute"
          element={
            <AuthProvider argFeatCode={SCHCHROUTEPLAN}>
              <MultiRouteMain />
            </AuthProvider>
          }
        />
        <Route
          path="/charteredSchedule"
          element={
            <AuthProvider argFeatCode={SCHCHROUTESCHEDULE}>
              <ScheduleMain />
            </AuthProvider>
          }
        />
        <Route
          path="/charteredOrderDetails"
          element={<CharteredOrderDetails />}
        />
        <Route
          path="/charteredRate"
          element={
            <AuthProvider argFeatCode={SCHCHRATECONFIG}>
              <CharteredRateConfigMain />
            </AuthProvider>
          }
        />
        {/* System Configuration */}
        <Route
          path="/globalConfig"
          element={
            <AuthProvider argFeatCode={SYSCFG}>
              <GenerelConfig />
            </AuthProvider>
          }
        />
        <Route
          path="/taxConfig"
          element={
            <AuthProvider argFeatCode={INSTAX}>
              <InsuranceTaxConfig />
            </AuthProvider>
          }
        />
        <Route
          path="/holiday"
          element={
            <AuthProvider argFeatCode={HOLIDAY}>
              <Holiday />
            </AuthProvider>
          }
        />
        <Route
          path="/cancellationConfig"
          element={
            <AuthProvider argFeatCode={CANCEL}>
              <ShipmentCancellationConfig />
            </AuthProvider>
          }
        />
        <Route
          path="/shipment"
          element={
            <AuthProvider argFeatCode={CONTDOC}>
              <ShipmentContentDocMgm />
            </AuthProvider>
          }
        />
        <Route
          path="/discount"
          element={
            <AuthProvider argFeatCode={DIS}>
              <Discount />
            </AuthProvider>
          }
        />
        <Route
          path="/promotion"
          element={
            <AuthProvider argFeatCode={PROMO}>
              <PromotionMain />
            </AuthProvider>
          }
        />
        {/* <Route
            path="/inboundShipment"
            element={
              <AuthProvider argFeatCode={INBOUORDCFG}>
                <InternationalInboundConfig />
              </AuthProvider>
            }
          /> */}
        <Route
          path="/aboutus"
          element={
            <AuthProvider argFeatCode={ABOUTUS}>
              <AboutUs />
            </AuthProvider>
          }
        />
        {/* Admin  */}
        <Route
          path="/sbu"
          element={
            <AuthProvider argFeatCode={SBU}>
              <SbuManagement />
            </AuthProvider>
          }
        />
        <Route
          path="/userManagement"
          element={
            <AuthProvider argFeatCode={USRMGM}>
              <UserManagement />
            </AuthProvider>
          }
        />
        <Route
          path="/securityRoles"
          element={
            <AuthProvider argFeatCode={ROLE}>
              <RolesMain />
            </AuthProvider>
          }
        />
        <Route
          path="/approvalWorkFlow"
          element={
            <AuthProvider argFeatCode={APVLWF}>
              <AWFMain />
            </AuthProvider>
          }
        />
        <Route
          path="/adminDashBoard"
          element={
            <AuthProvider argFeatCode={ADMDSHB}>
              <AdminDashboard />
            </AuthProvider>
          }
        />
        {/* Order managment */}
        <Route
          path="/customerOrder"
          element={
            <AuthProvider argFeatCode={ORDLIST}>
              <CustomerOrderMain />
            </AuthProvider>
          }
        />
        <Route
          path="/orderDetails"
          element={
            <AuthProvider argFeatCode={ORDDTLS}>
              <CustomerOrderDetails />
            </AuthProvider>
          }
        />
        <Route
          path="/shipmentView"
          element={
            <AuthProvider argFeatCode={SHIPVIEW}>
              {" "}
              <CustomerOrderShipmentView />
            </AuthProvider>
          }
        />
        <Route
          path="/lastMilePlanning"
          element={
            <AuthProvider argFeatCode={LMP}>
              <LastMilePlanningMain />
            </AuthProvider>
          }
        />
        <Route
          path="/ftlLastMilePlanning"
          element={
            <AuthProvider argFeatCode={LMP}>
              <FtlLastMilePlanningMain />
            </AuthProvider>
          }
        />
        <Route
          path="/trackWaybill"
          element={
            // <AuthProvider argFeatCode={TRKSHIP}>
            <TrackWaybill />
            // </AuthProvider>
          }
        />
        <Route
          path="/preBooking"
          element={
            <AuthProvider argFeatCode={PREBOOKC}>
              <Suspense>
                <PreBooking />
              </Suspense>
            </AuthProvider>
          }
        />
        <Route
          path="/inboundorder"
          element={
            <AuthProvider argFeatCode={INBORDC}>
              <InbStateProvider>
                <InboundOrderMain />
              </InbStateProvider>
            </AuthProvider>
          }
        />
        <Route
          path="/inbOrderManifest"
          element={
            <AuthProvider argFeatCode={INBORDC}>
              <Suspense>
                <InbStateProvider>
                  <InboundOrderManifest />
                </InbStateProvider>
              </Suspense>
            </AuthProvider>
          }
        />

        <Route
          path="/claim"
          element={
            <AuthProvider argFeatCode={CLAIM}>
              <ClaimMain />
            </AuthProvider>
          }
        />
        <Route
          path="/invoice"
          element={
            <AuthProvider argFeatCode={INV}>
              <InvoiceMgmMain />
            </AuthProvider>
          }
        />
        <Route
          path="/creditNoteDtls/:data"
          element={
            <AuthProvider argFeatCode={INV}>
              <CreditNoteDetails />
            </AuthProvider>
          }
        />
        <Route
          path="/creditNote"
          element={
            <AuthProvider argFeatCode={INV}>
              <CreditNoteRoute />
            </AuthProvider>
          }
        />
        <Route
          path="/bankSlip"
          element={
            <AuthProvider argFeatCode={BSAPPRV}>
              <BankSlipMain />
            </AuthProvider>
          }
        />
        {/* Service Management */}
        <Route
          path="/service"
          element={
            <AuthProvider argFeatCode={SLA}>
              <SlaServiceMain />
            </AuthProvider>
          }
        />
        <Route
          path="/rateChart"
          element={
            <AuthProvider argFeatCode={RACH}>
              <Service />
            </AuthProvider>
          }
        />
        <Route
          path="/rateChartDtls"
          element={
            <AuthProvider argFeatCode={RACH}>
              <RateChat />
            </AuthProvider>
          }
        />

        {/* Vehicle Management */}
        <Route path="/driver" element={<Driver />} />
        <Route
          path="/vehicleType"
          element={
            <AuthProvider argFeatCode={VHCLTYPE}>
              <VehicleTypeMain />
            </AuthProvider>
          }
        />
        <Route
          path="/vehicle"
          element={
            <AuthProvider argFeatCode={VEHICLE}>
              <VehicleMain />
            </AuthProvider>
          }
        />

        {/* Route Management */}
        <Route
          path="/route"
          element={
            <AuthProvider argFeatCode={ROUT}>
              <RouteMain />
            </AuthProvider>
          }
        />
        <Route
          path="/routePlan"
          element={
            <AuthProvider argFeatCode={RTPLN}>
              <Suspense>
                <PlanList />
              </Suspense>
            </AuthProvider>
          }
        />
        <Route
          path="/routePlanDetails/:data"
          element={
            <AuthProvider argFeatCode={RTPLNDTLS}>
              <Suspense>
                <PlanDetails />
              </Suspense>
            </AuthProvider>
          }
        />
        {/* <Route path="/standardPalletRate" element={<StandardPalletRate />} /> */}

        <Route
          path="/isrCommissionConfig"
          element={
            <AuthProvider argFeatCode={ISRCOMMCONFIG}>
              <IsrCommissionConfig />
            </AuthProvider>
          }
        />
        {/* <Route path="/isrAssign" element={<IsrAssignMain />} /> */}
        {/* Customer Management */}
        <Route
          path="/customerManagement"
          element={
            <AuthProvider argFeatCode={CMGLIST}>
              <CustomerManagementMain type={query.get("type")} />
            </AuthProvider>
          }
        />
        <Route
          path="/customerDetails"
          element={
            <AuthProvider argFeatCode={CMGDTLS}>
              <CustomerDetails />
            </AuthProvider>
          }
        />
        <Route
          path="/customerDashboard"
          element={
            <AuthProvider argFeatCode={CMGDSHB}>
              <CustomerDashboard />
            </AuthProvider>
          }
        />
        {/* Customer Approvals  */}
        <Route
          path="/approvalTask"
          element={
            <AuthProvider argFeatCode={CUSTAPVL}>
              <ApprovalTask type={query.get("type")} />
            </AuthProvider>
          }
        />
        {/* ISR Approvals  */}
        <Route
          path="/isrApproval"
          element={
            <AuthProvider argFeatCode={ISRAPVL}>
              {" "}
              ISR
              <IsrApprovalMain />
            </AuthProvider>
          }
        />
        {/* ISR Management */}
        <Route
          path="/isrActivation"
          element={
            <AuthProvider argFeatCode={ISRLIST}>
              <IsrActivationMain />
            </AuthProvider>
          }
        />
        <Route
          path="/isrRegistration"
          element={
            <AuthProvider argFeatCode={ISRREG}>
              <IsrRegistration />
            </AuthProvider>
          }
        />
        {/* Partner Management */}
        <Route path="/personalProfile" element={<DriverProfile />} />
        <Route
          path="/partnerManagement"
          element={
            <AuthProvider argFeatCode={PMLIST}>
              <PartnerManagement />
            </AuthProvider>
          }
        />
        {/* Partner Approval */}
        <Route
          path="/partnerApproval"
          element={
            <AuthProvider argFeatCode={PARTAPPRV}>
              <PartnerApprovalMain />
            </AuthProvider>
          }
        />
        <Route path="/partnerDetails" element={<PartnerDetails />} />

        {/* Profile */}
        <Route
          path="/customerProfile"
          element={
            <AuthProvider argFeatCode={PRFLCUST}>
              <CustomerProfile />
            </AuthProvider>
          }
        />
        <Route
          path="/staffProfile"
          element={
            <AuthProvider argFeatCode={PRFLSTAFF}>
              <StaffProfile />
            </AuthProvider>
          }
        />
        <Route
          path="/companyProfile"
          element={
            <AuthProvider argFeatCode={PRFLCOMP}>
              <CompanyProfile />
            </AuthProvider>
          }
        />

        <Route path="/workInProgress" element={<WorkInProgress />} />
        <Route path="/inprogress" element={<Inprogress />} />

        {/* Order Booking */}
        <Route
          path="/bookingStnd"
          element={
            <AuthProvider argFeatCode={ORDBKNG}>
              <StndBooking />
            </AuthProvider>
          }
        />
        <Route
          path="/bookingChartered"
          element={
            <AuthProvider argFeatCode={ORDBKNG}>
              <ChartaredBooking />
            </AuthProvider>
          }
        />

        <Route
          path="/postOrderBooking"
          element={
            <AuthProvider argFeatCode={ORDBKNG}>
              <PostBooking />
            </AuthProvider>
          }
        />
        <Route
          path="/ordrSummary"
          element={
            <AuthProvider argFeatCode={ORDBKNG}>
              {" "}
              <OrderSummary />{" "}
            </AuthProvider>
          }
        />
        {/* <Route
            path="/bookingSearch"
            element={
              <AuthProvider argFeatCode={ORDBKNG}>
                <BookingSearchComponent />
              </AuthProvider>
            }
          /> */}
        {/* <Route path="/bookingList" element={<StndBookingList />} /> */}

        <Route
          path="/inspection"
          element={
            <AuthProvider argFeatCode={INSPLG}>
              <InspectionLogMain />
            </AuthProvider>
          }
        />
        <Route path="/printWayBill/:data" element={<WayBillGenerator />} />

        <Route
          path="/fuellog"
          element={
            <AuthProvider argFeatCode={VFLG}>
              <VehicleFuelLogMain />
            </AuthProvider>
          }
        />
        <Route
          path="/customNotification"
          element={<CustomNotificationMain />}
        />
        <Route
          path="/reassignVehicle"
          element={<ShipmentViewReassignVehicle />}
        />

        <Route path="/commission" element={<CommissionMain />} />

        <Route path="/billingList" element={<BillingList />} />
        <Route
          path="/bidList"
          element={
            <Suspense>
              <BidList />
            </Suspense>
          }
        />
        <Route
          path="/do"
          element={
            <AuthProvider argFeatCode={LCOPRTN}>
              <DOMain />
            </AuthProvider>
          }
        />
        <Route
          path="/raiseClaim"
          element={
            <AuthProvider argFeatCode={CLAIM}>
              <ClaimRaise />
            </AuthProvider>
          }
        />

        <Route
          path="/operetional-report"
          element={
            <AuthProvider argFeatCode={REPORT_OPERATION}>
              <OperationalReports />
            </AuthProvider>
          }
        />
        <Route
          path="/claimAck"
          element={
            <AuthProvider argFeatCode={CLAIM}>
              <ClaimAck />
            </AuthProvider>
          }
        />
        <Route
          path="/finantial-report"
          element={
            <AuthProvider argFeatCode={REPORT_FINANCE}>
              <FinancialReports />
            </AuthProvider>
          }
        />
        <Route
          path="/customer-user"
          element={
            <AuthProvider argFeatCode={CUSTOMER_USER_MANAGEMENT}>
              <CustomerUserMain />
            </AuthProvider>
          }
        />
        <Route
          path="/home-notification-config"
          element={
            <AuthProvider argFeatCode={HOMECONFIG}>
              <NotificationMain />
            </AuthProvider>
          }
        />
        <Route
          path="/home-advertisement-config"
          element={
            <AuthProvider argFeatCode={HOMECONFIG}>
              <AdvertisementMain />
            </AuthProvider>
          }
        />

        <Route
          path="/inbOrdRateConfig"
          element={
            <AuthProvider argFeatCode={INBRATECONFIG}>
              <RateMain />
            </AuthProvider>
          }
        />

        <Route
          path="/home-news-bulletin-config"
          element={
            <AuthProvider argFeatCode={HOMECONFIG}>
              <NewsAndBulletinMain />
            </AuthProvider>
          }
        />
        <Route
          path="/suppliermanagement"
          element={
            <AuthProvider argFeatCode={SUPPLIERMGM}>
              <SupplierRegistrationMain />
            </AuthProvider>
          }
        />
        <Route path="/AgentCustomerCharges" element={<VasChargeMain />} />
        <Route
          path="/addressBook"
          element={
            <AuthProvider argFeatCode={CUSTADDRBOOK}>
              <AddressBookMain />
            </AuthProvider>
          }
        />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;

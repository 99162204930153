import React from "react";
import { createContext } from "react";
import { useState } from "react";
import { initInbOrd } from "./InboundOrderUploadFile";

const InbStateContext = createContext();

const InbStateProvider = ({ children }) => {
  const [order, setOrder] = useState(initInbOrd);
  return (
    <InbStateContext.Provider value={{ order, setOrder }}>
      <>{children}</>
    </InbStateContext.Provider>
  );
};

export { InbStateContext, InbStateProvider };

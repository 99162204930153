import React from "react";
import {
  GetSvgBuilding,
  GetSvgPhone,
} from "../../../images/svglist";
import { Alert } from 'react-bootstrap'

const AddressBook = (props) => {
  const { addressBook, handelOnAddressSelected } = props;


  return (
    <div>
      {
        !!addressBook && addressBook.length > 0
          ? addressBook.map((addrs) => (
            <div className="bg-white shadow border border-2 rounded-2 p-2 mb-3 cursor-pointer"
              onClick={() => handelOnAddressSelected(addrs)}
            >
              <div className="fw-bold theme-blue-txt">{addrs.name}</div>
              <div className="my-2">
                <span className="theme-violet-txt">{GetSvgPhone(false)}</span>{" "}
                {addrs.phone}
              </div>
              <div>
                <span className="theme-violet-txt">{GetSvgBuilding(false)}</span>{" "}
                {addrs.address +
                  " , " +
                  addrs.city +
                  " , " +
                  addrs.state +
                  " , " +
                  addrs.postcode}{" "}
              </div>
            </div>
          ))
          : <Alert variant="warning">
            There are no saved address in your address book.
          </Alert>
      }


    </div >
  );
};

export default AddressBook;

import React, { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import DataTable from "react-data-table-component-with-filter";
import { invColumns } from "./InvoiceList";
import {
  DATE_TIME_FORMAT_MOMENT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import moment from "moment";

export default function InvoiceDetails(props) {
  const { customerDtls, fetchAllInvoiceList, unBilledInvArr, dnCnList,latestInvAmt,cnAmt,openCustCn,avlCreditLimit } = props;
  const [modalConfig, setModalConfig] = useState(null);
  const [showProf, setShowProf] = useState(false);
  // const [custDtls, setCustDtls] = useState([]);
  // useEffect(()=>{
  //   if(!!unBilledInvArr && unBilledInvArr.length>0)
  //       openModal();
  // },[unBilledInvArr])
  const viewAllCnAndDnList = (type) => {
    openModal(type);
  };
  const openModal = (type) => {
    if (type === "cd") {
      setShowProf(true);
    }
    setModalConfig({
      size: type !== "cd" ? "xl" : "lg",
      fullscreen: type !== "cd" ? true : false,
      show: true,
      title: type !== "cd" ? "Un-Billed Details" : "Credit Note / Debit Note",
    });
  };
  const closeModal = () => {
    setShowProf(false);
    setModalConfig({
      size: "xl",
      fullscreen: false,
      show: false,
      title: "Un-Billed Details",
    });
  };
  const cAndDColumns = [
    {
      name: "Transaction Date",
      selector: (row) => moment(row.trans_date).format(DATE_TIME_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Debit/Credit",
      selector: (row) =>
        row.trans_type === "DN" ? "Debit Note" : "Credit Note",
      sortable: true,
      filterable: true,
    },
    {
      name: "Amount",
      selector: (row) => +row.amount,
      sortable: true,
    },
    {
      name: "Remarks",
      selector: (row) => row.remark,
    },
  ];
  return (
    <>
      <div className="theme-blue border border-1 rounded-2 p-2 mt-4 ">
        {/* <div className="text-center f"><h3>Current Statement Amount</h3></div> */}
        <div className="row">
          <div className="col-md-3 my-2 ">
            <div className="fw-bold">Customer :</div>
            <div className="mt-1 font-18">
              {customerDtls.custName}({customerDtls.custAccount})
            </div>
          </div>
          {/* <div className="col my-2 ">
            <div className="fw-bold">Opening Balance : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custOpeningBls?.toFixed(2)}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Total Bill : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custTotalBill?.toFixed(2)}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Total Paid : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custTotalPaid?.toFixed(2)}
            </div>
          </div> */}
          <div className="col my-2">
            <div className="fw-bold">Latest Monthly Statement Amount : </div>
            <div className="mt-1 font-18">
             {!!latestInvAmt ? "RM "+ latestInvAmt?.toFixed(2): "N/A"}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Outstanding : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custOutStndBls?.toFixed(2)}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Available Credit Note:</div>
            <div
              className="mt-1 font-18"
              
            >
              RM{" "}
              
                <button className=" bg-white theme-violet-txt fw-bold rounded-pill" 
                onClick={openCustCn}
                >
                  {" "}
                  { !!avlCreditLimit?avlCreditLimit?.toFixed(2):"0.00"}
                </button>
              
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Un-Invoiced Bill:</div>
            <div
              className="mt-1 font-18"
              
            >
              RM{" "}
              {!!customerDtls.custUnBill && customerDtls.custUnBill !== 0 ? (
                <button className=" bg-white theme-violet-txt fw-bold rounded-pill" onClick={() => {
                  fetchAllInvoiceList(customerDtls.custId, "UBL").then((res) =>
                    openModal()
                  );
                }}>
                  {" "}
                  {customerDtls.custUnBill?.toFixed(2)}
                </button>
              ) : (
                "0.00"
              )}
            </div>
          </div>

          {/* <div className="col align-self-center">
            <button
              className="bg-white border border-0 px-3 py-2 fw-bold rounded-pill"
              onClick={() => viewAllCnAndDnList("cd")}
            >
              DN/CN Details
            </button>
          </div> */}
        </div>
      </div>

      {!!modalConfig && (
        <ModalLayout moadlConfig={modalConfig} handleMoalClose={closeModal}>
          {showProf === false ? (
            <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
              <DataTable
                columns={invColumns}
                data={unBilledInvArr}
                customStyles={customDataTableStyles}
                pagination
                striped
                highlightOnHover
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
              />
            </div>
          ) : (
            <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
              <DataTable
                columns={cAndDColumns}
                data={dnCnList}
                customStyles={customDataTableStyles}
                pagination
                striped
                highlightOnHover
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
              />
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Layout from "../../Layout/Layout";
import GetSvgIcon from "../../../images/svglist";
import CustomerOrderBillingDetails from "../../OrderManagement/CustomerOrderBillingDetails";
import { CallApiGet } from "../../Helper/serviceApi";
import { DownloadFile } from "../../Helper/FileStoreService";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initObj = {
  orderType: 0,
  bookedOn: "",
  shipmentDate: "",
  origin: "",
  originSbu: "",
  destSbu: "",
  destination: "",
  senderAddress: "",
  recipientAddress: "",
  valueAddedService: "",
  custname: "",
  attachments: [
    {
      fileName: "",
      docBase64: null,
      description: "",
      attachmentId: 0,
    },
  ],
  shipmentbilling: [
    {
      billId: 0,
      billNo: "",
      billDate: "",
      billDtlId: 0,
      invoiceId: null,
      taxAmount: 0,
      billAmount: 0,
      customerId: 0,
      particular: "",
      totalTaxAmount: 0,
      totalBillAmount: 0,
    },
  ],
};

const CharteredOrderDetails = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [chartOrdDtls, setChartOrdDtls] = useState(
    JSON.parse(JSON.stringify(initObj))
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [shipId, setShipId] = useState([]);
  const [bills, setBills] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [stops, setStops] = useState([]);

  useEffect(() => {
    const orderId = searchParams.get("data");
    setShipId(orderId);
    getOrdDtls("orders", { orderId: orderId });
  }, []);

  const getOrdDtls = async (type, argData) => {
    setSpinner(true);
    let data = await CallApiGet("GetOrderDetails?ordId=" + argData.orderId);
    if (data.respCode === 200) {
      setChartOrdDtls(data.respData[0]);
      setBills(JSON.parse(data.respData[0].billing_data));
      setStops(JSON.parse(data.respData[0].stops_data));
      let attachmentData = JSON.parse(data.respData[0].attachment_data);

      const groupByStopSeq = attachmentData.reduce((attchMap, attch) => {
        if (!!attch.att_id) {
          if (!attchMap[attch.stop_seq]) {
            attchMap[attch.stop_seq] = [];
          }
          attchMap[attch.stop_seq].push(attch);
        }

        return attchMap;
      }, {});
      const attachArr = Object.keys(groupByStopSeq)
        .sort()
        .map((stopSeq) => ({
          stopSeq,
          attchObj: groupByStopSeq[stopSeq],
        }));
      setAttachments([...attachArr]);
      setSpinner(false);
    } else {
      console.error();
      setSpinner(false);
    }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };
  const openAlert = (isSuccess, title, msg) => {
    setAlertConfig({
      isSuccess: isSuccess,
      size: "",
      show: true,
      title: title,
      description: msg,
    });
  };

  const onSuccess = (title, body) => {
    console.log("title", title, "body", body);
  };

  const onError = (errMsg) => {
    console.log("errMsg", errMsg);
  };

  const stopsView = () => {
    let stopBoxes = [];
    for (let i = 0; i < stops.length; i++) {
      let stopData = stops[i];
      let stopName = "Origin";
      if (stopData?.stop_seq === 99) stopName = "Destination";
      else if (stopData?.stop_seq > 0) stopName = `Stop ${stopData?.stop_seq}`;

      // Push each stop into stopBoxes as a column element
      stopBoxes.push(
        <div className="col-md-6 mb-3 mt-3" key={i}>
          <div className="grd-bg p-2 rounded-3 ms-2">
            <div className="fw-bold">{stopName} Address</div>
            <div className="theme-blue-txt">
              {stopData?.stop_address +
                ", " +
                stopData?.stop_city +
                ", " +
                stopData?.stop_state +
                ", Postcode - " +
                stopData?.stop_postcode +
                ", Latitude -" +
                stopData?.stop_lat +
                ", Longitude -" +
                stopData?.stop_long}
            </div>
          </div>
        </div>
      );
    }

    // Group every two stopBoxes into a row
    const rows = [];
    for (let i = 0; i < stopBoxes.length; i += 2) {
      rows.push(
        <div className="row w-100" key={`row-${i}`}>
          {stopBoxes[i]}
          {stopBoxes[i + 1]}
        </div>
      );
    }

    // Return the grouped rows
    return <>{rows}</>;
  };

  const RenderAttch = ({ attachments, DownloadFile }) => {
    const getLabel = (argStopSeq) => {
      switch (argStopSeq) {
        case 0:
          return "Origin";
        case 99:
          return "Destination";
        default:
          return `Stop-${argStopSeq}`;
      }
    };
    return (
      <Row className="mx-2">
        {!!attachments &&
          attachments.length > 0 &&
          attachments?.map((a, i) => (
            <Col md={6} className="grd-bg p-2 my-2 rounded-3 mb-1 mt-3">
              <>
                <span className="fw-bold">{getLabel(+a.stopSeq)}</span>
                {!!a.attchObj &&
                  a.attchObj
                    .sort((obj1, obj2) =>
                      obj1.att_name.localeCompare(obj2.att_name)
                    )
                    .map((o, i) => (
                      <div className="theme-blue-txt d-flex align-items-center justify-content-between">
                        <span className="fw-bold me-2">{o.att_name}</span>
                        <span className="theme-blue-txt me-2">{o.att_url}</span>
                        <button
                          type="button"
                          className="btn btn-outline theme-blue-txt bi bi-download ms-2"
                          onClick={() =>
                            DownloadFile(o.att_url, onSuccess, onError)
                          }
                        >
                          {GetSvgIcon("IconFiledownload")}
                        </button>
                      </div>
                    ))}
              </>
            </Col>
          ))}
      </Row>
    );
  };
  const attachmentView = () => {
    let attBoxes = [];
    let rowBoxes = [];

    for (let i in attachments) {
      let attData = attachments[i];
      let stopName = "Origin";
      if (+i === 99) stopName = "Destination";
      else if (+i > 0) stopName = `Stop${i}`;

      rowBoxes.push(
        <div className="col-md-6 p-2">
          <div className="grd-bg p-2 my-2 rounded-3">
            <div>
              <div className="fw-bold">{stopName}</div>
              <div className="theme-blue-txt d-flex align-items-center justify-content-between">
                <span className="theme-blue-txt me-2">
                  {attData["DO"].att_name}
                </span>
                <span className="me-auto">{attData["DO"].att_url}</span>
                <button
                  type="button"
                  className="btn btn-outline bi bi-download ms-2"
                  onClick={() =>
                    DownloadFile(attData["DO"].att_url, onSuccess, onError)
                  }
                >
                  {GetSvgIcon("IconFiledownload")}
                </button>
              </div>

              {+i === 0 && (
                <div className="theme-blue-txt d-flex align-items-center justify-content-between mt-1">
                  <span className="theme-blue-txt me-2">
                    {attData["POP"].att_name}
                  </span>
                  <span className="me-auto">{attData["POP"].att_url}</span>
                  <button
                    type="button"
                    className="btn btn-outline bi bi-download ms-2"
                    onClick={() =>
                      DownloadFile(attData["POP"].att_url, onSuccess, onError)
                    }
                  >
                    {GetSvgIcon("IconFiledownload")}
                  </button>
                </div>
              )}

              {+i > 0 &&
                attData["POD"].map((pod, index) => (
                  <div
                    key={index}
                    className="theme-blue-txt d-flex align-items-center justify-content-between mt-1"
                  >
                    <span className="theme-blue-txt me-2">{pod.att_name}</span>
                    <span className="me-auto">{pod.att_url}</span>
                    <button
                      type="button"
                      className="btn btn-outline bi bi-download ms-2"
                      onClick={() =>
                        DownloadFile(pod.att_url, onSuccess, onError)
                      }
                    >
                      {GetSvgIcon("IconFiledownload")}
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );

      // Add the row to the attBoxes array and reset rowBoxes if we have two columns
      if ((+i + 1) % 2 === 0 || +i === attachments.length - 1) {
        attBoxes.push(<div className="row">{rowBoxes}</div>);
        rowBoxes = [];
      }
    }

    return attBoxes;
  };

  const generateBillBtn = async () => {
    setSpinner(true);
    let data = await CallApiGet(
      "CreateSchCharteredBill/" + chartOrdDtls?.planId
    );
    if (data.respCode === 200 || data.respCode === 0) {
      window.location.reload();
    } else {
      handleApiResponse();
    }
    setSpinner(false);
  };

  const handleApiResponse = () => {
    openAlert(
      false,
      "ERROR",
      "Something went wrong. Please try after sometime!!"
    );
  };
  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="row mt-3">
          <div className="col-xxl-8 col-lg-9 col-md-12">
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Chartered Order Details
              </div>
              <div class="p-2">
                <div class="d-flex justify-content-between align-item-center">
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Order Number</div>
                    <div class="theme-blue-txt">{chartOrdDtls?.order_no}</div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Booking Date</div>
                    <div class="theme-blue-txt">
                      {/* {moment(chartOrdDtls?.booked_on).format(
                        DATE_TIME_FORMAT_MOMENT
                      )} */}
                      {chartOrdDtls?.booked_on}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Shipment Date</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.shipment_date}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Estimated Delivery Date</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.est_delivery}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-item-center">
                  {chartOrdDtls?.rate_type === 766 && (
                    <div class="col grd-bg p-2 p my-2 rounded-3">
                      <div class="fw-bold">Weight</div>
                      <div class="theme-blue-txt">
                        {chartOrdDtls?.order_weight} KG
                      </div>
                    </div>
                  )}
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Extra Manpower</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.order_manpower > 0
                        ? chartOrdDtls?.order_manpower
                        : "NA"}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Status</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.order_status}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Customer Details
              </div>
              <div class="p-2">
                <div class="d-flex justify-content-between align-item-center">
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Customer Name</div>
                    <div class="theme-blue-txt">{chartOrdDtls?.cust_name}</div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Company Name</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.business_name}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Account Number</div>
                    <div class="theme-blue-txt">{chartOrdDtls?.acct_code}</div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-item-center">
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Phone</div>
                    <div class="theme-blue-txt">{chartOrdDtls?.cust_phone}</div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Email</div>
                    <div class="theme-blue-txt">{chartOrdDtls?.cust_email}</div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Address</div>
                    <div class="theme-blue-txt">
                      {chartOrdDtls?.cust_addr +
                        ", " +
                        chartOrdDtls?.cust_city +
                        ", " +
                        chartOrdDtls?.cust_state +
                        ", Postcode - " +
                        chartOrdDtls?.post_code}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Stops
              </div>
              {stopsView()}
            </div>
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Order Attachment
              </div>

              {/* {attachmentView()} */}
              <RenderAttch
                attachments={attachments}
                DownloadFile={DownloadFile}
              />
            </div>
          </div>
          <div className="col-xxl-4 col-lg-3 col-md-12">
            {bills.length > 0 && bills.some((bill) => !!bill.billId) ? (
              <CustomerOrderBillingDetails billingDetails={bills} />
            ) : (
              <>
                <div class="col grd-bg p-2 p my-2 rounded-3">
                  <span className="theme-violet-txt fw-bold ms-4">
                    The Bill is not yet generated!!!{" "}
                  </span>{" "}
                  {chartOrdDtls?.statusId === 79 && (
                    <>
                      <div className="row">
                        <div className="col  text-end">
                          <Button
                            variant="outline-primary"
                            className="mt-2"
                            onClick={() => generateBillBtn()}
                          >
                            Generate Now
                          </Button>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </>
                  )}{" "}
                </div>
              </>
            )}

            {/* {bills.length > 0 && bills.some((bill) => !!bill.billId) && ( */}
            {/* <CustomerOrderBillingDetails billingDetails={bills} /> */}
            {/* )} */}
          </div>
        </div>
        <Header />
      </Layout>
    </>
  );
};

export default CharteredOrderDetails;

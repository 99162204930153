import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  ALPHANUM_NAME,
  BLACK_SPCL_CHARS,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import moment from "moment";
import { CallApiPost } from "../../Helper/serviceApi";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { DownloadFile } from "../../Helper/FileStoreService";
import {
  downloadFiles,
  getRandomInteger,
  toBase64,
} from "../../Helper/CommonMethods";

const EMPTY_DATA = [null, undefined, ""];

export const AdvertisementAddEdit = ({
  config,
  handleClose,
  handleApiResponse,
  advState,
  handleFileAttach,
  data,
}) => {
  const {
    register,
    watch,
    control,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({ defaultValues: advState });
  const watchValidFrom = watch("validFrom");
  const watchValidTo = watch("validTo");
  const watchAttachment = watch("attachment");

  useEffect(() => {
    reset(advState);
  }, [advState]);

  const onSubmit = async (data) => {
    if (!valiadteFile(data)) {
      return;
    }
    const resp = await CallApiPost("SaveAndUpdateAdvertisment", [data]);
    handleClose();
    handleApiResponse(resp);
  };
  const valiadteFile = (data) => {
    let isFileValid = true;
    if (
      EMPTY_DATA.includes(data?.attachment?.photo) &&
      EMPTY_DATA.includes(data?.attachment?.photoBucketUrl)
    ) {
      setError("attachment", {
        type: "file",
        message: "This field is required!!",
      });
      isFileValid = false;
    }
    return isFileValid;
  };

  const onError = (err) => {
    console.log(err);
  };

  const handleAttch = async (event) => {
    clearErrors("attachment");
    const file = event.target.files;
    const photo = await toBase64(file[0]);
    const argFileName = file[0].name;
    var ext = argFileName.substring(argFileName.lastIndexOf(".") + 1);
    const fileName =
      "ADV_" +
      getRandomInteger(1, 1000) +
      "_" +
      moment().format("YYYYMMDD") +
      moment().milliseconds() +
      "." +
      ext;

    if (!!photo) {
      let attchObj = {};
      attchObj.attchId = 0;
      attchObj.photo = photo;
      attchObj.photoFileName = fileName;
      attchObj.photoFileType = 0;
      attchObj.photoBucketUrl = null;

      setValue("attachment", attchObj);
    }
  };

  return (
    <Modal show={config?.show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="section-title">{config?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Valid From{""} <span className="text-danger">&#9733;</span>
              </label>
              <div>
                <Controller
                  name="validFrom"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Valid From"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={new Date()}
                      maxDate={
                        !!watchValidTo ? new Date(watchValidTo) : watchValidTo
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format(DATE_FORMAT_SERVER) : d)
                      }
                    />
                  )}
                />
                {errors?.validFrom && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.validFrom?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Valid To{""} <span className="text-danger">&#9733;</span>
              </label>
              <div>
                <Controller
                  name="validTo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Valid To"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={
                        !!watchValidFrom ? new Date(watchValidFrom) : new Date()
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format("YYYY-MM-DD") : d)
                      }
                    />
                  )}
                />
                {errors?.validTo && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.validTo?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="form-label theme-label">
                Title <span className="text-danger">&#9733;</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("title", {
                  pattern: {
                    validate: (v) => (BLACK_SPCL_CHARS.test(v) ? false : true),
                  },
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              {errors?.title && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.title?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col md={6}>
              <label className="form-label theme-label">
                Description <span className="text-danger">&#9733;</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("description", {
                  pattern: {
                    validate: (v) => (BLACK_SPCL_CHARS.test(v) ? false : true),
                  },
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              {errors?.description && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.description?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="form-label theme-label">
                Attachment <span className="text-danger">&#9733;</span>
              </label>
              <div className="input-group">
                <label className="form-control attachment-label">
                  <span className=" attachment-label-no-ellipsis">
                    {!!watchAttachment?.photoFileName
                      ? watchAttachment?.photoFileName.split("/").pop()
                      : "Choose a file..."}
                  </span>
                  <input
                    type="file"
                    className="UploadFilesty "
                    aria-describedby="button-addon2"
                    accept="application/pdf, image/jpeg, image/jpg"
                    id={`attachment`}
                    onChange={(e) => handleAttch(e)}
                  />
                </label>
                <Button
                  className="btn btn-outline-secondary theme-violet"
                  disabled={
                    typeof watchAttachment?.photoBucketUrl === "string" &&
                    watchAttachment?.photoBucketUrl.trim().length > 0
                      ? false
                      : true
                  }
                >
                  <a
                    href={
                      !!watchAttachment?.photoBucketUrl
                        ? watchAttachment?.photoBucketUrl
                        : ""
                    }
                    className="text-warning"
                  >
                    {GetSvgIcon("IconFiledownload")}
                  </a>
                </Button>
              </div>
              {errors?.attachment && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.attachment?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
        <Button
          variant="primary"
          className="theme-violet"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import BillingMouseOver from "./BillingMouseOver";
import DataTable from "react-data-table-component-with-filter";
import { CallApiGet } from "../Helper/serviceApi";
import { DATE_FORMAT_MOMENT, customDataTableStyles } from "../Helper/Constants";
import moment from "moment";

export default function BillingDetails(props) {
  const { invoiceDetails, setModalData } = props;
  const [paymentDtlsList, setPaymentDtlsList] = useState([]);
  const overlayDivRef = useRef();
  const [breakupData, setBreakupData] = useState([]);
  const [target, setTarget] = useState();

  const columns = [
    {
      name: "Payment Date",
      selector: (row) => moment(row.paymentDate).format(DATE_FORMAT_MOMENT),
      filterable: true,
    },
    {
      name: "Transaction Ref. No.",
      selector: (row) => row.transactionRef,
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row) => row.paymentMode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.paymentAmount,
      sortable: true,
    },
    {
      name: "Applied Amount",
      cell: (row) => (
        <div
          ref={overlayDivRef}
          onMouseEnter={(evnt) =>
            handleMouseEnter(evnt, row.invoiceDistributionList)
          }
          onMouseLeave={(evnt) =>
            handleMouseLeave(evnt, row.invoiceDistributionList)
          }
          className="popover-cut"
        >
          <BillingMouseOver
            popHeader="Details"
            target={target}
            appliedAmount={row.appliedAmount}
            data={breakupData}
            reference={overlayDivRef}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Remaining Amount",
      selector: (row) => row.remainingAmount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.paymentStatus,
      sortable: true,
      filterable: true,
    },
  ];
  useEffect(() => {
     getPaymentDetails(invoiceDetails.invoiceId);
    //getPaymentDetails(203);
  }, [invoiceDetails]);

  const getPaymentDetails = async (invoiceId) => {
    let data = await CallApiGet("getPaymentDetails/" + invoiceId);
    if (data.respCode === 200) {
      setPaymentDtlsList(data.respData);
    }
  };
  const handleMouseEnter = (evnt, data) => {
    setTarget(evnt.target);
    setBreakupData(data);
  };
  const handleMouseLeave = (evnt, data) => {
    setBreakupData([]);
    setTarget(evnt.target);
  };
  return (
    <div className="col-md-12  popup-data-table">
      <DataTable
        columns={columns}
        data={paymentDtlsList}
        customStyles={customDataTableStyles}
        pagination
        striped
        highlightOnHover
        defaultSortFieldId={1}
        defaultSortAsc={false}
      />
    </div>
  );
}

import React from 'react'
import './CustomPopup.css'

const CustomPopup = ({ icon, body, callback }) => {
    return (
        <div className="popup-box">

            <div className="box">
                <div className="modal-content popup-modal">
                    {icon}
                    <div className="content-body">
                        <p>{body}</p>
                    </div>
                    <div className="modal-footer footer-style">
                        <button
                            type="button"
                            className="btn col-md-2 m-auto mt-3 theme-violet"
                            data-bs-dismiss="modal"
                            onClick={callback}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomPopup
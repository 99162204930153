import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import { DELIVERY_SERVICE_ID, PICKUP_SERVICE_ID } from "../../../ConfigConstants/ConfigConstants";
import moment from "moment";
import { toBase64 } from "../../Helper/CommonMethods";

const BookingInsurance = (props) => {
  const {
    insuranceData,
    selectedInsurance,
    handelInsuranceSelect,
    filterInsurance,
    avalServices,
    register,
    setValue,
    watch,
    errors,
    clearErrors
  } = props;

  const [services, setServices] = useState({ isPickup: false, isDelivery: false })
  const watchInsUpload = watch("insAttch")

  useEffect(() => {
    const selectedServices = {
      isPickup: avalServices?.find(srvc => srvc.value === PICKUP_SERVICE_ID)?.isChecked,
      isDelivery: avalServices?.find(srvc => srvc.value === DELIVERY_SERVICE_ID)?.isChecked
    }

    setServices(selectedServices)
  }, [avalServices])

  const handelFileSelect = async (event, key, fileKey, attType) => {
    const file = event.target.files
    const argFileName = file[0].name
    var ext = argFileName.substr(argFileName.lastIndexOf(".") + 1);
    const fileName = fileKey + moment().format("YYYYMMDD") + moment().milliseconds() + "." + ext
    const base64File = await toBase64(file[0])

    const argInsAtt = {
      fileName: fileName,
      docBase64: base64File,
      attType: attType
    }
    setValue(key, argInsAtt)
    clearErrors(key)

  }

  const handelSpecialPermissionChange = (event, key) => {
    if (!event.target.checked) {
      setValue(key, null)
    }
  }

  return (
    <>
      <div className="w-100 d-block">
        <div className="bg-white border border-2 rounded-2 my-3">
          <div className="col-lg-12">
            <div className="subheadingtext p-3">Choose Insurance</div>
            <div className=" popup-data-table">
              <Table striped>
                <thead>
                  <tr>
                    <th></th>
                    <th>Package Id</th>
                    <th className="text-center">Coverage Upto (RM)</th>
                    <th className="text-center">Protection Fee (RM)</th>
                  </tr>
                </thead>
                <tbody>
                  {insuranceData?.length > 0 &&
                    insuranceData?.map((item) => (
                      <tr>
                        <td className="pt-1">
                          <input
                            className="ms-3"
                            type="checkbox"
                            checked={selectedInsurance === item.insuranceId}
                            onChange={(e) => (
                              handelInsuranceSelect(
                                e.target.checked,
                                item.insuranceId
                              ),
                              filterInsurance(
                                e.target.checked,
                                item.insuranceId
                              )
                            )}
                          />
                        </td>
                        <td>
                          <>
                            <div>
                              {item.packageId}{" "}
                              <span
                                data-tooltip-id={"ins-" + item.packageId}
                                className="ms-1"
                              >
                                {GetSvgIcon("ToolTip")}
                              </span>
                            </div>
                            <Tooltip
                              style={{
                                background: " #483f3f",
                                color: "aliceblue",
                              }}
                              id={"ins-" + item.packageId}
                              content={"Package Name : " + item.packageName}
                              place="right"
                            />
                          </>
                        </td>
                        <td className="text-center">{item.coverage}</td>
                        <td className="text-center">{item.price}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {
                !!selectedInsurance &&
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-5 my-2">
                      <div>
                        <label className="form-label theme-label" htmlFor="">
                          Please upload the invoice/receipt for the insured item <span className="text-danger">&#9733;</span>
                        </label>
                      </div>
                      <div className="Upload-Photo w-100">
                        <input
                          type="file"
                          accept="application/pdf, image/jpeg, image/jpg"
                          id="attachmentFile"
                          onChange={(e) => handelFileSelect(e, "insAttch", "INS_DOC_", "INS")}
                        />
                        <div className="uploaded-text ms-2 w-100">
                          {watchInsUpload?.fileName}{GetSvgUpload(true)}
                        </div>
                      </div>
                      {errors.insAttch && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.insAttch.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-4 my-2">
                      <label className="form-label theme-label" htmlFor="">
                        Declare the Price <span className="text-danger">&#9733;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("insDeclaredPrice")}
                      />
                      {errors.insDeclaredPrice && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.insDeclaredPrice.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              }

            </div>
          </div>
        </div>
      </div>
      {/* put file upload here */}

      {(services?.isPickup || services?.isDelivery) && (
        <div>
          <div className="w-100 d-block">
            <div className="bg-white border border-2 rounded-2 my-3">
              <div className="col-lg-12">
                <div className="subheadingtext p-3">Sepcial permission</div>
                {services?.isPickup && (
                  <div className="row">
                    <div className="col">
                      <div className="col align-self-center mx-3 my-2 ">
                        <div className="input-group position-relative">
                          <div className="input-group-text py-3">
                            <input
                              className="form-check-input formsty"
                              type="checkbox"
                              name=""
                              id=""
                              value="specialPickup"
                              // value="option1"
                              {...register("specialPickup", { onChange: (e) => handelSpecialPermissionChange(e, "pickupInstUpload") })}
                            // disabled={!avalServices?.pickup}
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value="Does the driver need special permission for Pickup?"
                            disabled
                          />
                          {
                            watch("specialPickup") &&
                            <div className="Upload-Photo right-top position-absolute">
                              <input
                                type="file"
                                accept="application/pdf, image/jpeg, image/jpg"
                                id="attachmentFile"
                                onChange={(e) => handelFileSelect(e, "pickupInstUpload", "PIC_INST_DOC_", "PIC")}
                              />
                              <div className="uploaded-text ms-2 w-100">
                                {!!watch("pickupInstUpload") ? watch("pickupInstUpload")?.fileName : "Upload Doc "}{GetSvgUpload(true)}
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {services?.isDelivery && (
                  <div className="row">
                    <div className="col">
                      <div className="col align-self-center mx-3 my-2 ">
                        <div className="input-group position-relative">
                          <div className="input-group-text py-3">
                            <input
                              className="form-check-input formsty"
                              type="checkbox"
                              name=""
                              id=""
                              value="specialDelivery"
                              // value="option1"
                              {...register("specialDelivery", { onChange: (e) => handelSpecialPermissionChange(e, "dlvInstUpload") })}
                            // disabled={!avalServices?.pickup}
                            />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value="Does the driver need special permission for Delivery?"
                            disabled
                          />
                          {
                            watch("specialDelivery") &&
                            <div className="Upload-Photo right-top position-absolute">
                              <input
                                type="file"
                                accept="application/pdf, image/jpeg, image/jpg"
                                id="attachmentFile"
                                onChange={(e) => handelFileSelect(e, "dlvInstUpload", "DLV_INST_DOC_", "DLV")}
                              />
                              <div className="uploaded-text ms-2 w-100">
                                {!!watch("dlvInstUpload") ? watch("dlvInstUpload")?.fileName : "Upload Doc "}{GetSvgUpload(true)}
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* uploads ends here */}
    </>
  );
};

export default BookingInsurance;

import React from "react";
import GetSvgIcon from "../../../images/svglist";

const ProgressBarGraph = (props) => {
    return (
        <>
            <h4 className="small font-weight-bold">
                {props?.data?.label}
                <span className="float-end">{GetSvgIcon('Currencydashboard')}{" "}{" "}{props?.data?.amount.toFixed(2)}RM</span>
            </h4>
            <div className="progress mb-4">
                <div className={"progress-bar bg-" + props?.data?.color} role="progressbar" style={{ width: props?.data?.percent + "%" }} aria-valuenow={props?.data?.percent} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </>
    )
}

export default ProgressBarGraph;
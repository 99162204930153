import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import GetSvgIcon from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  readLocalStorage,
  getKiplForm,
  fetchAddressBook,
  writeLocalStorage,
} from "../../Helper/CommonMethods";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  COUNTRY_CODES,
  EMPTY_PALLETS,
  INIT_FROM_SRVC,
  INIT_TO_SRVC,
  NUMBER_REGEX,
  NUMBER_TWO_DECIMAL,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";

import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Spinner from "../../Layout/Spinner";
import Popup from "../../Layout/Popup";
import CustomPopup from "../../Layout/CustomPopup/CustomPopup";
import { Card } from "react-bootstrap";
import OrderSummary from "./OrderSummary";
import ModalLayout from "../../Layout/ModalLayout";
import AddressBook from "./AddressBook";
import ModalAlert from "../../Layout/ModalAlert";
import ProtectionConformAlert from "./ProtectionConformAlert";
import BookingAddress from "./BookingAddress";
import BookingInsurance from "./BookingInsurance";
import BookingSapceService from "./BookingSapceService";
import FareSummaryComponent from "./FareSummaryComponent";
import BookingSearchComponent, { options } from "./BookingSearchComponent";
import {
  DELIVERY_SERVICE_ID,
  HANDLING_SERVICE_ID,
  PACKAGING_SERVICE_ID,
  PICKUP_SERVICE_ID,
  LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL,
  LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS,
  LINEHAUL_STAFF_ISR_USER_TYPE,
  BOOKING_PAYMENT_TYPE_CASH,
  BOOKING_PAYMENT_TYPE_CREDIT,
  BOOKING_PAYMENT_TYPE_CASHADV,
  STANDARD_BOOKING_PACKING_TYPE,
  LINEHAUL_CUSTOMER_BUSINESS_PARTNERS,
} from "../../../ConfigConstants/ConfigConstants";
import BookingPromoCodes from "./BookingPromoCodes";
import ModalConfirmAlertCustom from "../../Layout/ModalConfirmAlertCustom";

/**
 * totalPalletArea: Refers to No. of Packages field in UI present in BookingSpaceService Component. This field value needs to be passed on as pallets.
 * pallets: Refers to the Pallet Space Field in the BookingSearchComponent. This field value needs to be passed on as palletSpaceRequired.
 */
const initialFormData = {
  isInternational: false,
  shipmentDate: "",
  slaId: 0,
  destination: 0,
  origin: 0,
  totalWeight: 0,
  pallets: 0,
  userId: 0,
  customerId: 0,
  orderType: 1,
  paymentBy: "CREDIT",
  daysToDeliver: 0,
  shipmentVas: [],
  shipmentPallets: [],
  totalPalletArea: "",
};
const intitCities = [
  {
    value: "",
    label: "",
  },
];

// const options = [
//   { label: "Pallet", value: "PLT" },
//   { label: "Motor Bike", value: "MBK" },
//   // { label: "Full Truck", value: "FTL" },
// ];

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

export const P_HDL = "p_handling";
export const D_HDL = "d_handling";
export const PKG = "packaging";

const StndBooking = () => {
  const [initialBookingData, setInitialBookingData] = useState();
  const [avalServices, setAvalServices] = useState();
  const [bookingCost, setBookingCost] = useState(0);
  const [pickupCost, setPickupCost] = useState(0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [SurchargeAmount, setSurchargeAmount] = useState(0);
  const [packagingCost, setPackagingCost] = useState(0);
  const [promoAmount, setPromoAmount] = useState(0);
  const [handlingAmount, setHandlingAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [shipmentBilling, setShipmentBilling] = useState();
  const [show, setShow] = useState(false);
  const [stateCity, setStateCity] = useState();
  const [allCites, setAllCites] = useState([]);
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [senderCityData, setSenderCityData] = useState(intitCities);
  // const [pickupCityData, setPickupCityData] = useState(intitCities);
  // const [deliveryCityData, setDeliveryCityData] = useState(intitCities);
  const [recipientCityData, setRecipientCityData] = useState(intitCities);
  const [custType, setCustType] = useState();
  const [availableCredits, setAvailableCredits] = useState();
  const [creditAvl, setCreditAvl] = useState(true);
  const [acceptedSize, setAcceptedSize] = useState([]);
  const [insuranceData, setInsuranceData] = useState();
  const [selectedInsurance, setSelectedInsurance] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [validSpecContent, setValidSpecContent] = useState(true);
  const sepDescription = "Special content more than available palette";
  const [title, setTitle] = useState();
  const [activeBlock, setActiveBlock] = useState(1);
  const [modalConfig, setModalConfig] = useState(null);
  const [promoModalConfig, setPromoModalConfig] = useState(null);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [addressBook, setAddressBook] = useState([]);
  const [selectedAddrType, setSelectedAddrType] = useState();
  const [senderAddressCalled, setSenderAddressCalled] = useState(false);
  const [isSateAvl, setIsSateAvl] = useState(false);
  const [isAddrsBookAvl, setIsAddrsBookAvl] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(initAlertConfig);
  const [selectedInsData, setSelectedInsData] = useState();
  const [calcReturnDatas, setCalcReturnDatas] = useState();
  const [iscalculatedOnce, setiscalculatedOnce] = useState(false);
  const [cacheBillId, setCacheBillId] = useState();
  const [searchParams] = useSearchParams();
  const [orgDestAddrs, setOrgDestAddrs] = useState();
  const [customerId, setCustomerId] = useState();
  const [canBook, setCanBook] = useState(true);
  const [appCreditLimit, setAppCreditLimit] = useState(0);
  const navigate = useNavigate();
  const modalRef = useRef();
  // let vasArray = [];
  const [applyCredit, setApplyCredit] = useState(false);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [sstPercent, setSstPercent] = useState();
  const [creditNote, setCreditNote] = useState(0);
  const [creditDisp, setCreditDisp] = useState(0);
  const [debitNote, setDebitNote] = useState(0);
  const [debitNoteToPay, setDebitNoteToPay] = useState(0);
  const [dnAlertConfig, setDnAlertConfig] = useState(initAlertConfig);
  const [lastDnDate, setLastDnDate] = useState();
  const [toDnDate,setToDnDate] = useState()
  const [fromDnDate,setFromDnDate] = useState()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: initialFormData });

  const watchPicHandling = watch("P_HDL");
  const watchDelHandling = watch("D_HDL");
  const watchPackaging = watch("PKG");

  useEffect(() => {
    const cId = !!readLocalStorage("customerId")
      ? parseInt(readLocalStorage("customerId"))
      : 0;
    setCustomerId(cId);
    initializeBooking();
    getAddressBookData();
  }, []);

  /**
   * This function retrieves and sets various data for an initial booking process, including SLA data,
   * initial booking data, and available services.
   */
  const initializeBooking = async () => {
    setShow(true);

    // === Update Customer and User ID
    setValue("userId", parseInt(getUserInfo("userId")));
    setValue(
      "customerId",
      !!readLocalStorage("customerId")
        ? parseInt(readLocalStorage("customerId"))
        : 0
    );

    let slaData = JSON.parse(readLocalStorage("BookingSla"));
    let currBkngData = JSON.parse(readLocalStorage("initialBookingData"));

    setCustType(getUserInfo("customerType"));
    setValue("isPromoApplied", false);
    // await handleOnSearch(currBkngData, slaData);
    console.log(currBkngData);
    let type = searchParams.get("type");
    if (type === "STB") {
      // Repeat Booking
      repeatBooking(currBkngData);
    } else if (type === "PRB") {
      // Pre-Booking
    } else if (type === "ISR") {
      // ISR booking for customer
      setValue("option", "PLT");
    }
    await handleOnSearch(currBkngData, slaData);
    setInitialBookingData(currBkngData);
    setShow(false);
    clearStorages();
  };

  const handleOnSearch = async (currBkngData, slaData) => {
    await setUpOrgDest(currBkngData?.sbuFrom, currBkngData?.sbuTo);
    await setUpSbuServices(currBkngData);
    await setUpInsurance(currBkngData);
    // await setUpPalletSize()

    // let range = !!currBkngData?.pallets ? [...Array(parseInt(currBkngData?.pallets)).keys()] : [];
    // setPalletRange(range);

    setValue("option", currBkngData?.option);
    setValue("serviceCode", currBkngData?.serviceCode);
    setValue("origin", currBkngData?.origin);
    setValue("destination", currBkngData?.destination);
    setValue("sbuFrom", currBkngData?.sbuFrom);
    setValue("fromSbuCode", currBkngData?.fromSbuCode);
    setValue("sbuTo", currBkngData?.sbuTo);
    setValue("totalWeight", 0);
    setValue(
      "pallets",
      !!currBkngData?.pallets ? parseInt(currBkngData?.pallets) : null
    );
    setValue("bkngService", currBkngData?.bkngService);
    setValue("isPickUp", currBkngData?.isPickUp);
    setValue("isDelivery", currBkngData?.isDelivery);
    setValue("packageDesc", currBkngData?.packageDesc);

    setValue("userId", parseInt(getUserInfo("userId")));

    setValue(
      "customerId",
      !!currBkngData?.customerId
        ? currBkngData?.customerId
        : !!readLocalStorage("customerId")
        ? parseInt(readLocalStorage("customerId"))
        : 0
    );
    setValue("daysToDeliver", slaData?.daysToDeliver);
    setValue(
      "totalPalletArea",
      !!currBkngData?.pallets ? parseInt(currBkngData?.pallets) : null
    );
    setValue("routingCode", slaData?.routingCode);

    if (
      !!currBkngData?.pallets &&
      parseInt(currBkngData?.pallets) <= slaData?.freeSpace
    ) {
      setValue("shipmentDate", slaData?.shipOn);
      setValue("estDeliveryDate", slaData?.deliveryOn);
    }

    setUpPickup(currBkngData?.fromAdrData);
    setUpDelivery(currBkngData?.toAdrData);
    const destService = currBkngData?.toService;
    if (
      destService?.find((srvc) => srvc?.value === DELIVERY_SERVICE_ID)
        ?.isChecked
    ) {
      const curr = currBkngData?.toAdrData;
      setUpRecipientAddr(
        null,
        null,
        curr?.address,
        curr?.state,
        curr?.city,
        curr?.postcode
      );
    }
    if (!!currBkngData?.fromService && !!currBkngData?.toService) {
      setAvalServices([
        ...currBkngData?.fromService,
        ...currBkngData?.toService,
      ]);
      setUpShipmentVas([
        ...currBkngData?.fromService,
        ...currBkngData?.toService,
      ]);
    } else {
      setAvalServices([...INIT_FROM_SRVC, ...INIT_TO_SRVC]);
      setUpShipmentVas([...INIT_FROM_SRVC, ...INIT_TO_SRVC]);
    }
  };

  const setUpShipmentVas = (argAvlSrvc) => {
    let vasArray = !!getValues("vasArray") ? getValues("vasArray") : [];
    if (
      argAvlSrvc?.find(
        (obj) => obj.value === PICKUP_SERVICE_ID && obj.isChecked
      )
    ) {
      let objp = {
        serviceId: PICKUP_SERVICE_ID,
        pallets: parseInt(getValues("pallets")),
      };
      const pickupIndex = vasArray?.findIndex(
        (vas) => vas.serviceId === PICKUP_SERVICE_ID
      );
      if (pickupIndex !== -1) {
        vasArray?.splice(pickupIndex, 1);
      }
      vasArray?.push(objp);
    } else {
      const indexToRemove = vasArray?.findIndex(
        (vas) => vas.serviceId === PICKUP_SERVICE_ID
      );
      if (indexToRemove !== -1) {
        vasArray?.splice(indexToRemove, 1);
      }
    }
    if (
      argAvlSrvc?.find(
        (obj) => obj.value === DELIVERY_SERVICE_ID && obj.isChecked
      )
    ) {
      let objd = {
        serviceId: DELIVERY_SERVICE_ID,
        pallets: parseInt(getValues("pallets")),
      };
      const dlvIndex = vasArray?.findIndex(
        (vas) => vas.serviceId === DELIVERY_SERVICE_ID
      );
      if (dlvIndex !== -1) {
        vasArray.splice(dlvIndex, 1);
      }
      vasArray?.push(objd);
    } else {
      const indexToRemove = vasArray?.findIndex(
        (vas) => vas.serviceId === DELIVERY_SERVICE_ID
      );
      if (indexToRemove !== -1) {
        vasArray?.splice(indexToRemove, 1);
      }
    }
    setValue("vasArray", vasArray);
  };

  const setUpOrgDest = async (from, to) => {
    const addressDataResp = await CallApiGet(
      "get-org-dest-address?originId=" + from + "&destId=" + to
    );
    if (addressDataResp.respCode === 200) {
      setOrgDestAddrs(addressDataResp.respData[0]);
    }
  };

  const setUpSbuServices = async (argData) => {
    let sbuServices = await CallApiGet(
      "get-hub-services?originId=" +
        argData?.sbuFrom +
        "&destinationId=" +
        argData?.sbuTo
    );
    const srvcData = sbuServices.respData;
    setValue(D_HDL, !!srvcData.D_HDL ? sbuServices.respData?.D_HDL : "false");
    setValue(P_HDL, !!srvcData.P_HDL ? sbuServices.respData?.P_HDL : "false");
    setValue(PKG, !!srvcData?.PKG ? srvcData?.PKG : "false");
    if (!!argData?.picHandlingPallets) {
      setValue("P_HDL", true);
      setValue("picHandlingPallets", argData?.picHandlingPallets);
    }
    if (!!argData?.delHandlingPallets) {
      setValue("D_HDL", true);
      setValue("delHandlingPallets", argData?.delHandlingPallets);
    }
    if (!!argData?.packagingPallets) {
      setValue("PKG", true);
      setValue("packagingPallets", argData?.packagingPallets);
    }
  };

  // const setUpPalletSize = async () => {
  //   let palletSize = await CallApiGet("get-accsepted-pallet-size");

  //   if (palletSize.respCode === 200) {
  //     setAcceptedSize(palletSize.respData);
  //   }
  // }

  const setUpBookingConfigData = async (argSrvcCode) => {
    setShow(true);
    const payload = { srvcCode: argSrvcCode };
    const apiResp = await CallApiPost("GetBookConfigData", payload);

    if (apiResp.respCode === 200 && !!apiResp.respData) {
      const data = apiResp?.respData;
      setValue("palletL", data?.palletL);
      setValue("palletW", data?.palletW);
      setValue("palletH", data?.palletH);
      setValue("maxWgtPerPallet", data?.maxWgtPerPallet);
      setValue("freeStrgDays", data?.freeStrgDays);
      setValue("lodgeIn", data?.lodgeIn);
      setValue("minDaysForDisc", data?.minDaysForDisc);
      setValue("minPltsForDisc", data?.minPltsForDisc);
    }
    setShow(false);
  };

  const setUpInsurance = async (argData) => {
    let insDataResp = await CallApiGet("getInsuranceData");
    if (insDataResp.respCode === 200) {
      setInsuranceData(insDataResp.respData.insuranceData);

      let insData = insDataResp.respData.insuranceData.filter(
        (ins) => ins.insuranceId === argData?.insurancePkgId
      );
      if (insData.length > 0) {
        setSelectedInsData(insData);
        setSelectedInsurance(insData[0].insuranceId);
        setValue("selectedInsurace", insData);
      } else {
        setSelectedInsData();
        setValue("selectedInsurace", "");
      }
    }
  };

  const setUpPickup = (pickupData) => {
    setValue("pickupName", !!pickupData?.name ? pickupData?.name : null);
    setValue(
      "pickupPhoneCode",
      !!pickupData?.phone ? pickupData?.phone.substr(0, 3) : "+60"
    );
    setValue(
      "pickupPhone",
      !!pickupData?.phone ? pickupData?.phone.substr(3) : null
    );
    setValue("pickupAddress", pickupData?.address);
    setValue("pickupState", pickupData?.state);
    setValue("pickupCity", pickupData?.city);
    setValue("pickupPostcode", pickupData?.postcode);
  };

  const setUpDelivery = (deliveryData) => {
    setValue("deliveryName", !!deliveryData?.name ? deliveryData?.name : null);
    setValue(
      "deliveryPhoneCode",
      !!deliveryData?.phone ? deliveryData?.phone.substr(0, 3) : "+60"
    );
    setValue(
      "deliveryPhone",
      !!deliveryData?.phone ? deliveryData?.phone.substr(3) : null
    );
    setValue("deliveryAddress", deliveryData?.address);
    setValue("deliveryState", deliveryData?.state);
    setValue("deliveryCity", deliveryData?.city);
    setValue("deliveryPostcode", deliveryData?.postcode);
  };

  const setUpRecipientAddr = (name, phone, addr, state, city, postcode) => {
    setValue("recipientName", name);
    setValue("recipientPhoneCode", !!phone ? phone.substr(0, 3) : "+60");
    setValue("recipientPhone", phone?.substr(3));
    setValue("recipientAddress", addr);
    setValue("recipientState", state);
    setValue("recipientCity", city);
    setValue("recipientPostcode", postcode);
  };

  const repeatBooking = (argData) => {
    setAddressData(argData?.senderAddress, "sender");
    if (!argData?.isDelivery) {
      setAddressData(argData?.recipientAddress, "recipient");
    }
    setValue("totalPalletArea", parseInt(argData?.pallets));
    setValue("dor", argData?.doReturn);
  };

  /**
   * This function calculates the cost of a shipment and sets various cost-related values based on the
   * input data.
   * @returns a boolean value indicating whether the calculation was successful or not.
   */
  const calculateBooking = async (isSearch) => {
    setShow(true);
    let calculated = false;
    let calcReqData = getCalculateReqData(isSearch);

    setShow(true);
    let calDatas = await CallApiPost("CalculateShipmentBill", calcReqData);
    setShow(false);
    if (calDatas.respCode === 200) {
      setShipmentBilling(calDatas.respData);
      checkPickupDeliveryAvailability(calDatas.respData);
      let tax = 0;
      let cost = 0;
      updateCalcReturnDatas(calDatas.respData);

      let handlingCost = 0;
      calDatas.respData.shipmentBilling.forEach((calData) => {
        switch (calData?.particular) {
          case "Shipping Bill":
            setBookingCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Pickup":
            setPickupCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Delivery":
            setDeliveryCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Handling":
            handlingCost += calData.billAmount;
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Packaging":
            setPackagingCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Insurance":
            setInsuranceCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          // case "Promotion":
          //   setPromoAmount(calData.billAmount.toFixed(2));
          //   tax = tax + calData.taxAmount;
          //   cost = cost + calData.billAmount;
          //   break;
          case "Surcharge":
            setSurchargeAmount(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Discount":
            setDiscountAmount(calData.billAmount.toFixed(2));
            cost = cost + calData.billAmount;
            break;
          case "SST Tax":
            tax = tax + calData.taxAmount;
            setSstPercent(calData.svcInfo);
            // cost = cost + calData.billAmount;
            break;

          default:
            break;
        }
      });
      setHandlingAmount(handlingCost.toFixed(2));
      setTaxAmount(tax.toFixed(2));

      let avlCreditAmount = calDatas.respData.creditLimit;
      let approvedCredit = calDatas.respData.approvedCreditLimit;
      let payType;
      setTotalCost((cost + tax).toFixed(2));
      setAppCreditLimit(approvedCredit);

      if (LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType)) {
        if (cost + tax <= avlCreditAmount)
          payType = BOOKING_PAYMENT_TYPE_CREDIT;
        else {
          setCreditAvl(false);
          // payType = avlCreditAmount < 0 ? "CASHADV" : BOOKING_PAYMENT_TYPE_CASH;
          // payType =
          //   approvedCredit === 0 ? "CASHADV" : BOOKING_PAYMENT_TYPE_CASH;
          payType = BOOKING_PAYMENT_TYPE_CASH;
        }
      } else {
        // payType = avlCreditAmount < 0 ? "CASHADV" : BOOKING_PAYMENT_TYPE_CASH;
        payType = BOOKING_PAYMENT_TYPE_CASH;
        setCreditAvl(false);
      }

      setCreditNote(-calDatas.respData.creditNote);
      // setDebitNote(calDatas.respData.debitNote)

      setDebitNoteToPay(calDatas.respData.debitNote);
      setToDnDate(calDatas.respData.toUnpDnDate);
      setFromDnDate(calDatas.respData.fromUnpDnDate);
      setValue("paymentBy", payType);
      setAvailableCredits(avlCreditAmount);
      calculated = true;
      setiscalculatedOnce(true);
      if (
        custType === LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL ||
        (LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) &&
          appCreditLimit === 0)
      ) {
        setCreditDisp(-calDatas.respData.creditNote);
      } else {
        setCreditDisp(avlCreditAmount + calDatas.respData.creditNote);
      }
    } else if (calDatas.respCode === 99) {
      setIsSuccess(false);
      setDescription(SERVER_ERR_MSG);
      modalRef.current.click();
    } else {
      setIsSuccess(false);
      setDescription(calDatas.respMessage);
      modalRef.current.click();
    }
    setShow(false);
    return calculated;
  };

  const onErr = (data) => {
    console.log("onError", data);
  };

  /**
   * This function handles the booking process for a shipment and redirects to a payment gateway if
   * necessary.
   * @param data - It is a parameter passed to the function `bookOrder` which is used to make a
   * shipment booking. The exact contents of the `data` parameter are not shown in the code snippet,
   * but it is likely an object containing information about the shipment being booked, such as the
   * sender and recipient addresses,
   */
  const bookOrder = async (data) => {
    if (applyCredit) {
      data.paymentBy = BOOKING_PAYMENT_TYPE_CASHADV;
    }

    setShow(true);
    setActiveBlock(6);
    let bookingResponse = await CallApiPost(
      "SaveShipmentBooking",
      getBookingRequestData(data)
    );
    // setShow(false);
    if (bookingResponse.respCode === 200) {
      clearStorages();
      const userType = getUserInfo("userType");
      if (
        userType !== LINEHAUL_STAFF_ISR_USER_TYPE &&
        +bookingResponse.respData?.shipmentBilling[0]?.invoiceId > 0 &&
        bookingResponse.respData.paymentGateway
      ) {
        document.getElementById("paymentForm").innerHTML = getKiplForm(
          bookingResponse.respData.paymentGateway,
          bookingResponse.respData.shipmentId
        );
        document.getElementById("onlinepayment").submit();
      } else {
        navigate(
          "/postOrderBooking?orderId=" + bookingResponse.respData?.shipmentId
        );
      }
    } else if (bookingResponse.respCode === 409) {
      setIsSuccess(false);
      setTitle("Booking Failed");
      setDescription(bookingResponse.respMessage);
      modalRef.current.click();
      setActiveBlock(4);
      setTcChecked(false);
    } else {
      setIsSuccess(false);
      setTitle("Booking Failed");
      setDescription(
        !!bookingResponse.respMessage
          ? bookingResponse.respMessage
          : SERVER_ERR_MSG
      );
      modalRef.current.click();
      setActiveBlock(4);
      setTcChecked(false);
    }
    setShow(false);
  };

  const validateHandlig = (key, value, argHdlType, vasArray) => {
    let valid = true;
    // let vasArray = getValues("vasArray")
    if (getValues(key) && getValues(key) !== "") {
      const vld = validateTextField(value);
      if (vld.valid) {
        clearErrors(key);
        let objh = {
          serviceId: HANDLING_SERVICE_ID,
          pallets: parseInt(value),
          svcInfo1: argHdlType,
        };
        const index = vasArray.findIndex(
          (vas) =>
            vas.serviceId === HANDLING_SERVICE_ID && vas.svcInfo1 === argHdlType
        );
        if (index !== -1) {
          vasArray.splice(index, 1);
        }
        vasArray.push(objh);
        setValue("vasArray", vasArray);
      } else {
        setError(key, {
          type: "required",
          message: vld.msg,
        });
        valid = false;
      }
    } else {
      setError(key, {
        type: "required",
        message: EMPTY_PALLETS,
      });
      valid = false;
    }
    return valid;
  };

  const validateInsDocUpload = () => {
    let valid = true;
    const ins = getValues("insAttch");
    const insDeclared = getValues("insDeclaredPrice");
    const isInsChecked = !!selectedInsurance && selectedInsData?.length > 0;
    if (isInsChecked && (ins === null || ins === undefined)) {
      valid = false;
      setError("insAttch", {
        type: "required",
        message:
          "Please upload the invoice/receipt for the insured item to proceed for booking!!",
      });
    } else {
      clearErrors("insAttch");
    }

    if (
      isInsChecked &&
      (insDeclared === null ||
        insDeclared === undefined ||
        insDeclared === "" ||
        !NUMBER_TWO_DECIMAL.test(insDeclared))
    ) {
      valid = false;
      setError("insDeclaredPrice", {
        type: "required",
        message: "Please declare price to proceed for booking!!",
      });
    } else {
      clearErrors("insDeclaredPrice");
    }
    return valid;
  };
  /**
   * This function validates form inputs and adds VAS (value-added services) for calculation.
   * @returns The function `validateForCalculate` is returning a boolean value indicating whether the
   * form data is valid or not.
   */
  const validateForCalculate = async () => {
    let valid = new Set();
    valid.add(true);
    let vasArray = !!getValues("vasArray") ? getValues("vasArray") : [];
    switch (activeBlock) {
      case 1:
        // vasArray = !!getValues("vasArray") ? getValues("vasArray") : [];
        //validation for required palets space
        if (
          getValues("totalPalletArea") &&
          getValues("totalPalletArea") !== "" &&
          !!getValues("totalPalletArea")
        ) {
          if (NUMBER_REGEX.test(getValues("totalPalletArea"))) {
            if (parseInt(getValues("totalPalletArea")) > 0) {
              clearErrors("totalPalletArea");
            } else {
              setError("totalPalletArea", {
                type: "required",
                message: "Please enter value greater than 0",
              });
              valid.add(false);
            }
          } else {
            setError("totalPalletArea", {
              type: "required",
              message: "Please enter a valid number",
            });
            valid.add(false);
          }
        } else {
          setError("totalPalletArea", {
            type: "required",
            message: "Please enter no. of packages",
          });
          valid.add(false);
        }
        if (
          getValues("packageDesc") &&
          getValues("packageDesc") !== "" &&
          !!getValues("packageDesc")
        ) {
          if (ALPHANUM_SPACE_SPCL_CHAR.test(getValues("packageDesc"))) {
            clearErrors("packageDesc");
          } else {
            setError("packageDesc", {
              type: "required",
              message: "Input contains some invalid characters!!",
            });
            valid.add(false);
          }
        } else {
          setError("packageDesc", {
            type: "required",
            message: "Please provide content description!!",
          });
          valid.add(false);
        }
        if (getValues("shipmentDate") && getValues("shipmentDate") !== "") {
        } else {
          if (!getValues("routingCode")) {
            showAlert({
              isSuccess: false,
              title: "",
              description: "Please select an option to proceed with booking!!",
            });
          } else {
            showAlert({
              isSuccess: false,
              title: "",
              description: "Please select a Shipment Date",
            });
          }
          valid.add(false);
        }

        if (watchPicHandling) {
          valid.add(
            validateHandlig(
              "picHandlingPallets",
              getValues("picHandlingPallets"),
              "P",
              vasArray
            )
          );
        } else {
          const indexToRemove = vasArray?.findIndex(
            (vas) =>
              vas.serviceId === HANDLING_SERVICE_ID && vas.svcInfo1 === "P"
          );
          if (indexToRemove !== -1) {
            vasArray.splice(indexToRemove, 1);
          }
        }

        if (watchDelHandling) {
          valid.add(
            validateHandlig(
              "delHandlingPallets",
              getValues("delHandlingPallets"),
              "D",
              vasArray
            )
          );
        } else {
          const indexToRemove = vasArray?.findIndex(
            (vas) =>
              vas.serviceId === HANDLING_SERVICE_ID && vas.svcInfo1 === "D"
          );
          if (indexToRemove !== -1) {
            vasArray.splice(indexToRemove, 1);
          }
        }

        if (watchPackaging) {
          if (
            getValues("packagingPallets") &&
            getValues("packagingPallets") !== ""
          ) {
            const vld = validateTextField(getValues("packagingPallets"));
            if (vld.valid) {
              clearErrors("packagingPallets");
              let objp = {
                serviceId: PACKAGING_SERVICE_ID,
                pallets: parseInt(getValues("packagingPallets")),
              };
              removePackaging(vasArray);
              vasArray.push(objp);
            } else {
              setError("packagingPallets", {
                type: "required",
                message: vld.msg,
              });
              valid.add(false);
            }
          } else {
            setError("packagingPallets", {
              type: "required",
              message: EMPTY_PALLETS,
            });
            valid.add(false);
          }
        } else {
          removePackaging(vasArray);
        }
        // setVas(vasArray);
        setValue("vasArray", vasArray);
        break;
      case 2:
        let mainArr = [
          "senderPostcode",
          "senderCity",
          "senderState",
          "senderAddress",
          "senderPhone",
          "senderName",
          "recipientPostcode",
          "recipientCity",
          "recipientState",
          "recipientAddress",
          "recipientPhone",
          "recipientName",
        ];
        let pickupArr = [
          "pickupPostcode",
          "pickupCity",
          "pickupState",
          "pickupAddress",
          "pickupPhone",
          "pickupName",
        ];
        let delivArr = [
          "deliveryPostcode",
          "deliveryCity",
          "deliveryState",
          "deliveryAddress",
          "deliveryPhone",
          "deliveryName",
        ];
        if (
          avalServices?.find((srvc) => srvc.value === PICKUP_SERVICE_ID)
            ?.isChecked
        ) {
          mainArr = mainArr.concat(pickupArr);
        }

        if (
          avalServices?.find((srvc) => srvc.value === DELIVERY_SERVICE_ID)
            ?.isChecked
        ) {
          mainArr = mainArr.concat(delivArr);
        }

        valid.add(await trigger(mainArr));

        // add VAS for calculate
        getVas();
        break;
      case 3:
        // add VAS for calculate
        getVas();
        valid.add(validateInsDocUpload());
        break;

      case 4:
        // add VAS for calculate
        getVas();
        break;

      default:
        break;
    }
    setValue("insuranceValue", selectedInsurance);

    return valid.has(false) ? false : true;
  };

  const removePicHandling = (argVasArray) => {
    const indexToRemove = argVasArray?.findIndex(
      (vas) => vas.serviceId === HANDLING_SERVICE_ID && vas.svcInfo1 === "P"
    );
    if (indexToRemove !== -1) {
      argVasArray?.splice(indexToRemove, 1);
    }
  };

  const removeDlvHandling = (argVasArray) => {
    const indexToRemove = argVasArray?.findIndex(
      (vas) => vas.serviceId === HANDLING_SERVICE_ID && vas.svcInfo1 === "D"
    );
    if (indexToRemove !== -1) {
      argVasArray?.splice(indexToRemove, 1);
    }
  };

  const removePackaging = (argVasArray) => {
    const indexToRemove = argVasArray?.findIndex(
      (vas) => vas.serviceId === PACKAGING_SERVICE_ID
    );
    if (indexToRemove !== -1) {
      argVasArray?.splice(indexToRemove, 1);
    }
  };
  /**
   * The function `getVas` creates an array of objects representing value-added services based on user
   * input.
   * @returns The function `getVas` is returning an array of objects that represent the value-added
   * services (VAS) selected by the user. Each object contains a `serviceId` property that maps to a
   * specific VAS service, and a `pallets` property that represents the number of pallets associated
   * with that service.
   */
  const getVas = () => {
    // setUpShipmentVas(avalServices)
    let vasArray = !!getValues("vasArray") ? getValues("vasArray") : [];
    if (watchPicHandling) {
      let objhp = {
        serviceId: HANDLING_SERVICE_ID,
        pallets: parseInt(getValues("picHandlingPallets")),
        svcInfo1: "P",
      };
      removePicHandling(vasArray);
      vasArray?.push(objhp);
    } else {
      removePicHandling(vasArray);
    }

    if (watchDelHandling) {
      let objhd = {
        serviceId: HANDLING_SERVICE_ID,
        pallets: parseInt(getValues("delHandlingPallets")),
        svcInfo1: "D",
      };
      removeDlvHandling(vasArray);
      vasArray?.push(objhd);
    } else {
      removeDlvHandling(vasArray);
    }
    if (watchPackaging) {
      let objp = {
        serviceId: PACKAGING_SERVICE_ID,
        pallets: parseInt(getValues("packagingPallets")),
      };
      removePackaging(vasArray);
      vasArray?.push(objp);
    } else {
      removePackaging(vasArray);
    }
    setValue("vasArray", vasArray);
    // return vasArray;
  };

  /**
   * The function validates a text field input for a valid number and checks if it is less than or
   * equal to a certain value.
   * @param data - The input data that needs to be validated. It is assumed to be a string representing
   * a number.
   * @returns An object with two properties: `valid` and `msg`. The `valid` property is a boolean
   * indicating whether the input data is valid or not, and the `msg` property is a string message
   * providing additional information about the validation result.
   */
  const validateTextField = (data) => {
    let obj;
    if (!NUMBER_REGEX.test(data)) {
      obj = { valid: false, msg: "Please enter a valid number" };
    } else if (parseInt(data) > parseInt(getValues("pallets"))) {
      obj = { valid: false, msg: "Value cannot be more than pallet space" };
    } else {
      obj = { valid: true, msg: "" };
    }

    return obj;
  };

  /**
   * The function clears two specific items from local storage.
   */
  const clearStorages = () => {
    localStorage.removeItem("BookingSla");
    localStorage.removeItem("initialBookingData");
  };

  /**
   * This function fetches a list of states and cities from an API and sets the data in various state
   * variables.
   */
  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });

    setStateData(stateArrList);

    setRecipientCityData(cityArrList);
    // setDeliveryCityData(cityArrList);
    // setPickupCityData(cityArrList);
    setSenderCityData(cityArrList);
    setAllCites(cityArrList);
    setIsSateAvl(true);
  };

  /**
   * The function filters a list of cities based on a given state and updates the cities data
   * accordingly.
   * @param state - The state for which the cities need to be filtered.
   * @param cityType - a string representing the type of city being filtered (e.g. "origin" or
   * "destination")
   */
  const filterCity = (state, cityType) => {
    if (state) {
      let cityArrList = [];

      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });

      setCitiesData(cityArrList, cityType);
      setValue(cityType, "");
    } else {
      setCitiesData(allCites, cityType);
      setValue(cityType, "");
    }
  };

  /**
   * The function sets data for different types of cities based on the input type.
   * @param data - The data parameter is the information that needs to be set for a specific city type.
   * It could be an object, an array, or any other data type that is relevant to the specific city
   * type.
   * @param type - The type parameter is a string that determines which city data state variable to
   * update. It can have one of the following values: "senderCity", "deliveryCity", "recipientCity", or
   * "pickupCity".
   */
  const setCitiesData = (data, type) => {
    switch (type) {
      case "senderCity":
        setSenderCityData(data);
        break;
      // case "deliveryCity":
      //   setDeliveryCityData(data);
      //   break;
      case "recipientCity":
        setRecipientCityData(data);
        break;
      // case "pickupCity":
      //   setPickupCityData(data);
      //   break;
      default:
        break;
    }
  };

  /**
   * The function filters a state based on a given city and sets the value of a specified state type
   * input field while clearing any errors.
   * @param city - The city parameter is a string representing the name of a city.
   * @param stateType - The stateType parameter is a string representing the name of the input field
   * where the state value will be set. It is used in the setValue and clearErrors functions to update
   * the state value and clear any errors associated with the input field.
   */
  const filterState = (city, stateType) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });
      if (flt.length === 1) {
        setValue(stateType, flt[0].map_state);
      }
      clearErrors(stateType);
    }
  };

  /**
   * The function sets the selected shipping option and updates the selected shipment date.
   * @param item - The "item" parameter is an object that represents a selected shipping option (SLA)
   * with properties such as "shipOn" which represents the date the item will be shipped. The function
   * "onSelectSla" is called when a user selects a shipping option and it sets the selected SLA
   */
  const onSelectSla = (item, argFreeSpace) => {
    if (!!item && item.freeSpace >= argFreeSpace && item.holiday !== true) {
      // setSelectedShipDate(item?.shipOn);
      setValue("shipmentDate", item?.shipOn);
      setValue("estDeliveryDate", item?.deliveryOn);
      setValue("routingCode", item?.routingCode);
      getVas();
      calculateBooking(true);
    }
  };

  /**
   * This function handles a breadcrumb click event and performs validation, calculation, and
   * navigation based on the clicked page number.
   * @param pageNo - represents the page number that is being clicked on the breadcrumb navigation.
   */
  const handelBreadcrumbClicked = async (pageNo) => {
    let valid;
    if (pageNo < activeBlock) {
      valid = true;
    } else {
      valid = await validateForCalculate();
    }

    if (valid) {
      await calculateBooking();
      setTcChecked();

      if (pageNo === 4) {
        validateForSummary();
      } else {
        setActiveBlock(pageNo);
        // setPromoAmount("");
        // setValue("promoCode");
        if (pageNo === 2) {
          if (!senderAddressCalled) getSenderAddress();
          if (!isSateAvl) fetchStatesAndCityList();
          if (!isAddrsBookAvl) getAddressBookData();
        }
      }
    }
  };

  /**
   * The function handles the click event of the next button, validates the input, calculates the
   * booking, and updates the active block if valid.
   */
  const handelNextBtnClicked = async () => {
    let valid = await validateForCalculate();
    if (valid) {
      await calculateBooking();
      if (activeBlock < 4) setActiveBlock(activeBlock + 1);
      if (activeBlock === 1) {
        if (!senderAddressCalled) getSenderAddress();
        if (!isSateAvl) fetchStatesAndCityList();
        if (!isAddrsBookAvl) getAddressBookData();
      }
    }
    if (activeBlock === 3 && !getValues("insuranceValue")) {
      openConfirmAlert();
    }
  };

  /**
   * The function decreases the value of the activeBlock variable by 1 if it is greater than 1.
   */
  const handelPrvBtnClicked = () => {
    if (activeBlock > 1) setActiveBlock(activeBlock - 1);
    // setPromoAmount("");
    // setValue("promoCode");
    setTcChecked();
  };

  const setModalData = (data) => {
    setModalConfig({
      size: "md",
      show: true,
      title: "Choose Address",
    });
    setSelectedAddrType(data);
  };

  const handleModalClose = (reload = false) => {
    setModalConfig({
      size: "md",
      show: false,
      title: "",
    });
    // reset(selectedData);
  };
  const getAllPromoList = async () => {
    setShow(true);
    const promoResp = await CallApiGet(
      "getPromoCodeList?customerId=" + customerId
    );
    setShow(false);
    if (promoResp.respCode === 200) {
      setPromoCodeList(promoResp.respData);
    }
  };
  const setPromoModalData = (data) => {
    getAllPromoList();
    setPromoModalConfig({
      size: "lg",
      show: true,
      title: "APPLY PROMOCODE",
    });
    setSelectedAddrType(data);
  };

  const handlePromoModalClose = (reload = false) => {
    setPromoModalConfig({
      size: "lg",
      show: false,
      title: "",
    });
    setValue("promoCode", "");
    // reset(selectedData);
  };

  /**
   * This function retrieves the sender's details from an API and sets the corresponding form values.
   */
  const getSenderAddress = async () => {
    setShow(true);

    const cid = !!readLocalStorage("customerId")
      ? parseInt(readLocalStorage("customerId"))
      : 0;
    const ctype =
      getUserInfo("userType") === LINEHAUL_STAFF_ISR_USER_TYPE
        ? LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS
        : getUserInfo("userType");

    let resp = await CallApiGet(
      "getUserDetails?profileId=" + cid + "&userType=" + ctype
    );
    setShow(false);
    if (resp.respCode === 200) {
      let dt = resp.respData[0];
      let phoneNo = dt.phone;

      setValue("senderName", dt.name);
      setValue("senderPhoneCode", phoneNo.substr(0, 3));
      setValue("senderPhone", phoneNo.substr(3));
      setValue("senderAddress", dt.address);
      setValue("senderState", dt.state);
      setValue("senderCity", dt.city);
      setValue("senderPostcode", dt.postcode);
      setSenderAddressCalled(true);
    }
  };

  /**
   * This function saves a customer's address book and displays a success message if the save is
   * successful.
   * @param addrType - The parameter `addrType` is likely a string that specifies the type
   * of address being saved to the address book. It is used to generate the data that will be sent in
   * the API call to save the address book entry.
   */
  const saveAddressBook = async (addrType) => {
    setShow(true);
    let addResp = await CallApiPost(
      "SaveCustAddrBook",
      getAddressBookDataForSave(addrType)
    );
    setShow(false);
    if (addResp.respCode === 200) {
      handleModalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Address Added Successfully",
      });
      //get Updated Address Book
      getAddressBookData();
    } else {
      // handleModalClose();
      showAlert({
        isSuccess: false,
        title: "",
        description: addResp.respMessage,
      });
    }
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  /**
   * The function returns an object containing address book data for a specific address type.
   * @param addrType - addrType is a string parameter that represents the type of address book data being
   * retrieved. It is used to construct the keys for retrieving the values of various fields such as
   * name, phone, address, state, city, and postcode. For example, if addrType is "billing", then the
   * function will
   * @returns The function `getAddressBookDataForSave` is returning an object with properties `custId`,
   * `name`, `phone`, `address`, `state`, `city`, and `postcode`. The values of these properties are
   * obtained from various functions such as `getUserInfo`, `getValues`, and string concatenation. The
   * specific values depend on the argument `addrType` passed to the function.
   */
  const getAddressBookDataForSave = (addrType) => {
    return {
      custId: getUserInfo("userId"),
      name: getValues(addrType + "Name"),
      phone:
        getValues(addrType + "PhoneCode") + "" + getValues(addrType + "Phone"),
      address: getValues(addrType + "Address"),
      state: getValues(addrType + "State"),
      city: getValues(addrType + "City"),
      postcode: getValues(addrType + "Postcode"),
    };
  };

  /**
   * The function retrieves address book data for a specific customer ID and sets it as the address
   * book while also setting a flag indicating the availability of the address book.
   */
  const getAddressBookData = async () => {
    let addrData = await fetchAddressBook();
    setAddressBook(addrData?.addressBook);
    setIsAddrsBookAvl(addrData?.isAddrsBookAvl);
  };

  /**
   * The function handles the selection of an address and populates the corresponding form fields with
   * the selected address data.
   * @param data - The data parameter is an object that contains information about the selected address,
   * including the name, phone number, address, state, city, and postcode.
   */
  const handelOnAddressSelected = (data) => {
    handleModalClose();

    clearErrors(selectedAddrType + "Name");
    clearErrors(selectedAddrType + "Phone");
    clearErrors(selectedAddrType + "Address");
    clearErrors(selectedAddrType + "State");
    clearErrors(selectedAddrType + "City");
    clearErrors(selectedAddrType + "Postcode");

    setValue(selectedAddrType + "Name", data.name);
    setValue(selectedAddrType + "PhoneCode", data.phone.substr(0, 3));
    setValue(selectedAddrType + "Phone", data.phone.substr(3));
    setValue(selectedAddrType + "Address", data.address);
    setValue(selectedAddrType + "State", data.state);
    setValue(selectedAddrType + "City", data.city);
    setValue(selectedAddrType + "Postcode", data.postcode);
  };

  /**
   * The function validates form input fields and sets the active page based on the validity of the
   * input.
   */
  const validateForSummary = async () => {
    let valid = false;
    if (
      getValues("senderPostcode") &&
      getValues("senderCity") &&
      getValues("senderState") &&
      getValues("senderAddress") &&
      getValues("senderPhone") &&
      getValues("senderName") &&
      getValues("recipientPostcode") &&
      getValues("recipientCity") &&
      getValues("recipientState") &&
      getValues("recipientAddress") &&
      getValues("recipientPhone") &&
      getValues("recipientName")
    ) {
      valid = true;
    }
    if (
      avalServices?.find((srvc) => srvc.value === PICKUP_SERVICE_ID)?.isChecked
    ) {
      valid = false;
      if (
        getValues("pickupPostcode") &&
        getValues("pickupCity") &&
        getValues("pickupState") &&
        getValues("pickupAddress") &&
        getValues("pickupPhone") &&
        getValues("pickupName")
      )
        valid = true;
    }

    if (
      avalServices?.find((srvc) => srvc.value === DELIVERY_SERVICE_ID)
        ?.isChecked
    ) {
      valid = false;
      if (
        getValues("deliveryPostcode") &&
        getValues("deliveryCity") &&
        getValues("deliveryState") &&
        getValues("deliveryAddress") &&
        getValues("deliveryPhone") &&
        getValues("deliveryName")
      )
        valid = true;
    }
    const activePage = valid ? 4 : 2;

    if (activePage === 2) {
      setActiveBlock(activePage);
      if (!senderAddressCalled) getSenderAddress();
      if (!isSateAvl) fetchStatesAndCityList();
      if (!isAddrsBookAvl) getAddressBookData();
      if (!valid) {
        showAlert({
          isSuccess: false,
          title: "Delivery Information",
          description: "Please Provide Required delivery Information",
        });
      }
    }
    if (activePage === 4 && !getValues("insuranceValue")) {
      openConfirmAlert();
    }
    setActiveBlock(activePage);
  };

  /**
   * The function retrieves sender, recipient, pickup, and delivery addresses from a form and returns
   * them as an object.
   * @returns The function `getGivenAddresses` is returning an object `addresses` which contains the
   * sender, recipient, pickup and delivery addresses. If the corresponding input fields are not
   * filled, the respective address object will be empty.
   */
  const getGivenAddresses = () => {
    let addresses = {};

    if (getValues("senderName")) {
      addresses.senderAddress = {
        name: getValues("senderName"),
        address: getValues("senderAddress"),
        city: getValues("senderCity"),
        state: getValues("senderState"),
        postcode: getValues("senderPostcode"),
        phone: getValues("senderPhoneCode") + "" + getValues("senderPhone"),
      };
    } else {
      addresses.senderAddress = {};
    }
    if (getValues("recipientName")) {
      addresses.recipientAddress = {
        name: getValues("recipientName"),
        address: getValues("recipientAddress"),
        city: getValues("recipientCity"),
        state: getValues("recipientState"),
        postcode: getValues("recipientPostcode"),
        phone:
          getValues("recipientPhoneCode") + "" + getValues("recipientPhone"),
      };
    } else {
      addresses.recipientAddress = {};
    }

    // if (avalServices?.find(srvc => srvc.value === PICKUP_SERVICE_ID)?.isChecked && getValues("pickupName")) {
    if (watch("isPickUp") && getValues("pickupName")) {
      addresses.pickupAddress = {
        name: getValues("pickupName"),
        address: getValues("pickupAddress"),
        city: getValues("pickupCity"),
        state: getValues("pickupState"),
        postcode: getValues("pickupPostcode"),
        phone: getValues("pickupPhoneCode") + "" + getValues("pickupPhone"),
      };
    } else {
      addresses.pickupAddress = {};
    }
    // if (avalServices?.find(srvc => srvc.value === DELIVERY_SERVICE_ID)?.isChecked && getValues("deliveryName")) {
    if (watch("isDelivery") && getValues("deliveryName")) {
      addresses.deliveryAddress = {
        name: getValues("deliveryName"),
        address: getValues("deliveryAddress"),
        city: getValues("deliveryCity"),
        state: getValues("deliveryState"),
        postcode: getValues("deliveryPostcode"),
        phone: getValues("deliveryPhoneCode") + "" + getValues("deliveryPhone"),
      };
    } else {
      addresses.deliveryAddress = {};
    }
    return addresses;
  };

  const onTermConditionChecked = (isChecked) => {
    if (isChecked && debitNoteToPay > 0) {
      document.getElementById("term").checked = false;
      openDnAlert();
    } else {
      if (canBook) setTcChecked(isChecked);
    }
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const confirmAlert = () => {
    setActiveBlock(3);
    hideConfirmAlert();
  };
  const openConfirmAlert = (row) => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "lg",
      show: true,
      title: "Protection",
      // description: "Deactivate Rate",
    });
  };

  const hideDnAlert = () => {
    setDnAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const openDnAlert = () => {
    setDnAlertConfig({
      isSuccess: false,
      size: "lg",
      show: true,
      title: "Un-Paid DNs",
      // description: "Deactivate Rate",
    });
  };

  const payDn = () => {
    writeLocalStorage("invToDate", toDnDate);
    writeLocalStorage("invFromDate", fromDnDate);
    navigate("/invoice");
  };

  /**
   * The function `filterInsurance` filters the `insuranceData` array based on the provided `id`, sets
   * the filtered data as the selected insurance data, and updates the value of the "selectedInsurance"
   * field.
   * This function is used for getting the selected insurance data for summary page.
   * @param id - The `id` parameter is the unique identifier of the insurance data that you want to
   * filter.
   */
  const filterInsurance = (isChecked, id) => {
    if (isChecked) {
      let insData = insuranceData.filter((ins) => ins.insuranceId === id);
      setSelectedInsData(insData);
      setValue("selectedInsurace", insData);
    } else {
      setSelectedInsData();
      setValue("selectedInsurace", "");
    }
  };

  /**
   * The function returns booking request data with various properties and values based on the input
   * data.
   * @param data - an object containing various data related to a booking request, such as customerId,
   * serviceCode, shipmentDate, totalPalletArea, pallets, recipientIC, insuranceValue, destination,
   * origin, paymentBy, userId, etc.
   * @returns The function `getBookingRequestData` is returning an object with various properties such
   * as `customerId`, `serviceCode`, `routingCode`, `shipOption`, `shipmentDate`,
   * `palletSpaceRequired`, `pallets`, `recipientIC`, `insurancePkgId`, `cacheId`, `destination`,
   * `origin`, `shipmentVas`, `doReturn`, `senderAddress`,
   */
  const getBookingRequestData = (data) => {
    getVas();
    let allAddresses = getGivenAddresses();
    getSpecialPermissionDataModel(data);
    let argAttachments = [];
    argAttachments.push(data?.insAttch);
    if (watch("specialPickup")) {
      argAttachments.push(data?.pickupInstUpload);
    }
    if (watch("specialDelivery")) {
      argAttachments.push(data?.dlvInstUpload);
    }
    let obj = {
      customerId: !!readLocalStorage("customerId")
        ? parseInt(readLocalStorage("customerId"))
        : 0,
      serviceCode: data.serviceCode,
      routingCode: data.routingCode,
      shipOption: data.option,
      shipmentDate: data.shipmentDate,
      palletSpaceRequired: data.pallets,
      pallets:
        data.totalPalletArea === ""
          ? 0
          : Math.min(parseInt(data.totalPalletArea), parseInt(data.pallets)),
      recipientIC: data.recipientIC ? data.recipientIC : "",
      insurancePkgId: data.insuranceValue ? data.insuranceValue : 0,
      cacheId: data?.cacheId,
      shipmentVas: !!getValues("vasArray") ? getValues("vasArray") : [],
      doReturn: data.dor ? true : false,
      senderAddress: allAddresses.senderAddress,
      pickupAddress: getValues("isPickUp")
        ? allAddresses.pickupAddress
        : {
            name: "N/A",
            phone: "N/A",
            address: "N/A",
            city: "N/A",
            state: "N/A",
            postcode: "N/A",
          },
      recipientAddress:
        getValues("isDelivery") === true
          ? allAddresses.deliveryAddress
          : allAddresses.recipientAddress,
      deliveryAddress:
        getValues("isDelivery") === true
          ? allAddresses.deliveryAddress
          : allAddresses.recipientAddress,
      paymentBy: data.paymentBy,
      isInternational: false,
      userId: data.userId,
      totalWeight: 0,
      estDeliveryDate: data.estDeliveryDate,
      orderInfos: getSpecialPermissionDataModel(data),
      promoCode: data.promoCode,
      cacheBillId: cacheBillId,
      attachments: argAttachments,
      packageDesc: data.packageDesc,
      packingType: STANDARD_BOOKING_PACKING_TYPE,
      insDeclaredPrice: getValues("insDeclaredPrice"),
    };
    if (calcReturnDatas && calcReturnDatas.destination)
      obj.destination = calcReturnDatas.destination;
    else obj.destination = { sbuId: getValues("sbuTo") };

    if (calcReturnDatas && calcReturnDatas.origin)
      obj.origin = calcReturnDatas.origin;
    else obj.origin = { sbuId: getValues("sbuFrom") };

    return obj;
  };

  /**
   * The function `getSpecialPermissionDataModel` takes in an object `data` and returns an array of
   * special permission objects based on the properties `specialPickup` and `specialDelivery` of the
   * `data` object.
   * @param data - The `data` parameter is an object that contains information about special
   * permissions. It has two properties: `specialPickup` and `specialDelivery`. These properties are
   * boolean values indicating whether special pickup and special delivery permissions are granted or
   * not.
   * @returns The function `getSpecialPermissionDataModel` returns an array of objects. Each object in
   * the array has two properties: `infoCode` and `infoValue`.
   */
  const getSpecialPermissionDataModel = (data) => {
    let specialPermissionObj = [];
    if (data.specialPickup) {
      specialPermissionObj.push({
        infoCode: 176,
        infoValue: "pickup",
      });
    }
    if (data.specialDelivery) {
      specialPermissionObj.push({
        infoCode: 176,
        infoValue: "delivery",
      });
    }
    return specialPermissionObj;
  };

  /**
   * The function `getCalculateReqData` returns an object containing various data related to a shipment,
   * including customer ID, service code, shipment date, pallet space required, and addresses.
   * @returns The function `getCalculateReqData` is returning an object `data` which contains various
   * properties such as `customerId`, `serviceCode`, `routingCode`, `shipOption`, `shipmentDate`,
   * `palletSpaceRequired`, `pallets`, `recipientIC`, `insurancePkgId`, `cacheId`, `shipmentVas`,
   * `destination`, `origin`, `senderAddress`,
   */
  const getCalculateReqData = (isSearch) => {
    let data;
    let allAddresses = getGivenAddresses();
    data = {
      customerId: getValues("customerId"),
      serviceCode: getValues("serviceCode"),
      routingCode: getValues("routingCode"),
      shipOption: getValues("option"),
      shipmentDate: getValues("shipmentDate"),
      palletSpaceRequired: getValues("pallets"),
      customerTypeId: custType,
      approvedCreditLimit: appCreditLimit,
      pallets:
        getValues("totalPalletArea") === ""
          ? 0
          : Math.min(
              parseInt(getValues("totalPalletArea")),
              parseInt(getValues("pallets"))
            ),
      recipientIC: getValues("recipientIC") ? getValues("recipientIC") : "",
      insurancePkgId: getValues("insuranceValue")
        ? getValues("insuranceValue")
        : 0,
      cacheId: getValues("cacheId"),
      shipmentVas: !!getValues("vasArray") ? getValues("vasArray") : [],
      cacheBillId: cacheBillId,
    };
    if (!isSearch && calcReturnDatas && calcReturnDatas.destination)
      data.destination = calcReturnDatas.destination;
    else data.destination = { sbuId: getValues("sbuTo") };

    if (!isSearch && calcReturnDatas && calcReturnDatas.origin)
      data.origin = calcReturnDatas.origin;
    else data.origin = { sbuId: getValues("sbuFrom") };

    if (!isSearch && calcReturnDatas && calcReturnDatas.senderAddress)
      data.senderAddress = calcReturnDatas.senderAddress;
    else data.senderAddress = allAddresses.senderAddress;

    if (!isSearch && calcReturnDatas && calcReturnDatas.recipientAddress)
      data.recipientAddress = calcReturnDatas.recipientAddress;
    else data.recipientAddress = allAddresses.recipientAddress;

    if (
      !isSearch &&
      calcReturnDatas &&
      calcReturnDatas.pickupAddress &&
      allAddresses.pickupAddress.name
    )
      data.pickupAddress = calcReturnDatas.pickupAddress;
    else data.pickupAddress = allAddresses.pickupAddress;

    if (
      !isSearch &&
      calcReturnDatas &&
      calcReturnDatas.deliveryAddress &&
      allAddresses.deliveryAddress.name
    )
      data.deliveryAddress = calcReturnDatas.deliveryAddress;
    else data.deliveryAddress = allAddresses.deliveryAddress;

    return data;
  };

  /**
   * The function updates and sets the calculated return data based on the provided response data.
   * @param resData - an object containing data related to a delivery, including origin, destination,
   * delivery address, pickup address, sender address, and recipient address.
   */
  const updateCalcReturnDatas = (resData) => {
    let data = {};
    data.origin = resData.origin;
    data.destination = resData.destination;
    setCacheBillId(resData.cacheBillId);
    // if (resData.deliveryAddress.name) {
    //   data.deliveryAddress = resData.deliveryAddress;
    // }
    // if (resData.pickupAddress.name) {
    //   data.pickupAddress = resData.pickupAddress;
    // }
    if (resData.senderAddress && resData.senderAddress.name) {
      data.senderAddress = resData.senderAddress;
    }
    if (resData.recipientAddress && resData.recipientAddress.name) {
      data.recipientAddress = resData.recipientAddress;
    }
    setCalcReturnDatas(data);
  };

  /**
   * The function `checkPickupDeliveryAvailability` checks if pickup or delivery service is available
   * for a given address and displays an alert if it is not available.
   * @param respData - The `respData` parameter is an object that contains information about the pickup
   * and delivery addresses. It has the following properties:
   */
  const checkPickupDeliveryAvailability = (respData) => {
    if (
      (getValues("pickupName") || getValues("deliveryName")) &&
      (respData.pickupAddress.noService === true ||
        respData.deliveryAddress.noService === true)
    ) {
      showAlert({
        isSuccess: false,
        title: "Service Not Available",
        description:
          "Pickup or Delivery service is not available for your address",
      });
      if (respData.pickupAddress.noService) {
        setValue("pickup", "lodgein");
        clearAddressData("pickup");
      }
      if (respData.deliveryAddress.noService) {
        setValue("delivery", "selfCollect");
        clearAddressData("delivery");
      }
    }
  };
  const clearAddressData = (addrType) => {
    setValue(addrType + "Name", undefined);
    setValue(addrType + "Phone", undefined);
    setValue(addrType + "PhoneCode", COUNTRY_CODES[0].value);
    setValue(addrType + "Address", undefined);
    setValue(addrType + "State", undefined);
    setValue(addrType + "City", undefined);
    setValue(addrType + "Postcode", undefined);
    setValue(addrType + "Pallets", undefined);
  };

  /**
   * The function `applyPromoCode` applies a promo code to a calculation request data, updates the tax
   * and cost amounts, and displays a success or error message.
   */
  const applyPromoCode = async () => {
    let promoCode = getValues("promoCode");
    let calcReqData = getCalculateReqData();
    calcReqData.shipmentBilling = shipmentBilling.shipmentBilling;
    calcReqData.promoCode = promoCode;
    calcReqData.paymentBy = getValues("paymentBy");

    setShow(true);
    let respDatas = await CallApiPost("applyPromo", calcReqData);
    setShow(false);
    if (respDatas.respCode === 200) {
      // let tax = taxAmount;
      // let cost = totalCost - taxAmount;
      // let handlingCost = 0;
      let tax = 0;
      let cost = 0;
      let handlingCost = 0;

      respDatas.respData.shipmentBilling.forEach((calData) => {
        switch (calData?.particular) {
          case "Shipping Bill":
            setBookingCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            break;
          case "Promotion":
            setPromoAmount(calData.billAmount.toFixed(2));
            tax = parseFloat(tax) + calData.taxAmount;
            cost = parseFloat(cost) + calData.billAmount;
            setValue("isPromoApplied", true);
            break;
          case "Packaging":
            setPackagingCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            setValue("isPromoApplied", true);
            break;
          case "Handling":
            handlingCost += calData.billAmount;
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            setValue("isPromoApplied", true);
            break;
          case "Delivery":
            setDeliveryCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            setValue("isPromoApplied", true);
            break;
          case "Pickup":
            setPickupCost(calData.billAmount.toFixed(2));
            tax = tax + calData.taxAmount;
            cost = cost + calData.billAmount;
            setValue("isPromoApplied", true);
            break;
          case "SST Tax":
            tax = +calData.taxAmount;
            setSstPercent(calData.svcInfo);
            break;
          default:
            break;
        }
      });

      setHandlingAmount(handlingCost.toFixed(2));
      setTaxAmount(tax.toFixed(2));
      setTotalCost((cost + tax).toFixed(2));
      console.log(cost);
      handlePromoModalClose();
      setValue("promoCode", promoCode);
    } else {
      setValue("promoCode", "");
      setValue("promoErrorResp", respDatas.respMessage);
      setTimeout(() => {
        setValue("promoErrorResp", null);
      }, 5000);
      // showAlert({
      //   isSuccess: false,
      //   title: "Promotion",
      //   description: respDatas.respMessage,
      // });
    }
  };

  /**
   * The function `setAddressData` takes in data and a type, and sets the corresponding values in the
   * form based on the data.
   * This is used for repite booking from myOrder page
   * @param data - The `data` parameter is an object that contains the address information. It has the
   * following properties:
   * @param type - The `type` parameter is a string that represents the type of address data being
   * passed. It is used to construct the names of the form fields that will be set with the
   * corresponding data.
   */
  const setAddressData = (data, type) => {
    setValue(type + "Name", data?.name);
    setValue(type + "Phone", data?.phone?.substr(4));
    setValue(type + "PhoneCode", data?.phone?.substr(0, 3));
    setValue(type + "Address", data?.address);
    setValue(type + "State", data?.state);
    setValue(type + "City", data?.city);
    setValue(type + "Postcode", data?.postcode);
    // setValue(type + "Pallets", data);
  };

  const handelInsuranceSelect = (isChecked, id) => {
    //
    if (isChecked) {
      setSelectedInsurance(id);
      clearErrors("insAttch");
      clearErrors("insDeclaredPrice");
    } else {
      setSelectedInsurance("");
      setValue("insAttch", null);
      setValue("insDeclaredPrice", null);
      clearErrors("insAttch");
      clearErrors("insDeclaredPrice");
    }
  };

  const updateBkngData = (argBkngData) => {
    setValue("option", argBkngData?.option);
    setValue("pallets", argBkngData?.pallets);
    setValue("totalPalletArea", argBkngData?.pallets);
    setValue("origin", argBkngData?.origin);
    setValue("destination", argBkngData?.destination);
    setValue("sbuFrom", argBkngData?.sbuFrom);
    setValue("sbuTo", argBkngData?.sbuTo);
    setValue("isPickUp", argBkngData?.isPickUp);
    setValue("isDelivery", argBkngData?.isDelivery);
    setValue("fromService", argBkngData?.fromService);
    setValue("toService", argBkngData?.toService);
    setValue("fromAddr", argBkngData?.fromAdrData);
    setValue("toAddr", argBkngData?.toAdrData);
    setValue("cacheId", argBkngData?.cacheId);
    setValue("bkngService", argBkngData?.bkngService);

    setUpOrgDest(argBkngData?.sbuFrom, argBkngData?.sbuTo);
    setUpSbuServices(argBkngData);

    if (!!argBkngData?.fromService && !!argBkngData?.toService) {
      setAvalServices([...argBkngData?.fromService, ...argBkngData?.toService]);
      setUpShipmentVas([
        ...argBkngData?.fromService,
        ...argBkngData?.toService,
      ]);
    }

    if (argBkngData?.isPickUp) {
      setUpPickup(argBkngData?.fromAdrData);
    }
    if (argBkngData?.isDelivery) {
      setUpDelivery(argBkngData?.toAdrData);
      setUpRecipientAddr(
        argBkngData?.toAdrData?.name,
        argBkngData?.toAdrData?.phone,
        argBkngData?.toAdrData?.address,
        argBkngData?.toAdrData?.state,
        argBkngData?.toAdrData?.city,
        argBkngData?.toAdrData?.postcode
      );
    }
    clearErrors();
  };

  const updateServiceCode = (argServiceCode) => {
    setValue("serviceCode", argServiceCode);
    setUpBookingConfigData(argServiceCode);
  };

  const clearPromoCode = () => {
    setValue("isPromoApplied", false);
    setValue("promoCode", "");
    setPromoAmount(0);
    calculateBooking();
  };

  const onProcided = () => {
    hideConfirmAlert();
    if (
      LINEHAUL_CUSTOMER_BUSINESS_PARTNERS.includes(custType) &&
      getValues("paymentBy") === "CASH"
    ) {
      showAlert({
        isSuccess: false,
        title: "",
        description: "Insufficient credit amount switched to cash",
      });
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid p-0 min-ht-100 wt-tbl">
        <BookingSearchComponent
          initBkngData={initialBookingData}
          showToggler={activeBlock === 1}
          showSlider={activeBlock === 1}
          setInitBkngData={setInitialBookingData}
          handleOptionSelect={onSelectSla}
          updateBkngData={updateBkngData}
          updateServiceCode={updateServiceCode}
          showSearchBtn={activeBlock === 1}
          addressBook={addressBook}
          isReadOnly={activeBlock > 1}
          disableOpt={true}
          defaultOpt="PLT"
        />

        <div className="px-4 py-2">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item theme-blue-txt fw-bold cursor-pointer">
                <a href="/">{GetSvgIcon("Home")}</a>
              </li>
              <li
                className={
                  activeBlock === 1
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                aria-current="page"
                onClick={() => handelBreadcrumbClicked(1)}
              >
                Space & Services
              </li>
              <li
                className={
                  activeBlock === 2
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                onClick={() => handelBreadcrumbClicked(2)}
              >
                Delivery Info
              </li>
              <li
                className={
                  activeBlock === 3
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                onClick={() => handelBreadcrumbClicked(3)}
              >
                Protection
              </li>
              <li
                className={
                  activeBlock === 4
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                onClick={() => handelBreadcrumbClicked(4)}
              >
                Summary
                {/* {previousClickble?"true":"false"} */}
              </li>
              {/* <li>
              <button type="button" className="btn btn-primary" onClick={handelOptionsPreviousButtonClick} disabled={!previousClickble}>privious</button>
              <button type="button" className="btn btn-primary" onClick={handelOptionsNextButtonClick}>Next</button>
              </li> */}
            </ol>
          </nav>
        </div>
        <div className="px-3">
          <div className="row">
            <div className="col-xxl-8 col-lg-9 col-md-12">
              <div>
                <div className="col-md-12">
                  {activeBlock === 1 && (
                    <BookingSapceService
                      register={register}
                      watch={watch}
                      avalServices={avalServices}
                      errors={errors}
                      clearErrors={clearErrors}
                      watchPicHandling={watchPicHandling}
                      watchDelHandling={watchDelHandling}
                      acceptedSize={acceptedSize}
                      setValue={setValue}
                      watchPackaging={watchPackaging}
                      isPicHandling={watch(P_HDL)}
                      isDlvHandling={watch(D_HDL)}
                      isPackaging={watch(PKG)}
                    />
                  )}

                  {/* address block start */}
                  {activeBlock === 2 && (
                    <BookingAddress
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      control={control}
                      stateData={stateData}
                      filterCity={filterCity}
                      senderCityData={senderCityData}
                      filterState={filterState}
                      setModalData={setModalData}
                      trigger={trigger}
                      saveAddressBook={saveAddressBook}
                      avalServices={avalServices}
                      recipientCityData={recipientCityData}
                      bookingType={"standard"}
                    />
                  )}
                  {/* address block ends */}

                  {/* Insurance block */}
                  {activeBlock === 3 && (
                    <BookingInsurance
                      insuranceData={insuranceData}
                      selectedInsurance={selectedInsurance}
                      handelInsuranceSelect={handelInsuranceSelect}
                      filterInsurance={filterInsurance}
                      avalServices={avalServices}
                      register={register}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      clearErrors={clearErrors}
                    />
                  )}
                  {/* insurance block ends */}

                  {activeBlock === 4 && (
                    <div className="w-100 d-block">
                      <OrderSummary
                        getValues={getValues}
                        avalServices={avalServices}
                        onTermConditionChecked={onTermConditionChecked}
                        orgDestAddrs={orgDestAddrs}
                        options={options}
                        bookingType={"standard"}
                      />
                    </div>
                  )}

                  {activeBlock === 6 && (
                    <div id="paymentForm">
                      <div className="py-5">
                        <center>
                          <h3 className="text-danger">
                            Please Wait. Payment is in progress...
                          </h3>
                          <h5 className="text-danger">
                            Do not click any button or refresh the page.
                          </h5>
                        </center>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-between my-3">
                    {activeBlock > 1 && activeBlock < 6 && (
                      <div className="col text-start">
                        <button
                          className="btn btn-outline-secondary theme-violet text-nowrap"
                          type="button"
                          onClick={handelPrvBtnClicked}
                        >
                          &larr; Previous
                        </button>
                      </div>
                    )}
                    {activeBlock < 4 && (
                      <div className="col text-end">
                        <button
                          className="btn btn-outline-secondary theme-violet text-nowrap"
                          type="button"
                          onClick={handelNextBtnClicked}
                        >
                          Next &rarr;
                        </button>
                      </div>
                    )}

                    {activeBlock === 4 && (
                      <div className="row">
                        <div className="col-md-12 ">
                          <div className="text-center">
                            <button
                              className="btn btn-outline-secondary theme-violet text-nowrap"
                              // type="submit"
                              disabled={!tcChecked}
                              onClick={handleSubmit(bookOrder, onErr)}
                            >
                              {creditAvl
                                ? "Confirm Booking "
                                : "Proceed for Payment "}{" "}
                              &rarr;
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-4 col-lg-3 col-md-12">
              <FareSummaryComponent
                totalCost={totalCost}
                bookingCost={bookingCost}
                pickupCost={pickupCost}
                deliveryCost={deliveryCost}
                handlingAmount={handlingAmount}
                packagingCost={packagingCost}
                insuranceCost={insuranceCost}
                taxAmount={taxAmount}
                SurchargeAmount={SurchargeAmount}
                discountAmount={discountAmount}
                iscalculatedOnce={iscalculatedOnce}
                activeBlock={activeBlock}
                register={register}
                watch={watch}
                setValue={setValue}
                applyPromoCode={applyPromoCode}
                promoAmount={promoAmount}
                custType={custType}
                BUSHINESS_USER_TYPE={LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS}
                ISR_USER_TYPE={LINEHAUL_STAFF_ISR_USER_TYPE}
                availableCredits={availableCredits}
                creditAvl={creditAvl}
                customerId={customerId}
                modalRef={modalRef}
                PERSONAL_USER_TYPE={LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL}
                payByCredit={setApplyCredit}
                payByCreditVal={applyCredit}
                setPromoModalData={setPromoModalData}
                clearPromoCode={clearPromoCode}
                sstPercent={sstPercent}
                applyCredit={applyCredit}
                appCreditLimit={appCreditLimit}
                creditNote={creditNote}
                creditDisp={creditDisp}
                debitNote={debitNote}
              />
            </div>
          </div>
        </div>
      </div>

      <Spinner show={show} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        // handler={onCreditBookingSuccess}
      />
      {!validSpecContent && (
        <CustomPopup
          icon={GetSvgIcon("ExclamationTriangleFill")}
          body={sepDescription}
          callback={() => setValidSpecContent(true)}
        />
      )}

      {modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
          // footer={footerControls}
        >
          <AddressBook
            handelOnAddressSelected={handelOnAddressSelected}
            addressBook={addressBook}
            selectedInsData={selectedInsData}
          />
        </ModalLayout>
      )}
      {promoModalConfig && (
        <ModalLayout
          moadlConfig={promoModalConfig}
          handleMoalClose={handlePromoModalClose}
          // footer={footerControls}
        >
          <BookingPromoCodes
            register={register}
            promoCodeList={promoCodeList}
            applyPromoCode={applyPromoCode}
            setValue={setValue}
            watch={watch}
          />
        </ModalLayout>
      )}

      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}

      {!!confirmAlertConfig && (
        <ProtectionConformAlert
          confirmAlertConfig={confirmAlertConfig}
          hideConfirmAlert={hideConfirmAlert}
          confirmAlert={confirmAlert}
          onProcided={onProcided}
        >
          <Card>
            <Card.Body>
              <Card.Text className="fw-bold">
                To ensure better coverage, it is advisable to purchase insurance
                otherwise, you will be limited to a maximum claim of RM200.
              </Card.Text>
              <Card.Text className="" style={{ fontSize: "smaller" }}>
                Press <span className="fw-bold">"Purchase Insurance"</span> to
                go back to the insurance page else press{" "}
                <span className="fw-bold">"Proceed without insurance"</span>
              </Card.Text>
            </Card.Body>
          </Card>
          {/* <div className="py-3">
            To ensure better coverage, it is advisable to purchase insurance; otherwise, you will be limited to a maximum claim of RM200.
          </div> */}
        </ProtectionConformAlert>
      )}

      {!!dnAlertConfig && (
        <ModalConfirmAlertCustom
          confirmAlertConfig={dnAlertConfig}
          hideConfirmAlert={hideDnAlert}
          confirmAlert={payDn}
          confirmBtnName={"Pay DN"}
          cancelBtnName={"Close"}
        >
          <div className="py-3 fw-bold">
          You have Unpaid DNs, Please Pay the outstanding amount first to Book a new Order
          </div>
        </ModalConfirmAlertCustom>
      )}
    </>
  );
};

export default StndBooking;

import React from "react";
import Modal from "react-bootstrap/Modal";
import GetSvgIcon from "../../images/svglist";

const ModalAlert = ({ children, alertConfig, hideAlert }) => {
  return (
    <>
      <Modal
        size={alertConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        show={alertConfig.show}
        onHide={hideAlert}
      >
        <Modal.Body>
          <div className="pop-up">
            {alertConfig.isSuccess
              ? GetSvgIcon("TickMark")
              : GetSvgIcon("ExclamationMark")}
            <h1
              className={alertConfig.isSuccess ? "text-success" : "text-danger"}
            >
              {alertConfig.title}
            </h1>
            {children}
          </div>
          <div className="text-center">
            <button
              className="btn btn-outline-secondary theme-violet"
              type="button"
              onClick={hideAlert}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAlert;

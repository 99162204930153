import React from 'react'
import DataTable from 'react-data-table-component-with-filter'
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../../Helper/Constants'

export default function RateCodeList(props) {
  return (
      <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={props.columns}
        data={props.data}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}

      />
    </div>
  )
}

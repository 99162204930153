import React, { useEffect, useState } from 'react'
//import useScript from '../Utilities/useScript'
import NotificationBell from '../Notification/NotificationBell'
import Profile from '../Profile/Profile'
import MenuNavBar from '../MenuNavBar/MenuNavBar'
import logo from './images/LinehaulLogo.png'
import GetSvgIcon from "../../images/svglist"
import { getUserInfo } from '../AuthUtils/AuthUtils'
import { CallApiPost } from '../Helper/serviceApi'
import { useNavigate } from 'react-router-dom'
import CustomAlert from './CustomAlert/CustomAlert'
import CustomPopup from './CustomPopup/CustomPopup'
import { SERVER_ERR_MSG } from '../Helper/Constants'

const homeUrl = '/'
const dashboardUrl = '/dashboard'
function Header({ isHome }) {
  //useScript(process.env.REACT_APP_FC_URL, getUserInfo("userName")? true : false);
  const [isOpen, setIsOpen] = useState(false)
  const [isPopup, setIsPopup] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isUserActive, setIsUserActive] = useState(false)
  const [description, setDescription] = useState()
  const [title, setTitle] = useState()
  const [loggedIn, setLoggedIn] = useState()
  const [userName, setUsername] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    setIsUserActive(getUserInfo("userActive"))
    setLoggedIn(getUserInfo("acctId"))
    setUsername(getUserInfo("userName"))
    //setFCWidgetData()
  }, [])

  const setFCWidgetData = () => {
    setTimeout(() => {
      if (!!window?.fcWidget) {
        window.fcWidget.user.setFirstName(getUserInfo("userName"));
      }
    }, 5000)
  }

  const resetPwdHandler = async () => {
    const resetModel = {
      userId: getUserInfo("userId"),
      requireUserDetails: true
    }
    let resp = await CallApiPost("forgot-password", resetModel)

    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess(resp.respMessage)
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setIsSuccess(false)
      setIsPopup(true)
      setIsOpen(false)
      setTitle("UNAUTHORIZED")
      setDescription("Sorry, you don't have the required permission!!")
    } else {
      onFailure()
    }
  }

  const toggleReset = (bool) => {
    setIsOpen(bool)
  }

  const onSuccess = (sucMsg) => {
    setIsSuccess(true)
    setIsPopup(true)
    setIsOpen(false)
    setTitle("Congratulations")
    setDescription(sucMsg)
  }

  const onFailure = () => {
    setIsSuccess(false)
    setIsPopup(true)
    setIsOpen(false)
    setTitle("ERROR")
    setDescription(SERVER_ERR_MSG)
  }

  const callback = () => {
    localStorage.clear()
    setIsPopup(false)
    navigate("/login", { replace: true })
  }

  return (
    <header className="clearfix border-box">

      {
        (isUserActive === "true") &&
        (
          <>
            <a href='#' className="float-start m-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">
              {GetSvgIcon('IconHeaderMenu')}{" "}
            </a>
            <MenuNavBar />
          </>

        )
      }

      {(isUserActive === "true") ? <a href={homeUrl}>
        <img src={logo} className="img-fluid float-start my-2 ms-2" alt="LinehaulLogo" />
      </a>:<div> <img src={logo} className="img-fluid float-start my-2 ms-2" alt="LinehaulLogo" /></div>}

      {loggedIn && (<>
        <Profile toggleReset={toggleReset} userName={userName} />
        <NotificationBell isHome={isHome} />
      </>
      )}

      {!loggedIn && (
        <div className="float-end m-2">
          <button className="btn btn-outline-secondary btn-lg theme-violet px-4" type="button" onClick={callback}>Login
          </button>
        </div>
      )}
      {
        isOpen &&
        <CustomAlert
          toggleReset={toggleReset}
          alertTitle="Reset Password"
          alertBody="You have requested to reset your password"
          callback={resetPwdHandler}
        />
      }
      {
        isPopup &&
        (isSuccess
          ? <CustomPopup
            icon={GetSvgIcon("EnvelopePaperFill")}
            body={description}
            callback={callback}
          />
          : <CustomPopup
            icon={GetSvgIcon("ExclamationTriangleFill")}
            body={description}
            callback={() => setIsPopup(false)}
          />
        )

      }
    </header>
  )
}

export default Header
import React, { useState } from "react";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
  customDataTableStyles,
} from "../Helper/Constants";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import { useEffect } from "react";
import DataTable from "react-data-table-component-with-filter";
import { CallApiPost } from "../Helper/serviceApi";
import { Tooltip } from "react-tooltip";

export default function VehicleFuelLogList(props) {
  const { vehicleList, setFuelLog } = props;
  
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setValue("fromDate", moment().format(DATE_FORMAT_SERVER));
    setValue("toDate", moment().endOf("month").format(DATE_FORMAT_SERVER));
  }, []);

  const [fuelLogRow, setFuelLogRow] = useState([]);
  const fromdateWatch = watch("fromDate");
  const todateWatch = watch("toDate");
  const fetchAllLogList = async (data) => {
    const uri = "list-fuellog";
    let resp = await CallApiPost(uri, data);
    setFuelLogRow(resp.respData);
  };
  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.fuelDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vhclNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Filled by",
      selector: (row) => row.driverName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Odo Meter",
      selector: (row) => row.odoMeter,
      sortable: true,
      filterable: true,
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      filterable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
        <div
          className="me-3"
          data-bs-toggle="modal"
          data-bs-target="#button-modal"
          data-tooltip-id={"edit-" + row.id}
          onClick={() => getFuelLogDtls(row)}
        >
          {GetSvgIcon("EditPencil")}
        </div>
        <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const getFuelLogDtls = async (data) => {
    ;
    setFuelLog(data);
  };

  return (
    <>
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-2">
            <label htmlFor="" className="form-label theme-label">
              Vehicle No. <span className="text-danger">&#9733;</span>
            </label>
            <Controller
              name="vehicleId"
              control={control}
              rules={{
                required: { value: true, message: REQUIRED_ERR_MSG },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={vehicleList}
                  placeholder="Select Vehicle"
                  value={
                    value
                      ? vehicleList.find((opt) => opt.value === value)
                      : value
                  }
                  onChange={(d) => onChange(d ? d.value : d)}
                  isSearchable={true}
                  isClearable={true}
                  className="form-control  p-0"
                />
              )}
            />
            {errors.vehicleNO && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.vehicleNO.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-md-2">
            <label htmlFor="" className="form-label theme-label">
              Log From
            </label>
            <Controller
              control={control}
              name="fromDate"
              rules={{
                required: {
                  value: todateWatch ? true : false,
                  message: REQUIRED_ERR_MSG,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  className="form-control"
                  selected={value ? new Date(value) : value}
                  onChange={(d) =>
                    onChange(
                      d !== null ? moment(d).format(DATE_FORMAT_SERVER) : ""
                    )
                  }
                  dateFormat={DATE_FORMAT_CLIENT}
                  placeholderText="Select From"
                  maxDate={new Date(todateWatch)}
                />
              )}
            />
            {errors?.dateFrom && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors?.dateFrom?.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-md-2">
            <label htmlFor="" className="form-label theme-label">
              Log To
            </label>
            <Controller
              control={control}
              name="toDate"
              rules={{
                required: {
                  value: fromdateWatch ? true : false,
                  message: REQUIRED_ERR_MSG,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <ReactDatePicker
                  className="form-control"
                  selected={value ? new Date(value) : value}
                  onChange={(d) =>
                    onChange(
                      d !== null ? moment(d).format(DATE_FORMAT_SERVER) : ""
                    )
                  }
                  dateFormat={DATE_FORMAT_CLIENT}
                  placeholderText="Select To"
                  minDate={new Date(fromdateWatch)}
                />
              )}
            />
            {errors?.dateTo && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors?.dateTo?.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-md-2">
            <div className="mt-1">
              <label htmlFor="" className="form-label theme-label"></label>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(fetchAllLogList)}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={fuelLogRow}

          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import CharteredRateList from "./CharteredRateList";
import GetSvgIcon from "../../images/svglist";
import CharteredRateAddEdit from "./CharteredRateAddEdit";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { SERVER_ERR_MSG } from "../Helper/Constants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const initModalConfig = {
  show: false,
  title: "",
};

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initObj = {
  thresholdType: 0,
  threshold: 0,
  baseCharge: 0,
  surcharge: 0,
  unitPrice: 0,
  custId: 0,
  rateTypeId: 0,
  rateId: 0,
};

const CharteredRateConfigMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [custList, setCustList] = useState([]);
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );

  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );

  const [rateType, setrateType] = useState([]);

  const [charteredRateList, setCharteredRateList] = useState([]);
  const [selRate, setSelRate] = useState(Object.assign({}, initObj));

  useEffect(() => {
    getRateList();
    getDropdownData();
  }, []);

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const getRateList = async () => {
    setSpinner(true);
    const uri = "GetCharteredRate?customerId=0";
    const data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setCharteredRateList(data.respData);
    } else {
      console.error(data.respMessage);
    }
    setSpinner(false);
  };

  const getDropdownData = async () => {
    setSpinner(true);
    const dropdownModel = {
      argRouteType: 1,
      argCustomer: 1,
      argChRateType: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      const respData = data.respData;
      setCustList(respData.custArr);
      setrateType(respData.chRateType);
    } else {
      console.error(data.respMessage);
    }
    setSpinner(false);
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setAlertConfig({
      isSuccess: boolData,
      size: "md",
      show: true,
      title: popupTitle,
      description: popupDesc,
    });
  };

  const handlePostApiCall = (resp, sucMsg) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", sucMsg);
        break;
      case 500:
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the write permission!!"
        );
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after soemtime!!"
        );
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    const resp = await CallApiPost("SaveCharteredRateConfig", data);
    handlePostApiCall(resp, "Chartered Rate processed successfully!!");
    handleCLose();
    getRateList();
    setSpinner(false);
  };

  const handleBtnClick = (argData, argTitle) => {
    setSelRate(argData);
    setModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setModalConfig({
      show: false,
      title: "",
    });
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">
              Chartered Rate Configuration{" "}
            </div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() => handleBtnClick(initObj, "ADD Rate")}
              >
                {GetSvgIcon("PlusCircle")} Add Rate
              </button>
            </div>
          </div>
        </div>
        <CharteredRateList
          handleAction={handleBtnClick}
          charteredRateList={charteredRateList}
        />
        <CharteredRateAddEdit
          modalConfig={modalConfig}
          onHide={handleCLose}
          custList={custList}
          rateType={rateType}
          selRate={() => selRate}
          onSubmit={onSubmit}
        />
      </Layout>
    </>
  );
};

export default CharteredRateConfigMain;

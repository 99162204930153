import React, { useEffect, useState } from "react";
import moment from "moment";
import { DATE_FORMAT_MOMENT, DATE_TIME_FORMAT_MOMENT } from "../Helper/Constants";
import CustomerOrderAttachment from "./CustomerOrderAttachment";
import CustomerOrderBillingDetails from "./CustomerOrderBillingDetails";
import GetSvgIcon, {
  GetSvgBuilding,
  GetSvgPerson,
  GetSvgPhone,
} from "../../images/svglist";
import { deliveryEventlog } from "../Helper/CommonMethods";
import OrderWaybillList from "./OrderWaybillList";
import { CallApiGet } from "../Helper/serviceApi";
import { initValShipmentDtls } from "./initValShipmentDtls";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import DeliveryEventLog from "./DeliveryEventLog";
import ClaimAddEdit from "../Claim/ClaimAddEdit";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const CustomerOrderDetails = ({
  cancelShipment,

  handelRtsClick,
  handelRepeatBooking,
  conformCancel,
}) => {
  // const SHOW_CANCEL_SHIPMENT_STS = 76;
  // const SHOW_TRANSIT_SHIPMENT_STS = 80;
  // const printAllWaybill = () => {
  //   const waybillData = {
  //     waybill_no: waybillList.map((item) => item.waybillNo),
  //     shipmentId: shipmentDtls?.shipmentId,
  //   };
  //   printWaybill(waybillData);
  // };
  const [dispAddress, setDispAddress] = useState(true);
  const [dispLodgin, setDispLodgin] = useState(true);
  const [dispSCollect, setDispSCollect] = useState(true);
  const [shipmentDtls, setShipmentDtls] = useState(
    JSON.parse(JSON.stringify(initValShipmentDtls))
  );
  const [waybillList, setWaybillList] = useState([]);
  const [claimId, setClaimId] = useState(0);
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [claimPalletId, setClaimPalletId] = useState(null);
  const [claimDetails, setClaimDetails] = useState(null);
  const [orgDestAddrs, setOrgDestAddrs] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [deliveryEventLog, setDeliveryEventLog] = useState();
  const [message, setMessage] = useState(null);
  const [shipId, setShipId] = useState();
  const [insPackages, setInsPackages] = useState(null);
  const navigate = useNavigate();
  const { Buffer } = require("buffer");

  useEffect(() => {
    const orderId = searchParams.get("data");
    setShipId(orderId);
    getShipmetDtls("orders", { shipmentId: orderId });
  }, []);

  const resetMessage = () => {
    setTimeout(() => setMessage(""), 50);
  };

  const getShipmetDtls = async (type, shipmentData) => {
    setSpinner(true);
    let data = await CallApiGet(
      "getShipmentDetailsById/" + shipmentData.shipmentId
    );

    if (data.respCode === 200) {
      data.respData.shipDateDiffInDays = moment(
        data.respData.shipmentDate
      ).diff(moment().format("YYYY-MM-DD"), "days");
      setShipmentDtls(data.respData);
      if (
        !!data?.respData?.shipmentPallets &&
        data.respData.shipmentPallets.length > 0
      ) {
        let waybillArray = data.respData.shipmentPallets.map((item) => {
          return {
            palletId: item.palletId,
            waybillNo: item.waybillNo,
            weight: item.weight,
            width: item.width,
            height: item.height,
            length: item.length,
          };
        });
        if (shipmentData.waybill_no && ["waybill", "claim"].includes(type)) {
          waybillArray = waybillArray.filter(
            (item) => item.waybillNo === shipmentData.waybill_no
          );
        }
        setWaybillList(waybillArray);
        if (type === "claim") {
          setClaimOrderId(data?.respData?.shipmentId);
          setClaimPalletId(waybillArray[0].palletId);
        }
      }
      getOriginDestinationAddress(
        data?.respData?.origin?.sbuId,
        data?.respData?.destination?.sbuId
      );
      filterShipmentDtls(data?.respData);
      setInsPackages(data?.respData?.insurPkgDtls ? JSON.parse(data?.respData?.insurPkgDtls) : null)
      setSpinner(false);
    } else {
      setSpinner(false);
    }

    // if(insData.respCode === 200){

    //   // setInsPackages(insData.respData.insuranceData)
    //   setInsPackages(insData.respData.insuranceData.filter(a=>a.insuranceId===data.respData.insurancePkgId)[0])
    // }

  };

  const getOriginDestinationAddress = async (originId, destId) => {
    if (originId) {
      let addressDataResp = await CallApiGet(
        "get-org-dest-address?originId=" + originId + "&destId=" + destId
      );

      if (addressDataResp.respCode === 200) {
        setOrgDestAddrs(addressDataResp.respData[0]);
      }
    }
  };
  const reloadShipmentData = () => {
    getShipmetDtls("orders", { shipmentId: shipId });
  };
  const filterShipmentDtls = (shipmentDtls) => {
    if (shipmentDtls?.shipmentVas?.length > 0) {
      let shipmentVasDataList = shipmentDtls?.shipmentVas;
      let slodg = true;
      let scollect = true;
      shipmentVasDataList.forEach((e) => {
        if (e.serviceId === 9) {
          slodg = false;
          setDispLodgin(false);
        } else if (e.serviceId === 148) {
          scollect = false;
          setDispSCollect(false);
        }
      });
      // console.log(shipmentVasDataList);
      setDispAddress(scollect || slodg ? true : false);
      setDispAddress(true);
    } else {
      setDispAddress(true);
      if (shipmentDtls.orderTypeCode === "FTL") {
        setDispSCollect(false);
        setDispLodgin(false);
      }
    }
  };
  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    getShipmetDtls("orders", { shipmentId: searchParams.get("data") });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const getDeliveryEventLog = async (wb) => {
    document.body.click();
    setSpinner(true);
    let deliveryEventLogData = await deliveryEventlog(wb);
    setSpinner(false);
    if (deliveryEventLogData.respCode === 200) {
      let data = deliveryEventLogData.respData;
      setDeliveryEventLog(data);
    } else if (deliveryEventLogData.respCode === 500) {
      showAlert({
        isSuccess: false,
        title: "",
        description: deliveryEventLogData.respMessage,
      });
    } else {
      setDeliveryEventLog([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  // const showClaimForm = (data) => {
  //   if (!data.hasOwnProperty("shipmentId") || !data.shipmentId) {
  //     data["shipmentId"] = data?.orderId;
  //     delete data["shipmentid"];
  //   }
  //   document.body.click();
  //   getShipmetDtls("claim", data);
  // };

  const showClaimForm = (argData) => {
    let encoded = Buffer.from(JSON.stringify({
      claimId: 0,
      orderId: argData?.orderId,
      palletId: argData?.palletId,
      waybillNo: argData?.waybillNo
    })).toString("base64");
    //window.open(`/raiseClaim?data=${encoded}`, "_blank");
    navigate(`/raiseClaim?data=${encoded}`);
  }
  const cancelClaimForm = () => {
    setClaimOrderId(null);
    setClaimPalletId(null);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        {!!message && (
          <div id="errorMsg" className="alert alert-danger" role="alert">
            {message}
          </div>
        )}
        <div className="row mt-3">
          <div className="col-xxl-8 col-lg-9 col-md-12">
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Order Details
              </div>
              <div class="p-2">
                <div class="d-flex justify-content-between align-item-center">
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Order Number</div>
                    <div class="theme-blue-txt">{shipmentDtls?.orderNo}</div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Booking Date</div>
                    <div class="theme-blue-txt">
                      {moment(shipmentDtls?.bookedOn).format(
                        DATE_TIME_FORMAT_MOMENT
                      )}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Shipment Date</div>
                    <div class="theme-blue-txt">
                      {moment(shipmentDtls?.shipmentDate).format(
                        DATE_FORMAT_MOMENT
                      )}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Estimated Delivery Date</div>
                    <div class="theme-blue-txt">
                      {moment(shipmentDtls?.estDeliveryDate).format(
                        DATE_FORMAT_MOMENT
                      )}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-start align-item-center">
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Origin</div>
                    <div class="theme-blue-txt">
                      <span className="text-info">
                        {GetSvgIcon("BiGeoAltFillDanger")}
                      </span>{" "}
                      {shipmentDtls.origin.sbuCode}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Destination</div>
                    <div class="theme-blue-txt">
                      <span className="text-success">
                        {GetSvgIcon("BiGeoAltFillSuccess")}
                      </span>{" "}
                      {shipmentDtls.destination.sbuCode}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Content Type</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.packingType !== "null"
                        ? shipmentDtls.packingType
                        : ""}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Content Description</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.packageDesc !== "null"
                        ? shipmentDtls.packageDesc
                        : "N/A"}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-start align-item-center">
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Service Name</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.slaName !== "null"
                        ? shipmentDtls.slaName
                        : "N/A"}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Pallets</div>
                    <div class="theme-blue-txt">{shipmentDtls?.pallets}</div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Booked Pallet Space</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.initPalletSpace !== "null"
                        ? shipmentDtls.initPalletSpace
                        : "N/A"}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Actual Pallet Space</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.palletSpaceRequired !== "null"
                        ? shipmentDtls.palletSpaceRequired
                        : "N/A"}
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-start align-item-center">
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Value Added Services</div>
                    <div>
                      {shipmentDtls.shipmentVas.map((vas) => (
                        <span className="theme-blue-txt">
                          {vas.serviceId === 10
                            ? "Packaging : " + vas.pallets + " pallet(s) "
                            : vas.serviceId === 149 && vas.svcInfo1 === "P"
                              ? "Pickup Handling : " + vas.pallets + " pallet(s) "
                              : vas.serviceId === 149 && vas.svcInfo1 === "D"
                                ? "Delivery Handling : " + vas.pallets + " pallet(s) "
                                : ""}
                        </span>
                      ))}
                    </div>

                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Delivery Order Return</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.doReturn === true
                        ? "Yes" : "No"
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!!insPackages && <div className="bg-white border border-2 rounded-2 mb-3">
              <div className="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Protection Info
              </div>
              <div className="p-2">
                <div className="d-flex justify-content-between align-item-center">
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Package Id</div>
                    <div className="theme-blue-txt">
                      {insPackages !== null
                        ? insPackages.package_id
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Coverage Upto</div>
                    <div className="theme-blue-txt">
                      {insPackages !== null
                        ? "RM " + insPackages.coverage
                        : "N/A"}
                    </div>
                  </div>
                  <div className="col grd-bg p-2 p my-2 rounded-3">
                    <div className="fw-bold">Insurance Fee</div>
                    <div className="theme-blue-txt">
                      {insPackages !== null
                        ? "RM " + insPackages.charge
                        : "N/A"}
                    </div>
                  </div>
                  <div class="col-3 grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Insurance Declared Price</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls.insDeclaredPrice !== "null"
                        ? "RM " + shipmentDtls.insDeclaredPrice
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            <div class="bg-white border border-2 rounded-2 mb-3">
              <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                Customer Details
              </div>
              <div class="p-2">
                <div class="d-flex justify-content-between align-item-center">
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Customer Name</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls?.customerDtls?.custName}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Account Number</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls?.customerDtls?.accountCode}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Phone</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls?.customerDtls?.custPhone}
                    </div>
                  </div>
                  <div class="col grd-bg p-2 p my-2 rounded-3">
                    <div class="fw-bold">Address</div>
                    <div class="theme-blue-txt">
                      {shipmentDtls?.customerDtls?.custAddr +
                        "," +
                        shipmentDtls?.customerDtls?.custCity +
                        "," +
                        shipmentDtls?.customerDtls?.custState}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {dispAddress && (
              <div class="bg-white border border-2 rounded-2 mb-3">
                <div class="tab-panel-heading fw-bold p-2 font-18 rounded-top border-3">
                  Address
                </div>
              
                <div className="d-flex justify-content-between align-item-center">
                  
                    <div className="col grd-bg p-2 p my-2 rounded-3 ms-2">
                      <div className="col">
                        <div className="fw-bold">Sender Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPerson(false)}
                          </span>
                          {shipmentDtls?.senderAddress?.name}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>
                          {shipmentDtls?.senderAddress?.phone?.substr(0, 3) +
                            "-" +
                            shipmentDtls?.senderAddress?.phone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {shipmentDtls?.senderAddress?.address +
                              ", " +
                              shipmentDtls?.senderAddress?.city +
                              ", " +
                              shipmentDtls?.senderAddress?.state +
                              ", " +
                              shipmentDtls?.senderAddress?.postcode}
                          </div>
                        </div>
                      </div>
                    </div>
                  
                 
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="col">
                        <div className="fw-bold">Recipient Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPerson(false)}
                          </span>
                          {shipmentDtls?.recipientAddress?.name}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt  me-2">
                            {GetSvgPhone(false)}
                          </span>{" "}
                          {shipmentDtls?.recipientAddress?.phone?.substr(0, 3) +
                            "-" +
                            shipmentDtls?.recipientAddress?.phone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {shipmentDtls?.recipientAddress?.address +
                              ", " +
                              shipmentDtls?.recipientAddress?.city +
                              ", " +
                              shipmentDtls?.recipientAddress?.state +
                              ", " +
                              shipmentDtls?.recipientAddress?.postcode}
                          </div>
                        </div>
                      </div>
                    </div>
                  
                </div>


                <div className="d-flex justify-content-between align-item-center">
                  {dispLodgin === true ? (
                    <div className="col grd-bg p-2 p my-2 rounded-3 ms-2">
                      <div className="col">
                        <div className="fw-bold">Lodge in Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgIcon("LocPin")}
                          </span>
                          {orgDestAddrs?.origingName}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>{" "}
                          {orgDestAddrs?.origingPhone?.substr(0, 3) +
                            "-" +
                            orgDestAddrs?.origingPhone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {orgDestAddrs?.origingAddress +
                              ", " +
                              orgDestAddrs?.origingCity +
                              ", " +
                              orgDestAddrs?.origingState +
                              ", " +
                              orgDestAddrs?.originPostCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col grd-bg p-2 p my-2 rounded-3 ms-2">
                      <div className="col">
                        <div className="fw-bold">Pickup Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPerson(false)}
                          </span>
                          {shipmentDtls?.pickupAddress?.name}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>
                          {shipmentDtls?.pickupAddress?.phone?.substr(0, 3) +
                            "-" +
                            shipmentDtls?.pickupAddress?.phone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {shipmentDtls?.pickupAddress?.address +
                              ", " +
                              shipmentDtls?.pickupAddress?.city +
                              ", " +
                              shipmentDtls?.pickupAddress?.state +
                              ", " +
                              shipmentDtls?.pickupAddress?.postcode}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dispSCollect === true ? (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="col">
                        <div className="fw-bold">Self Collect Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgIcon("LocPin")}
                          </span>
                          {orgDestAddrs?.destinationName}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPhone(false)}
                          </span>{" "}
                          {orgDestAddrs?.destinationPhone?.substr(0, 3) +
                            "-" +
                            orgDestAddrs?.destinationPhone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {orgDestAddrs?.destinationAddress +
                              ", " +
                              orgDestAddrs?.destinationCity +
                              ", " +
                              orgDestAddrs?.destinationState +
                              ", " +
                              orgDestAddrs?.destinationPostCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col grd-bg p-2 p my-2 rounded-3">
                      <div className="col">
                        <div className="fw-bold">Delivery Address:</div>
                        <div className="theme-blue-txt">
                          <span className="theme-blue-txt me-2">
                            {GetSvgPerson(false)}
                          </span>
                          {shipmentDtls?.deliveryAddress?.name}
                        </div>
                        <div className="theme-blue-txt font-12 mt-2">
                          <span className="theme-blue-txt  me-2">
                            {GetSvgPhone(false)}
                          </span>{" "}
                          {shipmentDtls?.deliveryAddress?.phone?.substr(0, 3) +
                            "-" +
                            shipmentDtls?.deliveryAddress?.phone?.substr(3)}
                        </div>
                        <div className="theme-blue-txt d-flex font-12 mt-2">
                          <span className="theme-blue-txt me-2">
                            {GetSvgBuilding(false)}
                          </span>
                          <div className="font-12">
                            {shipmentDtls?.deliveryAddress?.address +
                              ", " +
                              shipmentDtls?.deliveryAddress?.city +
                              ", " +
                              shipmentDtls?.deliveryAddress?.state +
                              ", " +
                              shipmentDtls?.deliveryAddress?.postcode}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

              </div>
            )}

            {!!shipmentDtls &&
              !!shipmentDtls?.shipmentId &&
              shipmentDtls?.shipmentId !== "" && (
                <>
                  <CustomerOrderAttachment
                    shipmentDtls={shipmentDtls}
                    setSpinner={setSpinner}
                    reloadShipmentData={reloadShipmentData}
                    message={message}
                    setMessage={setMessage}
                    resetMessage={resetMessage}
                    showAlert={showAlert}
                  />
                </>
              )}
            {!!shipmentDtls &&
              !!shipmentDtls?.shipmentId &&
              shipmentDtls?.shipmentId !== "" && (
                <>
                  <OrderWaybillList
                    shipmentPallets={shipmentDtls?.shipmentPallets}
                    showClaimForm={showClaimForm}
                    getDeliveryEventLog={getDeliveryEventLog}
                    orderStatus={shipmentDtls.status}
                    setSpinner={setSpinner}
                  />
                </>
              )}
          </div>
          {!!shipmentDtls &&
            !!shipmentDtls?.shipmentId &&
            shipmentDtls?.shipmentId !== "" &&
            shipmentDtls.shipmentBilling.length > 0 && (
              <div className="col-xxl-4 col-lg-3 col-md-12">
                <CustomerOrderBillingDetails
                  billingDetails={shipmentDtls.shipmentBilling}
                  orderData={shipmentDtls}
                />
              </div>
            )}
        </div>
        <DeliveryEventLog data={deliveryEventLog} />
      </Layout>
      {!!claimOrderId && !!claimPalletId && (
        <ClaimAddEdit
          claimId={claimId}
          claimDetails={claimDetails}
          claimOrderId={claimOrderId}
          claimPalletId={claimPalletId}
          cancelClaimForm={cancelClaimForm}
          showAlert={showAlert}
          setSpinner={setSpinner}
        />
      )}
    </>
  );
};

export default CustomerOrderDetails;

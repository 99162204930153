import fileDownload from "js-file-download";
import { CallApiPost } from "./serviceApi";
import { Buffer } from "buffer";

export const DownloadFile = async (argFileName, onError, onSuccess) => {
  const model = {
    fileName: argFileName,
    fileType: "",
    fileContent: "",
  };
  const resp = await CallApiPost("GetFromFileStore", model);
  console.log(resp);
  switch (resp.respCode) {
    case 0:
      let fileBlob1 = Buffer.from(resp.respData, "base64");
      let fileBlob;
      fileBlob = argFileName.startsWith("DO_M")
        ? fileBlob1
        : Buffer.from(fileBlob1.toString(), "base64");
      fileDownload(fileBlob, argFileName);
      if (typeof onSuccess === "function") onSuccess();
      break;
    case 9:
      //onError();
      break;
    default:
      break;
  }
};

export const UploadFile = async (
  argFileName,
  argFileType,
  argFileData,
  onError
) => {
  const model = {
    fileName: argFileName,
    fileType: argFileType,
    fileContent: argFileData,
  };
  const resp = await CallApiPost("SaveToFileStore", model);
  switch (resp.respCode) {
    case 0:
      console.log("===SUCCESS===");
      console.log(resp.respData);
      break;
    case 9:
      onError();
      break;
    default:
      break;
  }
};

export const GetBase64Img = async (argFileName, onError) => {
  const model = {
    fileName: argFileName,
    fileType: "",
    fileContent: "",
  };
  const resp = await CallApiPost("GetFromFileStore", model);

  let fileBlob;
  switch (resp.respCode) {
    case 0:
      fileBlob = Buffer.from(resp.respData, "base64");
      break;
    case 9:
      // onError();
      fileBlob = "";
      break;
    default:
      break;
  }
  return fileBlob;
};

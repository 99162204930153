import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import moment from "moment";
import { DATE_FORMAT_MOMENT } from "../../Helper/Constants";
import ModalCustom from "../../Layout/ModalCustom";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import BankSlipDetailes from "./BankSlipDetailes";
import BankSlipList from "./BankSlipList";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import { useSearchParams } from "react-router-dom";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initialModalConfig = {
  show: false,
  title: "",
  size: "sm",
  data: [],
};

const BankSlipMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [openModalConfig, setOpenModalConfig] = useState(
    Object.assign({}, initialModalConfig)
  );
  const [bsList, setBsList] = useState([]);
  const [isAppovedClicked, setIsAppovedClicked] = useState(false);
  const [selectedBs, setSelectedBs] = useState();
  let selectedBs2;
  const [footerButton, setFooterButton] = useState();
  const [invoiceList, setInvoiceList] = useState([]);
  const [searchParams] = useSearchParams();
  const [creditNoteApplied, setCreditNoteApplied] = useState(false);
  const [finalAmount, setFinalAmount] = useState();
  const [isRemarkBlank, setIsRemarkBlank] = useState(false);
  const custConditionalStyle2 = [
    {
      when: (row) => row.statusId === 811,
      style: {
        color: "#00964b",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.statusId === 812,
      style: {
        color: "red",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.statusId === 810,
      style: {
        color: "#f5c30f",
        fontWeight: 500,
      },
    },
  ];
  const bsCols = [
    {
      name: "Upload Date",
      selector: (row) =>
        !!row?.date ? moment(row?.date).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
      // omit: hideColums,
      //
    },
    {
      name: "Customer",
      selector: (row) => row?.custName,
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Invoices",
      selector: (row) => row?.invoices,
      cell: (row) => (
        <>
          <pre className="box-text p-1">
            <span className="d-inline-block">{row?.invoices}</span>
          </pre>
        </>
      ),
      // sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      conditionalCellStyles: custConditionalStyle2,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"inv-" + row?.ref_no + row?.trans_date}
            className="me-3"
            onClick={() => handelActionButtonClick(row)}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"inv-" + row.ref_no} content="Action" place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  const invCols = [
    {
      name: "Invoice Date",
      selector: (row) =>
        !!row?.invDate ? moment(row?.invDate).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      // filterable: true,
      // omit: hideColums,
      //
    },
    {
      name: "Invoice No.",
      selector: (row) => row?.invNo,
      sortable: true,
      // filterable: true,
      // omit: hideColums,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.amount,
      sortable: true,
      // filterable: true,
      // omit: hideColums,
    },
  ];
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    // getCustCn();
  };

  const openModal = (argData) => {
    setOpenModalConfig({
      show: true,
      title: "Bank Slip",
      size: "lg",
      data: argData,
    });
  };

  const handleModalClose = () => {
    setOpenModalConfig({
      size: "sm",
      show: false,
      title: "",
    });
    getDynamicBtn(false);
    setIsAppovedClicked(false);
    setSelectedBs();
  };

  const handelActionButtonClick = async (data) => {
    setSpinner(true);
    let bsDtlsResp = await CallApiGet(
      "getBankSlipDetails?bankSlipId=" + data.id + "&custId=" + data.custId
    );
    if (bsDtlsResp.respCode === 200) {
      if (bsDtlsResp.respData.length > 0) {
        setSelectedBs(bsDtlsResp.respData[0]);
        selectedBs2 = bsDtlsResp.respData[0];
        setInvoiceList(JSON.parse(bsDtlsResp.respData[0]?.invoice));
        setFinalAmount(bsDtlsResp.respData[0].totalInvoiceAmount);
        getDynamicBtn(false, bsDtlsResp.respData[0]);
      }
    }
    setSpinner(false);
    openModal();
  };

  useEffect(() => {
    loadBsList();

    if (searchParams.get("bsId"))
      handelActionButtonClick({
        id: searchParams.get("bsId"),
        custId: searchParams.get("custId"),
      });
  }, []);

  const loadBsList = async () => {
    setSpinner(true);
    let bsListResp = await CallApiGet("getAllBankSlip");
    if (bsListResp.respCode === 200) {
      if (bsListResp.respData.length > 0) setBsList(bsListResp.respData);
    }
    setSpinner(false);
  };

  const onApproveClick = () => {
    setIsAppovedClicked(true);
    getDynamicBtn(true, selectedBs2);
  };

  const onRejectClick = async () => {
    setSpinner(true);
    handleModalClose();
    let resp = await CallApiGet("rejectBankSlip?bsId=" + selectedBs2.id);
    if (resp.respCode === 200) {
      openPopup(true, "Success", "Bank Slip Rejected");
      loadBsList();
    } else {
      handleApiResponse(resp);
    }
    setSpinner(false);
  };
  const payByCreditVal = (checked) => {
    setCreditNoteApplied(checked);
    if (checked) {
      let amt = selectedBs.totalInvoiceAmount - selectedBs.avlCnAmount;
      if (amt > 0) {
        setFinalAmount(amt.toFixed(2));
      } else setFinalAmount(0);
    } else {
      setFinalAmount(selectedBs.totalInvoiceAmount);
    }
  };

  const onPayClick = async (id) => {
    const bsId = selectedBs2?.id;
    let remark = document.getElementById("pay-remark").value;
    // let remark = payRemark;

    setIsRemarkBlank(!remark);
    if (!remark) {
      return;
    } else {
      const payload = {
        bsId: bsId,
        remark: remark,
        cnUsed: document.getElementById("pay-by-cn").checked,
      };
      handleModalClose();

      let resp = await CallApiPost("approveBankSlip", payload);
      if (resp.respCode === 200) {
        openPopup(
          true,
          "Success",
          "Bank Slip Approved and Payment Completed Successfully"
        );
        loadBsList();
      } else {
        handleApiResponse(resp);
      }

      setSpinner(false);
    }
  };

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const approveBtn = (
    <>
      <button
        type="button"
        className="btn btn-danger me-2"
        onClick={onRejectClick}
      >
        Reject
      </button>
      <button
        type="button"
        className="btn btn-primary theme-violet"
        onClick={onApproveClick}
      >
        Approve
      </button>
    </>
  );

  const payBtn = (
    <>
      <button
        type="button"
        className="btn btn-primary theme-violet"
        onClick={onPayClick}
      >
        paid
      </button>
    </>
  );

  const getDynamicBtn = (isApproved, data) => {
    // if (selectedBs?.statusId === 810) {
    if (!!data && data.statusId === 810 && isApproved) {
      setFooterButton(payBtn);
    } else if (!!data && data.statusId === 810) {
      setFooterButton(approveBtn);
    } else {
      setFooterButton("");
    }
    // }
  };
  const onSuccess = (title, body) => {
    console.log("title", title, "body", body);
  };

  const onError = (errMsg) => {
    console.log("errMsg", errMsg);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={closePopup}
      >
        <BankSlipList bsColumns={bsCols} bsList={bsList} />
      </Layout>

      <ModalCustom
        confirmAlertConfig={openModalConfig}
        hideConfirmAlert={handleModalClose}
        modalDynamicButtons={footerButton}
        modalStyle={{ titelStyle: "theme-label fs-6 " }}
      >
        <BankSlipDetailes
          selectedBs={selectedBs}
          isAppovedClicked={isAppovedClicked}
          invCols={invCols}
          invoiceList={invoiceList}
          finalAmount={finalAmount}
          payByCreditVal={payByCreditVal}
          isRemarkBlank={isRemarkBlank}
          setIsRemarkBlank={setIsRemarkBlank}
        />
      </ModalCustom>
    </>
  );
};

export default BankSlipMain;

import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../../Layout/Layout";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import AdvertisementList from "./AdvertisementList";
import { Button } from "react-bootstrap";
import GetSvgIcon from "../../../images/svglist";
import { AdvertisementAddEdit } from "./AdvertisementAddEdit";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initAdData = {
  id: 0,
  title: "",
  description: "",
  validFrom: null,
  validTo: null,
  active: true,
  attachment: {
    attchId: 0,
    photo: null,
    photoFileName: null,
    photoFileType: 0,
    photoBucketUrl: null,
  },
};
const initAdvModalConfig = {
  show: false,
  title: "",
};

export const AdvertisementMain = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [adList, setAdList] = useState([]);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [adData, setAdData] = useState(Object.assign({}, initAdData));
  const [advModalConfig, setAdvModalConfig] = useState(
    Object.assign({}, initAdvModalConfig)
  );
  const closeAdvMOdal = () => {
    setAdData(Object.assign({}, initAdData));
    setAdvModalConfig(Object.assign({}, initAdvModalConfig));
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [advId, setAdvId] = useState();

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };

  const openAdModal = (argData, argTitle) => {
    setAdData(Object.assign({}, argData));
    setAdvModalConfig({
      show: true,
      title: argTitle,
    });
  };

  useEffect(() => {
    fetchAdvertisements();
  }, []);
  const closePopUp = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchAdvertisements();
  };
  const fetchAdvertisements = async () => {
    setShowSpinner(true);
    const uri = "Advertisment";
    const resp = await CallApiGet(uri);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setAdList(resp?.respData);
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to load advertisements. Please try after sometime!!";
      }
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };
  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);

        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };
  const handleActiveDeactive = (row) => {
    if (row.active) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate Advertisement <b>{row.title}</b>
        </>
      );
      setAlertTitle("Deactivate Advertisement");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate Advertisement <b>{row.title}</b>
        </>
      );

      setAlertTitle("Activate Advertisement");
    }
    setAdvId(row.id);
    toggleAlert(true);
  };

  const handleEditClick = (rowData) => {
    let locData = Object.assign({}, rowData);
    locData.attachment = JSON.parse(locData?.attachment);
    openAdModal(locData, "Update ADVERTISEMENT");
  };
  const activeInactive = async () => {
    const uri = "ActiveDeactiveAdv?id=" + advId;
    let resp = await CallApiPost(uri);
    setShowAlert(false);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "", "Advertisement status updated successfully !!!");
        break;
      case 409:
        openPopup(false, "Error!", resp.respMessage);
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  return (
    <Layout
      spinnershow={showSpinner}
      alertConfig={alertConfig}
      hideAlert={closePopUp}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">Home Advertisement</div>
        </div>
        <div className="float-end d-flex align-items-center">
          <Button
            className="theme-violet"
            onClick={() => openAdModal(initAdData, "ADD NEW ADVERTISEMENT")}
          >
            {GetSvgIcon("PlusCircle")} Add
          </Button>
        </div>
      </div>
      <AdvertisementList
        tableData={adList}
        handleEditClick={handleEditClick}
        handleActiveDeactive={handleActiveDeactive}
      ></AdvertisementList>
      <AdvertisementAddEdit
        handleApiResponse={handleApiResponse}
        config={advModalConfig}
        handleClose={closeAdvMOdal}
        advState={adData}
      ></AdvertisementAddEdit>
      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={activeInactive}
        />
      )}
    </Layout>
  );
};

import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import GetSvgIcon from "../../images/svglist";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import Header from "../Layout/Header";
import Popup from "../Layout/Popup";
import AddUser from "./AddUser";
import { initValUser } from "./intiValUser";
import ListUser from "./ListUser";
import EditUser from "./EditUser";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from '../AuthUtils/AuthUtils';
import DriverModal from './DriverModal';
import Spinner from "../Layout/Spinner";
import { Tooltip } from "react-tooltip";

const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};

const initDriver = {
  userId: 0,
  name: "",
  phone: "",
  email: "",
  profilePhoto: initPhotoFile,
  driverLicense: "",
  driverLicenseExpiryDate: null,
  driverLicenseImage: initPhotoFile,
  partnerTypeId: 137, // Partner_Personal
  partnerType: 'Partner_Personal',
  phoneCode: "+60"
}

function UserManagement() {
  const [showParent, setShowParent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [userMgm, setUserMgm] = useState(initValUser);
  const [staffUser, setStaffUser] = useState([]);
  const [stateCity, setStateCity] = useState();
  const [staffDriver, setStaffDriver] = useState([]);
  const [driverDetails, setDriverDetails] = useState(initDriver);
  const isPartner = getUserInfo("userType") === "138";
  const [sbuDropdownList, setSbuDropdownList] = useState([]);
  let navigate = useNavigate();
  const countryCodes = [
    { label: "+60", value: "+60" },
    { label: "+62", value: "+62" },
    { label: "+65", value: "+65" },
    { label: "+66", value: "+66" },
    { label: "+84", value: "+84" },
    { label: "+86", value: "+86" },
  ];
  const [sbuList, setSbuList] = useState([]);
  const [sbuTypeDtls, setSbuTypeDtls] = useState([
    {
      id: 0,
      code: "",
    },
  ]);
  const [roleDtls, setRoleDtls] = useState([
    {
      id: 0,
      roleName: "",
    },
  ]);
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    callAllService();
  }, []);

  const callAllService = () => {
    setShowParent(true);
    if (isPartner) {
      fetchPartnerDriverList();
    } else {
      fetchUserTypes();
      getRoleList();
      getSBUTypeList()
      getSBUList();
      fetchUserInfoList();
      fetchAllStaffUserList();
      fetchStatesAndCityList();
    }
    setShowParent(false);
  };
  const [userType, setUserType] = useState([
    {
      id: 0,
      code: "",
    },
  ]);

  const [userInfo, setUserInfo] = useState([]);
  const getRoleList = async () => {
    let roleArr = [];
    let data = await CallApiGet("getRoleList");
    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        if (element.roleName !== "ISR") {
          roleArr.push({
            value: element.id,
            label: element.roleName,
          });
        }
      });
      setRoleDtls(roleArr);
    }
  };
  const getSBUTypeList = async () => {
    let sbuTypeArr = [];
    let data = await CallApiPost("SysOptionGetByCode", ["SBU_TYPE"]);
    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        sbuTypeArr.push({
          value: element.id,
          label: element.optionValue,
        });
      });
      setSbuTypeDtls(sbuTypeArr);
    }
  };
  const getSBUList = async () => {
    let data = await CallApiGet("get-sbu-for-dropdown");
    if (data.respCode === 200) {
      let sbuArr = [];
      setSbuList(data.respData);
      data.respData.forEach((item) => {
        sbuArr.push({
          value: item.id,
          label: item.code,
        });
      })
      setSbuDropdownList(sbuArr);
    }
  };
  const fetchUserTypes = async () => {
    let userArr = [];
    let data = await CallApiPost("SysOptionGetByCode", ["USER_TYPE"]);
    data.respData.forEach((element) => {
      if (
        element.optionValue !== "Linehaul_ISR" &&
        element.optionValue !== "Customer_Personal" &&
        element.optionValue !== "Customer_Business" &&
        element.optionValue !== "Partner_Personal" &&
        element.optionValue !== "Partner_Business" &&
        element.optionValue !== "System_Admin"
      ) {
        userArr.push({
          value: element.id,
          label: element.optionValue,
        });
      }
    });
    setUserType(userArr);
  };
  const fetchUserInfoList = async () => {
    let infoArr = [];
    let data = await CallApiGet("allUserTypeInfoList");
    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        infoArr.push({
          infoId: element.id,
          documentType: element.optionValue,
          docNumber: "",
          expiredOn: "",
          remarks: "",
          infoAttachment: "",
        });
      });
      setUserInfo(infoArr);
    }
  };

  const fetchAllStaffUserList = async () => {
    let data = await CallApiGet("getAllStaffUserList");
    if (data.respCode === 401) {
      setStaffUser([]);
    } else setStaffUser(data.respData);
  };

  const fetchPartnerDriverList = async () => {
    let data = await CallApiPost("getPartnerDriverList", {});
    if (data.respCode === 401) {
      setStaffDriver([]);
    } else setStaffDriver(data.respData);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.userName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Role",
      selector: (row) => row.roleNames,
      sortable: true,
      filterable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.roleName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
      width:'20%'
    },
    {
      name: "Account Code",
      selector: (row) => row.accountCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "SBU",
      selector: (row) => row.sbuName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.active === true ? "Active" : "Inactive"),
      sortable: true,
      filterable: true,
    },
    {
      cell: (row) => (
        <>
        <div
          className="me-3" 
          data-tooltip-id={"edit-" + row.id}
          data-bs-toggle="modal"
          data-bs-target="#edit-modal-user"
          onClick={() => getStaffDetailsById(row)}
        >
          {GetSvgIcon("EditPencil")}
        </div>
        <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const partColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.active === true ? "Active" : "Inactive"),
      sortable: true,
      filterable: true,
    },
    {
      cell: (row) => (
        <div
          className="me-3"
          data-bs-toggle="modal"
          data-bs-target="#add-modal-driver"
          onClick={() => setDriverDetailsData(row)}
        >
          {GetSvgIcon("EditPencil")}
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const setDriverDetailsData = (data) => {
    let driverData = { ...data, partnerTypeId: 137, partnerType: 'Partner_Personal' } // 137: Partner_Personal
    if (driverData.phone) {
      let phone = driverData.phone;
      driverData.phoneCode = phone.substr(0, 3);
      driverData.phone = phone.substr(3);
    }
    setDriverDetails(driverData);
  }

  const getStaffDetailsById = async (data) => {
    getStaffInfoDetailsByStaffId(data);
    data.userRoleId = data.userRoleId.map((e) => parseInt(e));
    let sbuData = sbuList.filter((a) => {
      return a.id === parseInt(data.sbuId);
    });
    data.sbuTypeId = sbuData[0].sbuType;
    setUserMgm(data);
  };
  const callback = () => {
    fetchAllStaffUserList();
  };
  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
  };
  const onFailure = (msg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(msg);
    modalRef.current.click();
  };
  const onError = (error) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(error || "Something went wrong!!!");
    modalRef.current.click();
  };

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
  };

  const getStaffInfoDetailsByStaffId = async (data) => {
    const uri = "getStaffDetailsById";
    let resp = await CallApiPost(uri, data);
    let proflieData = resp.respData;
    setUserDetails(proflieData);
    //setValue("userDetails", proflieData);
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Staff Management</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-start me-2">
                  {isPartner ?
                    <button
                      className="btn btn-outline-secondary theme-violet text-nowrap"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-modal-driver"
                      onClick={() => setDriverDetailsData(initDriver)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>{" "}
                      Add Driver
                    </button> :
                    <button
                      className="btn btn-outline-secondary theme-violet text-nowrap"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-modal-user"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>{" "}
                      New User
                    </button>}
                </div>
                <div className="float-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <ListUser staffUserList={isPartner ? staffDriver : staffUser} columns={isPartner ? partColumns : columns} />
            <AddUser
              userTypeList={userType}
              sbuTypeDtlsList={sbuTypeDtls}
              // sbuDtlsList={sbuDtls}
              sbuList={sbuList}
              roleDtlsList={roleDtls}
              userInfoList={userInfo}
              custmer={userMgm}
              setUserMgm={setUserMgm}
              countryCodes={countryCodes}
              stateList={stateData}
              cityList={cityData}
              stateCityData={stateCity}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onError={onError}
              sbuDropdownList={sbuDropdownList}
            />
            <EditUser
              userMgm={userMgm}
              setUserMgm={setUserMgm}
              userTypeList={userType}
              sbuList={sbuList}
              sbuTypeDtlsList={sbuTypeDtls}
              // sbuTypeDtlsList={sbuTypeDtls}
              //sbuDtlsList={sbuDtls}
              roleDtlsList={roleDtls}
              userInfoList={userInfo}
              countryCodes={countryCodes}
              stateList={stateData}
              cityList={cityData}
              stateCityData={stateCity}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onError={onError}
              userDetailsData={userDetails}
              sbuDropdownList={sbuDropdownList}
            />
            <DriverModal
              driverDetails={driverDetails}
              countryCodes={countryCodes}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onError={onError}
              fetchPartnerStaff={fetchPartnerDriverList}
            />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={showParent} />
    </div>
  );
}

export default UserManagement;

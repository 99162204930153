import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCodeGenerator from "./QRCodeGenerator";
import "./pdf.css";
import BarCodeGenerator from "./BarCodeGenerator";
import { CallApiGet } from "./serviceApi";
import { initValShipmentDtls } from "../OrderManagement/initValShipmentDtls";
import logo from "../../images/LinehaulLogo.png";
import moment from "moment";
import { DATE_FORMAT_MOMENT } from "./Constants";

export default function WayBillGenerator() {
  const { Buffer } = require("buffer");
  const [waybills, setWayBills] = useState([]);
  const [shipmentDtls, setShipmentDtls] = useState(JSON.parse(JSON.stringify(initValShipmentDtls)));
  const [selfLod, setSelfLod] = useState(false);
  const [selfCollect, setSelfCollect] = useState(false);
  let params = useParams();
  const encodedData = params?.data;
  const decodedBuffer = Buffer.from(encodedData, "base64");
  const decodedString = decodedBuffer.toString("utf-8");
  const decodedData = JSON.parse(decodedString);
  const [stopsList,setStopList]=useState([])
  useEffect(() => {
    getShipmetDtls(decodedData.shipmentId);
  }, []);

  const getShipmetDtls = async (shipmentId) => {
    let incomeWayBillList = decodedData.waybillNo;
    let data = await CallApiGet("getShipmentDetailsById/" + shipmentId);
    if (data.respCode === 200) {
      
      setShipmentDtls(data.respData);
      let stopArr=[];
      Object.keys(data.respData.shipmentPallets[0].stops).map((element,index) => {
        if( index!==0 && index !== Object.keys(data.respData.shipmentPallets[0].stops).length-1){
          if(data.respData.shipmentPallets[0].stops[index]!==null  && data.respData.shipmentPallets[0].stops[index]!==data.respData.destination.sbuCode ){
          stopArr.push(data.respData.shipmentPallets[0].stops[index])
        stopArr.push("&")
          }
        }
    });
    stopArr.pop()
    setStopList(stopArr)

      let paletsList = data.respData.shipmentPallets;
      let wayArr = paletsList.filter((paletData) =>
        incomeWayBillList.includes(paletData.waybillNo)
      );
      setWayBills(wayArr);

      let serviceList = data.respData.shipmentVas;
      serviceList.forEach((e) => {
        if (e.serviceId === 9) setSelfLod(true);

        if (e.serviceId === 148) setSelfCollect(true);
      });
    }
  };
  return (
    <>
      <div className="clearfix"></div>
      <div className="element-to-print" style={{ width: "100%" }}>
        
        {waybills &&
          waybills.map((data, index) => (
            <table className="w-100">
              <tr>
                <td className="w-50">
                  <div className="w-100" style={{ border: "5px solid #000" }}>
                    <div className="col-md-12">
                      <table className="table mb-0 w-100">
                        <tr>
                          <td className="text-start">
                            <img src={logo} width="120" />
                          </td>
                          <td className="w-50">
                            <table style={{"font-size":'12px'}}>
                              <tr>
                                <td> Order Number : </td>
                                <td>
                                  <strong>{shipmentDtls?.orderNo}</strong>
                                </td>
                              </tr>
                              <tr>
                              <td> Waybill Number : </td>
                                <td>
                                  <strong>{data.waybillNo}</strong>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td className="text-end">
                            <div className="canva-qr">
                            <QRCodeGenerator data={data.waybillNo} />
                            </div>
                            
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <table className="border-all">
                            <tr>
                                <td className="w-25">Origin : {!!shipmentDtls?.origin?.sbuCode?shipmentDtls?.origin?.sbuCode:"-NA-"} &nbsp;</td>
                                <td>
                                  <strong>{Object.keys(data?.stops).length>1 && (data?.stops?.[1] !== shipmentDtls?.origin?.sbuCode  && data?.stops?.[1] !== shipmentDtls?.destination?.sbuCode) ? data?.stops?.[1] : "NA"}</strong>
                                </td>
                                <td className="w-25">
                                  <strong>{Object.keys(data?.stops).length>2 && (data?.stops?.[2] !== shipmentDtls?.origin?.sbuCode  && data?.stops?.[2] !== shipmentDtls?.destination?.sbuCode) && data?.stops?.[2]!==null ? data?.stops?.[2] : "NA"}</strong>
                                </td>
                                <td className="w-25"><strong>{data?.stops?.[3] && data?.stops?.[3] !== shipmentDtls?.destination?.sbuCode && data?.stops?.[2]!==null ? data?.stops?.[3] : "NA"}</strong></td>
                              </tr>
                              
                              <tr>
                                <td></td>
                                <td>
                                  <strong>NA</strong>
                                </td>
                                <td>
                                  <strong>NA</strong>
                                </td>
                                <td>Destination: {!!shipmentDtls?.destination?.sbuCode?shipmentDtls?.destination?.sbuCode:"-NA-"}</td>
                              </tr>
                            </table>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col">
                                <div>Pickup Address :</div>
                                {
                                   selfLod === true ?
                                 ( <table style={{"font-size":'12px'}}>
                                <tr>
                                    <td className="pe-2">Name:</td>
                                    <td>
                                      <span style={{ "text-transform": "uppercase" }}>
                                      {
                                        !!shipmentDtls?.pickupAddress?shipmentDtls?.pickupAddress?.name : "-NA-"
                                      }
                                        {/* {!!shipmentDtls?.senderAddress?.name?shipmentDtls?.senderAddress?.name:"-NA-"} */}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="pe-2">Phone:</td>
                                    <td>
                                    <span>{!!shipmentDtls?.pickupAddress?.phone?shipmentDtls?.pickupAddress?.phone:"-NA-"}</span>
                                      {/* <span>{!!shipmentDtls?.senderAddress?.phone?shipmentDtls?.senderAddress?.phone:"-NA-"}</span> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                    {
                                       !!shipmentDtls?.pickupAddress?shipmentDtls?.pickupAddress?.address : "-NA-"
                                      }
                                      {/* {!!shipmentDtls?.senderAddress?.address?shipmentDtls?.senderAddress?.address:"-NA-"} , {!!shipmentDtls?.senderAddress?.postcode?shipmentDtls?.senderAddress?.postcode:"-NA-"} */}
                                    </td>
                                  </tr>
                                  
                                </table>): 'Not Available'
                                }
                              </div>

                              <div className="col">
                                <div>Customer Account Info</div>
                                <table style={{"font-size":'12px'}}>
                                  {/* <tr>
                                    <td className="pe-2">Company Name:</td>
                                    <td>
                                      <span>{!!shipmentDtls?.businessName?shipmentDtls?.businessName:"-NA-"}</span>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td className="pe-2"> Name:</td>
                                    <td>
                                    <span>{!!shipmentDtls?.customerDtls?.custName?shipmentDtls?.customerDtls?.custName:"-NA-"}</span>
                                      {/* <span>{!!shipmentDtls?.recipientAddress?.name?shipmentDtls?.recipientAddress?.name:"-NA-"}</span> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="pe-2">Phone:</td>
                                    <td>
                                    <span>{!!shipmentDtls?.customerDtls?.custPhone?shipmentDtls?.customerDtls?.custPhone:"-NA-"}</span>
                                      {/* <span>{!!shipmentDtls?.recipientAddress?.phone?shipmentDtls?.recipientAddress?.phone:"-NA-"}</span> */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="pe-2">Address:</td>
                                    <td>
                                    <span>{!!shipmentDtls?.senderAddress?.address?shipmentDtls?.senderAddress?.address:"-NA-"},
                                    {!!shipmentDtls?.senderAddress?.city?shipmentDtls?.senderAddress?.city:"-NA-"},
                                    {!!shipmentDtls?.senderAddress?.state?shipmentDtls?.senderAddress?.state:"-NA-"}
                                    </span>
                                      {/* <span>{!!shipmentDtls?.recipientAddress?.phone?shipmentDtls?.recipientAddress?.phone:"-NA-"}</span> */}
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <table className="border-all w-100">
                              <tr>
                                <td>
                                  <strong>Date:</strong>{" "}
                                  {!!shipmentDtls?.shipmentDate?moment(shipmentDtls?.shipmentDate).format(DATE_FORMAT_MOMENT):"-NA-"}
                                </td>
                                <td>
                                  <strong
                                    style={{ "text-transform": "uppercase", "margin-left": "20px" }}
                                  >
                                    {" "}
                                    {selfLod === true ? "Pickup" : "Self Lodge in"}/{" "}
                                    {selfCollect === true ? "Delivery" : "Self Collect"}
                                  </strong>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div>
                                <div>Recipient/Delivery Address :</div>
                                <table style={{"font-size":'12px'}}>
                                  <tr>
                                    <td colspan="2">
                                      {!!shipmentDtls?.recipientAddress.address?shipmentDtls?.recipientAddress?.address:"-NA-"},
                                      {!!shipmentDtls?.recipientAddress?.postcode?shipmentDtls?.recipientAddress?.postcode:"-NA-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="pe-2">Receiver Name:</td>
                                    <td>
                                      <span>{!!shipmentDtls?.recipientAddress?.name?shipmentDtls?.recipientAddress?.name:"-NA-"}</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="pe-2">Receiver:</td>
                                    <td>
                                      <span>{!!shipmentDtls?.recipientAddress?.phone?shipmentDtls?.recipientAddress?.phone:"-NA-"}</span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <table className="border-all w-100">
                              <tr>
                                <td>
                                  Weight:
                                  <br />
                                  <strong></strong>
                                </td>
                                <td>
                                  PCS:
                                  <br />
                                  <strong></strong>
                                </td>
                                <td>
                                  Type:
                                  <br />
                                  <strong>{shipmentDtls?.orderTypeCode === 'PLT' ? "Partial Truck" : "Full Truck"}</strong>
                                </td>
                                <td>
                                  DO return:
                                  <br />
                                  <strong>{data.dor ? "Yes" : "No"}</strong>
                                </td>
                                <td style={{"text-align": "center"}}>
                                  Handling
                                  <br />
                                  <span style={{"margin-right": "5px"}}>Pickup :</span>
                                  <span style={{"margin-right": "5px"}}>
                                  <strong> {
                                    shipmentDtls.shipmentVas.some(item => item.serviceId === 9)? "Yes" :"No"
                                  }</strong>
                                  </span>
                                 <span style={{"margin-right": "5px"}}>Delivery :</span>
                                 <span style={{"margin-right": "5px"}}>
                                 <strong> {
                                    shipmentDtls.shipmentVas.some(item => item.serviceId === 148)? "Yes" :"No"
                                  }</strong>
                                 </span>
                                </td>
                                <td>
                                  Packaging:
                                  <br />
                                  <strong> {
                                    shipmentDtls.shipmentVas.some(item => item.serviceId === 10)? "Yes" :"No"
                                  }</strong>
                                  
                                </td>
                              </tr>
                            </table>
                          </div>
                          <table className="w-100">
                            <tr>
                              {/* <img  src="../images/bar-code.jpg" width="350" height="70" /> */}
                              <td>
                                <BarCodeGenerator value={data.waybillNo} />{" "}
                              </td>
                              <td style={{ "vertical-align": "middle" }}>
                                <h3>
                                  <strong>{!!shipmentDtls?.slaName?shipmentDtls?.slaName:"-NA-"}</strong>
                                </h3>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 mt-1" style={{ border: "5px solid #000" }}>
                    <div class="row">
                    <div className="col-md">
                      <div>Sender Signature</div>
                      <div>Sender  name</div>
                      <div>Date</div>
                    </div>    
                    <div className="col-md">
                      <div>Receiver Signature</div>
                      <div>Receiver  name</div>
                      <div>Date</div>
                    </div>           
                    </div>   
                  </div>
                </td>
                <td className="w-50" style={{ "vertical-align": "top", padding: "20px 50px" }}>
                  <table className="w-100">
                    <tr>
                      <td>
                        <strong style={{ "font-size": "22px" }}>For Linehaul use only</strong>
                      </td>
                      <td align="right">
                        <strong style={{ "font-size": "22px" }}>{!!shipmentDtls?.slaName?shipmentDtls?.slaName:"-NA-"}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" align="center" style={{ "padding-top": "20px" }}>
                        <QRCodeGenerator data={data.waybillNo} />
                        <br />
                        <strong style={{ "font-size": "22px" }}>{data.waybillNo}</strong>
                      </td>
                    </tr>
                  </table>

                  <div className="mt-3">
                    <table className="border-all w-100">
                      <tr>
                        <td className="w-25" align="center">
                          <h5>Origin</h5>
                        </td>
                        <td align="center">
                          <h5>{!!shipmentDtls?.origin?.sbuCode?shipmentDtls?.origin?.sbuCode:"-NA-"}</h5>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td className="w-25" align="center" style={{ height: "150px" }}>
                          <h5>Destination</h5>
                        </td>
                        <td align="center">
                          <h5>{!!shipmentDtls?.destination?.sbuCode?shipmentDtls?.destination?.sbuCode:"-NA-"}</h5>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td className="w-25" align="center">
                          <h5>
                            Cage Transit
                            <br />
                            (No Mix)
                          </h5>
                        </td>
                        <td align="center">
                        <h5>{stopsList.map((item) =>(
                            item+" "
                          ))}</h5>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td className="w-25" align="center">
                          <h5>Date</h5>
                        </td>
                        <td align="center">
                          <h5>{!!shipmentDtls?.shipmentDate?moment(shipmentDtls?.shipmentDate).format(DATE_FORMAT_MOMENT):"-NA-"}</h5>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </table>
          ))}
      </div>
    </>
  );
}

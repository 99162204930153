import React from 'react'

const Toggler = (props) => {
    const { isBtn1Checked, isBtn2Checked, btn1Value, btn2Value, btn1Label, btn2Label, btnName, handler } = props
    const onChange = (label, value) => {
        handler(label, value)
    }
    return (
        <div className="toggleSwitchWrapper">
            <input
                name={btnName}
                type="radio"
                className="btn-check"
                id={btnName + "_0"}
                autoComplete="off"
                value={btn1Value}
                checked={isBtn1Checked}
                onChange={(e) => onChange(btn1Label, btn1Value)}
            />
            <label
                className="btn btn-outline-success btn-sm tl-btn font-12"
                htmlFor={btnName + "_0"}
            >
                {btn1Label}
            </label>

            <input
                name={btnName}
                type="radio"
                className="btn-check"
                id={btnName + "_1"}
                autoComplete="off"
                checked={isBtn2Checked}
                value={btn2Value}
                onChange={(e) => onChange(btn2Label, btn2Value)}
            />
            <label
                className="btn btn-outline-success btn-sm tr-btn font-12"
                htmlFor={btnName + "_1"}
            >
                {btn2Label}
            </label>
        </div>
    )
}

export default Toggler
import GetSvgIcon from "../../images/svglist";
import { useState } from "react";
import { read, utils } from "xlsx";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { CallApiGet, CallApiPost } from "./serviceApi";
import { Tooltip } from "react-tooltip";
import Spinner from "../Layout/Spinner";
import { Alert, Col, Row } from "react-bootstrap";
import XLSX from "xlsx";

export default function UploadDataFile(props) {
  const {
    cbValidateData,
    cbPreUpload,
    dataUploadUrl,
    onSuccess,
    onError,
    template,
    downloadFileName,
    downloadFileServer,
    downloadUrl,
    header,
    isFullScreen = false,
    onCloseClear,
    preValidation,
  } = props;
  const [dataRows, setDataRows] = useState([]);
  const [dataCols, setDataCols] = useState([]);
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());
  const [validationError, setValidationError] = useState("");
  const [show, setShow] = useState(false);
  const [isDataValid, setDataValid] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const sortByDataInValid = (argDataRows) => {
    const sortedData = argDataRows.slice().sort((a, b) => {
      // Sort false values first, then true values
      return a.dataValid === b.dataValid ? 0 : a.dataValid ? 1 : -1;
    });
    return sortedData;
  };

  const fileToJson = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, { raw: true });
        const sheets = wb.SheetNames;
        //---Get Column Names
        var worksheet = wb.Sheets[sheets[0]];
        var colNames = [];
        var cellsInSheet = Object.keys(worksheet);
        for (var i = 0; i < Object.keys(cellsInSheet).length; i++) {
          //---We need to check the first row only. Stop this loop when it reaches second row
          if (cellsInSheet[i].indexOf("2") > -1) break;
          if (cellsInSheet[i].indexOf("1") > -1) {
            colNames.push(worksheet[cellsInSheet[i]].v); //Contails all column names
          }
        }
        //--End col names
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          extractDataCols(rows, colNames);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const extractDataCols = (jsonData, cols) => {
    if (jsonData.length <= 0) {
      setValidationError("No data to upload!");
      setDataRows([]);
      return;
    }
    setValidationError("");
    //let cols = Object.keys(jsonData[0]);
    let tempDataCols = [];
    let colId = "COl";
    tempDataCols.push({
      id: "COLV",
      name: "Valid",
      selector: (row) => row["dataValid"].toString(),
      conditionalCellStyles: [
        {
          when: (row) => row["dataValid"] === false,
          style: {
            textDecoration: "underline",
            color: "red",
            "&:hover": {
              cursor: "pointer",
              color: "red",
            },
          },
        },
        {
          when: (row) => row["dataValid"] === true,
          style: {
            color: "green",
          },
        },
      ],
      sortable: true,
    });
    // tempDataCols.push({ id: (colId + "M"), name: 'Error Message', selector: row => row['errorMessage'] });
    cols.forEach((col, index) => {
      tempDataCols.push({
        id: colId + index,
        name: col,
        selector: (row) => row[col],
      });
    });
    //--- Add dataValid for each row. This column will be used to conditionally highlight the row
    jsonData.forEach((dataRow) => {
      dataRow.dataValid = true;
      dataRow.errorMessage = "";
    });

    setShowBadge(false);
    setErrMsg("");
    if (cbValidateData) {
      const isValid = cbValidateData(jsonData);
      setDataValid(isValid);
      if (!isValid) {
        setFileInputDummyKey(Date.now());
      } else {
        setShowBadge(false);
        setErrMsg("");
      }
    }
    setDataCols(tempDataCols);
    setDataRows(sortByDataInValid(jsonData));
    console.log(jsonData);
  };

  const sendFileToServer = async () => {
    setShow(true);
    if (dataRows.length === 0) {
      setValidationError("No data to upload!");
      setShow(false);
      return;
    }
    let dataToSend = dataRows;
    if (cbPreUpload) {
      dataToSend = cbPreUpload(dataToSend);
    }
    if (!!preValidation && !preValidation()) {
      setShow(false);
      return;
    }
    console.log("====Uploading Data...");
    console.log(dataToSend);
    let resp = await CallApiPost(dataUploadUrl, dataToSend);
    if (resp.respCode === 0 || resp.respCode === 200) {
      setShow(false);
      // closeDialog();
      document.getElementById("btn-modal-close").click();
      onSuccess();
    } else if (resp.respCode === 9) {
      setFileInputDummyKey(Date.now());
      setDataValid(false);
      // let copyDataRows = [];
      let copyDataRows = [...dataRows];
      if (!!resp.respData && resp.respData instanceof Array) {
        resp.respData.forEach((err) => {
          let errIndx = parseInt(err.RowIndex);
          let errorRow = { ...copyDataRows[errIndx] };
          errorRow.dataValid = false;
          errorRow.errorMessage =
            typeof errorRow.errorMessage === "string" &&
            errorRow.errorMessage.length > 0
              ? errorRow.errorMessage + "," + err.ErrorMessage
              : err.ErrorMessage;
          copyDataRows[errIndx] = errorRow;
        });
        const sortedData = sortByDataInValid(copyDataRows);
        setDataRows(sortedData);
      } else {
        setDataRows([]);
        onError(resp);
        if (!!onCloseClear) {
          onCloseClear();
        }
      }
      setShow(false);
    } else {
      setShow(false);
      setFileInputDummyKey(Date.now());
      setDataRows([]);
      onError(resp);
      if (!!onCloseClear) {
        onCloseClear();
      }
    }
  };

  const handleRowClick = (row) => {
    setShowBadge(true);
    setErrMsg(row.errorMessage);
  };

  const closeDialog = () => {
    setDataCols([]);
    setDataRows([]);
    setFileInputDummyKey(Date.now());
    setValidationError("");
    setDataValid(false);
    setErrMsg("");
    setShowBadge(false);
    if (!!onCloseClear) {
      onCloseClear();
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.dataValid === false,
      style: {
        color: "red",
        cursor: "pointer",
      },
    },
    {
      when: (row) => row.dataValid === true,
      style: {
        color: "green",
      },
    },
  ];

  const renderErrorMsg = () => {
    const errArr = errMsg.split(",");
    return (
      <Row>
        {errArr.map((msg, index) => (
          <Col md="auto">
            <p className="text-danger mt-0 mb-0">
              {index + 1}. {msg.trim()}
            </p>
          </Col>
        ))}
      </Row>
    );
  };
  const downloadExcelFile = async () => {
    let data = await CallApiGet(downloadUrl);
    if (data.respCode === 200) {
      var wb = XLSX.utils.book_new(),
        ws = !!header
          ? XLSX.utils.json_to_sheet(data.respData, { header: header })
          : XLSX.utils.json_to_sheet(data.respData);
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, downloadFileName);
    }
  };
  return (
    <div>
      <div
        className="modal fade"
        id="upload-file-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div
          className={
            isFullScreen
              ? "modal-dialog modal-fullscreen"
              : "modal-dialog modal-xl"
          }
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title section-title" id="exampleModalLabel">
                Upload Data File
              </h5>
              <button
                id="btn-modal-close"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeDialog}
              ></button>
            </div>
            <div className="modal-body">
              {!!props?.children && props?.children}
              <div className="row">
                <div>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-md-11 mb-2">
                        <label
                          htmlFor="floatingInputGrid"
                          className="form-label theme-label"
                        >
                          Upload File{" "}
                          <span className="text-danger">&#9733;</span>{" "}
                          <span className="text-primary">
                            (&#9432; XLSX files allowed)
                          </span>
                        </label>
                        <div className="input-group">
                          <input
                            type="file"
                            className="form-control"
                            aria-describedby="button-addon2"
                            onChange={(e) => fileToJson(e)}
                            accept="xlsx,xls"
                            id="FileInput"
                            key={fileInputDummyKey}
                          />
                          <button
                            className="btn btn-outline-secondary theme-violet"
                            type="button"
                            id="button-addon2"
                            onClick={sendFileToServer}
                            disabled={!isDataValid}
                          >
                            Upload
                          </button>
                        </div>
                        {validationError && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {validationError}
                            </span>
                          </div>
                        )}
                      </div>
                      {!!downloadFileServer && downloadFileServer === true ? (
                        <div className="col-md-1 text-center px-0 pt-4">
                          <button
                            className="btn btn-default"
                            onClick={downloadExcelFile}
                            data-tooltip-id="download-vch"
                          >
                            {GetSvgIcon("IconDownload")}
                            <Tooltip
                              id="download-vch"
                              content="Download"
                              place="left"
                              style={{ zIndex: 900 }}
                            />
                          </button>
                        </div>
                      ) : (
                        <div className="col-md-1 text-center px-0 pt-4">
                          <a
                            type="button"
                            className="btn btn-default"
                            href={template}
                            download={downloadFileName}
                            data-tooltip-id="download-vch"
                          >
                            {GetSvgIcon("IconDownload")}
                            <Tooltip
                              id="download-vch"
                              content="Download"
                              place="left"
                              style={{ zIndex: 900 }}
                            />
                          </a>
                        </div>
                      )}
                    </div>
                    {showBadge && !!errMsg && errMsg.length > 0 && (
                      <div className="row">
                        <div className="col-md-12">
                          <Alert
                            variant="warning"
                            onClose={() => setShowBadge(false)}
                            dismissible
                          >
                            {/* {errMsg} */}
                            {renderErrorMsg()}
                          </Alert>
                        </div>
                      </div>
                    )}
                    <div className="upload-popup">
                      <DataTable
                        columns={dataCols}
                        data={dataRows}
                        pagination
                        striped
                        highlightOnHover
                        conditionalRowStyles={conditionalRowStyles}
                        customStyles={customDataTableStyles}
                        defaultSortFieldId="COLV"
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        onRowClicked={handleRowClick}
                        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                        paginationPerPage={defaultPageLimit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner show={show} />
    </div>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { DATE_FORMAT_MOMENT } from "../Helper/Constants";
import moment from "moment";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../images/svglist";
import ModalLayout from "../Layout/ModalLayout";
import ClaimAction from "./ClaimAction";
import { downloadFiles } from "../Helper/CommonMethods";
import { Tooltip } from "react-tooltip";

const ClaimHistory = (props) => {
  const {
    historyList,
    modalConfig,
    handleModalClose,
    setHistoryList,
    cancelClaimForm,
    claimDetails,
    setClaimDetails,
    setSpinner,
    showAlert,
    reloadClaims,
  } = props;
  const userType = getUserInfo("userType");
  const [hideColums, setHideColums] = useState(false);
  // const [modalConfig, setModalConfig] = useState(null);
  const [action, setAction] = useState(null);
  const [claimLogDetails, setClaimLogDetails] = useState(null);

  // useEffect(() => {
  //     setHideColums(LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ? true : false);
  //     if (!!historyList && historyList.length > 0) {
  //         showClaimLogs()
  //     }
  // }, [historyList])

  // const showClaimLogs = () => {
  //     setModalConfig({
  //         size: "lg",
  //         show: true,
  //         title: "Claim History"
  //     });
  // };

  // const handleModalClose = () => {
  //     setModalConfig({
  //         size: "lg",
  //         show: false,
  //         title: "",
  //     });
  //     // setHistoryList([])
  //     // setClaimDetails(null)
  // };

  // const showActionForm = (row) => {

  //     setModalConfig({
  //         size: "md",
  //         show: false,
  //         title: "",
  //     });
  //     setAction(row?.action_code)
  //     setClaimLogDetails(row)
  // }

  const downloadAttachments = useCallback((data) => {
    if (data.length > 0) {
      downloadFiles(data);
    }
  }, []);

  const columns = [
    {
      name: "Action Date",
      selector: (row) => moment(row.logDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Action By",
      selector: (row) => row.actionBy,
      sortable: true,
      filterable: true,
    },
    {
      name: "Assigned To",
      selector: (row) =>
        !!row.assignedToName ? row.assignedToName : "Customer",
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Assigned SBU",
      selector: (row) => row?.sbuCode,
      omit: hideColums,
    },
    {
      name: "Action",
      selector: (row) => row.actionType,
      sortable: true,
      filterable: false,
    },
    {
      name: "Remarks",
      selector: (row) => row.remarks?.replace("#$", "\n"),
      cell: (row) => (
        // <pre className="box-text">
        <p className="d-inline-block remark-style mb-1 align-self-center">
          {row.remarks?.replace("#$", "\n")}
        </p>

        // </pre>
      ),
      sortable: true,
      filterable: false,
    },
    {
      name: "",
      selector: (row) =>
        !!row?.attachments && Array.isArray(JSON.parse(row?.attachments))
          ? JSON.parse(row?.attachments).length
          : 0,
      sortable: false,
      filterable: false,
      width: "5%",
      cell: (row) => (
        <>
          {!!row?.attachments &&
            Array.isArray(JSON.parse(row?.attachments)) && (
              <>
                <div
                  data-tooltip-id={`log-${row.id}`}
                  className="modal-links fw-bold"
                  onClick={() =>
                    downloadAttachments(JSON.parse(row?.attachments))
                  }
                >
                  {GetSvgIcon("BiArrowDownCircleFill")}
                </div>
                <Tooltip
                  id={`log-${row.id}`}
                  content="Downoad all the attachments"
                  place="left"
                />
              </>
            )}
        </>
      ),
    },
  ];

  const actionFooterControls = (
    <>
      <button
        type="button"
        className="btn btn-default border"
        onClick={() => handleModalClose()}
      >
        Close
      </button>
    </>
  );

  return (
    <>
      {!!modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
          footer={actionFooterControls}
        >
          <div className="col-md-12 customized-data-table position-relative">
            <DataTable
              columns={columns}
              data={historyList}
              pagination
              striped
              highlightOnHover
              paginationRowsPerPageOptions={defaultRowsPerPageOptions}
              paginationPerPage={defaultPageLimit}
              customStyles={customDataTableStyles}
            />
          </div>
        </ModalLayout>
      )}
      {/* {
                !!action &&
                <ClaimAction viewMode={true} claimLogDetails={claimLogDetails} actionType={action} claimDetails={claimDetails} setSpinner={setSpinner} showAlert={showAlert} setAction={setAction} cancelClaimForm={cancelClaimForm} reloadClaims={reloadClaims} />
            } */}
    </>
  );
};

export default ClaimHistory;

import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DATE_TIME_FORMAT_MOMENT,
  TIME_FORMAT_SERVER,
  shipmentViewStatus,
} from "../Helper/Constants";
import { CallApiPost } from "../Helper/serviceApi";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import CustomerOrderShipmentViewList from "./CustomerOrderShipmentViewList";
import { useNavigate, useLocation } from "react-router-dom";
import GetSvgIcon from "../../images/svglist";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import Select from "react-select";
import { customDateSort, exportToExcel } from "../Helper/CommonMethods";
import { Tooltip } from "react-tooltip";

const CustomerOrderShipmentView = () => {
  const pageTitle = `Shipment View`;
  let location = useLocation();

  const mergeShipmentData = {
    title: "Merge Pallets",
    description: "Are you sure, you want to merge pallets ?",
    confirmEventName: "mergeShipment",
  };
  const saveShipmentData = {
    title: "Update Pallets",
    description: "Are you sure, you want to change pallets status ?",
    confirmEventName: "saveShipment",
  };
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const initConfirmAlertConfig = {
    size: "",
    show: false,
    title: "",
    description: "",
    confirmEventName: "",
  };

  const [spinner, setSpinner] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [tripDate, setTripDate] = useState(null);
  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);
  const [originList, setOriginList] = useState([]);
  const [shipmentList, setShipmentList] = useState([]);
  const [shipmentListBack, setShipmentListBack] = useState([]);
  const [mergedList, setMergedList] = useState([]);
  const [waybillSelected, setWaybillSelected] = useState([]);
  const [waitingList, setWaitingList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [selectedWaybills, setSelectedWaybills] = useState([]);
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(
    initConfirmAlertConfig
  );
  const navigate = useNavigate();
  const ROUTE_TYPES = "27,28,29";
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(2, "days").format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).add(2, "days").format(DATE_FORMAT_SERVER)
  );

  const columns = [
    {
      name: "",
      width: "2%",
      cell: (row) => (
        <>
          <input
            type="checkbox"
            // checked={mergedList.includes(row.waybill_no)  }
            onChange={(e) => handelOnSelect(e, row)}
            // disabled={Number(row.merged_id) > 0}
          />
        </>
      ),
    },
    {
      name: "Trip Date",
      selector: (row) =>
        !!row.trip_start
          ? moment(row.trip_start).format(DATE_FORMAT_MOMENT)
          : "",
      sortable: true,
      filterable: true,
      width: "7%",
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.trip_start, rowB.trip_start),
    },
    {
      name: "Origin",
      selector: (row) => row.origin,
      sortable: true,
      filterable: true,
      width: "7%",
      exportd: "origin",
    },
    {
      name: "Destination",
      selector: (row) => row.destination,
      sortable: true,
      filterable: true,
      width: "7%",
    },
    {
      name: "Service",
      selector: (row) => row.sla_name,
      sortable: true,
      filterable: true,
      // width: "7%",
    },
    {
      name: "Type",
      selector: (row) => row.package_type,
      sortable: true,
      filterable: true,
      // width: "7%",
    },
    {
      name: <div className="text-wrap">Order (PalletSpace)</div>,
      // name: "Order(PalletSpace)",
      selector: (row) => row.order_no + "(" + row.pallet_space + ")",
      sortable: true,
      filterable: true,
    },
    {
      name: "Waybill",
      selector: (row) => row.waybill_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Booked On",
      selector: (row) => moment(row.booked_on).format(DATE_TIME_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Merged To",
      selector: (row) => row.merged_waybill,
      sortable: true,
      filterable: true,
      cell: (row) => <div>{row.merged_id > 0 ? row.merged_waybill : ""}</div>,
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vhcl_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Departure",
      selector: (row) =>
        moment(row.origin_etd_date).format(DATE_FORMAT_MOMENT) +
        " " +
        moment(row.origin_etd_time, "HH:mm:ss").format(TIME_FORMAT_SERVER),
      sortable: true,
      filterable: true,
    },
    {
      name: "Lodgein",
      selector: (row) =>
        row.lodgein ? moment(row.lodgein).format(DATE_TIME_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer_name,
      sortable: true,
      filterable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status,
      sortable: false,
      filterable: true,
      width: "12%",
      cell: (row, index) => (
        <div className="toggleSwitchWrapper">
          <input
            name={"row_group_" + index}
            type="radio"
            className="btn-check"
            id={"planned_" + index}
            autoComplete="off"
            checked={row.status_id === shipmentViewStatus.planned} //90: Delivery Planned
            onChange={() => changeStatus(row, shipmentViewStatus.planned)}
          />
          <label
            className="btn btn-outline-success btn-sm tl-btn font-12"
            htmlFor={"planned_" + index}
          >
            Planned
          </label>

          <input
            name={"row_group_" + index}
            type="radio"
            className="btn-check"
            id={"Waiting_" + index}
            autoComplete="off"
            checked={row.status_id === shipmentViewStatus.waiting} //89: Waiting
            onChange={() => changeStatus(row, shipmentViewStatus.waiting)}
          />
          <label
            className="btn btn-outline-success btn-sm tr-btn font-12"
            htmlFor={"Waiting_" + index}
          >
            Waiting
          </label>
        </div>
      ),
    },
    {
      // name: <div className="text-wrap">Problem Resions</div>,
      name: "Problem Reasons",
      selector: (row) => row.p_resion,
      sortable: true,
      filterable: true,
    },
  ];

  useEffect(() => {
    getDropdownData();
    loadList();
  }, []);

  // useEffect(() => {
  //   if (!!origin && !!tripDate) {
  //     loadListByTripDateAndOrigin();
  //   }
  // }, [origin, tripDate]);

  const getDropdownData = async () => {
    setSpinner(true);
    const dropdownModel = {
      argport: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      const respData = data.respData;
      setOriginList(respData.portArr);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const loadListByTripDateAndOrigin = async () => {
    let payload = {
      origin: "",
      fromDate: moment(fromDate).format(DATE_FORMAT_SERVER),
      toDate: moment(toDate)
        // .add(1, "days")
        .format(DATE_FORMAT_SERVER),
    };

    setSpinner(true);
    setShipmentList([]);
    let data = await CallApiPost("getShipmentsByOriginTrackStartDate", payload);
    if (!!data && data.respCode === 200) {
      restoreData();
      setShipmentList(data.respData);
      let dt = data.respData;
      setShipmentListBack(dt);
      setWaitingList(
        data.respData.filter(
          (item) => item.status_id === shipmentViewStatus.waiting
        )
      );
      getPlannedVehicles(data.respData);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const loadList = async (mergedList = []) => {
    let roles = JSON.parse(getUserInfo("userRoles"));
    setOrigin(roles[0]?.sbuId > 0 ? roles[0]?.sbuId : "");
    setTripDate(null);

    let payload = {
      origin: roles[0]?.sbuId > 0 ? roles[0]?.sbuId : "",
      fromDate: fromDate,
      toDate: toDate,
    };
    setSpinner(true);
    setShipmentList([]);
    let data = await CallApiPost("getShipmentsByOriginTrackStartDate", payload);
    if (!!data && data.respCode === 200) {
      if (mergedList.length > 0) {
        updateMergedPalletStatus(data.respData, mergedList);
      }
      restoreData();
      setShipmentList(data.respData);
      setShipmentListBack(data.respData);
      setWaitingList(
        data.respData.filter(
          (item) => item.status_id === shipmentViewStatus.waiting
        )
      );

      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const handelOnSelect = (event, rowData) => {
    var updatedList = [...mergedList];
    let updatedWaybills = [...selectedWaybills];
    if (event.target.checked) {
      updatedList = [...mergedList, rowData.waybill_no];
      updatedWaybills = [...selectedWaybills, rowData];
    } else {
      updatedList.splice(mergedList.indexOf(rowData.waybill_no), 1);
      updatedWaybills.splice(selectedWaybills.indexOf(rowData), 1);
    }
    setMergedList(updatedList);
    setSelectedWaybills(updatedWaybills);
  };

  const updateCheckedWaybills = (rowData, status, mergedWaybills) => {
    var updatedList = [...waybillSelected];

    let filteredList = updatedList.filter(
      (item) => item.pallet_itin_id === rowData.pallet_itin_id
    );
    if (filteredList.length > 0) {
      updatedList.forEach((el) => {
        if (el.pallet_itin_id === rowData.pallet_itin_id) {
          el.status_id = status;
        }
      });
    } else {
      updatedList = [...waybillSelected, rowData];
    }

    if (mergedWaybills.length > 0) {
      mergedWaybills.forEach((item) => {
        let flData = [...updatedList].filter(
          (el) => el.pallet_itin_id === item
        );
        if (flData.length === 0) {
          let flShipData = [...shipmentList].filter(
            (el) => el.pallet_itin_id === item
          );
          flShipData[0].status_id = status;
          updatedList.push(flShipData[0]);
        }
      });
    }

    setWaybillSelected(updatedList);
  };

  const changeStatus = (rowData, status) => {
    let filteredMergedWaybills = [...shipmentList]
      .filter((el) => el.merged_id > 0 && el.merged_id === rowData.merged_id)
      .map((el) => el.pallet_itin_id);
    updateCheckedWaybills(rowData, status, filteredMergedWaybills);
    let mShipmentList = [];
    shipmentList.forEach((item) => {
      if (item.pallet_itin_id === rowData.pallet_itin_id) {
        item.status_id = status;
      }
      if (
        filteredMergedWaybills.length > 0 &&
        filteredMergedWaybills.includes(item.pallet_itin_id)
      ) {
        item.status_id = status;
      }
      mShipmentList.push(item);
    });

    setShipmentList(mShipmentList);
    setWaitingList(
      mShipmentList.filter(
        (item) => item.status_id === shipmentViewStatus.waiting
      )
    );
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      size: confirmAlertConfig.size,
      show: false,
      title: "",
      description: "",
      confirmEventName: "",
    });
  };

  const showConfirmAlert = (data) => {
    if (data.confirmEventName === "saveShipment") {
      setConfirmAlertConfig({
        size: "md",
        show: true,
        title: data.title,
        description: data.description,
        confirmEventName: data.confirmEventName,
      });
    } else {
      if (validateForMearging()) {
        // if (validateForOriginDestination().isValid) {
        setConfirmAlertConfig({
          size: "md",
          show: true,
          title: data.title,
          description: data.description,
          confirmEventName: data.confirmEventName,
        });
        // } else {
        //   let alerData = {
        //     isSuccess: false,
        //     title: "",
        //     description:
        //       "selected waybills are not having same origin or destination",
        //   };
        //   showAlert(alerData);
        // }
      } else {
        let alerData = {
          isSuccess: false,
          title: "",
          description: "The selected waybills are already merged",
        };
        showAlert(alerData);
      }
    }
  };

  const confirmAlert = () => {
    hideConfirmAlert();
    if (
      !!confirmAlertConfig?.confirmEventName &&
      confirmAlertConfig?.confirmEventName === "saveShipment"
    ) {
      saveShipment();
    }

    if (
      !!confirmAlertConfig?.confirmEventName &&
      confirmAlertConfig?.confirmEventName === "mergeShipment"
    ) {
      mergeShipment();
    }
  };

  const saveShipment = async () => {
    let updateStatus;
    if (waybillSelected.length > 0) {
      let mappedWaybills = waybillSelected.map((item) => {
        return {
          pallet_itin_id: item.pallet_itin_id,
          status: item.status_id,
        };
      });
      updateStatus = await updatePalletStatus(mappedWaybills);
    }
    return updateStatus;
  };

  const updatePalletStatus = async (mappedWaybills, merged = false) => {
    setSpinner(true);
    let updateStatus = false;
    let data = await CallApiPost("updatePalletStatus", mappedWaybills);
    if (!!data && data.respCode === 200) {
      updateStatus = true;
      // loadList();
      if (!!tripDate && !!origin) loadListByTripDateAndOrigin();
      else loadList();
      setSpinner(false);
      restoreData();
      showAlert({
        isSuccess: true,
        title: "",
        description: merged
          ? "Pallets Merged Successfully"
          : "Status Updated Successfully",
      });
    } else {
      setSpinner(false);
      showAlert({
        isSuccess: false,
        title: "",
        description: "!!Some Error Occured",
      });
    }
    return updateStatus;
  };

  const mergeShipment = async () => {
    if (validateMerge()) {
      setSpinner(true);
      let data = await CallApiPost("mergePallets", mergedList);
      if (!!data && data.respCode === 200) {
        loadListByTripDateAndOrigin();
        setSpinner(false);
        restoreData();
        showAlert({
          isSuccess: true,
          title: "",
          description: "Pallets Merged Successfully",
        });
      } else {
        setSpinner(false);
        showAlert({
          isSuccess: false,
          title: "",
          description: "!!Some Error Occured",
        });
      }
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: "Selected pallets origin and final destination mismatch",
      });
    }
  };

  const getPlannedVehicles = async (shipmentList, argOrigin, argTripStart) => {
    let payload = {
      origin: !!origin && origin !== "" ? origin : argOrigin,
      tripDate: !!tripDate
        ? moment(tripDate).format(DATE_FORMAT_SERVER)
        : argTripStart,
      routeType: ROUTE_TYPES,
    };

    let shipmentStatus = "Delivery Planned";
    setSpinner(true);
    let data = await CallApiPost("GetVehicleListByTripDateAndOrigin", payload);
    if (!!data && data.respCode === 200) {
      let mVehicleList = data.respData.map((item) => {
        return {
          ...item,
          calculatedBooked: shipmentList.filter(
            (el) =>
              item.plan_id === el.plan_id &&
              el.status_id === shipmentViewStatus.planned
          ).length,
          waybills: shipmentList.filter(
            (el) =>
              item.plan_id === el.plan_id &&
              el.status_id === shipmentViewStatus.planned
          ),
        };
      });
      setVehicleList(mVehicleList);
      if (!!argOrigin && !!argTripStart)
        redirectToReassign(mVehicleList, argOrigin, argTripStart, shipmentList);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
    return true;
  };

  const restoreData = () => {
    setMergedList([]);
    setWaybillSelected([]);
  };

  const redirectToReassign = (
    vehicleListData,
    argOrigin,
    argTripStart,
    argWaybillList
  ) => {
    let waybillArr = argWaybillList.map((e) => e.waybill_no);
    let waitList = shipmentListBack
      .filter((e) => waybillArr.includes(e.waybill_no))
      .filter((e) => e.status_id === shipmentViewStatus.waiting);

    navigate("/reassignVehicle", {
      state: {
        vehicleList: vehicleListData,
        waitingList: argWaybillList,
        shipmentList: shipmentList.filter((el) => el.originid == argOrigin),
        origin: argOrigin,
        tripDate: argTripStart,
      },
    });
  };

  const onFilterData = (e) => {
    if (e.hasOwnProperty("Origin")) {
      if (e?.Origin?.value.length === 4) {
        let flOrigin = [...originList].filter((el) =>
          el.label.includes(e?.Origin?.value.toUpperCase())
        );
        if (flOrigin.length > 0) {
          setOrigin(flOrigin[0].value);
        } else {
          setOrigin(null);
        }
      } else {
        setOrigin(null);
      }
    } else {
      setOrigin(null);
    }

    if (e.hasOwnProperty("Trip Date")) {
      if (e["Trip Date"].value.length === 10) {
        setTripDate(e["Trip Date"].value);
      } else {
        setTripDate(null);
      }
    } else {
      setTripDate(null);
    }
  };

  const validateMerge = () => {
    let originDestnNotMatched = 0;
    let filteredList = [...shipmentList].filter((el) =>
      mergedList.includes(el.waybill_no)
    );

    let origin1 = filteredList[0].origin;
    let destination1 = filteredList[0].description;
    filteredList.forEach((item) => {
      if (!(item.origin === origin1 && item.destination === destination1)) {
        originDestnNotMatched++;
      }
    });
    return originDestnNotMatched > 0 ? false : true;
  };

  const updateMergedPalletStatus = (shipmentList, mergedWaybills) => {
    let filteredData = [...shipmentList].filter((el) =>
      mergedWaybills.includes(el.waybill_no)
    );
    let mergeParentRecord = [...shipmentList].filter(
      (el) => el.pallet_id === filteredData[0].merged_id
    );
    let mappedWaybills = [...filteredData].map((item) => {
      return {
        pallet_itin_id: item.pallet_itin_id,
        status: mergeParentRecord[0].status,
      };
    });
    updatePalletStatus(mappedWaybills, true);
  };

  const validateForMearging = () => {
    let isValid =
      selectedWaybills.filter((item) => item.merged_id === 0).length > 0
        ? true
        : false;

    return isValid;
  };
  const validateForOriginDestination = () => {
    let tempOrigin = selectedWaybills[0].originid;
    let tempDestination = selectedWaybills[0].destinid;

    let isValid =
      selectedWaybills.filter(
        (item) =>
          item.originid !== tempOrigin || item.destinid !== tempDestination
      ).length > 0
        ? false
        : true;

    // if (isValid) setOrigin(tempOrigin);

    return { isValid: isValid, origin: tempOrigin };
  };
  const validateForWaitingStatus = () => {
    let isValid =
      selectedWaybills.filter(
        (item) => item.status_id === shipmentViewStatus.planned
      ).length > 0
        ? false
        : true;

    return isValid;
  };

  const validateForSameDate = () => {
    let tempTripStartDate = selectedWaybills[0].trip_start;
    let isValid =
      selectedWaybills.filter((item) => item.trip_start !== tempTripStartDate)
        .length > 0
        ? false
        : true;

    // if (isValid) setTripDate(tempTripStartDate);

    return { isValid: isValid, tripDate: tempTripStartDate };
  };

  const getVehicles = async () => {
    let isSameOrigin = validateForOriginDestination();
    let isSameTripStart = validateForSameDate();

    if (validateForWaitingStatus()) {
      if (isSameOrigin.isValid) {
        if (isSameTripStart.isValid) {
          let updateStatus = true;
          if (!!waybillSelected && waybillSelected.length > 0)
            updateStatus = await saveShipment();
          if (updateStatus) {
            let isCallComplite = await getPlannedVehicles(
              selectedWaybills,
              isSameOrigin.origin,
              isSameTripStart.tripDate
            );
          }
          // if (isCallComplite === true) redirectToReassign();
        } else {
          let alerData = {
            isSuccess: false,
            title: "",
            description:
              "selected waybills are not having same Trip start date",
          };
          showAlert(alerData);
        }
      } else {
        let alerData = {
          isSuccess: false,
          title: "",
          description:
            "selected waybills are not having same origin or destination",
        };
        showAlert(alerData);
      }
    } else {
      //error selected waybills are not in waiting state
      let alerData = {
        isSuccess: false,
        title: "",
        description: "The selected waybills are not in waiting state",
      };
      showAlert(alerData);
    }
  };

  const exportData = () => {
    exportToExcel(shipmentList, columns, "shipment_view.csv");
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
        confirmAlertConfig={confirmAlertConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={confirmAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between py-3 m-1">
          <div className="col-auto">
            <div className="border rounded p-2 me-3 wt-tbl float-start">
              <div className="d-flex align-item-center">
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label align-self-center me-2"
                    title="This field is mandatory"
                  >
                    From Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!fromDate ? new Date(fromDate) : fromDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setFromDate(d)}
                    onSelect={(d) => setFromDate(d)}
                  />
                </div>
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label align-self-center me-2 ms-2"
                    title="This field is mandatory"
                  >
                    To Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control "
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!toDate ? new Date(toDate) : toDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setToDate(d)}
                    onSelect={(d) => setToDate(d)}
                  />
                </div>
                {/* <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label ms-3 align-self-center"
                    title="This field is mandatory"
                  >
                    Origin:
                  </label>
                  <Select
                    options={originList}
                    placeholder="Choose One Origin"
                    value={
                      origin === ""
                        ? ""
                        : originList.find((opt) => opt.value === origin)
                    }
                    onChange={(d) => setOrigin(d ? d.value : d)}
                    isSearchable={true}
                    isClearable={true}
                    className="form-control-sm"
                  />
                </div> */}
              </div>
            </div>

            <button
              type="button"
              className="btn btn-outline-secondary theme-violet text-nowrap float-end mt-2"
              onClick={() => loadListByTripDateAndOrigin()}
              // disabled={!(!!tripDate && !!origin)}
            >
              {GetSvgIcon("Search")}
            </button>
          </div>
          <button
            type="button"
            className="btn btn-outline-secondary theme-violet  text-end mt-2 mb-2"
            onClick={exportData}
            data-tooltip-id="csv-export"
          >
            {/* Download */}
            {GetSvgIcon("SpreadSheet")}
          </button>
          <Tooltip
            id="csv-export"
            content="Download to Excel"
            place="right"
            style={{ zIndex: "100" }}
          />
        </div>
        {!!shipmentList && shipmentList.length > 0 ? (
          <>
            <CustomerOrderShipmentViewList
              mergedList={mergedList}
              shipmentList={shipmentList}
              handelOnSelect={handelOnSelect}
              changeStatus={changeStatus}
              onFilterData={onFilterData}
              columns={columns}
            />
          </>
        ) : (
          <div className="noData">No Data Available</div>
        )}

        {!!shipmentList && shipmentList.length > 0 && (
          <div className="row mt-2 border-top pt-2">
            <div className="col-lg-12 text-right">
              {/* <button
                className="btn btn-default border me-2"
                type="button"
                onClick={() => loadList()}
              >
                Reset
              </button> */}

              <button
                className="btn btn-outline-secondary theme-violet text-nowrap mr-5"
                type="button"
                onClick={() => getVehicles()}
                disabled={mergedList.length > 0 ? false : true}
              >
                Reassign Vehicle
              </button>

              {/* <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() => showConfirmAlert(mergeShipmentData)}
                disabled={mergedList.length > 1 ? false : true}
              >
                Merge
              </button> */}

              {/* {!!waybillSelected && waybillSelected.length > 0 && ( */}
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap mr-5 ms-1"
                type="button"
                onClick={() => showConfirmAlert(saveShipmentData)}
                disabled={
                  !!waybillSelected && waybillSelected.length === 0
                    ? true
                    : false
                }
              >
                Save
              </button>
              {/* )} */}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default CustomerOrderShipmentView;

import React from "react";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import { Badge } from "react-bootstrap";

const SupplierList = (props) => {
  const { suppList, setPrflId, columns } = props;

  return (
    <div className="col-md-12 mt-5 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={suppList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default SupplierList;

export const initRateCode = {
  rateCodeId: 0,
  rateCode: "",
  rateCodeDesc: "",
};

export const intiRateChat = {
  rateId: 0,
  ratechartId: 0,
  originSbuCode: "",
  destSbuCode: "",
  originId: 0,
  destId: 0,
  palletPrice: 0,
  tripPrice: 0,
  monthlyPrice: 0,
  overweightPrice: 0,
};

export const initServiceDetails = {
  serviceTargetId: 0,
  serviceId: 0,
  validFrom: "",
  validTo: "",
  customerId: 0,
  customerIds: "",
  targetDays: null,
  lastPickup: "",
  lastLodgein: "",
  ratechartId: null,
};

export const initService = {
  serviceId: 0,
  serviceName: "",
  serviceDesc: "",
  active: true,
  international: false,
  priority: "",
  isDefault: false,
  serviceDtlsList: [initServiceDetails],
  allowPickup: true,
  allowDelivery: true,
};

export const initDynPrice = [
  {
    loadFrom: 0,
    loadTo: 0,
    rate: 0,
    dynPriceId: 0,
    remark: "",
    serviceId: 0,
    percentage: "true",
  },
];

export const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../images/LinehaulLogo.png";
import {
  GetSvgPhone,
  GetSvgMail,
  GetSvgPerson,
  GetSvgMail2,
  GetSvgCurrencyDollar,
  GetSvgBank,
  GetSvgBuilding,
  GetSvgUpload,
} from "../../../images/svglist";
import {
  ALPHANUM,
  EMAIL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  NUMBER_TWO_DECIMAL,
  CUST_TYPE_PERSONAL,
  CUST_TYPE_BUSINESS,
  CUST_TYPE_AGENT,
  COUNTRY_CODES,
  PHONE_NUM,
  NAME_REGEX,
  INVALID_NAME,
  BLACK_SPCL_CHARS_STR,
  BLACK_SPCL_CHARS,
  //MAX_REQ_CREDIT_AMOUNT
} from "../../Helper/Constants";
import { CallApiPost, CallApiGet } from "../../Helper/serviceApi";
import Footer from "../../Layout/Footer";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import Select from "react-select";
import { toBase64 } from "../../Helper/CommonMethods";
import MultipleFileUpload from "../../Utilities/MultipleFileUpload";
const customCss = {
  main: "mb-0",
  header: {
    levelColor: "signin-link mb-0",
  },
  comp: {
    mainDiv: "row mt-3",
    innerDiv: "col-lg-6 mb-2",
    innerLevel: "uploaded-text ms-2",
  },
};
const customData = {
  headerLevel: "Upload General Document",
  defaultName: "Upload General Document",
};
const CustomerRegistration = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [stateCity, setStateCity] = useState();
  const [cityListData, setCityListData] = useState();
  const [photoImg, setPhotoImg] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const modalRef = useRef();
  const navigate = useNavigate();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsPrivacyPolicyAccepted(event.target.checked);
  };

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const {
    fields: generalDocList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "generalDocList",
  });
  useEffect(() => {
    fetchStatesAndCityList();
    setIsBusiness(state?.stateCustType === "BC");
    setIsAgent(state?.stateCustType === "AC");
    setValue("sstExempt", false);
    // setValue("sstExemptStartDate", new  Date());
    switch (state?.stateCustType) {
      case "BC":
        setValue("customerType", CUST_TYPE_BUSINESS);
        break;
      case "PC":
        setValue("customerType", CUST_TYPE_PERSONAL);
        break;
      case "AC":
        setValue("customerType", CUST_TYPE_AGENT);
        break;
      default:
        break;
    }
  }, []);

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
    setCityListData(cityArrList);
  };

  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });
      setCityListData(cityArrList);
    } else {
      setCityListData(cityData);
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    } else {
      setValue("state", "");
    }
  };

  // const toggleIsBusiness = (value) => {
  //   clearErrors();
  //   setIsBusiness(value);
  // };

  const onErrors = (data) => {
    console.error(data);
  };

  const onSuccess = () => {
    navigate("/home");
  };

  const onSubmit = async (data) => {
    setShow(true);
    // data.isSstExempted = data.isSstExempted === "true"  ? true : false;

    data.isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
    if (
      !!data.sstExempt &&
      (data.sstExempt === "true" || data.sstExempt === true)
    )
      data.sstExemptStartDate = new Date();
    else data.sstNumber = "";
    if (isNaN(data.requestedCreditAmt) || data.requestedCreditAmt === "") {
      data.requestedCreditAmt = 0;
    } else {
      data.requestedCreditAmt = parseFloat(data.requestedCreditAmt);
      // if (data.requestedCreditAmt > MAX_REQ_CREDIT_AMOUNT) {
      //   setShow(false);
      //   setIsSuccess(false);
      //   setTitle("Error");
      //   setDescription(
      //     `Requested credit amount cannot be more than RM ${MAX_REQ_CREDIT_AMOUNT}`
      //   );
      //   modalRef.current.click();
      //   return;
      // }
    }

    if (
      data.requestedCreditAmt > 0 &&
      (!data.bankStatement.photo || !data.ssmDoc.photo)
    ) {
      setShow(false);
      setIsSuccess(false);
      setTitle("Error");
      setDescription(
        "Bank statement and SSM document are required to avail credit facility."
      );
      modalRef.current.click();
      return;
    }

    if (data.phone !== "") {
      data.phone = data.personalPhoneCode + data.phone;
    }
    if (data.pickupAttentionPhone !== "") {
      data.pickupAttentionPhone =
        data.pickupPhoneCode + data.pickupAttentionPhone;
    }

    delete data.personalPhoneCode;
    delete data.pickupPhoneCode;

    let resp = await CallApiPost("register-customer", data);
    switch (resp.respCode) {
      case 99:
        setShow(false);
        setIsSuccess(false);
        setTitle("Error");
        setDescription("Server is down");
        modalRef.current.click();
        break;
      case 200:
        setShow(false);
        setIsSuccess(true);
        setTitle("Success");
        if (CUST_TYPE_PERSONAL == data.customerType) {
          setDescription(
            `Thank you for registering with us! Please check your email for a verification link to activate your account.`
          );
        } else {
          setDescription(
            `Thank you for registering with us! We have sent an email to your registered email address with a link to reset your password.`
          );
        }
        modalRef.current.click();
        break;
      default:
        setShow(false);
        setIsSuccess(false);
        setTitle("Error");
        setDescription(resp.respMessage);
        modalRef.current.click();
        break;
    }
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      if (key === "profilePhoto") setPhotoImg(file[0]);
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const ssmDocPlaceHolder = watch(
    "ssmDoc.photoFileName",
    "Upload SSM Document"
  );
  const watchIsSstExempted = watch("sstExempt");
  const onIsExemptChange = (e) => {
    if (e.target.value === "false" || e.target.value === false)
      setValue("sstNumber", "");
  };

  return (
    <div className="backdrop-image-register">
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 p-3">
            <img
              src={logo}
              className="img-fluid mx-auto d-block"
              alt="LinehaulLogo"
              height="100px"
              width="=200px"
            />
            <div className="Signupbg Registerform m-auto py-4 col-md-6">
              <div className="px-4">
                <div className="Form-title mb-1">Register Here!</div>
                {/* <div className="row py-2">
                  <div className="col-md-8">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input radiobtnsty"
                        type="radio"
                        name="registrationType"
                        id="registrationType1"
                        value={CUST_TYPE_PERSONAL}
                        {...register("customerType")}
                        onChange={() => toggleIsBusiness(false)}
                        // defaultChecked
                      />
                      <label className="Form-Lable" htmlFor="inlineRadio1">
                        Personal Use
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input radiobtnsty"
                        type="radio"
                        name="registrationType"
                        id="registrationType2"
                        value={CUST_TYPE_BUSINESS}
                        {...register("customerType")}
                        onChange={() => toggleIsBusiness(true)}
                      />
                      <label className="Form-Lable" htmlFor="inlineRadio2">
                        Business Use
                      </label>
                    </div>
                  </div>
                </div> */}
                <div
                  className="accordion form-register"
                  id="accordionPanelsStayOpenExample"
                >
                  {/** Personal Details */}
                  <div>
                    <h6 className="signin-link mb-0">Personal Details</h6>
                    <div className="clearfix"></div>
                    <div>
                      <div className="accordion-body px-0">
                        <div className="row">
                          <input
                            type="hidden"
                            {...register("password", { value: "" })}
                            value=""
                          />
                          <input
                            type="hidden"
                            {...register("pwdSalt", { value: "" })}
                            value=""
                          />

                          <div className="col">
                            <div className="row">
                              <div className="mb-2">
                                <div className="input-group">
                                  <span
                                    className="input-group-text inputsty mndtryInputSvg"
                                    id="basic-addon1"
                                  >
                                    {GetSvgPerson({ required: false })}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control border-end-0 pe-1"
                                    placeholder="Name"
                                    aria-label="Name"
                                    aria-describedby="basic-addon1"
                                    name="name"
                                    {...register("name", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: NAME_REGEX,
                                        message: INVALID_NAME,
                                      },
                                    })}
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.name && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.name.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="w-100">
                                <div className="input-group">
                                  <span
                                    className="input-group-text inputsty"
                                    id="basic-addon1"
                                  >
                                    {GetSvgPhone({ required: false })}
                                  </span>
                                  <select
                                    name="personalPhoneCode"
                                    {...register("personalPhoneCode")}
                                    defaultValue="+60"
                                    className="form-control country-code-customize"
                                  >
                                    {COUNTRY_CODES.map((cc, index) => (
                                      <option key={index} value={cc.value}>
                                        {cc.label}
                                      </option>
                                    ))}
                                  </select>
                                  <input
                                    type="tel"
                                    className="form-control border-end-0 pe-1"
                                    placeholder="Phone"
                                    aria-label="Phone"
                                    aria-describedby="basic-addon1"
                                    maxLength="17"
                                    name="phone"
                                    {...register("phone", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: PHONE_NUM,
                                        message: PATTERN_ERR_MSG,
                                      },
                                    })}
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.phone && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.phone.message}
                                    </span>
                                  </div>
                                )}
                              </div>

                              {!isBusiness && !isAgent && (
                                <>
                                  <div className="mb-2">
                                    <div className="input-group mt-2">
                                      <span
                                        className="input-group-text inputsty"
                                        id="basic-addon1"
                                      >
                                        {GetSvgMail2({ required: false })}
                                      </span>
                                      <Controller
                                        name="state"
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <Select
                                            options={stateData}
                                            placeholder="Select State"
                                            value={
                                              value
                                                ? stateData.filter((opt) =>
                                                    value.includes(opt.value)
                                                  )
                                                : value
                                            }
                                            onChange={(d) =>
                                              onChange(
                                                d ? d.value : d,
                                                filterCity(d ? d.value : d)
                                              )
                                            }
                                            isSearchable={true}
                                            isClearable={true}
                                            className="form-control  p-0 border-end-0 pe-1"
                                          />
                                        )}
                                        rules={{
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }}
                                      />
                                      <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                        *
                                      </span>
                                    </div>
                                    {errors.state && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; {errors.state.message}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <span
                                        className="input-group-text inputsty"
                                        id="basic-addon1"
                                      >
                                        {GetSvgMail2({ required: false })}
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control border-end-0 pe-1"
                                        placeholder="Post Code"
                                        aria-label="Post Code"
                                        aria-describedby="basic-addon1"
                                        name="postcode"
                                        {...register("postcode", {
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                          pattern: {
                                            value: ALPHANUM,
                                            message: PATTERN_ERR_MSG,
                                          },
                                        })}
                                        maxLength="50"
                                      />
                                      <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                        *
                                      </span>
                                    </div>
                                    {errors.postcode && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; {errors.postcode.message}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="mb-2">
                                <div className="input-group">
                                  <span
                                    className="input-group-text inputsty"
                                    id="basic-addon1"
                                  >
                                    {GetSvgMail({ required: false })}
                                  </span>
                                  <input
                                    type="email"
                                    className="form-control border-end-0 pe-1"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    name="email"
                                    {...register("email", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: EMAIL,
                                        message: PATTERN_ERR_MSG,
                                      },
                                    })}
                                    maxLength="200"
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.email && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.email.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="w-100">
                                <div className="input-group">
                                  <span
                                    className="input-group-text inputsty"
                                    id="basic-addon1"
                                  >
                                    {GetSvgPerson({ required: false })}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control border-end-0 pe-1"
                                    placeholder={
                                      isAgent
                                        ? "Sales Partner IC"
                                        : "Customer IC"
                                    }
                                    aria-label="Customer IC"
                                    aria-describedby="basic-addon1"
                                    name="ic"
                                    {...register("ic", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: ALPHANUM,
                                        message: PATTERN_ERR_MSG,
                                      },
                                    })}
                                    maxLength="50"
                                  />
                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.ic && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.ic.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                              {!isBusiness && !isAgent && (
                                <>
                                  <div className="mb-2">
                                    <div className="input-group mt-2">
                                      <span
                                        className="input-group-text inputsty"
                                        id="basic-addon1"
                                      >
                                        {GetSvgMail2({ required: false })}
                                      </span>
                                      <Controller
                                        name="city"
                                        control={control}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <Select
                                            options={cityListData}
                                            placeholder="Select City"
                                            value={
                                              value
                                                ? cityListData.filter((opt) =>
                                                    value.includes(opt.value)
                                                  )
                                                : value
                                            }
                                            onChange={(d) =>
                                              onChange(
                                                d ? d.value : d,
                                                filterState(d ? d.value : d)
                                              )
                                            }
                                            isSearchable={true}
                                            isClearable={true}
                                            className="form-control  p-0  border-end-0 pe-1"
                                          />
                                        )}
                                        rules={{
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }}
                                      />
                                      <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                        *
                                      </span>
                                    </div>
                                    {errors.city && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; {errors.city.message}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="mb-2">
                                    <div className="input-group">
                                      <span
                                        className="input-group-text inputsty"
                                        id="basic-addon1"
                                      >
                                        {GetSvgMail2({ required: false })}
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control  border-end-0 pe-1"
                                        placeholder="Address"
                                        aria-label="Address"
                                        maxLength="500"
                                        name="address"
                                        {...register("address", {
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                          onChange: (e) => {
                                            setValue(
                                              "pickupAddress",
                                              e.target.value
                                            );
                                            clearErrors("pickupAddress");
                                          },
                                        })}
                                      ></input>
                                      <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                        *
                                      </span>
                                    </div>
                                    {errors.address && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; {errors.address.message}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className="mb-1 file-placeholder w-100 d-flex justify-content-center"
                              {...register("profilePhoto")}
                            >
                              <input
                                type="file"
                                accept="image/png, image/jpeg "
                                onChange={(e) => selectFile("profilePhoto", e)}
                              />
                              {photoImg ? (
                                <img
                                  src={URL.createObjectURL(photoImg)}
                                  alt="img"
                                />
                              ) : (
                                <div>
                                  {GetSvgUpload({ required: false })}
                                  Upload Photo{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** Business Details */}
                  {(isBusiness || isAgent) && (
                    <div>
                      <h6 className="signin-link mb-0">Business Details</h6>
                      <div className="clearfix"></div>
                      <div>
                        <div className="accordion-body px-0">
                          <div className="row">
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgPerson({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control  border-end-0 pe-1"
                                  placeholder="Company Name"
                                  aria-label="Name"
                                  aria-describedby="basic-addon1"
                                  name="companyName"
                                  {...register("companyName", {
                                    required: {
                                      value: isBusiness || isAgent,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    pattern: {
                                      value: NAME_REGEX,
                                      message: INVALID_NAME,
                                    },
                                  })}
                                  maxLength="200"
                                />
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.companyName && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.companyName.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgBuilding({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control  border-end-0 pe-1"
                                  placeholder="Company Regd Number"
                                  aria-label="Phone"
                                  aria-describedby="basic-addon1"
                                  name="companyRegNo"
                                  {...register("companyRegNo", {
                                    required: {
                                      value: isBusiness || isAgent,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    // pattern: {
                                    //   value: COMPANY_REGD_NO_REGEX,
                                    //   message: INVALID_COMPANY_REGD_NO_ERROR_MSG,
                                    // },
                                  })}
                                  maxLength="30"
                                />
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.companyRegNo && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.companyRegNo.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* {isBusiness && (
                            <> */}
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgMail2({ required: false })}
                                </span>
                                <Controller
                                  name="state"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      options={stateData}
                                      placeholder="Select State"
                                      value={
                                        value
                                          ? stateData.filter((opt) =>
                                              value.includes(opt.value)
                                            )
                                          : value
                                      }
                                      onChange={(d) =>
                                        onChange(
                                          d ? d.value : d,
                                          filterCity(d ? d.value : d)
                                        )
                                      }
                                      isSearchable={true}
                                      isClearable={true}
                                      className="form-control  p-0 border-end-0 pe-1"
                                    />
                                  )}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                  }}
                                />
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.state && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.state.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgMail2({ required: false })}
                                </span>
                                <Controller
                                  name="city"
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      options={cityListData}
                                      placeholder="Select City"
                                      value={
                                        value
                                          ? cityListData.filter((opt) =>
                                              value.includes(opt.value)
                                            )
                                          : value
                                      }
                                      onChange={(d) =>
                                        onChange(
                                          d ? d.value : d,
                                          filterState(d ? d.value : d)
                                        )
                                      }
                                      isSearchable={true}
                                      isClearable={true}
                                      className="form-control  p-0  border-end-0 pe-1"
                                    />
                                  )}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                  }}
                                />
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.city && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.city.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgMail2({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control border-end-0 pe-1"
                                  placeholder="Post Code"
                                  aria-label="Post Code"
                                  aria-describedby="basic-addon1"
                                  name="postcode"
                                  {...register("postcode", {
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    pattern: {
                                      value: ALPHANUM,
                                      message: PATTERN_ERR_MSG,
                                    },
                                  })}
                                  maxLength="50"
                                />
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.postcode && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.postcode.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgMail2({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control  border-end-0 pe-1"
                                  placeholder="Address"
                                  aria-label="Address"
                                  maxLength="500"
                                  name="address"
                                  {...register("address", {
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    onChange: (e) => {
                                      setValue("pickupAddress", e.target.value);
                                      clearErrors("pickupAddress");
                                    },
                                  })}
                                ></input>
                                <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                  *
                                </span>
                              </div>
                              {errors.address && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.address.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgPerson({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Sales Representative"
                                  aria-label="Linehaul Sales Representative"
                                  aria-describedby="basic-addon1"
                                  {...register("isrCode", {
                                    pattern: {
                                      value: ALPHANUM,
                                      message: "Please Enter Valid ISR Code",
                                    },
                                  })}
                                />
                              </div>
                              {errors.isrCode && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.isrCode.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="col-md-6 mb-2">
                              <div className="input-group">
                                <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgCurrencyDollar({ required: false })}
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Requested Credit Amount"
                                  aria-label=""
                                  aria-describedby="basic-addon1"
                                  name="requestedCreditAmt"
                                  {...register("requestedCreditAmt", {
                                    pattern: {
                                      value: NUMBER_TWO_DECIMAL,
                                      message: PATTERN_ERR_MSG,
                                    },
                                  })}
                                />
                              </div>
                              {errors.requestedCreditAmt && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.requestedCreditAmt.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            {/* </>
                          )} */}
                            <input
                              type="hidden"
                              name="companyAddress"
                              {...register("companyAddress")}
                            ></input>
                            {/* <div className="col-md-6 mb-2">
                            <div className="input-group">
                              <span className="input-group-text inputsty" id="basic-addon1">
                                {GetSvgMail2({ required: false })}
                              </span>
                              <input
                                type="text"
                                className="form-control  border-end-0 pe-1"
                                placeholder="Company Address"
                                aria-label="Address"
                                maxLength="1000"
                                name="companyAddress"
                                {...register("companyAddress", {
                                  required: {
                                    value: isBusiness,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              ></input>
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">*</span>
                            </div>
                            {errors.companyAddress && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.companyAddress.message}
                                </span>
                              </div>
                            )}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/** Bank Details */}
                  <div>
                    <h6 className="signin-link mb-0">Bank Details</h6>
                    <div className="clearfix"></div>
                    <div className="accordion-body px-0">
                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <div className="input-group">
                            <span
                              className="input-group-text inputsty"
                              id="basic-addon1"
                            >
                              {GetSvgBank({ required: false })}
                            </span>
                            <input
                              type="text"
                              className="form-control  border-end-0 pe-1"
                              placeholder="Bank Name"
                              aria-label=""
                              aria-describedby="basic-addon1"
                              name="bankName"
                              {...register("bankName", {
                                required: {
                                  value: isBusiness || isAgent,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: NAME_REGEX,
                                  message: INVALID_NAME,
                                },
                              })}
                              maxLength="200"
                            />
                            {(isBusiness || isAgent) && (
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            )}
                          </div>
                          {errors.bankName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="input-group">
                            <span
                              className="input-group-text inputsty"
                              id="basic-addon1"
                            >
                              {GetSvgPerson({ required: false })}
                            </span>
                            <input
                              type="text"
                              className="form-control  border-end-0 pe-1"
                              placeholder="Beneficiary Name"
                              aria-label=""
                              aria-describedby="basic-addon1"
                              name="beneficiaryName"
                              {...register("beneficiaryName", {
                                required: {
                                  value: isBusiness || isAgent,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: NAME_REGEX,
                                  message: INVALID_NAME,
                                },
                              })}
                              maxLength="200"
                            />
                            {(isBusiness || isAgent) && (
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            )}
                          </div>
                          {errors.beneficiaryName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.beneficiaryName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="input-group">
                            <span
                              className="input-group-text inputsty"
                              id="basic-addon1"
                            >
                              {GetSvgPerson({ required: false })}
                            </span>
                            <input
                              type="text"
                              className="form-control  border-end-0 pe-1"
                              placeholder="Account Number"
                              aria-label=""
                              aria-describedby="basic-addon1"
                              name="bankAccount"
                              {...register("bankAccount", {
                                required: {
                                  value: isBusiness || isAgent,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: ALPHANUM,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                              maxLength="200"
                            />
                            {(isBusiness || isAgent) && (
                              <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span>
                            )}
                          </div>
                          {errors.bankAccount && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankAccount.message}
                              </span>
                            </div>
                          )}
                        </div>

                        {(isBusiness || isAgent) && (
                          <>
                            <div className="col-lg-6 mb-2">
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("bankStatement", {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "", //,
                                  // required: {
                                  //   value: true,
                                  //   message: REQUIRED_ERR_MSG,
                                  // },
                                })}
                              >
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    selectFile("bankStatement", e)
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {bankStatementPlaceHolder
                                    ? bankStatementPlaceHolder
                                    : "Upload Bank Statement"}
                                </div>
                              </div>
                              {errors.bankStatement && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {REQUIRED_ERR_MSG}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register("ssmDoc", {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "", //,
                                  // required: {
                                  //   value: true,
                                  //   message: REQUIRED_ERR_MSG,
                                  // },
                                })}
                              >
                                <input
                                  type="file"
                                  onChange={(e) => selectFile("ssmDoc", e)}
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: false })}
                                  {ssmDocPlaceHolder
                                    ? ssmDocPlaceHolder
                                    : "Upload SSM Document"}
                                </div>
                              </div>
                              {errors.ssmDoc && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {REQUIRED_ERR_MSG}
                                  </span>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* SST Exemption */}
                  {(isBusiness || isAgent) && (
                    <div>
                      <h6 className="signin-link mb-0">
                        Do you want to avail for SST Exemption?{" "}
                      </h6>
                      <div className="clearfix"></div>
                      <div className="accordion-body px-0 ">
                        <div className="row">
                          <>
                            <div className="col-md-6 mb-2 ">
                              <div className="input-group ">
                                {/*add css class here*/}
                                {/* <span
                                  className="input-group-text inputsty"
                                  id="basic-addon1"
                                >
                                  {GetSvgPerson({ required: false })}
                                </span> */}
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="sstExempt"
                                    id="sstRadio1"
                                    value={true}
                                    checked={
                                      !!watchIsSstExempted
                                        ? watchIsSstExempted === true ||
                                          watchIsSstExempted === "true"
                                        : false
                                    }
                                    {...register("sstExempt")}
                                  />
                                  <label
                                    className="form-check-label text-light"
                                    htmlFor="sstRadio1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="sstExempt"
                                    id="sstRadio2"
                                    value={false}
                                    checked={
                                      !!watchIsSstExempted
                                        ? watchIsSstExempted === false ||
                                          watchIsSstExempted === "false"
                                        : true
                                    }
                                    {...register("sstExempt", {
                                      onChange: onIsExemptChange,
                                    })}
                                  />
                                  <label
                                    className="form-check-label text-light"
                                    htmlFor="sstRadio2"
                                  >
                                    No
                                  </label>
                                </div>

                                {/* <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                *
                              </span> */}
                              </div>
                              {errors.sstExempt && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.sstExempt.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            {(watchIsSstExempted === true ||
                              watchIsSstExempted === "true") && (
                              <div className="col-md-6 mb-2">
                                <div className="input-group">
                                  <span
                                    className="input-group-text inputsty"
                                    id="basic-addon1"
                                  >
                                    {GetSvgPerson({ required: false })}
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control  border-end-0 pe-1"
                                    placeholder="SST Number"
                                    aria-label=""
                                    aria-describedby="basic-addon1"
                                    name="sstNumber"
                                    {...register("sstNumber", {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: ALPHANUM,
                                        message: PATTERN_ERR_MSG,
                                      },
                                    })}
                                    maxLength="200"
                                  />

                                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                                    *
                                  </span>
                                </div>
                                {errors.sstNumber && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.sstNumber.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Upload General Document */}
                  {(isBusiness || isAgent) && (
                    <MultipleFileUpload
                      customData={customData}
                      customCss={customCss}
                      register={register}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      generalDocList={generalDocList}
                      remove={remove}
                      append={append}
                    />
                  )}
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={isPrivacyPolicyAccepted}
                      onChange={handleCheckboxChange}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      <a
                        href="/privacyPolicy"
                        target="_blank"
                        className="text-white"
                      >
                        Please read and accept the privacy policy
                      </a>
                    </label>
                  </div>
                </div>

                <div className="clearfix col-md-4 m-auto mt-3">
                  <button
                    className="btn-primary-search w-100 rounded-pill theme-violet"
                    onClick={handleSubmit(onSubmit, onErrors)}
                    disabled={!isPrivacyPolicyAccepted}
                  >
                    Register
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                  <div className="text-center my-2 signin-link">
                    Already Registered?
                    <Link to="/login"> Sign In here</Link>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={onSuccess}
      />
      <Spinner show={show} />
    </div>
  );
};

export default CustomerRegistration;

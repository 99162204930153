import React, { useState, useEffect } from "react";
import Layout from "../Layout/Layout";
import CustomerOrderList from "./CustomerOrderList";
import ProblemShipmentList from "./ProblemShipmentList";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { initValShipmentDtls } from "./initValShipmentDtls";
import { DownloadFile } from "../Helper/FileStoreService";
import {
  PROBSLIS,
  FTL,
  DELIVERY_SERVICE_ID,
  HANDLING_SERVICE_ID,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  PACKAGING_SERVICE_ID,
  PICKUP_SERVICE_ID,
} from "../../ConfigConstants/ConfigConstants";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import { useForm } from "react-hook-form";
import {
  getKiplForm,
  deliveryEventlog,
  writeLocalStorage,
  permissionCheck,
  exportToExcel,
} from "../Helper/CommonMethods";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_FORMAT_SERVER,
  DATE_FORMAT_CLIENT,
  INIT_FROM_SRVC,
  INIT_TO_SRVC,
  DATE_TIME_ZONE_MOMENT_FORMAT,
  DATE_FORMAT_MOMENT,
  SERVER_ERR_MSG,
  DATE_TIME_FORMAT_MOMENT,
} from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import GetSvgIcon from "../../images/svglist";
import DeliveryEventLog from "./DeliveryEventLog";
import FtlModal from "./FtlModal";
import FullTruckOrderApprovalModal from "./FullTruckOrderApprovalModal";
import FullTruckOrderList from "./FullTruckOrderList";
import { Buffer } from "buffer";
import OrderCancellationLog from "./OrderCancellationLog";
import { Tooltip } from "react-tooltip";
import { getCurrentColumn } from "./OrderListColumns";

export default function CustomerOrderMain() {
  const countInitState = { all: 0, inprogress: 0, delivered: 0 };
  const pageTitle = `Order List`;
  const accId = getUserInfo("acctId");
  const userType = getUserInfo("userType");
  const [spinner, setSpinner] = useState(false);
  const [statusType, setStatusType] = useState("ALL");
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState(null);
  const [orderListCount, setOrderListCount] = useState(countInitState);
  const [moadlConfig, setMoadlConfig] = useState(null);
  const [creditAvl, setCreditAvl] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState();
  const [selectedOrder, setSelectedOrder] = useState();

  const [shipmentDtls, setShipmentDtls] = useState(
    JSON.parse(JSON.stringify(initValShipmentDtls))
  );
  const [waybillList, setWaybillList] = useState([]);
  const [message, setMessage] = useState(null);
  const [rtsFee, setRtsFee] = useState();
  const [sstPercent, setsstPercent] = useState();
  const navigate = useNavigate();
  const resetMessage = () => {
    setTimeout(() => setMessage(""), 50);
  };

  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const [claimOrderId, setClaimOrderId] = useState(null);
  const [claimPalletId, setClaimPalletId] = useState(null);
  const [orgDestAddrs, setOrgDestAddrs] = useState();
  const [listData, setListData] = useState({
    ALL: [],
    Delivered: [],
    Inprogress: [],
    ProblemShipment: [],
  });

  const [searchType, setSearchType] = useState("Shipment");
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "days").format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment().add(7, "days").format(DATE_FORMAT_SERVER)
  );
  const [ftlOrderData, setFtlOrderData] = useState();

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(initAlertConfig);
  const [cancelconfirmConfig, setCancelconfirmConfig] =
    useState(initAlertConfig);
  const [deliveryEventLog, setDeliveryEventLog] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [claimConfirmConfig, setClaimConfirmConfig] = useState(
    JSON.parse(JSON.stringify(initAlertConfig))
  );
  const [claimData, setClaimData] = useState(null);
  const [cancellationLog, setCancellationLog] = useState();
  const [cancellationReason, setCancellationReason] = useState("");
  const [reasonBlank, setReasonBlank] = useState(false);

  const [hideColums, setHideColums] = useState(false);
  const [pageStatus, setPageStatus] = useState("ALL");

  const getOrderListByCustomer = async (statusType, reload = false) => {
    let payload = {
      custId: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ? accId : 0,
      filterType: "ALL",
      searchType: !!searchParams.get("plan") ? "ShipmentByPlan" : searchType,
      fromDate: moment(fromDate).format(DATE_FORMAT_SERVER),
      toDate: moment(toDate).format(DATE_FORMAT_SERVER),
      planId: !!searchParams.get("plan") ? searchParams.get("plan") : 0,
      pltStatus: !!searchParams.get("status") ? searchParams.get("status") : 0,
    };

    let dateDiff = moment(payload?.toDate).diff(
      moment(payload?.fromDate),
      "days"
    );

    if (
      reload &&
      (dateDiff < 0 ||
        payload.fromDate.length === 0 ||
        payload.toDate.length === 0)
    ) {
      payload.fromDate = moment()
        .subtract(2, "months")
        .format(DATE_FORMAT_SERVER);
      payload.toDate = moment().add(1, "month").format(DATE_FORMAT_SERVER);
      setFromDate(moment().subtract(2, "months").format(DATE_FORMAT_SERVER));
      setToDate(moment().add(1, "month").format(DATE_FORMAT_SERVER));
    }

    if (Number(dateDiff) >= 0 || reload) {
      setSpinner(true);
      Promise.all([
        CallApiPost(`getAllOrders`, payload),
        CallApiPost("GetProblemShipmentList", payload),
        // CallApiPost("getAllFtlOrders", payload),
        CallApiGet("GetConfigDataById?id=" + 63),
      ])
        .then(([olData, psData,  prct]) => {
          if (olData.respCode === 200) {
            let deliveryStatusArray = [79];
            let inprogresStatusArray = [80];
            let deliveryListArray = [];
            let inprogressListArray = [];
            let copiedObj = [...olData.respData];

            if (
              (!!olData.respData && olData.respData.length > 0) ||
              (!!psData.respData && psData.respData.length > 0)
              // || (!!ftlData.respData && ftlData.respData.length > 0)
            ) {
              if (!!olData.respData && olData.respData.length > 0) {
                // olData.respData = olData.respData.filter(
                //   (e) => e.orderType !== 513
                // );
                //commenting for not to use pallet status in list page
                // olData.respData.forEach((item) => {
                //   if (item.status === 77) {
                //     item["pallet_status_code"] = 0;
                //     item["pallet_status"] = item.statusText;
                //   } else {
                //     let pallet_status_map = !!item.palletStatusMap
                //       ? item.palletStatusMap[item.waybill_no]
                //         ? item.palletStatusMap[item.waybill_no].split("_")
                //         : item.palletStatusMap[item.orderNo].split("_")
                //       : [];
                //     item["pallet_status_code"] =
                //       pallet_status_map.length > 0 ? pallet_status_map[0] : "";
                //     item["pallet_status"] =
                //       pallet_status_map.length > 0 ? pallet_status_map[1] : "";
                //   }
                // });
                //comment ends here
                deliveryListArray = copiedObj.filter((item) =>
                  deliveryStatusArray.includes(item.status)
                );
                inprogressListArray = copiedObj.filter((item) =>
                  inprogresStatusArray.includes(item.status)
                );
                // fltlListArray = copiedObj.filter((item) =>
                //   ftlStatusArray.includes(item.orderType)
                // );
                // copiedObj = copiedObj.filter((e) => e.orderType !== 513);

                setsstPercent(prct.respData[0].value_num);
              }
            }

            let flData = {
              ALL: !!olData?.respData ? olData?.respData : [],
              Delivered: deliveryListArray,
              Inprogress: inprogressListArray,
              ProblemShipment: !!psData?.respData ? psData?.respData : [],
              // FTL:
              //   !!ftlData?.respData && Array.isArray(ftlData?.respData)
              //     ? ftlData?.respData
              //     : [],
            };
            setListData(flData);

            setOrderListCount({
              all: copiedObj.length,
              inprogress: inprogressListArray.length,
              delivered: deliveryListArray.length,
              problemshipment: psData.respData.length,
              // ftl:
              //   !!ftlData?.respData && Array.isArray(ftlData?.respData)
              //     ? ftlData.respData.length
              //     : 0,
            });

            setOrderList(flData[statusType]);
            if (!!searchParams.get("plan")) {
              searchParams.delete("plan");
              setSearchParams(searchParams);
            }
            setSpinner(false);
          } else {
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.log("Error===>", error);
          setSpinner(false);
        });
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: "From Date should be smaller than To Date",
      });
    }
  };

  const getShipmetDtls = async (type, shipmentData) => {
    setSpinner(true);
    let data = await CallApiGet(
      "getShipmentDetailsById/" + shipmentData?.shipmentId
    );
    if (data.respCode === 200) {
      setShipmentDtls(data.respData);
      if (
        !!data?.respData?.shipmentPallets &&
        data.respData.shipmentPallets.length > 0
      ) {
        let waybillArray = data.respData.shipmentPallets.map((item) => {
          return {
            palletId: item.palletId,
            waybillNo: item.waybillNo,
            weight: item.weight,
            width: item.width,
            height: item.height,
            length: item.length,
          };
        });
        if (["waybill", "claim"].includes(type)) {
          waybillArray = waybillArray.filter(
            (item) => item.waybillNo === shipmentData.waybill_no
          );
        }
        setWaybillList(waybillArray);
        if (type === "claim") {
          setClaimOrderId(data?.respData?.shipmentId);
          setClaimPalletId(waybillArray[0].palletId);
        }
      }
      getOriginDestinationAddress(
        data?.respData?.origin?.sbuId,
        data?.respData?.destination?.sbuId
      );
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const getOriginDestinationAddress = async (originId, destId) => {
    if (originId) {
      let addressDataResp = await CallApiGet(
        "get-org-dest-address?originId=" + originId + "&destId=" + destId
      );

      if (addressDataResp.respCode === 200) {
        setOrgDestAddrs(addressDataResp.respData[0]);
      }
    }
  };

  const loadData = (statusType, reload = false) => {
    setStatusType(statusType);
    getOrderListByCustomer(statusType, reload);
  };

  const filterData = (statusType) => {
    setStatusType(statusType);
    setOrderList(listData[statusType]);
    setPageStatus(statusType);
  };

  const setModalData = (type, data) => {
    setMoadlConfig({
      type: type,
      size: "xl",
      show: true,
      title: type === "order" ? "ORDER DETAILS" : "WAYBILL DETAILS",
    });
    setOrderData(data);
    getShipmetDtls(type, data);
  };

  const openOrderDtls = (type, data) => {
    const url = `/orderDetails?data=${data.shipmentId}`;
    window.open(url, "_blank");
  };

  const handleMoalClose = (reload = false) => {
    setMoadlConfig({
      type: "",
      size: "",
      show: false,
      title: "",
    });
    setShipmentDtls(JSON.parse(JSON.stringify(initValShipmentDtls)));
    setWaybillList([]);
    if (reload === false) {
      setOrderData(null);
    }
  };

  const reloadShipmentData = (type) => {
    setModalData(type, orderData);
    setTimeout(() => loadData(statusType, true), 50);
  };

  const conformCancel = (shipment) => {
    setCancelconfirmConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Confirm Cancellation",
      description: "Are you sure you want to cancel the order",
    });
    setCancellationReason(null);
    setReasonBlank(false);
    setSelectedOrder(shipment);
  };
  const cancelShipment = async () => {
    let reason = document.getElementById("cancellation-reason").value;
    setReasonBlank(!reason);
    if (!reason) {
      return;
    } else {
      let modalType = "order";
      hideCancelConfirmAlert();
      setSpinner(true);
      const custId = selectedOrder?.customerId;
      let data = await CallApiPost(`cancelShipment`, {
        // shipmentId: shipmentId,
        shipmentId: selectedOrder.shipmentId,
        customerId: custId,
        remarks: reason,
        origin: selectedOrder.origin.sbuId,
      });
      if (data.respCode === 200) {
        // orderData.status = 77;
        // setOrderData(orderData);
        reloadShipmentData(modalType);
        setSpinner(false);
        setMessage("Shipment Cancelled Successfully");
        resetMessage();
      } else {
        showAlert({
          isSuccess: false,
          title: "Error",
          description: data.respMessage || data.respData?.ret_message,
        });
        setSpinner(false);
        setMessage("!!Some Error Occured");
        reloadShipmentData(modalType);
        resetMessage();
      }
      setCancellationReason(null);
      setReasonBlank(false);
    }
  };

  const orderCancellationLog = async (orderId) => {
    setSpinner(true);
    let cancellationLogData = await CallApiGet(
      `cancelShipmentAudit/${orderId}`
    );
    setSpinner(false);
    if (cancellationLogData.respCode === 200) {
      let data = cancellationLogData.respData;
      setCancellationLog(data);
    } else if (cancellationLogData.respCode === 500) {
      showAlert({
        isSuccess: false,
        title: "",
        description: cancellationLogData.respMessage,
      });
    } else {
      setCancellationLog([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const handelRtsClick = async (shipmentId) => {
    setSpinner(true);
    document.body.click();
    setSelectedOrderId(shipmentId);
    let data = await CallApiGet(
      "get-rts-fee?shipmentId=" +
        shipmentId +
        "&customerId=" +
        getUserInfo("acctId")
    );
    if (data.respCode === 200) {
      setSpinner(false);

      let credAvl =
        +data.respData.curr_credit_limit >= +data.respData.fee ? true : false;
      setCreditAvl(credAvl);
      setValue("paymentBy", credAvl ? "CREDIT" : "CASH");
      setRtsFee(data.respData);
      handleMoalClose();
      setConfirmAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "Create RTS",
        description: "Create RTS",
      });
    } else {
      setSpinner(false);
    }
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };
  const hideCancelConfirmAlert = () => {
    setCancelconfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const hideClaimConfirm = () => {
    setClaimConfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const confirmAlert = async (shipmentId, paymentType) => {
    //get default service
    let serviceCode;
    const custId = getUserInfo("acctId") ? parseInt(getUserInfo("acctId")) : 0;
    let slaListResp = await CallApiPost(
      "ListServiceForBooking?argCustId=" + custId,
      {}
    );
    if (slaListResp.respCode === 200) {
      serviceCode = slaListResp.respData.filter((e) => e.serviceDefault)[0]
        .serviceCode;

      let dataModel = {
        shipmentId: selectedOrderId,
        payType: getValues("paymentBy"),
        userId: getUserInfo("userId"),
        serviceCode: serviceCode,
      };
      let resp = await CallApiPost("createRts", dataModel);
      if (resp.respCode === 200) {
        hideConfirmAlert();
        // reloadShipmentData("order");

        if (resp.respData.responseData.InvoiceId > 0) {
          document.getElementById("paymentForm").innerHTML = getKiplForm(
            resp.respData.paymentGateway,
            selectedOrderId
          );
          document.getElementById("onlinepayment").submit();
        } else {
          loadData("ALL", true);
          showAlert({
            isSuccess: true,
            title: "Success",
            description: "RTS Created Successfully",
          });
        }
      } else {
        hideConfirmAlert();
        showAlert({
          isSuccess: false,
          title: "",
          description: resp.respMessage,
        });
      }
    }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const downloadAttachments = (attachments) => {
    if (attachments && attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        if (attachments[i]) {
          DownloadFile(attachments[i]);
        }
      }
    }
  };

  useEffect(() => {
    setHideColums(
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) ? true : false
    );

    if (!!searchParams.get("plan")) {
      setFromDate(null);
      setToDate(null);
    }
    let pageName = searchParams.get("page");
    if (pageName) loadData(pageName);
    else loadData(statusType);
  }, []);

  // const showClaimForm = (data) => {
  //   if (!data.hasOwnProperty("shipmentId") || !data.shipmentId) {
  //     data["shipmentId"] = data?.orderId;
  //     delete data["shipmentid"];
  //   }
  //   document.body.click();
  //   getShipmetDtls("claim", data);
  // };

  const showClaimForm = (argData) => {
    fetchClaimCnt(argData);
  };

  const navToClaimRaisePage = (argData) => {
    if (argData?.shipmentId === null || argData?.shipmentId === undefined) {
      argData = JSON.parse(JSON.stringify(claimData));
    }
    let encoded = Buffer.from(
      JSON.stringify({
        claimId: 0,
        orderId: argData?.shipmentId,
        palletId: 0,
        orderNo: argData?.orderNo,
        origin: argData?.origin?.sbuCode,
        destination: argData?.destination?.sbuCode,
        shipDt: moment(argData?.shipmentDate).format(DATE_FORMAT_MOMENT),
        estDt: moment(argData?.estDeliveryDate).format(DATE_FORMAT_MOMENT),
        ordSts: argData?.statusText,
      })
    ).toString("base64");
    //window.open(`/raiseClaim?data=${encoded}`, "_blank");
    navigate(`/raiseClaim?data=${encoded}`);
  };

  const fetchClaimCnt = async (argData) => {
    setSpinner(true);
    const uri = "ValidateClaimRaise?orderId=" + argData?.shipmentId;
    const resp = await CallApiGet(uri);
    if (resp.respCode === 200 && !Number.isNaN(parseInt(resp.respData))) {
      let claimCnt = parseInt(resp.respData);
      if (claimCnt === 0) {
        navToClaimRaisePage(argData);
      } else if (claimCnt > 0 && claimCnt < 3) {
        setClaimData(argData);
        document.body.click();
        setClaimConfirmConfig({
          isSuccess: false,
          size: "md",
          show: true,
          title: "Claim Request Confirmation",
          description: "You are left with " + (3 - claimCnt) + " more attempts",
        });
      } else {
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description:
            "Sorry, You can not proceed further as your request has been rejected 3 times already!!",
        });
      }
    } else {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    }
    setSpinner(false);
  };

  const cancelClaimForm = () => {
    setClaimOrderId(null);
    setClaimPalletId(null);
  };

  const showProblemShipmentAction = async (actionType, pData) => {
    let data = await CallApiPost(
      "problemshipmentAction/" + actionType + "/" + pData.order_no
    );
    if (data.respCode === 0) {
    }
  };

  const actionOnOrder = (type, data) => {
    if (type === "cancel") {
      cancelOrder(data);
    }
    if (type === "resolve") {
      resolveProblem(data);
    }
  };

  const cancelOrder = async (rowData) => {
    let payload = {
      shipmentId: rowData?.shipmentid,
      customerId: rowData?.customer_id,
      origin: rowData?.origin?.sbuId,
    };
    setSpinner(true);
    let data = await CallApiPost(`cancelShipment`, payload);
    if (
      data.respCode === 200 &&
      data.respData.length > 0 &&
      data.respData[0]?.ret_code === 0
    ) {
      let resolvePayload = {
        problemId: rowData?.id,
        remark: "Resolved",
      };
      let resolveRes = await CallApiPost(`problemresolve`, resolvePayload);
      if (resolveRes.respCode === 200 && resolveRes.respData.length > 0) {
        loadData("ProblemShipment", true);
        showAlert({
          isSuccess: true,
          title: "Success",
          description: "Order Cancelled Successfully",
        });
        setSpinner(false);
      }
    } else {
      showAlert({
        isSuccess: false,
        title: "Error",
        description: data.respMessage || data.respData?.ret_message,
      });
      setSpinner(false);
    }
  };

  const resolveProblem = async (rowData) => {
    let payload = {
      problemId: rowData?.id,
      remark: "Resolved",
    };
    setSpinner(true);
    let data = await CallApiPost(`problemresolve`, payload);
    if (data.respCode === 200) {
      loadData("ProblemShipment", true);
      showAlert({
        isSuccess: true,
        title: "Success",
        description: "Problem resolved successfully",
      });
      setSpinner(false);
    } else {
      showAlert({
        isSuccess: false,
        title: "Error",
        description: data.respMessage,
      });
      setSpinner(false);
    }
  };

  const handelRepeatBooking = (id) => {
    setSpinner(true);
    fetchOrderData(id)
      .then((res) => {
        setSpinner(false);
        navigate("/bookingStnd?orderId=" + id + "&type=STB");
      })
      .catch((e) => {
        setSpinner(false);
        showAlert({ isSuccess: false, title: "ERROR", description: e.message });
      });
  };

  /**
   * The function `fetchOrderData` fetches shipment details and performs some operations on the data
   * before returning it.
   * @param shipmentId - The `shipmentId` parameter is the unique identifier for a specific shipment. It
   * is used to fetch the details of the shipment from the API.
   * @returns The function `fetchOrderData` returns the `initBknData` object.
   */
  const fetchOrderData = async (shipmentId) => {
    const resp = await CallApiGet("getShipmentDetailsById/" + shipmentId);
    if (resp.respCode === 200 && !!resp.respData) {
      const data = resp.respData;
      let initBknData = {};
      setUpShipmentData(data, initBknData);
      const selectedSla = {
        shipOn: moment(
          initBknData?.shipDate,
          DATE_TIME_ZONE_MOMENT_FORMAT,
          true
        ).format(DATE_FORMAT_SERVER),
      };
      writeLocalStorage("initialBookingData", JSON.stringify(initBknData));
      writeLocalStorage("BookingSla", JSON.stringify(selectedSla));
      writeLocalStorage("customerType", data.customerTypeId);
      // writeLocalStorage("slaList", JSON.stringify(argAllSla));
      return initBknData;
    } else {
      throw new Error("Sorry, failed to fetch order data");
    }
  };

  /**
   * The function `setUpShipmentData` sets up shipment data based on the input data and initializes the
   * `initBknData` object.
   * @param data - The `data` parameter is an object that contains various properties related to a
   * shipment. It includes information such as pickup address, delivery address, shipment VAS (Value
   * Added Services), sender address, recipient address, origin, destination, customer ID, service
   * code, order type code, ship date, insurance
   * @param initBknData - An object that stores the initial booking data.
   */
  const setUpShipmentData = (data, initBknData) => {
    const tempFromSrvc = [...INIT_FROM_SRVC];
    const tempToSrvc = [...INIT_TO_SRVC];
    if (data?.shipmentVas?.some((vas) => vas.serviceId === PICKUP_SERVICE_ID)) {
      initBknData.isPickUp = true;
      const argFromService = tempFromSrvc.map((srvc) => {
        if (srvc.value === PICKUP_SERVICE_ID) {
          return { ...srvc, isChecked: true };
        } else {
          return { ...srvc, isChecked: false };
        }
      });
      initBknData.fromService = argFromService;
      initBknData.fromAdrData = data?.pickupAddress;
      initBknData.pickupAddress = data?.pickupAddress;
    } else {
      initBknData.isPickUp = false;
      const argFromService = tempFromSrvc.map((srvc) => {
        if (srvc.value === PICKUP_SERVICE_ID) {
          return { ...srvc, isChecked: false };
        } else {
          return { ...srvc, isChecked: true };
        }
      });
      initBknData.fromService = argFromService;
      initBknData.fromAdrData = {
        address: null,
        city: null,
        state: null,
        postcode: null,
      };
      initBknData.pickupAddress = null;
    }

    if (
      data?.shipmentVas?.some((vas) => vas.serviceId === DELIVERY_SERVICE_ID)
    ) {
      initBknData.isDelivery = true;
      const argToService = tempToSrvc.map((srvc) => {
        if (srvc.value === DELIVERY_SERVICE_ID) {
          return { ...srvc, isChecked: true };
        } else {
          return { ...srvc, isChecked: false };
        }
      });
      initBknData.toService = argToService;
      initBknData.toAdrData = data?.deliveryAddress;
      initBknData.deliveryAddress = data?.deliveryAddress;
    } else {
      initBknData.isDelivery = false;
      const argToService = tempToSrvc.map((srvc) => {
        if (srvc.value === DELIVERY_SERVICE_ID) {
          return { ...srvc, isChecked: false };
        } else {
          return { ...srvc, isChecked: true };
        }
      });
      initBknData.toService = argToService;
      initBknData.toAdrData = {
        address: null,
        city: null,
        state: null,
        postcode: null,
      };
      initBknData.deliveryAddress = null;
    }
    const argHdlPallets = data?.shipmentVas?.filter(
      (vas) => vas.serviceId === HANDLING_SERVICE_ID
    );
    const argPckPallets = data?.shipmentVas?.find(
      (vas) => vas.serviceId === PACKAGING_SERVICE_ID
    );
    initBknData.picHandlingPallets =
      argHdlPallets.length > 0
        ? argHdlPallets?.find((obj) => obj.svcInfo1 === "P")?.pallets
        : null;
    initBknData.delHandlingPallets =
      argHdlPallets.length > 0
        ? argHdlPallets?.find((obj) => obj.svcInfo1 === "D")?.pallets
        : null;
    initBknData.packagingPallets = !!argPckPallets
      ? argPckPallets?.pallets
      : null;
    initBknData.senderAddress = data?.senderAddress;
    initBknData.recipientAddress = data?.recipientAddress;
    initBknData.origin =
      data?.origin?.sbuCity +
      " , " +
      data?.origin?.sbuState +
      "(" +
      data?.origin?.sbuCode +
      ")";
    initBknData.sbuFrom = data?.origin?.sbuId;
    initBknData.fromSbuCode = data?.origin?.sbuCode;
    initBknData.destination =
      data?.destination?.sbuCity +
      " , " +
      data?.destination?.sbuState +
      "(" +
      data?.destination?.sbuCode +
      ")";
    initBknData.sbuTo = data?.destination?.sbuId;
    initBknData.toSbuCode = data?.destination?.sbuCode;
    initBknData.customerId = data?.customerId;
    const srvcCode = JSON.parse(data?.serviceCode);
    initBknData.bkngService = srvcCode?.label;
    initBknData.serviceCode = srvcCode?.value;
    initBknData.isPickupToggler = srvcCode?.isPickup;
    initBknData.isDeliveryToggler = srvcCode?.isDelivery;
    initBknData.option = data?.orderTypeCode;
    initBknData.shipDate = moment().add(2, "d").format(DATE_FORMAT_SERVER);
    initBknData.insurancePkgId = data?.insurancePkgId;
    initBknData.pallets = data?.pallets;
    initBknData.doReturn = data?.doReturn;
    initBknData.packageDesc = data?.packageDesc;
    writeLocalStorage("customerId", data?.customerId);
  };

  const getDeliveryEventLog = async (wb) => {
    setSpinner(true);
    let deliveryEventLogData = await deliveryEventlog(wb);
    setSpinner(false);
    if (deliveryEventLogData.respCode === 200) {
      let data = deliveryEventLogData.respData;
      setDeliveryEventLog(data);
    } else if (deliveryEventLogData.respCode === 500) {
      showAlert({
        isSuccess: false,
        title: "",
        description: deliveryEventLogData.respMessage,
      });
    } else {
      setDeliveryEventLog([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const showFTLApprovalModal = async (data) => {
    let res = await CallApiGet(
      "getFtlOrderDetails?shipmentId=" + data.orderId
    );
    if (res.respCode === 200) {
      res.respData.orderInfo = JSON.parse(res.respData.orderInfo);
      res.respData.attachments = JSON.parse(res.respData.attachments);

      let ftlOrdInfo = {};
      res.respData.orderInfo.forEach((e) => {
        if (e.infoCode === 176 && e.infoValue === "pickup")
          ftlOrdInfo.pickup = true;
        if (e.infoCode === 176 && e.infoValue === "delivery")
          ftlOrdInfo.delivery = true;
        if (e.infoCode === 171) ftlOrdInfo.distance = e.infoValue;
      });
      res.respData.infoData = ftlOrdInfo;
      setFtlOrderData(res.respData);
      document.body.click();
      setMoadlConfig({
        type: "FTL",
        size: "xl",
        show: true,
        title: "Full Truck",
      });
    }
    // console.log("row==",data)
  };
  const handelFtlRequest = async (id, amount, remark, type, msg) => {debugger
    const payload = {
      id: id,
      amount: amount,
      action: type,
      remark: remark,
      userId: getUserInfo("userId"),
    };
    let res = await CallApiPost("AcceptChartedBooking", payload);

    if (res.respCode === 200) {
      handleMoalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Order " + msg + "ed Successfully",
      });
      loadData("FTL", true);
    } else {
      handleMoalClose();
      showAlert({
        isSuccess: false,
        title: "Error",
        description: res.respMessage,
      });
    }
  };

  const updateOrderBookingStatus = async (orderId) => {
    setSpinner(true);
    let resp = await CallApiGet("updateOrderStatus?orderId=" + orderId);
    if (resp.respCode === 200) {
      loadData("ALL", true);
      setSpinner(false);
    }
  };

  const cancelFtlRequest = async (id, amount, remark, type, msg) => {
    const payload = {
      id: id,
      amount: amount,
      action: type,
      remark: remark,
      userId: getUserInfo("userId"),
    };
    let res = await CallApiPost("CancelChartedBooking", payload);

    if (res.respCode === 200) {
      handleMoalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Order " + msg + "ed Successfully",
      });
      loadData("FTL", true);
    } else {
      handleMoalClose();
      showAlert({
        isSuccess: false,
        title: "Error",
        description: res.respMessage,
      });
    }
  };

  const exportData = () => {
    let columns = getCurrentColumn(pageStatus, hideColums);
    exportToExcel(orderList, columns, "order-lst(" + pageStatus + ").xlsx");
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <section>
          <div className="py-3 m-1 d-flex justify-content-between">
            <div className="d-flex col-auto">
              <div className="col-auto d-flex align-item-center border rounded p-2 me-3 wt-tbl">
                {statusType !== "ProblemShipment" && (
                  <>
                    <div className="col-auto align-self-center me-3">
                      <input
                        className="form-check-input formsty"
                        type="radio"
                        name="filterType"
                        id="inlineRadio1"
                        value="Shipment"
                        checked={searchType === "Shipment" ? true : false}
                        onChange={() => setSearchType("Shipment")}
                      />
                      <label
                        className="form-check-label fw-bold ms-2"
                        htmlFor="inlineRadio1"
                      >
                        Shipment
                      </label>
                    </div>
                    <div className="col-auto align-self-center me-3">
                      <input
                        className="form-check-input formsty"
                        type="radio"
                        name="filterType"
                        id="inlineRadio2"
                        value="Booking"
                        checked={searchType === "Booking" ? true : false}
                        onChange={() => setSearchType("Booking")}
                      />
                      <label
                        className="form-check-label fw-bold ms-2"
                        htmlFor="inlineRadio2"
                      >
                        Booking
                      </label>
                    </div>
                  </>
                )}

                <div className="col-auto align-self-center me-3">
                  <label
                    htmlFor=""
                    className="form-label theme-label me-2"
                    title="This field is mandatory"
                  >
                    From:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!fromDate ? new Date(fromDate) : fromDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setFromDate(d)}
                    onSelect={(d) => setFromDate(d)}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <label
                    htmlFor=""
                    className="form-label theme-label me-2"
                    title="This field is mandatory"
                  >
                    To:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!toDate ? new Date(toDate) : toDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setToDate(d)}
                    onSelect={(d) => setToDate(d)}
                  />
                </div>
              </div>
              <div className="align-self-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet text-nowrap"
                  onClick={() => loadData(statusType)}
                  disabled={!(!!fromDate && toDate)}
                >
                  {GetSvgIcon("Search")}
                </button>
              </div>
            </div>

            <div className="col-auto my-3">
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  text-end mt-2 mb-2 ms-4"
                onClick={exportData}
                data-tooltip-id="csv-export"
              >
                {GetSvgIcon("SpreadSheet")}
              </button>
              <Tooltip
                id="csv-export"
                content="Download to Excel"
                place="top"
                style={{ zIndex: "100" }}
              />
            </div>
          </div>
          <nav>
            <div className="nav nav-tabs">
              <button
                type="button"
                className={
                  statusType === "ALL" ? "nav-link active" : "nav-link"
                }
                onClick={() => filterData("ALL")}
              >
                ALL ({orderListCount.all})
              </button>
              <button
                type="button"
                className={
                  statusType === "Delivered" ? "nav-link active" : "nav-link"
                }
                onClick={() => filterData("Delivered")}
              >
                Delivered ({orderListCount.delivered})
              </button>
              <button
                type="button"
                className={
                  statusType === "Inprogress" ? "nav-link active" : "nav-link"
                }
                onClick={() => filterData("Inprogress")}
              >
                Inprogress ({orderListCount.inprogress})
              </button>
              {permissionCheck(PROBSLIS, "allowedRead") && (
                <button
                  className={
                    statusType === "ProblemShipment"
                      ? "nav-link danger-nav-bg"
                      : "nav-link danger-nav-link"
                  }
                  onClick={() => filterData("ProblemShipment")}
                >
                  Problem Shipment ({orderListCount.problemshipment})
                </button>
              )}
              {/* {permissionCheck(FTL, "allowedRead") && (
                <button
                  className={
                    statusType === "FTL" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => filterData("FTL")}
                >
                  Full Truck ({orderListCount.ftl})
                </button>
              )} */}
            </div>
          </nav>
          <div className="tab-content mt-2">
            {statusType === "ProblemShipment" && (
              <ProblemShipmentList
                problemShipment={orderList}
                downloadAttachments={downloadAttachments}
                showClaimForm={showClaimForm}
                showShipmentAction={showProblemShipmentAction}
                actionOnOrder={actionOnOrder}
                showAlert={showAlert}
                setSpinner={setSpinner}
                loadData={loadData}
              />
            )}
            {/* {statusType === "FTL" && (
              <FullTruckOrderList
                dataFTL={orderList}
                showFTLApprovalModal={showFTLApprovalModal}
                userType={userType}
                updateOrderBookingStatus={updateOrderBookingStatus}
              />
            )} */}
            {!["ProblemShipment", "FTL"].includes(statusType) && (
              <CustomerOrderList
                orderList={orderList}
                setModalData={setModalData}
                showClaimForm={showClaimForm}
                actionOnOrder={actionOnOrder}
                handelRepeatBooking={handelRepeatBooking}
                getDeliveryEventLog={getDeliveryEventLog}
                updateOrderBookingStatus={updateOrderBookingStatus}
                handelRtsClick={handelRtsClick}
                openOrderDtls={openOrderDtls}
                conformCancel={conformCancel}
                setSpinner={setSpinner}
                orderCancellationLog={orderCancellationLog}
                loadData={loadData}
              />
            )}
          </div>
          <DeliveryEventLog data={deliveryEventLog} />
          <OrderCancellationLog data={cancellationLog} />
        </section>
      </Layout>
      {/* {!!moadlConfig && (
        // <ModalLayout
        <ModalLayout
          moadlConfig={moadlConfig}
          handleMoalClose={handleMoalClose}
        >
          {moadlConfig.type === "order" && (
            <CustomerOrderDetails
              orderData={orderData}
              shipmentDtls={shipmentDtls}
              waybillList={waybillList}
              setSpinner={setSpinner}
              reloadShipmentData={() => reloadShipmentData("order")}
              cancelShipment={cancelShipment}
              message={message}
              setMessage={setMessage}
              resetMessage={resetMessage}
              showAlert={showAlert}
              handelRtsClick={handelRtsClick}
              handelRepeatBooking={handelRepeatBooking}
              orgDestAddrs={orgDestAddrs}
              conformCancel={conformCancel}
              showClaimForm={showClaimForm}
              getDeliveryEventLog={getDeliveryEventLog}
            />
          )}
          {moadlConfig.type === "waybill" && (
            <CustomerWaybillDetails
              orderData={orderData}
              shipmentDtls={shipmentDtls}
              waybillList={waybillList}
              setSpinner={setSpinner}
              handleMoalClose={handleMoalClose}
              reloadShipmentData={() => reloadShipmentData("waybill")}
              message={message}
              setMessage={setMessage}
              resetMessage={resetMessage}
            />
          )}

          
          </ModalLayout>
        
      )} */}
      {/* {!!claimOrderId && !!claimPalletId && (
        <ClaimAddEdit
          claimId={claimId}
          claimDetails={claimDetails}
          claimOrderId={claimOrderId}
          claimPalletId={claimPalletId}
          cancelClaimForm={cancelClaimForm}
          showAlert={showAlert}
          setSpinner={setSpinner}
        />
      )} */}
      <ModalConfirmAlert
        confirmAlertConfig={confirmAlertConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={confirmAlert}
      >
        <div>
          <table className="text-start">
            <tbody>
              <tr>
                <td>RTS Fee</td>
                <td>{rtsFee?.fee}</td>
              </tr>
              <tr>
                <td className="pe-3">Available Credit Limit : </td>
                <td>
                  {new Intl.NumberFormat().format(rtsFee?.curr_credit_limit)}
                </td>
              </tr>
              <tr>
                <td className=" text-nowrap ">Payment Type:</td>
                <td className="" colSpan={2}>
                  <div className="form-check form-check-inline  ">
                    <input
                      className="form-check-input formsty"
                      type="radio"
                      name="paymentBy"
                      id="inlineRadio1"
                      value="CASH"
                      {...register("paymentBy")}
                      // disabled={!creditAvl}
                      disabled={true}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Cash
                    </label>
                  </div>
                  <div className="form-check form-check-inline ">
                    <input
                      className="form-check-input formsty"
                      type="radio"
                      name="paymentBy"
                      id="inlineRadio1"
                      value="CREDIT"
                      {...register("paymentBy")}
                      // disabled={!creditAvl}
                      disabled={true}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Credit
                    </label>
                  </div>
                </td>
              </tr>
              {/* <tr><td><div className="">RTS will be on {rtsFee?.rts_charge}% of Order Amount & on {rtsFee?.slaName}({rtsFee?.slaDays}days)</div></td></tr> */}
            </tbody>
          </table>
          <div className="alert-info alert my-2 font-12 text-primary">
            <strong>
              RTS will be on {rtsFee?.rts_charge}% of Order Amount & on{" "}
              {rtsFee?.slaName} ({rtsFee?.slaDays} days)
            </strong>
          </div>
        </div>
      </ModalConfirmAlert>
      <ModalConfirmAlert
        confirmAlertConfig={cancelconfirmConfig}
        hideConfirmAlert={hideCancelConfirmAlert}
        confirmAlert={cancelShipment}
        cancelBtnTxt="Not now"
        confirmBtnTxt="Cancel order"
      >
        <div className="row">
          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Reason <span className="text-danger">&#9733;</span>
            </label>
          </div>
          <div className="col-md-9 mb-2">
            <input
              id="cancellation-reason"
              type="text"
              className="form-control"
              name="cancellationReason"
              value={cancellationReason}
              autoComplete="false"
            />
            {reasonBlank && (
              <div style={{ textAlign: "left" }}>
                <span className="badge bg-danger">
                  &#9888; This field is required.
                </span>
              </div>
            )}
          </div>
        </div>
      </ModalConfirmAlert>
      <ModalConfirmAlert
        confirmAlertConfig={claimConfirmConfig}
        hideConfirmAlert={hideClaimConfirm}
        confirmAlert={navToClaimRaisePage}
      >
        <div>
          <p className="fw-bold">{claimConfirmConfig?.description}</p>
          <p>Are you sure you want to continue?</p>
        </div>
      </ModalConfirmAlert>

      {!!moadlConfig && moadlConfig.type === "FTL" && (
        <FtlModal moadlConfig={moadlConfig} handleMoalClose={handleMoalClose}>
          <FullTruckOrderApprovalModal
            ftlDatas={ftlOrderData}
            handelFtlRequest={handelFtlRequest}
            cancelFtlRequest={cancelFtlRequest}
            sstPercent={sstPercent}
          />
        </FtlModal>
      )}
      <div id="paymentForm"></div>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Layout/Layout";
import LonghaulCommissionList from "./LonghaulCommissionList";
import LonghaulAddEdit, { initObj } from "./LonghaulAddEdit";
import GetSvgIcon from "../../../images/svglist";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";
import UploadLonghaulCommission from "./UploadLonghaulCommission";
import Popup from "../../Layout/Popup";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const LonghaulCommission = () => {
  const [modalShow, setModalShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [vhclTypeOpt, setVhclTypeOpt] = useState([]);
  const [hubAndDepo, sethubAndDepo] = useState([]);
  const [lhCommission, setLhCommission] = useState(
    JSON.parse(JSON.stringify(initObj))
  );
  const [lhCommList, setLhCommList] = useState([]);
  const [commissionId, setCommissionId] = useState(0);
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [showCustomAlert, setShowAlert] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [uploadType, setUploadComm] = useState("");
  const modalRef = useRef();

  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };

  const [alertConfig, setAlertConfig] = useState(initAlertConfig);

  const hideAlert = async () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    setSpinner(true);
    await getLhComm();
    setSpinner(false);
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const handlePostApi = (argResp) => {
    switch (argResp?.respCode) {
      case 200:
        showAlert({
          isSuccess: true,
          title: "SUCCESS",
          description: argResp?.respMessage,
        });
        break;

      case 409:
      case 9:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: argResp?.respMessage,
        });
        break;
      default:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: "Something went wrong. Please try after sometime!!",
        });
        break;
    }
  };

  useEffect(() => {
    setSpinner(true);
    Promise.all([loadVhclTypes(), getHubAndDepoList(), getLhComm()])
      .then(() => setSpinner(false))
      .catch((error) => {
        console.error("Error:", error);
        setSpinner(false);
      });
  }, []);

  const getLhComm = async () => {
    const uri = "GetLongHaulCommList";
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setLhCommList(data.respData);
    } else if (data.respCode === 401) {
      console.error(data);
    } else {
      console.error(data);
    }
  };

  const loadVhclTypes = async () => {
    const uri = "getAllVhclTypes";

    let optArr = [];
    let optObj = {};
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      data.respData.forEach((e) => {
        optObj = {
          label: e.vhclType,
          value: e.id,
        };
        optArr.push(optObj);
      });
      setVhclTypeOpt(optArr);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setVhclTypeOpt([]);
    }
  };

  const getHubAndDepoList = async () => {
    let dataArr = [];
    let data = await CallApiGet("getHubAndDepoList");
    if (data.respCode === 200) {
      data.respData.forEach((element) => {
        dataArr.push({
          value: element.id,
          label: element.sbuCode + "(" + element.sbuName + ")",
          isDisabled: false,
        });
      });
      sethubAndDepo(dataArr);
    }
  };

  const updateLhCommStatus = async () => {
    const uri = "ActiveDeactiveLongHaulComm?id=" + commissionId;
    const response = await CallApiPost(uri, {});
    handlePostApi(response);
    toggleAlert(false);
  };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };
  const handleActiveDeactive = (argRow) => {
    setCommissionId(argRow.id);
    if (argRow.isActive) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate commission for{" "}
          <b>
            {argRow.vhclType} - {argRow.sbuCode}
          </b>
        </>
      );
      setAlertTitle("Deactivate Commission");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate commission for{" "}
          <b>
            {argRow.vhclType} - {argRow.sbuCode}
          </b>
        </>
      );
      setAlertTitle("Activate Commission");
    }
  };

  const fetchAndSetCommission = async (row) => {
    const editData = {
      id: row?.id,
      vhclTypeId: row?.vhclTypeId,
      remarks: row?.remarks,
      commArr: [row],
    };
    setLhCommission(editData);
  };
  const handleAction = (row) => {
    fetchAndSetCommission(row);
    setModalShow(true);
  };

  const handleAddBtnClick = () => {
    setLhCommission(JSON.parse(JSON.stringify(initObj)));
    setModalShow(true);
  };

  const handleCLose = () => {
    setLhCommission(JSON.parse(JSON.stringify(initObj)));
    setModalShow(false);
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const callback = () => {
    getLhComm();
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">LongHaul Commission </div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1 me-2">
              <button
                className="btn btn-outline-secondary theme-violet"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#upload-file-modal"
                onClick={setUploadComm}
              >
                {GetSvgIcon("IconArrowup")} Upload Commission
              </button>
            </div>
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={handleAddBtnClick}
              >
                {GetSvgIcon("PlusCircle")} Add Commission Details
              </button>
            </div>
            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
          </div>
        </div>
        <LonghaulCommissionList
          lhComm={lhCommList}
          handleAction={handleAction}
          // status={status}
          handleActiveDeactive={handleActiveDeactive}
          toggleAlert={toggleAlert}
        />
        <UploadLonghaulCommission
          openPopup={openPopup}
          uploadCommission={uploadType}
        />
        <Popup
          isSuccess={isSuccess}
          title={title}
          description={description}
          handler={callback}
        />

        <LonghaulAddEdit
          show={modalShow}
          onHide={handleCLose}
          vhclTypeOpts={vhclTypeOpt}
          hubAndDepos={hubAndDepo}
          sethubAndDepo={sethubAndDepo}
          lhCommission={lhCommission}
          handlePostApi={handlePostApi}
        />
        {showCustomAlert && (
          <CustomAlert
            toggleReset={toggleAlert}
            alertTitle={alertTitle}
            alertBody={alertBody}
            callback={updateLhCommStatus}
          />
        )}
      </Layout>
    </>
  );
};

export default LonghaulCommission;

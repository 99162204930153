import React from 'react'
import DataTable from 'react-data-table-component-with-filter';
import { customDataTableStyles } from '../../Helper/Constants';

export default function PartnerApprovalList(props) {
  const { columns, partnerRows } = props;
  return (
    <div>
      <div className="col-md-12 mt-3 customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={partnerRows}

          pagination
          // paginationServer
          // paginationTotalRows={props.totalRows}
          // onChangeRowsPerPage={props.handlePerPageChange}
          // onChangePage={props.handlePageClick}
          striped
          highlightOnHover
          // paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          // paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
          //defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </div>
    </div>
  )
}

/* eslint-disable no-sequences */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  DATE_FORMAT_MOMENT,
  EMPTY_AMOUNT,
  INVALID_VALUE,
  NUMBER_TWO_DECIMAL,
  REQUIRED_ERR_MSG,
  SST_APPLY_DATE,
} from "../Helper/Constants";
import GetSvgIcon, {
  GetSvgBuilding,
  GetSvgPhone,
  GetSvgUpload,
} from "../../images/svglist";
import { permissionCheck, toBase64 } from "../Helper/CommonMethods";
import { FTL, ORDBKNG } from "../../ConfigConstants/ConfigConstants";
import { DownloadFile } from "../Helper/FileStoreService";
import { CallApiPost } from "../Helper/serviceApi";

const FullTruckOrderApprovalModal = (props) => {
  const { ftlDatas, handelFtlRequest, cancelFtlRequest,sstPercent } = props;
  const [amount, setAmount] = useState(0);
  const [dispAmount, setDispAmount] = useState();
  const [isValid, setIsValid] = useState(true);
  const [isRemarkValid, setIsRemarkValid] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [remarkErrMsg, setRemarkErrMsg] = useState("");
  const [remark, setRemark] = useState("");
  const [defultRemark, setDefultRemark] = useState("");
  const [defaultFileName, setDefaultFileName] = useState("Upload Doc");
  const [dispTax, setDispTax] = useState()
  const [dispTotAmount, setDispTotAmount] = useState();
  // const [show, setShow] = useState(false);
  const amountRef = useRef();
  const fileRef = useRef();
  const decpRef = useRef();
  const taxRef = useRef();
  const tamountRef = useRef();
  const cancelStatus = [75, 76, 88];

  useEffect(() => {
    if (
      permissionCheck(FTL, "allowedWrite") &&
      ftlDatas?.shipment.status === 88
    )
      amountRef.current.focus();
      
      if(ftlDatas?.shipment.status !== 88){
        let billData = getBillDtls(ftlDatas?.shipment.shipmentBilling)
      // setDispAmount(ftlDatas?.shipment.shipmentBilling[0].billAmount)
      // setDefultRemark(ftlDatas?.shipment.remarks)
      setDispAmount(billData.amount)
      setDefultRemark(ftlDatas?.shipment.remarks)
      setDispTax(billData.tax)
      setDispTotAmount(billData.totalAmount)
      }
  }, []);
  const handelAcceptButton = () => {
    if (validateAmount()) {
      handelFtlRequest(
        ftlDatas.shipment.shipmentId,
        amount,
        remark,
        "accept",
        "accept"
      );
    }
  };
  const handelRejectButtonClick = () => {
    if (validateRemark())
      handelFtlRequest(
        ftlDatas.shipment.shipmentId,
        amount,
        remark,
        "reject",
        "reject"
      );
  };
  const handelCancelButtonClick = () => {
    if (validateRemark())
      cancelFtlRequest(
        ftlDatas.shipment.shipmentId,
        amount,
        remark,
        "reject",
        "cancel"
      );
  };
  const validateAmount = () => {
    let valid = true;
    if (amount === 0) {
      setIsValid(false);
      setErrMsg(EMPTY_AMOUNT);
      valid = false;
      amountRef.current.focus();
    } else {
      if (!NUMBER_TWO_DECIMAL.test(amount)) {
        setIsValid(false);
        setErrMsg(INVALID_VALUE);
        valid = false;
        amountRef.current.focus();
      }
    }
    if (valid === true) return validateRemark();
  };
  const validateRemark = () => {
    if (!remark) {
      setIsRemarkValid(false);
      setRemarkErrMsg(REQUIRED_ERR_MSG);
      return false;
    } else {
      return true;
    }
  };

  const handelFileSelect = (event) => {
    const file = event.target.files;
    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      setDefaultFileName(fileName);
    }
  };
  const handelOrderAttachment = async () => {
    const file = document.getElementById("attachmentFile").files;
    const desp = document.getElementById("decp").value;

    if (file.length === 0) {
    } else {
      var fileName = file[0].name;
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoRemark: desp,
      };
      const newAttachmentCopy = {
        photo: photo,
        photoFileName: fileName,
        description: desp,
      };
      let attachment = ftlDatas.attachments;
      attachment.push(newAttachmentCopy);
      document.getElementById("attachmentFile").value = "";
      document.getElementById("decp").value = "";
      setDefaultFileName("Upload Doc");

      ftlDatas.attachments = attachment;
      let payload = {
        attachment: newAttachment,
        orderId: ftlDatas.shipment.shipmentId,
      };

      // let data =
      await CallApiPost("addFtlAttachment", payload);

      // if (data.respCode === 200) {
      //   getShipmetDtls(props.orderId);
      // }
    }
  };
  const onAmountChange=(e)=>{
    setAmount(e.target.value)
    setIsValid(true)
    let tax = e.target.value * sstPercent / 100 ;
    setDispTax(tax);
    setDispTotAmount((+e.target.value) + tax);
  }
  const getBillDtls =(bills)=>{
    let billData ={}
    bills.forEach(e => {
      switch (e.particular) {
        case "Shipping Bill":
          billData["amount"] = e.billAmount
          break;
          case "Total Amount":
            billData["totalAmount"] = e.billAmount
            break;
            case "SST Tax":
          billData["tax"] = e.taxAmount
          billData["percent"] = e.svcInfo
          break;
        default:
          break;
      }
    });
    return  billData;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row pt-1 mx-0">
          <div className="col-md-12 px-0">
            <div className="bg-white border border-2 rounded-2 p-2 my-3">
              {/* <div className="theme-blue-txt fw-bold mb-2">Vehicle </div> */}
              <div className="d-flex justify-content-between align-item-center">
                <div className="col grd-bg p-2">
                  <div className="theme-blue-txt fw-bold">
                    Shipping Date :
                    <span className="theme-blue-txt">
                      {ftlDatas?.shipment.shipmentDate
                        ? moment(
                            new Date(ftlDatas?.shipment.shipmentDate)
                          ).format(DATE_FORMAT_MOMENT)
                        : ""}
                      {/* Bandar Puncak Alam , Selangor(DKLG) */}
                    </span>
                  </div>
                </div>
                <div className="col grd-bg ">
                  <div className="theme-blue-txt fw-bold p-2 ">
                    Vehicle Type :{" "}
                    <span className="theme-blue-txt">
                      {ftlDatas?.vehicle.vhclType}
                      {/* Bandar Enstek , Negeri Sembilan(HKLA) */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white border border-2 rounded-2 p-2">
              {/* <div className="theme-blue-txt fw-bold mb-2">Delivery Info</div> */}
              <div className="d-flex justify-content-between align-item-center">
                {/* <div className="col grd-bg p-2">
                  <div className="fw-bold">Sender:</div>
                  <div className="theme-blue-txt">
                    {ftlDatas?.shipment.senderAddress.name}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt">
                      {GetSvgPhone(false)}
                    </span>{" "}
                    {ftlDatas?.shipment.senderAddress.phone}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt me-2">
                      {GetSvgBuilding(false)}
                    </span>
                    {ftlDatas?.shipment.senderAddress.address +
                      ", " +
                      ftlDatas?.shipment.senderAddress.city +
                      ", " +
                      ftlDatas?.shipment.senderAddress.state +
                      ", " +
                      ftlDatas?.shipment.senderAddress.postcode}
                  </div>
                </div>
                <div className="col grd-bg p-2">
                  <div className="fw-bold">Recipient:</div>
                  <div className="theme-blue-txt">
                    {ftlDatas?.shipment.recipientAddress.name}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt">
                      {GetSvgPhone(false)}
                    </span>{" "}
                    {ftlDatas?.shipment.recipientAddress.phone}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt me-2">
                      {GetSvgBuilding(false)}
                    </span>
                    {ftlDatas?.shipment.recipientAddress.address +
                      ", " +
                      ftlDatas?.shipment.recipientAddress.city +
                      ", " +
                      ftlDatas?.shipment.recipientAddress.state +
                      ", " +
                      ftlDatas?.shipment.recipientAddress.postcode}
                  </div>
                </div> */}
              </div>
              <div className="d-flex justify-content-between align-item-center mt-3">
                <div className="col grd-bg p-2">
                  <div className="theme-blue-txt fw-bold">Pickup:</div>
                  <div className="theme-blue-txt">
                    {ftlDatas?.shipment.pickupAddress.name}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt">
                      {GetSvgPhone(false)}
                    </span>{" "}
                    {ftlDatas?.shipment.pickupAddress.phone}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt me-2">
                      {GetSvgBuilding(false)}
                    </span>
                    {ftlDatas?.shipment.pickupAddress.address +
                      ", " +
                      ftlDatas?.shipment.pickupAddress.city +
                      ", " +
                      ftlDatas?.shipment.pickupAddress.state +
                      ", " +
                      ftlDatas?.shipment.pickupAddress.postcode}
                  </div>
                </div>

                <div className="col  grd-bg p-2">
                  <div className="theme-blue-txt fw-bold">Delivery:</div>
                  <div className="theme-blue-txt">
                    {ftlDatas?.shipment.deliveryAddress.name}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt">
                      {GetSvgPhone(false)}
                    </span>{" "}
                    {ftlDatas?.shipment.deliveryAddress.phone}
                  </div>
                  <div className="theme-blue-txt font-12">
                    <span className="theme-violet-txt me-2">
                      {GetSvgBuilding(false)}
                    </span>
                    {ftlDatas?.shipment.deliveryAddress.address +
                      ", " +
                      ftlDatas?.shipment.deliveryAddress.city +
                      ", " +
                      ftlDatas?.shipment.deliveryAddress.state +
                      ", " +
                      ftlDatas?.shipment.deliveryAddress.postcode}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white border border-2 rounded-2 p-2 my-3">
              {/* <div className="theme-blue-txt fw-bold mb-2">Distance </div> */}
              <div className="d-flex justify-content-between align-item-center">
                <div className="col grd-bg p-2">
                  <div className="theme-blue-txt">
                    Shipping Distance:{" "}
                    <span className="fw-bold">
                      {ftlDatas?.infoData.distance} KM
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white border border-2 rounded-2 p-2 my-3">
              {/* <div className="theme-blue-txt fw-bold mb-2">Special permission </div> */}
              <div className="d-flex justify-content-between align-item-center">
                <div className="col grd-bg p-2">
                  <div className="theme-blue-txt">
                    Pickup Special Permission :{" "}
                    <span className="fw-bold">
                      {ftlDatas?.infoData.pickup === true ? "YES" : "NO"}
                    </span>
                  </div>
                </div>
                <div className="col grd-bg p-2">
                  <div className="theme-blue-txt">
                    Delivery Special Permission :{" "}
                    <span className="fw-bold">
                      {ftlDatas?.infoData.delivery === true ? "YES" : "NO"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <>
              <div className="mb-1 mt-3 subheadingtext">Order Attachment </div>
              <div className="wt-tbl border border-2 rounded-2 p-2">
                <div className="row">
                  <div className="col-md-12 mb-2">
                    <div className="clearfix"></div>
                    <div className="row">
                      <div className="col-md-5">
                        <div>
                          <label className="form-label theme-label" htmlFor="">
                            Select Doc
                          </label>
                        </div>
                        <div className="Upload-Photo mb-2 w-100">
                          <input
                            type="file"
                            accept="application/pdf, image/jpeg, image/jpg"
                            id="attachmentFile"
                            ref={fileRef}
                            disabled={
                              !cancelStatus.includes(ftlDatas?.shipment.status)
                            }
                            onChange={(e) => handelFileSelect(e)}
                          />
                          <div
                            className={
                              !cancelStatus.includes(ftlDatas?.shipment.status)
                                ? "uploaded-text opacity-50 ms-2 w-100 "
                                : "uploaded-text ms-2 w-100"
                            }
                          >
                            {GetSvgUpload(true)}
                            {defaultFileName}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div>
                          <label className="form-label theme-label" htmlFor="">
                            Add description{" "}
                           
                          </label>
                        </div>
                        <input
                          id="decp"
                          type="text"
                          className="form-control"
                          name="description"
                          ref={decpRef}
                          // onKeyUp={setFileDescError(true)}
                          disabled={
                            defaultFileName === "Upload Doc" ? true : false
                          }
                        />
                      </div>
                      <div className="col-md-2">
                        <div className="mt-1">
                          <label
                            className="form-label theme-label"
                            htmlFor=""
                          ></label>
                        </div>

                        <button
                          className="btn btn-outline-secondary theme-violet px-4"
                          type="button"
                          disabled={
                            defaultFileName === "Upload Doc" ? true : false
                          }
                          onClick={handelOrderAttachment}
                        >
                          ADD
                        </button>
                      </div>
                    </div>

                    <table className="table">
                      <tbody>
                        {ftlDatas.attachments.map(
                          (item, index) =>
                            item.photoFileName && (
                              <tr key={index}>
                                <td className="col-md-5">
                                  <div className="w-75 break-word">
                                    {item.photoFileName}
                                  </div>
                                </td>
                                <td className="col-md-5">{item.description}</td>
                                <td>
                                  {!item.file && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary theme-violet float-end download-uploaded-file"
                                      onClick={() =>
                                        DownloadFile(item.photoFileName)
                                      }
                                    >
                                      {GetSvgIcon("IconFiledownload")}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </>

            {/* <div className="bg-white border border-2 rounded-2 p-2 mb-3">
            <div className="theme-blue-txt fw-bold mb-2">
              General Instruction
            </div>
          </div> */}
            {!permissionCheck(FTL, "allowedWrite") &&
              permissionCheck(ORDBKNG, "allowedWrite") &&
              cancelStatus.includes(ftlDatas?.shipment.status) && (
                <>
                  {dispAmount &&
                  <div className="row my-3">
                  <div className="col-md-5">
                      <label className="subheadingtext">Amount </label>
                      <input
                        type="text"
                        ref={amountRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispAmount}
                        disabled={ftlDatas?.shipment.status !== 88?true:false}
                        onChange={(e) => (
                          setAmount(e.target.value), setIsValid(true)
                        )}
                      />
                      {!isValid && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errMsg}
                          </span>
                        </div>
                      )}
                    </div>
                    {!moment(ftlDatas?.shipment.shipmentDate).isBefore(SST_APPLY_DATE)&&<>
                    <div className="col">
                      <label className="subheadingtext">SST </label>
                      <input
                        type="text"
                        ref={taxRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispTax}
                        disabled
                        
                      />
                      
                    </div>
                    <div className="col">
                      <label className="subheadingtext">Final Amount </label>
                      <input
                        type="text"
                        ref={tamountRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispTotAmount}
                        disabled
                        
                      />
                      
                    </div>
                    </>}
                    </div>
                    }
                  <div className="row my-3">
                    <div className="col">
                    <label className="subheadingtext">Remark</label>
                    <input
                      type="text"
                      className="form-control border"
                      // placeholder="0.00"
                      onChange={(e) => (
                        // eslint-disable-next-line no-sequences
                        setRemark(e.target.value), setIsRemarkValid(true)
                      )}
                    />
                    {!isRemarkValid && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {remarkErrMsg}
                        </span>
                      </div>
                    )}
                    </div>
                  </div>
                </>
              )}
            {permissionCheck(FTL, "allowedWrite") &&
              // ftlDatas?.shipment.status === 88 && 
              (
                <>
                  <div className="row my-3">
                    <div className="col">
                      <label className="subheadingtext">Amount </label>
                      <input
                        type="text"
                        ref={amountRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispAmount}
                        disabled={ftlDatas?.shipment.status !== 88?true:false}
                        onChange={(e) => (
                          // setAmount(e.target.value), setIsValid(true)
                          onAmountChange(e)
                        )}
                        
                        
                      />
                      {!isValid && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errMsg}
                          </span>
                        </div>
                      )}
                    </div>
                    {!moment(ftlDatas?.shipment.shipmentDate).isBefore(SST_APPLY_DATE)&&<>
                    <div className="col">
                      <label className="subheadingtext">SST </label>
                      <input
                        type="text"
                        ref={taxRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispTax}
                        disabled
                        
                      />
                      
                    </div>
                    <div className="col">
                      <label className="subheadingtext">Final Amount </label>
                      <input
                        type="text"
                        ref={tamountRef}
                        className="form-control border"
                        placeholder="0.00"
                        defaultValue={dispTotAmount}
                        disabled
                        
                      />
                      
                    </div>
                    </>}
                    </div>
                    <div className="row my-3">
                    <div className="col">
                      <label className="subheadingtext">Remark</label>
                      <input
                        type="text"
                        className="form-control border"
                        // placeholder="0.00"
                        disabled={ftlDatas?.shipment.status !== 88?true:false}
                        defaultValue={defultRemark}
                        onChange={(e) => (
                          setRemark(e.target.value), setIsRemarkValid(true)
                        )}
                      />
                      {!isRemarkValid && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {remarkErrMsg}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        {permissionCheck(FTL, "allowedWrite") &&
          ftlDatas?.shipment.status === 88 && (
            <>
              <button
                className="btn btn-danger"
                type="submit"
                onClick={handelRejectButtonClick}
              >
                Reject
              </button>
              <button
                className="btn btn-outline-secondary theme-violet"
                type="submit"
                onClick={handelAcceptButton}
              >
                Accept
              </button>
            </>
          )}
        {!permissionCheck(FTL, "allowedWrite") &&
          permissionCheck(ORDBKNG, "allowedWrite") &&
          cancelStatus.includes(ftlDatas?.shipment.status) && (
            <button
              className="btn btn-danger"
              type="submit"
              onClick={handelCancelButtonClick}
            >
              Cancel FTL
            </button>
          )}
      </div>
    </>
  );
};

export default FullTruckOrderApprovalModal;

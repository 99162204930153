import React, { useEffect, useState } from "react";
import { CallApiPost } from "../../Helper/serviceApi";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { DATE_FORMAT_MOMENT, DATE_FORMAT_SERVER } from "../../Helper/Constants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import CreditNoteDetailsList from "./CreditNoteDetailsList";
import Layout from "../../Layout/Layout";
import ModalLayout from "../../Layout/ModalLayout";
import CreditNoteInvoiceDtls from "./CreditNoteInvoiceDtls";
import { useParams } from "react-router-dom";
import { Row } from "react-bootstrap";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../../ConfigConstants/ConfigConstants";
const { Buffer } = require("buffer");
const customerType = getUserInfo("customerType");

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initialModalConfig = {
  show: false,
  title: "",
  size: "lg",
  data: [],
};

function CreditNoteDetails() {
  const [spinner, setSpinner] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const profileId = getUserInfo("acctId");
  const [customerId, setCustomerId] = useState();
  const [cnList, setCnList] = useState([]);
  const [modalConfig, setModalConfig] = useState(null);
  const [creditData, setCreditData] = useState({});
  const [custName, setCustName] = useState("");
  let params = useParams();
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [openModalConfig, setOpenModalConfig] = useState(
    Object.assign({}, initialModalConfig)
  );
  const custConditionalStyle2 = [
    {
      when: (row) => row.cn_status === "Un-Utilized",
      style: {
        color: "#00964b",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.cn_status === "Utilized",
      style: {
        color: "red",
        fontWeight: 500,
      },
    },
    {
      when: (row) => row.cn_status === "Partially-Utilized",
      style: {
        color: "#f5c30f",
        fontWeight: 500,
      },
    },
  ];
  const invCols = [
    {
      name: "Invoice Date",
      selector: (row) =>
        !!row?.inv_date ? moment(row?.inv_date).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
      // omit: hideColums,
      //
    },
    {
      name: "Invoice No",
      selector: (row) => row?.inv_no,
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row?.inv_amt?.toFixed(2),
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "CN Utilized On",
      selector: (row) =>
        !!row?.inv_utl_on
          ? moment(row?.inv_utl_on).format(DATE_FORMAT_MOMENT)
          : "",
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
  ];
  const openModal = (argData, argTitle) => {
    setOpenModalConfig({
      show: true,
      title: argTitle,
      size: "lg",
      data: argData,
    });
  };

  const openCustCnInv = (data) => {
    setCreditData(data);
    openModal(
      !!data?.inv_datas ? JSON.parse(data?.inv_datas?.value) : [],
      "Credit Note Utilization"
    );
  };
  const cnColumns = [
    {
      name: "Date",
      // selector: (row) => row.cn_date,
      selector: (row) => moment(row.cn_date).format(DATE_FORMAT_MOMENT),
      cell: (row) => moment(row.cn_date).format(DATE_FORMAT_MOMENT),
      // sortable: true,
      filterable: true,
      // omit: hideColums,
      // sortType: (a, b) => {
      //   return new Date(b.values.cn_date) - new Date(a.values.cn_date);
      // },
    },
    {
      name: "CN Number",
      selector: (row) => row.cn_no,
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "CN Details",
      selector: (row) => row.dtls,
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Total CN Amount",
      selector: (row) => row.amount?.toFixed(2),
      sortable: true,
      filterable: true,
      // omit: hideColums,
    },
    {
      name: "Utilized CN Amount",
      selector: (row) => (!!row.utl_amt ? row.utl_amt?.toFixed(2) : "0.00"),

      sortable: true,
      filterable: true,
    },
    {
      name: "Balance CN Amount",
      selector: (row) =>
        !!row.avl_amount ? row.avl_amount?.toFixed(2) : "0.00",
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.cn_status,
      sortable: true,
      filterable: true,
      conditionalCellStyles: custConditionalStyle2,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"invDTLs-" + row.payment_id}
            className="me-3"
            onClick={() => {
              openCustCnInv(row);
            }}
          >
            {GetSvgIcon("EyeFill")}
          </div>
          <Tooltip
            id={"invDTLs-" + row.id}
            content="View Details"
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    getCustCn();
  };
  const handleModalClose = () => {
    setOpenModalConfig({
      size: "lg",
      show: false,
      title: "",
    });
  };
  useEffect(() => {
    const encodedData = params?.data;
    let data;
    if (!!encodedData) {
      const decodedBuffer = Buffer.from(encodedData, "base64");
      const decodedString = decodedBuffer.toString("utf-8");
      data = JSON.parse(decodedString);
    }
    let tdate;
    let fdate;
    let cid;

    if (!!data) {
      tdate = data.toDate;
      fdate = data.fromDate;
      cid = data.custId;
    } else {
      tdate = new Date();
      fdate = new Date(moment().subtract(3, "M"));
      cid = profileId;
    }

    setToDate(tdate);
    setFromDate(fdate);
    setCustomerId(cid);
    if (!!tdate) {
      getCustCn(fdate, tdate, cid);
    } else {
      getCustCn(
        new Date(moment().subtract(3, "y")),
        new Date(moment().add(8, "y")),
        cid
      );
    }
  }, []);
  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        if (!!resp.respData[0]?.total_cn) {
          setCustName(resp.respData[0]?.cust_name);
          setCnList(resp.respData);
        } else {
          setCustName(resp.respData[0]?.cust_name);
          setCnList([]);
        }

        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const getCustCn = async (fromDt, toDt, custId) => {
    setSpinner(true);
    const uri = "creditNoteDtlsById";
    let param = {
      dateFrom: moment(fromDt).format(DATE_FORMAT_SERVER),
      dateTo: moment(toDt).format(DATE_FORMAT_SERVER),
      customerId: custId,
    };
    let resp = await CallApiPost(uri, param);
    handleApiResponse(resp);
    setSpinner(false);
  };

  const reLoadData = () => {
    getCustCn(fromDate, toDate, customerId);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={closePopup}
      >
        <div>
          <div className="row mt-2">
            <div className="col-md-6">
              {/* <div className="border rounded p-2 me-3 wt-tbl float-start">
                <div className="d-flex align-item-center">
                  <div className="col-auto align-self-center d-flex">
                    <label
                      htmlFor=""
                      className="form-label theme-label align-self-center me-2"
                      title="This field is mandatory"
                    >
                      From Date:
                    </label>
                    <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!fromDate ? new Date(fromDate) : fromDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setFromDate(d)}
                      onSelect={(d) => setFromDate(d)}
                      maxDate={new Date(toDate)}
                      // minDate={new Date(moment(toDate).subtract(3,"months"))}
                    />
                  </div>
                  <div className="col-auto align-self-center d-flex">
                    <label
                      htmlFor=""
                      className="form-label theme-label ms-3 align-self-center"
                      title="This field is mandatory"
                    >
                      To Date:
                    </label>
                    <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control "
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!toDate ? new Date(toDate) : toDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setToDate(d)}
                      onSelect={(d) => setToDate(d)}
                      // maxDate={new Date()}
                      minDate={new Date(fromDate)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet text-nowrap float-end ms-3"
                    onClick={() => {
                      reLoadData();
                    }}
                  >
                    {GetSvgIcon("Search")}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <Row className="mt-2">
              <div className="theme-blue border border-1 rounded-2 p-2 ">
                {/* <div className="text-center f"><h3>Current Statement Amount</h3></div> */}
                <div className="row">
                  {!LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(customerType) && (
                    <div className="col my-2 ">
                      <div className="fw-bold ms-2">
                        Customer : <span className="font-18">{custName}</span>
                      </div>
                    </div>
                  )}
                  <div className="col my-2 ">
                    <div className="fw-bold ms-2">
                      Total Credit Note Amount :{" "}
                      <span className="font-18">
                        {"RM "}
                        {!!cnList[0]?.total_cn
                          ? cnList[0]?.total_cn?.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                  <div className="col my-2 ">
                    <div className="fw-bold">
                      Utilized CN Amount :{" "}
                      <span className="font-18">
                        {"RM "}
                        {!!cnList[0]?.total_utl
                          ? cnList[0]?.total_utl?.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                  <div className="col my-2 ">
                    <div className="fw-bold">
                      Available CN Amount :{" RM "}
                      <span className="font-18">
                        {" "}
                        {!!cnList[0]?.total_avl
                          ? cnList[0]?.total_avl?.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>

        <CreditNoteDetailsList cnColumns={cnColumns} cnList={cnList} />
        <ModalLayout
          moadlConfig={openModalConfig}
          handleMoalClose={handleModalClose}
        >
          <CreditNoteInvoiceDtls
            invCols={invCols}
            cnList={openModalConfig?.data}
            creditData={creditData}
          />
        </ModalLayout>
      </Layout>
    </>
  );
}

export default CreditNoteDetails;

import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../images/svglist";
import moment from "moment";
import { DATE_FORMAT_MOMENT, DATE_FORMAT_SERVER } from "../Helper/Constants";
import { Badge, Tab, Tabs } from "react-bootstrap";
import CommissionList from "./CommissionList";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import CommissionUpdateModal from "./CommissionUpdateModal";
import { useForm } from "react-hook-form";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  COMMI,
  LINEHAUL_STAFF_ISR_USER_TYPE,
  LINEHAUL_STAFF_USER_TYPE,
  LINEHAUL_ROLE_DRIVER
} from "../../ConfigConstants/ConfigConstants";
import DriverCommissionList from "./DriverCommissionList";
import DriverDetailsModal from "./DriverDetailsModal";
import DriverCommisionUpdateModal from "./DriverCommisionUpdateModal";
import ModalLayoutFullScreen from "../Layout/ModalLayoutFullScreen";
import IsrCommissionDetailsModal from "./IsrCommissionDetailsModal";
import ModalLayout from "../Layout/ModalLayout";
import { permissionCheck } from "../Helper/CommonMethods";
import CommissionSearch from "./CommissionSearch";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initSearchParam = {
  commYr: moment().year(),
  commMon: moment().month()
}

const CommissionMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [modalConfig, setModalConfig] = useState(null);
  const [modalDriveDtlsConfig, setModalDriveDtlsConfig] = useState(null);
  const [modalIsrDtlsConfig, setModalIsrDtlsConfig] = useState(null);
  const [modalDrivePayConfig, setModalDrivePayConfig] = useState(null);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [commissionData, setCommissionData] = useState();
  const [isrCommissionSummary, setIsrCommissionSummary] = useState();
  const [drvCommissionSummary, setDrvCommissionSummary] = useState();
  const [driverCommissionData, setDriverCommissionData] = useState();
  const [searchParams, setSearchParams] = useState(initSearchParam)
  const [selectedData, setSelectedData] = useState();
  const [showTab, setShowTab] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [isIsr, setIsIsr] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    watch: watch2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    control: control2,
    formState: { errors: errors2 },
  } = useForm();

  const userType = getUserInfo("userType");

  useEffect(() => {
    if(LINEHAUL_STAFF_ISR_USER_TYPE === userType) {
      setIsIsr(true);
    } else if(LINEHAUL_STAFF_USER_TYPE === userType) {
      let roles = JSON.parse(getUserInfo("userRoles"));
      if(roles.some((element) => LINEHAUL_ROLE_DRIVER === element.roleId)) {
        let driverFlag = true;
        setIsDriver(driverFlag);
      } else if (permissionCheck(COMMI, "allowedRead")) {
        setActiveTab("isr");
        setShowTab(["isr", "driver"]);
      }
    } else if (permissionCheck(COMMI, "allowedRead")) {
      setActiveTab("isr");
      setShowTab(["isr", "driver"]);
    }
    setIsLoaded(true);
  }, [])

  useEffect(() => {
    if(isLoaded)
      getCommissionDatas();
  }, [isLoaded]);

  useEffect(() => {
    loadTabData();
  }, [activeTab])

  const columns = [
    {
      name: "Date",
      // width: "10%",
      selector: (row) => moment(row.endDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "ISR",
      // width: 15%",
      selector: (row) => row.isrName + " (" + row.isrId + ")",
      cell: (row) => (
        <>
          <div id={"isr-" + row.id}>{row.isrName + " (" + row.isrId + ")"}</div>
        </>
      ),
      sortable: true,
      filterable: true,
      omit: isIsr,
    },
    {
      name: "Customer",
      // width: "25%",
      selector: (row) => row.custName + " (" + row.custCode + ")",
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice",
      // width: "15%",
      selector: (row) => row.invoice_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice Amount",
      // width: "5%",
      selector: (row) => row.paidAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "Delay In Day(s)",
      // width: "5%",
      selector: (row) => row.delay,
      sortable: true,
      filterable: true,
    },

    {
      name: "Commission Amount",
      // width: "5%",
      selector: (row) => row.commissionAmt,
      sortable: true,
      filterable: true,
    },
  ];

  const driverColumns = [
    {
      name: "Driver",
      // width: "15%",
      selector: (row) => row.staffName + "(" + row.staffCode + ")",
      sortable: true,
      filterable: true,
    },
    {
      name: "Commission",
      selector: (row) => row.commAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "Packaging",
      selector: (row) => row.packingAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "Handling",
      selector: (row) => row.handlingAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "Total",
      // width: "5%",
      selector: (row) => row.commAmount + row.packingAmount + row.handlingAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      width: "5%",

      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => {
              setDriverDtlsModalData(row);
            }}
            data-tooltip-id={"drvDtl-" + row.staffId}
          >
            {GetSvgIcon("EyeFill")}
          </div>
          <Tooltip
            id={"drvDtl-" + row.staffId}
            content={"View Details"}
            place="left"
          />
        </>
      ),
    },
  ];

  const isrSummaryColumns = [
    {
      name: "ISR",
      // width: "15%",
      selector: (row) => row.staffName + "(" + row.staffCode + ")",
      sortable: true,
      filterable: true,
    },
    {
      name: "Total",
      // width: "5%",
      selector: (row) => row.commAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      width: "5%",

      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => {
              setIsrDtlsModalData(row);
            }}
            data-tooltip-id={"isrDtl-" + row.staffId}
          >
            {GetSvgIcon("EyeFill")}
          </div>
          <Tooltip
            id={"isrDtl-" + row.staffId}
            content={"View Details"}
            place="left"
          />
        </>
      ),
    },
  ];

  const driverDtlscolumns = [
    {
      name: "Date",
      // width: "8%",
      selector: (row) => row.commissionDate,
      sortable: true,
      filterable: true,
    },
    {
      name: "Route",
      // width: "15%",
      selector: (row) => row.routeCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Trip Start",
      // width: "15%",
      selector: (row) => row.tripStart,
      sortable: true,
      filterable: true,
    },
    {
      name: "Stops/Addresses",
      // width: "5%",
      selector: (row) => row.tripStops,
      sortable: true,
      filterable: true,
    },
    {
      name: "Pallets",
      // width: "5%",
      selector: (row) => row.totalPallets,
      sortable: true,
      filterable: true,
    },

    {
      name: "Commission Amount",
      // width: "5%",
      selector: (row) => row.commissionAmt,

      sortable: true,
      filterable: true,
    },
    {
      name: "Sub Break Amount",
      // width: "10%",
      selector: (row) => row.subbreakAmt,
      sortable: true,
      filterable: true,
    },
    {
      name: "Handling Amount",
      selector: (row) => row.handlingAmt + " (" + row.palletsHandling + ")",
      sortable: true,
      filterable: true,
    },
    {
      name: "Packaging Amount",
      selector: (row) => row.packingingAmt + " (" + row.palletsPacking + ")",
      sortable: true,
      filterable: true,
    },

    {
      name: "Total",
      selector: (row) => row.commissionAmt + row.subbreakAmt + row.handlingAmt + row.packingingAmt,
      sortable: true,
      filterable: true,
    },
  ];

  const loadIsrCommSummary = async () => {
    setSpinner(true)
    var param = {};
    param.dateFrom = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).format(DATE_FORMAT_SERVER);
    param.dateTo = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).endOf('month').format(DATE_FORMAT_SERVER);
    param.staffId = 0;
    let resps = await CallApiPost("getIsrCommissionsSummary", param);
    if (resps.respCode === 200) {
      setIsrCommissionSummary(resps.respData);
    }
    setSpinner(false);
  }

  const loadDrCommSummary = async () => {
    setSpinner(true)
    var param = {};
    param.dateFrom = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).format(DATE_FORMAT_SERVER);
    param.dateTo = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).endOf('month').format(DATE_FORMAT_SERVER);
    let resps2 = await CallApiPost("getDriverCommissionsSummary", param);
    if (resps2.respCode === 200) {
      setDrvCommissionSummary(resps2.respData);
    }
    setSpinner(false);
  }

  const getCommissionDatas = async () => {
    if(isIsr) {
      await setIsrDtlsModalData();
    } else if(isDriver) {
      await setDriverDtlsModalData();
    } else {
      await loadIsrCommSummary();
      await loadDrCommSummary();
    }
  };

  const setDriverDtlsModalData = async (data = {}) => {
    var params = {};
    params.dateFrom = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).format(DATE_FORMAT_SERVER);
    params.dateTo = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).endOf('month').format(DATE_FORMAT_SERVER);
    params.staffId = isDriver ? getUserInfo("userId") : data.staffId;
    let resps = await CallApiPost("getDriverCommissions", params);
    if (resps.respCode === 200) {
      let commAmt = 0;
      if(data?.commAmount) {
        commAmt = data?.commAmount + data?.packingAmount + data?.handlingAmount
      } else if(!data?.commAmount && resps.respData && resps.respData.length > 0) {
        commAmt = resps.respData.reduce((accumulator, currentValue) => accumulator + currentValue?.commissionAmt + currentValue?.subbreakAmt + currentValue?.packingAmount + currentValue?.handlingAmount, 0);
      }
      const argCommData = {
        driver: data?.staffName,
        month: searchParams?.commMon,
        year: searchParams?.commYr,
        totalComm: commAmt,
        details: resps.respData
      }
      setDriverCommissionData(argCommData);
      if(!isDriver) {
        setModalDriveDtlsConfig({
          size: "xl",
          show: true,
          title: "Driver Commissions Details",
        });
      }
    }
  };

  const setIsrDtlsModalData = async (data = {}) => {
    setSpinner(true);
    var params = {};
    params.dateFrom = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).format(DATE_FORMAT_SERVER);
    params.dateTo = moment({ year: searchParams?.commYr, month: searchParams?.commMon, day: 1 }).endOf('month').format(DATE_FORMAT_SERVER);
    params.staffId = isIsr ? getUserInfo("acctId") : data.staffId;
    let resps = await CallApiPost("getIsrCommissions", params);
    if (resps.respCode === 200) {
      if(!data?.commAmount && resps.respData && resps.respData.length > 0) {
        data.commAmount = resps.respData.reduce((accumulator, currentValue) => accumulator + currentValue?.commissionAmt, 0);
      }
      const argCommData = {
        isr: data?.staffName,
        month: searchParams?.commMon,
        year: searchParams?.commYr,
        totalComm: data?.commAmount,
        details: resps.respData
      }
      setCommissionData(argCommData);
      if(!isIsr) {
        setModalIsrDtlsConfig({
          size: "xl",
          show: true,
          title: "ISR Commissions Details",
        });
      }
    }
    setSpinner(false);
  };

  const handleIsrDtlsModalClose = (reload = false) => {
    setModalIsrDtlsConfig({
      size: "xl",
      show: false,
      title: "Details",
    });
  };

  const setDriverPayModalData = (data) => {
    setModalDrivePayConfig({
      size: "md",
      show: true,
      title: "UPDATE STATUS",
    });
    data.drvId = data.name + " (" + data.staffCode + ")";
    data.payAmount = data.unpaid;
    data.drvPaymentDate = new Date();
    reset2(data);
  };

  const handleDriverDtlsModalClose = (reload = false) => {
    setModalDriveDtlsConfig({
      size: "xl",
      show: false,
      title: "Details",
    });
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const doTabChange = async (key) => {
    setSearchParams(initSearchParam);
    setActiveTab(key);
  }

  const loadTabData = async () => {
    if(activeTab === "isr") {
      await loadIsrCommSummary();
    } else {
      await loadDrCommSummary();
    }
  }

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">COMMISSION </div>
          </div>
          <div className="float-end"></div>
        </div>
        {isIsr && (
          <>
            <CommissionSearch
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              handleSearch={setIsrDtlsModalData}
              handleSearchParam={{}}
            />
            <IsrCommissionDetailsModal
              commissionData={commissionData}
              columns={columns}
              isIsr={isIsr}
            />
          </>
        )}

        {isDriver && (
          <>
            <CommissionSearch
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              handleSearch={setDriverDtlsModalData}
              handleSearchParam={{}}
            />
            <DriverDetailsModal
              commissionData={driverCommissionData}
              columns={driverDtlscolumns}
              isDriver={isDriver}
            />
          </>
        )}

        {!isIsr && !isDriver && (
          <Tabs defaultActiveKey={activeTab} onSelect={doTabChange}>
            {showTab.includes("isr") && (
              <Tab eventKey="isr" title="ISR Commission">
                <CommissionSearch
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  handleSearch={loadIsrCommSummary}
                  handleSearchParam={{}}
                />
                <CommissionList
                    columns={isrSummaryColumns}
                    data={isrCommissionSummary}
                  />
              </Tab>
            )}
            {showTab.includes("driver") && (
              <Tab eventKey="driver" title="Driver Commission">
                <CommissionSearch
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  handleSearch={loadDrCommSummary}
                  handleSearchParam={{}}
                />
                <DriverCommissionList
                  columns={driverColumns}
                  data={drvCommissionSummary}
                />
              </Tab>
            )}
          </Tabs>
        )}
      </Layout>

      {modalIsrDtlsConfig && !isIsr && (
        <ModalLayoutFullScreen
          moadlConfig={modalIsrDtlsConfig}
          handleMoalClose={handleIsrDtlsModalClose}
        >
          <IsrCommissionDetailsModal
            commissionData={commissionData}
            columns={columns}
            isIsr={isIsr}
          />
        </ModalLayoutFullScreen>
      )}

      {modalDriveDtlsConfig && !isDriver && (
        <ModalLayoutFullScreen
          moadlConfig={modalDriveDtlsConfig}
          handleMoalClose={handleDriverDtlsModalClose}
        >
          <DriverDetailsModal
            commissionData={driverCommissionData}
            columns={driverDtlscolumns}
            isDriver={isDriver}
          />
        </ModalLayoutFullScreen>
      )}
    </>
  );
};

export default CommissionMain;

import moment from "moment";
import { DATE_FORMAT_MOMENT } from "../Helper/Constants";
import {
  ORDER_STATUS_CANCEL,
  ORDER_STATUS_PENDINGPAY_REVIEW,
} from "../../ConfigConstants/ConfigConstants";

const CustomerOrderBillingDetails = ({ billingDetails, orderData }) => {
  return (
    <>
      <div className="filled-view-blue bill-details-section">
        <div className="subheadingtext mb-2 d-flex justify-content-between align-item-center">
          <div className="font-18">Billing Details</div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <table className="table">
              <tbody>
                <tr>
                  <td>Bill Number</td>
                  <td align="right">{billingDetails[0]?.billNo}</td>
                </tr>
                <tr>
                  <td>Billing Date</td>
                  <td align="right">
                    {billingDetails[0]?.billDate &&
                      moment(billingDetails[0]?.billDate).format(
                        DATE_FORMAT_MOMENT
                      )}
                  </td>
                </tr>
              </tbody>
              <tbody>
                {billingDetails.length > 0 &&
                  billingDetails.map(
                    (item, index) =>
                      item.billAmount !== 0 &&
                      !!item.particular && (
                        <tr key={index}>
                          <td>{item.particular}</td>
                          <td align="right">
                            {item.billAmount?.toFixed(2)} RM
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <span className="fw-bold">Sub Total </span>
                    <span className="font-12">(Excluding SST)</span>
                  </td>
                  <td align="right">
                    {(
                      billingDetails[0]?.totalBillAmount -
                      billingDetails[0]?.totalTaxAmount
                    ).toFixed(2)}{" "}
                    RM
                  </td>
                </tr>
                <tr>
                  <td>SST </td>
                  <td align="right">
                    {billingDetails[0]?.totalTaxAmount?.toFixed(2)} RM
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="fw-bold">Total Cost </span>
                    <span className="font-12 font-weight-normal">
                      (Including SST)
                    </span>
                  </td>
                  <td align="right">
                    {billingDetails[0]?.totalBillAmount?.toFixed(2)} RM
                  </td>
                </tr>
                {!!orderData && orderData.status !== ORDER_STATUS_CANCEL && (
                  <>
                    <tr>
                      <td>Paid Amount</td>
                      <td align="right">
                        {!ORDER_STATUS_PENDINGPAY_REVIEW.includes(
                          orderData.status
                        )
                          ? billingDetails[0]?.totalBillAmount?.toFixed(2)
                          : "0.00"}{" "}
                        RM
                      </td>
                    </tr>
                    <tr>
                      <th>Remaining Amount</th>
                      <td align="right">
                        {!ORDER_STATUS_PENDINGPAY_REVIEW.includes(
                          orderData.status
                        )
                          ? "0.00"
                          : billingDetails[0]?.totalBillAmount?.toFixed(2)}{" "}
                        RM
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerOrderBillingDetails;

import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component-with-filter";
import { Link, useNavigate } from "react-router-dom";
import GetSvgIcon from "../../../images/svglist";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import { CallApiGet } from "../../Helper/serviceApi";
import CustomPopup from "../../Layout/CustomPopup/CustomPopup";
import Header from "../../Layout/Header";
import Spinner from "../../Layout/Spinner";
import { customDateSort, exportToExcel } from "../../Helper/CommonMethods";
import { Tooltip } from "react-tooltip";

const PartnerManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  useEffect(() => {
    fetchPartnerList();
  }, []);

  const fetchPartnerList = async () => {
    setShow(true);
    let data = await CallApiGet("list-partner");
    if (data.respCode === 200 || data.respCode === 0) {
      setPartnerList(data.respData ? data.respData : []);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setPartnerList([]);
    } else {
      console.error(data);
      setIsOpen(true);
    }
    setShow(false);
  };

  const callback = () => {
    setIsOpen(false);
    navigate("/dashboard", { replace: true });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.partName || row.businessName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Type",
      selector: (row) => row.partType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.partEmail,
      sortable: true,
      filterable: true,
      width: "20%",
    },
    {
      name: "Account Code",
      selector: (row) => row.acctCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Region",
      selector: (row) => row.region,
      sortable: true,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) =>
        row.registrationDate
          ? moment(row.registrationDate).format(DATE_FORMAT_MOMENT)
          : "NA",
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.registrationDate, rowB.registrationDate),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        // <Link to={"/partnerDetails?profileId=" + row.profileId}>
        <>
          <Link
            to="/partnerDetails"
            state={{ profileId: row.profileId, userType: row.partTypeId }}
            data-tooltip-id={"edit-" + row.profileId}
          >
            {GetSvgIcon("EditPencil")}
          </Link>
          <Tooltip id={"edit-" + row.profileId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const exportData = () => {
    exportToExcel(partnerList, columns, "partner_list.csv");
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">
                  Partner Management List
                </div>
              </div>
            </div>
            <div className="float-end d-flex align-items-center mt-1">
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  text-end "
                onClick={exportData}
                data-tooltip-id="csv-export"
              >
                {/* Download */}
                {GetSvgIcon("SpreadSheet")}
              </button>
            </div>
            <div className="col-md-12 mt-5 customized-data-table position-relative">
              <DataTable
                columns={columns}
                data={partnerList}
                pagination
                striped
                highlightOnHover
                customStyles={customDataTableStyles}
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
              />
            </div>
          </main>
        </div>
      </div>
      <Spinner show={show} />
      {isOpen && (
        <CustomPopup
          icon={GetSvgIcon("ExclamationTriangleFill")}
          body="Something went wrong. Please try after sometime!!!"
          callback={callback}
        />
      )}
    </>
  );
};

export default PartnerManagement;

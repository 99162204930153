import React from "react";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import {
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  NUMBER_REGEX,
  TWO_THREE_DECIMAL,
} from "../Helper/Constants";

const RateAddEdit = ({
  config,
  onHide,
  srvcDropdown,
  dropdownData,
  rateTypeList,
  thresholdTypeList,
  selRate,
  onSubmit,
}) => {
  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subRates",
  });

  const watchRateId = watch("rateId");
  const watchCustId = watch("custId");
  const watchRateType = watch("rateTypeId");

  useEffect(() => {
    reset(Object.assign({}, selRate));
  }, [selRate]);

  const handleClose = () => {
    reset();
    onHide();
  };

  const onError = (err) => {
    console.error(err);
  };

  const dropPointConfig = (
    <Row>
      <Col md={4}>
        <label className="form-label theme-label">
          Max Drop Point <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="threshold"
          {...register("threshold", {
            required: {
              value: watchRateType === 750 ? true : false,
              message: "This field is required!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 100,
              message: "Value must less than or equal to 100.",
            },
            validate: {
              isWholeNumber: (value) =>
                watchRateType === 750
                  ? NUMBER_REGEX.test(value) || "Please pass a whole Number!!"
                  : TWO_THREE_DECIMAL.test(value) ||
                    "Please pass numericvalues with max 3 decimal points!!",
            },
          })}
        />
        {errors?.threshold && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.threshold?.message}
            </span>
          </div>
        )}
      </Col>
      <Col md={4}>
        <label className="form-label theme-label">
          Max Drop Point Charges <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="baseCharge"
          {...register("baseCharge", {
            required: {
              value: watchRateType === 750 ? true : false,
              message: "This field is required!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0",
            },
            max: {
              value: 999999999.99,
              message: "Value must be less than or equal to 999999999.99",
            },
          })}
        />
        {errors?.maxDropCharge && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.maxDropCharge?.message}
            </span>
          </div>
        )}
      </Col>
      <Col md={4}>
        <label className="form-label theme-label">
          Charges Per Drop Above Max Drop Point{" "}
          <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="surcharge"
          {...register("surcharge", {
            required: {
              value: watchRateType === 750 ? true : false,
              message: "This field is required!!",
            },
            min: {
              value: watchRateType === 750 ? 1 : 0,
              message: "Value must be greater than or equal to 1",
            },
            max: {
              value: 999999999.99,
              message: "Value must be less than or equal to 999999999.99",
            },
          })}
        />
        {errors?.chargePerDropAboveMax && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.chargePerDropAboveMax?.message}
            </span>
          </div>
        )}
      </Col>
    </Row>
  );

  const containerRateConfig = (
    <Row>
      <Col md={6}>
        <label className="form-label theme-label">
          Unit Price <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="unitPrice"
          {...register("unitPrice", {
            required: {
              value: watchRateType === 751 ? true : false,
              message: "This field is required!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0",
            },
            max: {
              value: 999999999.99,
              message: "Value must be less than or equal to 999999999.99",
            },
          })}
        />
        {errors?.unitPrice && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.unitPrice?.message}
            </span>
          </div>
        )}
      </Col>
      <Col md={6}>
        <label className="form-label theme-label">
          Minimum Volume(M3) <span className="text-danger">&#9733;</span>
        </label>
        <input
          type="number"
          className="form-control"
          name="threshold"
          {...register("threshold", {
            required: {
              value: watchRateType === 751 ? true : false,
              message: "This field is required!!",
            },
            min: {
              value: 0,
              message: "Value must be greater than or equal to 0.",
            },
            max: {
              value: 999999999.99,
              message: "Value must less than or equal to 999999999.99.",
            },
          })}
        />
        {errors?.threshold && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.threshold?.message}
            </span>
          </div>
        )}
      </Col>
    </Row>
  );

  const regionRateConfig = (
    <>
      <Row>
        <Col md={12}>
          <label className="form-label theme-label">
            Minimum Volume(M3) <span className="text-danger">&#9733;</span>
          </label>
          <input
            type="number"
            className="form-control"
            name="threshold"
            {...register("threshold", {
              required: {
                value: watchRateType === 751 ? true : false,
                message: "This field is required!!",
              },
              min: {
                value: 0,
                message: "Value must be greater than or equal to 0.",
              },
              max: {
                value: 999999999.99,
                message: "Value must less than or equal to 999999999.99.",
              },
            })}
          />
          {errors?.threshold && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888; {errors?.threshold?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <div className="col-lg-12">
        <div className="fw-bold mt-4 theme-violet-txt border-bottom">
          Rate Per Region
        </div>
        <Table className="table">
          <thead>
            <tr>
              <th scope="col">Region</th>
              <th scope="col">Unit Price(m3)</th>
            </tr>
          </thead>
          <tbody>
            {fields
              .filter((data) => data.serviceType === "R")
              .map((region, index) => (
                <tr key={region.id}>
                  <td>
                    <span className="text-uppercase text-primary ">
                      {region.serviceCode}
                    </span>
                  </td>
                  <td>
                    <input
                      name={`subRates.${region.index - 1}.charges`}
                      type="number"
                      className="form-control"
                      {...register(`subRates.${region.index - 1}.charges`, {
                        required: {
                          value: watchRateType === 752 ? true : false,
                          message: "This field is required!!",
                        },
                        min: {
                          value: watchRateType === 752 ? 0.1 : 0,
                          message: "Value must be greater than or equal to 0",
                        },
                        max: {
                          value: 999999999.99,
                          message:
                            "Value must be less than or equal to 999999999.99",
                        },
                      })}
                    />
                    {!!errors?.subRates &&
                      errors?.subRates[region.index - 1]?.charges && (
                        <div>
                          <span className="text-danger fw-bold font-12">
                            &#9888;{" "}
                            {
                              errors?.subRates[region.index - 1]?.charges
                                ?.message
                            }
                          </span>
                        </div>
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );

  const renderConfig = (argRateType) => {
    switch (argRateType) {
      case 750:
        return dropPointConfig;
      case 751:
        return containerRateConfig;
      case 752:
        return regionRateConfig;
      default:
        break;
    }
  };

  const Config = ({ rateType }) => {
    let retConfig = renderConfig(rateType);
    return <>{retConfig}</>;
  };

  return (
    <Modal
      size="xl"
      show={config?.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="section-title">{config?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="hidden" name="rateId" {...register("rateId")} />
        <Row>
          <Col md={6}>
            <label className="form-label theme-label">
              Customer <span className="text-danger">&#9733;</span>
            </label>
            <Controller
              name="custId"
              control={control}
              rules={{
                required: { value: true, message: "This field is required!!" },
                min: { value: 1, message: "This field is required!!" },
                pattern: {
                  value: NUMBER_REGEX,
                  message: "This field is required!!",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Select Customer"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  options={dropdownData}
                  value={
                    !!value
                      ? dropdownData?.find((opt) => opt.value === value)
                      : value
                  }
                  onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                />
              )}
            />
            {errors?.custId && (
              <div>
                <span className="text-danger fw-bold font-12">
                  &#9888; This field is mandatory
                </span>
              </div>
            )}
          </Col>
          <Col md={6}>
            <label className="form-label theme-label">
              Rate Type <span className="text-danger">&#9733;</span>
            </label>
            <Controller
              name="rateTypeId"
              control={control}
              rules={{
                required: { value: true, message: "This field is required!!" },
                min: { value: 1, message: "This field is required!!" },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Select Rate Type"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  options={rateTypeList}
                  value={
                    !!value
                      ? rateTypeList?.find((opt) => opt.value === value)
                      : value
                  }
                  onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                  isDisabled={watchRateId > 0}
                />
              )}
            />
            {errors?.rateTypeId && (
              <div>
                <span className="text-danger fw-bold font-12">
                  &#9888; This field is mandatory
                </span>
              </div>
            )}
          </Col>
        </Row>
        <Config rateType={watchRateType} />
        <Row>
          <Col md={12}>
            <label className="form-label theme-label">Remarks</label>
            <input
              type="text"
              className="form-control"
              name="remarks"
              maxLength="100"
              autoComplete="off"
              {...register("remarks", {
                validate: (value) =>
                  BLACK_SPCL_CHARS.test(value) ? false : true,
              })}
            />
            {errors?.remarks && (
              <div>
                <span className="text-danger fw-bold font-12">
                  &#9888;{" "}
                  {"This field contains some Invalid characters " +
                    BLACK_SPCL_CHARS_STR}
                </span>
              </div>
            )}
          </Col>
        </Row>

        <div className="col-lg-12">
          <div className="fw-bold mt-4 theme-violet-txt border-bottom">
            Service Charges
          </div>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">
                  Charges <span className="text-danger">&#9733;</span>
                </th>
                <th scope="col">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {fields
                .filter((data) => data.serviceType === "S")
                .map((srvc, index) => (
                  <tr key={srvc.id}>
                    <th className="text-uppercase text-primary">
                      {/* <input
                        name={`subRates.${srvc.index}.rateId`}
                        type="hidden"
                        value={watchRateId}
                        {...register(`subRates.${srvc.index}.rateId`)}
                      />
                      <input
                        name={`subRates.${srvc.index}.serviceId`}
                        type="hidden"
                        value={srvc.value}
                        {...register(`subRates.${srvc.index}.serviceId`)}
                      />
                      <input
                        name={`subRates.${srvc.index-1}.custId`}
                        type="hidden"
                        value={watchCustId}
                        {...register(`subRates.${srvc.index-1}.custId`, {
                          value: watchCustId,
                        })}
                      /> */}
                      {srvc?.serviceCode}
                    </th>
                    <th>
                      <input
                        name={`subRates.${srvc.index - 1}.charges`}
                        type="number"
                        className="form-control"
                        {...register(`subRates.${srvc.index - 1}.charges`, {
                          required: {
                            value: true,
                            message: "This field is required!!",
                          },
                          min: {
                            value: 0,
                            message: "Value must be greater than or equal to 0",
                          },
                          max: {
                            value: 999999999.99,
                            message:
                              "Value must be less than or equal to 999999999.99",
                          },
                        })}
                      />
                      {!!errors?.subRates &&
                        errors?.subRates[srvc.index - 1]?.charges && (
                          <div>
                            <span className="text-danger fw-bold font-12">
                              &#9888;{" "}
                              {
                                errors?.subRates[srvc.index - 1]?.charges
                                  ?.message
                              }
                            </span>
                          </div>
                        )}
                    </th>
                    <th>
                      <input
                        name={`subRates.${srvc.index - 1}.remarks`}
                        type="text"
                        className="form-control"
                        maxLength="100"
                        autoComplete="off"
                        {...register(`subRates.${srvc.index - 1}.remarks`, {
                          validate: (value) =>
                            BLACK_SPCL_CHARS.test(value) ? false : true,
                        })}
                      />
                      {!!!!errors?.subRates &&
                        errors?.subRates[srvc.index - 1]?.remarks && (
                          <div>
                            <span className="text-danger fw-bold font-12">
                              &#9888;{" "}
                              {"Remove Invalid characters " +
                                BLACK_SPCL_CHARS_STR}
                            </span>
                          </div>
                        )}
                    </th>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          className="theme-violet"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RateAddEdit;

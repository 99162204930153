
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CUSTPRFL, LINEHAUL_ADMIN_USER_TYPE, LINEHAUL_CUSTOMER_USER_TYPE_LIST, LINEHAUL_PARTNER_COMPANY_USER_TYPE, LINEHAUL_PARTNER_DRIVER_USER_TYPE, LINEHAUL_STAFF_USER_TYPE, PARTCOMPPRFL, PARTPERSPRFL, STAFFPRFL } from '../../ConfigConstants/ConfigConstants'
import userDummy from './images/user-dummy.jpg'
import { isAuthorized } from '../Helper/CommonMethods'
import { getUserInfo } from '../AuthUtils/AuthUtils'

function Profile({ toggleReset, userName }) {
  const navigate = useNavigate();


  const handleProfileClick = () => {
    const userType = getUserInfo("userType")
    let element;
    if (isAuthorized(CUSTPRFL) && LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)) {
      element = '/customerProfile';
    }
    else if (isAuthorized(PARTCOMPPRFL) && userType === LINEHAUL_PARTNER_COMPANY_USER_TYPE)
      element = '/companyProfile';
    else if (isAuthorized(PARTPERSPRFL) && userType === LINEHAUL_PARTNER_DRIVER_USER_TYPE)
      element = '/personalProfile';
    else if (isAuthorized(STAFFPRFL) && (userType === LINEHAUL_STAFF_USER_TYPE || userType === LINEHAUL_ADMIN_USER_TYPE)) {
      element = '/staffProfile';
    }
    navigate(element)
  }

  const signout = () => {
    localStorage.clear()
    navigate("/login");
    window.location.reload();
  }

  return (
    <>
      <div className="float-end d-flex justify-content-center align-content-center ht-70">
        <div text='dark' className='text-start align-self-center'>{userName}</div>
        <div className="dropdown text-end align-self-center mx-3">
          {/* Profile Icon */}
          <span className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img src={userDummy} alt="mdo" width="32" height="32" className="rounded-circle border border-2" />
          </span>

          {/* Profile Dropdown Menu */}
          <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
            <li>
              {/* <Link to={element} className="dropdown-item">Profile</Link> */}
              <button className="dropdown-item" onClick={handleProfileClick}>
                Profile
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#alert"
                data-bs-dismiss="dropdown"
                onClick={() => toggleReset(true)}
              >Reset Password</button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              {/* <a className="dropdown-item" href="#">Sign out</a> */}
              <span className="dropdown-item" onClick={signout}>Sign out</span>
            </li>
          </ul>
        </div>

      </div>

    </>
  )
}

export default Profile
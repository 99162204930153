import React from "react";
import { cropToTwoDeciaml } from "../Helper/CommonMethods";
import {
  ALPHABET_REGEX,
  DECIMAL_REGEX,
  NUMBER_SPACE_REGEX,
} from "../Helper/Constants";
import UploadDataFile from "../Helper/DataUploadUtil";

const columns = [
  "Customer Account Code",
  "Rate Type",
  "Max Drop Point",
  "Max Drop Point Charge",
  "Charge Per Drop Above Max",
  "ODA Charges",
  "Chg Address Charges",
  "Region Code",
  "Unit Price",
  "Min M3",
];

const UploadInboundRate = ({ handlePostApiCall, rateTypeList }) => {
  const template = require(`../../assets/templates/InboundOrderRateTemplate.xlsx`);

  const validateFilesData = (fileData) => {
    let isValid = true;
    let custArr = [];
    let custRateTypeMap = new Map();
    let custRegionSet = new Set();

    fileData.forEach((row) => {
      let errMsg = [];

      const custAcctCode = String(row["Customer Account Code"]).trim();
      const rateType = String(row["Rate Type"]).trim();
      const regionCode = String(row["Region Code"]).trim();
      const isRatePerVol = rateTypeList.some(
        (rt) => rt.label === row["Rate Type"] && rt.value !== 750
      );
      //=== Check for more than one rate type for a customer
      if (
        custRateTypeMap.has(`${custAcctCode}`) &&
        custRateTypeMap.get(`${custAcctCode}`) !== rateType
      ) {
        errMsg.push(`Error: ${custAcctCode} has more than one rate type`);
      } else {
        custRateTypeMap.set(custAcctCode, rateType);
      }
      //=== Check for duplicate customer-region combination
      if (isRatePerVol) {
        if (custRegionSet.has(`${custAcctCode}-${regionCode}`)) {
          errMsg.push("Duplicate Customer Region Code combination");
        } else {
          custRegionSet.add(`${custAcctCode}-${regionCode}`);
        }
      }
      columns.forEach((col) => validateEachCol(col, errMsg, row));
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const validateEachCol = (col, errMsg, row) => {
    const isRateByDrop = rateTypeList.some(
      (rt) => rt.label === row["Rate Type"] && rt.value === 750
    );
    const isRateByRegion = rateTypeList.some(
      (rt) => rt.label === row["Rate Type"] && rt.value === 752
    );
    const isRateByVol = !isRateByDrop;
    switch (col) {
      case "Customer Account Code":
        if (row[col] === undefined || row[col] === null || row[col] === "") {
          errMsg.push(col + ": This field is required");
        } else if (!ALPHABET_REGEX.test(row[col])) {
          errMsg.push("Account code has to be an alphanumeric value");
        }
        break;
      case "Rate Type":
        if (row[col] === undefined || row[col] === null || row[col] === "") {
          errMsg.push(col + ": This field is required");
        } else if (!rateTypeList.some((rt) => rt.label === row[col])) {
          errMsg.push(col + ": Please select from dropdown");
        }
        break;
      case "Max Drop Point":
        if (
          isRateByDrop &&
          (row[col] === undefined || row[col] === null || row[col] === "")
        ) {
          errMsg.push(col + ": This field is required");
        } else if (isRateByDrop && !NUMBER_SPACE_REGEX.test(row[col])) {
          errMsg.push("Max Drop Point field can accept only numeric values");
        }
        break;
      case "Max Drop Point Charge":
      case "Charge Per Drop Above Max":
        if (
          isRateByDrop &&
          (row[col] === undefined || row[col] === null || row[col] === "")
        ) {
          errMsg.push(col + ": This field is required");
        } else if (isRateByDrop && !DECIMAL_REGEX.test(row[col])) {
          errMsg.push(col + ": Entered value is not a decimal value");
        }
        break;
      case "ODA Charges":
      case "Chg Address Charges":
        // if (row[col] === undefined || row[col] === null || row[col] === "") {
        //   errMsg.push(col + ": This field is required");
        // } else
        if (!!row[col] && !DECIMAL_REGEX.test(row[col])) {
          errMsg.push(col + ": Entered value is not a decimal value");
        }
        break;
      case "Region Code":
        if (
          isRateByRegion &&
          (row[col] === undefined || row[col] === null || row[col] === "")
        ) {
          errMsg.push(col + ": This field is required");
        } else if (!ALPHABET_REGEX.test(row[col])) {
          errMsg.push("Region code has to be an alphanumeric value");
        }
        break;
      case "Unit Price":
      case "Min M3":
        if (
          isRateByVol &&
          (row[col] === undefined || row[col] === null || row[col] === "")
        ) {
          errMsg.push(col + ": This field is required");
        } else if (isRateByVol && !DECIMAL_REGEX.test(row[col])) {
          errMsg.push(col + ": Entered value is not a decimal value");
        }
        break;
      default:
        break;
    }
  };
  const postExcelFileRead = (fileData) => {
    let baseRateArr = [];
    let targetRateArr = [];
    let custSrvcIdSet = new Set();
    let custSet = new Set();
    fileData.forEach((row, index) => {
      const isRateByDrop = rateTypeList.some(
        (rt) => rt.label === row["Rate Type"] && rt.value === 750
      );
      const isRateByVol = !isRateByDrop;
      const custAcctCode = String(row["Customer Account Code"]).trim();

      let baseRateObj = {
        RowIndex: index,
        ErrorMessage: null,
        rateId: 0,
        custAcctCode: custAcctCode,
        threshold: isRateByDrop
          ? String(row["Max Drop Point"]).trim()
          : cropToTwoDeciaml(String(row["Min M3"]).trim()),
        thresholdType: isRateByDrop ? 761 : 760,
        baseCharge: isRateByDrop
          ? cropToTwoDeciaml(String(row["Max Drop Point Charge"]).trim())
          : 0,
        surcharge: isRateByDrop
          ? cropToTwoDeciaml(String(row["Charge Per Drop Above Max"]).trim())
          : 0,
        rateType: row["Rate Type"],
        unitPrice: isRateByVol
          ? cropToTwoDeciaml(String(row["Unit Price"]).trim())
          : 0,
      };
      if (!custSet.has(custAcctCode)) {
        baseRateArr.push(baseRateObj);
        custSet.add(custAcctCode);
      }

      if (!!row["ODA Charges"] && !custSrvcIdSet.has(`${custAcctCode}-666`)) {
        targetRateArr.push({
          id: 0,
          rateId: 0,
          RowIndex: index,
          ErrorMessage: null,
          custAcctCode: custAcctCode,
          serviceId: 666,
          charges: cropToTwoDeciaml(String(row["ODA Charges"]).trim()),
        });
        custSrvcIdSet.add(`${custAcctCode}-666`);
      }

      if (
        !!row["Chg Address Charges"] &&
        !custSrvcIdSet.has(`${custAcctCode}-667`)
      ) {
        targetRateArr.push({
          id: 0,
          rateId: 0,
          RowIndex: index,
          ErrorMessage: null,
          custAcctCode: custAcctCode,
          serviceId: 667,
          charges: cropToTwoDeciaml(String(row["Chg Address Charges"]).trim()),
        });
        custSrvcIdSet.add(`${custAcctCode}-667`);
      }
      if (isRateByVol) {
        targetRateArr.push({
          id: 0,
          rateId: 0,
          RowIndex: index,
          ErrorMessage: null,
          custAcctCode: custAcctCode,
          serviceId: 669,
          charges: cropToTwoDeciaml(String(row["Unit Price"]).trim()),
          regionCode: String(row["Region Code"]).trim(),
        });
      }
    });
    const fileDataOut = {
      operationType: 0,
      baseRates: baseRateArr,
      targetRates: targetRateArr,
    };
    return fileDataOut;
  };
  const onUploadSuc = () => {
    const resp = {
      respCode: 200,
      respMessage: "Rate Uploaded Successfully!!!",
    };
    handlePostApiCall(resp, "Rate Uploaded Successfully!!!");
  };
  return (
    <UploadDataFile
      dataUploadUrl="SaveInbOrdRate"
      cbValidateData={validateFilesData}
      cbPreUpload={postExcelFileRead}
      onSuccess={onUploadSuc}
      template={template}
      downloadFileName={"InboundOrderRateTemplate.xlsx"}
      onError={handlePostApiCall}
    />
  );
};

export default UploadInboundRate;

import React from "react";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  DATE_TIME_ZONE_MOMENT_FORMAT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../../images/svglist";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const NewsAndBulletinList = (props) => {
  const {
    handleAction,
    handleActiveDeactive,
    toggleReset,
    newsList,
    setNewsId,
  } = props;

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        !!row.stampdate
          ? moment(row.stampdate, DATE_TIME_ZONE_MOMENT_FORMAT, true).format(
              DATE_FORMAT_MOMENT
            )
          : "",
      sortable: true,
      filterable: true,
    },
    {
      name: "Title",
      selector: (row) => row.bulletinTitle,
      sortable: true,
      filterable: true,
    },
    {
      name: "Description",
      selector: (row) => row.bulletinDescription,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => (row?.active ? "In-Active" : "Active"),
    //   cell: (row) => <span>{row?.active ? "In-Active" : "Active"}</span>,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"edit-" + row.id}
            onClick={() => handleAction(row, "EDIT NEWS & BULLETIN")}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={row.id}
            onClick={() => {
              handleActiveDeactive(row);
              toggleReset(true);
              setNewsId(row.id);
            }}
          >
            {row.active === true
              ? GetSvgIcon("BixCircle")
              : GetSvgIcon("BiCheck2Circle")}
          </div>
          {/* <Tooltip
            id={row.id}
            content={row?.active === true ? "Deactivate " : "Activate "}
            place="left"
          /> */}
        </>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={newsList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default NewsAndBulletinList;

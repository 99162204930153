import React from 'react';
import GetSvgIcon from '../../../images/svglist';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const VehicleWidget = (props) => {

    const options = {
        onClick: (e, element) => {
            if (element.length > 0) {
                console.log(e,element)
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
    };

    return (
        <>
            <div className="card mb-4 shadow">
                <div className="card-header h6">
                    <span className="float-start">Vehicle Licence Expiry (Last Three Months)</span>
                    {/* <span className="float-end">{GetSvgIcon('FullscreenWindow')}</span> */}
                </div>
                <div className="card-body">
                    <div className="chart-area">
                        <Line options={options} data={props?.data} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VehicleWidget;
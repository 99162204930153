import React, { useEffect, useState } from "react";
import UploadDataFile from "../../Helper/DataUploadUtil";
import {
  NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR,
  NUMBER_FOUR_DECIMAL,
  NUMBER_TWO_DECIMAL,
  SBU_CODE_LENGTH,
} from "../../Helper/Constants";

const columns = [
  "Origin",
  "Destination",
  "Price Per Pallet",
  "Price Per Trip",
  "Price Per Month",
  "Overweight Surcharge"
];
const UploadRateChart = (props) => {
  let { onSuccess, onUploadErr, service } = props;

  const templateName = "SlaRateChartTemplate.xlsx";
  const template = require(`../../../assets/templates/${templateName}`);
  const [url, setUrl] = useState("");

  useEffect(() => {
   setUrl("UploadRates");
  }, [service]);

  const validateRateData = (fileData) => {
    let isValid = true;
    let orgDestArr = [];
    fileData.forEach((element) => {
      let errMsg = [];
      columns.forEach((col) => {
        if (!element.hasOwnProperty(col)) {
          errMsg.push(col + " is absent, ");
        }
        validateEachCol(col, errMsg, element);
      });
      let orgDestStr = element["Origin"] + "-" + element["Destination"];
      if (orgDestArr.includes(orgDestStr)) {
        if (errMsg.length > 1) {
          errMsg.push(", ");
        }
        errMsg.push("Duplicate Origin Destination");
      } else {
        orgDestArr.push(orgDestStr);
      }

      if (errMsg.length > 0) {
        element.dataValid = false;
        element.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    fileData.sort((e1, e2) => e1.dataValid ? 1 : -1);
    return isValid;
  };
  const validateEachCol = (col, errMsg, row) => {
    switch (col) {
      case "Price Per Pallet":
      case "Price Per Trip":
      case "Price Per Month":
      case "Overweight Surcharge":
        if (!NUMBER_TWO_DECIMAL.test(row[col])) {
          errMsg.push(col + " Invalid values ");
        }
        break;
      case "Origin":
      case "Destination":
        if (row[col].length > SBU_CODE_LENGTH) {
          errMsg.push(
            "Length of " + col + " cannot be greater than " + SBU_CODE_LENGTH
          );
        }
        break;
      default:
        if (!NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
          errMsg.push(col + " contains some invalid character ");
        }
        break;
    }
  };
  const postExcelFileRead = (fileData) => {
    const fileDataOut = [];
    fileData.map((row, index) => {
      let rowOut = {};
      rowOut.ratechartId = service.id;
      rowOut.originSbuCode = row["Origin"];
      rowOut.destSbuCode = row["Destination"];
      rowOut.palletPrice = row["Price Per Pallet"];
      rowOut.tripPrice = row["Price Per Trip"];
      rowOut.monthlyPrice = row["Price Per Month"];
      rowOut.overweightPrice = row["Overweight Surcharge"];
      rowOut.RowIndex=index;
      fileDataOut.push(rowOut);
    });
    return fileDataOut;
  };

  return (
    <UploadDataFile
      dataUploadUrl={url}
      cbValidateData={validateRateData}
      cbPreUpload={postExcelFileRead}
      onSuccess={onSuccess}
      template={template}
      downloadFileName={templateName}
      onError={onUploadErr}
    />
  );
};

export default UploadRateChart;

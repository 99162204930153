import React from "react";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";

const Option = {
  label: "",
  value: "",
};
export default function CustomInputWithChip(props) {

  const { value, setValue, className } = props;
  const [inputValue, setInputValue] = useState("");
  const createOption = (label) => ({
    label,
    value: label,
  });
  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
      case " ":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  return (
    <CreatableSelect
      components={Option}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={(newValue) => setValue(newValue)}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder="Enter Waybill Numbers separated by comma or space or tab or enter"
      value={value}
      className={className}
    />
  );
}

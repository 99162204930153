import React from 'react';
import { 
  monthDropdown,
  yearDropdown,
} from '../Helper/Constants';
import Select from "react-select";
import GetSvgIcon from "../../images/svglist";

const CommissionSearch = (props) => {
    const { searchParams, setSearchParams, handleSearch, handleSearchParam } = props;

    return (
        <div className="mt-2 d-flex align-items-end">
            <div className="me-3">
                <label className="form-label theme-label">Year</label>
                <div>
                    <Select
                    options={yearDropdown}
                    placeholder="Select Year"
                    className="form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    value={yearDropdown.find(
                        (yr) => yr.value === searchParams?.commYr
                    )}
                    onChange={(yr) =>
                        !!yr
                        ? setSearchParams({ ...searchParams, commYr: yr.value })
                        : setSearchParams({ ...searchParams, commYr: null })
                    }
                    />
                </div>
            </div>
            <div className="me-3">
                <label className="form-label theme-label">Month</label>
                <div>
                    <Select
                    options={monthDropdown}
                    placeholder="Select Month"
                    className="form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    value={monthDropdown.find(
                        (mon) => mon.value === searchParams?.commMon
                    )}
                    onChange={(mon) =>
                        !!mon
                        ? setSearchParams({ ...searchParams, commMon: mon.value })
                        : setSearchParams({ ...searchParams, commMon: null })
                    }
                    />
                </div>
            </div>
            <div className="me-3">
                <button
                    className="btn btn-outline-secondary theme-violet text-nowrap mt-4"
                    type="button"
                    onClick={() => handleSearch(handleSearchParam)}
                >
                    {GetSvgIcon("Search")}
                </button>
            </div>
        </div>
    );
}

export default CommissionSearch;
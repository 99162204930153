import React from "react";
import ModalLayout from "../Layout/ModalLayout";
import { Col, Container, Row } from "react-bootstrap";
import {
    DATE_FORMAT_SERVER,
    NET_ERR_MSG, SERVER_ERR_MSG,
    TIME_FORMAT_SERVER
} from "../Helper/Constants";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { CallApiPost } from "../Helper/serviceApi";
import { REQUIRED_ERR_MSG } from "../Helper/Constants";

const LastMileBidModal = ({
  closeBidModal,
  modalConfig,
  vhclTypes,
  initBidData,
  showAlert,
  setSpinner,
  reloadData,
}) => {
  const [bidData, setBidData] = useState();
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setBidData(initBidData);
  }, [initBidData]);

  const validateData = (key, value) => {
    let isValid = true;

    switch (key) {
      
      case "vhclType":
        if (value === 0) {
          setErrors((state) => ({
            ...state,
            [key]: { value: true, message: REQUIRED_ERR_MSG },
          }));
          isValid = false;
        } else {
          setErrors((state) => ({
            ...state,
            [key]: { value: false, message: "" },
          }));
        }
        break;
      default:
        if (!!value && !(value instanceof String) && value <= 0) {
          setErrors((state) => ({
            ...state,
            [key]: { value: true, message: REQUIRED_ERR_MSG },
          }));
          isValid = false;
        } else {
          setErrors((state) => ({
            ...state,
            [key]: { value: false, message: "" },
          }));
        }
        break;
    }
    return isValid;
  };

  const isBidDataValid = (data) => {
    let isValid = [];
    Object.entries(data).forEach(([key, value]) => {
      let vRes = validateData(key, value);
      isValid.push(vRes);
    });
    return isValid.filter((el) => el === false).length > 0 ? false : true;
  };

  const onSubmit = async () => {
    setSpinner(true);
    if (!isBidDataValid(bidData)) {
      setSpinner(false);
      return;
    }
    bidData.tripDate = moment().format(DATE_FORMAT_SERVER);
    bidData.tripTime = moment().format(TIME_FORMAT_SERVER);
    if (bidData.vhclType === -1) bidData.vhclType = 0;
    const res = await CallApiPost("SaveRouteBid", bidData);
    
    closeBidModal();
    switch (res.respCode) {
      case 200:
        showAlert({
          isSuccess: true,
          title: "SUCCESS",
          description: res.respMessage,
        });
        !!reloadData && reloadData();
        break;
      case 500:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: SERVER_ERR_MSG,
        });
        break;
      case 99:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: NET_ERR_MSG,
        });
        break;
      default:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: res.respMessage,
        });
        break;
    }
    setSpinner(false);
  };

  const handleMoalClose = () => {
    closeBidModal();
  };

  const header = (
    <h5 className="modal-title section-title">
      {bidData?.bidId > 0 ? "BID DETAILS" : "INVITE BID"}
    </h5>
  );

  const footer = (
    <button
      className="btn btn-outline-secondary theme-violet text-nowrap mr-5"
      type="button"
      onClick={onSubmit}
    >
      Submit
    </button>
  );

  const handleOnChange = (name, value) => {
    setBidData((state) => ({
      ...state,
      [name]: value,
    }));
    validateData(name, value);
  };

  return (
    <>
      {!!modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          header={header}
          footer={footer}
          handleMoalClose={handleMoalClose}
        >
          <Container>
            <Row>
             
              <Col md="4" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Vehicle Type<span className="text-danger">&#9733;</span>
                </label>
                <Select
                  options={vhclTypes}
                  placeholder="Select Vehicle Type"
                  value={
                    !!bidData?.vhclType
                      ? vhclTypes.filter((opt) => opt.id === bidData?.vhclType)
                      : bidData?.vhclType
                  }
                  getOptionLabel={(option) => option.vhclType}
                  getOptionValue={(option) => option.id}
                  onChange={(d) => handleOnChange("vhclType", !!d ? d.id : d)}
                  isSearchable={true}
                  isClearable={true}
                  classNamePrefix="form-control"
                  className="form-control p-0"
                  isDisabled={bidData?.bidId > 0}
                />
                {!!errors?.vhclType && errors.vhclType.value && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vhclType.message}
                    </span>
                  </div>
                )}
              </Col>
             
              <Col md="8" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Remark<span className="text-danger">&#9733;</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={bidData?.remarks}
                  onChange={(e) => handleOnChange("remarks", e.target.value)}
                />
                {!!errors?.remarks && errors.remarks.value && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.tripPrice.message}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </ModalLayout>
      )}
    </>
  );
};

export default LastMileBidModal;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  DATE_FORMAT_MOMENT,
} from "../Helper/Constants";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { GetSvgDownload } from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";
import { customDateSort } from "../Helper/CommonMethods";

const ApprovalLogList = (props) => {
  const approvalhistoryColumns = [
    {
      name: "Approval Step",
      selector: (row) => row.approvalStep,
      sortable: true,
      filterable: true,
    },
    {
      name: "Approver Role",
      selector: (row) => row.approverRole,
      cell: (row) => (
        <>
          <span data-tooltip-id={"approverRole-" + row.approvalLogId}>{row.approverRole}</span>
          <Tooltip
            id={"approverRole-" + row.approvalLogId}
            content={row.approverRole}
            place="left"
          />
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Approver",
      selector: (row) => row.approver,
      cell: (row) => (
        <>
          <span data-tooltip-id={"approver-" + row.approvalLogId}>
            {row.approver ? row.approver : "NA"}
          </span>
          <Tooltip id={"approver-" + row.approvalLogId} content={row.approver} place="left" />
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status === "REJECT" ? "REJECTED" : row.status),
      sortable: true,
      filterable: true,
    },
    {
      name: "Assigned On",
      selector: (row) => !!row.assignedOn ? moment(row.assignedOn).format(DATE_FORMAT_MOMENT) : "NA",
      filterable: true,
      sortFunction: (rowA, rowB) => customDateSort(rowA.assignedOn, rowB.assignedOn)
    },
    {
      name: "Comments",
      selector: (row) => row.logText,
      cell: (row) => (
        <>
          <span data-tooltip-id={"comments-" + row.approvalLogId}>{row.logText}</span>
          <Tooltip id={"comments-" + row.approvalLogId} content={row.logText} place="left" />
        </>
      ),
      filterable: true,
    },
    {
      name: "Attachment",
      selector: (row) => row.attachmentUrl,
      cell: (row) =>
        row.attachmentUrl ? (
          <>
            <span onClick={() => DownloadFile(row.attachmentUrl)}>
              {GetSvgDownload({ required: false })}
            </span>
          </>
        ) : (
          "NA"
        ),
    },
    {
      name: "Updated On",
      selector: (row) => !!row.loggedOn ? moment(row.loggedOn).format(DATE_FORMAT_MOMENT) : 'NA',
      filterable: true,
      sortFunction: (rowA, rowB) => customDateSort(rowA.loggedOn, rowB.loggedOn)
    },
    {
      name: "Updated By",
      selector: (row) => row.loggedBy,
      sortable: true,
      filterable: true,
    },
  ];

  return (
    <div
      className="modal fade"
      id="approval-history-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Approval History
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="clearfix"></div>
                    <div className="col-md-12 mt-1 popup-data-table position-relative">
                      <DataTable
                        columns={approvalhistoryColumns}
                        data={props.data}

                        pagination
                        striped
                        highlightOnHover
                        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                        paginationPerPage={defaultPageLimit}
                        customStyles={customDataTableStyles}
                        defaultSortAsc={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default border me-2" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalLogList;

import React from 'react'
import Header from '../Layout/Header'

const WorkInProgress = () => {
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12">
            <div className="banner banner0">
              <h2>WORK IN PROGRESS</h2>
              <p className="slogan">You will get to test it soon.</p>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default WorkInProgress
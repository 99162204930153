import React, { useState } from "react";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import DataTable from "react-data-table-component-with-filter";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../images/svglist";
import { invColumns } from "./InvoiceList";
import CreditAndDebitNote from "./CreditAndDebitNote";
import ModalAlert from "../Layout/ModalAlert";
import ModalLayout from "../Layout/ModalLayout";
const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

export default function FinanceSummaryList(props) {
  const {
    paymentStatusBtn,
    setPaymentStatusBtn,
    setModalData,
    setInvoiceDistributionList,
    customerDtls,
    setTotalaBillAmount,
    setSearchParams,
    searchParams,
    custTypeShow,
    financeRow,
    summaryStatus,
    setSummaryStatus,
    setcustomerDtls,
    enableBackBtn,
    fetchAllInvoiceList,
    invoiceSummaryList,
    unBilledInvArr,
    setUpCustDtls,
    setSearchBtn,
  } = props;
  const [alertConfig, setAlertConfig] = useState(
    JSON.parse(JSON.stringify(initAlertConfig))
  );
  const [show, setShow] = useState(false);
  const [custId, setCustId] = useState(0);

  const [modalConfig, setModalConfig] = useState(null);
  const [showProf, setShowProf] = useState(false);
  const [custDtls, setCustDtls] = useState({});

  // useEffect(()=>{
  //   if(!!unBilledInvArr && unBilledInvArr.length>0)
  //       openModal();
  // },[unBilledInvArr])

  const openModal = (type, data) => {
    if (type === "prf") {
      setShowProf(true);
      setCustDtls(data);
    }
    setModalConfig({
      size: type !== "prf" ? "xl" : "lg",
      fullscreen: type !== "prf" ? true : false,
      show: true,
      title: type !== "prf" ? "Un-Billed Details" : "Customer Details",
    });
  };
  const closeModal = () => {
    setShowProf(false);
    setCustDtls({});
    setModalConfig({
      size: "xl",
      fullscreen: false,
      show: false,
      title: "",
    });
  };

  // === Debit and Credit Note modal ===
  const handleClose = () => setShow(false);
  const handleShow = (id, val) => {
    setShow(val);
    setCustId(id);
  };
  // === Debit and Credit Note modal ===

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: true,
      size: "md",
      show: true,
      title: "Success",
      description: data,
    });
  };

  const openInvDtls = (row, type) => {
    fetchAllInvoiceList(row.cust_id, type).then((res) => {
      switch (type) {
        case "BL":
          setSearchBtn(false);
          enableBackBtn();
          setUpCustDtls(row, row.cust_id);
          setSummaryStatus(false);
          break;
        case "UBL":
          openModal("UBL", row);
          break;

        default:
          break;
      }
      // setPaymentStatusBtn(false);
    });
  };
  const columns = [
    {
      name: "Customer",
      selector: (row) => row.cust_name + "(" + row.acct_code + ")",
      cell: (row) => (
        <>
          <div
            className="text-primary cursor-pointer"
            onClick={() => {
              openModal("prf", row);
            }}
          >
            {!!row?.business_name && row?.business_name?.length > 0
              ? row?.business_name + "(" + row.acct_code + ")"
              : row.cust_name + "(" + row.acct_code + ")"}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "300px",
    },
    {
      name: "Op. Balance",
      selector: (row) =>
        ((!!row.old_inv_amt ? row.old_inv_amt : 0) -
        (!!row.old_pmt_amt ? row.old_pmt_amt : 0)).toFixed(2),
    },
    {
      name: "Orders(Cancel)",
      selector: (row) => row.orders + " (" + row.cancelled + ")",
    },
    {
      name: "Total Space",
      selector: (row) => row.total_space + " (" + row.cancelled_space + ")",
    },
    {
      name: "Invoices",
      selector: (row) => row.inv_count,
      sortable: true,
    },
    {
      name: "Total Bill",
      selector: (row) => row.inv_amt,
      sortable: true,
    },
    {
      name: "Total Paid",
      selector: (row) => row.pmt_amt,
    },
    {
      name: "Outstanding",
      selector: (row) =>
        // (
        //   (row.old_inv_amt ? row.old_inv_amt : 0) -
        //   (row.old_pmt_amt ? row.old_pmt_amt : 0) +
        //   row.inv_amt -
        //   row.pmt_amt
        // ).toFixed(2),
        (row.outstand_amount).toFixed(2),
        // row.outstand_amount,
      sortable: true,
    },
    {
      name: "Un-Invoiced Bill",
      selector: (row) => row.unb_amt,
      cell: (row) => (
        <>
          {!!row.unb_amt && row.unb_amt !== 0 ? (
            <div
              className="action-item-button"
              onClick={() => {
                openInvDtls(row, "UBL");
              }}
            >
              {row.unb_amt}
            </div>
          ) : (
            <div>{0.0}</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"invDTLs-" + row.cust_id}
            className="me-3"
            onClick={() => {
              openInvDtls(row, "BL");
            }}
          >
            {GetSvgIcon("PaymentDetails")}
          </div>
          <Tooltip
            id={"invDTLs-" + row.cust_id}
            content="View Details"
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"CD-" + row.cust_id}
            className="me-3"
            onClick={() => handleShow(row.cust_id, true)}
          >
            {GetSvgIcon("debitCreditNote")}
          </div>
          <Tooltip id={"CD-" + row.cust_id} content="ADD CN/DN" place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "20px",
    },
  ];

  const onSuccess = (msg) => {
    setShow(false);
    showAlert(msg);
  };
  return (
    <>
      <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
        <DataTable
          columns={columns}
          data={financeRow}
          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          defaultSortFieldId={1}
          defaultSortAsc={false}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>

      {!!show && (
        <CreditAndDebitNote
          show={show}
          handleClose={handleClose}
          custId={custId}
          onSuccess={onSuccess}
        />
      )}
      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}
      {!!modalConfig && (
        <ModalLayout moadlConfig={modalConfig} handleMoalClose={closeModal}>
          {showProf === false ? (
            <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
              <DataTable
                columns={invColumns}
                data={unBilledInvArr}
                customStyles={customDataTableStyles}
                pagination
                striped
                highlightOnHover
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
              />
            </div>
          ) : (
            <>
              <div className="d-flex">
                <div className="col-6 grd-bg p-2 rounded-3">
                  <label className="font-12">Name : </label>
                  <div>{custDtls.cust_name}</div>
                </div>
                <div className="col-6 grd-bg p-2 rounded-3">
                  <label className="font-12">Email : </label>
                  <div>{custDtls.cust_email}</div>
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="col-6 grd-bg p-2 rounded-3">
                  <label className="font-12">Phone : </label>
                  <div>{custDtls.cust_phone}</div>
                </div>
                <div className="col-6 grd-bg p-2 rounded-3">
                  <label className="font-12">User IC : </label>
                  <div>{custDtls.cust_ic}</div>
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="col-12 grd-bg p-2 rounded-3">
                  <label className="font-12">Address : </label>
                  <div>{custDtls.cust_addr}</div>
                </div>
              </div>
            </>
          )}
        </ModalLayout>
      )}
    </>
  );
}

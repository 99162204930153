import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { customDateSort, getOrderStatusBadgeClass2 } from "../Helper/CommonMethods";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GetSvgIcon from "../../images/svglist";

export default function FtlLastMilePlanningList(props) {
  const { planningList, openReplanningModal,openBidModal, } =
    props;

  const columns = [
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      sortable: true,
      filterable: true,
    },
    {
      name: "Pickup Address",
      selector: (row) => getFullAddress(row, "pickup"),
      cell: (row) => (
        <>
          <pre className='box-text'>
            <span className="d-inline-block">
              {getFullAddress(row, "pickup")}
            </span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
      width: '300px',
    },
    {
      name: "Delivery Address",
      selector: (row) => getFullAddress(row, "delivery"),
      cell: (row) => (
        <>
          <pre className='box-text'>
            <span className="d-inline-block">
              {getFullAddress(row, "delivery")}
            </span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
      width: '300px',
    },
    {
      name: "Vehicle No.",
      selector: (row) => row.vhcl_no,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={row.vhcl_no === "Not Assigned" ? "text-danger" : ""}>
            {row.vhcl_no}
          </div>
        </>
      ),
    },
    {
      name: "Driver",
      selector: (row) => row.dr1_name+" ("+row.dr1_code+")",
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={row.dr1_name === "Not Assigned" ? "text-danger" : ""}>
          {!!row.dr1_code? row.dr1_name+" ("+row.dr1_code+")": row.dr1_name}
          </div>
        </>
      ),
    },
    {
      name: "Shipment Date",
      selector: (row) => moment(row.shipment_date).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) => customDateSort(rowA.shipment_date, rowB.shipment_date),
    },
    {
      name: "Order Status",
      selector: (row) => row.order_status,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={getOrderStatusBadgeClass2(row.order_status_code)}>
            {row.order_status}
          </div>
        </>
      ),
    },
    {
      name: "Bid Status",
      selector: (row) => row.bid_status,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={getOrderStatusBadgeClass2(row.bid_status)}>
            {/* {!!row.bid_status?row.bid_status:"Not Created"}  */}
            {row.bid_status}
          </div>
        </>
      ),
      width: "100px",
    },
    {
      name: "",
      width: "5%",
      cell: (row, index) => (
        <>
          <OverlayTrigger
            trigger="click"
            key={index}
            placement="left"
            rootClose
            overlay={
              <Popover id={`popover-positioned-lefft`}>
                <Popover.Body>
                  {(row.order_status_code === 76) && (
                    <>
                      <div
                        className="action-item-button"
                        onClick={() => {
                          openReplanningModal(row);
                          document.body.click();
                        }}
                      >
                        {GetSvgIcon("VehicleTruck")} Trip Plan
                      </div>
                      {!row.vehicle_id &&
                      row.bid_status !== "Open" &&
                      row.bid_status !== "Closed" && 
                        <div
                          className="action-item-button"
                          onClick={() => {
                            openBidModal(row);
                            // showConfirmAlert(row)
                            document.body.click();
                          }}
                        >
                          {GetSvgIcon("Geoalttrack")} Invite Bid
                        </div> }
                    </>
                  )}



                </Popover.Body>
              </Popover>
            }
          >
            <span className="row-action-button">
              {GetSvgIcon("Trippleverticaldot")}
            </span>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  const getFullAddress = (rowData, type) => {
    if (type === "pickup") // Pickup
      return `Name: ${rowData.pickup_name}\nAddress: ${rowData.pickup_address}, ${rowData.pickup_city}, ${rowData.pickup_state}\nPostCde: ${rowData.pickup_postcode}\nPhone: ${rowData.pickup_phone}`;
    else if (type === "delivery") // Delivery
      return `Name: ${rowData.deliver_name}\nAddress: ${rowData.deliver_address}, ${rowData.deliver_city}, ${rowData.deliver_state}\nPostCode: ${rowData.deliver_postcode}\nPhone: ${rowData.deliver_phone}`;
    else
      return ``;
  };

  return (
    <div>
      <div>
        <div className="col-md-12 table-responsive customized-data-table position-relative">
          <DataTable
            columns={columns}
            data={planningList}
            style={`table table-striped table-hover`}
            customStyles={customDataTableStyles}
            pagination
            striped
            highlightOnHover
            // defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        </div>
      </div>
    </div>
  );
}

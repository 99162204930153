import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import GetSvgIcon, { GetSvgCalendar } from "../../images/svglist";
import { Tooltip } from "react-tooltip";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import {
  AD_HOC_CH_ORDER,
  CHCXLORD,
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_STAFF_USER_TYPE,
  DLVEVENT,
  CHRECEIVEQR,
} from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  getOrderStatusBadgeClass,
  permissionCheck,
} from "../Helper/CommonMethods";

const CharteredOrdersList = ({
  handlePlanBtnClick,
  charteredList,
  openCharteredDetails,
  openConfirmAlert,
  downloadDO,
  downloadReceiveQR,
  handleCxlShipment,
  getDeliveryEventLog,
}) => {
  const PlanBtn = ({ row }) => {
    const isStaff = [
      LINEHAUL_ADMIN_USER_TYPE,
      LINEHAUL_STAFF_USER_TYPE,
    ].includes(getUserInfo("userType"));
    return (
      <>
        {isStaff && (
          <>
            <div
              data-tooltip-id={"plan" + row.orderId}
              onClick={() => handlePlanBtnClick(row)}
              className="theme-violet-txt"
            >
              {GetSvgCalendar("Calender")}
            </div>
            <Tooltip
              id={"plan" + row.orderId}
              content={"Chartered Planning"}
              place="left"
            />
          </>
        )}
      </>
    );
  };

  const ConfirmWgtBtn = ({ row }) => {
    const isWgtToConfirm =
      LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType")) &&
      !row.isConfirm &&
      !!row.serviceValue &&
      row.serviceValue > 0;
    return (
      <>
        {isWgtToConfirm && (
          <>
            <div
              data-tooltip-id={"plan" + row.orderId}
              onClick={() => openConfirmAlert(row)}
              className="ms-2 theme-violet-txt"
            >
              {GetSvgIcon("IconDiscountFill")}
            </div>
            <Tooltip
              id={"plan" + row.orderId}
              content={"Confirm Weight"}
              place="left"
            />
          </>
        )}
      </>
    );
  };

  const ActionBtn = ({ row }) => {
    let retBtn = <></>;
    if (row?.orderType === AD_HOC_CH_ORDER) {
      retBtn = <PlanBtn row={row} />;
    } else {
      retBtn = <ConfirmWgtBtn row={row} />;
    }
    return retBtn;
  };

  const QrCodeBtn = ({ data }) => {
    const qrArr = JSON.parse(data?.qrCode);
    return (
      <>
        {qrArr && qrArr.length > 0 && qrArr.map((e, i) => (
          <div
            key={i}
            className="action-item-button"
            onClick={() =>
              downloadReceiveQR(data?.orderId, e.receive_qr_code, e.stop_seq)
            }
          >
            {GetSvgIcon("Print")}{" "}
            {e.stop_seq === 99 ? "Destination QR" : `Stop-${e.stop_seq} QR`}
          </div>
        ))}
      </>
    );
  };

  const ActionPopover = ({ data }) => {
    const handleCxl = (row) => {
      document.getElementById(`row-action-button-${row?.orderId}`).click();
      handleCxlShipment(row);
    };
    return (
      <>
        {data?.statusId !== 77 && (
          <>
            <div
              className="action-item-button"
              onClick={() => downloadDO(data)}
            >
              {GetSvgIcon("Print")} Print DO
            </div>
            {permissionCheck(CHRECEIVEQR, "allowedRead") && (
              <QrCodeBtn data={data} />
            )}
          </>
        )}

        {permissionCheck(CHCXLORD, "allowedWrite") &&
          [76, 619, 620].includes(data?.statusId) && (
            <div className="action-item-button" onClick={() => handleCxl(data)}>
              {GetSvgIcon("IconDiscount")} Cancel{" "}
            </div>
          )}

        {permissionCheck(DLVEVENT, "allowedRead") && (
          <div
            className="action-item-button"
            onClick={() => getDeliveryEventLog(data?.orderId)}
            data-bs-toggle="modal"
            data-bs-target="#delivery-event-log-modal"
          >
            {GetSvgIcon("IconDiscount")} Delivery Log
          </div>
        )}
      </>
    );
  };

  const columns = [
    {
      name: "Account Code",
      selector: (row) => row?.acctCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.custName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Company Name",
      selector: (row) =>
        row?.businessName === "null" ? "" : row?.businessName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Order No",
      selector: (row) => row?.orderNo,
      cell: (row) => (
        <>
          <div
            onClick={() => openCharteredDetails("order", row)}
            className="modal-links fw-bold"
          >
            {row.orderNo}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Do Number",
      selector: (row) => row?.doNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: "Origin",
      selector: (row) => row?.origin,
      sortable: true,
      filterable: true,
      cell: (row) => <code className="address-style">{row?.origin}</code>,
    },
    {
      name: "Destination",
      selector: (row) => row?.destination,
      sortable: true,
      filterable: true,
      cell: (row) => <code className="address-style">{row?.destination}</code>,
    },
    {
      name: "Booked on",
      selector: (row) => row?.bookedOn,
      sortable: true,
      filterable: true,
    },
    {
      name: "Shipment Date",
      selector: (row) => row?.shipmentDate,
      sortable: true,
      filterable: true,
      cell: (row) => <span className="p-0">{row?.shipmentDate}</span>,
    },
    {
      name: "Order Type",
      selector: (row) =>
        row?.orderType === AD_HOC_CH_ORDER ? "Ad-Hoc" : "Scheduled",
      sortable: true,
      filterable: true,
    },
    {
      name: "Weight",
      selector: (row) => !!row?.serviceValue && row?.serviceValue.toFixed(2),
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.statusText,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <span className="p-1">
          <Badge
            className={`text-wrap ${getOrderStatusBadgeClass({
              status: row?.statusId,
            })}`}
          >
            {row?.statusText}
          </Badge>
        </span>
      ),
    },

    // {
    //   name: "",
    //   cell: (row) => <ActionBtn row={row} />,
    //   width: "40px",
    // },
    {
      name: "",
      width: "15px",
      cell: (row) => (
        <>
          <ActionBtn row={row} />
          <OverlayTrigger
            key={row?.orderId}
            trigger="click"
            placement="left"
            rootClose={true}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <ActionPopover data={row} />
                </Popover.Body>
              </Popover>
            }
          >
            <span
              className="row-action-button"
              id={`row-action-button-${row?.orderId}`}
            >
              {GetSvgIcon("Trippleverticaldot")}
            </span>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="col-md-12 customized-data-table position-relative customize-tbl-design">
        <DataTable
          columns={columns}
          data={charteredList}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
        />
      </div>
    </>
  );
};

export default CharteredOrdersList;

import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../../../images/svglist";
import { DATE_FORMAT_MOMENT } from "../../../Helper/Constants";
import { CallApiPost } from "../../../Helper/serviceApi";
import Footer from "../../../Layout/Footer";
import Header from "../../../Layout/Header";
import Popup from "../../../Layout/Popup";
import Spinner from "../../../Layout/Spinner";
import IsrApprovalList from "./IsrApprovalList";
import IsrDetails from "./IsrDetails";
import { APPROVAL_STATUS_REJECT, ISRAPVL } from "../../../../ConfigConstants/ConfigConstants";
import { permission } from "../../../Helper/CommonMethods";


const initialSearch = {
  assignTo: "%",
  status: "PENDING",
  // limit: defaultPageLimit,
  // offset: defaultPageOffset,
};

// const statusDList = [
//   { label: "PENDING", value: "PENDING" },
//   { label: "APPROVED", value: "APPROVED" },
//   { label: "REJECT", value: "REJECT" },
// ];

const isrDtlModel = {
  acNumber: "",
  address: "",
  bankName: "",
  benificiaryName: "",
  email: "",
  isrIc: "",
  name: "",
  phone: "",
  postCode: "",
  userId: 0,
};

// const initialFieldData = {
//   assignTo: "",
//   status: "PENDING",
// };
const IsrApprovalMain = () => {
  const [show, setShow] = useState(false);
  // const [rmList, setRmList] = useState([]);
  const [search] = useState(JSON.parse(JSON.stringify(initialSearch)));
  // const [pageCount, setPageCount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [isrList, setIsrList] = useState();
  const [isrDtls, setIsrDtls] = useState(isrDtlModel);
  const [status, setStatus] = useState();
  const modalRef = useRef();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (permission(ISRAPVL)?.allowedRead) {
      getInitialData();
    }
  }, []);

  const columns = [
    {
      name: "RM",
      selector: (row) => row.rmUserName,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "ISR ID",
    //   selector: (row) => row.sla_days,
    //   sortable: true,
    // },
    {
      name: "Name",
      selector: (row) => row.staff_name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.cust_email,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) => moment(row.date).format(DATE_FORMAT_MOMENT),
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status === APPROVAL_STATUS_REJECT ? 'REJECTED' : row.status,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            onClick={() => getSelectedRow(row)}
            data-bs-toggle="modal"
            data-bs-target="#button-modal"
            data-tooltip-id={"rowaction-" + row["user_id"]}
          >
            {GetSvgIcon("EditPencil")}
            {/* {GetSvgIcon('EyeFill')} */}
          </div>
          <Tooltip id={"rowaction-" + row["user_id"]} content={"Edit"} place="left" />
        </>
      ),
      width: "56px",
    },
  ];

  const getInitialData = async () => {
    setShow(true);
    let allIsrData = await CallApiPost(
      "pending-isr-list-for-approve",
      initialSearch
    );
    setShow(false);

    // let allRms = await CallApiGet("rm-list");

    if (allIsrData.respCode !== 401 && allIsrData.respCode !== 400) {
      setTotalRows(allIsrData.respData[0].noOfRecords);
      let data = allIsrData.respData;
      setIsrList(data);
      let isrProfileId = Number(searchParams.get("profileId"));
      if (isrProfileId) {
        for (let i = 0; i < data.length; i++) {
          if (isrProfileId === data[i]["prfl_staff_id"]) {
            await getSelectedRow(data[i]);
            document.getElementById(`rowaction-${data[i]["user_id"]}`).click();
            break;
          }
        }
      }
    } else {
      setIsrList([]);

    }
  };

  const getIsrData = async (data) => {
    let allIsrData = await CallApiPost("pending-isr-list-for-approve", search);
    if (allIsrData.respCode !== 401 && allIsrData.respCode !== 400) {
      if (allIsrData.respData.length > 0) {
        setTotalRows(allIsrData.respData[0].noOfRecords);
        let data = allIsrData.respData;
        setIsrList(data);
      } else {
        setIsrList([]);
      }
    } else {
      setIsrList([]);
    }
  };

  const getSelectedRow = async (data) => {
    setShow(true);
    let isrDtl = await CallApiPost("isr-details", { isrUserId: data.prfl_staff_id });
    setShow(false);
    if (isrDtl.respCode === 200) {
      setIsrDtls(isrDtl.respData);
      setStatus(isrDtl.respData.approvalStatus);
    }
  };

  const onSuccess = (statusData, desc = '') => {
    let isSucc = true
    setTitle("Success");
    if (statusData === "DEACTIVATIONAPPROVE")
      setDescription("Deactivation approved successfully !!!");
    else if (statusData === "DEACTIVATIONREJECT")
      setDescription("Deactivation rejected successfully !!!");
    else if (statusData === "APPROVE")
      setDescription("Successfully approved !!!");
    else if (statusData === "REJECT")
      setDescription("Successfully rejected !!!");
    else if (statusData === "NOPRIVILEGE") {
      isSucc = false
      setTitle("FAILURE");
      setDescription(desc || "You don't have the privilege to do this operation. Please contact admin.");
    } else
      setDescription("Successfully saved !!!");

    setIsSuccess(isSucc);
    modalRef.current.click();
  };

  return (
    <>
      <Header />
      <div className="container-fluid  min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">ISR Approval</div>
              </div>
            </div>

            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
            <IsrApprovalList
              columns={columns}
              totalRows={totalRows}
              data={isrList}
            />

            <IsrDetails
              onSuccess={onSuccess}
              isrDtls={isrDtls}
              status={status}
            />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={getIsrData}
      />
      <Spinner show={show} />
      <Footer />
    </>
  );
};

export default IsrApprovalMain;

import React, { useEffect, useState } from "react";
import { permissionCheck } from "../../Helper/CommonMethods";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { INV, INVS } from "../../../ConfigConstants/ConfigConstants";
import CreditNoteDetails from "./CreditNoteDetails";
import CreditNoteSummary from "./CreditNoteSummary";

const CreditNoteRoute = () => {
  const customerType = getUserInfo("customerType");
  const [custTypeShow, setCustTypeShow] = useState(true);
  const [isCust, setisCust] = useState(false);
  useEffect(() => {
    if (permissionCheck(INVS, "allowedRead")?.allowedRead) {
      setCustTypeShow(true);

      // financeSummary();
    } else if (permissionCheck(INV, "allowedRead")?.allowedRead) {
      if (
        +customerType === 135 ||
        +customerType === 136 ||
        +customerType === 141
      )
        setisCust(true);
      setCustTypeShow(false);

      // financeSummary();
    }
  }, []);
  return (
    <>
      {custTypeShow && !isCust ? <CreditNoteSummary /> : <CreditNoteDetails />}
    </>
  );
};

export default CreditNoteRoute;

import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomInputWithChip from "../../Utilities/CustomInputWithChip";
import CardWidget from "../Widgets/CardWidget";
import InvoiceWidget from "../Widgets/InvoiceWidget";
import CreditOverviewWidget from "../Widgets/CreditOverviewWidget";
import ClaimWidget from "../Widgets/ClaimWidget";
import OrderDetailWidget from "../Widgets/OrderDetailWidget";
import GetSvgIcon from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { CallApiPost } from "../../Helper/serviceApi";
import BookingSearchComponent from "../../OrderBooking/StandardBooking/BookingSearchComponent";
import { fetchAddressBook, permission } from "../../Helper/CommonMethods";
import { STNDBKNG } from "../../../ConfigConstants/ConfigConstants";

const CustomerDashboard = () => {
  const sDate = moment().subtract(90, "days").format("YYYY-MM-DD");
  const eDate = moment().format("YYYY-MM-DD");
  const navigate = useNavigate();
  const [wayBillNos, setWayBillNos] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [addrbook, setAddrBook] = useState([]);

  let initCardData = [
    { border: "primary", title: "ALL", count: 0, icon: "TotalListDashboard" },
    {
      border: "danger",
      title: "TRANSIT",
      count: 0,
      icon: "TotalTransitDashboard",
    },
    {
      border: "warning",
      title: "OUT FOR PICKUP",
      count: 0,
      icon: "TotalWaitingForPickup",
    },
    {
      border: "success",
      title: "OUT FOR DELIVERY",
      count: 0,
      icon: "TotalDeliveredDashboard",
    },
  ];
  const [cardData, setCardData] = useState(initCardData);

  const [orderDetails, setOrderDetails] = useState(null);
  const [orderGraphData, setOrderGraphData] = useState(null);
  const [claimDetails, setClaimDetails] = useState(null);

  let initCreditData = [
    { color: "info", label: "Credit Limit", percent: 0, amount: 0 },
    { color: "danger", label: "Credit Used", percent: 0, amount: 0 },
    { color: "success", label: "Remaining", percent: 0, amount: 0 },
  ];
  const [creditData, setCreditData] = useState(initCreditData);

  let initInvoiceData = {
    gData: [
      { color: "info", label: "Billed Invoice", percent: 0, amount: 0 },
      { color: "success", label: "Paid Amount", percent: 0, amount: 0 },
      { color: "danger", label: "Due Amount", percent: 0, amount: 0 },
      { color: "warning", label: "Unbilled Invoice", percent: 0, amount: 0 },
    ],
  };
  const [invoiceData, setInvoiceData] = useState(initInvoiceData);
  const [isUserActive, setIsUserActive] = useState(false);

  useEffect(() => {
    setIsUserActive(getUserInfo("userActive"));
    getDashboardData();
    fetchAddressBook()
      .then((res) => {
        setAddrBook(res?.addressBook);
      })
      .catch((err) => {
        setAddrBook([]);
      });
  }, []);

  const getDashboardData = async () => {
    let payload = { fromDt: sDate, toDt: eDate, custId: getUserInfo("acctId") };
    let resp = await CallApiPost("DashboardCustomer", payload);
    setSpinner(true);
    if (!!resp && resp.respCode === 200) {
      let data = resp.respData;
      mapClaimData(data?.claimDetails[0]);
      setOrderDetails(data?.orderDetails[0]);
      mapCardsData(data?.orderDetails[0]);
      mapOrderDetailsGraphData(data?.orderDetails[0]);

      let creditObj = {
        creditLimit: !!data?.invoiceDetails[0]?.creditLimit
          ? data?.invoiceDetails[0]?.creditLimit
          : 0,
        creditUsed: 0,
        creditRemaining: !!data?.invoiceDetails[0]?.currCreditLimit
          ? data?.invoiceDetails[0]?.currCreditLimit
          : 0,
      };
      creditObj.creditUsed = creditObj?.creditLimit - creditObj.creditRemaining;
      mapCreditData(creditObj);

      let invoiceObj = {
        totalInvoiceAmt: 0,
        billedInvoiceAmt: !!data?.invoiceDetails[0]?.billedInvoice
          ? data?.invoiceDetails[0]?.billedInvoice
          : 0,
        unbilledInvoiceAmt: !!data?.invoiceDetails[0]?.unbilledAmount
          ? data?.invoiceDetails[0]?.unbilledAmount
          : 0,
        totalAmt: !!data?.invoiceDetails[0]?.paidAmount
          ? data?.invoiceDetails[0]?.paidAmount
          : 0,
        paidAmt: !!data?.invoiceDetails[0]?.paidAmount
          ? data?.invoiceDetails[0]?.paidAmount
          : 0,
        dueAmt: 0,
      };
      invoiceObj.totalInvoiceAmt =
        invoiceObj?.billedInvoiceAmt + invoiceObj?.unbilledInvoiceAmt;
      mapInvoiceData(invoiceObj);

      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const trackShipment = () => {
    navigate("/trackWaybill", { state: wayBillNos });
  };

  const mapCardsData = (oDetails) => {
    setCardData([
      {
        border: "primary",
        title: "ALL",
        count: oDetails?.totalOrders,
        icon: "TotalListDashboard",
      },
      {
        border: "danger",
        title: "TRANSIT",
        count: oDetails?.ordersInTransit,
        icon: "TotalTransitDashboard",
      },
      {
        border: "warning",
        title: "OUT FOR PICKUP",
        count: oDetails?.outForPickup,
        icon: "TotalWaitingForPickup",
      },
      {
        border: "success",
        title: "OUT FOR DELIVERY",
        count: oDetails?.outForDelivery,
        icon: "TotalDeliveredDashboard",
      },
    ]);
  };

  const mapClaimData = (clDetails) => {
    let claimGraphData = {
      labels: ["Settled", "Under Review"],
      datasets: [
        {
          label: "No. of claims",
          data: [clDetails?.claimSettled, clDetails?.claimUnderReview],
          backgroundColor: ["rgba(56, 177, 170,0.3)", "rgba(220, 53, 69,0.3)"],
          borderColor: ["rgba(56, 177, 170,1)", "rgba(220, 53, 69,1)"],
          borderWidth: 1,
        },
      ],
    };
    setClaimDetails({
      gData: claimGraphData,
      data: clDetails,
    });
  };

  const mapOrderDetailsGraphData = (oDetails) => {
    setOrderGraphData({
      labels: ["Transit", "Out For Delivery", "Out For Pickup"],
      datasets: [
        {
          label: "No. of orders",
          data: [
            oDetails?.ordersInTransit,
            oDetails?.outForDelivery,
            oDetails?.outForPickup,
          ],
          backgroundColor: [
            "rgba(56, 177, 170,0.3)",
            "rgba(220, 53, 69,0.3)",
            "rgba(153, 102, 255, 0.3)",
          ],
          borderColor: [
            "rgba(56, 177, 170,1)",
            "rgba(220, 53, 69,1)",
            "rgba(153, 102, 255, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  };

  const mapCreditData = (creditObj) => {
    setCreditData([
      {
        color: "info",
        label: "Credit Limit",
        percent: Number(creditObj?.creditLimit) > 0 ? 100 : 0,
        amount: creditObj?.creditLimit,
      },
      {
        color: "danger",
        label: "Credit Used",
        percent: (creditObj?.creditUsed / creditObj?.creditLimit) * 100,
        amount: creditObj?.creditUsed,
      },
      {
        color: "success",
        label: "Remaining",
        percent: (creditObj?.creditRemaining / creditObj?.creditLimit) * 100,
        amount: creditObj?.creditRemaining,
      },
    ]);
  };

  const mapInvoiceData = (invoiceObj) => {
    setInvoiceData({
      gData: [
        {
          color: "info",
          label: "Billed Invoice",
          percent:
            (invoiceObj?.billedInvoiceAmt / invoiceObj?.totalInvoiceAmt) * 100,
          amount: invoiceObj?.billedInvoiceAmt,
        },
        {
          color: "success",
          label: "Paid Amount",
          percent: (invoiceObj?.paidAmt / invoiceObj?.totalAmt) * 100,
          amount: invoiceObj?.paidAmt,
        },
        {
          color: "danger",
          label: "Due Amount",
          percent: (invoiceObj?.dueAmt / invoiceObj?.totalAmt) * 100,
          amount: invoiceObj?.dueAmt,
        },
        {
          color: "warning",
          label: "Unbilled Invoice",
          percent:
            (invoiceObj?.unbilledInvoiceAmt / invoiceObj?.totalInvoiceAmt) *
            100,
          amount: invoiceObj?.unbilledInvoiceAmt,
        },
      ],
    });
  };

  return (
    <>
      <Layout spinnershow={spinner} type={"D"} isHome={true}>
        {/* <BookingWidget setSpinner={setSpinner} /> */}
        {permission(STNDBKNG).allowedRead && isUserActive === true && (
          <div className="row">
            <BookingSearchComponent
              showToggler={true}
              showSlider={true}
              showSearchBtn={true}
              addressBook={addrbook}
              isReadOnly={false}
              disableOpt={false}
            />
          </div>
        )}
        <div className="row">
          {!!cardData &&
            cardData.length > 0 &&
            cardData.map((item, index) => (
              <CardWidget
                key={index}
                border={item?.border}
                title={item?.title}
                count={item?.count}
                icon={item?.icon}
              />
            ))}
          <div className="col-md-3 pt-2">
            <div className="w-100 ">
              <div className="input-group me-3">
                <CustomInputWithChip
                  value={wayBillNos}
                  setValue={setWayBillNos}
                  className="form-control"
                />
                <button
                  className="btn btn-outline-secondary theme-violet"
                  type="button"
                  id="button-addon2"
                  title="Track"
                  onClick={trackShipment}
                >
                  {GetSvgIcon("Rightarrow")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-3">
          {!!orderDetails && (
            <OrderDetailWidget
              data={orderDetails}
              gData={orderGraphData}
              type={"C"}
            />
          )}
          {!!invoiceData && <InvoiceWidget data={invoiceData} />}
          {!!claimDetails && <ClaimWidget data={claimDetails} type={"C"} />}
          {!!creditData && <CreditOverviewWidget data={creditData} />}
        </div>
      </Layout>
    </>
  );
};
export default CustomerDashboard;

import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions,
} from "../../Helper/Constants";

const RateChatList = (props) => {

  return (
    <div className="col-md-12 table-responsive customized-data-table position-relative">
      <DataTable
        columns={props.columns}
        data={props.rateList}
        pagination
        striped
        highlightOnHover
        customStyles={customDataTableStyles}
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
      />
    </div>
  );
};

export default RateChatList;

import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Header";
import { useFieldArray, useForm } from "react-hook-form";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import GetSvgIcon from "../../../images/svglist";
import { REQUIRED_ERR_MSG, SERVER_ERR_MSG } from "../../Helper/Constants";
import { NUMBER_REGEX } from "../../Helper/Constants";
import { Spinner } from "react-bootstrap";
import Popup from "../../Layout/Popup";

const defvalue = [
  {
    delayUpto: "",
    commission: "",
  },
];

const row = { id: 0, delayUpto: "", commission: "" };

const IsrCommissionConfig = () => {
  const [show, setShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm();
  const {
    fields: coms,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "comm",
  });

  useEffect(() => {
    getInitValues();
  }, []);

  const getInitValues = async () => {
    const resps = await CallApiGet("getIsrCommissionConfig");
    if (resps.respCode === 200) {
      //   setConfigData({"isrCommission":resps.respData});
      reset({ comm: resps.respData });
    }
  };

  const checkUniqueness = (data) => {
    let flag = true;
    const delayArray = data.comm.map((c) => parseInt(c.delayUpto));
    const commissionArray = data.comm.map((c) => parseInt(c.commission));
    let trmDelayArray = Array.from(new Set(delayArray));
    let trmComArray = Array.from(new Set(commissionArray));

    if (
      delayArray.length !== trmDelayArray.length ||
      commissionArray.length !== trmComArray.length
    ) {
      flag = false;
      setIsSuccess(false);
      setTitle("Error");
      setDescription("Duplicate Record found");
      modalRef.current.click();
    }
    return flag;
  };

  const onSubmit = async (data) => {
    if (checkUniqueness(data)) {
      setShow(true);
      let saveResponse = await CallApiPost(
        "saveIsrCommissionConfig",
        data.comm
      );
      if (saveResponse.respCode === 200) {
        setIsSuccess(true);
        setTitle("Success");
        setDescription("ISR commission saved successfully");
        modalRef.current.click();
      } else if (
        saveResponse.respCode === 401 ||
        saveResponse.respCode === 400
      ) {
        setIsSuccess(false);
        setTitle("UNAUTHORIZED");
        setDescription("Sorry, you don't have the write permission!!");
        modalRef.current.click();
      } else {
        setIsSuccess(false);
        setTitle("ERROR");
        setDescription(SERVER_ERR_MSG);
        modalRef.current.click();
      }

      setShow(false);
    }
  };

  const onFormErr = (err) => {
    console.log("errors", err);
  };

  return (
    <>
      <Header />
      <div className="container-fluid  min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="section-title mt-1">
                ISR Commission Configuration
              </div>
            </div>
            <div className="col-md-12 my-3">
              <div className="row">
                <div className="col-md-4 m-auto">
                  <div className="border border-3 rounded">
                    {/* <h5 className="p-2">ISR Commission</h5> */}
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Delay upto (Days)</th>
                          <th scope="col">Commission (%)</th>
                          <th className="w-25">
                            <div
                              className="px-2 text-success"
                              onClick={() =>
                                append(JSON.parse(JSON.stringify(row)))
                              }
                            >
                              {GetSvgIcon("PlusCircleNoFill")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {coms &&
                          coms.map((data, index) => {
                            let { id, delayUpto, commission } = data;
                            return (
                              <tr key={id}>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`comm.${index}.delayUpto`}
                                    {...register(`comm.${index}.delayUpto`, {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: NUMBER_REGEX,
                                        message: "Accepts Number!",
                                      },
                                    })}
                                    defaultValue={delayUpto}
                                  />
                                  {errors.comm &&
                                    errors?.comm[index]?.delayUpto && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888;{" "}
                                          {
                                            errors?.comm[index]?.delayUpto
                                              ?.message
                                          }
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`comm.${index}.commission`}
                                    {...register(`comm.${index}.commission`, {
                                      required: {
                                        value: true,
                                        message: REQUIRED_ERR_MSG,
                                      },
                                      pattern: {
                                        value: NUMBER_REGEX,
                                        message: "Accepts Number!",
                                      },
                                    })}
                                    defaultValue={commission}
                                  />
                                  {errors.comm &&
                                    errors?.comm[index]?.commission && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888;{" "}
                                          {
                                            errors?.comm[index]?.commission
                                              ?.message
                                          }
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td className="w-25 ">
                                  <div
                                    className="px-2 text-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {GetSvgIcon("DashCircleNoFill")}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="clearfix col-md-2 m-auto my-3">
                      <button
                        type="button"
                        className="btn btn-outline-secondary theme-violet"
                        onClick={handleSubmit(onSubmit, onFormErr)}
                      >
                        Save
                      </button>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#success-modal"
                        id="modalPopup"
                        className="d-none"
                        ref={modalRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Spinner show={show} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={getInitValues}
      />
    </>
  );
};

export default IsrCommissionConfig;

import DataTable from "react-data-table-component-with-filter";
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from "../../Helper/Constants";

function ServiceList(props) {
  return (


    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={props.columns}
        data={props.data}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}

      />
    </div>
  );
}

export default ServiceList;

import DataTable from 'react-data-table-component-with-filter';
import GetSvgIcon from '../../images/svglist';
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions, SERVER_ERR_MSG } from '../Helper/Constants';
import { CallApiGet } from '../Helper/serviceApi';
import { Tooltip } from 'react-tooltip';

const ContentDocList = (props) => {
    const { contentDocMgmList, setShipment, openPopup, setShow } = props
    const columns = [
        {
            name: 'Country',
            selector: row => row.countryName,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Content',
            selector: row => row.contentType,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Delivery Port',
            selector: row => row.hubName,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Remarks',
            selector: row => row.remarks,
            sortable: true,
            filterable: true,
        },
        {
            name: 'Edit',
            cell: row => (
                <>
                <div className="me-3" data-bs-toggle="modal" data-bs-target="#shipment-modal"
                    onClick={() => handleOnClick(row)} data-tooltip-id={"edit-" + row.id}
                >
                    {GetSvgIcon("EditPencil")} 
                </div>
                <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
                </>
            ),
            allowOverflow: true,
            button: true,
            width: '56px',
        },
    ]

    const handleOnClick = async (row) => {
        setShow(true)
        const uri = "getDocByContent?contentId=" + row.id
        const data = await CallApiGet(uri)
        if (data.respCode === 200 || data.respCode === 0) {
            const currentShip = {
                shipmentId: row.id,
                countryCode: row.countryCode,
                contentType: row.contentType,
                hubId: row.hubId,
                remarks: row.remarks,
                contentDocs: data.respData
            }
            setShipment(currentShip)
        } else {
            console.error(data.respMessage)
            openPopup(false, "Error!", SERVER_ERR_MSG)
        }
        setShow(false)
    }

    return (
        <div className="col-md-12 mt-3 customized-data-table position-relative">
            <DataTable
                columns={columns}
                data={contentDocMgmList}

                pagination
                striped
                highlightOnHover
                customStyles={customDataTableStyles}
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
            />
        </div>
    )
}

export default ContentDocList
import axios from "axios";
import {
  getPersonalId,
  getSessionInfo,
  getUserInfo,
} from "../AuthUtils/AuthUtils";

const BASE_URL = process.env.REACT_APP_API_URL;
export default BASE_URL;

axios.interceptors.request.use(
  config => {
    config.headers['sessionId'] = getSessionInfo();
    config.headers['userId'] = getUserInfo("userId");
    config.headers['personalId'] = getPersonalId();
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export async function CallApiGet(argApiName) {
  try {
    //======== Call API GET =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.get(apiUrl);
    PostApiCallHandler(resp.data)
    return resp.data;

  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export async function CallApiPost(argApiName, argData) {
  try {
    //======== Call API POST =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.post(apiUrl, argData);
    PostApiCallHandler(resp)
    return resp.data;

  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export const CallApiPut = async (argApiName, argData) => {
  try {

    //======== Call API PUT =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.put(apiUrl, argData);
    PostApiCallHandler(resp)
    return resp.data

  } catch (error) {
    console.error(error)
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export const CallApiDelete = async (argApiName) => {
  try {

    //======== Call API DELETE =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.delete(apiUrl);
    PostApiCallHandler(resp)
    return resp.data;

  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export const CallApiFetch = async (argApiName) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = fetch(apiUrl,
      {
        method: 'GET',
        headers: {
          sessionId: getSessionInfo(),
          userId: getUserInfo("userId"),
          personalId: getPersonalId()
        },
      })
    return resp
  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export const PostApiCallHandler = (resp) => {
  if (resp.respCode === 403) {
    localStorage.clear()
    navigateToPage("/login")
  }
}

export const navigateToPage = (argPageUrl) => {
  window.location.href = argPageUrl
}

export async function CallApiGetWithHead(argApiName) {
  try {
    //======== Call API GET =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.get(apiUrl,{ responseType:
      "blob"
      });
    PostApiCallHandler(resp.data)
    return resp;

  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export async function CallApiPostWithHead(argApiName, argData) {
  try {
    //======== Call API POST =========
    const apiUrl = process.env.REACT_APP_API_URL + "/" + argApiName;
    const resp = await axios.post(apiUrl, argData,{ responseType:
      "blob"
      });
    PostApiCallHandler(resp)
    return resp;

  } catch (error) {
    console.error(error);
    return { "respCode": 99, "respMessage": error, "respData": "" }
  }
}

export const ReactVersion = process.env.REACT_APP_VERSION;
export const LCWebsiteURL = process.env.REACT_APP_LC_WEBSITE_URL;
export const LCTrackWaybillURL = process.env.REACT_APP_LC_TRACK_WAYBILL_URL;

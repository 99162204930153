import React, { useEffect, useState } from "react";
import AddressBookList from "./AddressBookList";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import Layout from "../Layout/Layout";
import GetSvgIcon from "../../images/svglist";
import AddressBookAddEdit from "./AddressBookAddEdit";
import { cleanNewLine } from "../Helper/CommonMethods";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const initModalConfig = {
  show: false,
  title: "",
};

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initAddrObj = {
  name: "",
  address: "",
  state: "",
  city: "",
  postcode: "",
  phone: "",
  phoneCode: "+60",
};

const initStateCityObj = {
  loadedStateArr: [],
  loadedCityArr: [],
  selStateArr: [],
  selCityArr: [],
  stateCityMap: [
    {
      state: "",
      city: "",
    },
  ],
};

const AddressBookMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [addrBook, setAddrBook] = useState([]);
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [initAdd, setInitAdd] = useState(Object.assign({}, initAddrObj));
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [custList, setCustList] = useState([]);
  const [stateCityObj, setStateCityObj] = useState(
    Object.assign({}, initStateCityObj)
  );

  useEffect(() => {
    fetchAddr();
    fetchCustList();
    fetchStatesAndCityList();
  }, []);

  const fetchAddr = async () => {
    setSpinner(true);
    const resp = await CallApiGet("GetAddrBookList");
    if (resp.respCode === 200) {
      setAddrBook(resp.respData);
    }
    setSpinner(false);
  };

  const handleBtnClick = (argData, argTitle) => {
    let copyObj = Object.assign({}, argData);
    const tempPhoneCode = copyObj?.phone?.substr(0, 3);
    copyObj.phoneCode = !!tempPhoneCode ? tempPhoneCode : "+60";
    copyObj.phone = copyObj?.phone?.substr(3);
    setInitAdd(Object.assign({}, copyObj));
    setModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setInitAdd(Object.assign({}, initAddrObj));
    let tempObj = Object.assign({}, stateCityObj);
    tempObj.selCityArr = tempObj.loadedCityArr;
    tempObj.selStateArr = tempObj.loadedStateArr;
    setStateCityObj(tempObj);
    setModalConfig({
      show: false,
      title: "",
    });
  };

  const fetchCustList = async () => {
    const data = await CallApiGet("list-cust/ALL");
    if (data.respCode === 0) {
      setCustList(data.respData);
    }
  };

  const onSubmit = async (payload) => {
    setSpinner(true);
    payload.custId = LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
      getUserInfo("userType")
    )
      ? getUserInfo("acctId")
      : payload.custId;
    payload.userId = LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
      getUserInfo("userType")
    )
      ? getUserInfo("userId")
      : 0
    payload.phone = payload.phoneCode + payload.phone;
    payload.address = cleanNewLine(payload.address);
    let resp = await CallApiPost("SaveAddrBook", payload);

    handleCLose();
    handleApiResponse(resp);
    setSpinner(false);
  };

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let cityArrList = [];
    let data = await CallApiGet("getStateAndCityList");
    if (data.respCode === 0) {
      let obj = {
        stateCityMap: data.respData,
      };
      data.respData.forEach((element) => {
        if (!stateArrList.some((e) => e.value === element.map_state)) {
          stateArrList.push({
            value: element.map_state,
            label: element.map_state,
          });
        }
        if (!cityArrList.some((e) => e.value === element.map_city)) {
          cityArrList.push({
            value: element.map_city,
            label: element.map_city,
          });
        }
      });
      obj.loadedCityArr = [...cityArrList];
      obj.loadedStateArr = [...stateArrList];
      obj.selCityArr = [...cityArrList];
      obj.selStateArr = [...stateArrList];
      setStateCityObj(obj);
    }
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchAddr();
  };

  return (
    <div>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={closePopup}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">Address Book</div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() => handleBtnClick(initAddrObj, "ADD ADDRESS")}
              >
                {GetSvgIcon("PlusCircle")} ADD ADDRESS
              </button>
            </div>
          </div>
        </div>
        <AddressBookList
          addrBook={addrBook}
          handleAction={handleBtnClick}
          handleCLose={handleCLose}
        />
        <AddressBookAddEdit
          modalConfig={modalConfig}
          onHide={handleCLose}
          initAdd={initAdd}
          onSubmit={onSubmit}
          custList={custList}
          stateCityObj={stateCityObj}
          setStateCityObj={setStateCityObj}
        />
      </Layout>
    </div>
  );
};

export default AddressBookMain;

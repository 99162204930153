import React from "react";
import GetSvgIcon from "../../../images/svglist";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const ClaimWidget = (props) => {
    const ClaimLabels = (props) => {
        return (
                <div className="col">
                    <h4 className="small font-weight-bold my-3">
                        Claim Raised{" "}<span className="float-end"><strong>{props?.data?.claimRaised}</strong></span>
                    </h4>
                    <h4 className="small font-weight-bold my-3">
                        Claim Settled{" "}<span className="float-end"><strong>{props?.data?.claimSettled}</strong></span>
                    </h4>
                    <h4 className="small font-weight-bold my-3">
                        Claim Under Review{" "}<span className="float-end"><strong>{props?.data?.claimUnderReview}</strong></span>
                    </h4>
                    <h4 className="small font-weight-bold my-3">
                        Approved Claim Amount<span className="float-end"><strong>RM {props?.data?.approvedClaimAmt.toFixed(2)}</strong></span>
                    </h4>
                </div>
            )
        }

        return (
            <>
            <div className="col">
                <div className="card mb-4 shadow">
                    <div className="card-header h6">
                        <span className="float-start">
                            {
                                props?.type === 'A' ? 'Claims (Last Three Months)' : 'My Claims'
                            }
                        </span>
                        {/* <span className="float-end">{GetSvgIcon('FullscreenWindow')}</span> */}
                    </div>
                    <div className="card-body ht-48">
                        {
                            props?.type === 'A' ?
                                <>
                                    <div className="row">
                                        <div className="col ht-48">
                                            <Doughnut data={props?.data?.gData} />
                                        </div>
                                        <div className="col py-5">
                                            <ClaimLabels data={props?.data?.data} />
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div style={{ width: '90%', height: '90%' }}>
                                        <Doughnut data={props?.data?.gData} />
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h4 className="small font-weight-bold my-3">
                                                Refund Amount{" "}<span className="float-end"><strong>RM {props?.data?.data?.approvedClaimAmt.toFixed(2)}</strong></span>
                                            </h4>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimWidget;
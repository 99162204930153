import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  EMAIL,
  NUMBER_SPACE_REGEX,
  PATTERN_ERR_MSG,
  PHONE_NUM,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import { toBase64 } from "../../Helper/CommonMethods";
import { initValUser } from "../../UserManagement/intiValUser";
import { GetSvgUpload } from "../../../images/svglist";

export const initObj = {
  userId: 0,
  profileId: 0,
  name: "",
  email: "",
  phone: "",
  supplierId: "",
  state: "",
  city: "",
  postcode: "",
  address: "",
  profilePhoto: {
    photo: null,
    photoFileType: null,
    photoUrl: null,
  },
  userInfo: '[{"infoId": 0, "userId": 0, "infoCode": 125, "attachment": null}]',
};

const SupplierAddEdit = ({
  modalConfig,
  onHide,
  selectedSup,
  onSubmit,
  countryCodes,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    register,
    clearErrors,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm();

  const [photoImg, setPhotoImg] = useState();
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());

  const watchProfilePhoto = watch("profilePhoto");
  const watchUserId = watch("userId");

  useEffect(() => {
    reset(Object.assign({}, selectedSup));
  }, [selectedSup]);

  const handleCLoseModal = () => {
    reset(Object.assign({}, initObj));
    reset();
    onHide();
  };

  const onErr = (err) => {
    console.log(err);
  };

  const clearData = () => {
    clearErrors();
    reset(initValUser);
    setFileInputDummyKey(Date.now());
    setPhotoImg();
  };

  const clearInfo = () => {
    clearErrors();
    reset(selectedSup);
    setFileInputDummyKey(Date.now());
    setPhotoImg();
  };

  const selectPhotoFile = async (event) => {
    const file = event.target.files;
    clearErrors("photo");

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      const acceptedExt = ["png", "jpeg", "jpg"];
      if (acceptedExt.includes(ext)) {
        var photo = "";
        try {
          photo = await toBase64(file[0]);
        } catch (error) {
          console.error(error);
        }

        const newAttachment = {
          photo: photo,
          photoFileType: ext,
          photoUrl: null,
        };
        setValue("profilePhoto", newAttachment);
        console.log(newAttachment);
      } else {
        setError("photo", "This file formant not supported");
      }
    }
  };

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={handleCLoseModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton onClick={clearData}>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title section-title">{modalConfig?.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="mb-1 file-placeholder thumbnail-pic"
                  {...register("photo")}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={selectPhotoFile}
                    key={fileInputDummyKey}
                  />

                  {!!watchProfilePhoto?.photoUrl ||
                  !!watchProfilePhoto?.photo ? (
                    <img
                      // className="file-placeholder"
                      src={
                        !!watchProfilePhoto?.photoUrl
                          ? watchProfilePhoto?.photoUrl
                          : "data:image/jpeg;base64," + watchProfilePhoto?.photo
                      }
                      alt="img"
                    />
                  ) : (
                    <div>
                      {GetSvgUpload({ required: false })}
                      Upload Photo{" "}
                    </div>
                  )}
                  {errors.photo && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; "upload a valid image"
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Row>
              <Col md={4}>
                <div>
                  <label
                  // htmlFor=""
                  // className="form-label theme-label"
                  // title="This field is mandatory"
                  >
                    Name<span className="text-danger">&#9733;</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User Name"
                    aria-label="input example"
                    {...register("name", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM_SPACE_SPCL_CHAR,
                        message: PATTERN_ERR_MSG,
                      },
                      maxLength: {
                        value: 100,
                        message: "Limit: 100 characters",
                      },
                    })}
                  />
                  {errors.name && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.name.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Phone<span className="text-danger">&#9733;</span>
                  </label>
                  <div className="input-group">
                    {/* <select
                      name="countryCode"
                      {...register("phone")}
                      defaultValue="+60"
                      className="form-control country-code-customize"
                    >
                      {countryCodes.map((cc, index) => (
                        <option key={index} value={cc.value}>
                          {cc.label}
                        </option>
                      ))}
                    </select> */}

                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Phone"
                      aria-label="input example"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: PHONE_NUM,
                          message: PATTERN_ERR_MSG,
                        },
                        maxLength: {
                          value: 12,
                          message: "Field should contains 12 characters",
                        },
                      })}
                    />
                  </div>
                  {/* {errors.countryCode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.countryCode.message}
                      </span>
                    </div>
                  )} */}
                  {errors.phone && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.phone.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Email<span className="text-danger">&#9733;</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    aria-label="input example"
                    disabled={+watchUserId > 0}
                    {...register("email", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: EMAIL,
                        message: PATTERN_ERR_MSG,
                      },
                      // disabled: getValues("userId") > 0,
                    })}
                    maxLength="200"
                  />
                  {errors.email && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.email?.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Supplier ID
                    <span className="text-danger">&#9733;</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="supplier Id"
                    aria-label="input example"
                    {...register("supplierId", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM_SPACE_SPCL_CHAR,
                        message: PATTERN_ERR_MSG,
                      },
                      maxLength: {
                        value: 20,
                        message: "Field should contain 20 characters",
                      },
                    })}
                    maxLength="20"
                  />
                  {errors.supplierId && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.supplierId.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    aria-label="input example"
                    {...register("state", {
                      maxLength: {
                        value: 40,
                        message: "Exceeded max limit!!!",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: "Alphabets only acceptable!!",
                      },
                    })}
                    maxLength="40"
                  />
                  {/* <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={stateList}
                    placeholder="Select State"
                    value={
                      value
                        ? stateList.filter((opt) => value.includes(opt.value))
                        : value
                    }
                    onChange={(d) =>
                      onChange(d ? d.value : d, filterCity(d ? d.value : d))
                    }
                    isSearchable={true}
                    isClearable={true}
                    className="form-control  p-0"
                  />
                )}
              /> */}
                  {errors.state && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.state?.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    City
                  </label>

                  <input
                    type="text"
                    className="form-control pe-0"
                    placeholder="City"
                    aria-label="Name"
                    aria-describedby="basic-addon1"
                    {...register("city", {
                      maxLength: {
                        value: 40,
                        message: "Exceeded max limit!!!",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: "Alphabets only acceptable!!",
                      },
                    })}
                    maxLength="40"
                  />
                  {errors.city && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.city?.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Post Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="postcode"
                    maxLength="10"
                    {...register("postcode", {
                      required: false,
                      pattern: {
                        value: NUMBER_SPACE_REGEX,
                        message:
                          "Numeric values only acceptable without space inbetween!!",
                      },

                      maxLength: {
                        value: 10,
                        message: "Limit: 10 characters",
                      },
                    })}
                  />
                  {errors.postcode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.postcode?.message}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="Address"
                    aria-label="input example"
                    {...register("address", {
                      validate: (v) =>
                        BLACK_SPCL_CHARS.test(v) ? false : true,
                    })}
                    // maxLength="50"
                  />
                  {errors.address && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888;{" "}
                        {`Special characters ${BLACK_SPCL_CHARS_STR} not acceptable`}
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button
            type="reset"
            className="btn btn-default border"
            onClick={clearInfo}
            style={{ color: "black" }}
          >
            {watchUserId > 0 ? "Reset" : "Clear"}
          </Button>
          <Button
            variant="primary"
            className="theme-violet"
            onClick={handleSubmit(onSubmit, onErr)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SupplierAddEdit;

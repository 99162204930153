import { CUST_TYPE_BUSINESS } from "../../Helper/Constants";

export const initValCustomer = {
    address: '',
    bankAccount: '',
    bankName: '',
    beneficiaryName: '',
    city: '',
    companyAddress: '',
    companyName: '',
    companyRegNo: '',
    customerType: CUST_TYPE_BUSINESS,
    email: '',
    ic: '',
    isrCode: '',
    name: '',
    password: '',
    personalPhoneCode: '',
    phone: '',
    pickupAddress: '',
    pickupAttentionPhone: '',
    pickupAttentionTo: '',
    pickupPhoneCode: "+60",
    postcode: '',
    pwdSalt: '',
    requestedCreditAmt: '',
    state: '',
    generalDocList:[]

}
import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  ALPHANUM_NAME,
  BLACK_SPCL_CHARS,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import moment from "moment";
import { CallApiPost } from "../../Helper/serviceApi";

const NotificationAddEdit = ({
  config,
  handleClose,
  notifState,
  handleApiResponse,
}) => {
  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(notifState);
  }, [notifState]);

  const watchValidFrom = watch("validFrom");
  const watchValidTo = watch("validTo");

  const onSubmit = async (data) => {
    const resp = await CallApiPost("SaveAndUpdateNotification", [data]);
    handleClose();
    handleApiResponse(resp);
  };

  const onError = (err) => {
    console.log(err);
  };

  return (
    <Modal show={config?.show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="section-title">{config?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Valid From{""} <span className="text-danger">&#9733;</span>
              </label>
              <div>
                <Controller
                  name="validFrom"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Valid From"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={new Date()}
                      maxDate={
                        !!watchValidTo ? new Date(watchValidTo) : watchValidTo
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format(DATE_FORMAT_SERVER) : d)
                      }
                    />
                  )}
                />
                {errors?.validFrom && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.validFrom?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Valid To{""} <span className="text-danger">&#9733;</span>
              </label>
              <div>
                <Controller
                  name="validTo"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Valid To"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={
                        !!watchValidFrom ? new Date(watchValidFrom) : new Date()
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format("YYYY-MM-DD") : d)
                      }
                    />
                  )}
                />
                {errors?.validTo && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.validTo?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="form-label theme-label">
                Title <span className="text-danger">&#9733;</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("title", {
                  pattern: {
                    validate: (v) => (BLACK_SPCL_CHARS.test(v) ? false : true),
                  },
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              {errors?.title && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.title?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col md={6}>
              <label className="form-label theme-label">
                Description <span className="text-danger">&#9733;</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("description", {
                  pattern: {
                    validate: (v) => (BLACK_SPCL_CHARS.test(v) ? false : true),
                  },
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              {errors?.description && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.description?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
        <Button
          variant="primary"
          className="theme-violet"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationAddEdit;

import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import NotificationList from "./NotificationList";
import { Button } from "react-bootstrap";
import GetSvgIcon from "../../../images/svglist";
import NotificationAddEdit from "./NotificationAddEdit";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initNotifData = {
  id: 0,
  title: "",
  description: "",
  validFrom: null,
  validTo: null,
  active: true,
};

const initNotifModalConfig = {
  show: false,
  title: "",
};

const NotificationMain = () => {
  const [notifList, setNotifList] = useState([]);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [notifData, setNotifData] = useState(Object.assign({}, initNotifData));
  const [notifModalConfig, setNotifModalConfig] = useState(
    Object.assign({}, initNotifModalConfig)
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [notifId, setAdvId] = useState();
  useEffect(() => {
    fetchNotifcations();
  }, []);

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };

  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchNotifcations();
  };

  const openNotifModal = (argData, argTitle) => {
    setNotifData(Object.assign({}, argData));
    setNotifModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const closeNotifMOdal = () => {
    setNotifData(Object.assign({}, initNotifData));
    setNotifModalConfig(Object.assign({}, initNotifModalConfig));
  };

  const fetchNotifcations = async () => {
    setShowSpinner(true);
    const uri = "NotificationList";
    const resp = await CallApiGet(uri);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setNotifList(resp?.respData);
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to load notifications. Please try after sometime!!";
      }
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const handleEditClick = (data) => {
    openNotifModal(data, "EDIT NOTIFICATION");
  };
  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };
  const handleActiveDeactive = (row) => {
    if (row.active) {
      setAlertBody(
        <>
          Are you sure, you want to Delete Notification <b>{row.title}</b>
        </>
      );
      setAlertTitle("Delete Notification");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate Notification <b>{row.title}</b>
        </>
      );

      setAlertTitle("Activate Notification");
    }
    setAdvId(row.id);
    toggleAlert(true);
  };
  const activeInactive = async () => {
    const uri = "ActiveDeactiveNotif?id=" + notifId;
    let resp = await CallApiPost(uri);
    setShowAlert(false);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "", "Notification status updated successfully !!!");
        break;
      case 409:
        openPopup(false, "Error!", resp.respMessage);
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  return (
    <Layout
      spinnershow={showSpinner}
      alertConfig={alertConfig}
      hideAlert={closePopup}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">Home Notification</div>
        </div>
        <div className="float-end d-flex align-items-center">
          <Button
            className="theme-violet"
            onClick={() =>
              openNotifModal(initNotifData, "ADD NEW NOTIFICATION")
            }
          >
            {GetSvgIcon("PlusCircle")} Add
          </Button>
        </div>
      </div>
      <NotificationList
        tableData={notifList}
        handleEditClick={handleEditClick}
        handleActiveDeactive={handleActiveDeactive}
      />
      <NotificationAddEdit
        config={notifModalConfig}
        handleClose={closeNotifMOdal}
        notifState={notifData}
        handleApiResponse={handleApiResponse}
      />
      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={activeInactive}
        />
      )}
    </Layout>
  );
};

export default NotificationMain;

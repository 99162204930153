import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import {
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  COUNTRY_CODES,
  NAME_REGEX,
  NUMBER_REGEX,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const AddressBookAddEdit = ({
  initAdd,
  modalConfig,
  onHide,
  onSubmit,
  custList,
  stateCityObj,
  setStateCityObj,
}) => {
  const userType = getUserInfo("userType");

  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: initAdd });

  const watchCity = watch("city");
  const watchState = watch("state");
  const watchId = watch("id");

  const { fields: append, remove } = useFieldArray({
    control,
    name: "",
  });

  useEffect(() => {
    reset(Object.assign({}, initAdd));
  }, [initAdd]);

  const filterCity = (state) => {
    let cityArrList = [];
    let scObj = Object.assign({}, stateCityObj);
    if (!!state) {
      cityArrList = scObj.stateCityMap
        .filter((e) => e.map_state === state)
        .map((e) => ({ value: e.map_city, label: e.map_city }));
      scObj.selCityArr = cityArrList;
      if (!cityArrList.includes(watchCity)) {
        setValue("city", "");
      }
    } else {
      scObj.selCityArr = scObj.loadedCityArr;
    }
    setStateCityObj(Object.assign({}, scObj));
  };

  const filterState = (city) => {
    let stateArr = [];
    let scObj = Object.assign({}, stateCityObj);
    if (!!city) {
      stateArr = scObj.stateCityMap
        .filter((e) => e.map_city === city)
        .map((e) => ({ value: e.map_state, label: e.map_state }));

      const distStateArr = [...new Set(stateArr)];
      scObj.selStateArr = [...distStateArr];
      setValue("state", distStateArr[0].value);
      clearErrors("state");
    } else {
      scObj.selStateArr = scObj.loadedStateArr;
      setValue("state", "");
    }
    setStateCityObj(Object.assign({}, scObj));
  };

  const handleClose = () => {
    reset();
    onHide();
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title section-title">{modalConfig?.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            {!LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType) && (
              <Row>
                <Col md={6}>
                  <label className="form-label theme-label">
                    Select Customer <span className="text-danger">&#9733;</span>
                  </label>
                  <Controller
                    name="custId"
                    control={control}
                    rules={{
                      required: {
                        value:
                          !LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType),
                        message: "This field is required!!",
                      },
                      min: {
                        value: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
                          userType
                        )
                          ? 0
                          : 1,
                        message: "This field is required!!",
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: "This field is required!!",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="Select Customer"
                        isSearchable={true}
                        isClearable={true}
                        className="form-control p-0"
                        options={custList}
                        value={
                          !!value
                            ? custList?.find((opt) => opt.value === value)
                            : value
                        }
                        onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                      />
                    )}
                  />
                  {errors?.custId && (
                    <div>
                      <span className="text-danger fw-bold font-12">
                        &#9888; {errors?.custId?.message}
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col md={6}>
                <label className="form-label theme-label">
                  Name <span className="text-danger">&#9733;</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter name"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: NAME_REGEX,
                      message: "Enter Valid Characters!!!",
                    },
                    maxLength: {
                      value: 250,
                      message: "Character Limit: 250 Characters!!",
                    },
                  })}
                />

                {errors?.name && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.name?.message}
                    </span>
                  </div>
                )}
              </Col>
              <Col md={6}>
                <label className="form-label theme-label">
                  Contact <span className="text-danger">&#9733;</span>
                </label>
                <div className="input-group">
                  <select
                    name="phoneCode"
                    {...register("phoneCode", {
                      required: {
                        value: true,
                        message: "Phonecode field is required!!!",
                      },
                    })}
                    defaultValue="+60"
                    className="input-group-text country-code-customize"
                  >
                    {COUNTRY_CODES.map((cc, index) => (
                      <option key={index} value={cc.value}>
                        {cc.label}
                      </option>
                    ))}
                  </select>

                  <input
                    type="number"
                    className="form-control"
                    name="phone"
                    placeholder="Enter Phone"
                    {...register("phone", {
                      required: {
                        value: true,
                        message: "Phone field is required",
                      },
                      maxLength: {
                        value: 20,
                        message: "Character Limit: 20 Digit!!",
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: "Incorrect Phone Number!!!",
                      },
                    })}
                  />
                </div>
                {errors?.phone && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.phone?.message}
                    </span>
                  </div>
                )}
                {errors?.phoneCode && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.phoneCode?.message}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="form-label theme-label">
                  Address <span className="text-danger">&#9733;</span>
                </label>
                <textarea
                  className="form-control"
                  name="address"
                  placeholder="Enter Address"
                  maxLength={500}
                  {...register("address", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: 500,
                      message: "Character Limit: 500 Characters!!",
                    },
                    validate: (v) =>
                      BLACK_SPCL_CHARS.test(v)
                        ? "Please check for invalid characters: " +
                          BLACK_SPCL_CHARS_STR
                        : true,
                  })}
                />
                {errors?.address && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.address?.message}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label className="form-label theme-label">
                  City <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={stateCityObj?.selCityArr}
                      placeholder="Select City"
                      value={
                        value
                          ? stateCityObj?.selCityArr.filter(
                              (opt) => value === opt.value
                            )
                          : value
                      }
                      onChange={(d) =>
                        onChange(d ? d.value : d, filterState(d ? d.value : d))
                      }
                      isSearchable={true}
                      isClearable={true}
                      className="form-control p-0"
                    />
                  )}
                />
                {errors?.city && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.city?.message}
                    </span>
                  </div>
                )}
              </Col>
              <Col md={4}>
                <label className="form-label theme-label">
                  State <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={stateCityObj?.selStateArr}
                      placeholder="Select State"
                      value={
                        value
                          ? stateCityObj?.selStateArr.filter(
                              (opt) => value === opt.value
                            )
                          : value
                      }
                      onChange={(d) =>
                        onChange(d ? d.value : d, filterCity(d ? d.value : d))
                      }
                      isSearchable={true}
                      isClearable={true}
                      className="form-control p-0"
                    />
                  )}
                />
                {errors?.state && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.state?.message}
                    </span>
                  </div>
                )}
              </Col>
              <Col md={4}>
                <label className="form-label theme-label">
                  Postcode <span className="text-danger">&#9733;</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="postcode"
                  placeholder="Enter Postcode"
                  {...register("postcode", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: 20,
                      message: "Character Limit: 20 Digit!!",
                    },
                    pattern: {
                      value: NUMBER_REGEX,
                      message: "Incorrect Phone Number!!!",
                    },
                  })}
                />
                {errors?.postcode && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888; {errors?.postcode?.message}
                    </span>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="theme-violet"
            disabled={+watchId > 0 && !isDirty}
            onClick={handleSubmit(onSubmit, onError)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressBookAddEdit;

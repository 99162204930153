import React from "react";
import Modal from "react-bootstrap/Modal";

const ProtectionConformAlert = ({
  children,
  confirmAlertConfig,
  hideConfirmAlert,
  confirmAlert,
  onProcided,
}) => {
  return (
    <>
      <Modal
        size={confirmAlertConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmAlertConfig.show}
        onHide={hideConfirmAlert}
      >
        <Modal.Header closeButton>
          <Modal.Title>{confirmAlertConfig.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{children}</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-danger me-2"
            onClick={onProcided}
          >
            Proceed without insurance
          </button>
          <button
            type="button"
            className="btn btn-primary theme-violet"
            onClick={confirmAlert}
          >
            Purchase Insurance
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProtectionConformAlert;

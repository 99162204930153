import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import ScheduleList from "./ScheduleList";
import GetSvgIcon from "../../../images/svglist";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import UploadCharteredSchedule from "./UploadCharteredSchedule";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DATE_TIME_FORMAT_MOMENT,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import moment from "moment";
import SchedulePlan from "./SchedulePlan";
import PlannedOrderSummary from "../../OrderBooking/ChartaredOrderBooking/PlannedOrderSummary";
import ModalConfirmAlert from "../../Layout/ModalConfirmAlert";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  CHARTERED_WGT_SRVC_ID,
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_STAFF_USER_TYPE,
} from "../../../ConfigConstants/ConfigConstants";
import BookingModal from "./BookingModal";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initModalConfig = {
  show: false,
  fullscreen: true,
  modalType: "",
  data: {
    planId: 0,
    routeId: 0,
    routeCode: "",
    planSts: 0,
    remark: "",
    status: "",
    tripStart: "",
    validFrom: "",
    validTo: "",
    driver1Id: 0,
    driver2Id: 0,
    truckId: 0,
    origin: "",
    orgAddr: "",
    orgCity: "",
    orgState: "",
    orgPostcode: "",
    originId: 0,
    originSbuCode: "",
    destination: "",
    destAddr: "",
    destCity: "",
    destState: "",
    destPostcode: "",
    destnationId: 0,
    destinationSbuCode: "",
    itinerary: "[]",
  },
};
const initSearchParam = {
  dateFrom: moment().subtract(1, "days").format(DATE_FORMAT_SERVER),
  dateTo: moment().add(1, "days").format(DATE_FORMAT_SERVER),
};
const initDropdownOpt = {
  vhclOpts: [],
  planStsOpts: [],
  driverOpts: [],
};

const initOrdSummaryConfig = {
  show: false,
  title: "",
};

const initConfirmConfig = {
  show: false,
  size: "sm",
  title: "",
};

const ScheduleMain = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [scheduleList, setScheduleList] = useState([]);
  const [searchParams, setSearchParams] = useState(
    Object.assign({}, initSearchParam)
  );
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [dropdownOpts, setDropdownOpts] = useState(
    Object.assign({}, initDropdownOpt)
  );
  const [ordSummaryModal, setOrdSummaryModal] = useState(
    Object.assign({}, initOrdSummaryConfig)
  );
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(
    Object.assign({}, initConfirmConfig)
  );
  const [selPlan, setSelPlan] = useState(Object.assign({}, modalConfig.data));

  const [bookModalConfig, setBookModalConfig] = useState(
    Object.assign({}, initAlertConfig)
  );

  const handleClose = () =>
    setOrdSummaryModal(Object.assign({}, initOrdSummaryConfig));
  const [message, setMessage] = useState(null);

  const openRouteDetls = async (rowData, modalType) => {
    setShowSpinner(true);
    const resp = await CallApiGet(`GetPlanDtlsById?planId=${rowData?.planId}`);
    if (resp.respCode === 200 && !!resp.respData && resp.respData.length > 0) {
      let copyRowData = Object.assign({}, resp.respData[0]);
      copyRowData.itinerary = JSON.parse(copyRowData.itinerary);
      setModalConfig({
        show: true,
        fullscreen: true,
        data: copyRowData,
        modalType: modalType,
      });
    } else {
      openPopup(
        false,
        "ERROR",
        "Oops! something went wrong. Please try after sometime"
      );
    }
    setShowSpinner(false);
  };

  const onHide = () => {
    setModalConfig(Object.assign({}, initModalConfig));
  };

  const fetchScheduleList = async (payload) => {
    setShowSpinner(true);
    const uri = `GetCharteredPlanList?dateFrom=${payload?.dateFrom}&dateTo=${payload?.dateTo}`;
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setScheduleList(data.respData);
    } else if (data.respCode === 401) {
      console.error(data);
      openPopup(
        false,
        "UNAUTHORIZED",
        "Sorry, you don't have the read permission!!"
      );
    } else {
      console.error(data);
      openPopup(false, "ERROR", SERVER_ERR_MSG);
    }
    setShowSpinner(false);
  };

  const fetchDropdownOpts = async () => {
    setShowSpinner(true);
    const payload = {
      argVehicle: 1,
      argDriverUser: 1,
      argPlanStatus: 1,
    };
    const resp = await CallApiPost("GetDropdownOptions", payload);
    if (resp.respCode === 200 && !!resp.respData) {
      setDropdownOpts({
        vhclOpts: resp?.respData?.vhclNoArr,
        planStsOpts: resp?.respData?.planStatus,
        driverOpts: resp?.respData?.driverUserArr,
      });
    }
    setShowSpinner(false);
  };

  useEffect(() => {
    fetchScheduleList(searchParams);
    fetchDropdownOpts();
  }, []);

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: false,
      size: "md",
      show: false,
      title: "",
      description: "",
    });
    fetchScheduleList(searchParams);
  };

  const openPopup = (bool, title, desc) => {
    setAlertConfig({
      isSuccess: bool,
      size: "md",
      show: true,
      title: title,
      description: desc,
    });
  };

  const handleChange = (key, value) => {
    if (!!value) {
      setSearchParams({
        ...searchParams,
        [key]: moment(value).format(DATE_FORMAT_SERVER),
      });
    } else {
      setSearchParams({ ...searchParams, [key]: value });
    }
  };

  const onSubmit = () => {
    fetchScheduleList(searchParams);
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Oops! Something went wrong. Please try after sometime!!"
        );
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Sorry, we failed to process your request due to some techinical error. Please try after sometime!!"
        );
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const onSave = async (data) => {
    setShowSpinner(true);
    let stopsArr = [];
    data.itinerary
      .filter((element) => element.stopSeq > 0 && element.stopSeq < 99)
      .forEach((element) => {
        const stop = element.stopSeq + "-S" + element.stopSeq;
        stopsArr.push(stop);
      });

    const routeValidArray = [
      {
        RowIndex: 0,
        RouteCode: data.routeCode,
        StopsStr: stopsArr.join(","),
      },
    ];
    if (modalConfig?.modalType === "A") {
      data.parentPlanId = data.planId;
      data.isAddTruck = true;
      data.planId = 0;
      const date = moment(data.tripStart, "DD-MM-YYYY HH:mm").format(
        DATE_FORMAT_SERVER
      );
      data.tripStart = moment(`${date} ${data.etd}`).format(
        DATE_TIME_FORMAT_MOMENT
      );
    }
    let payload = {
      routeValidArr: routeValidArray,
      planArr: [data],
    };
    const resp = await CallApiPost("SaveCharteredPlan", payload);
    onHide();
    if (resp.respCode === 9) {
      resp.respMessage = resp.respData[0].ErrorMessage;
    }
    handleApiResponse(resp);
    setShowSpinner(false);
  };
  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };
  const resetMessage = () => {
    setMessage("");
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig(Object.assign({}, initConfirmConfig));
    setSelPlan(Object.assign({}, modalConfig.data));
  };

  const saveWgtForPlan = async () => {
    setShowSpinner(true);
    selPlan.serviceId = CHARTERED_WGT_SRVC_ID;
    const apiResp = await CallApiPost("SetServiceForPlan", selPlan);
    handleApiResponse(apiResp);
    hideConfirmAlert();
    setShowSpinner(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelPlan({ ...selPlan, [name]: value });
  };

  const openModalForWgt = (row) => {
    setSelPlan(row);
    setConfirmAlertConfig({
      show: true,
      size: "sm",
      title: "WEIGHT",
    });
  };

  const UploadBtn = () => {
    const userType = getUserInfo("userType");
    const isStaff = [
      LINEHAUL_ADMIN_USER_TYPE,
      LINEHAUL_STAFF_USER_TYPE,
    ].includes(userType);
    return (
      <>
        {isStaff && (
          <div className="float-end d-flex">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet me-1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#upload-file-modal"
                onClick={() => setUploadType("D")}
              >
                {GetSvgIcon("IconArrowup")} Upload Driver
              </button>
              <button
                className="btn btn-outline-secondary theme-violet"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#upload-file-modal"
                onClick={() => setUploadType("P")}
              >
                {GetSvgIcon("IconArrowup")} Upload Schedule
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  const openBookModal = (row) => {
    setSelPlan(Object.assign({}, row));
    setBookModalConfig({
      show: true,
      size: "lg",
      title: "Order Information",
    });
  };

  const handleCLose = () => {
    setBookModalConfig(Object.assign({}, initAlertConfig));
  };

  const onBook = async (payload) => {
    setShowSpinner(true);
    const resp = await CallApiPost("saveScheduleChartedBooking", payload);
    setShowSpinner(false);
    handleCLose();
    handleApiResponse(resp);
    fetchScheduleList(searchParams);
  };

  return (
    <>
      <div>
        <Layout
          spinnershow={showSpinner}
          alertConfig={alertConfig}
          hideAlert={hideAlert}
        >
          <div className="clearfix border-bottom pb-2 mb-2">
            <div className="float-start">
              <div className="section-title mt-1">Chartered Schedule</div>
            </div>
            <UploadBtn />
          </div>
          <ScheduleList
            scheduleList={scheduleList}
            onSubmit={onSubmit}
            searchParams={searchParams}
            handleChange={handleChange}
            openRouteDetls={openRouteDetls}
            handleBtnClick={openBookModal}
            openModalForWgt={openModalForWgt}
          />
          <UploadCharteredSchedule
            openPopup={openPopup}
            uploadType={uploadType}
          />
          <SchedulePlan
            modalConfig={modalConfig}
            handleClose={onHide}
            dropdownOpts={dropdownOpts}
            onSave={onSave}
          />
          <PlannedOrderSummary
            handleMoalClose={handleClose}
            moadlConfig={ordSummaryModal}
            showAlert={showAlert}
            setShowSpinner={setShowSpinner}
          />
          <ModalConfirmAlert
            confirmAlertConfig={confirmAlertConfig}
            hideConfirmAlert={hideConfirmAlert}
            confirmAlert={saveWgtForPlan}
            confirmBtnTxt="SAVE"
          >
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Enter Weight (kg){" "}
            </label>
            <input
              type="number"
              name="serviceValue"
              className="form-control"
              defaultValue={selPlan?.serviceValue}
              onChange={handleOnChange}
            />
          </ModalConfirmAlert>
          <BookingModal
            handleCLose={handleCLose}
            bookModalConfig={bookModalConfig}
            selPlan={selPlan}
            onBook={onBook}
          />
        </Layout>
      </div>
    </>
  );
};

export default ScheduleMain;

import React from "react";
import Header from "./Header";
import Spinner from "./Spinner";
import ModalAlert from "./ModalAlert";
import ModalConfirmAlert from "./ModalConfirmAlert";

const Layout = ({
  children,
  spinnershow,
  alertConfig,
  hideAlert,
  confirmAlertConfig,
  hideConfirmAlert,
  confirmAlert,
  type = "N",
  isHome = false,
  mainClass,
}) => {
  return (
    <>
      <Header isHome={isHome} />
      <div
        className={
          type === "D"
            ? "container-fluid min-ht-100 bg-admin-dashboard"
            : "container-fluid min-ht-100"
        }
      >
        <div className="row">
          <main
            className={
              !!mainClass
                ? mainClass
                : type === "D"
                ? "col-md-12 mt-4"
                : "col-md-12 p-3"
            }
          >
            {children}
          </main>
        </div>
      </div>
      <Spinner show={spinnershow} />

      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}

      {!!confirmAlertConfig && (
        <ModalConfirmAlert
          confirmAlertConfig={confirmAlertConfig}
          hideConfirmAlert={hideConfirmAlert}
          confirmAlert={confirmAlert}
        >
          <p>{confirmAlertConfig.description}</p>
        </ModalConfirmAlert>
      )}
    </>
  );
};

export default Layout;

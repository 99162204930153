import React, { useEffect, useState } from "react";
import moment from "moment";
import Layout from "../../Layout/Layout";
import CardWidget from "../Widgets/CardWidget";
import RegistrationWidget from "../Widgets/RegistrationWidget";
import OrderDetailWidget from "../Widgets/OrderDetailWidget";
import ClaimWidget from "../Widgets/ClaimWidget";
import VehicleWidget from "../Widgets/VehicleWidget";
import { CallApiPost } from "../../Helper/serviceApi";

const AdminDashboard = () => {
  let initCardData = [
    { border: "primary", title: "Total Orders", count: 0, icon: "TotalListDashboard" },
    { border: "danger", title: "Order in Transit", count: 0, icon: "TotalTransitDashboard" },
    { border: "success", title: "Order Delivered", count: 0, icon: "TotalDeliveredDashboard" },
    { border: "warning", title: "Domestic Orders", count: 0, icon: "TotalDomesticDashboard" },
    { border: "info", title: "International Orders", count: 0, icon: "TotalInternationalDashboard" }
  ]
  const sDate = moment().subtract(90, "days").format("YYYY-MM-DD")
  const eDate = moment().format("YYYY-MM-DD")
  const [orderDetails, setOrderDetails] = useState(null)
  const [orderGraphData, setOrderGraphData] = useState(null)
  const [claimDetails, setClaimDetails] = useState(null)
  const [cardData, setCardData] = useState(initCardData)
  const [registrationWdata, setRegistrationWdata] = useState(null)
  const [vehicleWData, setVehicleWData] = useState(null)
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    getDashboardData()
  }, [])

  const getDashboardData = async () => {
    setSpinner(true)
    let payload = { fromDt: sDate, toDt: eDate }
    let resp = await CallApiPost('DashboardStaff', payload)
    if (!!resp && resp.respCode === 200) {
      let data = resp.respData
      setOrderDetails(data?.orderDetails[0])
      mapTopCardsData(data?.orderDetails[0])
      mapRegistrationData(data)
      mapClaimData(data?.claimDetails[0])
      mapVehicleExpiryData(data)
      mapOrderDetailsGraphData(data?.orderDetails[0])
      setSpinner(false)
    } else {
      setSpinner(false)
    }
  }

  const mapTopCardsData = (oDetails) => {
    setCardData([
      { border: "primary", title: "Total Orders", count: oDetails?.totalOrders, icon: "TotalListDashboard" },
      { border: "danger", title: "Order in Transit", count: oDetails?.ordersInTransit, icon: "TotalTransitDashboard" },
      { border: "success", title: "Order Delivered", count: oDetails?.ordersDelivered, icon: "TotalDeliveredDashboard" },
      { border: "warning", title: "Domestic Orders", count: oDetails?.domesticOrders, icon: "TotalDomesticDashboard" },
      { border: "info", title: "International Orders", count: oDetails?.internationalOrders, icon: "TotalInternationalDashboard" }
    ])
  }

  const mapRegistrationData = (data) => {
    let labelsMonth = data?.registration.map(item => item.Month)
    let dataMapPersonal = data?.registration.map(item => item.Customer_Personal)
    let dataMapBusiness = data?.registration.map(item => item.Customer_Business)
    let dataMapAgent = data?.registration.map(item => item.Customer_Agent)
    setRegistrationWdata({
      labels: labelsMonth,
      datasets: [
        {
          label: 'Personal',
          data: dataMapPersonal,
          backgroundColor: 'rgb(56, 177, 170)'
        },
        {
          label: 'Business',
          data: dataMapBusiness,
          backgroundColor: 'rgb(100, 242, 194)'
        },
        {
          label: 'Sales Partner',
          data: dataMapAgent,
          backgroundColor: 'rgb(100, 242, 194)'
        }
      ],
    })
  }

  const mapVehicleExpiryData = (data) => {
    const labelsVE = data?.vehicleExpiry.map(item => item.Month)
    const dataMapPO = data?.vehicleExpiry.map(item => item.PUSPAKOM)
    const dataMapRT = data?.vehicleExpiry.map(item => item.ROADTAX)
    const dataMapIN = data?.vehicleExpiry.map(item => item.INSURANCE)
    setVehicleWData({
      labels: labelsVE,
      datasets: [
        {
          label: 'Road Tax',
          data: dataMapRT,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Puspakom',
          data: dataMapPO,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Insurance',
          data: dataMapIN,
          borderColor: 'rgb(100, 242, 194)',
          backgroundColor: 'rgba(100, 242, 194, 0.5)',
        },
      ],
    })
  }

  const mapClaimData = (clDetails) => {
    let claimGraphData = {
      labels: ['Settled', 'Under Review'],
      datasets: [
        {
          label: 'No. of claims',
          data: [clDetails?.claimSettled, clDetails?.claimUnderReview],
          backgroundColor: [
            'rgba(56, 177, 170,0.3)',
            'rgba(220, 53, 69,0.3)'
          ],
          borderColor: [
            'rgba(56, 177, 170,1)',
            'rgba(220, 53, 69,1)'
          ],
          borderWidth: 1,
        },
      ]
    }
    setClaimDetails({
      gData: claimGraphData,
      data: clDetails
    })
  }

  const mapOrderDetailsGraphData = (oDetails) => {
    setOrderGraphData({
      labels: ['Transit', 'Delivered'],
      datasets: [
        {
          label: 'No. of orders',
          data: [oDetails?.ordersInTransit, oDetails?.ordersDelivered],
          backgroundColor: [
            'rgba(56, 177, 170,0.3)',
            'rgba(220, 53, 69,0.3)'
          ],
          borderColor: [
            'rgba(56, 177, 170,1)',
            'rgba(220, 53, 69,1)'
          ],
          borderWidth: 1,
        },
      ],
    })
  }

  return (
    <>
      <Layout
        spinnershow={spinner}
        type={'D'}
      >
        <div className="row">
          <div class="float-start"><div class="section-title mt-1">Last 3 Months Data</div></div>
        </div>
        <div className="row my-4">
          {
            !!cardData && cardData.length > 0 &&
            cardData.map((item, index) => (
              <CardWidget key={index} border={item?.border} title={item?.title} count={item?.count} icon={item?.icon} />
            ))
          }
        </div>
        <div className="row">
          <div className="col">
            {!!registrationWdata && <RegistrationWidget data={registrationWdata} />}
          </div>
          <div className="col">
            {!!vehicleWData && <VehicleWidget data={vehicleWData} />}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {!!claimDetails && <ClaimWidget data={claimDetails} type={'A'} />}
          </div>
          <div className="col">
            {!!orderDetails && <OrderDetailWidget data={orderDetails} gData={orderGraphData} type={'A'} />}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AdminDashboard;

import React from "react";
import moment from "moment";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  DATE_TIME_FORMAT_MOMENT
} from "../Helper/Constants";

const OrderCancellationLog = (props) => {
    const columns = [
        {
            name: "Date & Time",
            selector: (row) => moment(row.cancelled_on).format(DATE_TIME_FORMAT_MOMENT),
        },
        {
            name: "Cancelled By (Account ID)",
            selector: (row) => row.cancelled_by,
        },
        {
            name: "Reason of Cancel",
            selector: (row) => row.cancellation_reason,
        },
        {
            name: "Order Status Before Cancellation",
            selector: (row) => row.order_status,
        }
    ];

    return (
        <div
            className="modal fade"
            id="order_cancellation-log-modal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title section-title" id="exampleModalLabel">
                            Order Cancellation Details
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body overflow-auto">
                        <div className="row">
                            <div>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="clearfix"></div>
                                        <div className="col-md-12 mt-1 popup-data-table position-relative">
                                            <DataTable
                                                columns={columns}
                                                data={props.data}
                                                pagination
                                                striped
                                                highlightOnHover
                                                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                                                paginationPerPage={defaultPageLimit}
                                                customStyles={customDataTableStyles}
                                                defaultSortAsc={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-default border me-2" data-bs-dismiss="modal">
                            Cancel
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default OrderCancellationLog
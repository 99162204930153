import React from "react";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import { Col } from "react-bootstrap";

const BookingSapceService = (props) => {
  const {
    register,
    watch,
    errors,
    clearErrors,
    watchPicHandling,
    watchDelHandling,
    acceptedSize,
    setValue,
    watchPackaging,
    isPicHandling, isDlvHandling, isPackaging
  } = props;
  return (
    <>
      <div className="w-100 d-block">
        <div className="alert alert-primary border border-2 rounded-2 p-3 mb-3">
          <div className="col-lg-12">
            <div className="row">
              <Col>
                <div className="col">
                  <span className="ms-1">
                    {GetSvgIcon("ToolTip")}{" "}
                  </span>
                  <label className="form-label theme-label" htmlFor="">
                    Pallet Dimension: {(!!watch("palletL") && !!watch("palletW") && !!watch("palletH")) && (watch("palletL") + "cm x " + watch("palletW") + "cm x " + watch("palletH") + "cm")}
                  </label>
                </div>

                <div className="col">
                  <span className="ms-1">
                    {GetSvgIcon("ToolTip")}{" "}
                  </span>
                  <label className="form-label theme-label" htmlFor="">
                    Max weight per pallet space: {!!watch("maxWgtPerPallet") && (watch("maxWgtPerPallet") + "KG")}
                  </label>
                </div>
              </Col>

              <Col>
                {
                  !!watch("minDaysForDisc") && +watch("minDaysForDisc") > 0 &&
                  <div className="col">
                    <span className="ms-1">
                      {GetSvgIcon("ToolTip")}{" "}
                    </span>
                    <label className="form-label theme-label" htmlFor="">
                      Pre-Order atleast {watch("minDaysForDisc")} Days in advance to enjoy discount rate!
                    </label>
                  </div>
                }

                {
                  !!watch("minPltsForDisc") && +watch("minPltsForDisc") > 0 &&
                  <div className="col">
                    <span className="ms-1">
                      {GetSvgIcon("ToolTip")}{" "}
                    </span>
                    <label className="form-label theme-label" htmlFor="">
                      Order atleast {watch("minPltsForDisc")} Pallet above to enjoy discount rate!
                    </label>
                  </div>
                }
              </Col>


            </div>
          </div>
        </div>
      </div>

      <div className="bg-white border border-2 rounded-2 p-2">
        <div className="d-flex p-2 justify-content-start align-item-center">
          <div className="subheadingtext col-auto align-self-center me-2">
            No. of Packages  <span className="text-danger">&#9733;</span>
          </div>
          <div className="col-3 align-self-center">
            <input
              id=""
              type="text"
              className="form-control ps-2"
              {...register("totalPalletArea", {
                onChange: () => clearErrors("totalPalletArea"),
              })}
            />
            {errors.totalPalletArea && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.totalPalletArea.message}
                </span>
              </div>
            )}
          </div>
          {/* <div className="col-auto align-self-center ms-2">
            <div className="text-danger font-12">
              Standard Pallet size {acceptedSize[0]?.value}M X {acceptedSize[1]?.value}M
              <br />Max Pallet Weight
            </div>
          </div> */}
          <div className="subheadingtext col-auto align-self-center mx-2">
            Content Description  <span className="text-danger">&#9733;</span>
          </div>
          <div className="col-3 align-self-center">
            <input
              id=""
              type="text"
              className="form-control ps-2"
              {...register("packageDesc", {
                onChange: () => clearErrors("packageDesc"),
              })}
            />
            {errors.packageDesc && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.packageDesc.message}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-100 d-block">
        <div className="bg-white border border-2 rounded-2 p-3 my-3">
          <div className="col-lg-12">
            <div className="subheadingtext pb-2">Value Added Services</div>
            <div>
              <div className="row">
                <div className="col">
                  <label className="form-label theme-label" htmlFor="">
                    Pickup Handling
                    <span data-tooltip-id="p-handel-label" className="ms-1">
                      {GetSvgIcon("ToolTip")}
                    </span>
                  </label>
                  <Tooltip
                    id="p-handel-label"
                    content={
                      "Enter Number of Pallets for which Pickup Handling Service Required."
                    }
                    place="top"
                  />
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value="p_handling"
                        aria-label="Checkbox for following text input"
                        {...register("P_HDL", {
                          onChange: (val) => (
                            !val.target.checked
                              ? setValue("picHandlingPallets", "")
                              : // eslint-disable-next-line no-sequences
                              "",
                            clearErrors("picHandlingPallets")
                          ),
                        })}
                        disabled={isPicHandling === 'false'}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("picHandlingPallets", {
                        onChange: () => clearErrors("picHandlingPallets"),
                      })}
                      disabled={(isPicHandling === 'true') || !watchPicHandling}
                    />
                  </div>
                  {errors.picHandlingPallets && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.picHandlingPallets.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col">
                  <label className="form-label theme-label" htmlFor="">
                    Delivery Handling
                    <span data-tooltip-id="d-handel-label" className="ms-1">
                      {GetSvgIcon("ToolTip")}
                    </span>
                  </label>
                  <Tooltip
                    id="d-handel-label"
                    content={
                      "Enter Number of Pallets for which Delivery Handling Service Required."
                    }
                    place="top"
                  />
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value="d_handling"
                        aria-label="Checkbox for following text input"
                        {...register("D_HDL", {
                          onChange: (val) => (
                            !val.target.checked
                              ? setValue("delHandlingPallets", "")
                              : // eslint-disable-next-line no-sequences
                              "",
                            clearErrors("delHandlingPallets")
                          ),
                        })}
                        disabled={isDlvHandling === 'false'}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("delHandlingPallets", {
                        onChange: () => clearErrors("delHandlingPallets"),
                      })}
                      disabled={(isDlvHandling === 'true') || !watchDelHandling}
                    />
                  </div>
                  {errors.delHandlingPallets && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.delHandlingPallets.message}
                      </span>
                    </div>
                  )}
                </div>

                <div className="col">
                  <label className="form-label theme-label" htmlFor="">
                    Packaging
                    <span data-tooltip-id="pack-lebel" className="ms-1">
                      {GetSvgIcon("ToolTip")}
                    </span>
                  </label>
                  <Tooltip
                    id="pack-lebel"
                    content={
                      "Enter Number of Pallets for which Packaging Service Required."
                    }
                    place="top"
                  />
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value="packaging"
                        aria-label="Checkbox for following text input"
                        {...register("PKG", {
                          onChange: (val) => (
                            !val.target.checked
                              ? setValue("packagingPallets", "")
                              : // eslint-disable-next-line no-sequences
                              "",
                            clearErrors("packagingPallets")
                          ),
                        })}
                        disabled={isPackaging === 'false'}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("packagingPallets", {
                        onChange: () => clearErrors("packagingPallets"),
                      })}
                      disabled={(isPackaging === 'true') || !watchPackaging}
                    />
                  </div>
                  {errors.packagingPallets && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.packagingPallets.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col align-self-center mt-2">
                  <div className="input-group">
                    <div className="input-group-text">
                      <input
                        className="form-check-input formsty"
                        type="checkbox"
                        name=""
                        id=""
                        value="dor"
                        // value="option1"
                        {...register("dor")}
                      // disabled={!avalServices?.pickup}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value="Delivery Order Return"
                      disabled
                    />
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default BookingSapceService;

import React from 'react'

const Footer = () => {
  return (
    <footer className="footer mt-auto px-3 py-1 d-none">
		<div className="col-md-12">
			<div className="clearfix">
				<div className="float-start">
					<span>&copy; 2021 Line Haul. All Rights Reserved.</span>
				</div>
				<div className="float-end">
				  <a href="../html/Privacy_Policy.html">Privacy Policy</a> | <a href="#">Cookies Policy</a>   | <a href="../html/TermsofUse.html">Terms of Use</a>
				</div>
			 </div>
		</div>
	</footer>
  )
}

export default Footer
import React, { useState} from 'react'
import ModalLayout from "../Layout/ModalLayout";
import { Col, Container, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { DATE_FORMAT_CLIENT,DATE_FORMAT_SERVER } from '../Helper/Constants';
import moment from 'moment';
import { CallApiPost } from '../Helper/serviceApi';

const ProblemShipmentRerouteModal = ({ closeModal, modalConfig, rowData,showAlert,setSpinner, loadData}) => {
    const [tripDate, setTripDate] = useState(null)

    const onSubmit = async () => {
        setSpinner(true)
        try{
            let payload = {
                palletId: rowData?.plt_id,
                originSbuId: rowData?.event_sbu_id,
                destnSbuId: rowData?.dest_sbu_id,
                tripDate: moment(tripDate).format(DATE_FORMAT_SERVER),
                problemId: rowData?.id
            }
            let result = await CallApiPost('reroute', payload)
            setSpinner(false);
            if(result != null && (result?.respCode === 200 || result?.respCode === 0)) {
                loadData("ProblemShipment");
                closeModal();
                showAlert({
                    isSuccess: true,
                    size: "md",
                    show: true,
                    title: 'SUCCESS',
                    description: result?.respMessage
                });
            } else if(result != null && result?.respCode === 304) { //Not Modified
                closeModal();
                showAlert({
                    isSuccess: false,
                    size: "md",
                    show: true,
                    title: 'FAILURE',
                    description: result?.respMessage
                });
            } else {
                closeModal();
                showAlert({
                    isSuccess: false,
                    size: "md",
                    show: true,
                    title: 'FAILURE',
                    description: 'Something went wrong. Please try after sometime.'
                });
            }
        } catch (e) {
            setSpinner(false)
            showAlert({
                isSuccess: false,
                size: "md",
                show: true,
                title: 'FAILURE',
                description: 'Something went wrong. Please try after sometime.'
            });
        }
        
    }

    const onReset = async () => {
        setTripDate(null)
    }

    const handleModalClose = () => {
        closeModal()
    }

    const header = (
        <h5 className="modal-title section-title" >Re Route</h5>
    )

    const footer = (
        <>
            <button className="btn btn-outline-secondary text-nowrap mr-5" type="button" onClick={()=>onReset()}>
                Reset
            </button>
            <button className="btn btn-outline-secondary theme-violet text-nowrap mr-5" type="button" 
                disabled={!tripDate} onClick={()=>onSubmit()}>
                Submit
            </button>
        </>
    )

    return (

        <>
            {
                !!modalConfig &&
                <ModalLayout
                    moadlConfig={modalConfig}
                    header={header}
                    footer={footer}
                    handleMoalClose={handleModalClose}
                >
                    <Container>
                        <Row>
                            <Col md="4" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label" title="">
                                    From
                                    <span className="text-danger">&#9733;</span>
                                </label>
                                <input type='text' className='form-control'
                                    defaultValue={rowData?.event_sbu}
                                    disabled={!!rowData?.event_sbu ? true : false}
                                />
                            </Col>
                            <Col md="4" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label" title="">
                                    To
                                    <span className="text-danger">&#9733;</span>
                                </label>
                                <input type='text' className='form-control'
                                    defaultValue={rowData?.dest_sbu}
                                    disabled={!!rowData?.dest_sbu ? true : false}
                                />
                            </Col>
                            <Col md="4" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label"
                                    title="This field is mandatory">Date
                                    <span className="text-danger">&#9733;</span>
                                </label>
                                <ReactDatePicker
                                    placeholderText="Select Trip Date"
                                    className="form-control"
                                    dateFormat={DATE_FORMAT_CLIENT}
                                    selected={!!tripDate ? new Date(tripDate) : tripDate}
                                    minDate={new Date()}
                                    onChange={(d) => setTripDate(d)}
                                />
                            </Col>
                        </Row>

                    </Container>
                </ModalLayout>
            }
        </>
    )
}

export default ProblemShipmentRerouteModal
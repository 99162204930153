import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import CharteredOrdersList from "./CharteredOrdersList";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import CharteredPlanning from "./CharteredPlanning";
import moment from "moment";
import {
  BLACK_SPCL_CHARS,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
} from "../Helper/Constants";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import ReactDatePicker from "react-datepicker";
import GetSvgIcon from "../../images/svglist";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import CharteredDeliveryEventLog from "./CharteredDeliveryEventLog";
import { deliveryEventlogChartered } from "../Helper/CommonMethods";

const initDropDownOpt = {
  drArr: [],
  vhclArr: [],
  sbuArr: [],
};

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initConfirmAlert = {
  size: "md",
  show: false,
  title: "",
  description: "",
};

const initCancelReason = {
  value: "",
  error: false,
};

const CharteredOrdersMain = () => {
  const [dropDownOpt, setDropDownOpt] = useState(
    Object.assign({}, initDropDownOpt)
  );

  const [showSpinner, setShowSpinner] = useState(false);
  const [showPlan, setShowPlan] = useState(false);
  const [charteredList, setCharteredList] = useState([]);
  const [selOrder, setSelOrder] = useState({});
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [searchType, setSearchType] = useState("Shipment");
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "months").format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment().add(1, "months").format(DATE_FORMAT_SERVER)
  );
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(
    Object.assign({}, initConfirmAlert)
  );
  const [cancelReason, setCancelReason] = useState(
    Object.assign({}),
    initCancelReason
  );
  const [cancelconfirmConfig, setCancelconfirmConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [deliveryEventLog, setDeliveryEventLog] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDropDownData();
    getCharteredList();
  }, []);

  const fetchPlan = async (argData) => {
    setShowSpinner(true);
    const resp = await CallApiGet(
      `GetCharteredPlanByOrderId?shipmentId=${argData?.orderId}`
    );
    if (resp.respCode === 200) {
      argData.itinArr = resp.respData;
      // setCharteredOrder({
      //   orderId: argData?.orderId,
      //   origin: argData?.origin,
      //   destination: argData?.destination,
      //   shipmentDate: argData?.shipmentDate,
      //   itinArr: resp.respData,
      // });
      setCharteredOrder({ ...argData });
      setShowPlan(true);
    } else {
      handleRespPostApiCall(resp);
    }
    setShowSpinner(false);
  };

  const handlePlanBtnClick = (data) => {
    fetchPlan(data);
  };
  const handleClose = () => {
    setShowPlan(false);
    setSelOrder({});
  };

  const fetchDropDownData = async () => {
    setShowSpinner(true);
    const commObj = Object.assign({}, initDropDownOpt);

    const payload = {
      argVehicle: 1,
      argDriverUser: 1,
    };

    const dropResp = await CallApiPost("GetDropdownOptions", payload);
    const sbuResp = await CallApiGet("getHubAndDepoList");
    if (dropResp.respCode === 200) {
      commObj.drArr = dropResp?.respData?.driverUserArr;
      commObj.vhclArr = dropResp?.respData?.vhclNoArr;
    }
    if (sbuResp.respCode === 200) {
      commObj.sbuArr = sbuResp?.respData?.map((item) => {
        return {
          label: item?.sbuCode + " (" + item?.sbuName + ")",
          value: item?.id,
        };
      });
    }
    setDropDownOpt(Object.assign(commObj));
    setShowSpinner(false);
  };
  const fetchCharteredOrders = async (payload) => {
    setShowSpinner(true);
    const uri = "getAllFtlOrders";
    let data = await CallApiPost(uri, payload);
    if (data.respCode === 200) {
      setCharteredList(data.respData);
    } else {
      console.error(data);
    }
    setShowSpinner(false);
  };

  const getCharteredList = async () => {
    const locToDate = moment()
      .clone()
      .add(1, "months")
      .format(DATE_FORMAT_SERVER);
    const locFromDate = moment()
      .clone()
      .subtract(1, "months")
      .format(DATE_FORMAT_SERVER);
    const payload = {
      searchType: searchType,
      custId: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType"))
        ? getUserInfo("acctId")
        : 0,
      toDate: locToDate,
      fromDate: locFromDate,
    };
    setFromDate(locFromDate);
    setToDate(locToDate);
    fetchCharteredOrders(payload);
  };

  const setCharteredOrder = (row) => {
    let tempData = { ...row };
    const isItinPresent =
      Array.isArray(tempData.itinArr) && tempData.itinArr.length > 0;
    if (!isItinPresent) {
      tempData.itinArr = [
        {
          origin: tempData?.origin,
          originId: 0,
          destId: 0,
          destination: tempData?.destination,
          vehicleId: 0,
          driver1: 0,
          driver2: 0,
          sta: "",
          std: "",
        },
      ];
    }

    setSelOrder({ ...tempData });
  };

  const handleRespPostApiCall = (resp) => {
    switch (resp.respCode) {
      case 200:
        showAlert({
          isSuccess: true,
          title: "SUCCESS",
          description: resp?.respMessage,
        });
        break;
      case 99:
        showAlert({
          isSuccess: false,
          title: "NETWORK ERROR",
          description: (
            <>
              We're having trouble connecting to the server. <br /> Please check
              your internet connection and try again.
            </>
          ),
        });
        break;
      case 500:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description:
            "Oops! Something went wrong while processing your request.",
        });
        break;
      default:
        showAlert({
          isSuccess: false,
          title: "ERROR",
          description: resp?.respMessage,
        });
        break;
    }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: false,
      size: "md",
      show: false,
      title: "",
      description: "",
    });
    getCharteredList();
  };
  const hideCancelConfirmAlert = () => {
    setCancelconfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    setCancelReason(Object.assign({}, initCancelReason));
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };
  const onSumit = () => {
    const payload = {
      searchType: searchType,
      fromDate: moment(fromDate).format(DATE_FORMAT_SERVER),
      toDate: moment(toDate).format(DATE_FORMAT_SERVER),
      custId: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType"))
        ? getUserInfo("acctId")
        : 0,
    };
    fetchCharteredOrders(payload);
  };

  const openCharteredDetails = (type, data) => {
    const url = `/charteredOrderDetails?data=${data.orderId}`;
    window.open(url, "_blank");
  };
  const downloadDO = async (data) => {
    setShowSpinner(true);
    document.body.click();
    const uri = "DownloadDO/" + data?.orderId;
    const resp = await CallApiGet(uri);
    if (resp?.respCode === 200 && !!resp?.respData) {
      window.open(resp.respData, "_blank");
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to download. Please try after sometime!!";
      }
      handleRespPostApiCall(resp);
    }
    setShowSpinner(false);
  };
  const downloadReceiveQR = async (orderId, qrCode, stopSeq) => {
    setShowSpinner(true);
    document.body.click();
    const uri = `DownloadReceiveQr/${orderId}/${stopSeq}?qrCode=${qrCode}`;
    const resp = await CallApiGet(uri);
    if (resp?.respCode === 200 && !!resp?.respData) {
      window.open(resp.respData, "_blank");
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to download. Please try after sometime!!";
      }
      handleRespPostApiCall(resp);
    }
    setShowSpinner(false);
  };

  const openConfirmAlert = (data) => {
    setSelOrder(Object.assign({}, data));
    setConfirmAlertConfig({
      show: true,
      size: "md",
      title: "CONFIRM WEIGHT",
      description:
        "Are you sure want to confirm the weight " + data?.serviceValue + " kg",
    });
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig(Object.assign({}, initConfirmAlert));
  };

  const confirmAlert = async () => {
    setShowSpinner(true);
    const uri = `ConfirmWeight?orderId=${selOrder?.orderId}`;
    const resp = await CallApiPost(uri);
    hideConfirmAlert();
    handleRespPostApiCall(resp);
    setShowSpinner(false);
  };
  const isDataValid = (argValue) => {
    if (!!argValue && argValue?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const cancelShipment = async () => {
    if (!isDataValid(cancelReason.value)) {
      setCancelReason({
        ...cancelReason,
        error: true,
      });
      return;
    }
    setShowSpinner(true);
    const payload = {
      shipmentId: selOrder?.orderId,
      remarks: cancelReason.value,
    };
    const resp = await CallApiPost("CancelChBooking", payload);
    handleRespPostApiCall(resp);
    hideCancelConfirmAlert();
    setShowSpinner(false);
  };
  const handleCxlShipment = (row) => {
    setSelOrder(Object.assign({}, row));
    setCancelconfirmConfig({
      isSuccess: true,
      size: "md",
      show: true,
      title: "Cancel Shipment",
      description: "",
    });
  };

  const handleOnChange = (event) => {
    const { value } = event.target;
    let copyCancelReason = Object.assign({}, cancelReason);
    if (!BLACK_SPCL_CHARS.test(value)) {
      copyCancelReason.error = false;
      copyCancelReason.value = value;
      setCancelReason(copyCancelReason);
    }
  };

  const getDeliveryEventLog = async (orderId) => {
    document.body.click();
    setShowSpinner(true);
    let deliveryEventLogData = await deliveryEventlogChartered(orderId);
    setShowSpinner(false);
    if (deliveryEventLogData.respCode === 200) {
      let data = deliveryEventLogData.respData;
      setDeliveryEventLog(data);
    } else if (deliveryEventLogData.respCode === 500) {
      showAlert({
        isSuccess: false,
        title: "",
        description: deliveryEventLogData.respMessage,
      });
    } else {
      setDeliveryEventLog([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  return (
    <div>
      <Layout
        spinnershow={showSpinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
        confirmAlertConfig={confirmAlertConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={confirmAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">Chartered Orders</div>
          </div>
        </div>
        <section>
          <div className="py-3 m-1 d-flex justify-content-between">
            <div className="d-flex col-auto">
              <div className="col-auto d-flex align-item-center border rounded p-2 me-3 wt-tbl">
                <div className="col-auto align-self-center me-3">
                  <input
                    className="form-check-input formsty"
                    type="radio"
                    name="filterType"
                    id="inlineRadio1"
                    value="Shipment"
                    checked={searchType === "Shipment" ? true : false}
                    onChange={() => setSearchType("Shipment")}
                  />
                  <label
                    className="form-check-label fw-bold ms-2"
                    htmlFor="inlineRadio1"
                  >
                    Shipment
                  </label>
                </div>
                <div className="col-auto align-self-center me-3">
                  <input
                    className="form-check-input formsty"
                    type="radio"
                    name="filterType"
                    id="inlineRadio2"
                    value="Booking"
                    checked={searchType === "Booking" ? true : false}
                    onChange={() => setSearchType("Booking")}
                  />
                  <label
                    className="form-check-label fw-bold ms-2"
                    htmlFor="inlineRadio2"
                  >
                    Booking
                  </label>
                </div>
                <div className="col-auto align-self-center me-3">
                  <label
                    htmlFor=""
                    className="form-label theme-label me-2"
                    title="This field is mandatory"
                  >
                    From:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!fromDate ? new Date(fromDate) : fromDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setFromDate(d)}
                    onSelect={(d) => setFromDate(d)}
                    maxDate={!!toDate ? new Date(toDate) : null}
                  />
                </div>
                <div className="col-auto align-self-center">
                  <label
                    htmlFor=""
                    className="form-label theme-label me-2"
                    title="This field is mandatory"
                  >
                    To:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!toDate ? new Date(toDate) : toDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setToDate(d)}
                    onSelect={(d) => setToDate(d)}
                    minDate={!!fromDate ? new Date(fromDate) : null}
                  />
                </div>
              </div>
              <div className="align-self-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet text-nowrap"
                  onClick={onSumit}
                  disabled={!(!!fromDate && toDate)}
                >
                  {GetSvgIcon("Search")}
                </button>
              </div>
            </div>
          </div>
        </section>
        <CharteredDeliveryEventLog data={deliveryEventLog} />
        <CharteredOrdersList
          handlePlanBtnClick={handlePlanBtnClick}
          charteredList={charteredList}
          openCharteredDetails={openCharteredDetails}
          openConfirmAlert={openConfirmAlert}
          downloadDO={downloadDO}
          downloadReceiveQR={downloadReceiveQR}
          handleCxlShipment={handleCxlShipment}
          getDeliveryEventLog={getDeliveryEventLog}
        />
        <CharteredPlanning
          show={showPlan}
          hide={handleClose}
          dropDownOpts={dropDownOpt}
          selOrder={selOrder}
          handleRespPostApiCall={handleRespPostApiCall}
          setShowSpinner={setShowSpinner}
        />
        <ModalConfirmAlert
          confirmAlertConfig={cancelconfirmConfig}
          hideConfirmAlert={hideCancelConfirmAlert}
          confirmAlert={cancelShipment}
          cancelBtnTxt="Not now"
          confirmBtnTxt="Cancel order"
        >
          <div className="row">
            <div className="col-md-3">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Reason <span className="text-danger">&#9733;</span>
              </label>
            </div>
            <div className="col-md-9 mb-2">
              <input
                id="cancellation-reason"
                type="text"
                className="form-control"
                name="cancellationReason"
                value={cancelReason.value}
                autoComplete="false"
                onChange={handleOnChange}
              />
              {cancelReason.error && (
                <div style={{ textAlign: "left" }}>
                  <span className="badge bg-danger">
                    &#9888; This field is required.
                  </span>
                </div>
              )}
            </div>
          </div>
        </ModalConfirmAlert>
      </Layout>
    </div>
  );
};

export default CharteredOrdersMain;

import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { getFileName, toBase64 } from "../Helper/CommonMethods";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_CLIENT,
  EMAIL,
  PATTERN_ERR_MSG,
  PHONE_NUM,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import { DownloadFile, GetBase64Img } from "../Helper/FileStoreService";
import { CallApiDelete, CallApiPost } from "../Helper/serviceApi";
import CustomAlert from "../Layout/CustomAlert/CustomAlert";
import Spinner from "../Layout/Spinner";
import { LINEHAUL_STAFF_USER_TYPE, LINEHAUL_ROLE_DRIVER } from "../../ConfigConstants/ConfigConstants";

const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
  photoFileName: "",
};

export default function AddUser(props) {
  const {
    userTypeList,
    sbuTypeDtlsList,
    sbuList,
    roleDtlsList,
    countryCodes,
    userInfoList,
    stateList,
    cityList,
    stateCityData,
    onSuccess,
    onFailure,
    onError,
    userMgm,
    userDetailsData,
    sbuDropdownList,
  } = props;
  const navigate = useNavigate();
  const cancelBtn = useRef();
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();

  const [show, setShow] = useState(false);
  const [isDisabled, setDisabled] = useState({});
  const [photoImg, setPhotoImg] = useState();
  const [photoImgErrMsg, setPhotoImgErrMsg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [imageData, setImageData] = useState(initPhotoFile);
  const [cityListData, setCityListData] = useState();
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());
  const [showAlert, setShowAlert] = useState(false);
  const [cityListBack, setCityListBack] = useState();
  const [isdeactive, setDiactive] = useState(false)
  const [userSbu, setUserSbu] = useState(0);
  const [isDriver, setIsDriver] = useState(false);
  const [sbuDtls, setSbuDtls] = useState([
    {
      id: 0,
      code: "",
    },
  ]);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    setError,
    getValues,
    control,
    formState: { errors },
  } = useForm({ defaultValues: userMgm });

  useEffect(() => {
    setUserSbu(userMgm.sbuId);
    setIsDriver(userMgm.userRoleId && userMgm.userRoleId.length > 0 && userMgm.userRoleId.includes(LINEHAUL_ROLE_DRIVER));
    getSbuListBySbuType(userMgm.sbuTypeId)
    setValue("sbuTypeId", userMgm.sbuTypeId);
    setCityListData(cityList);
    setCityListBack(cityList)
    // setValue("secondarySbuIds",[81,72])
    reset(userMgm);
    if (userMgm.active === true) {
      setDiactive(true)
    } else {
      setDiactive(false)
    }
    setSubmitStatusFail(false);
  }, [userMgm]);

  useEffect(() => { 
    setUserSbu(userMgm.sbuId);
    setIsDriver(userMgm.userRoleId && userMgm.userRoleId.length > 0 && userMgm.userRoleId.includes(LINEHAUL_ROLE_DRIVER));
    getSbuListBySbuType(userMgm.sbuTypeId);
    setValue("sbuTypeId", userMgm.sbuTypeId);
    setValue("sbuId", userMgm.sbuId);
    setValue("userDetails", userDetailsData);


    if (userMgm.profilePhoto && userMgm.profilePhoto.photoBucketUrl !== "") {
        setImageData(userMgm.profilePhoto.photoBucketUrl);
    } else {
      setImageData("");
    }
    if (userMgm.phone !== "") {
      setValue("phone", userMgm.phone.substr(3));
      setValue("phoneCode", userMgm.phone.substr(0, 3));
    }
    setSubmitStatusFail(false);
  }, [userDetailsData]);

  const { fields: userDetails } = useFieldArray({
    control,
    name: "userDetails",
  });
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    setDisabled(state => ({
      ...state,
      [key]: true
    }))
    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const onSubmit = async (data) => {
    setShow(true);
    if (data.phone !== "") {
      data.phone = data.phoneCode + data.phone;
    }
    delete data.phoneCode;
    data.userTypeId = LINEHAUL_STAFF_USER_TYPE;
    data.oldSbuId = userSbu;
    data.isDriver = isDriver;
    const uri = "saveStaffUser";
    let resp = await CallApiPost(uri, data);
    setShow(false);
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess(resp.respMessage);
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setSubmitStatusFail(true);
      setFailMsg("Sorry, you don't have the write permission!!");
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else if (resp.respCode === 409) {
      setSubmitStatusFail(true);
      setFailMsg(resp.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else {
      onError(resp.respMessage);
    }
  };

  const formErrors = (err) => {
    console.log(err);
  };

  const clearData = () => {
    setValue("userDetails", userInfoList);
    reset();
    clearErrors();
    setPhotoImg("");
    setImageData(initPhotoFile);

    // cancelBtn.current.click();
    //fetchAllStaffUserList()
  };
  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      clearErrors("profilePhoto");
      setPhotoImgErrMsg();
    }
  };

  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCityData.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });

      setCityListData(cityArrList);
    } else {
      setValue("city", '')
      setCityListData(cityListBack);

    }
  };
  const filterState = (city) => {
    if (city) {
      let flt = stateCityData.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    }
  };
  const download = (fileName) => {
    window.open(fileName, "_blank");
  };
  const toggleAlert = (boolData) => {

    setShowAlert(boolData);
  };

  const changeActiveStatus = async () => {
    const userStatus = userMgm.active === true ? false : true;
    const uri =
      "deavticeStaffUser?userId=" + userMgm.id + "&status=" + userStatus + "&isDriver=" + isDriver;
    let resp = await CallApiDelete(uri);
    // clearData()

    setShowAlert(false);
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess(resp.respMessage);
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setSubmitStatusFail(true);
      setFailMsg("Sorry, you don't have the write permission!!");
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else if (resp.respCode === 409) {
      setSubmitStatusFail(true);
      setFailMsg(resp.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else {
      onError(resp.respMessage);
    }
  };
  const getSbuListBySbuType = (id) => {
    let sbuArr = [];
    if (!!id) {
      setValue("sbuId", "");
      let subDataList = sbuList.filter((a) => {
        return a.sbuType === id;
      });
      subDataList.forEach((element) => {
        sbuArr.push({
          value: element.id,
          label: element.code,
        });
      });

      setSbuDtls(sbuArr);
    } else {
      setValue("sbuId", "");
    }
  };
  return (
    <div
      className="modal fade"
      id="edit-modal-user"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          {submitStatusFail && (
            <div id="errorMsg" className="alert alert-danger" role="alert">
              {" "}
              {JSON.parse(failMsg).userName} <br />
              {JSON.parse(failMsg).code} <br />
              {JSON.parse(failMsg).email} <br />
              {JSON.parse(failMsg).trip}{" "}
            </div>
          )}
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearData}
            ></button>
          </div>
          <div className="modal-body modal-scroll overflow-auto">
            <div className="col-md-12 card card-body">


              <div className="w-100">
                <h5
                  className="modal-title section-title"
                  id="exampleModalLabel"
                >
                  User Information
                </h5>
                <div className="row">
                  <div className="col-md-4">
                    <div 
                      className="mb-1 file-placeholder thumbnail-pic"
                      {...register("profilePhoto")}
                    >
                      {isdeactive &&
                        <input
                          type="file"
                          accept="image/png, image/jpeg "
                          onChange={selectPhotoFile}
                          key={fileInputDummyKey}
                        />
                      }
                      {photoImg ? (
                        <img
                          // className="file-placeholder"
                          src={URL.createObjectURL(photoImg)}
                          alt="img"
                        />
                      ) : imageData !== "" ? (
                        <img
                          src={
                            imageData !== ""
                              ?  imageData
                              : ""
                          }
                          alt="img"
                        />
                      ) : (
                        <div>
                          {GetSvgUpload({ required: false })}
                          Upload Photo{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      User Type<span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="userTypeId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select

                          options={userTypeList}
                          placeholder="Select User Type"
                          value={
                            value
                              ? userTypeList.find(
                                (opt) => opt.value === parseInt(value)
                              )
                              : value
                          }
                          onChange={(d) => onChange(d ? d.value : d)}
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                          isDisabled={true } 
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.userTypeId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!!
                        </span>
                      </div>
                    )}
                  </div> */}
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      User Name<span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User Name"
                      aria-label="input example"
                      {...register("userName", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="200"
                      disabled={!isdeactive}
                    />
                    {errors.userName && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.userName.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Staff Id/Account No.
                      <span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Staff Id /Account No"
                      aria-label="input example"
                      {...register("accountId", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="20"
                      disabled={!isdeactive}
                    />
                    {errors.accountId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.accountId.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Phone<span className="text-danger">&#9733;</span>
                    </label>
                    <div className="input-group">
                      <select
                        name="countryCode"
                        {...register("phoneCode")}
                        defaultValue="+60"
                        className="form-control country-code-customize"
                      >
                        {countryCodes.map((cc, index) => (
                          <option key={index} value={cc.value}>
                            {cc.label}
                          </option>
                        ))}
                      </select>

                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone"
                        aria-label="input example"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: PHONE_NUM,
                            message: PATTERN_ERR_MSG,
                          },
                        })}
                        maxLength="11"
                        disabled={!isdeactive}
                      />
                    </div>
                    {errors.countryCode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.countryCode.message}
                        </span>
                      </div>
                    )}
                    {errors.phone && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.phone.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      User IC
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User IC"
                      aria-label="input example"
                      {...register("userIdCard", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="50"
                      disabled={!isdeactive}
                    />
                    {errors.userIdCard && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.userIdCard.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Email<span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      aria-label="input example"
                      {...register("email", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: EMAIL,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="200"
                      disabled={!isdeactive}
                    />
                    {errors.email && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.email.message}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Role<span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="userRoleId"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={roleDtlsList}
                          placeholder="Select Role"
                          value={roleDtlsList.filter((opt) =>
                            value?.includes(parseInt(opt.value))
                          )}
                          onChange={(d) =>
                            onChange(d ? d.map((opt) => opt.value) : [])
                          }
                          isSearchable={true}
                          isClearable={true}
                          isMulti={true}
                          className="form-control  p-0"
                          isDisabled={!isdeactive}
                        />
                      )}
                    />
                    {errors.userRoleId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Primary SBU Type<span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="sbuTypeId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={sbuTypeDtlsList}
                          placeholder="Select SBU Type"
                          // value={value ? value.value : value} 
                          value={
                            value
                              ? sbuTypeDtlsList.find((opt) => opt.value === parseInt(value))
                              : value
                          }
                          onChange={(d) => {
                            onChange(d ? d.value : d);
                            getSbuListBySbuType(d ? d.value : d);
                          }}
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.sbuTypeId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Primary SBU <span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="sbuId"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={sbuDtls}
                          placeholder="Select SBU"
                          value={
                            value
                              ? sbuDtls.find(
                                (opt) => opt.value === parseInt(value)
                              )
                              : value
                          }
                          onChange={(d) => onChange(d ? d.value : d)}
                          isSearchable={true}
                          isClearable={true}
                          className="form-control  p-0"
                          isDisabled={!isdeactive}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.sbuId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-10 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Secondary SBU(Multi Select)
                    </label>
                    <Controller
                      name="secondarySbuIds"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={sbuDropdownList}
                          value={sbuDropdownList.filter((opt) =>
                            value?.includes(opt.value)
                          )}
                          onChange={(d) =>
                            onChange(d ? d.map((opt) => opt.value) : [])
                          }
                          isSearchable={true}
                          isClearable={true}
                          isMulti={true}
                          className="form-control  p-0"
                        />
                      )}
                      rules={{ required: false }}
                    />
                    {errors.sbuId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This Field is required!!!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      aria-label="input example"
                      {...register("address", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="50"
                      disabled={!isdeactive}
                    />
                    {errors.address && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.address.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      State
                    </label>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={stateList}
                          placeholder="Select State"
                          value={
                            value
                              ? stateList.filter((opt) =>
                                value.includes(opt.value)
                              )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterCity(d ? d.value : d)
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          isDisabled={!isdeactive}
                          className="form-control  p-0"
                        />
                      )}
                    />
                    {errors.state && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.state.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      City
                    </label>
                    {/* <Controller
                      name="city"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={cityListData}
                          placeholder="Select City"
                          value={
                            value
                              ? cityListData.filter((opt) =>
                                value.includes(opt.value)
                              )
                              : value
                          }
                          onChange={(d) =>
                            onChange(
                              d ? d.value : d,
                              filterState(d ? d.value : d)
                            )
                          }
                          isSearchable={true}
                          isClearable={true}
                          isDisabled={!isdeactive}
                          className="form-control  p-0"
                        />
                      )}
                    /> */}
                    <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="City"
                          aria-label="Name"
                          aria-describedby="basic-addon1"
                          {...register("city", {
                            maxLength: {
                              value: 40,
                              message: "Exceeded max limit!!!",
                            },
                          })}
                          maxLength="40"
                        />
                    {errors.city && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.city.message}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="col-md-2 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Post Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="postCode"
                      disabled={!isdeactive}
                      {...register("postCode", {
                        required: false,
                      },
                        {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG
                        })}
                    />
                    {errors.postCode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.postCode.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <h5
                  className="modal-title section-title m-auto"
                  id="exampleModalLabel"
                >
                  User Details
                </h5>
                {/* {JSON.stringify(userDetails)} */}
                <div className="row">
                  <div className="col-lg-12 mb-2">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Information</th>
                            <th scope="col">
                              Doc Number{" "}
                              {/* <span className="text-danger">&#9733;</span>{" "} */}
                            </th>
                            <th scope="col">
                              Expires On{" "}
                              {/* <span className="text-danger">&#9733;</span> */}
                            </th>
                            <th scope="col">Picture</th>
                            <th scope="col">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {userDetails.map((item, index) => (
                              <>
                                <tr key={item.id}>
                                  <td>
                                    {item.documentType}
                                    <input
                                      type="hidden"
                                      value={item.infoId}
                                      {...register(
                                        `userDetails.${index}.infoId`
                                      )}
                                    />{" "}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      placeholder="Doc Number"
                                      className="form-control"
                                      {...register(
                                        `userDetails.${index}.docNumber`,
                                        {
                                          // required: {
                                          //   value: true,
                                          //   message: REQUIRED_ERR_MSG,
                                          // },
                                          pattern: {
                                            value: ALPHANUM_SPACE_SPCL_CHAR,
                                            message: PATTERN_ERR_MSG,
                                          },
                                        }
                                      )}
                                      maxLength="250"
                                      disabled={!isdeactive}
                                    />
                                    {errors.userDetails &&
                                      errors.userDetails[index].docNumber && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888; This field is mandatory
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <Controller
                                      control={control}
                                      name={`userDetails[${index}].expiresOn`}
                                      // rules={{
                                      //   required: {
                                      //     value: true,
                                      //     message: REQUIRED_ERR_MSG,
                                      //   },
                                      // }}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <ReactDatePicker
                                            placeholderText="Expiry Date"
                                            className="form-control"
                                            dateFormat={DATE_FORMAT_CLIENT}
                                            selected={
                                              value ? new Date(value) : value
                                            }
                                            minDate={new Date()}
                                            onChange={(d) =>
                                              onChange(
                                                d !== null
                                                  ? moment(d).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                  : ""
                                              )
                                            }
                                          />
                                        );
                                      }}
                                      disabled={isDisabled}
                                    />
                                    {errors.userDetails &&
                                      errors.userDetails[index].expiresOn && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888; This field is mandatory
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <div
                                      className="Upload-Photo w-100 mb-2"
                                      {...register(
                                        `userDetails.${index}.infoAttachment`
                                      )}
                                    >
                                      <input
                                        type="file"
                                        onChange={(e) =>
                                          selectFile(
                                            `userDetails.${index}.infoAttachment`,
                                            e
                                          )
                                        }
                                      />
                                      <div className="uploaded-text ms-2">
                                        {GetSvgUpload({ required: false })}
                                        {watch(
                                          `userDetails.${index}.infoAttachment.photoBucketUrl`
                                        ) === null ||
                                          watch(
                                            `userDetails.${index}.infoAttachment.photoBucketUrl`
                                          ) === "" ||
                                          watch(
                                            `userDetails.${index}.infoAttachment.photoBucketUrl`
                                          ) === "null"
                                          ? "Upload Image"
                                          : watch(
                                            `userDetails.${index}.infoAttachment.photoFileName`
                                          )}
                                      </div>
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                        disabled={
                                          !(watch(`userDetails.${index}.infoAttachment.photo`) === ""
                                            && watch(`userDetails.${index}.infoAttachment.photoFileName`) !== "")

                                        }
                                        onClick={() =>
                                          download( 
                                            watch(`userDetails.${index}.infoAttachment.photoBucketUrl`)
                                            //item.infoAttachment.photoFileName.photoBucketUrl
                                            // getFileName(
                                            //   watch(
                                            //     `userDetails.${index}.infoAttachment.photoBucketUrl`
                                            //   )
                                            // )
                                          )
                                        }
                                      >
                                        {GetSvgIcon("IconFiledownload")}
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `userDetails.${index}.remarks`,
                                        {
                                          pattern: {
                                            value: ALPHANUM_SPACE_SPCL_CHAR,
                                            message: PATTERN_ERR_MSG,
                                          },
                                        }
                                      )}
                                      maxLength="20"
                                      disabled={!isdeactive}
                                    />
                                  </td>
                                </tr>
                              </>
                            ))}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isdeactive &&
            <div className="modal-footer">
              <button
                type="button"
                className={
                  userMgm.active === true ? "btn  btn-danger" : "btn  disabled"
                }
                onClick={() => toggleAlert(true)}
              >
                {userMgm.active === true ? "Deactive" : "Deactivated"}

              </button>
              <button
                type="reset"
                className="btn btn-default border"
                onClick={clearData}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                // data-bs-dismiss="modal"
                onClick={handleSubmit(onSubmit, formErrors)}
              >
                Save
              </button>
            </div>
          }
        </div>
      </div>
      <Spinner show={show} />
      {
        showAlert && (
          <CustomAlert
            toggleReset={toggleAlert}
            alertTitle="Action On User"
            alertBody={
              <p>
                Are you sure, you want to{" "}
                {userMgm.active === true ? "deactive" : "active"} the user{" "}
                <b>{userMgm.userName}</b>
              </p>
            }
            callback={changeActiveStatus}
          />
        )
      }
    </div >
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import GetSvgIcon from "../../images/svglist";
import { SERVER_ERR_MSG } from "../Helper/Constants";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import Layout from "../Layout/Layout";
import RateAddEdit from "./RateAddEdit";
import RateList from "./RateList";
import UploadInboundRate from "./UploadInboundRate";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initDataModal = {
  show: false,
  title: "",
};

const initDataObj = {
  rateId: 0,
  custId: 0,
  maxDrop: 0,
  maxDropCharge: 0,
  chargePerDropAboveMax: 0,
  rateTypeId: 0,
  threshold: 0,
  unitPrice: 0,
  remarks: "",
  subRates: "[]",
};

const RateMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [inbRateList, setInbRateList] = useState([]);
  const [srvcDropdown, setSrvcDropdown] = useState([]);
  const [dataModal, setDataModal] = useState(Object.assign({}, initDataModal));
  const [customerList, setCustomerList] = useState([]);
  const [rateTypeList, setRateTypeList] = useState([]);
  const [thresholdTypeList, setThresholdTypeList] = useState([]);
  const [selRate, setSelRate] = useState(Object.assign({}, initDataObj));
  const [operationType, setOperationType] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    reloadInboundRates();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setAlertConfig({
      isSuccess: boolData,
      size: "md",
      show: true,
      title: popupTitle,
      description: popupDesc,
    });
  };

  const fetchAllInboundRates = async () => {
    const resp = await CallApiGet("GetInbOrdRate");
    updateRateList(resp);
  };

  const fetchSrvcDropdown = async () => {
    const resp = await CallApiGet("GetSrvcDropdown");
    updateSrvcDropdown(resp);
  };

  const reloadInboundRates = async () => {
    setSpinner(true);
    await fetchAllInboundRates();
    setSpinner(false);
  };

  const copyData = (array1, array2) => {
    array2.forEach((item2) => {
      let existingItemIndex = array1.findIndex(
        (item1) =>
          item1.serviceId === item2.serviceId &&
          item1.serviceCode === item2.serviceCode
      );
      if (existingItemIndex !== -1) {
        item2.index = array1[existingItemIndex].index;
        // array1[existingItemIndex] = item2;
        array1.splice(existingItemIndex, 1, item2);
      }
    });
  };

  const handleBtnClick = (argData, argTitle, argSaveType) => {
    setOperationType(argSaveType);
    let locData = Object.assign({}, argData);
    let locSrvcDropdown = [...srvcDropdown];
    locData.subRates =
      !!locData?.subRates && typeof locData?.subRates === "string"
        ? JSON.parse(locData?.subRates)
        : [];

    if (locData.subRates?.length > 0) {
      copyData(locSrvcDropdown, locData.subRates);
    }
    locData.subRates = locSrvcDropdown;
    setSelRate(Object.assign({}, locData));
    setDataModal({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setDataModal({
      show: false,
      title: "",
    });
  };
  const updateRateList = (resp) => {
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setInbRateList(resp?.respData);
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 404;
        resp.respMessage =
          "Opps! failed to load data due to some error. Please try after sometime!!";
      }
      handlePostApiCall(resp, "SUCCESS");
    }
  };

  const updateSrvcDropdown = (resp) => {
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setSrvcDropdown(resp?.respData);
    } else {
      console.error(
        "API GetSrvcDropdown FIALED with error: " + resp?.respMessage
      );
    }
  };

  const handlePostApiCall = (resp, sucMsg) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", sucMsg);
        break;
      case 500:
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the write permission!!"
        );
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after soemtime!!"
        );
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const getDropdownData = async () => {
    setSpinner(true);
    const dropdownModel = {
      argAllCust: 1,
      argAllRateType: 1,
      argThresholdType: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      const respData = data.respData;
      setCustomerList(respData.allCustArr);
      setRateTypeList(respData.rateTypeArr);
      setThresholdTypeList(respData.thresholdTypeArr);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const loadData = async () => {
    setSpinner(true);
    Promise.all([
      fetchAllInboundRates(),
      fetchSrvcDropdown(),
      getDropdownData(),
    ])
      .then(() => {
        setSpinner(false);
      })
      .catch((err) => {
        console.error(err);
        setSpinner(false);
      });
  };

  const getThresholdType = (argRateType) => {
    switch (argRateType) {
      case 750:
        return 761;
      case 751:
        return 760;
      case 752:
        return 760;
      default:
        return 0;
    }
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    data.thresholdType = getThresholdType(data.rateTypeId);
    if (Array.isArray(data.subRates)) {
      data.subRates = data.subRates.map((rt) => ({
        ...rt,
        custId: data.custId,
      }));
    }
    const payload = {
      operationType: operationType,
      baseRates: [data],
      targetRates: data.subRates,
    };
    const resp = await CallApiPost("SaveInbOrdRate", payload);
    handleCLose();
    handlePostApiCall(resp, "Inbound rate processed successfully!!");
    setSpinner(false);
  };

  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={hideAlert}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">INBOUND ORDER RATE CONFIG</div>
        </div>
        <div className="float-end">
          <button
            className="btn btn-outline-secondary theme-violet text-nowrap me-2"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#upload-file-modal"
          >
            Upload
          </button>
          <Button
            className="theme-violet"
            onClick={() => handleBtnClick(initDataObj, "ADD RATE", 0)}
          >
            {GetSvgIcon("PlusCircle")} Add Rate
          </Button>
        </div>
      </div>
      <RateList tableData={inbRateList} handleBtnClick={handleBtnClick} />
      <RateAddEdit
        config={dataModal}
        onHide={handleCLose}
        srvcDropdown={srvcDropdown}
        dropdownData={customerList}
        rateTypeList={rateTypeList}
        thresholdTypeList={thresholdTypeList}
        selRate={selRate}
        onSubmit={onSubmit}
      />
      <UploadInboundRate
        handlePostApiCall={handlePostApiCall}
        rateTypeList={rateTypeList}
      />
    </Layout>
  );
};

export default RateMain;

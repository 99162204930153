import React from "react";
import GetSvgIcon from "../../../images/svglist";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const RegistrationWidget = (props) => {
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Last Three Months - Registrations',
            },
        },
    };

    return (
        <>
            <div className="card mb-4 shadow">
                <div className="card-header h6">
                    <span className="float-start">Registrations (Last Three Months)</span>
                    {/* <span className="float-end">{GetSvgIcon('FullscreenWindow')}</span> */}
                </div>
                <div className="card-body">
                    <div className="chart-bar"><Bar options={options} data={props?.data} /></div>
                </div>
            </div>
        </>
    )
}

export default RegistrationWidget;
import React from 'react'
import DataTable from 'react-data-table-component-with-filter'
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../../../Helper/Constants'

const IsrApprovalList = (props) => {
  return (

    <>
      <div className="col-md-12 mt-3 customized-data-table position-relative">
        <DataTable
          columns={props.columns}
          data={props.data}

          pagination
          // paginationServer
          // paginationTotalRows={props.totalRows}
          onChangeRowsPerPage={props.handlePerPageChange}
          onChangePage={props.handlePageClick}
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
          customStyles={customDataTableStyles}
          //defaultSortFieldId={6}
          defaultSortAsc={false}
        />
      </div>
    </>
  )
}

export default IsrApprovalList
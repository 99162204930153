import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  NUMBER_TWO_DECIMAL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  customReactSelectMenuStyle,
} from "../Helper/Constants";
import Select from "react-select";
import { initValFuelLog } from "./initValFuelLog";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { getFileName, toBase64 } from "../Helper/CommonMethods";
import { DownloadFile } from "../Helper/FileStoreService";
import { CallApiPost } from "../Helper/serviceApi";

export default function VehicleFuelLogAddEdit(props) {
  const { vehicleList, driverList, setFuelLog, fuelLog, onFailure, onSuccess } =
    props;
  const [pageType, setPageType] = useState(false);
  const [show, setShow] = useState(false);
  const [serverPic, setServerPic] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fuelLog.id !==0 ? setPageType(true) : setPageType(false)

    reset(fuelLog);
    if (typeof fuelLog?.infoAttachment === "string") {
      const attch = JSON.parse(fuelLog?.infoAttachment);
      setValue("infoAttachment", attch);
      attch.photoFileName === null ? setServerPic(false) : setServerPic(true);
    }
  }, [fuelLog]);

  const clearData = () => {
    reset(JSON.parse(JSON.stringify(initValFuelLog)));
    setFuelLog(JSON.parse(JSON.stringify(initValFuelLog)));
    setServerPic(true);
  };

  const handelOnSubmit = async (data) => {
    setShow(true);
    const uri = "saveVehicleFuelLog";
    let resp = await CallApiPost(uri, data);
    setShow(false);
    clearData();
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess(resp.respMessage);
    } else {
      onFailure(resp.respMessage);
    }
  };
  const err = (errors) => {
    console.log(errors);
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;
    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(key, newAttachment);
    clearErrors(key);
  };
  const download = (fileName) => {
    if (fileName !== "") {
      setShow(true);
      DownloadFile(fileName, sucs, sucs);
      setShow(false);
    }
  };
  const sucs = () => {};

  return (
    <div>
      <div
        className="modal fade"
        id="button-modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title section-title" id="exampleModalLabel">
                Fuel Log
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={clearData}
              ></button>
            </div>
            <div className="modal-body modal-scroll overflow-auto">
              <div className="row">
                <div className="col-lg-6 mb-2">
                  <label
                    for=""
                    className="form-label theme-label"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="This field is mandatory"
                  >
                    Vehicle <span className="text-danger">&#9733;</span>
                  </label>
                  <Controller
                    name="vehicleId"
                    control={control}
                    rules={{
                      required: { value: true, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={vehicleList}
                        placeholder="Select Vehicle"
                        value={
                          value
                            ? vehicleList.find((opt) => opt.value === value)
                            : value
                        }
                        onChange={(d) => onChange(d ? d.value : d)}
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors.vehicleId && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.vehicleId.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 mb-2">
                  <label
                    for=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Filled By <span className="text-danger">&#9733;</span>
                  </label>
                  <Controller
                    name="driverId"
                    control={control}
                    rules={{
                      required: { value: true, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={driverList}
                        placeholder="Select Filled By"
                        value={
                          value
                            ? driverList.find((opt) => opt.value === value)
                            : value
                        }
                        onChange={(d) => onChange(d ? d.value : d)}
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors.driverId && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.driverId.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 mb-2">
                  <label
                    for=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Odo Meter <span className="text-danger">&#9733;</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("odoMeter", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_TWO_DECIMAL,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                  />
                  {errors.odoMeter && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.odoMeter.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 mb-2">
                  <label
                    for=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Amount <span className="text-danger">&#9733;</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("amount", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_TWO_DECIMAL,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                  />
                  {errors.amount && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.amount.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-lg-12 mb-2">
                  <label
                    for=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Attachment 
                  </label>

                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("infoAttachment")}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile(`infoAttachment`, e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {watch("infoAttachment")?.photoFileName === null
                        ? "Upload Image"
                        : watch("infoAttachment")?.photoFileName}
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file end-0 top-0"
                      disabled={!serverPic}
                      onClick={() =>
                        download(
                          getFileName(watch("infoAttachment")?.photoFileName)
                        )
                      }
                    >
                      {GetSvgIcon("IconFiledownload")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {pageType ? (
                <button
                  type="button"
                  className="btn btn-default border"
                  data-bs-dismiss="modal"
                  onClick={clearData}
                >
                  Cancel
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-default border"
                  //data-bs-dismiss="modal"
                  onClick={clearData}
                >
                  Clear
                </button>
              )}

              <button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                onClick={handleSubmit(handelOnSubmit, err)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GetSvgIcon from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { readLocalStorage } from "../../Helper/CommonMethods";
import { COUNTRY_CODES, REQUIRED_ERR_MSG } from "../../Helper/Constants";
import { toBase64 } from "../../Helper/CommonMethods";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Spinner from "../../Layout/Spinner";
import CustomPopup from "../../Layout/CustomPopup/CustomPopup";
import ModalLayout from "../../Layout/ModalLayout";
import AddressBook from "./AddressBook";
import ModalAlert from "../../Layout/ModalAlert";
import ChartedBookConformAlert from "./ChartedBookConformAlert";
import FtlBookingAddress from "./FtlBookingAddress";
import {
  PICKUP_SERVICE_ID,
  HANDLING_SERVICE_ID,
  DELIVERY_SERVICE_ID,
  PACKAGING_SERVICE_ID,
  LINEHAUL_STAFF_ISR_USER_TYPE,
  MANPOWER_SERVICE_ID,
  EQUIPMENT_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";
import ChartedOrderSummary from "./ChartedOrderSummary";

const initialFormData = {
  isInternational: false,
  shipmentDate: "",
  slaId: 0,
  destination: 0,
  origin,
  totalWeight: 0,
  pallets: 0,
  userId: 0,
  customerId: 0,
  orderType: 1,
  paymentBy: "CREDIT",
  daysToDeliver: 0,
  shipmentVas: [],
  shipmentPallets: [],
  totalPalletArea: "",
  delivery: "selfCollect",
  pickup: "lodgein",
};
const intitCities = [
  {
    value: "",
    label: "",
  },
];

const options = [
  { label: "Pallet", value: "PLT" },
  { label: "Motor Bike", value: "MBK" },
  { label: "Full Truck", value: "FTL" },
];
const BUSHINESS_USER_TYPE = "136";
const ISR_USER_TYPE = "139";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const ChartaredBooking = () => {
  const [initialBookingData, setInitialBookingData] = useState();
  const [avalServices, setAvalServices] = useState();
  const [ftlServices, setFtlServices] = useState();

  const [show, setShow] = useState(false);

  const [sameAsSender, setSameAsSender] = useState(true);
  const [sameAsReciver, setSameAsReciver] = useState(true);
  const [stateCity, setStateCity] = useState();
  const [allCites, setAllCites] = useState([]);
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [senderCityData, setSenderCityData] = useState(intitCities);
  const [pickupCityData, setPickupCityData] = useState(intitCities);
  const [deliveryCityData, setDeliveryCityData] = useState(intitCities);
  const [recipientCityData, setRecipientCityData] = useState(intitCities);

  const [selectedInsurance, setSelectedInsurance] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [validSpecContent, setValidSpecContent] = useState(true);
  const [newOrderId, setNewOrderId] = useState();
  const sepDescription = "Special content more than available palette";
  const [title, setTitle] = useState();
  const [activeBlock, setActiveBlock] = useState(2);
  const [modalConfig, setModalConfig] = useState(null);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [addressBook, setAddressBook] = useState([]);
  const [selectedAddrType, setSelectedAddrType] = useState();
  const [senderAddressCalled, setSenderAddressCalled] = useState(false);
  const [isSateAvl, setIsSateAvl] = useState(false);
  const [isAddrsBookAvl, setIsAddrsBookAvl] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(initAlertConfig);
  const [selectedInsData, setSelectedInsData] = useState();
  const [calcReturnDatas, setCalcReturnDatas] = useState();
  const [orgDestAddrs, setOrgDestAddrs] = useState();
  const [canBook, setCanBook] = useState(true);
  const [ftlDatas, setFtlDatas] = useState();
  const [pickupFileLable, setPickupFileLable] = useState("Choose a File ...");
  const [delivaryFileLable, setDelivaryFileLable] =
    useState("Choose a File ...");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({ defaultValues: initialFormData });

  const watchPickup = watch("pickup");
  const watchDelivery = watch("delivery");
  const watchIsMnpSelected = watch("MNP");
  const watchIsEqpSelected = watch("EQP");
  // const watchHandling = watch("handling");
  // const watchPackaging = watch("packaging");

  useEffect(() => {
    initCharteredDat();
  }, []);

  const initCharteredDat = async () => {
    setFtlServices({
      delivary: true,
      handling: true,
      packing: true,
      pickup: true,
    });
    setAvalServices([
      {
        value: PICKUP_SERVICE_ID,
        label: "Pick up",
        isChecked: true,
      },
      {
        value: DELIVERY_SERVICE_ID,
        label: "Delivery",
        isChecked: true,
      },
      {
        value: HANDLING_SERVICE_ID,
        label: "Handling",
        isChecked: true,
      },
      {
        value: PACKAGING_SERVICE_ID,
        label: "Packaging",
        isChecked: true,
      },
    ]);
    setValue("delivery", "delivery");
    setValue("pickup", "pickup");

    setFtlDatas({
      shipDate: readLocalStorage("FtlDate"),
      vehicleType: readLocalStorage("FtlTuck"),
      vehicleTypeName: readLocalStorage("FtlTuckName"),
    });

    let serviceCode = readLocalStorage("ServiceCode");
    console.log("code =", serviceCode);
    setValue("serviceCode", readLocalStorage("ServiceCode"));

    // const custId = getUserInfo("acctId") ? parseInt(getUserInfo("acctId")) : 0;
    const custId = !!readLocalStorage("customerId")
      ? parseInt(readLocalStorage("customerId"))
      : 0;

    setValue("customerId", custId);
    setValue("userId", parseInt(getUserInfo("userId")));
    getSenderAddress();
    fetchStatesAndCityList();
    getAddressBookData();
    clearStorages();
  };

  /**
   * This function handles the booking process for a shipment and redirects to a payment gateway if
   * necessary.
   * @param data - It is a parameter passed to the function `bookOrder` which is used to make a
   * shipment booking. The exact contents of the `data` parameter are not shown in the code snippet,
   * but it is likely an object containing information about the shipment being booked, such as the
   * sender and recipient addresses,
   */
  const bookOrder = async (data) => {
    setShow(true);
    let bookingResponse = await CallApiPost(
      "SaveChartedBooking",
      getBookingRequestData(data)
    );
    setShow(false);
    if (bookingResponse.respCode === 200) {
      clearStorages();
      setNewOrderId(bookingResponse.respData.shipmentId);
      openConfirmAlert();
    } else {
      showAlert({
        isSuccess: false,
        title: "",
        description: bookingResponse.respMessage,
      });
    }
  };

  /**
   * This function validates form inputs and adds VAS (value-added services) for calculation.
   * @returns The function `validateForCalculate` is returning a boolean value indicating whether the
   * form data is valid or not.
   */
  const validateForCalculate = async () => {
    let valid = true;

    switch (activeBlock) {
      case 2:
        let mainArr = [
          "senderPostcode",
          "senderCity",
          "senderState",
          "senderAddress",
          "senderPhone",
          "senderName",
          "recipientPostcode",
          "recipientCity",
          "recipientState",
          "recipientAddress",
          "recipientPhone",
          "recipientName",
        ];
        let pickupArr = [
          "pickupPostcode",
          "pickupCity",
          "pickupState",
          "pickupAddress",
          "pickupPhone",
          "pickupName",
        ];
        let delivArr = [
          "deliveryPostcode",
          "deliveryCity",
          "deliveryState",
          "deliveryAddress",
          "deliveryPhone",
          "deliveryName",
        ];
        if (getValues("pickup") === "pickup") {
          mainArr = mainArr.concat(pickupArr);
        }

        if (getValues("delivery") === "delivery") {
          mainArr = mainArr.concat(delivArr);
        }

        // if(getValues("MNP")==='MNP'){
        //   mainArr = mainArr.concat("manpower");
        // }
        // if(getValues("EQP")==='EQP'){
        //   mainArr = mainArr.concat("equipment");
        // }

        valid = await trigger(mainArr);

        // add VAS for calculate
        let vasArray = [];

        if (getValues("specialPickup") === "specialPickup") {
          if (getValues("pickupAttachment").length === 0) {
            valid = false;
            setError("pickupAttachment", {
              type: "required",
              message: REQUIRED_ERR_MSG,
            });
          }
        }
        if (getValues("specialDelivery") === "specialDelivery") {
          if (getValues("deliveryAttachment").length === 0) {
            valid = false;
            setError("deliveryAttachment", {
              type: "required",
              message: REQUIRED_ERR_MSG,
            });
          }
        }

        break;

      case 4:
        // add VAS for calculate
        vasArray = [];
        break;

      default:
        break;
    }
    setValue("insuranceValue", selectedInsurance);

    return valid;
  };

  /**
   * The function `getVas` creates an array of objects representing value-added services based on user
   * input.
   * @returns The function `getVas` is returning an array of objects that represent the value-added
   * services (VAS) selected by the user. Each object contains a `serviceId` property that maps to a
   * specific VAS service, and a `pallets` property that represents the number of pallets associated
   * with that service.
   */
  const getVas = () => {
    // setUpShipmentVas(avalServices)
    let vasArray = !!getValues("vasArray") ? getValues("vasArray") : [];
    if (watchIsMnpSelected) {
      let objhp = {
        serviceId: MANPOWER_SERVICE_ID,
        pallets: 0,
        svcInfo5: !!getValues("manpower") ? getValues("manpower") : "",
      };
      removeVas(vasArray, MANPOWER_SERVICE_ID);
      vasArray?.push(objhp);
    } else {
      removeVas(vasArray, MANPOWER_SERVICE_ID);
    }

    if (watchIsEqpSelected) {
      let objhd = {
        serviceId: EQUIPMENT_SERVICE_ID,
        pallets: 0,
        svcInfo5: !!getValues("equipment") ? getValues("equipment") : "",
      };
      removeVas(vasArray, EQUIPMENT_SERVICE_ID);
      vasArray?.push(objhd);
    } else {
      removeVas(vasArray, EQUIPMENT_SERVICE_ID);
    }

    setValue("vasArray", vasArray);

    return vasArray;
  };

  const removeVas = (argVasArray, serviceId) => {
    const indexToRemove = argVasArray?.findIndex(
      (vas) => vas.serviceId === serviceId
    );
    if (indexToRemove !== -1) {
      argVasArray?.splice(indexToRemove, 1);
    }
  };

  /**
   * The function copies the sender's information to the pickup information fields if the pickup
   * checkbox is checked, and clears any errors in those fields.
   * @param value - The event object that triggered the function, which contains information about the
   * checkbox that was checked or unchecked.
   */
  const handelPickupcheck = (value) => {
    if (value.target.checked) {
      setValue("pickupName", getValues("senderName"));
      setValue("pickupPhoneCode", getValues("senderPhoneCode"));
      setValue("pickupPhone", getValues("senderPhone"));
      setValue("pickupAddress", getValues("senderAddress"));
      setValue("pickupState", getValues("senderState"));
      setValue("pickupCity", getValues("senderCity"));
      setValue("pickupPostcode", getValues("senderPostcode"));

      clearErrors("pickupName");
      clearErrors("pickupPhone");
      clearErrors("pickupAddress");
      clearErrors("pickupState");
      clearErrors("pickupCity");
      clearErrors("pickupPostcode");

      // setSameAsSender(false);
    } else {
      clearAddressData("pickup");
      // setSameAsSender(true);
    }
  };

  /**
   * The function copies the values of recipient details to delivery details if a checkbox is checked,
   * and clears any errors associated with the delivery details.
   * @param value - The event object that triggered the function, which contains information about the
   * checkbox that was checked or unchecked.
   */
  const handelDeliverycheck = (value) => {
    if (value.target.checked) {
      setValue("deliveryName", getValues("recipientName"));
      setValue("deliveryPhoneCode", getValues("recipientPhoneCode"));
      setValue("deliveryPhone", getValues("recipientPhone"));
      setValue("deliveryAddress", getValues("recipientAddress"));
      setValue("deliveryState", getValues("recipientState"));
      setValue("deliveryCity", getValues("recipientCity"));
      setValue("deliveryPostcode", getValues("recipientPostcode"));

      clearErrors("deliveryPhone");
      clearErrors("deliveryAddress");
      clearErrors("deliveryName");
      clearErrors("deliveryState");
      clearErrors("deliveryCity");
      clearErrors("deliveryPostcode");

      // setSameAsReciver(false);
    } else {
      clearAddressData("delivery");
      // setSameAsReciver(true);
    }
  };

  /**
   * The function clears two specific items from local storage.
   */
  const clearStorages = () => {
    localStorage.removeItem("FtlTuckName");
    localStorage.removeItem("FtlTuck");
    localStorage.removeItem("FtlDate");
  };

  /**
   * This function fetches a list of states and cities from an API and sets the data in various state
   * variables.
   */
  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });

    setStateData(stateArrList);

    setRecipientCityData(cityArrList);
    setDeliveryCityData(cityArrList);
    setPickupCityData(cityArrList);
    setSenderCityData(cityArrList);
    setAllCites(cityArrList);
    setIsSateAvl(true);
  };

  /**
   * The function filters a list of cities based on a given state and updates the cities data
   * accordingly.
   * @param state - The state for which the cities need to be filtered.
   * @param cityType - a string representing the type of city being filtered (e.g. "origin" or
   * "destination")
   */
  const filterCity = (state, cityType) => {
    if (state) {
      let cityArrList = [];

      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });

      setCitiesData(cityArrList, cityType);
      setValue(cityType, "");
    } else {
      setCitiesData(allCites, cityType);
      setValue(cityType, "");
    }
  };

  /**
   * The function sets data for different types of cities based on the input type.
   * @param data - The data parameter is the information that needs to be set for a specific city type.
   * It could be an object, an array, or any other data type that is relevant to the specific city
   * type.
   * @param type - The type parameter is a string that determines which city data state variable to
   * update. It can have one of the following values: "senderCity", "deliveryCity", "recipientCity", or
   * "pickupCity".
   */
  const setCitiesData = (data, type) => {
    switch (type) {
      case "senderCity":
        setSenderCityData(data);
        break;
      case "deliveryCity":
        setDeliveryCityData(data);
        break;
      case "recipientCity":
        setRecipientCityData(data);
        break;
      case "pickupCity":
        setPickupCityData(data);
        break;
      default:
        break;
    }
  };

  /**
   * The function filters a state based on a given city and sets the value of a specified state type
   * input field while clearing any errors.
   * @param city - The city parameter is a string representing the name of a city.
   * @param stateType - The stateType parameter is a string representing the name of the input field
   * where the state value will be set. It is used in the setValue and clearErrors functions to update
   * the state value and clear any errors associated with the input field.
   */
  const filterState = (city, stateType) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue(stateType, flt[0].map_state);
      clearErrors(stateType);
    }
  };

  /**
   * This function handles a breadcrumb click event and performs validation, calculation, and
   * navigation based on the clicked page number.
   * @param pageNo - represents the page number that is being clicked on the breadcrumb navigation.
   */
  const handelBreadcrumbClicked = async (pageNo) => {
    // let valid = await validateForCalculate();
    let valid = true;

    if (valid) {
      //   calculateBooking();
      setTcChecked();

      if (pageNo === 4) {
        validateForSummary();
      } else {
        setActiveBlock(pageNo);
        setValue("promoCode");
      }
    }
  };

  /**
   * The function handles the click event of the next button, validates the input, calculates the
   * booking, and updates the active block if valid.
   */
  const handelNextBtnClicked = async () => {
    let valid = await validateForCalculate();
    if (valid) {
      if (activeBlock < 4) {
        copyDeliveryAddressToRecipients();
        setActiveBlock(activeBlock + 2);
      }
    }
  };

  /**
   * The function decreases the value of the activeBlock variable by 1 if it is greater than 1.
   */
  const handelPrvBtnClicked = () => {
    if (activeBlock > 1) setActiveBlock(activeBlock - 2);
    setTcChecked();
  };

  const setModalData = (data) => {
    setModalConfig({
      size: "md",
      show: true,
      title: "Choose Address",
    });
    setSelectedAddrType(data);
  };

  const handleModalClose = (reload = false) => {
    setModalConfig({
      size: "md",
      show: false,
      title: "",
    });
    // reset(selectedData);
  };

  /**
   * This function retrieves the sender's details from an API and sets the corresponding form values.
   */
  const getSenderAddress = async () => {
    setShow(true);
    const cid = !!readLocalStorage("customerId")
      ? parseInt(readLocalStorage("customerId"))
      : 0;
    // const cid = customerId ? customerId : getUserInfo("acctId");
    const ctype =
      getUserInfo("customerType") === ISR_USER_TYPE
        ? BUSHINESS_USER_TYPE
        : getUserInfo("customerType");
    // console.log("getUserDetails?profileId=" + cid + "&userType=" + ctype);

    let resp = await CallApiGet(
      "getUserDetails?profileId=" + cid + "&userType=" + ctype
    );
    setShow(false);
    if (resp.respCode === 200) {
      let dt = resp.respData[0];
      let phoneNo = dt.phone;

      setValue("senderName", dt.name);
      setValue("senderPhoneCode", phoneNo.substr(0, 3));
      setValue("senderPhone", phoneNo.substr(3));
      setValue("senderAddress", dt.address);
      setValue("senderState", dt.state);
      setValue("senderCity", dt.city);
      setValue("senderPostcode", dt.postcode);
      setSenderAddressCalled(true);
      handelPickupcheck({ target: { checked: true } });
    }
  };

  /**
   * This function saves a customer's address book and displays a success message if the save is
   * successful.
   * @param addrType - The parameter `addrType` is likely a string that specifies the type
   * of address being saved to the address book. It is used to generate the data that will be sent in
   * the API call to save the address book entry.
   */
  const saveAddressBook = async (addrType) => {
    setShow(true);
    let addResp = await CallApiPost(
      "SaveCustAddrBook",
      getAddressBookDataForSave(addrType)
    );
    setShow(false);
    if (addResp.respCode === 200) {
      handleModalClose();
      showAlert({
        isSuccess: true,
        title: "",
        description: "Address Added Successfully",
      });
      //get Updated Address Book
      getAddressBookData();
    } else {
      // handleModalClose();
      showAlert({
        isSuccess: false,
        title: "",
        description: addResp.respMessage,
      });
    }
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  /**
   * The function returns an object containing address book data for a specific address type.
   * @param addrType - addrType is a string parameter that represents the type of address book data being
   * retrieved. It is used to construct the keys for retrieving the values of various fields such as
   * name, phone, address, state, city, and postcode. For example, if addrType is "billing", then the
   * function will
   * @returns The function `getAddressBookDataForSave` is returning an object with properties `custId`,
   * `name`, `phone`, `address`, `state`, `city`, and `postcode`. The values of these properties are
   * obtained from various functions such as `getUserInfo`, `getValues`, and string concatenation. The
   * specific values depend on the argument `addrType` passed to the function.
   */
  const getAddressBookDataForSave = (addrType) => {
    return {
      custId: getUserInfo("userId"),
      name: getValues(addrType + "Name"),
      phone:
        getValues(addrType + "PhoneCode") + "" + getValues(addrType + "Phone"),
      address: getValues(addrType + "Address"),
      state: getValues(addrType + "State"),
      city: getValues(addrType + "City"),
      postcode: getValues(addrType + "Postcode"),
    };
  };

  /**
   * The function retrieves address book data for a specific customer ID and sets it as the address
   * book while also setting a flag indicating the availability of the address book.
   */
  const getAddressBookData = async () => {
    let addrData = await CallApiGet(
      "CustAddrBookList?custId=" + getUserInfo("userId")
    );
    if (addrData.respCode === 200) {
      setAddressBook(addrData.respData);
      setIsAddrsBookAvl(true);
    }
  };

  /**
   * The function handles the selection of an address and populates the corresponding form fields with
   * the selected address data.
   * @param data - The data parameter is an object that contains information about the selected address,
   * including the name, phone number, address, state, city, and postcode.
   */
  const handelOnAddressSelected = (data) => {
    handleModalClose();

    clearErrors(selectedAddrType + "Name");
    clearErrors(selectedAddrType + "Phone");
    clearErrors(selectedAddrType + "Address");
    clearErrors(selectedAddrType + "State");
    clearErrors(selectedAddrType + "City");
    clearErrors(selectedAddrType + "Postcode");

    setValue(selectedAddrType + "Name", data.name);
    setValue(selectedAddrType + "PhoneCode", data.phone.substr(0, 3));
    setValue(selectedAddrType + "Phone", data.phone.substr(3));
    setValue(selectedAddrType + "Address", data.address);
    setValue(selectedAddrType + "State", data.state);
    setValue(selectedAddrType + "City", data.city);
    setValue(selectedAddrType + "Postcode", data.postcode);
  };

  /**
   * The function validates form input fields and sets the active page based on the validity of the
   * input.
   */
  const validateForSummary = async () => {
    let valid = false;
    if (
      getValues("senderPostcode") &&
      getValues("senderCity") &&
      getValues("senderState") &&
      getValues("senderAddress") &&
      getValues("senderPhone") &&
      getValues("senderName") &&
      getValues("recipientPostcode") &&
      getValues("recipientCity") &&
      getValues("recipientState") &&
      getValues("recipientAddress") &&
      getValues("recipientPhone") &&
      getValues("recipientName")
    ) {
      valid = true;
    }
    if (getValues("pickup") === "pickup") {
      valid = false;
      if (
        getValues("pickupPostcode") &&
        getValues("pickupCity") &&
        getValues("pickupState") &&
        getValues("pickupAddress") &&
        getValues("pickupPhone") &&
        getValues("pickupName")
      )
        valid = true;
    }

    if (getValues("delivery") === "delivery") {
      valid = false;
      if (
        getValues("deliveryPostcode") &&
        getValues("deliveryCity") &&
        getValues("deliveryState") &&
        getValues("deliveryAddress") &&
        getValues("deliveryPhone") &&
        getValues("deliveryName")
      )
        valid = true;
    }
    const activePage = valid ? 4 : 2;

    if (activePage === 2) {
      setActiveBlock(activePage);
      if (!senderAddressCalled) getSenderAddress();
      if (!isSateAvl) fetchStatesAndCityList();
      if (!isAddrsBookAvl) getAddressBookData();
      if (!valid) {
        showAlert({
          isSuccess: false,
          title: "Delivery Information",
          description: "Please Provide Required delivery Information",
        });
      }
    }
    // if (activePage === 4 && !getValues("insuranceValue")) {

    //   openConfirmAlert();
    // }
    copyDeliveryAddressToRecipients();
    setActiveBlock(activePage);
  };

  /**
   * The function retrieves sender, recipient, pickup, and delivery addresses from a form and returns
   * them as an object.
   * @returns The function `getGivenAddresses` is returning an object `addresses` which contains the
   * sender, recipient, pickup and delivery addresses. If the corresponding input fields are not
   * filled, the respective address object will be empty.
   */
  const getGivenAddresses = () => {
    let addresses = {};

    if (getValues("senderName")) {
      addresses.senderAddress = {
        name: getValues("senderName"),
        address: getValues("senderAddress"),
        city: getValues("senderCity"),
        state: getValues("senderState"),
        postcode: getValues("senderPostcode"),
        phone: getValues("senderPhoneCode") + "" + getValues("senderPhone"),
      };
    } else {
      addresses.senderAddress = {};
    }
    if (getValues("recipientName")) {
      addresses.recipientAddress = {
        name: getValues("recipientName"),
        address: getValues("recipientAddress"),
        city: getValues("recipientCity"),
        state: getValues("recipientState"),
        postcode: getValues("recipientPostcode"),
        phone:
          getValues("recipientPhoneCode") + "" + getValues("recipientPhone"),
      };
    } else {
      addresses.recipientAddress = {};
    }

    if (getValues("pickup") === "pickup" && getValues("pickupName")) {
      addresses.pickupAddress = {
        name: getValues("pickupName"),
        address: getValues("pickupAddress"),
        city: getValues("pickupCity"),
        state: getValues("pickupState"),
        postcode: getValues("pickupPostcode"),
        phone: getValues("pickupPhoneCode") + "" + getValues("pickupPhone"),
      };
    } else {
      addresses.pickupAddress = {};
    }
    if (getValues("delivery") === "delivery" && getValues("deliveryName")) {
      addresses.deliveryAddress = {
        name: getValues("deliveryName"),
        address: getValues("deliveryAddress"),
        city: getValues("deliveryCity"),
        state: getValues("deliveryState"),
        postcode: getValues("deliveryPostcode"),
        phone: getValues("deliveryPhoneCode") + "" + getValues("deliveryPhone"),
      };
    } else {
      addresses.deliveryAddress = {};
    }
    return addresses;
  };

  const onTermConditionChecked = (isChecked) => {
    if (canBook) setTcChecked(isChecked);
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const confirmAlert = () => {
    const userType = getUserInfo("userType");
    hideConfirmAlert();
    if (LINEHAUL_STAFF_ISR_USER_TYPE !== userType)
      navigate("/customerOrder?page=FTL");
    else navigate("/customerOrder");
  };
  const openConfirmAlert = (row) => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Requested",
      // description: "Deactivate Rate",
    });
  };

  const getBookingRequestData = (data) => {
    let allAddresses = getGivenAddresses();
    getSpecialPermissionDataModel(data);

    let obj = {
      customerId: data.customerId,
      shipOption: "FTL",
      shipmentDate: new Date(ftlDatas.shipDate),
      vehicleTypeId: ftlDatas.vehicleType,
      userId: data.userId,
      estDeliveryDate: new Date(ftlDatas.shipDate),
      shipmentVas: getVas(),
      orderInfos: getSpecialPermissionDataModel(data),
      serviceCode: data.serviceCode,
      pickupPermissionAttachment: data.pickupAttachment,
      deliveryPermissionAttachment: data.deliveryAttachment,
    };

    obj.destination = { sbuId: 0 };

    obj.origin = { sbuId: 0 };

    if (calcReturnDatas && calcReturnDatas.senderAddress)
      obj.senderAddress = calcReturnDatas.senderAddress;
    else obj.senderAddress = allAddresses.senderAddress;

    if (calcReturnDatas && calcReturnDatas.recipientAddress)
      obj.recipientAddress = calcReturnDatas.recipientAddress;
    else obj.recipientAddress = allAddresses.recipientAddress;

    if (
      calcReturnDatas &&
      calcReturnDatas.pickupAddress &&
      allAddresses.pickupAddress.name
    )
      obj.pickupAddress = calcReturnDatas.pickupAddress;
    else obj.pickupAddress = allAddresses.pickupAddress;

    if (
      calcReturnDatas &&
      calcReturnDatas.deliveryAddress &&
      allAddresses.deliveryAddress.name
    )
      obj.deliveryAddress = calcReturnDatas.deliveryAddress;
    else obj.deliveryAddress = allAddresses.deliveryAddress;

    return obj;
  };

  /**
   * The function `getSpecialPermissionDataModel` takes in an object `data` and returns an array of
   * special permission objects based on the properties `specialPickup` and `specialDelivery` of the
   * `data` object.
   * @param data - The `data` parameter is an object that contains information about special
   * permissions. It has two properties: `specialPickup` and `specialDelivery`. These properties are
   * boolean values indicating whether special pickup and special delivery permissions are granted or
   * not.
   * @returns The function `getSpecialPermissionDataModel` returns an array of objects. Each object in
   * the array has two properties: `infoCode` and `infoValue`.
   */
  const getSpecialPermissionDataModel = (data) => {
    let specialPermissionObj = [];
    if (data.specialPickup) {
      specialPermissionObj.push({
        infoCode: 176,
        infoValue: "pickup",
      });
    }
    if (data.specialDelivery) {
      specialPermissionObj.push({
        infoCode: 176,
        infoValue: "delivery",
      });
    }
    return specialPermissionObj;
  };

  const clearAddressData = (addrType) => {
    setValue(addrType + "Name", undefined);
    setValue(addrType + "Phone", undefined);
    setValue(addrType + "PhoneCode", COUNTRY_CODES[0].value);
    setValue(addrType + "Address", undefined);
    setValue(addrType + "State", undefined);
    setValue(addrType + "City", undefined);
    setValue(addrType + "Postcode", undefined);
    setValue(addrType + "Pallets", undefined);
  };

  const onErr = (err) => {
    console.log(err);
  };

  const selectPickupDeliveryFile = async (event, type) => {
    const file = event.target.files;

    if (file.length === 0) {
      type === "pickup"
        ? setPickupFileLable("Choose a File ...")
        : setDelivaryFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setPickupFileLable(fileName);
      type === "pickup"
        ? setPickupFileLable(fileName)
        : setDelivaryFileLable(fileName);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";

      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      // setStatementFile(newAttachment);
      type === "pickup"
        ? setValue("pickupAttachment", newAttachment)
        : setValue("deliveryAttachment", newAttachment);

      // console.log(newAttachment);
      clearErrors(type + "Attachment");
    }
  };

  const handelSpecialPermissionChange = (event, type) => {
    if (!event.target.checked) {
      // type === "pickup"
      //   ? setPickupFileLable("Choose a File ...")
      //   : setDelivaryFileLable("Choose a File ...");
      // type === "pickup"
      //   ? setValue("pickupAttachment", "")
      //   : setValue("deliveryAttachment", "");
      if (type === "pickup") {
        setPickupFileLable("Choose a File ...");
        setValue("pickupAttachment", "");
        clearErrors("pickupAttachment");
      } else {
        setDelivaryFileLable("Choose a File ...");
        setValue("deliveryAttachment", "");
        clearErrors("deliveryAttachment");
      }
    }
  };

  const copyDeliveryAddressToRecipients = () => {
    setValue("recipientPostcode", getValues("deliveryPostcode"));
    setValue("recipientCity", getValues("deliveryCity"));
    setValue("recipientState", getValues("deliveryState"));
    setValue("recipientAddress", getValues("deliveryAddress"));
    setValue("recipientPhone", getValues("deliveryPhone"));
    setValue("recipientPhoneCode", getValues("deliveryPhoneCode"));
    setValue("recipientName", getValues("deliveryName"));
  };

  return (
    <>
      <Header />
      <div className="container-fluid p-0 min-ht-100 wt-tbl">
        <div className="px-4 py-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item theme-blue-txt fw-bold cursor-pointer">
                <a href="/">{GetSvgIcon("Home")}</a>
              </li>

              <li
                className={
                  activeBlock === 2
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                onClick={() => handelBreadcrumbClicked(2)}
              >
                Delivery Info
              </li>

              <li
                className={
                  activeBlock === 4
                    ? "breadcrumb-item active fw-bold"
                    : "breadcrumb-item theme-blue-txt cursor-pointer"
                }
                onClick={() => handelBreadcrumbClicked(4)}
              >
                Summary
                {/* {previousClickble?"true":"false"} */}
              </li>
              {/* <li>
              <button type="button" className="btn btn-primary" onClick={handelOptionsPreviousButtonClick} disabled={!previousClickble}>privious</button>
              <button type="button" className="btn btn-primary" onClick={handelOptionsNextButtonClick}>Next</button>
              </li> */}
            </ol>
          </nav>
        </div>
        <div className="px-3">
          <div className="row">
            <div className="col-md-8 m-auto mt-3">
              <div>
                <div className="col-md-12">
                  {/* address block start */}
                  {activeBlock === 2 && (
                    <FtlBookingAddress
                      register={register}
                      clearErrors={clearErrors}
                      errors={errors}
                      control={control}
                      stateData={stateData}
                      filterCity={filterCity}
                      senderCityData={senderCityData}
                      filterState={filterState}
                      setModalData={setModalData}
                      trigger={trigger}
                      saveAddressBook={saveAddressBook}
                      avalServices={ftlServices}
                      watchPickup={watchPickup}
                      sameAsSender={sameAsSender}
                      handelPickupcheck={handelPickupcheck}
                      pickupCityData={pickupCityData}
                      recipientCityData={recipientCityData}
                      watchDelivery={watchDelivery}
                      sameAsReciver={sameAsReciver}
                      handelDeliverycheck={handelDeliverycheck}
                      deliveryCityData={deliveryCityData}
                      bookingType={"chartered"}
                      delivaryFileLable={delivaryFileLable}
                      pickupFileLable={pickupFileLable}
                      selectPickupDeliveryFile={selectPickupDeliveryFile}
                      watch={watch}
                      setValue={setValue}
                      watchIsEqpSelected={watchIsEqpSelected}
                      watchIsMnpSelected={watchIsMnpSelected}
                      handelSpecialPermissionChange={
                        handelSpecialPermissionChange
                      }
                    />
                  )}
                  {/* address block ends */}

                  {activeBlock === 4 && (
                    <div className="w-100 d-block">
                      <ChartedOrderSummary
                        getValues={getValues}
                        initialBookingData={initialBookingData}
                        onTermConditionChecked={onTermConditionChecked}
                        orgDestAddrs={orgDestAddrs}
                        avalServices={avalServices}
                        options={options}
                        bookingType={"chartered"}
                        ftlDatas={ftlDatas}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between my-3">
                    {activeBlock > 2 && (
                      <div className="col text-start">
                        <button
                          className="btn btn-outline-secondary theme-violet text-nowrap"
                          type="button"
                          onClick={handelPrvBtnClicked}
                        >
                          &larr; Previous
                        </button>
                      </div>
                    )}
                    {activeBlock < 4 && (
                      <div className="col text-end">
                        <button
                          className="btn btn-outline-secondary theme-violet text-nowrap"
                          type="button"
                          onClick={handelNextBtnClicked}
                        >
                          Next &rarr;
                        </button>
                      </div>
                    )}

                    {activeBlock === 4 && (
                      <div className="row">
                        <div className="col-md-12 ">
                          <div className="text-center">
                            <button
                              className="btn btn-outline-secondary theme-violet text-nowrap"
                              // type="submit"
                              disabled={!tcChecked}
                              onClick={handleSubmit(bookOrder, onErr)}
                            >
                              Submit Request &rarr;
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="ps-3 pb-2">
                  <button
                    className="btn btn-outline-secondary theme-violet"
                    type="button"
                    onClick={calculateBooking}
                  >
                    Calculate
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="paymentForm"></div>
      <Spinner show={show} />
      {/* <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        // handler={onCreditBookingSuccess}
      /> */}
      {!validSpecContent && (
        <CustomPopup
          icon={GetSvgIcon("ExclamationTriangleFill")}
          body={sepDescription}
          callback={() => setValidSpecContent(true)}
        />
      )}

      {modalConfig && (
        <ModalLayout
          moadlConfig={modalConfig}
          handleMoalClose={handleModalClose}
          // footer={footerControls}
        >
          <AddressBook
            handelOnAddressSelected={handelOnAddressSelected}
            addressBook={addressBook}
            selectedInsData={selectedInsData}
          />
        </ModalLayout>
      )}

      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}

      {!!confirmAlertConfig && (
        <ChartedBookConformAlert
          confirmAlertConfig={confirmAlertConfig}
          hideConfirmAlert={hideConfirmAlert}
          confirmAlert={confirmAlert}
        >
          Your request has been submitted.
        </ChartedBookConformAlert>
      )}
    </>
  );
};

export default ChartaredBooking;

import React, { useState } from 'react';
import Header from "../Layout/Header";
import './termsStyle.css';

export default function TermsConditions() {
    return (
        <>
            <Header />
            <div className="container-fluid min-ht-100 termss">
                <div className="row">
                    <main className="col-md-12 p-3">
                        <div className="clearfix border-bottom">
                            <div className="float-start">
                                <div className="section-title mt-1">Terms and Conditions of Carriage</div>
                            </div>
                            <div className="float-end d-flex">
                                <pre>
                                    <span><b>Revision:</b></span> <span>01</span><br />
                                    <span><b>Effective Date:</b></span> <span>10 Jul 2023</span>
                                </pre>
                            </div>
                        </div>
                        <div>
                            <div className="col-md-12 mt-3">
                                <ol id="l1">
                                    <li data-list-text="1.">
                                        <p>Privacy statement</p>
                                        <ol id="l2">
                                            <li data-list-text="1.1.">
                                                <p>
                                                    All and any business undertaken by LINE HAUL SDN BHD (Registration <span
                                                        class="s1">201901042436(1351766-K)</span>), a company with its business address at 3, Jalan
                                                    TP 2, Taman Perindustrian UEP, 47600 Subang Jaya, Selangor [hereinafter referred to as “<b>LINE
                                                        HAUL</b>”] is transacted pursuant to the terms and conditions hereinafter set out and shall
                                                    be deemed to be the terms and conditions of each andany agreement between LINE HAUL and its
                                                    customer [hereinafter referred to as the “<b>Customer</b>” and theterms “<b>Your</b>” or
                                                    “<b>You</b>” or “<b>Sender</b>” or “<b>Shipper</b>” or “<b>User</b>” shall refer to the same] or
                                                    any person acting on behalf of the Customer.</p>
                                            </li>
                                            <li data-list-text="1.2.">
                                                <p>The Shipper
                                                    irrevocably warrants that the Shipper is the actual, legal or proprietary owner of any Shipment
                                                    that is tendered to LINE HAUL for carriage or transportation, shall irrevocably agrees and
                                                    accepts the conditions herein not only for itself but also as agent for and on behalf of any
                                                    person who may have interests in the Shipment and shall be deemed to have accepted and agreed to
                                                    the terms and conditions herein, which terms and conditions shall be referred to as LINE HAUL
                                                    SDN BHD TERMS AND CONDITIONS OF CARRIAGE.</p>
                                            </li>
                                            <li data-list-text="1.3.">
                                                <p>LINE HAUL
                                                    shall not be bound by any agreement purporting to vary these conditions unless such agreement
                                                    shall be in writing and signed on behalf of LINE HAUL by an authorized Officer of LINE HAUL. All
                                                    conditions, warranties, descriptions, representations and agreements not set out in these
                                                    conditions and whether they be expressed or implied by law, custom or other are hereby expressly
                                                    excluded.No officer, servant, agent or representative is authorized to make any representations,
                                                    statements, warranties, conditions and or agreement not expressly set forth in these conditions
                                                    and LINE HAUL is notin any way bound by any of them or to be taken as to form part of the
                                                    contract with LINE HAUL or collateral to the main contract.</p>
                                            </li>
                                            <li data-list-text="1.4.">
                                                <p>LINE HAUL
                                                    shall have the authority to collect the Customer’s information (including but not limited to
                                                    personal data, propriety data and confidential information) pursuant to the Malaysian Personal
                                                    Data Protection Act 2010 (“<b>PDPA</b>”) in order to ease the shipment process and for the
                                                    purpose of the transactions contemplated under the agreement(s) between LINE HAUL and the
                                                    Customer.</p>
                                                <ol id="l3">
                                                    <li data-list-text="1.4.1.">
                                                        <p>The
                                                            data or information that will collect by LINE HAUL from its Customer shall include (but
                                                            not limited to) the Sender’s and intended receiver’s personal and confidential
                                                            information including name, age, occupation, bank account number, email address, phone
                                                            number, home/ office and related address, shipment information, shipment price,
                                                            financial information such as income, or income tax particulars identity card or
                                                            passport, place of birth, credit history and transaction history, and any related
                                                            information which is relevant to the transactions contemplated under the agreement(s)
                                                            between LINE HAUL and the Customer for the purpose of LINE HAUL’s processing, internal
                                                            compliances and for any other purpose allowed by law and with the Customer’s consent.
                                                        </p>
                                                    </li>
                                                    <li data-list-text="1.4.2.">
                                                        <p>The
                                                            Shipper warrant that neither the transaction nor the carriage of the Shipment is in
                                                            breach of any statute regulation, including but not limited to governmental regulations
                                                            and laws of any country to, from, through or over the Shipment, or other law relating to
                                                            postal or courier services, and that they are either the owners of or the authorized
                                                            agents of the owners of any goods or property being the subject matter of the
                                                            transaction and by entering into the transaction they accept these conditions for
                                                            themselves as well as for all other parties on whose behalf they are acting. Shipper and
                                                            consignees of any Shipment and / or their agents, undertakes to indemnify LINE HAUL
                                                            against losses, damages, expenses and fines arising from any inaccuracy or omission even
                                                            in such inaccuracy or omission is not due to any negligence.</p>
                                                        <p><br /></p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="1.5.">
                                                <p>Furtherance to 1.2.1, LINE
                                                    HAUL will collect the data so that LINE HAUL is able:</p>
                                                <ol id="l4">
                                                    <li data-list-text="1.5.1.">
                                                        <p>To
                                                            process your requested services;</p>
                                                    </li>
                                                    <li data-list-text="1.5.2.">
                                                        <p>To
                                                            process the payment transactions, delivery of notice, forms, letters and other documents
                                                            necessarily required;</p>
                                                    </li>
                                                    <li data-list-text="1.5.3.">
                                                        <p>To
                                                            respond to your enquiries;</p>
                                                    </li>
                                                    <li data-list-text="1.5.4.">
                                                        <p
                                                        >
                                                            To communicate with you and to deliver any relevant information by any communication
                                                            methods including, but not limited to e-mail, telephone calls, facsimile, short message
                                                            service, social media and other services;</p>
                                                    </li>
                                                    <li data-list-text="1.5.5.">
                                                        <p
                                                        >
                                                            To process the information for any other purposes provided for in LINE HAUL services
                                                            and/or products offered by us;</p>
                                                    </li>
                                                    <li data-list-text="1.5.6.">
                                                        <p>To conduct market
                                                            survey and trend analysis;</p>
                                                    </li>
                                                    <li data-list-text="1.5.7.">
                                                        <p>To
                                                            prevent and hinder, and to report any criminal activities including but not limited to,
                                                            fraud, bribery and money laundering;</p>
                                                    </li>
                                                    <li data-list-text="1.5.8.">
                                                        <p>To
                                                            comply with any legal requirements and/or to make any disclosure under the requirements
                                                            of any applicable law, regulations, directions, court orders, guidelines, circulars,
                                                            codes which are applicable to us;</p>
                                                    </li>
                                                    <li data-list-text="1.5.9.">
                                                        <p>To process the same
                                                            for internal records and maintenance of LINE HAUL user database;</p>
                                                    </li>
                                                    <li data-list-text="1.5.10.">
                                                        <p>To
                                                            process the same for LINE HAUL’s credit risk management and credibility worthiness check
                                                            of its Users; and</p>
                                                    </li>
                                                    <li data-list-text="1.5.11.">
                                                        <p>To monitor, review and
                                                            improve LINE HAUL’s services.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="1.6.">
                                                <p >LINE HAUL will collect data
                                                    and process data when the User:</p>
                                                <ol id="l5">
                                                    <li data-list-text="1.6.1.">
                                                        <p >Register online or place
                                                            an order for any of LINE HAUL’s products and/or services.</p>
                                                    </li>
                                                    <li data-list-text="1.6.2.">
                                                        <p
                                                        >
                                                            Voluntarily complete a customer survey or provide feedback on any of LINE HAUL’s message
                                                            boards or via email;</p>
                                                    </li>
                                                    <li data-list-text="1.6.3.">
                                                        <p >Use or view LINE HAUL’s
                                                            Website via User’s browser’s cookies.</p>
                                                    </li>
                                                    <li data-list-text="1.6.4.">
                                                        <p >
                                                            Acquire any information and data from approved external parties (for example credit
                                                            reference offices, administrative and enforcement offices, recruitment organizations,
                                                            business owners, schools, colleges, legitimate delegates).</p>
                                                    </li>
                                                    <li data-list-text="1.6.5.">
                                                        <p >When
                                                            you contact any of LINE HAUL entities/ representatives through different approaches for
                                                            example, application forms, emails and letters, telephone calls and conversations you
                                                            have with our staff in a HQ/ branch. In the event that you reach us or we get in touch
                                                            with you utilizingphone, we may screen or record the telephone call for quality
                                                            affirmation, training and security purposes.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="1.7.">
                                                <p >By signing
                                                    on the waybill documents, you are deemed to have consented and authorized LINE HAUL to collect,
                                                    process and use your personal data for all lawful purpose in accordance with the PDPA, including
                                                    but not limited to the disclosure of your personal data by LINE HAUL to any government and/or
                                                    regulatory authority to the extent permitted by any applicable laws. Further, LINE HAUL may
                                                    disclose / disseminate the Customer’s information to LINE HAUL’s agents and/or service providers
                                                    who provide services relating to the purposes of which the personal information is collected
                                                    including data processing services. For this,</p>
                                                <ol id="l6">
                                                    <li data-list-text="1.7.1.">
                                                        <p>Our
                                                            agent or third-party service providers shall be as LINE HAUL deems necessary or
                                                            appropriate for the services and the transactions contemplated under the agreement(s)
                                                            between LINE HAUL andthe Customer;</p>
                                                    </li>
                                                    <li data-list-text="1.7.2.">
                                                        <p>Any
                                                            person, who is under a duty of confidentiality with LINE HAUL and who has undertaken the
                                                            responsibility to keep such information confidential; and</p>
                                                    </li>
                                                    <li data-list-text="1.7.3.">
                                                        <p>Any
                                                            of our affiliates in and outside Malaysia who is/are connected to LINE HAUL Sdn Bhd;</p>
                                                    </li>
                                                    <li data-list-text="1.7.4.">
                                                        <p class="s2"
                                                        >
                                                            <span class="p">Bank, financial institution, insurance companies, government bodies
                                                                including but not limited to land authorities, Malaysian Inland Revenue Board,
                                                                Companies Commission of Malaysia, credit reporting agencies, and court of laws (the
                                                                list is non-exhaustive).</span></p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="1.8.">
                                                <p >Security</p>
                                                <p >1.6.1 LINE
                                                    HAUL is registered as a data user with the Ministry of Communications and Multimedia, Malaysia.
                                                    As such, LINE HAUL will take practical steps to protect your personal data from any loss,
                                                    misuse, modification, unauthorised or accidental access or disclosure by maintaining security of
                                                    our records, whether electronic or otherwise, and also by providing adequate training to our
                                                    staff on proper handling of personal data.</p>
                                            </li>
                                            <li data-list-text="1.9.">
                                                <p >Retention
                                                    period</p>
                                                <ol id="l7">
                                                    <li data-list-text="1.9.1.">
                                                        <p >All
                                                            data and information obtained will be retained by LINE HAUL so long as it is necessary
                                                            for the services and expedient for the fulfilment of any purpose as stated above
                                                            pursuant to LINE HAUL’s internal practices and requirements including but not limited to
                                                            the regulatory, legal and accounting requirements.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="1.10.">
                                                <p >Access</p>
                                                <ol id="l8">
                                                    <ol id="l9">
                                                        <ol id="l10">
                                                            <li data-list-text="1.8.1">
                                                                <p
                                                                >
                                                                    You may, upon payment of a fee as prescribed by the Personal Data Protection
                                                                    (Fees) Regulations 2013, make a request in <u>writing to</u> access the
                                                                    information on any of your personal data that is being processed by us and we
                                                                    will comply with the said data access request subject always to the PDPA.</p>
                                                            </li>
                                                            <li data-list-text="1.8.2">
                                                                <p
                                                                >
                                                                    You may also request for your data to be corrected by notifying us in
                                                                    <u>writing</u> if any personal data being held by us is inaccurate, incomplete,
                                                                    misleading or not up-to-date and we will comply with your request for data
                                                                    correction subject always to the PDPA.</p>
                                                            </li>
                                                        </ol>
                                                    </ol>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text="2.">
                                        <p>Website and Phone
                                            Applications</p>
                                        <p>These
                                            terms and conditions shall also govern the use of this Website. By browsing, accessing or viewing this
                                            Website, you have agreed to be bound by these terms and conditions. If you are not agreeable to these
                                            terms and conditions, please refrain from accessing this Website.</p>
                                        <ol id="l11">
                                            <li data-list-text="2.1.">
                                                <p >Terminology/ definitions of
                                                    terms use, terms of address,</p>
                                                <ol id="l12">
                                                    <li data-list-text="2.1.1.">
                                                        <p
                                                        >
                                                            The term “LINE HAUL” under these terms and conditions shall refer to LINE HAUL SDN BHD,
                                                            a company with its business address at 3, Jalan TP 2, Taman PerindustrianUEP, 47600
                                                            Subang Jaya, Selangor. The terms or ‘<b>Your</b>’ or ‘<b>You</b>’ or ‘<b>Sender</b>’
                                                            refer to the Customer,the person accessing this Website and agreeing to the LINE HAUL’s
                                                            terms and conditions. The term “<b>Website</b>” shall refer to this Website which shall
                                                            include all the materials in this Website, but is not limited to text, graphics, logos,
                                                            icons, software, information, data, features, sound recordings, derivative works and
                                                            contents thereof.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="2.2.">
                                                <p >Restriction
                                                    on use of the Website.</p>
                                                <p >You shall
                                                    ensure that you and your affiliates shall not, without prior consent from LINE HAUL:</p>
                                                <ol id="l13">
                                                    <li data-list-text="2.2.1.">
                                                        <p>
                                                            Copy, modify, distribute, download, display, transfer, post or transmit the Website or
                                                            its contents for any public or commercial purpose;</p>
                                                    </li>
                                                    <li data-list-text="2.2.2.">
                                                        <p >Use
                                                            any robot, spider, automatic device, manual or automated process or other means to
                                                            access the Website, the server(s) on which the site is/are stored, or any database
                                                            connected to the Website for any unauthorised purpose, such as the monitoring,
                                                            collection or copying of any material on the Website;</p>
                                                    </li>
                                                    <li data-list-text="2.2.3.">
                                                        <p>
                                                            Place false or misleading information on the Website;</p>
                                                    </li>
                                                    <li data-list-text="2.2.4.">
                                                        <p >
                                                            Input any information which contains viruses, Trojan horses, worms, malicious software
                                                            or other computer programming routine which intends to damage, interfere with, intercept
                                                            with or expropriate any system, the Website or information that infringes the
                                                            intellectual property rights of another.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="2.3.">
                                                <p >The access
                                                    to this Website may be terminated or suspended at any time without prior notice. Upon such
                                                    termination, you must immediately (a) discontinue from accessing the Website and (b) destroy all
                                                    and any copies that you have made pursuant to any portion of the Website. Take notice that
                                                    accessing the Website after such termination will constitute as an act of trespass.</p>
                                            </li>
                                            <li data-list-text="2.4.">
                                                <p >You shall
                                                    hereby agree to defend, indemnify and hold harmless LINE HAUL and its affiliates from any and
                                                    all claims arising out of your breach of any of these terms and conditions and any of your
                                                    activities conducted in connection with this Website.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text="3.">
                                        <p className='fw-bold'>General terms and conditions</p>
                                        <ol id="l14">
                                            <li data-list-text="3.1.">
                                                <p >Packaging guideline</p>
                                                <ol id="l15">
                                                    <li data-list-text="3.1.1.">
                                                        <p
                                                        >
                                                            The Sender shall be solely responsible for the proper packaging of goods, including the
                                                            placement of the documents or goods in any container, labelling and intact packaging, in
                                                            accordance with the safety requirement and courier standards to ensure the safety of
                                                            goods during the transportation process. LINE HAUL accepts no responsibility for direct
                                                            and indirect losses or damage to documents or goods caused by inadequate, inappropriate
                                                            or defective packaging. The use of LINE HAUL -provided packaging (including but not
                                                            limited to materials, supplies and assistance) does not guarantee that an item is
                                                            sufficiently packaged for transportation.</p>
                                                    </li>
                                                    <li data-list-text="3.1.2.">
                                                        <p >The
                                                            Sender shall, at Sender’s best endeavours, adhere to the following Basic Packaging
                                                            Guidelines &amp; Principles. The Sender is responsible for understanding and
                                                            comprehending the importance ofensuring that the parcel is able to withstand all transit
                                                            activities, including but not limited to movements, impacts, pressure, easy handling
                                                            etc. The following serves as a minimal guide should thepackaging be done at Sender’s
                                                            end:-</p>
                                                        <ol id="l16">
                                                            <li data-list-text="3.1.2.1.">
                                                                <p
                                                                >
                                                                    Ensure appropriate material, quality, sizes of packaging used suitable for
                                                                    shipment carried, considering its nature, weight, sizes, shapes etc. (E.g.
                                                                    Fragile items should be packed in boxes with solid in-fillers, documents should
                                                                    be packed in hard-cover paper enveloped etc.)</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.2.">
                                                                <p >A good
                                                                    packaging should be able to withstand 2 meters of free fall, and 30kg of
                                                                    pressure.</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.3.">
                                                                <p
                                                                >
                                                                    Ensure internal fillers are sufficient with proper layering, no space left, well
                                                                    organized, no shifting or movement of content in the packaging or impacts among
                                                                    contents in the packaging (if multiple contents, items should be wrapped
                                                                    individually in the parcel).</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.4.">
                                                                <p
                                                                >
                                                                    Ensure sharp or pointy items are properly wrapped, no exposure of sharp or
                                                                    pointy edges to avoid hurting our staffs or any other person or damaging other
                                                                    parcels. Sender is fully responsible for any damages if such parcel causes
                                                                    injuries to other person or damages to other parcels and/or property(ies).</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.5.">
                                                                <p
                                                                >
                                                                    Ensure high value items are tightly packed, sealed, un-concealable of internal
                                                                    content to outsiders and durable for transit.</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.6.">
                                                                <p >Ensure all
                                                                    fragile items are well packed and sealed.</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.7.">
                                                                <p
                                                                >
                                                                    Ensure parcels packed are clean, dry, odourless, oil-free, and contamination
                                                                    free. All parcels should be properly packed, no leakage, content exposure, tear,
                                                                    no re-use of used packaging materials etc.</p>
                                                            </li>
                                                            <li data-list-text="3.1.2.8.">
                                                                <p>
                                                                    Packaging of air-freight shipments should comply with IATA (International Air
                                                                    Transport Association) and ICAO (International Civil Aviation Organization)
                                                                    guidelines</p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li data-list-text="3.1.3.">
                                                        <p >The
                                                            Sender shall be responsible for all posted items, including but not limited to liquids,
                                                            creams, lotions, or cosmetics that may be risk at rupture, as well as perishable food
                                                            and/or other forms of food. LINE HAUL shall not be responsible or liable for direct or
                                                            indirect losses on any damages or losses resulting from packaging problem.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.2.">
                                                <p >Declaration shipment content
                                                </p>
                                                <ol id="l17">
                                                    <li data-list-text="3.2.1.">
                                                        <p>Declarations of
                                                            shipment by the Sender are based on an utmost good faith basis.</p>
                                                    </li>
                                                    <li data-list-text="3.2.2.">
                                                        <p >
                                                            Sender must disclose to LINE HAUL all important information including but not limited to
                                                            the actual value and description of package(s) and/or content(s).</p>
                                                    </li>
                                                    <li data-list-text="3.2.3.">
                                                        <p >The
                                                            Actual Value of a document (which term shall include any item of no commercial value
                                                            which is transported hereunder) shall be ascertained by reference to its cost of
                                                            preparation or replacement, reconstruction or reconstitution value at the time and place
                                                            of shipment whichever is less.</p>
                                                    </li>
                                                    <li data-list-text="3.2.4.">
                                                        <p >The
                                                            Actual Value of a parcel (which term shall include any item of commercial value which is
                                                            transported hereunder) shall be ascertained by reference to its cost of repair or
                                                            replacement, resale or fair market value at the time and place of shipment, whichever is
                                                            less. In no event shall such value exceed the original cost of the article actually paid
                                                            by the Shipper plus 10%.</p>
                                                    </li>
                                                    <li data-list-text="3.2.5.">
                                                        <p >
                                                            Sender shall be responsible for the accuracy and completeness of the particulars and for
                                                            ensuring that all shipments set out adequate contact details for the Sender and intended
                                                            receiver of the shipment and are properly packed, marked and/or labelled, with their
                                                            contents so described and classified and are accompanied by any such documentation as
                                                            may (in each case) be required to make them suitable for transportation.</p>
                                                    </li>
                                                    <li data-list-text="3.2.6.">
                                                        <p >LINE
                                                            HAUL shall not be responsible for any claim or freight charges or any other costs for,
                                                            including but not limited to, any false, misrepresentation, inconsistent, misleading,
                                                            illegal, incomplete and/or complex statement pertaining to the shipment.</p>
                                                    </li>
                                                    <li data-list-text="3.2.7.">
                                                        <p >
                                                            Sender shall indemnify and hold LINE HAUL harmless from any loss or damages arising out
                                                            of Sender’s breach of Condition 3 herein and LINE HAUL shall be free from any legal
                                                            liability arising from Sender’s failure to comply with any applicable laws or
                                                            regulations.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.3.">
                                                <p >Payment</p>
                                                <ol id="l18">
                                                    <li data-list-text="3.3.1.">
                                                        <p >All
                                                            goods and documents relating to the goods shall be subject to a particular and general
                                                            lien and right of detention for monies due either in respect of such goods or any
                                                            particular or general balance or other monies due from the sender owner or consignee and
                                                            their agents to LINE HAUL, if any money due to LINE HAUL is not paid within the terms
                                                            set out and agreed or within 14 days after the notice has been given to the person from
                                                            whom the money is due that such goods are being detained and same may be dispose or
                                                            otherwise at the sole discretion of LINE HAUL and at the expense of such a person for
                                                            fees applied or towards satisfaction of such indebtedness. The sender owner or consignee
                                                            and their agents hereby waives any cross claim payment due.</p>
                                                    </li>
                                                    <li data-list-text="3.3.2.">
                                                        <p>
                                                            Payment method (Credit):</p>
                                                        <ol id="l19">
                                                            <li data-list-text="3.3.2.1.">
                                                                <p>
                                                                    Standard credit term is 30 days. Any unpaid invoices may lead to suspension of
                                                                    account after due date.</p>
                                                            </li>
                                                            <li data-list-text="3.3.2.2.">
                                                                <p>All cheques
                                                                    should be crossed and made payable to LINE HAUL SDN BHD.</p>
                                                            </li>
                                                            <li data-list-text="3.3.2.3.">
                                                                <p>Overdue Interest
                                                                    at the rate of 1.5% per month will be charged on all overdue amounts.</p>
                                                            </li>
                                                            <li data-list-text="3.3.2.4.">
                                                                <p>
                                                                    LINE HAUL Express Bank Details: Malayan Banking Berhad (Account No.: 5127 5451
                                                                    2862)</p>
                                                            </li>
                                                            <li data-list-text="3.3.2.5.">
                                                                <p><a
                                                                    href="mailto:bcc@lineclearexpress.com" class="a" target="_blank">Email
                                                                    bank-in slip to </a><a href="mailto:bcc@lineclearexpress.com"
                                                                        target="_blank">bcc@lineclearexpress.com</a></p>
                                                                <p><br /></p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.4.">
                                                <p >Non fixed goods price and/or
                                                    fluctuating price</p>
                                                <ol id="l20">
                                                    <li data-list-text="3.4.1.">
                                                        <p >LINE
                                                            HAUL shall not be liable in any manner whatsoever for lost and/or damage of all
                                                            shipments containing item(s) that have fluctuating price tag and/or items with,
                                                            including but not limited to, value of sentimental, artistic, antique or anything thing
                                                            similar in nature such as heirlooms, paintings, second-hand bundle goods, drawing,
                                                            antiques and jewellery.</p>
                                                    </li>
                                                    <li data-list-text="3.4.2.">
                                                        <p >For
                                                            avoidance of doubt, items that are unable to have a determined / conclusive price based
                                                            on LINE HAUL’s sole discretion are excluded and forms part of Condition 3.3.1 above.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.5.">
                                                <p >Dangerous and prohibited item
                                                </p>
                                                <ol id="l21">
                                                    <li data-list-text="3.5.1.">
                                                        <p
                                                        >
                                                            LINE HAUL is not a common carrier hence reserves the right to refuse the carriage or
                                                            transportation of any class of goods at its absolute discretion. LINE HAUL shall not
                                                            carry nor accept the followingitems (not exhaustive nor limited to) for courier
                                                            delivery: cash (in any currency) cash cheques, blank or endorsed cheques, negotiable
                                                            instruments or securities (share certificates, bonds, etc.), gold and silver bullion,
                                                            coin, dust, cyanides, precipitates or any form of un-coined gold and precious and
                                                            semi-precious stones including commercial carbons or industrial diamonds, antiques,
                                                            livestock or plants, identity cards, passports, hazardous, combustible or explosive
                                                            materials, prohibited drugs liquor, tobacco, firearms or any goods or materials that are
                                                            prohibited.</p>
                                                    </li>
                                                    <li data-list-text="3.5.2.">
                                                        <p >LINE
                                                            HAUL also shall not accept any shipment if it is classified as hazardous material,
                                                            dangerous goods, prohibited or restricted articles by IATA (International Air Transport
                                                            Association), ICAO (International Civil Aviation Organization) or any shipment that is
                                                            prohibited by any other local or foreign regulation or laws.</p>
                                                    </li>
                                                    <li data-list-text="3.5.3.">
                                                        <p >LINE
                                                            HAUL reserves the right to refuse deliveries if it finds or discovers any dangerous or
                                                            prohibited items in the contents of the package(s) and/or part of it. Please refer to
                                                            https://lineclearexpress.com/ for the list of dangerous and prohibited items</p>
                                                    </li>
                                                    <li data-list-text="3.5.4.">
                                                        <p >LINE
                                                            HAUL is not liable in any way whatsoever for any direct and/or consequential claims in
                                                            damages, lost and/or delay of shipment if the item is deferred, detained, withheld,
                                                            returned to Sender, or was not processed because it contains or consists of dangerous
                                                            goods or prohibited postings, regardless of whether the item was intentionally or
                                                            unintentionally sent by the Sender or received by LINE HAUL.</p>
                                                    </li>
                                                    <li data-list-text="3.5.5.">
                                                        <p >Once
                                                            LINE HAUL has discovered any odour items, perishable items, prohibited items and/or
                                                            dangerous goods in the shipment(s), which makes it impossible to fulfil the conditions
                                                            of transit, LINE HAUL will evaluate the items’ condition and shall retain the right to
                                                            dispose it accordingly without further reference to the Sender and the intended
                                                            receiver.</p>
                                                    </li>
                                                    <li data-list-text="3.5.6.">
                                                        <p >LINE
                                                            HAUL reserves the right to amend or change the classes of goods or items which are
                                                            categorised as Dangerous Goods or Prohibited Items at any time without prior notice.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.6.">
                                                <p >Restricted or controlled
                                                    delivery item</p>
                                                <ol id="l22">
                                                    <li data-list-text="3.6.1.">
                                                        <p >
                                                            Sender is responsible to ensure all the necessary or required approvals, licenses,
                                                            permissions and clearances have been obtained from all relevant authorities and the
                                                            posting of restricted or controlled items have been declared and accompanied with all
                                                            original supporting documents evidencing the said approval, permission and clearance
                                                            (E.g.: Declaration Form for dangerous goods and prohibited items).</p>
                                                    </li>
                                                    <li data-list-text="3.6.2.">
                                                        <p >The
                                                            Sender is responsible to ensure that the posting is accompanied with complete supporting
                                                            documents. The Sender shall solely responsible for providing the required documentation
                                                            for customs clearance. By providing required documentation, the Sender hereby certifies
                                                            that all statements and information relating to the exportation and importation are true
                                                            and correct. LINE HAUL is not responsible for any direct or indirect costs incurred due
                                                            to Sender’s negligence if due to incomplete documents.</p>
                                                    </li>
                                                    <li data-list-text="3.6.3.">
                                                        <p >
                                                            Sender understands that LINE HAUL is subjected to security screening and clearing
                                                            procedures by the local custom and/or police and/or relevant authorities in accordance
                                                            with local law, and that civil and criminal penalties, including forfeiture and sale,
                                                            may be imposed for making false or fraudulent statements or consigning explosives or
                                                            explosive devices without lawful authority. If any penalty, fine or compound is imposed,
                                                            Sender shall be fully responsible for all the costs incurred. Sender shall indemnify and
                                                            hold LINE HAUL harmless from and against all liabilities, cost, charges or legal actions
                                                            as a result of the Sender’s non-compliance with this Clause.</p>
                                                    </li>
                                                    <li data-list-text="3.6.4.">
                                                        <p>
                                                            The making and packing of the Shippers’ documents or goods for transportation is the
                                                            Shipper’s sole responsibility including the placing of the documents or goods in any
                                                            container which may be supplied to the Shipper by LINE HAUL accepts no responsibility
                                                            for loss or damage to documents or goods caused by inadequate, inappropriate or
                                                            defective packaging</p>
                                                    </li>
                                                    <li data-list-text="3.6.5.">
                                                        <p >LINE
                                                            HAUL does not provide a protective service for the transportation of perishable
                                                            commodities or commodities requiring protection from heat or cold. Such commodities
                                                            susceptible to change of temperature will be accepted for transportation solely at the
                                                            Sender risk for any damage arising from the transportation.</p>
                                                    </li>
                                                    <li data-list-text="3.6.6.">
                                                        <p>LINE
                                                            HAUL shall not be liable for any direct or indirect costs and/or damages or lost on the
                                                            aforesaid items if the appearance/ surface of the package or label is not damaged.</p>
                                                    </li>
                                                    <li data-list-text="3.6.7.">
                                                        <p >LINE
                                                            HAUL shall not be responsible for any costs of posting, direct or indirect spoilage,
                                                            metamorphism, go bad, perished, damages, partial lost, regardless of whether the item
                                                            was sent by the Sender, accepted or received by LINE HAUL, intentionally or
                                                            unintentionally. LINE HAUL shall only be liable for total loss of the parcel if it is
                                                            proven that such lost was caused by the LINE HAULdirectly.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.7.">
                                                <p >Sender’s
                                                    responsibilities</p>
                                                <ol id="l23">
                                                    <li data-list-text="3.7.1.">
                                                        <p >If a
                                                            shipment is refused by the receiver, leaks or is damaged, the shipment will be return to
                                                            the Sender if possible. If the Sender refuses to accept the returned shipment or it
                                                            cannot be returned because of leakage, or damage due to faulty packaging, the Sender
                                                            shall be responsible for cost of transportation and/or disposal of the same.</p>
                                                    </li>
                                                    <li data-list-text="3.7.2.">
                                                        <p>
                                                            Sender shall be responsible for any events that causes late delivery, damage,
                                                            miss-delivery, non-delivery caused by incomplete or wrong receiver information.</p>
                                                    </li>
                                                    <li data-list-text="3.7.3.">
                                                        <p >
                                                            Sender shall hold full responsibility on shipment packaging, and any claim of damage,
                                                            missing pieces, miss-delivery or non-delivery shipment due to packaging issues are not
                                                            claimable, the same shall be concluded as act of negligence from the Sender side due to
                                                            non-compliance with Condition 3.1.2 herein</p>
                                                    </li>
                                                    <li data-list-text="3.7.4.">
                                                        <p >The
                                                            Sender shall also be responsible for posting of any fraudulent, imitation, counterfeit,
                                                            stolen, pilferage or illegal goods including but not limited to phones, watches, branded
                                                            items, jewelleries or other items sent.</p>
                                                    </li>
                                                    <li data-list-text="3.7.5.">
                                                        <p >LINE
                                                            HAUL hereby disclaims all liabilities and shall not be responsible for any costs of
                                                            posting, direct or indirect damages or lost on the aforesaid items, nor being part of
                                                            any fraudulent/scam/illegal/criminal activities, regardless of whether the item was sent
                                                            by the Sender or received by LINE HAUL, intentionally or unintentionally.</p>
                                                    </li>
                                                    <li data-list-text="3.7.6.">
                                                        <p >If
                                                            it is proven that the goods delivered consists of the nature stipulated in Clause 3.4,
                                                            LINE HAUL shall cooperate with the government or other relevant agency in providing the
                                                            necessary information and cooperation in investigations and shall not be liable for any
                                                            direct or indirect costs and/or damages or lost on the aforesaid items. The Sender will
                                                            be blacklisted or blocked from any claims/opening of merchant account/transactions with
                                                            LINE HAUL.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.8.">
                                                <p >Custom
                                                    clearance</p>
                                                <ol id="l24">
                                                    <li data-list-text="3.8.1.">
                                                        <p >All
                                                            shipments that crossed international borders must be cleared through customs. The Sender
                                                            is responsible for making sure goods are shipped in compliance with all relevant customs
                                                            regulatory (including but not limited to Customs Act 1967) requirements.</p>
                                                    </li>
                                                    <li data-list-text="3.8.2.">
                                                        <p>The
                                                            Sender warrants that each article in any shipment accurately described in waybill and
                                                            the Sender shall attach the relevant documents to meet the export or air freight
                                                            requirements.</p>
                                                    </li>
                                                    <li data-list-text="3.8.3.">
                                                        <p>LINE
                                                            HAUL shall not be responsible for the delay in the performance of its obligations as
                                                            transit time might be affected due to customs clearance procedure.</p>
                                                    </li>
                                                    <li data-list-text="3.8.4.">
                                                        <p >LINE
                                                            HAUL shall not be liable for or in respect of any loss or damage suffered by the Shipper
                                                            howsoever caused or arising and without limiting the generality of the foregoing,
                                                            whether caused or arising by reason or on account of loss or damage to goods, missed
                                                            delivery or non-delivery, delay in delivery, concealed damage, deterioration,
                                                            contamination, evaporation, cancellations or delays in scheduled air flights, in customs
                                                            procedures or any reason at all.</p>
                                                    </li>
                                                    <li data-list-text="3.8.5.">
                                                        <p >The
                                                            Shipper and consignees and / or their agents, if any shall be liable for any duty, tax,
                                                            impost or outlays of whatsoever nature levied by the authorities of any port or place
                                                            for or in connection with the goods and for any payments, fines, expenses, loss or
                                                            damage incurred or sustained by LINE HAUL in connection herewith. The Shipper warrants
                                                            that each article in any Shipment is accuratelydescribed and Shipper shall provide the
                                                            relevant documents to meet the export or importing</p>
                                                        <p
                                                        >
                                                            requirements. The Shipper appoints LINE HAUL or one appointed by LINE HAUL as Shipper’s
                                                            agent solely for performance of customer clearance.</p>
                                                    </li>
                                                    <li data-list-text="3.8.6.">
                                                        <p >LINE
                                                            HAUL shall not act as the representative for the Sender or the intended receiver in any
                                                            dealings with the custom or any relevant authorities that detained or seized the shipped
                                                            items; Sender and/or the intended receiver shall be fully responsible to liaise the
                                                            custom or any relevant authorities at the moment such detainment or suspension of the
                                                            shipment take place.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.9.">
                                                <p >Duty and
                                                    Taxes</p>
                                                <ol id="l25">
                                                    <li data-list-text="3.9.1.">
                                                        <p
                                                        >
                                                            In order to complete clearance of certain items through customs, LINE HAUL may disburse
                                                            duties and taxes as assessed by customs officials on behalf of the payer and assess a
                                                            surcharge for doing so, and the same shall be in accordance with all applicable and
                                                            relevant laws, including but not limited to the Customs Act 1967. For all shipments,
                                                            LINE HAUL may contact the payer and request confirmation of reimbursement arrangements
                                                            as a condition of clearance and delivery, and at our solediscretion, require payment of
                                                            duties and taxes to be made by the payer before the release of the shipment to the
                                                            receiver.</p>
                                                    </li>
                                                    <li data-list-text="3.9.2.">
                                                        <p >The
                                                            Shipper shall bear and pay all duties, taxes, fines, imposts, expenses and losses
                                                            (including without prejudice to the generality of the foregoing, charges for any
                                                            additional Carriage undertaken) incurred or suffered by reason thereof or by reason of
                                                            any illegal, incorrect or insufficient marking, numbering or addressing of the Shipment
                                                            and comply with but not limited to any imposed at any time before or during the Carriage
                                                            relating to anti-terrorism measures of custom, port and other authorities and Shipper
                                                            warrants LINE HAUL that the description and particulars including, but not limited to,
                                                            weight, content, measure quantity, quality, condition, marks, numbers and value are
                                                            correct.</p>
                                                    </li>
                                                    <li data-list-text="3.9.3.">
                                                        <p >If a
                                                            receiver or a third party from whom reimbursement confirmation is required refuses to
                                                            pay the duties and taxes upon request, we may contact the Sender. If the Sender refuses
                                                            to make satisfactory arrangements to reimburse LINE HAUL, the shipment may be returned
                                                            to the Sender (in which case, Sender will be responsible for both original and return
                                                            charges).</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.10.">
                                                <p >Proof of
                                                    Delivery</p>
                                                <ol id="l26">
                                                    <li data-list-text="3.10.1.">
                                                        <p>Where indirect
                                                            signature is required, LINE HAUL will obtain a signature in one of three ways:</p>
                                                        <ol id="l27">
                                                            <li data-list-text="3.10.1.1.">
                                                                <p>From someone
                                                                    at the delivery address with actual receiver instructions; or</p>
                                                            </li>
                                                            <li data-list-text="3.10.1.2.">
                                                                <p>
                                                                    From a neighbour, building manager or other person (3<span class="s4">rd</span>
                                                                    party/representative with actual receiver instructions); or</p>
                                                            </li>
                                                            <li data-list-text="3.10.1.3.">
                                                                <p>
                                                                    Where available, the receiver can sign to authorize release of the package
                                                                    without anyone present.</p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li data-list-text="3.10.2.">
                                                        <p>If
                                                            delivery cannot be completed in these ways, LINE HAUL may reattempt delivery.</p>
                                                    </li>
                                                    <li data-list-text="3.10.3.">
                                                        <p
                                                        >
                                                            Where direct signature is required, LINE HAUL will obtain a signature from someone at
                                                            the delivery address. If no one is available at the address, LINE HAUL may reattempt
                                                            delivery.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.11.">
                                                <p >Inspection of shipments and
                                                    Identification Requirement</p>
                                                <ol id="l28">
                                                    <li data-list-text="3.11.1.">
                                                        <p
                                                        >
                                                            LINE HAUL may, at LINE HAUL’s sole option, open and inspect any shipment at any time.
                                                            Nonetheless, such shipment shall also from time to time be subjected to any Governmental
                                                            authorities’ inspection (including the Royal Malaysian Customs Department) in accordance
                                                            with its duty and power conferred under relevant law and regulations.</p>
                                                    </li>
                                                    <li data-list-text="3.11.2.">
                                                        <p >LINE
                                                            HAUL reserves the right but not the obligation to inspect the goods or documents
                                                            consigned by its Customer to ensure that all such documents or goods are capable of
                                                            carriage to the countries of destination in accordance with standard operating
                                                            procedures. Customs declaration and handling methods of LINE HAUL however, does not
                                                            undertake or represent that any particular item to be carried is capable and delivery
                                                            without infringing the law of any country or state from, to or through which the item
                                                            may be carried.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.12.">
                                                <p >Pick-up</p>
                                                <ol id="l29">
                                                    <li data-list-text="3.12.1.">
                                                        <p>Pick-up
                                                            service will not be provided to or from any address not directly accessible to vehicles
                                                            or where it is impractical to operate a vehicle.</p>
                                                    </li>
                                                    <li data-list-text="3.12.2.">
                                                        <p>LINE
                                                            HAUL shall only responsible for item pick up to the Sender’s address as per written on
                                                            the waybill.</p>
                                                    </li>
                                                    <li data-list-text="3.12.3.">
                                                        <p>LINE
                                                            HAUL shall have the right to refuse or reject any shipment that contains prohibited item
                                                            as per listed in Condition 3.4 and bad packaging shipment as described in Condition 3.1.
                                                        </p>
                                                    </li>
                                                    <li data-list-text="3.12.4.">
                                                        <p>LINE HAUL shall have the
                                                            right to refuse or delay pick-up according to pick-up cut off time.</p>
                                                    </li>
                                                    <li data-list-text="3.12.5.">
                                                        <p>Sender
                                                            shall be responsible to ensure that every parcel to be picked-up are ready for LINE
                                                            HAUL’s pick-up before creating any order in LINE HAUL’s pick-up order systems.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.13.">
                                                <p>Delivery</p>
                                                <ol id="l30">
                                                    <li data-list-text="3.13.1.">
                                                        <p>LINE
                                                            HAUL shall only be responsible for delivery to the receiver&#39;s address written on the
                                                            waybill.</p>
                                                    </li>
                                                    <li data-list-text="3.13.2.">
                                                        <p >It
                                                            is sole responsibility of the Shipper to ensure that the address is correct and legibly
                                                            stated each consignment or waybill of documents or goods to enable effective delivery to
                                                            be made. LINE HAUL shall not be liable for delay in forwarding for delivery resulting
                                                            from the Shipper’s failure to comply with its obligations.</p>
                                                    </li>
                                                    <li data-list-text="3.13.3.">
                                                        <p >LINE
                                                            HAUL shall not apply where delivery or failure to deliver is due to circumstances beyond
                                                            LINE HAUL’s control such as delivery instruction or information such as P.O Box for
                                                            receiver address.</p>
                                                    </li>
                                                    <li data-list-text="3.13.4.">
                                                        <p >Any
                                                            delivery or service fee quoted by LINE HAUL is subject to service tax, value added tax
                                                            and any other applicable tax, SST or GST, duty and expenses as may be imposed by any
                                                            local or foreign customs or similar authority in respect of the carriage of the
                                                            Shipper’s Shipment and the Shipper shall be primarily liable for such payment and any
                                                            additional surcharge, fine, legal cost or loss suffered by LINE HAUL.</p>
                                                    </li>
                                                    <li data-list-text="3.13.5.">
                                                        <p >For
                                                            delayed Shipments, LINE HAUL’s liability shall only be limited up to the sum of the
                                                            courier charges paid by the Shipper provided that such delay was due to the negligence
                                                            of LINE HAUL, its servants or agents.</p>
                                                    </li>
                                                    <li data-list-text="3.13.6.">
                                                        <p >We
                                                            do not offer a restricted-delivery service and we reserve the right to make redirect
                                                            delivery and/or deliver to someone other than the person or entity names as the
                                                            receiver. For avoidance of doubt, a redirect delivery is a completed delivery to an
                                                            address or location other than the address on the waybill.</p>
                                                    </li>
                                                    <li data-list-text="3.13.7.">
                                                        <p >All
                                                            package addresses must include the complete street address and telephone number of the
                                                            receiver.</p>
                                                    </li>
                                                    <li data-list-text="3.13.8.">
                                                        <p>
                                                            Shipments to hotels, hospitals, government offices or installation, university campuses,
                                                            or other facilities that utilize a mailroom or other central receiving area will be
                                                            delivered to the central receiving area, unless otherwise authorized and approved by
                                                            LINE HAUL.</p>
                                                    </li>
                                                    <li data-list-text="3.13.9.">
                                                        <p>Once
                                                            the intended receiver has no further objection upon receiving of the item, the item
                                                            shall be considered as successfully delivered and LINE HAUL shall be released from all
                                                            obligations and shall not be responsible for any loss and/or damages afterwards.</p>
                                                    </li>
                                                    <li data-list-text="3.13.10.">
                                                        <p >If
                                                            the receiver is not present at the time of delivery, the items will be kept at LINE
                                                            HAUL’s designated branch for a certain period determined by LINE HAUL. The period during
                                                            which unclaimed items are kept is referred to as the “storage period”. LINE HAUL will
                                                            make three (3) attempts to deliver the said item to receiver or receiver may
                                                            collect/pick up the item from LINE HAUL’s designated branch within the storage period.
                                                            The shipment will be returned to Sender afterall three (3) attempts has been exhausted.
                                                            LINE HAUL shall not be responsible for any direct or indirect damages or losses to the
                                                            item or its contents arising during the storage period</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.14.">
                                                <p >Refusal
                                                    &amp; suspensions of service</p>
                                                <ol id="l31">
                                                    <li data-list-text="3.14.1.">
                                                        <p
                                                        >
                                                            In the event LINE HAUL is made aware that any shipment does not comply with any of the
                                                            listed restrictions or conditions, LINE HAUL reserves the right to refuse the said
                                                            shipment and if the said shipment is in progress, LINE HAUL may suspend and hold the
                                                            said shipment at LINE HAUL’s sole discretion.</p>
                                                    </li>
                                                    <li data-list-text="3.14.2.">
                                                        <p>
                                                            LINE HAUL also reserves the right to refuse to provide its services, among other
                                                            reasons, for any shipment that, in the sole discretion of LINE HAUL, soil, taint, or
                                                            otherwise damage to the other shipments or to LINE HAUL’s own equipment or assets, due
                                                            to the nature of its content or that is improperly or insecurely packed or wrapped.</p>
                                                    </li>
                                                    <li data-list-text="3.14.3.">
                                                        <p >LINE
                                                            HAUL reserves the right either to refuse to provide its services for any shipment to or
                                                            from any location, or to provide alternative service arrangements, when, among other
                                                            reasons, LINE HAUL, in its sole discretion, deems that it is unsafe or economically or
                                                            operationally impracticable to provide such services.</p>
                                                    </li>
                                                    <li data-list-text="3.14.4.">
                                                        <p>LINE
                                                            HAUL also reserves the right to suspend transportation of any shipment if:-</p>
                                                        <ol id="l32">
                                                            <li data-list-text="3.14.4.1.">
                                                                <p
                                                                >
                                                                    shipment and/or its content or part of the content are found not to be
                                                                    acceptable for transportation for any reason whatsoever</p>
                                                            </li>
                                                            <li data-list-text="3.14.4.2.">
                                                                <p>if LINE HAUL
                                                                    cannot effect delivery at the third attempt to do so</p>
                                                            </li>
                                                            <li data-list-text="3.14.4.3.">
                                                                <p>if the receiver
                                                                    refuses to accept delivery</p>
                                                            </li>
                                                            <li data-list-text="3.14.4.4.">
                                                                <p>if it cannot
                                                                    collect amounts due from the receiver on delivery</p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li data-list-text="3.14.5.">
                                                        <p>
                                                            The Sender shall bear the payment of all charges, calculated in accordance with the then
                                                            current LINE HAUL rates, caused by such suspension of transportation, including, but not
                                                            limited to, forwarding, disposal, or return of transportation charges as well as any
                                                            duty or tax, if applicable.</p>
                                                    </li>
                                                    <li data-list-text="3.14.6.">
                                                        <p >
                                                            Sender shall be responsible for all reasonable costs and expenses of LINE HAUL
                                                            (including storage), for such losses, taxes and customs duties as LINE HAUL may suffer
                                                            and for all claims made against LINE HAUL because a shipment does not meet any of the
                                                            restrictions or conditions listed herein or because of any refusal or suspension of
                                                            carriage or return of a shipment by LINE HAUL pursuant to these terms and conditions.
                                                        </p>
                                                    </li>
                                                    <li data-list-text="3.14.7.">
                                                        <p >LINE
                                                            HAUL shall not be held liable or responsible for the loss of or damage to any shipment,
                                                            the contents of which LINE HAUL is not authorised to accept, which LINE HAUL states it
                                                            will not accept, or which LINE HAUL has a right to refuse.</p>
                                                    </li>
                                                    <li data-list-text="3.14.8.">
                                                        <p>
                                                            Pursuant to Condition 3.14.7, in the event LINE HAUL suspends its services as set forth
                                                            in these terms and conditions, the payer of any transportation charges shall not be
                                                            entitled to a refund.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.15.">
                                                <p >Interruption
                                                    of service</p>
                                                <ol id="l33">
                                                    <li data-list-text="3.15.1.">
                                                        <p >If
                                                            LINE HAUL is unable to commence or continue with the delivery service of the Sender’s
                                                            shipment for a reason beyond its control, the Sender shall not be able to claim that
                                                            LINE HAUL has breached its agreement with the Sender, but LINE HAUL will take reasonable
                                                            steps that are reasonably practicable in the circumstances to commence or continue the
                                                            delivery.</p>
                                                    </li>
                                                    <li data-list-text="3.15.2.">
                                                        <p>LINE
                                                            HAUL shall not be liable for any failure, interruption, delay of service or any other
                                                            mattersof the nature whatsoever due to causes beyond LINE HAUL control including, but
                                                            not limited to:</p>
                                                        <ol id="l34">
                                                            <li data-list-text="3.15.2.1.">
                                                                <p>
                                                                    the unavailability or refusal of a person to accept delivery of the shipment,
                                                                </p>
                                                            </li>
                                                            <li data-list-text="3.15.2.2.">
                                                                <p>
                                                                    caused by any fraud, wilful acts, reckless acts for which the management of LINE
                                                                    HAUL has exercised reasonable diligence,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.3.">
                                                                <p>
                                                                    disease, outbreak, global health emergency, or pandemic,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.4.">
                                                                <p
                                                                >
                                                                    acts of God,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.5.">
                                                                <p>natural
                                                                    disasters,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.6.">
                                                                <p>war risks,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.7.">
                                                                <p
                                                                >
                                                                    acts of terrorism,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.8.">
                                                                <p>acts of public
                                                                    authorities acting with actual or apparent authority,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.9.">
                                                                <p
                                                                >
                                                                    acts or omissions of customs or similar authorities, authority of law,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.10.">
                                                                <p>insufficient
                                                                    information provided by a customer,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.11.">
                                                                <p>
                                                                    the application of security regulations imposed by the government or otherwise
                                                                    applicable to the shipment,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.12.">
                                                                <p>riots,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.13.">
                                                                <p
                                                                >
                                                                    a government agency hold, strikes or other labour disputes,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.14.">
                                                                <p>civil unrest,
                                                                </p>
                                                            </li>
                                                            <li data-list-text="3.15.2.15.">
                                                                <p>
                                                                    disruptions of any kind in national or local air or ground transportation
                                                                    networks (including, but not limited to, LINE HAUL transportation network),</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.16.">
                                                                <p>disruption or
                                                                    failure of communication and information systems,</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.17.">
                                                                <p
                                                                >
                                                                    adverse weather conditions</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.18.">
                                                                <p>
                                                                    A fire or robbery occurred at the LINE HAUL office or premises, although the
                                                                    company has taken appropriate safety control measures.</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.19.">
                                                                <p>
                                                                    Online scams and/or any other scamming or deceiving activities conducted where
                                                                    LINE HAUL is used to provide delivery service.</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.20.">
                                                                <p>Disruption of
                                                                    air or ground network</p>
                                                            </li>
                                                            <li data-list-text="3.15.2.21.">
                                                                <p>
                                                                    Mechanical problems to modes of transportation/machinery or material shortage
                                                                    (i.e fuel and electricity)</p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li data-list-text="3.15.3.">
                                                        <p >In
                                                            the event any of the above factors occur, LINE HAUL shall be exempted from any delay
                                                            claims, direct or indirect costs due to delays, or postponement of delivery.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.16.">
                                                <p >Claims</p>
                                                <ol id="l35">
                                                    <li data-list-text="3.16.1.">
                                                        <p >
                                                            Claim on damage/lost to the parcel or its content can only be claimed by the Sender or
                                                            the representative of the Sender who has been authorised in writing by the Sender. The
                                                            claim or lost request together with required physical and effective proof and any other
                                                            necessary documents must be completely filled up and submitted within 48 hours from the
                                                            date when the delivery should have reached the designated destination.</p>
                                                    </li>
                                                    <li data-list-text="3.16.2.">
                                                        <p >LINE
                                                            HAUL must receive notice of a claim due to damage (visible or concealed), delay
                                                            (including fresh product claims) or shortage within forty-eight (48) hours from the date
                                                            when the delivery should have reached the designated destination. Any claim which is
                                                            submitted beyond the limitation period shall be time-barred in which LINE HAUL shall
                                                            have no liability whatsoever.</p>
                                                    </li>
                                                    <li data-list-text="3.16.3.">
                                                        <p >In
                                                            the case of lost due to undelivered because they are insufficiently or incorrectly
                                                            addressed or because they are not collected or accepted by the consignee may be disposed
                                                            or returned at LINE HAUL’s option at any time, shipment shall not be deemed to be lost
                                                            until at least twenty one (21) days have elapsed since the date of lodgement of your
                                                            complaint of the undelivered item with LINE HAUL.</p>
                                                    </li>
                                                    <li data-list-text="3.16.4.">
                                                        <p >LINE
                                                            HAUL reserves the right to inspect a damaged shipment on the receiver’s premises as
                                                            wellas the right to retrieve the damaged package for inspection at a LINE HAUL facility.
                                                            When a package is picked up for inspection, a receipt for the damaged package will be
                                                            provided if requested by the receiver. LINE HAUL reserves the right to request a
                                                            self-inspection requiring proof of damage in the form of photographs of the carton,
                                                            inner packaging and damaged contents.</p>
                                                    </li>
                                                    <li data-list-text="3.16.5.">
                                                        <p>When
                                                            LINE HAUL resolves a claim by paying full value for a shipme</p>
                                                    </li>
                                                    <li data-list-text="3.16.6.">
                                                        <p>nt,
                                                            we reserve the right to pick up the package for salvage, and all rights, title to, and
                                                            interest in the package shall vest with us.</p>
                                                    </li>
                                                    <li data-list-text="3.16.7.">
                                                        <p >LINE
                                                            HAUL’s total liability under any circumstances whatsoever shall not exceed <b>Ringgit
                                                                Malaysia Two Hundred (RM200.00) </b>only per Shipment or the cost of replacement,
                                                            whichever is lower. For the purpose of determining the amount of LINE HAUL’s liability
                                                            under this clause, valueof a document of the goods shall be ascertained by reference to
                                                            their replacement or constitution valueat the time and place of shipment without
                                                            reference to their commercial utility to the Sender and/or other items of consequential
                                                            loss. Instances where a higher value has been declared in which case the shipment shall
                                                            be insured by the Sender and the Sender’s sole remedy shall be from the proceeds of the
                                                            insurance policy.</p>
                                                    </li>
                                                    <li data-list-text="3.16.8.">
                                                        <p >
                                                            Non-cooperation by the Sender such as incomplete submission of documents, breach of the
                                                            forty-eight (48) hours deadline, non-respond from Sender shall be deemed prejudicial
                                                            LINE HAUL and LINE HAUL reserves the rights to reject and end the claim request.</p>
                                                    </li>
                                                    <li data-list-text="3.16.9.">
                                                        <p >The
                                                            claimant has the burden to prove that he/she/it have suffered the loss or damage. LINE
                                                            HAUL reserves the right to refuse any claim if the claimant is unable to verify or
                                                            provide sufficient evidence that the loss or damage occurred under LINE HAUL&#39;s
                                                            control.</p>
                                                    </li>
                                                    <li data-list-text="3.16.10.">
                                                        <p>
                                                            Insured items will only be claimable under its respective insurance with complete
                                                            compliance with terms and conditions of insurance company as well as those listed
                                                            herein.</p>
                                                    </li>
                                                    <li data-list-text="3.16.11.">
                                                        <p>The
                                                            claimant shall bear all costs and expenses in returning the damaged (or in the event of
                                                            lost item recovered later) parcel(s) to LINE HAUL and LINE HAUL shall not be reimbursed
                                                            any expenses incurred by the claimant in connection with the claim request process.</p>
                                                    </li>
                                                    <li data-list-text="3.16.12.">
                                                        <p>
                                                            Claim will be based on partial claim or full claim, on case to case basis and based on
                                                            customer difference dispute scenario.</p>
                                                    </li>
                                                    <li data-list-text="3.16.13.">
                                                        <p >Upon
                                                            full investigation conducted by LINE HAUL and in the event LINE HAUL decided there
                                                            should be rejection of the claim request, such decision shall be final and conclusive.
                                                            LINE HAUL shall not be party to any further negotiation/appeal/settlement/litigation
                                                            process pertaining to the claim.</p>
                                                    </li>
                                                    <li data-list-text="3.16.14.">
                                                        <p
                                                        >
                                                            The claimant acknowledges that in no event shall LINE HAUL refund the shipping fee to
                                                            the claimant, regardless of whether the claim has been approved or rejected.</p>
                                                    </li>
                                                    <li data-list-text="3.16.15.">
                                                        <p >No
                                                            claim shall be entertained until all outstanding amount due to LINE HAUL from the Sender
                                                            have been fully settled. The amount of any such claim shall not be set off any amount
                                                            owing to LINE HAUL.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.17.">
                                                <p >Insurance
                                                </p>
                                                <ol id="l36">
                                                    <li data-list-text="3.17.1.">
                                                        <p
                                                        >
                                                            The Sender may purchase insurance from LINE HAUL to insure his/her/it own goods at a
                                                            rate of 0.5% of the parcel actual cost or value. The maximum coverage limit of the
                                                            insurance shall be capped at RM10,000.00</p>
                                                    </li>
                                                    <li data-list-text="3.17.2.">
                                                        <p >
                                                            Sender is responsible to ensure that the insurance is purchased for posting out any item
                                                            with correct value as per purchase invoice.</p>
                                                    </li>
                                                    <li data-list-text="3.17.3.">
                                                        <p >
                                                            There is no obligation from LINE HAUL to recommend a shipment to be insured and Sender,
                                                            at Sender’s own risk, shall procure any additional insurance coverage at own cost.</p>
                                                    </li>
                                                    <li data-list-text="3.17.4.">
                                                        <p >
                                                            Insurance coverage of such procured does not serve as a guarantee that the shipment is
                                                            free from damage or guarantee of compensation; such insurance coverage shall only effect
                                                            / vary the total amount of compensation.</p>
                                                    </li>
                                                    <li data-list-text="3.17.5.">
                                                        <p >The
                                                            Sender who purchases the insurance from LINE HAUL must completely and accurately
                                                            declares the contents and value of the insured item.</p>
                                                    </li>
                                                    <li data-list-text="3.17.6.">
                                                        <p>Any
                                                            damage or loss to the content or part of the item so insured by the insurance shall be
                                                            limited to any value determined by LINE HAUL and/or by the LINE HAUL’s insurer.</p>
                                                    </li>
                                                    <li data-list-text="3.17.7.">
                                                        <p>
                                                            Insurance coverage does not cover indirect loss or damage, or loss or damage caused by
                                                            delay.</p>
                                                    </li>
                                                    <li data-list-text="3.17.8.">
                                                        <p >LINE
                                                            HAUL makes no representation nor warranty as to the appropriateness and adequacy of any
                                                            insurance coverage that may be purchased from our panel.</p>
                                                    </li>
                                                    <li data-list-text="3.17.9.">
                                                        <p >LINE
                                                            HAUL’s shipment charges do not include insurance cover for Shipments in transit and LINE
                                                            HAUL is under no obligation to provide such insurance coverage. The Shipper will arrange
                                                            insurance for their Shipment for the intended transit. The insurance policy shall
                                                            include a waiver of subrogation clause for the benefit of LINE HAUL.</p>
                                                    </li>
                                                    <li data-list-text="3.17.10.">
                                                        <p >If
                                                            the Shipper requires greater protection, LINE HAUL can arrange insurance for Shipper
                                                            covering the actual cash value in respect of loss of or physical damage to the Shipment,
                                                            provided the Shipper requests it via LINE HAUL systems and pays the applicable premium.
                                                        </p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.18.">
                                                <p >Governing
                                                    law</p>
                                                <ol id="l37">
                                                    <li data-list-text="3.18.1.">
                                                        <p >These
                                                            terms and conditions shall be subject to and construed in accordance with the laws of
                                                            Malaysia. The parties hereby irrevocably submit to the exclusive jurisdiction of the
                                                            courts of Malaysia.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.19.">
                                                <p >Disclaimer</p>
                                                <ol id="l38">
                                                    <li data-list-text="3.19.1.">
                                                        <p
                                                        >
                                                            This disclaimer, set out in these terms and conditions, does not attempt or purport to
                                                            exclude liability arising under statute if, and to the extent, such liability cannot be
                                                            lawfully excluded.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.20.">
                                                <p >Liability Not Assumed</p>
                                                <ol id="l39">
                                                    <li data-list-text="3.20.1.">
                                                        <p >
                                                            Sender’s violation of any of the terms and conditions contained in these terms and
                                                            conditions, as amended or supplemented, or on a waybill, including, but not limited to,
                                                            the improper of insufficient packing, securing, marking and addressing of shipments.</p>
                                                    </li>
                                                    <li data-list-text="3.20.2.">
                                                        <p>LINE
                                                            HAUL compliance or noncompliance with any request to intercept a shipment in transit,
                                                            orto prevent delivery.</p>
                                                    </li>
                                                    <li data-list-text="3.20.3.">
                                                        <p>LINE
                                                            HAUL compliance or noncompliance with verbal or written delivery instructions from the
                                                            Sender, receiver or persons claiming to represent the Sender or receiver.</p>
                                                    </li>
                                                    <li data-list-text="3.20.4.">
                                                        <p>LINE
                                                            HAUL inability or failure to complete a delivery, or a delay to any delivery, due to
                                                            acts or omissions of customs or any other regulatory agencies.</p>
                                                    </li>
                                                    <li data-list-text="3.20.5.">
                                                        <p >
                                                            Sender’s failure to ship goods in packaging approved by LINE HAUL prior to shipment
                                                            where such prior approval is recommended or required.</p>
                                                    </li>
                                                    <li data-list-text="3.20.6.">
                                                        <p>The
                                                            shipment of goods or commodities that could be damaged by exposure to heat or cold,
                                                            including, but not limited to, the shipments of plants material, emu eggs or live
                                                            aquaculture.</p>
                                                    </li>
                                                    <li data-list-text="3.20.7.">
                                                        <p >LINE
                                                            HAUL shall not under any circumstances be liable for loss or damage resulting from or
                                                            attributed to any quotations, statement, representation, or information, whether oral or
                                                            in writing howsoever, whosesoever or to whomsoever made or given by or on behalf of LINE
                                                            HAUL or by any servant, employee or agent of LINE HAUL as to the classification of or
                                                            liability for amount scale or rate of customs duty, excise duty or other impost or tax
                                                            applicable to any goods or property are such</p>
                                                        <p
                                                        >
                                                            at LINE HAUL shall commit any breach of any Act Of Parliament regulation or Ordinance or
                                                            other law made in respect of the same.</p>
                                                    </li>
                                                    <li data-list-text="3.20.8.">
                                                        <p >
                                                            Whenever LINE HAUL undertakes or arranges transport storage or any other services it
                                                            shall be authorized to entrust the goods or arrangements to third parties, by any route
                                                            and procedure and by any courier or successive and according to any handling, storage
                                                            and transportation methods as LINE HAUL thinks fit on the latter’s contractual
                                                            conditions. Ultimately LINE HAUL reserves the right NOT to carry the Shipper’s documents
                                                            or goods</p>
                                                        <p><br /></p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.21.">
                                                <p >Limits of Liability</p>
                                                <ol id="l40">
                                                    <li data-list-text="3.21.1.">
                                                        <p
                                                        >
                                                            For non-insured shipments, LINE HAUL shall only be liable for the direct losses for
                                                            damaged or lost items, but is limited to the following :</p>
                                                        <ol id="l41">
                                                            <li data-list-text="3.21.1.1.">
                                                                <p
                                                                >
                                                                    The actual amount of loss or damage based on the actual value/costs of the
                                                                    contents as stated on purchase invoice of damaged or lost items or a maximum of <b>Ringgit Malaysia Two Hundred (RM200.00) only </b>for shipments, whichever is
                                                                    lower.</p>
                                                            </li>
                                                            <li data-list-text="3.21.1.2.">
                                                                <p
                                                                >
                                                                    For Domestic and International (Singapore, Brunei, and Thailand only) shipment,
                                                                    the amount of the <b>compensation value for high risk </b>item such as mobile
                                                                    phones, notebook/computer, any electronic devices, software, cosmetics and any
                                                                    health product is subject to <b>excess of 2% or a maximum of RM800.00 or
                                                                        whichever higher</b>.</p>
                                                            </li>
                                                        </ol>
                                                    </li>
                                                    <li data-list-text="3.21.2.">
                                                        <p >The
                                                            Sender has to insure his/her/its own item with insurance if the total value of item
                                                            exceeded the limit of compensation covered by LINE HAUL. The Sender shall proceed with
                                                            his/her/its own claim through insurance if the Sender have insured his/her/its own items
                                                            and the above Condition 3.21.1 shall not be applicable for insured items.</p>
                                                    </li>
                                                    <li data-list-text="3.21.3.">
                                                        <p>If
                                                            the parcel is partially damage, then it will be compensated based on the level of damage
                                                            or lost. If the damaged parcel can be properly functioned upon repair, then it will be
                                                            compensated based on the repair value, or the actual value of the parcel, whichever is
                                                            lower.</p>
                                                    </li>
                                                    <li data-list-text="3.21.4.">
                                                        <p >
                                                            Sender hereby undertakes that Sender shall not seek to recover and shall not be entitled
                                                            to recover from LINE HAUL or to be indemnified by LINE HAUL in respect of any direct,
                                                            indirect or consequential loss or damage or against any claims, proceedings, costs,
                                                            demands, liabilities and expenses whatsoever sustained, incurred or paid by Sender in
                                                            respect of any use of LINE HAUL service.</p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.22.">
                                                <p >Return</p>
                                                <ol id="l42">
                                                    <li data-list-text="3.22.1.">
                                                        <p
                                                        >
                                                            With respect to LINE HAUL’s return policy, notwithstanding any payment instructions that
                                                            are given to LINE HAUL, the party that initiates a return shipment transaction with LINE
                                                            HAUL is ultimately liable for, will be billed for, and agrees to pay, all charges and
                                                            fees which LINE HAUL have disbursed, regardless of any payment instructions to the
                                                            contrary, if the Sender or any third party fails or refuses to pay.</p>
                                                    </li>
                                                    <li data-list-text="3.22.2.">
                                                        <p >If
                                                            any return parcel is requested by the Sender due to uncontrollable circumstances of LINE
                                                            HAUL, the parcel will be returned with a new waybill generated and additional charges
                                                            may be applied. However, if request is due to controllable circumstances of LINE HAUL,
                                                            no additional charge will be imposed.</p>
                                                    </li>
                                                    <li data-list-text="3.22.3.">
                                                        <p >If
                                                            the Sender requests for return parcel, additional charges will be imposed regardless of
                                                            controllable or uncontrollable circumstances, additional charges must bared by the
                                                            Sender. A new waybill will be generated for return parcel.</p>
                                                        <p><br /></p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.23.">
                                                <p >3<span class="s4">rd</span> party</p>
                                                <ol id="l43">
                                                    <li data-list-text="3.23.1.">
                                                        <p >LINE
                                                            HAUL provides pickup and delivery to a variety of locations, and we may subcontract the
                                                            services, in whole or in part, to any third parties, at our sole discretion without
                                                            prior notice to you.</p>
                                                    </li>
                                                    <li data-list-text="3.23.2.">
                                                        <p >
                                                            Whenever LINE HAUL undertakes or arranges transport storage or any other services, it is
                                                            authorized to entrust the goods or arrangements to third parties, by any route and
                                                            procedure and by any courier or successive and according to any handling, storage and
                                                            transportation methods as LINE HAUL thinks fit on the latter’s contractual conditions.
                                                            Ultimately, LINE HAUL reserves the right NOT to deliver the Sender’s documents or goods.
                                                        </p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="3.24.">
                                                <p >LINE HAUL
                                                    shall not under any circumstances be liable for loss or damage resulting from or attributed to
                                                    any quotations, statement, representation, or information, whether oral or in writing howsoever,
                                                    whosesoever or to whomsoever made or given by or on behalf of LINE HAUL or by any servant,
                                                    employee</p>
                                                <p
                                                >
                                                    or agent of LINE HAUL as to the classification of or liability for amount scale or rate of
                                                    customs duty, excise duty or other impost or tax applicable to any goods or property.</p>
                                                <p><br /></p>
                                            </li>
                                            <li data-list-text="3.25.">
                                                <p >Dispute Resolution</p>
                                                <p >Any dispute,
                                                    controversy or claim arising out of relating to these terms and conditions which cannot be
                                                    resolved by negotiation between the parties within seven (7) business days of either party
                                                    giving notice to the other party that a dispute has arisen shall be submitted to mediation
                                                    pursuant to the Asian Institute of Alternative Dispute Resolution (“<b>AIADR</b>”) Mediation
                                                    Rules and failing settlement of that dispute by mediation within seven (7) business days
                                                    thereafter, the dispute shall be submitted by any party for final resolution by arbitration as
                                                    administered by the AIADR in accordance with the AIADR Ad Hoc Arbitration Rules on Appointment,
                                                    Case Administration and Financial Management whereby</p>
                                                <p><br /></p>
                                                <ol id="l44">
                                                    <li data-list-text="(a)">
                                                        <p>The number of arbitrator
                                                            shall be one;</p>
                                                    </li>
                                                    <li data-list-text="(b)">
                                                        <p>The seat
                                                            of the arbitration shall be Kuala Lumpur;</p>
                                                    </li>
                                                    <li data-list-text="(c)">
                                                        <p>The language to be used
                                                            in the arbitral proceedings shall be English.</p>
                                                    </li>
                                                </ol>
                                                <p><br /></p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text="4.">
                                        <p className='fw-bold'>Limitation of service</p>
                                        <ol id="l45">
                                            <li data-list-text="4.1.">
                                                <p>LINE HAUL have
                                                    a list of outskirt delivery areas which LINE HAUL may not be able to deliver or might cause
                                                    delay for delivery due to transportation availability, locality area, and islands.</p>
                                                    <p className='mx-4'>Refer <u>linehaul.com</u> for outskirt delivery/remote area.</p>               
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <div className='mx-5 fw-bold font-12'>All shipment
                                    are bounded by LINE HAUL. These terms and conditions herein may be updated by LINE HAUL from time to time
                                    without prior notice to you and such updated terms and conditions shall bind you in respect to the use of the
                                    services, as from the date specified by LINE HAUL or such date as the terms andconditions shall take effect,
                                    whichever is the earliest</div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  customReactSelectMenuStyle,
  LATPATTERN_5,
  LONGPATTERN_5,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  LAT_ERR_MSG,
  LONG_ERR_MSG,
  NUMBER_NINE_DECIMAL,
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  NUMBER_REGEX,
  PHONE_NUM,
  NAME_REGEX,
} from "../../Helper/Constants";
import Select from "react-select";
import { CHARTERED_RATE_WEIGHT_ID } from "../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { cleanNewLine } from "../../Helper/CommonMethods";
import GetSvgIcon from "../../../images/svglist";

const addrLabel = {
  org: { label: "Origin", value: "origin" },
  dest: { label: "Destination", value: "destination" },
};

const initStopObj = {
  address: "",
  city: "",
  state: "",
  postcode: "",
};

const DynamicRouteAddEdit = ({
  handleClose,
  config,
  saveDynamicRoute,
  routeState,
  stopList,
  custList,
  stateCityOpt,
  setStateForCity,
  setCitiesForState,
  chRateType,
  fetchCharteredRate,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors, isDirty },
  } = useForm();

  const {
    fields: stops,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "stops",
  });
  const { fields: rateTarget } = useFieldArray({
    control,
    name: "rateTarget",
  });
  const isPresent = (argStr) => {
    if (!!argStr && typeof argStr === "string" && argStr.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const validateAddressData = (argAddrObj) => {
    if (
      isPresent(argAddrObj.address) &&
      isPresent(argAddrObj.city) &&
      isPresent(argAddrObj.state) &&
      isPresent(argAddrObj.postcode)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = (data) => {
    data.routeType = 31;
    const isRateByWgt = data.rateType === CHARTERED_RATE_WEIGHT_ID;
    const rateCode = data.routeCode + "-" + data.rateType;
    const copyRateTarget = data.rateTarget.map((target, index) => {
      target.rateCode = rateCode;
      return target;
    });
    const isBaseRateUpd = routeState?.rateValue !== data?.rateValue;
    const isTargetRateUpd =
      JSON.stringify(routeState?.rateTarget) !== JSON.stringify(copyRateTarget);
    let rate = {
      //rateCode: data.rateType,
      userId: getUserInfo("userId"),
      operationType: 0,
      baseRates: [
        {
          rateId: data?.rateId,
          rateTypeId: data.rateType,
          custId: data.customerId,
          baseCharge: isRateByWgt ? 0 : data.rateValue,
          unitPrice: isRateByWgt ? data.rateValue : 0,
          rateCode: rateCode,
          isUpdated: isBaseRateUpd || isTargetRateUpd,
        },
      ],
      targetRates: copyRateTarget,
    };
    data.rate = rate;
    let retStops = [];
    data.origin.routeCode = data.routeCode;
    retStops.push(data.origin);
    data.stops.forEach((stp, index) => {
      if (validateAddressData(stp)) {
        stp.routeCode = data.routeCode;
        retStops.push(stp);
      }
    });
    data.destination.routeCode = data.routeCode;
    retStops.push(data.destination);
    data.stops = retStops;
    console.log("Processed Data: ", data);
    saveDynamicRoute(data);
    handleClose();
  };

  const onError = (err) => {
    console.error(err);
  };
  useEffect(() => {
    reset(routeState);
  }, [routeState]);

  const watchRouteId = watch("id");
  const watchIsActive = watch("active");

  // const validateOriginDestStops = (value, inpKey) => {
  //   let isValid = true;
  //   originDestStopsKey.map((key, index) => {
  //     if (
  //       key !== inpKey &&
  //       value !== "" &&
  //       value !== 0 &&
  //       watch(key) === value
  //     ) {
  //       isValid = false;
  //       return isValid;
  //     }
  //   });
  //   return isValid;
  // };

  const updateState = (key, opt) => {
    if (!!opt) {
      setValue(key, opt.value);
      let newStateArr = setStateForCity(key, opt.value);
      setValue(key, newStateArr[0].value);
    } else {
      setValue(key, "");
      setStateForCity(key, null);
    }
  };

  const addrBlock = (
    key,
    secKey,
    value,
    itemKey,
    index,
    isRequired,
    isArray
  ) => (
    <div key={itemKey} className="border-container w-100">
      <div className="border-text theme-label  fw-bold font-18">{value}</div>
      <Row>
        <Col>
          <label className="form-label theme-label">
            Address
            {isRequired && <span className="text-danger">&#9733;</span>}
          </label>
          <textarea
            className="form-control"
            type="text"
            {...register(`${key}.address`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              validate: (v) =>
                BLACK_SPCL_CHARS.test(v)
                  ? `Please check for invalid characters ${BLACK_SPCL_CHARS_STR}`
                  : true,
              setValueAs: (v) => cleanNewLine(v),
            })}
            maxLength={500}
            disabled={+watchRouteId > 0}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.address
            : errors?.[key]?.address) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.address?.message
                  : errors?.[key]?.address?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form-label theme-label">
            City
            {isRequired && <span className="text-danger">&#9733;</span>}
          </label>
          <Controller
            name={`${key}.city`}
            control={control}
            rules={{
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              min: { value: 1, message: REQUIRED_ERR_MSG },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={stateCityOpt?.[`${key}CityArr`]}
                value={stateCityOpt?.[`${key}CityArr`]?.find(
                  (el) => el.value === value
                )}
                onChange={
                  (opt) => {
                    onChange(!!opt ? opt.value : opt);
                    updateState(`${key}StateArr`, opt);
                  }
                  // !!opt ? onChange(opt.value) : onChange(0)
                }
                placeholder="Select City"
                isSearchable={true}
                isClearable={true}
                className="form-control p-0"
                isDisabled={+watchRouteId > 0}
                menuPortalTarget={document.body}
                styles={customReactSelectMenuStyle}
              />
            )}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.city
            : errors?.[key]?.city) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.city?.message
                  : errors?.[key]?.city?.message}
              </span>
            </div>
          )}
        </Col>
        <Col>
          <label className="form-label theme-label">
            State
            {isRequired && <span className="text-danger">&#9733;</span>}
          </label>
          <Controller
            name={`${key}.state`}
            control={control}
            rules={{
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              min: { value: 1, message: REQUIRED_ERR_MSG },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={stateCityOpt?.[`${key}StateArr`]}
                value={stateCityOpt?.[`${key}StateArr`]?.find(
                  (el) => el.value === value
                )}
                onChange={
                  (opt) => {
                    onChange(!!opt ? opt.value : opt);
                    if (!!opt) {
                      setCitiesForState(`${key}CityArr`, opt.value);
                    } else {
                      setCitiesForState(`${key}CityArr`, null);
                    }
                  }
                  // !!opt ? onChange(opt.value) : onChange(0)
                }
                placeholder="Select State"
                isSearchable={true}
                isClearable={true}
                className="form-control p-0"
                isDisabled={+watchRouteId > 0}
                menuPortalTarget={document.body}
                styles={customReactSelectMenuStyle}
              />
            )}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.state
            : errors?.[key]?.state) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.state?.message
                  : errors?.[key]?.state?.message}
              </span>
            </div>
          )}
        </Col>
        <Col>
          <label className="form-label theme-label">
            Postcode
            {isRequired && <span className="text-danger">&#9733;</span>}
            <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
            <span className="form-label font-12 text-danger p-2" htmlFor="">
              Maxlength 10
            </span>
          </label>
          <input
            name={`${key}.postcode`}
            className="form-control"
            type="number"
            {...register(`${key}.postcode`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              pattern: {
                value: NUMBER_REGEX,
                message: "Field accepts only numbers!!",
              },
              maxLength: {
                value: 10,
                message: "Maximun 10 characters allowed",
              },
            })}
            disabled={+watchRouteId > 0}
            maxLength={10}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.postcode
            : errors?.[key]?.postcode) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.postcode?.message
                  : errors?.[key]?.postcode?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form-label theme-label">
            Latitude
            {isRequired && <span className="text-danger">&#9733;</span>}
            <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
            <span className="form-label font-12 text-danger p-2" htmlFor="">
              Value range from -90.00000 to 90.00000
            </span>
          </label>
          <input
            name="latitude"
            className="form-control"
            type="number"
            disabled={watchIsActive === false}
            {...register(`${key}.latitude`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              pattern: {
                value: LATPATTERN_5,
                message: LAT_ERR_MSG,
              },
            })}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.latitude
            : errors?.[key]?.latitude) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.latitude?.message
                  : errors?.[key]?.latitude?.message}
              </span>
            </div>
          )}
        </Col>
        <Col>
          <label className="form-label theme-label">
            Longitude
            {isRequired && <span className="text-danger">&#9733;</span>}
            <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
            <span className="form-label font-12 text-danger p-2" htmlFor="">
              Value range from -180.00000 to 180.00000
            </span>
          </label>
          <input
            name="longitude"
            className="form-control"
            type="number"
            disabled={watchIsActive === false}
            {...register(`${key}.longitude`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              pattern: {
                value: LONGPATTERN_5,
                message: LONG_ERR_MSG,
              },
            })}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.longitude
            : errors?.[key]?.longitude) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.longitude?.message
                  : errors?.[key]?.longitude?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <label className="form-label theme-label">Contact Name</label>
          {isRequired && <span className="text-danger">&#9733;</span>}
          <input
            name="name"
            className="form-control"
            type="text"
            disabled={watchIsActive === false}
            {...register(`${key}.name`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              pattern: {
                value: NAME_REGEX,
                message: "Enter Valid Name",
              },
            })}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.name
            : errors?.[key]?.name) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.name?.message
                  : errors?.[key]?.name?.message}
              </span>
            </div>
          )}
        </Col>
        <Col>
          <label className="form-label theme-label">Contact Phone</label>
          {isRequired && <span className="text-danger">&#9733;</span>}
          <input
            name="phone"
            className="form-control"
            type="number"
            disabled={watchIsActive === false}
            {...register(`${key}.phone`, {
              required: {
                value: isRequired,
                message: REQUIRED_ERR_MSG,
              },
              pattern: {
                value: PHONE_NUM,
                message: "Enter Valid Contact No!!!",
              },
            })}
          />
          {(isArray && !!errors?.[secKey]
            ? errors?.[secKey][index]?.phone
            : errors?.[key]?.phone) && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888;{" "}
                {isArray && !!errors?.[secKey]
                  ? errors?.[secKey][index]?.phone?.message
                  : errors?.[key]?.phone?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );

  // const setRateDropdown = (argCustId) => {
  //   fetchCharteredRate(argCustId);
  // };

  return (
    <Modal show={config?.show} onHide={handleClose} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title className="section-title">{config?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <label className="form-label theme-label">
              Choose Customer <span className="text-danger">&#9733;</span>
            </label>
            <Controller
              name={"customerId"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
                min: { value: 1, message: REQUIRED_ERR_MSG },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={custList}
                  value={custList.find((el) => el.value === value)}
                  onChange={(opt) => {
                    if (!!opt) {
                      // setRateDropdown(opt.value);
                      onChange(opt.value);
                    } else {
                      // setRateDropdown(0);
                      onChange(0);
                    }
                  }}
                  placeholder="Select Customer"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  isDisabled={+watchRouteId > 0}
                />
              )}
            />
            {errors?.customerId && (
              <div>
                <span className="text-danger fw-bold font-12">
                  &#9888; {errors?.customerId?.message}
                </span>
              </div>
            )}
          </Col>

          <Col>
            <label className="form-label theme-label">
              Route Code <span className="text-danger">&#9733;</span>
            </label>
            <input
              className="form-control"
              type="text"
              {...register("routeCode", {
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
              })}
              disabled={+watchRouteId > 0}
            />
            {errors?.routeCode && (
              <div>
                <span className="text-danger fw-bold font-12">
                  &#9888; {errors?.routeCode?.message}
                </span>
              </div>
            )}
          </Col>
          <Col>
            <label className="form-label theme-label">Remarks</label>
            <input
              className="form-control"
              type="text"
              {...register("remarks")}
              // disabled={+watchRouteId > 0}
            />
          </Col>
          {/* <Col>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Valid From{""} <span className="text-danger">&#9733;</span>
            </label>
            <div>
              <Controller
                name="validFrom"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    placeholderText="Valid From"
                    className="form-control"
                    wrapperClassName="w-100"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={value ? new Date(value) : value}
                    minDate={new Date()}
                    maxDate={
                      !!watchValidTo ? new Date(watchValidTo) : watchValidTo
                    }
                    onChange={(d) =>
                      onChange(!!d ? moment(d).format(DATE_FORMAT_SERVER) : d)
                    }
                  />
                )}
              />
              {errors?.validFrom && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.validFrom?.message}
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Valid To{""} <span className="text-danger">&#9733;</span>
            </label>
            <div>
              <Controller
                name="validTo"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    placeholderText="Valid To"
                    className="form-control"
                    wrapperClassName="w-100"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={value ? new Date(value) : value}
                    minDate={
                      !!watchValidFrom ? new Date(watchValidFrom) : new Date()
                    }
                    onChange={(d) =>
                      onChange(!!d ? moment(d).format("YYYY-MM-DD") : d)
                    }
                  />
                )}
              />
              {errors?.validTo && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.validTo?.message}
                  </span>
                </div>
              )}
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col className=" mt-2">
            {addrBlock(
              addrLabel.org.value,
              addrLabel.org.value,
              addrLabel.org.label,
              0,
              0,
              true,
              false
            )}
          </Col>
          <Col className=" mt-2">
            {addrBlock(
              addrLabel.dest.value,
              addrLabel.dest.value,
              addrLabel.dest.label,
              99,
              99,
              true,
              false
            )}
          </Col>
        </Row>
        <Row>
          {!!stops &&
            Array.isArray(stops) &&
            stops.map(
              (item, index) =>
                (item.stopSeq !== 0 || item.stopSeq !== 99) && (
                  <Col key={item.id} md={6} className="mt-2">
                    {addrBlock(
                      `stops.${index}`,
                      "stops",
                      "Stop " + item.stopSeq,
                      item.id,
                      index,
                      false,
                      true
                    )}
                  </Col>
                )
            )}
        </Row>
        <Row>
          <p className=" mt-2 font-18 fw-bold form-label theme-label border-bottom">
            Rate Configuration
          </p>
          <Row>
            <Col>
              <label className="form-label theme-label">
                Billing Type
                <span className="text-danger">&#9733;</span>
              </label>
              <Controller
                name="rateType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                  min: { value: 1, message: REQUIRED_ERR_MSG },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={chRateType}
                    value={chRateType.find((el) => el.value === value)}
                    onChange={(opt) =>
                      !!opt ? onChange(opt.value) : onChange(0)
                    }
                    placeholder="Select Billing Type"
                    isSearchable={true}
                    isClearable={true}
                    className="form-control p-0"
                    isDisabled={watchIsActive === false}
                  />
                )}
              />
              {errors?.rateType && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.rateType?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col>
              <label className="form-label theme-label">
                Base Charge/Unit Price{" "}
                <span className="text-danger">&#9733;</span>
                <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
                <span className="form-label font-12 text-danger p-2" htmlFor="">
                  maxlength 11 with a value of two decimals
                </span>
              </label>

              <input
                className="form-control"
                type="number"
                name="rateValue"
                {...register("rateValue", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                  pattern: {
                    value: NUMBER_NINE_DECIMAL,
                    message: PATTERN_ERR_MSG,
                  },
                  validate: (v) => (v > 0 ? true : REQUIRED_ERR_MSG),
                })}
                disabled={watchIsActive === false}
              />
              {errors?.rateValue && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.rateValue?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {rateTarget.map((target, index) => (
              <Col>
                <label className="form-label theme-label">
                  {target.serviceText}
                  <span className="ms-1">{GetSvgIcon("ToolTip")} </span>
                  <span
                    className="form-label font-12 text-danger p-2"
                    htmlFor=""
                  >
                    maxlength 11 with a value of two decimals
                  </span>
                </label>

                <input
                  className="form-control"
                  name={`rateTarget.${index}.serviceUnitPrice`}
                  type="number"
                  {...register(`rateTarget.${index}.serviceUnitPrice`, {
                    pattern: {
                      value: NUMBER_NINE_DECIMAL,
                      message: PATTERN_ERR_MSG,
                    },
                  })}
                  disabled={watchIsActive === false}
                />
                {!!errors?.rateTarget && errors?.rateTarget[index] && (
                  <div>
                    <span className="text-danger fw-bold font-12">
                      &#9888;{" "}
                      {errors?.rateTarget[index]?.serviceUnitPrice?.message}
                    </span>
                  </div>
                )}
              </Col>
            ))}
          </Row>
        </Row>

        {/* This will dynamically add or remove the stops
        <Row>
          <Col>
            <p className=" mt-2 font-18 fw-bold form-label theme-label border-bottom">
              Add Stops
            </p>
            <Row className="mt-1 orm-label theme-label">
              <Col className="col-md-1 text-center">
                Seq No. <span className="text-danger">&#9733;</span>
              </Col>
              <Col className="col-md-4 text-center">
                Address <span className="text-danger">&#9733;</span>
              </Col>
              <Col className="col-md-2 text-center">
                City <span className="text-danger">&#9733;</span>
              </Col>
              <Col className="col-md-2 text-center">
                State <span className="text-danger">&#9733;</span>
              </Col>
              <Col className="col-md-2 text-center">
                Postcode <span className="text-danger">&#9733;</span>
              </Col>
              <Col className="col-md-1 text-end">
                <span
                  className="text-success"
                  onClick={() => append(Object.assign({}, initStopObj))}
                >
                  {GetSvgIcon("PlusCircleNoFill")}
                </span>
              </Col>
            </Row>
            {stops.map((stp, index) => (
              <Row className="mt-1">
                <Col className="col-md-1 text-center">{index + 1}</Col>
                <Col className="col-md-4">
                  <textarea
                    className="form-control"
                    type="text"
                    {...register(`stops.${index}.address`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                    maxLength={500}
                    disabled={+watchRouteId > 0}
                  />
                  {errors?.[`stops.${index}.address`] && (
                    <div>
                      <span className="text-danger fw-bold font-12">
                        &#9888; {errors?.[`stops.${index}.address`]?.message}
                      </span>
                    </div>
                  )}
                </Col>
                <Col className="col-md-2">
                  <Controller
                    name={`stops.${index}.city`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      min: { value: 1, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={stateCityOpt?.[`stops.${index}CityArr`]}
                        value={stateCityOpt?.[`stops.${index}CityArr`]?.find(
                          (el) => el.value === value
                        )}
                        onChange={
                          (opt) => {
                            onChange(!!opt ? opt.value : opt);
                            updateState(`stops.${index}StateArr`, opt);
                          }
                          // !!opt ? onChange(opt.value) : onChange(0)
                        }
                        placeholder="Select City"
                        isSearchable={true}
                        isClearable={true}
                        className="form-control p-0"
                        isDisabled={+watchRouteId > 0}
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors?.[`stops.${index}.city`] && (
                    <div>
                      <span className="text-danger fw-bold font-12">
                        &#9888; {errors?.[`stops.${index}.city`]?.message}
                      </span>
                    </div>
                  )}
                </Col>
                <Col className="col-md-2">
                  <Controller
                    name={`stops.${index}.state`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      min: { value: 1, message: REQUIRED_ERR_MSG },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={stateCityOpt?.[`stops.${index}StateArr`]}
                        value={stateCityOpt?.[`stops.${index}StateArr`]?.find(
                          (el) => el.value === value
                        )}
                        onChange={
                          (opt) => {
                            onChange(!!opt ? opt.value : opt);
                            if (!!opt) {
                              setCitiesForState("O", opt.value);
                            } else {
                              setCitiesForState("O", null);
                            }
                          }
                          // !!opt ? onChange(opt.value) : onChange(0)
                        }
                        placeholder="Select State"
                        isSearchable={true}
                        isClearable={true}
                        className="form-control p-0"
                        isDisabled={+watchRouteId > 0}
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors?.[`stops.${index}.state`] && (
                    <div>
                      <span className="text-danger fw-bold font-12">
                        &#9888; {errors?.[`stops.${index}.state`]?.message}
                      </span>
                    </div>
                  )}
                </Col>
                <Col className="col-md-2">
                  <input
                    name={`stops.${index}.postcode`}
                    className="form-control"
                    type="text"
                    {...register(`stops.${index}.postcode`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                    disabled={+watchRouteId > 0}
                  />
                  {errors?.[`stops.${index}.postcode`] && (
                    <div>
                      <span className="text-danger fw-bold font-12">
                        &#9888; {errors?.[`stops.${index}.postcode`]?.message}
                      </span>
                    </div>
                  )}
                </Col>
                <Col className="col-md-1 text-end text-danger">
                  <span onClick={() => remove(index)}>
                    {GetSvgIcon("DashCircleNoFill")}
                  </span>
                </Col>
              </Row>
            ))}
          </Col>
        </Row> */}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="primary"
          className="theme-violet"
          disabled={(+watchRouteId > 0 && !isDirty) || watchIsActive === false}
          onClick={handleSubmit(onSubmit, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DynamicRouteAddEdit;

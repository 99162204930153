import React from "react";
import Layout from "../Layout/Layout";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { Table } from "react-bootstrap";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  CLAIM_ATTACHMENT,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  PTRN_ERR_MSG,
  REQUIRED_ERR_MSG,
  SERVER_ERR_MSG,
  TEXT_AREA_REGEX,
} from "../Helper/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toBase64 } from "../Helper/CommonMethods";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { useEffect } from "react";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initFileObj = {
  photo: null,
  photoFileName: "Upload File",
  photoFileType: CLAIM_ATTACHMENT.GENERAL,
  photoBucketUrl: null,
};
const initChrono = {
  id: 0,
  incidentId: 0,
  attchId: 0,
  eventDate: null,
  errEventDt: false,
  eventTime: "",
  eventDesc: "",
  errEventDesc: false,
  attachment: initFileObj,
};
const initIncident = {
  id: 0,
  claimId: 0,
  rootCause: "",
  errRootCause: false,
  correctiveAction: "",
  errCorrAct: false,
  preventiveAction: "",
  errPrevAct: false,
  attachments: [],
  chronoArr: [],
  remarks: "",
  errRemarks: false,
};

const ClaimAck = () => {
  const [chronoArr, setChronoArr] = useState([
    JSON.parse(JSON.stringify(initChrono)),
  ]);
  const [incident, setIncident] = useState(
    JSON.parse(JSON.stringify(initIncident))
  );
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [attachments, setAttachments] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [genDocList, setGenDocList] = useState([]);

  useEffect(() => {
    if (!!state && !!state?.claimId) {
      fetchIncidentReport();
    }
  }, []);

  const fetchIncidentReport = async () => {
    setSpinner(true);
    const result = await CallApiGet(
      "LoadIncidentReport?claimId=" + state?.claimId
    );
    if (
      result.respCode === 200 &&
      !!result.respData &&
      Array.isArray(result.respData) &&
      result.respData.length > 0
    ) {
      let data = result.respData[0];
      const inciData = {
        id: data.id,
        claimId: data.claimId,
        rootCause: data.rootCause,
        correctiveAction: data.correctiveAction,
        preventiveAction: data.preventiveAction,
        remarks: data.remarks,
        errRootCause: data?.errRootCause,
        errCorrAct: data?.errCorrAct,
        errPrevAct: data?.errPrevAct,
        errRemarks: data?.errRemarks,
      };
      let argAttchs = JSON.parse(data.attachments);
      let argChronArr = JSON.parse(data.chronoarr);
      if (!!argChronArr && Array.isArray(argChronArr)) {
        argChronArr = argChronArr.map((element) => {
          if (!!element.attachment) {
            element.attachment = JSON.parse(element.attachment);
            return element;
          }
          return element;
        });
      }
      if (!!argAttchs && Array.isArray(argAttchs) && argAttchs.length > 0) {
        setAttachments(
          argAttchs?.filter((a) => a.photoFileType !== CLAIM_ATTACHMENT.GENERAL)
        );
        setGenDocList(
          argAttchs?.filter((a) => a.photoFileType === CLAIM_ATTACHMENT.GENERAL)
        );
      }
      setChronoArr(argChronArr);
      setIncident(inciData);
    }
    setSpinner(false);
  };

  const append = () => {
    const tempChrono = JSON.parse(JSON.stringify(initChrono));
    if (incident.id > 0) tempChrono.incidentId = incident.id;

    setChronoArr([...chronoArr, tempChrono]);
  };

  const remove = (index) => {
    const arr = [...chronoArr];
    arr.splice(index, 1);
    setChronoArr(arr);
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    if (alertConfig?.isSuccess) {
      navigate("/claim");
    }
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const handleChronData = (index, key, data, errKey) => {
    let arr = [...chronoArr];
    arr[index][key] = data;
    if (!!errKey) {
      arr[index][errKey] = false;
    }
    setChronoArr([...arr]);
  };

  const handleSummData = (key, value, errKey) => {
    let tempInciData = { ...incident };
    tempInciData[key] = value;
    tempInciData[errKey] = false;
    // setIncident({ ...incident, [key]: value });
    setIncident(tempInciData);
  };

  const handleAttch = async (event, attachementType, argIndex, argCode) => {
    const file = event.target.files;
    const photo = await toBase64(file[0]);
    const argFileName = file[0].name;
    var ext = argFileName.substring(argFileName.lastIndexOf(".") + 1);
    const fileName =
      argCode +
      "_" +
      moment().format("YYYYMMDD") +
      moment().milliseconds() +
      "." +
      ext;
    if (!!photo) {
      let fileObj = {
        attchId: 0,
        photo: photo,
        photoFileName: fileName,
        photoFileType: attachementType,
        photoBucketUrl: null,
      };

      switch (argCode) {
        case "CHRON":
          let arr = [...chronoArr];
          arr[argIndex].attachment = fileObj;
          setChronoArr([...arr]);
          break;

        default:
          let attchArr = [...attachments];
          let currAtIndex = attchArr.findIndex(
            (at) => at.photoFileType === attachementType
          );
          if (currAtIndex !== -1) {
            attchArr.splice(currAtIndex, 1);
          }
          attchArr.push(fileObj);
          setAttachments([...attchArr]);
          break;
      }
    }
  };

  const validata = () => {
    let isValid = true;
    let tempChronArr = [...chronoArr];

    tempChronArr = tempChronArr.map((ele, index) => {
      if (ele.eventDate === undefined || ele.eventDate === null) {
        ele.errEventDt = true;
        isValid = false;
      }
      if (!TEXT_AREA_REGEX.test(ele.eventDesc)) {
        ele.errEventDesc = true;
        isValid = false;
      } else if (!ele.eventDesc) {
        ele.errEventDesc = true;
        isValid = false;
      }
      return ele;
    });

    let tempInciData = { ...incident };
    if (
      !!!tempInciData.rootCause ||
      !TEXT_AREA_REGEX.test(tempInciData.rootCause)
    ) {
      tempInciData.errRootCause = true;
      isValid = false;
    }
    if (
      !!!tempInciData.correctiveAction ||
      !TEXT_AREA_REGEX.test(tempInciData.correctiveAction)
    ) {
      tempInciData.errCorrAct = true;
      isValid = false;
    }
    if (
      !!!tempInciData.preventiveAction ||
      !TEXT_AREA_REGEX.test(tempInciData.preventiveAction)
    ) {
      tempInciData.errPrevAct = true;
      isValid = false;
    }
    if (
      !!!tempInciData.remarks ||
      !TEXT_AREA_REGEX.test(tempInciData.remarks)
    ) {
      tempInciData.errRemarks = true;
      isValid = false;
    }

    setIncident(tempInciData);
    setChronoArr(tempChronArr);

    return isValid;
  };

  const saveAsDraft = async (argIsAck) => {
    setSpinner(true);

    if (!validata()) {
      setSpinner(false);
      return;
    }

    let payload = incident;
    payload.claimId = !!state ? state?.claimId : 0;
    payload.claimNo = !!state ? state?.claimNo : "";
    payload.chronoArr = chronoArr;
    payload.attachments = [...attachments, ...genDocList];
    payload.isAck = argIsAck;
    const resp = await CallApiPost("SaveIncidentReport", payload);
    setSpinner(false);
    if (resp.respCode === 200) {
      showAlert({
        isSuccess: true,
        title: "SUCCESS",
        description: resp.respMessage,
      });
    } else if (resp.respCode === 409) {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: resp.respMessage,
      });
    } else if (resp.respCode === 304) {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: resp.respMessage,
      });
    } else {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    }
  };

  const renderFileName = (argAttchType) => {
    const file = attachments?.find((att) => att.photoFileType === argAttchType);
    if (!!file?.photoBucketUrl) {
      return file?.photoBucketUrl?.split("/").pop();
    } else {
      return !!file?.photoFileName ? file?.photoFileName : "Upload File";
    }
  };

  const downloadFileByType = (argType) => {
    let url = attachments?.find(
      (ele) => ele.photoFileType === argType
    )?.photoBucketUrl;
    window.open(url, "_blank");
  };

  const removeDoc = (index) => {
    let currGenDocList = [...genDocList];
    currGenDocList.splice(index, 1);
    setGenDocList([...currGenDocList]);
    // setAttachments([...currGenDocList]);
  };

  const appendDoc = () => {
    setGenDocList([...genDocList, initFileObj]);
  };

  const handelFileSelect = async (
    event,
    attachementType,
    argIndex,
    argCode
  ) => {
    try {
      setSpinner(true);
      const file = event.target.files;
      const argFileName = file[0].name;
      var ext = argFileName.substr(argFileName.lastIndexOf(".") + 1);
      const fileName =
        argCode +
        "_" +
        moment().format("YYYYMMDD") +
        +moment().milliseconds() +
        "." +
        ext;

      if (file.length > 0) {
        let photo = "";
        photo = await toBase64(file[0]);
        if (!!photo) {
          let fileObj = {
            photo: photo,
            photoFileName: fileName,
            photoFileType: attachementType,
            photoBucketUrl: null,
          };
          if (attachementType === 491) {
            let currGenDocList = [...genDocList];
            let updtDocList = currGenDocList.map((obj, index) => {
              if (argIndex === index) {
                return fileObj;
              } else {
                return obj;
              }
            });
            setGenDocList([...updtDocList]);
            // setAttachments([...updtDocList]);
          }

          setSpinner(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const downloadFileUsingURL = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={hideAlert}
    >
      <div className="d-flex flex-row justify-content-between section-title border-bottom pb-1 mb-3 mt-1">
        <span>Claim Acknowledgement</span>
      </div>

      <div className="col-lg-12">
        <div className="fw-bold mt-1 theme-violet-txt border-bottom">
          <label className="form-label fw-bold" htmlFor="">
            CHRONOLOGY OF THE CASE
          </label>
          <span className="ms-3 cursor-pointer" onClick={append}>
            {GetSvgIcon("PlusCircle")}
          </span>
        </div>
        <Table hover>
          <thead>
            <tr>
              <th scope="col">
                Date <span className="text-danger">&#9733;</span>
              </th>
              <th scope="col">Time</th>
              {/* <th scope="col">Attachment</th> */}
              <th scope="col">
                Chronology of Events{" "}
                <span className="text-danger">&#9733;</span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {chronoArr.map((chron, index) => (
              <tr key={index}>
                <td style={{ width: "15%" }}>
                  <ReactDatePicker
                    placeholderText="Select Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={
                      !!chron?.eventDate
                        ? new Date(chron?.eventDate)
                        : chron?.eventDate
                    }
                    onChange={(val) =>
                      !!val
                        ? handleChronData(
                            index,
                            "eventDate",
                            moment(val, DATE_FORMAT_MOMENT, true).format(
                              DATE_FORMAT_SERVER
                            ),
                            "errEventDt"
                          )
                        : handleChronData(index, "eventDate", val, "errEventDt")
                    }
                    showTimeSelect={false}
                    showYearDropdown
                  />
                  {chron.errEventDt && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {REQUIRED_ERR_MSG}
                      </span>
                    </div>
                  )}
                </td>
                <td style={{ width: "15%" }}>
                  <input
                    type="time"
                    className="form-control"
                    value={chron?.eventTime}
                    onChange={(e) =>
                      handleChronData(index, "eventTime", e.target.value)
                    }
                  />
                </td>
                {/* <td>
                  <div className="Upload-Photo mb-2 w-100" key={`G-${index}`}>
                    <input
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg"
                      onChange={(e) =>
                        handleAttch(e, CLAIM_ATTACHMENT.GENERAL, index, "CHRON")
                      }
                      id={`attachement-${CLAIM_ATTACHMENT.GENERAL}-${index}`}
                    />
                    <div className="uploaded-text ms-2 w-100  d-flex flex-row justify-content-between">
                      <span>
                        {GetSvgUpload({ required: false })}
                        {!!chron?.attachment?.photoBucketUrl
                          ? chron?.attachment?.photoBucketUrl.split("/").pop()
                          : !!chron?.attachment?.photoFileName
                          ? chron?.attachment?.photoFileName
                          : "Upload"}
                      </span>
                      {!!chron?.attachment?.photoBucketUrl && (
                        <span
                          onClick={() =>
                            downloadFileByUrl(chron?.attachment?.photoBucketUrl)
                          }
                          className="me-3"
                        >
                          {GetSvgIcon("BiArrowDownCircleFill")}
                        </span>
                      )}
                    </div>
                  </div>
                </td> */}
                <td>
                  <textarea
                    className="form-control"
                    value={chron?.eventDesc}
                    onChange={(e) =>
                      handleChronData(
                        index,
                        "eventDesc",
                        e.target.value,
                        "errEventDesc"
                      )
                    }
                    maxlength="250"
                  ></textarea>
                  {chron.errEventDesc && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888;{" "}
                        {!!chron.eventDesc ? PTRN_ERR_MSG : REQUIRED_ERR_MSG}
                      </span>
                    </div>
                  )}
                </td>

                {index > 0 && (
                  <td>
                    <div className="text-danger" onClick={() => remove(index)}>
                      {GetSvgIcon("DashCircleNoFill")}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="theme-violet-txt border-bottom pb-1 mb-3 mt-1">
          <label className="form-label fw-bold" htmlFor="">
            SUMMARY OF THE CASE
          </label>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 mb-2">
              <label htmlFor="" className="form-label fw-bold">
                Root Cause of Failure{" "}
                <span className="text-danger">&#9733;</span>
              </label>
              {/* <input
                type="text"
                className="form-control"
                value={incident.rootCause}
                onChange={(e) => handleSummData("rootCause", e.target.value)}
              /> */}
              <textarea
                className="form-control"
                value={incident.rootCause}
                onChange={(e) =>
                  handleSummData("rootCause", e.target.value, "errRootCause")
                }
                maxlength="200"
              ></textarea>
              {incident.errRootCause && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{" "}
                    {!!incident.rootCause ? PTRN_ERR_MSG : REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <label htmlFor="" className="form-label fw-bold">
                Corrective Action <span className="text-danger">&#9733;</span>
              </label>
              {/* <input
                type="text"
                className="form-control"
                value={incident.correctiveAction}
                onChange={(e) =>
                  handleSummData("correctiveAction", e.target.value)
                }
              /> */}
              <textarea
                className="form-control"
                value={incident.correctiveAction}
                onChange={(e) =>
                  handleSummData(
                    "correctiveAction",
                    e.target.value,
                    "errCorrAct"
                  )
                }
                maxlength="200"
              ></textarea>
              {incident.errCorrAct && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{" "}
                    {!!incident.correctiveAction
                      ? PTRN_ERR_MSG
                      : REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <label htmlFor="" className="form-label fw-bold">
                Preventive Action <span className="text-danger">&#9733;</span>
              </label>
              {/* <input
                type="text"
                className="form-control"
                value={incident.preventiveAction}
                onChange={(e) =>
                  handleSummData("preventiveAction", e.target.value)
                }
              /> */}
              <textarea
                className="form-control"
                value={incident.preventiveAction}
                onChange={(e) =>
                  handleSummData(
                    "preventiveAction",
                    e.target.value,
                    "errPrevAct"
                  )
                }
                maxlength="200"
              ></textarea>
              {incident.errPrevAct && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{" "}
                    {!!incident.preventiveAction
                      ? PTRN_ERR_MSG
                      : REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <label htmlFor="" className="form-label fw-bold">
                Remark <span className="text-danger">&#9733;</span>
              </label>
              <textarea
                className="form-control"
                value={incident?.remarks}
                onChange={(e) =>
                  handleSummData("remarks", e.target.value, "errRemarks")
                }
                maxlength="200"
              ></textarea>
              {incident.errRemarks && (
                <div>
                  <span className="badge bg-danger">
                    &#9888;{" "}
                    {!!incident.remarks ? PTRN_ERR_MSG : REQUIRED_ERR_MSG}
                  </span>
                </div>
              )}
              {/* <input
                type="text"
                className="form-control"
                value={incident?.remarks}
                onChange={(e) => handleSummData("remarks", e.target.value)}
              /> */}
            </div>
          </div>
        </div>
        <div className="theme-violet-txt border-bottom pb-1 mb-3 mt-1">
          <label className="form-label fw-bold" htmlFor="">
            REPORTS
          </label>
        </div>
        <div className="row">
          <div className="col-md-6 mb-2">
            <label htmlFor="" className="form-label fw-bold">
              Police Report
            </label>
            <div className="Upload-Photo mb-2 w-100">
              <input
                type="file"
                onChange={(e) =>
                  handleAttch(e, CLAIM_ATTACHMENT.POLICE, 0, "POLICE_RPT")
                }
              />
              <div className="uploaded-text ms-2 w-100  d-flex flex-row justify-content-between">
                <span>
                  {GetSvgUpload({ required: false })}
                  {renderFileName(CLAIM_ATTACHMENT.POLICE)}
                </span>
                {!!attachments?.find(
                  (ele) => ele.photoFileType === CLAIM_ATTACHMENT.POLICE
                )?.photoBucketUrl && (
                  <span
                    onClick={() => downloadFileByType(CLAIM_ATTACHMENT.POLICE)}
                    className="me-3"
                  >
                    {GetSvgIcon("BiArrowDownCircleFill")}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <label htmlFor="" className="form-label fw-bold">
              Incident Report
            </label>
            <div className="Upload-Photo mb-2 w-100">
              <input
                type="file"
                onChange={(e) =>
                  handleAttch(e, CLAIM_ATTACHMENT.INCIDENT, 0, "INCIDENT_RPT")
                }
              />
              <div className="uploaded-text ms-2 w-100  d-flex flex-row justify-content-between">
                <span>
                  {GetSvgUpload({ required: false })}
                  {renderFileName(CLAIM_ATTACHMENT.INCIDENT)}
                </span>
                {!!attachments?.find(
                  (ele) => ele.photoFileType === CLAIM_ATTACHMENT.INCIDENT
                )?.photoBucketUrl && (
                  <span
                    onClick={() =>
                      downloadFileByType(CLAIM_ATTACHMENT.INCIDENT)
                    }
                    className="me-3"
                  >
                    {GetSvgIcon("BiArrowDownCircleFill")}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-8 mb-2">
          <label htmlFor="" className="form-label fw-bold">
            Remark
          </label>
          <textarea
            className="form-control"
            value={incident?.remarks}
            onChange={(e) => handleSummData("remarks", e.target.value)}
          ></textarea>
        </div> */}
        <div className="col-md-6 mb-2">
          <div>
            <label className="form-label fw-bold" htmlFor="">
              Image References & Other Documents
            </label>
            <span className="ms-3 cursor-pointer" onClick={() => appendDoc()}>
              {GetSvgIcon("PlusCircle")}
            </span>
          </div>

          {!!genDocList &&
            genDocList.length > 0 &&
            genDocList.map((ele, index) => (
              <div className="d-flex mb-2" key={index}>
                <div className="col-auto Upload-Photo w-100" key={`G-${index}`}>
                  <input
                    type="file"
                    accept="application/pdf, image/jpeg, image/jpg"
                    onChange={(e) =>
                      handelFileSelect(
                        e,
                        CLAIM_ATTACHMENT.GENERAL,
                        index,
                        "GENDOC"
                      )
                    }
                    id={`attachement-${CLAIM_ATTACHMENT.GENERAL}-${index}`}
                  />
                  <div className="uploaded-text ms-2 w-100 d-flex flex-row justify-content-between mb-2 me-5">
                    <span>
                      {GetSvgUpload({ required: false })}
                      {!!ele?.photo
                        ? ele?.photoFileName
                        : !!ele?.photoBucketUrl
                        ? ele?.photoBucketUrl.split("/").pop()
                        : "Upload"}
                    </span>
                    {!!ele?.photoBucketUrl && (
                      <span
                        onClick={() =>
                          downloadFileUsingURL(ele?.photoBucketUrl)
                        }
                        className="me-3"
                      >
                        {GetSvgIcon("BiArrowDownCircleFill")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-auto align-self-center">
                  <button
                    type="button"
                    className="border-0 bg-white cursor-pointer"
                    onClick={() => removeDoc(index)}
                  >
                    {GetSvgIcon("Removecircle")}
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div className="clearfix"></div>
        <div className="w-100 text-end mt-2">
          <button
            type="button"
            className="btn btn-default border me-1"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary theme-violet me-1"
            onClick={() => saveAsDraft(false)}
          >
            Save As Draft
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => saveAsDraft(true)}
          >
            Submit
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default ClaimAck;

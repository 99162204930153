import React from "react";
import { getUserInfo } from "./AuthUtils";
import { Navigate } from "react-router-dom";

export default function AuthProvider(props) {
  const { argFeatCode, children } = props;
  const userPermissions = JSON.parse(getUserInfo("userPermissions"));

  let isAuthorized;
  if (Array.isArray(userPermissions) && !!argFeatCode) {
    const featArr = userPermissions.filter(
      (up) => up.featureCode === argFeatCode
    );
    if (featArr.length > 0) {
      let permitted = featArr.filter(f => f.allowedRead);
      isAuthorized = (permitted && permitted.length > 0)
    } else isAuthorized = false;
  } else {
    isAuthorized = false;
  }

  return isAuthorized ? (
    children
  ) : (
    <Navigate to="/unauthorized" replace={true} />
  );
}

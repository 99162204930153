import { useEffect, useRef, useState } from "react";
import { CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import { useNavigate } from "react-router-dom";
import {
  BTN_TYPE_ADD,
  BTN_TYPE_EDIT,
  NET_ERR_MSG,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import Popup from "../../Layout/Popup";
import { Tooltip } from "react-tooltip";
import GetSvgIcon from "../../../images/svglist";
import UploadRateChart from "./UploadRateChart";
import Spinner from "../../Layout/Spinner";
import RateCodeAddEdit from "./RateCodeAddEdit";
import { initRateCode } from "./initRateCode";
import RateCodeList from "./RateCodeList";

const Service = () => {
  const [saveSuccess] = useState(false);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [service, setService] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const navigate = useNavigate();

  const ratecolumns = [
    {
      name: "Rate Code",
      selector: (row) => row.rateCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Description",
      selector: (row) => row.rateCodeDesc,
      sortable: true,
      filterable: false,
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <a
            data-tooltip-id={"rate-chat-" + row.rateCodeId}
            // href={"/rateChartDtls?slaId=" + row.rateCodeId}
            href={`/rateChartDtls?slaId=${row.rateCodeId}&rateCode=${row.rateCode}`}
          >
            {GetSvgIcon("PaymentDetails")}
          </a>
          <Tooltip
            id={"rate-chat-" + row.rateCodeId}
            content={"View Rate Chart " + row.rateCode}
            place="left"
          />
        </>
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <div
            className=""
            onClick={() => {
              setBtnType(BTN_TYPE_EDIT);
              setRateCode(row);
            }}
            data-bs-toggle="modal"
            data-bs-target="#button-modal"
            data-tooltip-id={"edit-" + row.rateCode}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip
            id={"edit-" + row.rateCode}
            content={"Edit " + row.rateCode}
            place="left"
          />
        </>
      ),
    },
    {
      name: "",
      width: "5%",
      cell: (row) => (
        <>
          <div
            className=""
            onClick={() =>
              setService({ id: row.rateCodeId, name: row.rateCode })
            }
            data-bs-toggle="modal"
            data-bs-target="#upload-file-modal"
            data-tooltip-id={"up-" + row.rateCodeId}
          >
            {GetSvgIcon("BiFileArrowUpFill")}
          </div>
          <Tooltip
            id={"up-" + row.rateCodeId}
            content={"Upload Rate Chart for " + row.rateCode}
            place="left"
          />
        </>
      ),
    },
  ];

  const [btnType, setBtnType] = useState("");
  const [rateCode, setRateCode] = useState(initRateCode);
  const [rateCodeRows, setRateCodeRows] = useState([]);

  useEffect(() => {
    getAllRestCodeList();
  }, []);

  const updateSuccessMsg = () => { };

  const getAllRestCodeList = async () => {
    setShow(true);
    rateCode["rateCode"] = "";
    rateCode["rateCodeId"] = 0;
    rateCode["rateCodeDesc"] = "";
    const data = await CallApiPost("ListRateCode", rateCode);
    setShowAlert(false);
    switch (data.respCode) {
      case 200:
      case 0:
        setRateCodeRows(data.respData);
        break;
      case 401:
        setRateCodeRows([]);
        break;
      case 99:
        openPopup(false, "Error!", NET_ERR_MSG);
        break;
      default:
        openPopup(false, "Error!", SERVER_ERR_MSG);
        break;
    }
    setShow(false);
  };

  const clearData = () => {
    setRateCode(initRateCode);
  };
  const onSuccess = () => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription("Successfully saved !!!");
    modalRef.current.click();
  };

  const openPopup = (boolData, title, desc) => {
    setIsSuccess(boolData);
    setTitle(title);
    setDescription(desc);
    getAllRestCodeList();
    modalRef.current.click();
  };

  const onUploadErr = (resp) => {
    switch (resp?.respCode) {
      case 400:
        openPopup(false, "Error!", resp.respMessage);
        break;
      case 401:
        openPopup(false, "UNAUTHORIZED", "Sorry, you don't have the write permission!!");
        break;
      case 500:
        openPopup(false, "Error!", SERVER_ERR_MSG);
        break;
      default:
        openPopup(false, "Error!", resp.respMessage);
        break;
    }
  };

  return (
    <>
      <Header />

      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Rate Chart</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-end">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#button-modal"
                    onClick={() => setBtnType(BTN_TYPE_ADD)}
                  >
                    {GetSvgIcon("PlusCircle")} ADD RATE CODE
                  </button>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>

            <RateCodeList
              columns={ratecolumns}
              data={rateCodeRows}
              setBtnType={setBtnType}
            />
            <RateCodeAddEdit
              rateCode={rateCode}
              clearData={clearData}
              saveSuccess={saveSuccess}
              btnType={btnType}
              openPopup={openPopup}
            />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={updateSuccessMsg}
      />
      <UploadRateChart
        onSuccess={onSuccess}
        onUploadErr={onUploadErr}
        service={service}
      />
      <Spinner show={show} />
    </>
  );
};

export default Service;

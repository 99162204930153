import React from "react";
import UploadDataFile from "../../Helper/DataUploadUtil";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DD_MM_YYYY_REGEX,
  ROUTE_CODE_LENGTH,
  SERVER_ERR_MSG,
  TIME_24_REGEX,
  TWO_DIGIT_DAY,
  YYYY_MM_REGEX,
} from "../../Helper/Constants";
import moment from "moment";
import { driverPlanColumns } from "../../RoutePlan/UploadPan";
import { cleanNewLine } from "../../Helper/CommonMethods";

const chartSchCol = [
  "Route Code",
  "Plan From (dd-mm-yyyy)",
  "Plan To (dd-mm-yyyy)",
  "Truck No",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
  "Origin ETA",
  "Origin ETD",
  "Stop 1 ETA",
  "Stop 1 ETD",
  "Stop 2 ETA",
  "Stop 2 ETD",
  "Destination ETA",
  "Destination ETD",
];
const UploadCharteredSchedule = (props) => {
  const { openPopup, uploadType } = props;
  const templateChartSchedule = require("../../../assets/templates/CharteredPlanUploadTemplate.xlsx");
  const templateDriver = require(`../../../assets/templates/RoutePlanDriverUploadTemplate.xlsx`);

  const validateCharteredData = (fileData) => {
    let isValid = true;
    fileData.forEach((row) => {
      let errMsg = [];
      chartSchCol.forEach((col) => {
        validateEachCol(col, errMsg, row);
      });
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const formatCharteredRouteData = (fileData) => {
    let fileDataOut = {};
    const planArr = [];
    let routeValidArr = [];
    fileData.map((row, index) => {
      let rowOut = {};
      let routeValidObj = {};
      let stopsArr = [];
      routeValidObj.RowIndex = index;
      rowOut.RowIndex = index;
      rowOut.ErrorMessage = "";

      rowOut.routeCode = row["Route Code"];
      routeValidObj.RouteCode = row["Route Code"];
      rowOut.validFrom = row["Plan From (dd-mm-yyyy)"];
      rowOut.validTo = row["Plan To (dd-mm-yyyy)"];
      rowOut.truckNo = row["Truck No"];
      let ptrnWeekDay = row["Mon"] === "Y" || row["Mon"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Tue"] === "Y" || row["Tue"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Wed"] === "Y" || row["Wed"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Thu"] === "Y" || row["Thu"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Fri"] === "Y" || row["Fri"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Sat"] === "Y" || row["Sat"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Sun"] === "Y" || row["Sun"] === "y" ? "1" : "0";
      rowOut.patternMonSun = ptrnWeekDay;

      let itinList = [];
      let itinObjO = {};
      itinObjO.stopSeq = 0;
      itinObjO.stopSbuCode = "O";
      itinObjO.sta = row["Origin ETA"];
      itinObjO.std = row["Origin ETD"];
      itinList.push(itinObjO);

      if (row["Stop 1 ETA"]) {
        let itin1 = {};
        itin1.stopSeq = 1;
        itin1.stopSbuCode = "S1";
        itin1.sta = row["Stop 1 ETA"];
        itin1.std = row["Stop 1 ETD"];
        itinList.push(itin1);
        stopsArr.push("1-" + String("S1").trim());
      }
      if (row["Stop 2 ETA"]) {
        let itin2 = {};
        itin2.stopSeq = 2;
        itin2.stopSbuCode = "S2";
        itin2.sta = row["Stop 2 ETA"];
        itin2.std = row["Stop 2 ETD"];
        itinList.push(itin2);
        stopsArr.push("2-" + String("S2").trim());
      }
      // if (row["Stop3"]) {
      //   let itin3 = {};
      //   itin3.stopSeq = 3;
      //   itin3.stopSbuCode = row["Stop3"];
      //   itin3.sta = row["ETA3"];
      //   itin3.std = row["ETD3"];
      //   itinList.push(itin3);
      //   // stopsArr.push("3-" + String(row["Stop3"]).trim());
      // }
      let itinObjD = {};
      itinObjD.stopSeq = 99;
      itinObjD.stopSbuCode = "D";
      itinObjD.sta = row["Destination ETA"];
      itinObjD.std = row["Destination ETD"];
      itinList.push(itinObjD);
      rowOut.itinerary = itinList;
      routeValidObj.StopsStr = stopsArr.toString();
      routeValidArr.push(routeValidObj);
      planArr.push(rowOut);
      return row;
    });
    fileDataOut.planArr = planArr;
    fileDataOut.routeValidArr = routeValidArr;
    return fileDataOut;
  };

  const validateDate = (errMsg, col, row) => {
    if (!DD_MM_YYYY_REGEX.test(row[col])) {
      errMsg.push(col + ":Invalid date format ");
    } else {
      const isValidDate = moment(row[col], DATE_FORMAT_MOMENT, true).isValid();
      if (!isValidDate) {
        errMsg.push(col + " contains invalid date");
      } else {
        const today = moment().startOf("day");
        const inpDt = moment(row[col], DATE_FORMAT_MOMENT, true).format(
          DATE_FORMAT_SERVER
        );
        if (moment(inpDt).isBefore(today)) {
          errMsg.push(col + " is in the past ");
        }
      }
    }

    if (col === "Plan To (dd-mm-yyyy)") {
      const from = moment(
        row["Plan From (dd-mm-yyyy)"],
        DATE_FORMAT_MOMENT,
        true
      );
      const to = moment(row["Plan To (dd-mm-yyyy)"], DATE_FORMAT_MOMENT, true);
      if (to.isBefore(from)) {
        errMsg.push("Plan To is before Plan From");
      }
    }
  };
  const matchStopAndTime = (row, col, errMsg) => {
    switch (col) {
      case "Origin ETA":
        if (!!row["Origin ETA"]) {
          errMsg.push("Origin ETA is mandatory");
        }
        break;
      case "Origin ETD":
        if (!!row["Stop2"]) {
          errMsg.push("Origin ETD is mandatory");
        }
        break;
      case "Destination ETA":
        if (!!row["Destination ETA"]) {
          errMsg.push("Destination ETA is mandatory");
        }
        break;
      case "Destination ETD":
        if (!!row["ETAD"] && !!row["ETDD"]) {
          errMsg.push("Destination ETD is mandatory");
        }
        break;
      default:
        break;
    }
  };

  const validateEachCol = (col, errMsg, row) => {
    if (!!row[col]) {
      switch (col) {
        case "Plan From (dd-mm-yyyy)":
        case "Plan To (dd-mm-yyyy)":
          validateDate(errMsg, col, row);
          break;
        case "Mon":
        case "Tue":
        case "Wed":
        case "Thu":
        case "Fri":
        case "Sat":
        case "Sun":
          if (!(row[col] === "Y" || row[col] === "y")) {
            errMsg.push(col + ": Please pass 'Y' or 'y' or no value ");
          }
          break;
        case "Origin ETA":
        case "Origin ETD":
        case "Stop 1 ETA":
        case "Stop 1 ETD":
        case "Stop 2 ETA":
        case "Stop 2 ETD":
        // case "ETA3":
        // case "ETD3":
        case "Destination ETA":
        case "Destination ETD":
          if (!TIME_24_REGEX.test(row[col])) {
            errMsg.push(col + " is not in 24 hour time format(hh:mm) ");
          }
          break;
        // case "Route Code":
        //   if (row[col].length > 25) {
        //     errMsg.push(
        //       "Length of " +
        //         col +
        //         " cannot be greater than " +
        //         ROUTE_CODE_LENGTH
        //     );
        //   }
        //   break;
        default:
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
          break;
      }
    } else {
      switch (col) {
        case "Plan From (dd-mm-yyyy)":
        case "Plan To (dd-mm-yyyy)":
        case "Route Code":
        case "Truck No":
          errMsg.push(col + " is mandatory");
          break;
        case "Origin ETA":
        case "Origin ETD":
        case "Stop 1 ETA":
        case "Stop 1 ETD":
        case "Stop 2 ETA":
        case "Stop 2 ETD":
        // case "ETA3":
        // case "ETD3":
        case "Destination ETA":
        case "Destination ETD":
          matchStopAndTime(row, col, errMsg);
          break;
        default:
          break;
      }
    }
  };

  const validateDriverData = (fileData) => {
    let isValid = true;
    fileData.forEach((row) => {
      let errMsg = [];
      let dateDataCnt = { cnt: 0 };
      driverPlanColumns.forEach((col) => {
        validateInData(row, col, errMsg, dateDataCnt);
      });
      if (dateDataCnt.cnt < 1) {
        errMsg.push("Please fill atleast one date");
      }
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const validateInData = (row, col, errMsg, dateDataCnt) => {
    switch (col) {
      case "Year-Month(yyyy-mm)":
        if (!!row[col]) {
          if (!YYYY_MM_REGEX.test(row[col])) {
            errMsg.push(col + " Invalid Data");
          }
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Driver1":
        if (!!row[col]) {
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
        } else if (row["Driver1"] === row["Driver2"]) {
          errMsg.push(col + " can not be same as Driver 2");
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Route Code":
        if (!!row[col]) {
          // if (row[col].length > 100) {
          //   errMsg.push(
          //     "Length of " +
          //       col +
          //       " cannot be greater than " +
          //       ROUTE_CODE_LENGTH
          //   );
          // }
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Driver2":
        if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
          errMsg.push(col + " contains some invalid character ");
        } else if (row["Driver1"] === row["Driver2"]) {
          errMsg.push(col + " can not be same as Driver 1");
        }
        break;
      default:
        if (!!row[col] && row[col] !== "Y" && row[col] !== "y") {
          errMsg.push(col + " accepts Y or y or no value ");
        }
        if (!!row[col]) {
          dateDataCnt.cnt++;
        }
        break;
    }
  };

  const formatDriverData = (fileData) => {
    let fileDataOut = [];
    fileData.forEach((row, index) => {
      const yrMonth = row["Year-Month(yyyy-mm)"];

      driverPlanColumns.forEach((col) => {
        if (TWO_DIGIT_DAY.test(col) && !!row[col]) {
          const inTripDate = yrMonth + "-" + col;
          if (moment(inTripDate, DATE_FORMAT_SERVER, true).isValid()) {
            let rowOut = {};
            rowOut.rowId = index;
            rowOut.routeCode = String(row["Route Code"]).trim();
            rowOut.driver1 = !!row["Driver1"]
              ? String(row["Driver1"]).trim()
              : null;
            rowOut.driver2 = !!row["Driver2"]
              ? String(row["Driver2"]).trim()
              : null;
            rowOut.tripDate = inTripDate + " 00:00";
            fileDataOut.push(rowOut);
          }
        }
      });
    });
    return fileDataOut;
  };

  const onSuccess = () => {
    openPopup(true, "SUCCESS", "Plan uploaded successfully!!");
  };

  const onError = (resp) => {
    let msg;
    if (
      resp.respMessage === undefined ||
      resp.respMessage === null ||
      resp.respMessage === "null"
    ) {
      msg = SERVER_ERR_MSG;
    } else {
      msg = resp.respMessage;
    }
    openPopup(false, "ERROR", msg);
  };

  return (
    <>
      <div>
        <UploadDataFile
          dataUploadUrl={
            uploadType === "P"
              ? "SaveCharteredPlan"
              : "SaveCharteredRoutePlanDriver"
          }
          onSuccess={onSuccess}
          onError={onError}
          template={uploadType === "P" ? templateChartSchedule : templateDriver}
          cbValidateData={
            uploadType === "P" ? validateCharteredData : validateDriverData
          }
          cbPreUpload={
            uploadType === "P" ? formatCharteredRouteData : formatDriverData
          }
          downloadFileName={
            uploadType === "P"
              ? "CharteredPlanUploadTemplate.xlsx"
              : "RoutePlanDriverUploadTemplate.xlsx"
          }
          downloadFileServer={uploadType === "P" ? false : true}
          downloadUrl="DownloadDrExcelForPlan?param1=31"
          header={uploadType === "P" ? chartSchCol : driverPlanColumns}
        />
      </div>
    </>
  );
};

export default UploadCharteredSchedule;

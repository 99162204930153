import React, { useState } from "react";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  COUNTRY_CODES,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import { Controller } from "react-hook-form";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

const FtlBookingAddress = (props) => {
  const {
    register,
    clearErrors,
    errors,
    control,
    stateData,
    filterCity,
    senderCityData,
    filterState,
    setModalData,
    trigger,
    saveAddressBook,
    avalServices,
    watchPickup,
    sameAsSender,
    handelPickupcheck,
    pickupCityData,
    recipientCityData,
    watchDelivery,
    sameAsReciver,
    handelDeliverycheck,
    deliveryCityData,
    bookingType,
    pickupFileLable,
    delivaryFileLable,
    selectPickupDeliveryFile,
    watch,
    handelSpecialPermissionChange,
    setValue,
    watchIsEqpSelected,
    watchIsMnpSelected,
  } = props;

  const watchPickupSpecialCont = watch("specialPickup");
  const watchDeliverySpecialCont = watch("specialDelivery");
  return (
    <>
      <div className="w-100 ">
        <div className="col-md-12 mb-3">
          <div className="bg-white border border-2 rounded-2 p-2">
            <div className="subheadingtext">Sender Details</div>
            <div className="row">
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Name
                </label>
                <div>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderName", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderName"),
                    })}
                  />
                  {errors.senderName && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderName.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Phone
                </label>
                <div className="input-group">
                  <select
                    name="senderPhoneCode"
                    {...register("senderPhoneCode", {
                      onChange: () => clearErrors("senderPhoneCode"),
                    })}
                    defaultValue="+60"
                    className="form-control country-code-customize"
                  >
                    {COUNTRY_CODES.map((cc, index) => (
                      <option key={index} value={cc.value}>
                        {cc.label}
                      </option>
                    ))}
                  </select>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderPhone", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: "Incorrect Phone Number!!!",
                      },
                      onChange: () => clearErrors("senderPhone"),
                    })}
                  />
                </div>
                {errors.senderPhone && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.senderPhone.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  Sender Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("senderAddress", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    onChange: () => clearErrors("senderAddress"),
                  })}
                />
                {errors.senderAddress && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.senderAddress.message}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  State
                </label>
                <div>
                  <Controller
                    name="senderState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={stateData}
                        placeholder="Select State"
                        value={
                          value
                            ? stateData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(
                            d ? d.value : d,
                            filterCity(d ? d.value : d, "senderCity")
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderState"),
                    }}
                  />
                  {errors.senderState && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderState.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 mt-2">
                <label htmlFor="" className="form-label theme-label">
                  City
                </label>
                <div>
                  <Controller
                    name="senderCity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={senderCityData}
                        placeholder="Select City"
                        // value={value ? value.value : value}
                        value={
                          value
                            ? senderCityData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(
                            d ? d.value : d,
                            filterState(d ? d.value : d, "senderState")
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderCity"),
                    }}
                  />
                  {errors.senderCity && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderCity.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4 my-2 ">
                <label htmlFor="" className="form-label theme-label">
                  Postcode
                </label>
                <div>
                  <input
                    id=""
                    type="text"
                    className="form-control"
                    name=""
                    {...register("senderPostcode", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: PATTERN_ERR_MSG,
                      },
                      onChange: () => clearErrors("senderPostcode"),
                      // onBlur: (e) =>
                      //   getByPostCode(e.target.value),
                    })}
                  />
                  {errors.senderPostcode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.senderPostcode.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col text-end my-2">
              <button
                className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                type="button"
                onClick={() => setModalData("sender")}
              >
                {GetSvgIcon("Checkborderedcircle")} Address Book
              </button>
              <button
                className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={async () => {
                  const result = await trigger([
                    "senderPostcode",
                    "senderCity",
                    "senderState",
                    "senderAddress",
                    "senderPhone",
                    "senderName",
                  ]);
                  if (result) saveAddressBook("sender");
                }}
              >
                {GetSvgIcon("IconOrder")} Save Address
              </button>
            </div>
          </div>
        </div>

        {/* {avalServices?.pickup && watchPickup === "pickup" && (
          <div className="col-md-12 mb-3">
            {sameAsSender && (
              <div className="bg-white border border-2 rounded-2 p-2">
                <div className="row">
                  <div className="subheadingtext col">Pickup Details</div>
                  <div className="col text-end">
                    <input
                      className="form-check-input formsty me-1"
                      type="checkbox"
                      name=""
                      id=""
                      // value="option2"
                      onClick={(value) => handelPickupcheck(value)}
                      {...register("pickupcheckbox")}
                    />
                    <label
                      className="form-check-label theme-blue-txt"
                      htmlFor=""
                    >
                      Same as Sender Address
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Pickup Name
                    </label>
                    <div>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("pickupName", {
                          required: {
                            value: watchPickup,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("pickupName"),
                        })}
                      />
                      {errors.pickupName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.pickupName.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Pickup Phone
                    </label>
                    <div className="input-group ">
                      <select
                        name="pickupPhoneCode"
                        {...register("pickupPhoneCode")}
                        defaultValue="+60"
                        className="form-control country-code-customize"
                      >
                        {COUNTRY_CODES.map((cc, index) => (
                          <option key={index} value={cc.value}>
                            {cc.label}
                          </option>
                        ))}
                      </select>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("pickupPhone", {
                          required: {
                            value: watchPickup,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Phone Number!!!",
                          },
                          onChange: () => clearErrors("pickupPhone"),
                        })}
                      />
                    </div>
                    {errors.pickupPhone && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupPhone.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Pickup Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("pickupAddress", {
                        required: {
                          value: watchPickup,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("pickupAddress"),
                      })}
                    />
                    {errors.pickupAddress && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.pickupAddress.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      State
                    </label>
                    <div>
                      <Controller
                        name="pickupState"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={stateData}
                            placeholder="Select State"
                            value={
                              value
                                ? stateData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                                : value
                            }
                            onChange={(d) =>
                              onChange(
                                d ? d.value : d,
                                filterCity(d ? d.value : d, "pickupCity")
                              )
                            }
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                            menuPlacement="auto"
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("pickupState"),
                        }}
                      />
                      {errors.pickupState && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.pickupState.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      City
                    </label>
                    <div>
                      <Controller
                        name="pickupCity"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={pickupCityData}
                            placeholder="Select City"
                            value={
                              value
                                ? pickupCityData.filter((opt) =>
                                  value.includes(opt.value)
                                )
                                : value
                            }
                            onChange={(d) =>
                              onChange(
                                d ? d.value : d,
                                filterState(d ? d.value : d, "pickupState")
                              )
                            }
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                            menuPlacement="auto"
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("pickupCity"),
                        }}
                      />
                      {errors.pickupCity && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.pickupCity.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 my-2">
                    <label htmlFor="" className="form-label theme-label">
                      Postcode
                    </label>
                    <div>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("pickupPostcode", {
                          required: {
                            value: watchPickup,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: PATTERN_ERR_MSG,
                          },
                          onChange: () => clearErrors("pickupPostcode"),
                        })}
                      />
                      {errors.pickupPostcode && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.pickupPostcode.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col text-end my-2">
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                    type="button"
                    onClick={() => setModalData("pickup")}
                  >
                    {GetSvgIcon("Checkborderedcircle")} Address Book
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    onClick={async () => {
                      const result = await trigger([
                        "pickupPostcode",
                        "pickupCity",
                        "pickupState",
                        "pickupAddress",
                        "pickupPhone",
                        "pickupName",
                      ]);
                      if (result) saveAddressBook("pickup");
                    }}
                  >
                    {GetSvgIcon("IconOrder")} Save Address
                  </button>
                </div>
              </div>
            )}
          </div>
        )} */}

        {avalServices?.delivary && watchDelivery === "delivery" && (
          <div className="col-md-12 mb-3">
            {sameAsReciver && (
              <div className="bg-white border border-2 rounded-2 p-2">
                <div className="row">
                  <div className="subheadingtext col">Delivery Details</div>
                </div>

                <div className="row">
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Delivery Name
                    </label>
                    <div>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("deliveryName", {
                          required: {
                            value: watchDelivery,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("deliveryName"),
                        })}
                      />
                      {errors.deliveryName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.deliveryName.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Delivery Phone
                    </label>
                    <div className="input-group ">
                      <select
                        name="deliveryPhoneCode"
                        {...register("deliveryPhoneCode")}
                        defaultValue="+60"
                        className="form-control country-code-customize"
                      >
                        {COUNTRY_CODES.map((cc, index) => (
                          <option key={index} value={cc.value}>
                            {cc.label}
                          </option>
                        ))}
                      </select>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("deliveryPhone", {
                          required: {
                            value: watchDelivery,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Phone Number!!!",
                          },
                          onChange: () => clearErrors("deliveryPhone"),
                        })}
                      />
                    </div>
                    {errors.deliveryPhone && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryPhone.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      Delivery Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("deliveryAddress", {
                        required: {
                          value: watchDelivery,
                          message: REQUIRED_ERR_MSG,
                        },
                        onChange: () => clearErrors("deliveryAddress"),
                      })}
                    />
                    {errors.deliveryAddress && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.deliveryAddress.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      State
                    </label>
                    <div>
                      <Controller
                        name="deliveryState"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={stateData}
                            placeholder="Select State"
                            value={
                              value
                                ? stateData.filter((opt) =>
                                    value.includes(opt.value)
                                  )
                                : value
                            }
                            onChange={(d) =>
                              onChange(
                                d ? d.value : d,
                                filterCity(d ? d.value : d, "deliveryCity")
                              )
                            }
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                            menuPlacement="auto"
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("deliveryState"),
                        }}
                      />
                      {errors.deliveryState && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.deliveryState.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mt-2">
                    <label htmlFor="" className="form-label theme-label">
                      City
                    </label>
                    <div>
                      <Controller
                        name="deliveryCity"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={deliveryCityData}
                            placeholder="Select City"
                            value={
                              value
                                ? deliveryCityData.filter((opt) =>
                                    value.includes(opt.value)
                                  )
                                : value
                            }
                            onChange={(d) =>
                              onChange(
                                d ? d.value : d,
                                filterState(d ? d.value : d, "deliveryState")
                              )
                            }
                            isSearchable={true}
                            isClearable={true}
                            className="form-control  p-0"
                            menuPlacement="auto"
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          onChange: () => clearErrors("deliveryCity"),
                        }}
                      />
                      {errors.deliveryCity && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.deliveryCity.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 my-2">
                    <label htmlFor="" className="form-label theme-label">
                      Postcode
                    </label>
                    <div>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...register("deliveryPostcode", {
                          required: {
                            value: watchDelivery,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: PATTERN_ERR_MSG,
                          },
                          onChange: () => clearErrors("deliveryPostcode"),
                        })}
                      />
                      {errors.deliveryPostcode && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.deliveryPostcode.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col text-end my-2">
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap me-2"
                    type="button"
                    onClick={() => setModalData("delivery")}
                  >
                    {GetSvgIcon("Checkborderedcircle")} Address Book
                  </button>
                  <button
                    className="btn btn-sm btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    onClick={async () => {
                      const result = await trigger([
                        "deliveryPostcode",
                        "deliveryCity",
                        "deliveryState",
                        "deliveryAddress",
                        "deliveryPhone",
                        "deliveryName",
                      ]);
                      if (result) saveAddressBook("delivery");
                    }}
                  >
                    {GetSvgIcon("IconOrder")} Save Address
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-100 d-block">
        <div className="bg-white border border-2 rounded-2 p-3 my-3">
          <div className="col-lg-12">
            <div className="subheadingtext pb-2">Value Added Services </div>
            <div>
              <div className="row">
                <div className="col">
                  <label className="form-label theme-label" htmlFor="">
                    Manpower
                    <span data-tooltip-id="p-handel-label" className="ms-1">
                      {GetSvgIcon("ToolTip")}
                    </span>
                  </label>
                  <Tooltip
                    id="p-handel-label"
                    content={"Provide Description"}
                    place="top"
                  />
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value="MNP"
                        aria-label="Checkbox for following text input"
                        {...register("MNP", {
                          onChange: (val) => (
                            !val.target.checked
                              ? setValue("manpower", "")
                              : // eslint-disable-next-line no-sequences
                                "",
                            clearErrors("manpower")
                          ),
                        })}
                        // disabled={isPicHandling === 'false'}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("manpower", {
                        // required: {
                        //   value: watchIsMnpSelected==='MNP',
                        //   message: REQUIRED_ERR_MSG,
                        // },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("manpower"),
                      })}
                      maxLength={500}
                      disabled={!watchIsMnpSelected}
                    />
                  </div>
                  {errors.manpower && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.manpower.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col">
                  <label className="form-label theme-label" htmlFor="">
                    Equipment
                    <span data-tooltip-id="d-handel-label" className="ms-1">
                      {GetSvgIcon("ToolTip")}
                    </span>
                  </label>
                  <Tooltip
                    id="d-handel-label"
                    content={"Provide Description"}
                    place="top"
                  />
                  <div className="input-group mb-3">
                    <div className="input-group-text">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        value="EQP"
                        aria-label="Checkbox for following text input"
                        {...register("EQP", {
                          onChange: (val) => (
                            !val.target.checked
                              ? setValue("equipment", "")
                              : // eslint-disable-next-line no-sequences
                                "",
                            clearErrors("equipment")
                          ),
                        })}
                        // disabled={isDlvHandling === 'false'}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      {...register("equipment", {
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                        onChange: () => clearErrors("equipment"),
                      })}
                      disabled={!watchIsEqpSelected}
                      maxLength={500}
                    />
                  </div>
                  {errors.equipment && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.equipment.message}
                      </span>
                    </div>
                  )}
                </div>

                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {bookingType === "chartered" && (
        <div>
          <div className="w-100 d-block">
            <div className="bg-white border border-2 rounded-2 my-3">
              <div className="col-lg-12">
                <div className="subheadingtext p-3">Sepcial permission</div>

                <div className="row">
                  <div className="col">
                    <div className="col align-self-center mx-3 my-2 ">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input
                            className="form-check-input formsty"
                            type="checkbox"
                            name=""
                            id=""
                            value="specialPickup"
                            // value="option1"
                            {...register("specialPickup", {
                              onChange: (e) =>
                                handelSpecialPermissionChange(e, "pickup"),
                            })}
                            // disabled={!avalServices?.pickup}
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value="Does the driver need special permission for pickup?"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {watchPickupSpecialCont === "specialPickup" && (
                      <div className="col-md-4 mb-2">
                        {/* <label
                                htmlFor=""
                                className="form-label theme-label"
                              >
                                Bank Statement
                              </label> */}
                        <div
                          className="Upload-Photo mb-2 "
                          {...register("pickupAttachment")}
                        >
                          <input
                            type="file"
                            onChange={(e) =>
                              selectPickupDeliveryFile(e, "pickup")
                            }
                            accept=".pdf"
                          />

                          {pickupFileLable === "Choose a File ..." ? (
                            <div className="uploaded-text ms-2">
                              {GetSvgUpload({ required: false })}
                              Upload Document
                            </div>
                          ) : (
                            <div className="uploaded-text ms-2">
                              {pickupFileLable}
                            </div>
                          )}
                        </div>
                        {errors.pickupAttachment && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.pickupAttachment.message}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="col align-self-center mx-3 my-2 ">
                      <div className="input-group">
                        <div className="input-group-text">
                          <input
                            className="form-check-input formsty"
                            type="checkbox"
                            name=""
                            id=""
                            value="specialDelivery"
                            // value="option1"
                            {...register("specialDelivery", {
                              onChange: (e) =>
                                handelSpecialPermissionChange(e, "delivery"),
                            })}
                            // disabled={!avalServices?.pickup}
                          />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value="Does the driver need special permission for Delivery?"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {watchDeliverySpecialCont === "specialDelivery" && (
                      <div className="col-md-4 mb-2">
                        {/* <label
                                htmlFor=""
                                className="form-label theme-label"
                              >
                                Bank Statement
                              </label> */}
                        <div
                          className="Upload-Photo mb-2"
                          {...register("deliveryAttachment")}
                        >
                          <input
                            type="file"
                            onChange={(e) =>
                              selectPickupDeliveryFile(e, "delivery")
                            }
                            accept=".pdf"
                          />

                          {delivaryFileLable === "Choose a File ..." ? (
                            <div className="uploaded-text ms-2">
                              {GetSvgUpload({ required: false })}
                              Upload Document
                            </div>
                          ) : (
                            <div className="uploaded-text ms-2">
                              {delivaryFileLable}
                            </div>
                          )}
                        </div>
                        {errors.deliveryAttachment && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.deliveryAttachment.message}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FtlBookingAddress;

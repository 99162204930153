import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import {
  DATE_FORMAT_SERVER,
  SERVER_ERR_MSG,
  DATE_FORMAT_CLIENT,
} from "../Helper/Constants";
import moment from "moment";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import LastMileRePlanningModal from "./LastMileRePlanningModal";
import ReactDatePicker from "react-datepicker";
import GetSvgIcon from "../../images/svglist";
import { printWaybill } from "../Helper/CommonMethods";
import FtlLastMilePlanningList from "./FtlLastMilePlanningList";
import { PICKUP_SERVICE_ID } from "../../ConfigConstants/ConfigConstants";
import FtlLastMileRePlanningModal from "./FtlLastMileRePlanningModal";
import FtlBidModal from "./FtlBidModal";

export default function FtlLastMilePlanningMain() {
  const pageTitle = `Full Truck Planning`;
  const [spinner, setSpinner] = useState(false);
  const [planningList, setPlanningList] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [modalConfig, setModalConfig] = useState();
  const [vhclTypes, setVhclTypes] = useState([]);
  const [initBidData, setInitBidData] = useState(null);
  const [replanningModalConfig, setReplanningModalConfig] = useState();
  const [bidData, setBidData] = useState();
  const [filteredVehicleType, setFilteredVehicleType] = useState();
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };

  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).add(7, "days").format(DATE_FORMAT_SERVER)
  );

  useEffect(() => {
    loadData();
    loadVhclTypes();
  }, []);

  const loadData = async () => {
    let payload = {
      fromDate: fromDate,
      toDate: toDate,
    };
    setSpinner(true);
    const data = await CallApiPost("getFtlLastMilePlanningList", payload);
    if (data.respCode === 200) {
      data.respData.forEach((item) => {
        item["vhcl_no"] = !!item["vhcl_no"] ? item["vhcl_no"] : "Not Assigned";
        item["dr1_name"] = !!item["dr1_name"]
          ? item["dr1_name"]
          : "Not Assigned";
      });
      setPlanningList(data.respData);
      setSpinner(false);
    } else {
      setPlanningList([]);
      setSpinner(false);
    }
  };
  const getDriverAndVehicleByOrigin = (origin, ftlVehicleType, bidId) => {
    let payload = { origin: origin };
    setSpinner(true);
    setVehicles([]);
    setDrivers([]);
    Promise.all([
      CallApiPost("getVehicleListByOrigin", payload),
      CallApiPost("getDriversByOrigin", payload),
    ])
      .then(([vehicleResp, driverResp]) => {
        vehicleResp.respData = vehicleResp?.respData.filter(
          (e) => e.vhclTypeId === ftlVehicleType
        );
        let vlc = [];
        let drv = [];
        if (!!vehicleResp?.respData) {
         
          vlc = [...vehicleResp?.respData].map((item) => {
            return {
              label: `${item.vhclNo} (Capacity-${item.vCapacity})`,
              value: item.id,
            };
          });
        }
        if (!!driverResp?.respData) {
          
          drv = [...driverResp?.respData].map((item) => {
            return {
              label: item.staff_name + " (" + item.staff_id + ")",
              value: item.userId,
            };
          });
        }

        if (!!bidId) getBidDriverVehicleData(bidId, vlc, drv);
        else setDriverVehicleData(drv, vlc);

        setSpinner(false);
      })
      .catch((error) => {
        console.log("Error===>", error);
        setSpinner(false);
      });
  };

  const setDriverVehicleData = (drv, vlc) => {
    setVehicles([...vlc]);
    setDrivers([...drv]);
  };

  const getBidDriverVehicleData = async (bidId, vlc, drv) => {
    let respData = await CallApiGet("bidDriverVehicleData?bidId=" + bidId);

    if (respData.respCode === 200 && respData.respData.length > 0) {
      let data = respData.respData[0];
      setBidData(data);
      let bidDrv = {
        label: data.staff_name + " (" + data.acct_code + ")",
        value: data.drv_id,
      };
      let bidVlc = {
        label: `${data.v_no} (Capacity-${data.v_type})`,
        value: data.vid,
      };

      if (vlc.filter((e) => e.value === bidDrv.value).length === 0) {
        setVehicles([...vlc, bidVlc]);
      } else {
        setVehicles([...vlc]);
      }

      if (drv.filter((e) => e.value === bidDrv.value).length === 0) {
        setDrivers([...drv, bidDrv]);
      } else {
        setDrivers([...drv]);
      }
    } else {
      setDriverVehicleData(drv, vlc);
    }
  };

  const validateForBidDriverVehicle = (reVehicle, reDriver) => {
    let validDrv = true;
    let validVlc = true;
    if (bidData.vid === reVehicle) {
      if (bidData.drv_id !== reDriver) validVlc = false;
    }
    if (bidData.drv_id === reDriver) {
      if (bidData.vid !== reVehicle) validDrv = false;
    }
    if (validDrv && validVlc) return true;
    else return false;
  };
  const reloadData = () => {
    loadData();
    setDrivers([]);
    setVehicles([]);
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };
  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };
  const openBidModal = (rowData) => {
    setSelectedRowData(rowData);

    setFilteredVehicleType(
      vhclTypes.filter((e) => e.id === rowData.ftl_vehicle_type)
    );
    setInitBidData({
      bidId: 0,
      routeId: rowData?.route,
      tripDate: "",
      tripTime: "",
      sbuId: rowData?.sbu_id,
      vhclType: 0,
      tripDuration: 0,
      pallets: 0,
      tripPrice: 0.0,
      remarks: "",
      planId: rowData?.plan,
      orderId: rowData?.order_id,
    });
    setModalConfig({
      size: "lg",
      show: true,
      title: "Invite Bid",
    });
  };
  const closeBidModal = () => {
    setModalConfig({
      size: "lg",
      show: false,
      title: "Invite Bid",
    });
    setSelectedRowData(null);
  };
  const openReplanningModal = (rowData) => {
    setSelectedRowData(rowData);
    getDriverAndVehicleByOrigin(
      rowData?.sbu_id,
      rowData?.ftl_vehicle_type,
      rowData?.bidId
    );
    setReplanningModalConfig({
      size: "lg",
      show: true,
      title: "Replanning",
    });
  };
  const closeReplanningModal = () => {
    setReplanningModalConfig({
      size: "lg",
      show: false,
      title: "Replanning",
    });
    setSelectedRowData(null);
  };
  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");

    if (data.respCode === 200 || data.respCode === 0) {
      if (!!data.respData && data.respData.length > 0) {
        setVhclTypes(data.respData);
      }
    } else if (data.respCode === 500) {
      setAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    } else {
      setAlertConfig({
        isSuccess: false,
        size: "md",
        show: true,
        title: "ERROR",
        description: data.respMessage,
      });
    }
  };

  const printAllWaybill = async (rowData) => {
    setSpinner(true);
    let data = await CallApiGet("getShipmentDetailsById/" + rowData.order_id);
    let waybillData = {};
    if (data.respCode === 200) {
      waybillData = {
        waybill_no: data?.respData?.shipmentPallets.map(
          (item) => item.waybillNo
        ),
        shipmentId: rowData.order_id,
      };
      printWaybill(waybillData);
      setSpinner(false);
    } else {
      setSpinner(false);
    }
  };

  const doRePlanning = (reVehicle, reDriver, reTripDate, reTripTime) => {
    setSpinner(true);
    let payload = {
      route_id: selectedRowData?.route,
      plan_date: moment(reTripDate + " " + reTripTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      vehicle_id: reVehicle,
      driver_id: reDriver,
      existing_plan_id: selectedRowData?.plan,
      order_data: [
        {
          order_id: selectedRowData?.order_id,
          service_id: PICKUP_SERVICE_ID, //FTL planning is always done for pickup
        },
      ],
    };
    CallApiPost("lastmileAssignVehicle", payload)
      .then((data) => {
        closeReplanningModal();
        if (data.respCode === 200) {
          reloadData();
          showAlert({
            isSuccess: true,
            size: "md",
            show: true,
            title: "",
            description: "Planned successfully",
          });
        } else {
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "",
            description: "!!Some Error Occured",
          });
        }
        setSpinner(false);
      })
      .catch((error) => {
        closeReplanningModal();
        console.log("Exception==", error);
        setSpinner(false);
      });
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <section>
          <div className="row py-3 m-1">
            <div className="col-auto d-flex align-item-center border rounded px-2 py-1 me-3 wt-tbl">
              <div className="col-auto align-self-center me-3">
                <label
                  htmlFor=""
                  className="form-label theme-label me-2"
                  title="This field is mandatory"
                >
                  From:
                </label>
                <ReactDatePicker
                  placeholderText="Select Date"
                  className="form-control"
                  dateFormat={DATE_FORMAT_CLIENT}
                  selected={!!fromDate ? new Date(fromDate) : fromDate}
                  onFocus={(e) => e.target.blur()}
                  onChange={(d) => setFromDate(d)}
                  onSelect={(d) => setFromDate(d)}
                />
              </div>
              <div className="col-auto align-self-center">
                <label
                  htmlFor=""
                  className="form-label theme-label me-2"
                  title="This field is mandatory"
                >
                  To:
                </label>
                <ReactDatePicker
                  placeholderText="Select Date"
                  className="form-control"
                  dateFormat={DATE_FORMAT_CLIENT}
                  selected={!!toDate ? new Date(toDate) : toDate}
                  onFocus={(e) => e.target.blur()}
                  onChange={(d) => setToDate(d)}
                  onSelect={(d) => setToDate(d)}
                />
              </div>
            </div>
            <div className="col-auto my-3">
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet text-nowrap"
                onClick={() => loadData()}
                disabled={!(!!fromDate && toDate)}
              >
                {GetSvgIcon("Search")}
              </button>
            </div>
          </div>
          {planningList.length > 0 ? (
            <FtlLastMilePlanningList
              selsbu={origin}
              planningList={planningList}
              openReplanningModal={openReplanningModal}
              openBidModal={openBidModal}
              selectedRowData={selectedRowData}
              printAllWaybill={printAllWaybill}
            />
          ) : (
            <div className="noData">No Data Available</div>
          )}
        </section>
      </Layout>
      {!!replanningModalConfig?.show && (
        <FtlLastMileRePlanningModal
          closeReplanningModal={closeReplanningModal}
          replanningModalConfig={replanningModalConfig}
          vehicles={vehicles}
          drivers={drivers}
          doRePlanning={doRePlanning}
          showAlert={showAlert}
          setSpinner={setSpinner}
          selectedRowData={selectedRowData}
        />
      )}
      {!!modalConfig?.show && (
        <FtlBidModal
          closeBidModal={closeBidModal}
          modalConfig={modalConfig}
          vhclTypes={filteredVehicleType}
          initBidData={initBidData}
          showAlert={showAlert}
          setSpinner={setSpinner}
          reloadData={reloadData}
        />
      )}
    </>
  );
}

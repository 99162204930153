import React, { useState } from "react";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  LINEHAUL_PARTNER_DRIVER_USER_TYPE,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
} from "../../../ConfigConstants/ConfigConstants";
const initObj = {
  userType: null,
  custType: null,
};
const CustomerTypeModal = (props) => {
  const { handleNavigation } = props;

  const handleOnchange = (bool, userType) => {
    document.getElementById("btn-close").click();
    handleNavigation(bool, userType);
  };
  const [user, setUser] = useState(JSON.parse(JSON.stringify(initObj)));

  const onChangeUser = (type) => {
    setUser({ ...user, userType: type });
  };
  const resetUser = () => {
    setUser(JSON.parse(JSON.stringify(initObj)));
  };
  const userTypeCClass =
    user?.userType === "C"
      ? "w-100 rounded-pill theme-violet-text text-white text-center fs-1"
      : "btn-primary-search w-100 rounded-pill theme-violet cursor-pointer";
  const userTypePClass =
    user?.userType === "P"
      ? "w-100 rounded-pill theme-violet-text text-white text-center fs-1"
      : "btn-primary-search w-100 rounded-pill theme-violet cursor-pointer";

  return (
    <div
      className="modal fade"
      id="customer-type-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl  modal-dialog-centered">
        <div className="modal-content Signupbg px-1 py-3">
          <button
            type="button"
            className="d-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="btn-close"
            onClick={props.clear}
          ></button>
          <div class=" m-auto py-1 col-md-12">
            <div className="modal-body modal-scroll overflow-auto">
              <div className="m-4">
                <div className="row align-items-center">
                  {(user?.userType === null || user?.userType === "C") && (
                    <div className="col-6 m-auto reg-option">
                      {/* <button
                        className='btn-primary-search w-100 rounded-pill theme-violet'
                        onClick={() => onChangeUser("C")}
                      >
                        I want to place an order
                      </button> */}
                      <div
                        className={userTypeCClass}
                        onClick={() => onChangeUser("C")}
                      >
                        I want to place an order
                      </div>
                    </div>
                  )}
                  {(user?.userType === null || user?.userType === "P") && (
                    <div className="col-auto m-auto reg-option">
                      {/* <button
                        className="btn-primary-search w-100 rounded-pill theme-violet"
                        onClick={() => onChangeUser("P")}
                      >
                        I want to become a business partner{" "}
                      </button> */}
                      <div
                        className={userTypePClass}
                        onClick={() => onChangeUser("P")}
                      >
                        I want to become a business partner{" "}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!!user?.userType && user?.userType === "C" && (
            <>
              <div className="row align-items-center">
                <div className="col">
                  <h6 class="text-white mb-3 ms-2 p-2 ps-3">
                    Just provide basic information & start ordering
                  </h6>
                  <button
                    className="btn-primary-search w-100 rounded-pill theme-violet f-18"
                    onClick={() => handleOnchange(false, "PC")}
                  >
                    Personal Customer
                  </button>
                </div>
                <div className="col">
                  <h6 class="text-white mb-3 ms-2 ps-3">
                    Want credit service & more?
                    <br />
                    Fill up and our Sales Agent will keep in touch with you.
                  </h6>
                  <button
                    className="btn-primary-search w-100 rounded-pill theme-violet f-18"
                    onClick={() => handleOnchange(false, "BC")}
                  >
                    Business Customer
                  </button>
                </div>
              </div>
              <div className="text-center my-4 signin-link">
                <a onClick={resetUser} className="cursor-pointer">
                  Back
                </a>
              </div>
            </>
          )}

          {!!user?.userType && user?.userType === "P" && (
            <>
              <div className="row">
                <div className="col md-6">
                  <div className="row"></div>
                  <div className="row">
                    <div className="col-12">
                      <h6 class="text-white mb-3 mt-5 ps-3">
                        Become our sales agent today
                      </h6>
                      <button
                        className="btn-primary-search w-100 rounded-pill theme-violet f-18"
                        onClick={() => handleOnchange(false, "AC")}
                      >
                        Sales Partner
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col md-6">
                  <div className="row">
                    <div className="col-12">
                      <h6 class="text-white mb-3 ms-2 ps-3">
                        Own a truck company? Register as our third party
                        logistic partner.
                      </h6>
                      <button
                        className="btn-primary-search w-100 rounded-pill theme-violet f-18"
                        onClick={() =>
                          handleOnchange(
                            true,
                            LINEHAUL_PARTNER_COMPANY_USER_TYPE
                          )
                        }
                      >
                        Partner Company
                      </button>
                      <div className="row p-1"></div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <h6 class="text-white mb-3 ms-2 ps-3">
                        Own a truck? Start taking orders by registering as our
                        driver-partner.
                      </h6>
                      <button
                        className="btn-primary-search w-100 rounded-pill theme-violet f-18"
                        onClick={() =>
                          handleOnchange(
                            true,
                            LINEHAUL_PARTNER_DRIVER_USER_TYPE
                          )
                        }
                      >
                        Partner Driver
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center my-4 signin-link">
                <a onClick={resetUser} className="cursor-pointer">
                  Back
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerTypeModal;

import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import GetSvgIcon from "../../../images/svglist";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import VasChargeList from "./VasChargeList";
import VasChargeAddEdit from "./VasChargeAddEdit";
import {
  DELIVERY_SERVICE_ID,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
  PICKUP_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";
import { SERVER_ERR_MSG } from "../../Helper/Constants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";

const initModalConfig = {
  show: false,
  title: "",
};

const initObj = {
  salesPartner: "",
  pickupCharge: 0,
  deliveryCharge: 0,
};

const vasObj = [
  {
    charge: 190.5,
    serviceId: 9,
    extraCharge: 5.75,
    serviceName: "Pickup Services",
    subBreakFee: 1.25,
    thresholdUnit: 2,
    subBreakThreshold: 10,
  },
  {
    charge: 250.75,
    serviceId: 148,
    extraCharge: 7,
    serviceName: "Delivery Services",
    subBreakFee: 2.5,
    thresholdUnit: 3,
    subBreakThreshold: 20,
  },
];

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const VasChargeMain = () => {
  const [selVas, setSelVas] = useState(Object.assign({}, vasObj));
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [vasChargeList, setVasChargeList] = useState([]);
  const [initVas, setInitVas] = useState([]);
  const [agentList, setAgentList] = useState([
    {
      label: "",
      value: 0,
    },
  ]);
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );

  useEffect(() => {
    loadData();
  }, []);

  const LoadServiceCharges = async () => {
    const resp = await CallApiGet("GetVasServiceCharge");
    return resp;
  };

  const handlePostApiCall = (resp, sucMsg) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", sucMsg);
        break;
      case 500:
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the permission!!"
        );
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after soemtime!!"
        );
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const loadData = () => {
    setSpinner(true);
    Promise.all([
      LoadServiceCharges(),
      CallApiGet(`get-cust-data?custType=${LINEHAUL_AGENT_CUSTOMER_USER_TYPE}`),
      CallApiPost("GetVasServices", {
        serviceId: [PICKUP_SERVICE_ID, DELIVERY_SERVICE_ID],
      }),
    ])
      .then(([respVas, respCust, respSrvc]) => {
        let error = false;
        if (respVas.respCode === 200 && !!respVas.respData) {
          setVasChargeList(respVas.respData);
        } else {
          error = true;
        }

        if (
          respCust.respCode === 200 &&
          !!respCust.respData &&
          Array.isArray(respCust.respData)
        ) {
          setAgentList(respCust.respData);
        } else {
          error = true;
        }

        if (respSrvc.respCode === 200 && !!respSrvc.respData) {
          setInitVas(JSON.parse(respSrvc.respData[0].vas));
        } else {
          error = true;
        }

        if (error) {
          const resp = {
            respCode: 400,
            respMessage:
              "Sorry, failed to load some data. Please refresh page!!",
            respData: null,
          };
          handlePostApiCall(resp);
        }
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.error(err);
        const resp = {
          respCode: 500,
          respMessage: SERVER_ERR_MSG,
          respData: null,
        };
        handlePostApiCall(resp);
      });
  };

  const refresh = async () => {
    setSpinner(true);
    const resp = await LoadServiceCharges();
    if (resp.respCode === 200 && !!resp.respData) {
      setVasChargeList(resp.respData);
    } else {
      handlePostApiCall(resp);
    }
    setSpinner(false);
  };

  const hideAlert = async () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    refresh();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setAlertConfig({
      isSuccess: boolData,
      size: "md",
      show: true,
      title: popupTitle,
      description: popupDesc,
    });
  };

  const handleBtnClick = (argData, argTitle) => {
    if (typeof argData.vas === "string") {
      argData.vas = JSON.parse(argData.vas);
    }
    setSelVas(Object.assign({}, argData));
    setModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setSelVas(Object.assign({}, initVas));
    setModalConfig({
      show: false,
      title: "",
    });
  };

  const onSubmit = async (data) => {
    setSpinner(true);
    data.userId = getUserInfo("userId");
    data.remarks = "";
    data.info = "";
    data.customerId = [data.customerId];
    console.log(data);

    const uri = await CallApiPost("saveVasServiceCharge", data);
    handleCLose()
    if (uri.respCode === 200 || uri.respCode === 0) {
      refresh();
    } else {
      handlePostApiCall(uri);
    }
    setSpinner(false);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">Service Charges</div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() =>
                  handleBtnClick({ customerId: 0, vas: initVas }, "ADD Rate")
                }
              >
                {GetSvgIcon("PlusCircle")} Add Rate
              </button>
            </div>
          </div>
        </div>
        <VasChargeList
          handleAction={handleBtnClick}
          vasChargeList={vasChargeList}
        />
        <VasChargeAddEdit
          modalConfig={modalConfig}
          onHide={handleCLose}
          agentList={agentList}
          selVas={selVas}
          onSubmit={onSubmit}
        />
      </Layout>
    </>
  );
};

export default VasChargeMain;

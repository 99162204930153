export const initValFuelLog = {
  id: 0,
  vehicleId: "",
  driverId: "",
  odoMeter: "",
  amount: "",
  fromDate: "",
  toDate: "",
  vehicleName: "",
  driverName: "",
  infoAttachment: {
    "photo": null,
    "photoFileType": null,
    "photoFileName": null
  }
};

import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../images/linehaul-logo.png";
import Popup from "../../Components/Layout/Popup";
import GetSvgIcon from "../../images/svglist";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import Spinner from "../Layout/Spinner";

export default function ResetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [viewPswd, setViewPswd] = useState(false)
  const [viewConfirmPswd, setViewConfirmPswd] = useState(false)

  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const navigate = useNavigate()
  const [token, setToken] = useState(0);
  const [header, setHeader] = useState("Reset Password");
  const [show, setShow] = useState(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setShow(true);
    let initAccount = Number(searchParams.get("initAccount"));
    let token = Number(searchParams.get("token"));
    setToken(token);
    async function checkResetFlag(initAccount, token) {
      let resp = await CallApiGet(`GetPwdResetFlag/${token}`);
      if(resp.respCode === 200) {
        if(resp.respData && initAccount === 1) {
          callback();
        } else if(resp.respData && initAccount === 0) {
          setHeader("Reset Password");
        } else if(!resp.respData && initAccount === 0) {
          setHeader("Set Password");
        } else {
          setHeader("Set Password");
        }
      } else {
        onError();
        callback();
      }
    }
    checkResetFlag(initAccount, token);
    setShow(false);
  }, []);

  const onSubmit = async (data) => {

    data["userId"] = token;
    data["password"] = getValues("password");


    //
    console.log(data);
    let res = await CallApiPost("reset-password", data);
    if(res.respCode == 200) {
      onSuccess();
    } else {
      console.error(res.data.respData);
      onError()
    }
  };
  const passwordValidation = () => {
    const confirmPws = getValues("confirmPassword");
    const pws = getValues("password");
    if (confirmPws !== pws) {
      return false;
    }
  };

  const viewPassword = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setViewPswd(true)
    } else {
      x.type = "password";
      setViewPswd(false)
    }
  }

  const viewConfirmPassword = () => {

    var x = document.getElementById("confirmPassword");
    if (x.type === "password") {
      x.type = "text";
      setViewConfirmPswd(true)
    } else {
      x.type = "password";
      setViewConfirmPswd(false)
    }
  }

  const onSuccess = () => {
    setIsSuccess(true)
    setTitle("Congratulations")
    setDescription("Password has been reset successfully!!!")
    modalRef.current.click()

  }

  const onError = () => {
    setIsSuccess(false)
    setTitle("Error")
    setDescription("Something went wrong!!!")
    modalRef.current.click()

  }

  const callback = () => {
    navigate("/login")
  }

  const handleKeypress = e => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <div className="backdrop-image-register">
      <div className="container-fluid mb-5">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="Signupbg Registerform m-auto mt-4 pb-4 col-md-4">
              <div className="text-center w-100 clearfix max-logo">
                <a href="javascript:void(0)" className="d-block">
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block"
                    alt="LinehaulLogo"
                  />
                </a>
              </div>
              <div className="px-4">
                <div className="Form-title mb-1">{header}</div>
                <div className="row">
                  <div>
                    <div className="w-100">
                      <div className="row">
                        <div className="col-12 input-group mb-3">
                          <span
                            className="input-group-text inputsty"
                            id="basic-addon1"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-unlock-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                            </svg>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            aria-label="New Password"
                            aria-describedby="basic-addon1"
                            name="password"
                            id="password"
                            {...register("password", { required: true })}
                            onKeyUp={handleKeypress}
                          />
                          <button className="btn btn-outline-secondary view-password" type="button" id="button-addon2"
                            tabIndex="-1" onClick={() => viewPassword()}
                          >
                            {
                              viewPswd
                                ? GetSvgIcon("BiEyeSlash")
                                : GetSvgIcon("BiEye")
                            }

                          </button>
                          {errors.password && (
                            <div className="col-12 input-group mb-3">
                              <span className="badge bg-danger">
                                &#9888; This Field cannot be empty!
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-12 input-group mb-3 position-relative">
                          <span
                            className="input-group-text inputsty"
                            id="basic-addon1"
                          >

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-unlock-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                            </svg>

                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            aria-label="Confirm Password"
                            aria-describedby="basic-addon1"
                            id="confirmPassword"
                            name="confirmPassword"
                            {...register("confirmPassword", {
                              required: true,
                              validate: passwordValidation,
                            })}
                            onKeyUp={handleKeypress}
                          />
                          <button className="btn btn-outline-secondary view-password" type="button" id="button-addon2"
                            onClick={() => viewConfirmPassword()}
                          >
                            {
                              viewConfirmPswd
                                ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                                : <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  className="bi bi-eye"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                            }

                          </button>

                          {errors.confirmPassword &&
                            errors.confirmPassword.type === "required" && (
                              <div className="col-12 input-group mb-3">
                                <span className="badge bg-danger">
                                  &#9888; This Field cannot be empty!
                                </span>
                              </div>
                            )}
                          {errors.confirmPassword &&
                            errors.confirmPassword.type === "validate" && (
                              <div className="col-12 input-group mb-3">
                                <span className="badge bg-danger">
                                  &#9888; Password mismatch!
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix col-md-6 m-auto mt-3">
                    <button className="btn-primary-search w-100 rounded-pill theme-violet"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                    >Confirm</button>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#success-modal"
                      id="modalPopup"
                      className="d-none"
                      ref={modalRef}

                    />
                    <div className="text-center my-2 signin-link">Existing User?

                      <Link className="text-center my-2 signin-link" to='/login'> Sign In</Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
        <Popup
          isSuccess={isSuccess}
          title={title}
          description={description}
          handler={callback}
        />
        <Spinner show={show} />
      </div>
    </div>
  );
}

import React from "react";
import GetSvgIcon from "../../../images/svglist";
const CardWidget = (props) => {

    return (
        <>
            <div className="col col-xs-6">
                <div className={`card border-start border-0 border-4 border-${props?.border} shadow h-100 py-2`}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">{props?.title}</div>
                                <div className="h4 mb-0 theme-violet-txt">{props?.count}</div>
                            </div>
                            <div className="col-auto">{GetSvgIcon(`${props?.icon}`)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardWidget;
import React from "react";
import Modal from "react-bootstrap/Modal";

const CostumeModalConfirmAlert = ({ children, confirmAlertConfig, hideConfirmAlert,confirmAlert }) => {
    return (
        <>
            <Modal
                size={confirmAlertConfig.size}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={confirmAlertConfig.show}
                onHide={hideConfirmAlert}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{confirmAlertConfig.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{children}</div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger me-2" onClick={hideConfirmAlert}>Cancel</button>
                    <button type="button" className="btn btn-primary theme-violet" onClick={confirmAlert}>Confirm</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CostumeModalConfirmAlert;

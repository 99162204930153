import React from "react";

import UploadDataFile from "../Helper/DataUploadUtil";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  BLACK_SPCL_CHARS,
  BLACK_SPCL_CHARS_STR,
  customReactSelectMenuStyle,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DECIMAL_REGEX,
  NUMBER_SPACE_REGEX,
  TWO_THREE_DECIMAL,
} from "../Helper/Constants";

import CustomToast from "../Utilities/CustomToast";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { CallApiGet } from "../Helper/serviceApi";
import { cleanNewLine } from "../Helper/CommonMethods";
import GetSvgIcon from "../../images/svglist";
import { initManfAttach } from "./InboundManifestEdit";
import { DownloadFile } from "../Helper/FileStoreService";
import { handleAttch } from "./InboundOrderMain";
import { useContext } from "react";
import { InbStateContext } from "./InboundContext";

const customerColumns = [
  "Container ID",
  "EDA (dd-mm-yyyy)",
  "Origin Code",
  "Port Name",
  "Item Details",
  "Quantity",
  "Weight (Kg)",
  "Sender Name",
  "Sender Address",
  "Recipient Name",
  "Recipient Address",
  "Recipient City",
  "Recipient State",
  "Recipient Postcode",
  "Recipient Phone",
];
const staffColumns = [
  "Location",
  "Shipment No",
  "Job No.",
  "IN Date",
  "CN Job",
  "Marking",
  "Name",
  "Quantity",
  "Weight",
  "Contact",
  "Address",
  "City",
  "State",
  "Postcode",
  "Remarks",
  "Special Remark",
  "Status",
];

export const initInbOrd = {
  orderId: 0,
  custAcctCode: "",
  containerNo: "",
  portName: "",
  origin: "",
  originId: 0,
  orderDt: "",
  ada: "",
  attachments: [initManfAttach],
  isCleanOnClose: true,
};

const initErrObj = {
  custAcctCode: false,
  containerNo: false,
  portName: false,
  origin: false,
  originId: false,
  orderDt: false,
  ada: false,
};

const mandFields = [
  // "custAcctCode",
  "custId",
  // "suppId",
  "containerNo",
  "originId",
  "ada",
];

export default function InboundOrderUploadFile(props) {
  let { handleRespPostApiCall, dropdownData, setShowSpinner } = props;
  const { order } = useContext(InbStateContext);
  const [showToast, setShowToast] = useState(false);
  const [addrArr, setAddrArr] = useState([]);
  const userType = getUserInfo("userType");
  const [excelDtls, setExcelDtls] = useState({
    template: "",
    validation: "",
    downloadFileName: "",
  });
  const [inbOrder, setInbOrder] = useState(
    JSON.parse(JSON.stringify(initInbOrd))
  );
  const [sbuList, setSbuList] = useState([]);
  const [errObj, setErrObj] = useState(JSON.parse(JSON.stringify(initErrObj)));

  const custTemplate = require(`../../assets/templates/InboundOrdersCustUploadTemplate.xlsx`);
  const staffTemplate = require(`../../assets/templates/InboundOrdersUploadTemplate.xlsx`);

  useEffect(() => {
    getSbuData(0);
  }, []);

  useEffect(() => {
    setInbOrder(Object.assign(Object.assign({}, order)));
  }, [order]);

  const getSbuData = async (argCustId) => {
    setShowSpinner(true);
    const uri = `getBookingStateAndCity?argCustId=${argCustId}`;
    let cityResponse = await CallApiGet(uri);
    if (cityResponse.respCode === 0 && !!cityResponse.respData) {
      setSbuList(cityResponse?.respData);
    }
    setShowSpinner(false);
  };

  const validateCustomerInboundData = (fileData) => {
    return validateFilesData(fileData, customerColumns);
  };

  const validateInboundData = (fileData) => {
    return validateFilesData(fileData, staffColumns);
  };

  const validateFilesData = (fileData, columns) => {
    let isValid = true;
    let addrArr = [];
    fileData.forEach((row, index) => {
      let errMsg = [];
      // Validate only one container details passed while upload
      // validateContainerDtls(errMsg, row, index, containerDtls);
      // isValid = vldMandField();
      // Loop over row in Excel
      columns.forEach((col) => {
        // Validate Mandatory columns
        // if (!row.hasOwnProperty(col)) {
        //   errMsg.push(col + " is absent ");
        // }
        // Validate each column data
        validateEachCol(col, errMsg, row);
      });
      addrArr.push({
        rindex: index,
        rcity: row["City"],
        rstate: row["State"],
        rpostcode: row["Postcode"],
        rorigin: inbOrder?.origin,
      });
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    setAddrArr([...addrArr]);
    return isValid;
  };

  const validateEachCol = (col, errMsg, row) => {
    switch (col) {
      case "Weight":
        if (!!row[col] && !DECIMAL_REGEX.test(row[col])) {
          errMsg.push("Invalid or Empty " + col + " ");
        }
        break;
      case "Quantity":
        if (!!row[col] && !NUMBER_SPACE_REGEX.test(row[col])) {
          errMsg.push("Invalid or Empty " + col + " ");
        }
        break;
      case "M3":
        if (!TWO_THREE_DECIMAL.test(row[col])) {
          errMsg.push("Invalid data");
        }
        break;
      case "Postcode":
        if (row[col] === undefined || row[col] === null || row[col] === "") {
          errMsg.push(col + ": This field is required");
        } else if (!NUMBER_SPACE_REGEX.test(row[col])) {
          errMsg.push(col + ": Accepts only numeric values");
        }
        break;
      case "Name":
      case "Address":
      case "State":
      case "Job No.":
        if (row[col] === undefined || row[col] === null || row[col] === "") {
          errMsg.push(col + ": This field is required");
        } else if (BLACK_SPCL_CHARS.test(row[col])) {
          errMsg.push(
            col + ": remove special characters " + BLACK_SPCL_CHARS_STR
          );
        }
        break;
      case "IN Date":
      case "Location":
      case "Shipment No":
      case "CN Job":
      case "Marking":
      case "Contact":
      case "Remarks":
      case "Special Remark":
      case "Status":
        // case "Job No.":
        if (BLACK_SPCL_CHARS.test(row[col])) {
          errMsg.push(
            col + ": remove special characters " + BLACK_SPCL_CHARS_STR
          );
        }
        break;
      default:
    }
  };

  const vldMandField = () => {
    let valid = true;
    const localErrObj = Object.assign({}, errObj);
    Object.entries(inbOrder).forEach(([key, value]) => {
      if (
        // key !== "portName" &&
        // key !== "origin" &&
        // key !== "orderId" &&
        mandFields.includes(key) &&
        (value === null ||
          value === undefined ||
          value === "" ||
          value === 0 ||
          value === "0")
      ) {
        localErrObj[key] = true;
        valid = false;
      }
    });
    setErrObj(localErrObj);
    setTimeout(() => {
      setErrObj(JSON.parse(JSON.stringify(initErrObj)));
    }, 4000);
    console.log(localErrObj);
    return valid;
  };

  const validateContainerDtls = (errMsg, row, index, containerDtls) => {
    let tempColsData = {};
    if (index === 0) {
      containerDtls.custId = row["Customer ID"];
      containerDtls.containerNo = row["Container ID"];
      containerDtls.eda = moment(
        row["EDA (dd-mm-yyyy)"],
        DATE_FORMAT_MOMENT,
        true
      ).format(DATE_FORMAT_SERVER);
      containerDtls.portName = row["Port Name"];
      containerDtls.origin = row["Origin Code"];
    } else {
      tempColsData.custId = row["Customer ID"];
      tempColsData.containerNo = row["Container ID"];
      tempColsData.eda = moment(
        row["EDA (dd-mm-yyyy)"],
        DATE_FORMAT_MOMENT,
        true
      ).format(DATE_FORMAT_SERVER);
      tempColsData.portName = row["Port Name"];
      tempColsData.origin = row["Origin Code"];
    }

    if (
      JSON.stringify(tempColsData) !== JSON.stringify(containerDtls) &&
      index !== 0
    ) {
      errMsg.push(
        'These columns "Customer and Container and EDA and Origin Code and Port" should be unique!'
      );
    }
  };

  useEffect(() => {
    let state;
    if (LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)) {
      state = {
        template: custTemplate,
        validation: validateCustomerInboundData,
        downloadFileName: "InboundOrdersCustUploadTemplate.xlsx",
      };
    } else {
      state = {
        template: staffTemplate,
        validation: validateInboundData,
        downloadFileName: "InboundOrdersUploadTemplate.xlsx",
      };
    }
    setExcelDtls(state);
  }, []);

  const postExcelFileRead = (fileData) => {
    const fileDataOut = { ...inbOrder };
    const items = [];
    let addrArr = [];
    fileData.map((row, index) => {
      let rowOut = {};
      let addrObj = {};
      // if (index === 0) {
      //   fileDataOut.userId = getUserInfo("userId");
      //   if (!LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(userType)) {
      //     fileDataOut.custAcctCode = row["Customer ID"];
      //   } else {
      //     fileDataOut.custAcctCode = getUserInfo("accountCode");
      //   }
      //   fileDataOut.containerNo = row["Container ID"];
      //   fileDataOut.eda = moment(
      //     row["EDA (dd-mm-yyyy)"],
      //     DATE_FORMAT_MOMENT,
      //     true
      //   ).format(DATE_FORMAT_SERVER);
      //   fileDataOut.origin = row["Origin Code"];
      //   fileDataOut.portName = row["Port Name"];
      // }
      rowOut.location = cleanNewLine(row["Location"]);
      rowOut.qty = cleanNewLine(row["Quantity"]);
      rowOut.wgt = cleanNewLine(row["Weight"]);
      rowOut.shipmentNo = cleanNewLine(row["Shipment No"]);
      // rowOut.inDt = moment(row["IN Date"], DATE_FORMAT_MOMENT, true).format(
      //   DATE_FORMAT_SERVER
      // );
      rowOut.inDt = String(cleanNewLine(row["IN Date"])).trim();
      rowOut.cnJob = cleanNewLine(row["CN Job"]);
      rowOut.marking = cleanNewLine(row["Marking"]);
      rowOut.name = cleanNewLine(row["Name"]);
      rowOut.contact = cleanNewLine(row["Contact"]);
      rowOut.address = String(cleanNewLine(row["Address"])).trim();
      rowOut.state = String(cleanNewLine(row["State"])).trim();
      rowOut.postcode = String(cleanNewLine(row["Postcode"])).trim();
      rowOut.m3 = cleanNewLine(row["M3"]);
      rowOut.remarks = cleanNewLine(row["Remarks"]);
      rowOut.specialRemark = cleanNewLine(row["Special Remark"]);
      rowOut.status = cleanNewLine(row["Status"]);
      rowOut.jobNo = cleanNewLine(row["Job No."]);
      items.push(rowOut);

      addrObj.addr = String(cleanNewLine(row["Address"])).trim();
      addrObj.city = "";
      addrObj.state = String(cleanNewLine(row["State"])).trim();
      addrObj.postcode = String(cleanNewLine(row["Postcode"])).trim();
      if (
        !addrArr.some((obj) => JSON.stringify(obj) === JSON.stringify(addrObj))
      ) {
        addrArr.push(addrObj);
      }
    });
    fileDataOut.items = items;
    fileDataOut.addrMod = addrArr;
    return fileDataOut;
  };

  const onUploadSuc = () => {
    const resp = {
      respCode: 200,
      respMessage: "Manifest Uploaded Successfully!!!",
    };
    handleRespPostApiCall(resp, "Manifest Uploaded Successfully!!!");
    if (inbOrder?.isCleanOnClose) {
      setInbOrder(JSON.parse(JSON.stringify(initInbOrd)));
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const isDataValid = (name, value) => {
    let valid = true;
    if (!ALPHANUM_SPACE_SPCL_CHAR.test(value)) {
      valid = false;
    }
    console.log("value = {}, valid = {}", value, valid);
    return valid;
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (isDataValid(name, value)) {
      setInbOrder((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const clearOrigin = () => {
    setInbOrder((state) => ({
      ...state,
      originId: 0,
    }));
  };

  const handleChange = (name, value) => {
    if (name === "custId") {
      getSbuData(value);
      clearOrigin();
    }
    setInbOrder((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const onCloseClear = () => {
    if (inbOrder?.isCleanOnClose) {
      setInbOrder(JSON.parse(JSON.stringify(initInbOrd)));
    }
  };

  const handleFileAttach = (argAttach) => {
    setInbOrder((state) => ({ ...state, attachments: argAttach }));
  };

  return (
    <>
      <UploadDataFile
        dataUploadUrl="SaveInboundOrder"
        cbValidateData={excelDtls.validation}
        cbPreUpload={postExcelFileRead}
        onSuccess={onUploadSuc}
        template={excelDtls.template}
        downloadFileName={excelDtls.downloadFileName}
        onError={handleRespPostApiCall}
        isFullScreen={true}
        onCloseClear={onCloseClear}
        preValidation={vldMandField}
      >
        <Row className="mb-2">
          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Customer Account Code <span className="text-danger">&#9733;</span>
            </label>
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">
                {inbOrder?.custAcctCode}
              </div>
            ) : (
              <>
                {/* <input
                  name="custAcctCode"
                  className="form-control"
                  value={inbOrder?.custAcctCode}
                  onChange={handleOnChange}
                /> */}
                <Select
                  placeholder="Select Customer"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  options={dropdownData?.customerList}
                  value={
                    !!inbOrder?.custId
                      ? dropdownData?.customerList?.find(
                          (opt) => opt.value === inbOrder?.custId
                        )
                      : 0
                  }
                  onChange={(opt) =>
                    !!opt
                      ? handleChange("custId", opt.value)
                      : handleChange("custId", 0)
                  }
                  menuPortalTarget={document.body}
                  styles={customReactSelectMenuStyle}
                />
                {errObj?.custId && (
                  <div>
                    <span className="text-danger fw-bold fs-6">
                      &#9888; This field is mandatory
                    </span>
                  </div>
                )}
              </>
            )}
          </Col>
          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Supplier
            </label>
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">
                {!!inbOrder?.supplier ? inbOrder?.supplier : "N/A"}
              </div>
            ) : (
              <>
                <Select
                  placeholder="Select Supplier"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  options={dropdownData?.supplierList}
                  value={
                    !!inbOrder?.suppId
                      ? dropdownData?.supplierList?.find(
                          (opt) => opt.value === inbOrder?.suppId
                        )
                      : 0
                  }
                  onChange={(opt) =>
                    !!opt
                      ? handleChange("suppId", opt.value)
                      : handleChange("suppId", 0)
                  }
                  menuPortalTarget={document.body}
                  styles={customReactSelectMenuStyle}
                />
              </>
            )}
          </Col>
          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Container Number <span className="text-danger">&#9733;</span>
            </label>
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">
                {inbOrder?.containerNo}
              </div>
            ) : (
              <>
                <input
                  name="containerNo"
                  className="form-control"
                  value={inbOrder?.containerNo}
                  onChange={handleOnChange}
                />
                {errObj?.containerNo && (
                  <div>
                    <span className="text-danger fw-bold fs-6">
                      &#9888; This field is mandatory
                    </span>
                  </div>
                )}
              </>
            )}
          </Col>
          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Port Name{" "}
            </label>
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">
                {!!inbOrder?.portName ? inbOrder?.portName : "N/A"}
              </div>
            ) : (
              <input
                name="portName"
                className="form-control"
                value={inbOrder?.portName}
                onChange={handleOnChange}
                disabled={inbOrder?.orderId > 0}
              />
            )}
          </Col>

          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Origin <span className="text-danger">&#9733;</span>
            </label>

            {/* <input
              name="origin"
              className="form-control"
              value={inbOrder?.origin}
              onChange={handleOnChange}
            /> */}
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">{inbOrder?.origin}</div>
            ) : (
              <>
                <Select
                  options={sbuList}
                  placeholder="Select Origin"
                  value={
                    !!inbOrder?.originId && inbOrder?.originId > 0
                      ? sbuList?.find((opt) => opt.value === inbOrder?.originId)
                      : inbOrder?.originId
                  }
                  onChange={(d) =>
                    handleOnChange({
                      target: { name: "originId", value: !!d ? d.value : 0 },
                    })
                  }
                  isSearchable={true}
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={customReactSelectMenuStyle}
                  className="form-control p-0"
                />
                {errObj?.originId && (
                  <div>
                    <span className="text-danger fw-bold fs-6">
                      &#9888; This field is mandatory
                    </span>
                  </div>
                )}
              </>
            )}
          </Col>
          <Col md={2} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Container Arrival Date{" "}
              <span className="text-danger">&#9733;</span>
            </label>
            {inbOrder?.orderId > 0 ? (
              <div className="grd-bg p-2 rounded-3">
                {moment(inbOrder?.ada, DATE_FORMAT_SERVER, true).format(
                  DATE_FORMAT_MOMENT
                )}
              </div>
            ) : (
              <>
                <div className="w-100">
                  <ReactDatePicker
                    className="form-control w-100"
                    placeholderText="DD-MM-YYYY"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={
                      !!inbOrder?.ada ? new Date(inbOrder?.ada) : inbOrder?.ada
                    }
                    onChange={(d) =>
                      handleChange(
                        "ada",
                        !!d
                          ? moment(d, DATE_FORMAT_MOMENT, true).format(
                              DATE_FORMAT_SERVER
                            )
                          : d
                      )
                    }
                    minDate={
                      !!inbOrder?.orderDt
                        ? new Date(inbOrder?.orderDt)
                        : new Date()
                    }
                    disabled={inbOrder?.orderId > 0}
                  />
                </div>
                {errObj?.ada && (
                  <div>
                    <span className="text-danger fw-bold fs-6">
                      &#9888; This field is mandatory
                    </span>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>

        <Row className="mb-2">
          {!!inbOrder?.attachments &&
            Array.isArray(inbOrder?.attachments) &&
            inbOrder?.attachments?.map((attach, index) => (
              <Col key={index}>
                <div>
                  <label className="form-label theme-label">Attachment</label>
                  <div className="input-group pe-3">
                    <label className="form-control attachment-label">
                      <span className=" attachment-label-no-ellipsis">
                        {attach?.attachName}
                      </span>
                      <input
                        type="file"
                        className="UploadFilesty "
                        aria-describedby="button-addon2"
                        accept="application/pdf, image/jpeg, image/jpg"
                        id={`attachments.${index}`}
                        key={attach?.attachName}
                        onChange={(e) =>
                          handleAttch(
                            e,
                            0,
                            attach?.attachId,
                            attach?.attachType,
                            handleFileAttach
                          )
                        }
                        disabled={inbOrder?.orderId > 0}
                      />
                    </label>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      disabled={
                        typeof attach.attachUrl === "string" &&
                        attach.attachUrl.trim().length > 0
                          ? false
                          : true
                      }
                      onClick={() => DownloadFile(attach?.attachUrl)}
                    >
                      {GetSvgIcon("IconFiledownload")}
                    </button>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </UploadDataFile>
      <CustomToast
        showToast={showToast}
        closeToast={closeToast}
        headerMsg="ERROR"
        body="Upload Validation failed. Please try after some time!!"
      />
    </>
  );
}

import { Modal } from "react-bootstrap";

const ModalCustom = ({
  children,
  confirmAlertConfig,
  hideConfirmAlert,
  modalStyle,
  modalDynamicButtons,
}) => {
  return (
    <>
      <Modal
        size={confirmAlertConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={confirmAlertConfig.show}
        onHide={hideConfirmAlert}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className={modalStyle?.titelStyle}>
            {confirmAlertConfig.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">{children}</p>
        </Modal.Body>
        <Modal.Footer>{modalDynamicButtons}</Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCustom;

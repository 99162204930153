import React from "react";
import Popup from "../Layout/Popup";
import Header from "../Layout/Header";
import { useState } from "react";
import { initValUser } from "./intiValUser";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { getFileName, toBase64 } from "../Helper/CommonMethods";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_CLIENT,
  EMAIL,
  PATTERN_ERR_MSG,
  PHONE_NUM,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import { DownloadFile } from "../Helper/FileStoreService";
import moment from "moment";
import Spinner from "../Layout/Spinner";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { useEffect } from "react";
import { useRef } from "react";

const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
  photoFileName: "",
};

export default function StaffProfile() {
  const userId = getUserInfo("userId");
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [userMgm, setUserMgm] = useState(initValUser);
  const [stateCity, setStateCity] = useState();
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const [photoImg, setPhotoImg] = useState();
  const [photoImgErrMsg, setPhotoImgErrMsg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [imageData, setImageData] = useState(initPhotoFile);
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());
  const [cityListData, setCityListData] = useState();
  const [isDisabled, setDisabled] = useState({});
  const [show, setShow] = useState(false);

  const [cityListBack, setCityListBack] = useState();
  let navigate = useNavigate();
  const countryCodes = [
    { label: "+60", value: "+60" },
    { label: "+62", value: "+62" },
    { label: "+65", value: "+65" },
    { label: "+66", value: "+66" },
    { label: "+84", value: "+84" },
    { label: "+86", value: "+86" },
  ];
  const [sbuList, setSbuList] = useState([]);
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: userMgm });
  const { fields: userDetails } = useFieldArray({
    control,
    name: "userDetails",
  });
  useEffect(() => {
    getStaffDetailsById(userId);
  }, []);
  useEffect(() => {
    callAllService();
    setCityListData(cityData);
    setCityListBack(cityData);
    reset(userMgm);
    setValue("phone", userMgm.phone.substr(3));
    setValue("phoneCode", userMgm.phone.substr(0, 3));
  }, [userMgm]);

  const getStaffDetailsById = async (userId) => {
    const url = "getStaffProfileById/" + userId;
    let resp = await CallApiGet(url);
    let data = resp.respData;
    getStaffInfoDetailsByStaffId(data);
    data.userRoleId = data.userRoleId.map((e) => parseInt(e));
    setUserMgm(data);
    if (data?.profilePhoto && data?.profilePhoto?.photoBucketUrl !== "") {
      // GetBase64Img(data.profileDoc.photoBucketUrl).then((resp) => {
      setImageData(data.profilePhoto.photoBucketUrl);
      setValue("profilePhoto", data.profilePhoto);
      // });
    } else {
      setImageData("");
      setValue("profilePhoto", {
        photo: "",
        photoFileType: "",
        photoBucketUrl: "",
        photoFileName: "",
      });
    }
  };

  const getStaffInfoDetailsByStaffId = async (data) => {
    const uri = "getStaffDetailsById";
    let resp = await CallApiPost(uri, data);
    let proflieData = resp.respData;
    if (proflieData.length > 0) {
      setValue("userDetails", proflieData);
    } else {
      fetchUserInfoList();
    }
  };

  const callAllService = () => {
    getSBUList();
    fetchStatesAndCityList();
  };
  const getSBUList = async () => {
    let data = await CallApiGet("get-sbu-for-dropdown");
    if (data.respCode === 200) {
      setSbuList(data.respData);
    }
  };
  const fetchUserInfoList = async () => {
    let infoArr = [];
    let data = await CallApiGet("allUserTypeInfoList");

    if (data.respCode === 0) {
      data.respData.forEach((element) => {
        infoArr.push({
          infoId: element.id,
          documentType: element.optionValue,
          docNumber: "",
          expiredOn: "",
          remarks: "",
          infoAttachment: "",
        });
      });
      setValue("userDetails", infoArr);
    }
  };
  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
  };
  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      // setStatementFileLable("Choose a File ...");
    } else {
      var fileName = file[0].name;
      // setStatementFileLable(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      clearErrors("profilePhoto");
      setPhotoImgErrMsg();
    }
  };
  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });

      setCityListData(cityArrList);
    } else {
      setValue("city", "");
      setCityListData(cityListBack);
    }
  };
  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    }
  };
  const download = (fileName) => {
    window.open(fileName, "_blank");
    // if (fileName !== "") {
    //   setShow(true);
    //   DownloadFile(fileName);
    //   setShow(false);
    // }
    //}
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    setDisabled((state) => ({
      ...state,
      [key]: true,
    }));
    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const onSubmit = async (data) => {
    setShow(true);
    if (data.phone !== "") {
      data.phone = data.phoneCode + data.phone;
    }
    delete data.phoneCode;
    const uri = "saveStaffUser";
    let resp = await CallApiPost(uri, data);
    setShow(false);
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess("Profile has been updated successfully");
    } else if (resp.respCode === 400 || resp.respCode === 401) {
      setSubmitStatusFail(true);
      setFailMsg("{ \"userName\": \"Sorry, you don't have the write permission!!\", \"code\": \"\" }");
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else if (resp.respCode === 409) {
      setSubmitStatusFail(true);
      setFailMsg(resp.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else {
      onError(resp.respMessage);
    }
  };
  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
  };

  const onError = (error) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription("Something went wrong!!!");
    modalRef.current.click();
  };
  const formErrors = (err) => {
    console.log(err);
  };
  const callback = () => {
    getStaffDetailsById(userId);
  };

  const handleCancel = () => {
    navigate("/home");
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Profile</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="sf-profile my-3 overflow-auto">
                <div className="col-md-12 card card-body">
                  {submitStatusFail && (
                    <div id="errorMsg" className="alert alert-danger" role="alert">
                      {JSON.parse(failMsg).userName} <br />
                      {JSON.parse(failMsg).code}
                    </div>
                  )}
                  <div className="w-100">
                    <h5
                      className="modal-title section-title"
                      id="exampleModalLabel"
                    >
                      User Information
                    </h5>
                    <div className="row">
                      <div className="col-md-4 ">
                        <div
                          className="mb-1 file-placeholder thumbnail-pic"
                          {...register("profilePhoto")}
                        >
                          <input
                            type="file"
                            accept="image/png, image/jpeg "
                            onChange={selectPhotoFile}
                            key={fileInputDummyKey}
                          />
                          {
                            photoImg ? (
                              <img
                                // className="file-placeholder"
                                src={URL.createObjectURL(photoImg)}
                                alt="img"
                              />
                            ) : 
                            imageData !== "" ? (
                              <img
                                src={
                                  imageData !== ""
                                    ? imageData
                                    : ""
                                }
                                alt="img"
                              />
                            ) : (
                              <div>
                                {GetSvgUpload({ required: false })}
                                Upload Photo{" "}
                              </div>
                            )}
                        </div>
                      </div>
                      <input {...register("userTypeId")} hidden />
                      <div className="col-md-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          User Name<span className="text-danger">&#9733;</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Name"
                          aria-label="input example"
                          {...register("userName", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: ALPHANUM_SPACE_SPCL_CHAR,
                              message: PATTERN_ERR_MSG,
                            },
                          })}
                          maxLength="200"
                        />
                        {errors.userName && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.userName.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Staff Id/Account No.
                          <span className="text-danger">&#9733;</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Staff Id /Account No"
                          aria-label="input example"
                          {...register("accountId", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: ALPHANUM_SPACE_SPCL_CHAR,
                              message: PATTERN_ERR_MSG,
                            },
                          })}
                          maxLength="20"
                          disabled={true}
                        />
                        {errors.accountId && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.accountId.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Phone<span className="text-danger">&#9733;</span>
                        </label>
                        <div className="input-group">
                          <select
                            name="countryCode"
                            {...register("phoneCode")}
                            defaultValue="+60"
                            className="form-control country-code-customize"
                          >
                            {countryCodes.map((cc, index) => (
                              <option key={index} value={cc.value}>
                                {cc.label}
                              </option>
                            ))}
                          </select>

                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Phone"
                            aria-label="input example"
                            {...register("phone", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: PHONE_NUM,
                                message: PATTERN_ERR_MSG,
                              },
                            })}
                            maxLength="11"
                          />
                        </div>
                        {errors.countryCode && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.countryCode.message}
                            </span>
                          </div>
                        )}
                        {errors.phone && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.phone.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          User IC
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User IC"
                          aria-label="input example"
                          {...register("userIdCard", {
                            required: {
                              value: false,
                            },
                            pattern: {
                              value: ALPHANUM_SPACE_SPCL_CHAR,
                              message: PATTERN_ERR_MSG,
                            },
                          })}
                          maxLength="50"
                        />
                        {errors.userIdCard && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.userIdCard.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Email<span className="text-danger">&#9733;</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          aria-label="input example"
                          {...register("email", {
                            required: {
                              value: true,
                              message: REQUIRED_ERR_MSG,
                            },
                            pattern: {
                              value: EMAIL,
                              message: PATTERN_ERR_MSG,
                            },
                          })}
                          maxLength="200"
                          disabled={true}
                        />
                        {errors.email && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.email.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <input {...register("userRoleId")} hidden />
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          SBU <span className="text-danger">&#9733;</span>
                        </label>
                        <Controller
                          name="sbuId"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={sbuList}
                              placeholder="Select SBU"
                              value={
                                value
                                  ? sbuList.find(
                                    (opt) => opt.id === parseInt(value)
                                  )
                                  : value
                              }
                              onChange={(d) => onChange(d ? d.id : d)}
                              getOptionLabel={(option) => option.code}
                              getOptionValue={(option) => option.id}
                              isSearchable={true}
                              isClearable={true}
                              className="form-control  p-0"
                              isDisabled={true}
                            />
                          )}
                          rules={{ required: true }}
                        />
                        {errors.sbuId && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; This Field is required!!!
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          aria-label="input example"
                          {...register("address", {
                            required: {
                              value: false,
                            },
                            pattern: {
                              value: ALPHANUM_SPACE_SPCL_CHAR,
                              message: PATTERN_ERR_MSG,
                            },
                          })}
                          maxLength="50"
                        />
                        {errors.address && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.address.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          State
                        </label>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={stateData}
                              placeholder="Select State"
                              value={
                                value
                                  ? stateData.filter((opt) =>
                                    value.includes(opt.value)
                                  )
                                  : value
                              }
                              onChange={(d) =>
                                onChange(
                                  d ? d.value : d,
                                  filterCity(d ? d.value : d)
                                )
                              }
                              isSearchable={true}
                              isClearable={true}
                              className="form-control  p-0"
                            />
                          )}
                        />
                        {errors.state && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.state.message}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          City
                        </label>
                        {/* <Controller
                          name="city"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              options={cityListData}
                              placeholder="Select City"
                              value={
                                value
                                  ? cityListData.filter((opt) =>
                                    value.includes(opt.value)
                                  )
                                  : value
                              }
                              onChange={(d) =>
                                onChange(
                                  d ? d.value : d,
                                  filterState(d ? d.value : d)
                                )
                              }
                              isSearchable={true}
                              isClearable={true}
                              className="form-control  p-0"
                            />
                          )}
                        /> */}
                        <input
                          type="text"
                          className="form-control border-end-0 pe-0"
                          placeholder="City"
                          aria-label="Name"
                          aria-describedby="basic-addon1"
                          {...register("city", {
                            maxLength: {
                              value: 40,
                              message: "Exceeded max limit!!!",
                            },
                          })}
                          maxLength="40"
                        />
                        {errors.city && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.city.message}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="col-md-3 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Post Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="postCode"
                          {...register(
                            "postCode",
                            {
                              required: false,
                            },
                            {
                              value: ALPHANUM_SPACE_SPCL_CHAR,
                              message: PATTERN_ERR_MSG,
                            }
                          )}
                        />
                        {errors.postCode && (
                          <div>
                            <span className="badge bg-danger">
                              &#9888; {errors.postCode.message}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <h5
                      className="modal-title section-title m-auto"
                      id="exampleModalLabel"
                    >
                      User Details
                    </h5>
                    <div className="row">
                      <div className="col-lg-12 mb-2">
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Information</th>
                                <th scope="col">
                                  Doc Number{" "}
                                </th>
                                <th scope="col">
                                  Expires On{" "}
                                </th>
                                <th scope="col">Picture</th>
                                <th scope="col">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {userDetails.map((item, index) => (
                                  <>
                                    <tr key={item.id}>
                                      <td>
                                        {item.documentType}
                                        <input
                                          type="hidden"
                                          value={item.infoId}
                                          {...register(
                                            `userDetails.${index}.infoId`
                                          )}
                                        />{" "}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          placeholder="Doc Number"
                                          className="form-control"
                                          {...register(
                                            `userDetails.${index}.docNumber`,
                                            {
                                              // required: {
                                              //   value: true,
                                              //   message: REQUIRED_ERR_MSG,
                                              // },
                                              pattern: {
                                                value: ALPHANUM_SPACE_SPCL_CHAR,
                                                message: PATTERN_ERR_MSG,
                                              },
                                            }
                                          )}
                                          maxLength="250"
                                        // disabled={!isdeactive}
                                        />
                                        {errors.userDetails &&
                                          errors.userDetails[index]
                                            .docNumber && (
                                            <div>
                                              <span className="badge bg-danger">
                                                &#9888; This field is mandatory
                                              </span>
                                            </div>
                                          )}
                                      </td>
                                      <td>
                                        <Controller
                                          control={control}
                                          name={`userDetails[${index}].expiresOn`}
                                          // rules={{
                                          //   required: {
                                          //     value: true,
                                          //     message: REQUIRED_ERR_MSG,
                                          //   },
                                          // }}
                                          render={({
                                            field: { onChange, value },
                                          }) => {
                                            return (
                                              <ReactDatePicker
                                                placeholderText="Expiry Date"
                                                className="form-control"
                                                dateFormat={DATE_FORMAT_CLIENT}
                                                selected={
                                                  value
                                                    ? new Date(value)
                                                    : value
                                                }
                                                minDate={new Date()}
                                                onChange={(d) =>
                                                  onChange(
                                                    d !== null
                                                      ? moment(d).format(
                                                        "YYYY-MM-DD"
                                                      )
                                                      : ""
                                                  )
                                                }
                                              />
                                            );
                                          }}
                                        />
                                        {errors.userDetails &&
                                          errors.userDetails[index]
                                            .expiresOn && (
                                            <div>
                                              <span className="badge bg-danger">
                                                &#9888; This field is mandatory
                                              </span>
                                            </div>
                                          )}
                                      </td>
                                      <td>
                                        <div
                                          className="Upload-Photo w-100 mb-2"
                                          {...register(
                                            `userDetails.${index}.infoAttachment`
                                            // ,
                                            // {
                                            //   validate: (v) =>
                                            //     v.photoBucketUrl !== null &&
                                            //     v.photoBucketUrl !== "",
                                            // }
                                          )}
                                        >
                                          <input
                                            type="file"
                                            onChange={(e) =>
                                              selectFile(
                                                `userDetails.${index}.infoAttachment`,
                                                e
                                              )
                                            }
                                          />
                                          <div className="uploaded-text ms-2">
                                            {GetSvgUpload({ required: false })}
                                            {watch(
                                              `userDetails.${index}.infoAttachment.photoBucketUrl`
                                            ) === null ||
                                              watch(
                                                `userDetails.${index}.infoAttachment.photoBucketUrl`
                                              ) === "" ||
                                              watch(
                                                `userDetails.${index}.infoAttachment.photoBucketUrl`
                                              ) === "null"
                                              ? "Upload Image"
                                              : watch(
                                                `userDetails.${index}.infoAttachment.photoFileName`
                                              )}
                                          </div>
                                          <button
                                            type="button"
                                            className="btn btn-outline-secondary theme-violet position-absolute rounded-0"
                                            disabled={
                                              !(
                                                watch(
                                                  `userDetails.${index}.infoAttachment.photo`
                                                ) === "" &&
                                                watch(
                                                  `userDetails.${index}.infoAttachment.photoFileName`
                                                ) !== ""
                                              )
                                            }
                                            onClick={() =>
                                              download(
                                                watch(`userDetails.${index}.infoAttachment.photoBucketUrl`)
                                              )
                                            }
                                          >
                                            {GetSvgIcon("IconFiledownload")}
                                          </button>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          {...register(
                                            `userDetails.${index}.remarks`,
                                            {
                                              pattern: {
                                                value: ALPHANUM_SPACE_SPCL_CHAR,
                                                message: PATTERN_ERR_MSG,
                                              },
                                            }
                                          )}
                                          maxLength="20"
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  className="btn btn-default border me-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={handleSubmit(onSubmit, formErrors)}
                >
                  Save
                </button>
              </div>
            </div>
            <Spinner show={show} />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { NUMBER_REGEX, NUMBER_TWO_DECIMAL } from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";

const VasChargeAddEdit = ({
  modalConfig,
  onHide,
  agentList,
  selVas,
  onSubmit,
}) => {
  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors,isDirty },
  } = useForm({ defaultValues: selVas });

  const {
    fields: vas,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "vas",
  });
  const [formVal,setFormVal]=useState();

  const handleClose = () => {
    onHide();
  };

  useEffect(() => {
    reset(selVas);
  }, [selVas]);

  const watchCustId = watch("customerId");

  // const ChargesComp = ({ item, register, getValues, index, errors }) => {
    const ChargesComp = ( item,index ) => {
    return (
      <Row>
        <div className="rate-container w-100">
          <div className="rate-border-text theme-label fw-bold font-12">
            {item?.serviceName}
          </div>
          <Row>
            <Col md={6}>
              <label className="form-label">Charge</label>
              <input
                type="number"
                className="form-control"
                name={`vas.${index}.charge`}
                {...register(`vas.${index}.charge`, {
                  required: {
                    value: true,
                    message: "This field is required!!",
                  },
                  pattern: {
                    value: NUMBER_TWO_DECIMAL,
                    message: "Accepted Decimal Place Should Be Two.",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than or equal to 0.",
                  },
                  max: {
                    value: 999.99,
                    message: "Value must less than or equal to 999.99",
                  },
                })}
              />
              {!!errors?.vas && errors?.vas[index]?.charge && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.vas[index]?.charge?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col md={6}>
              <label className="form-label">Charges Per KM Beyond Limit</label>
              <input
                type="number"
                className="form-control"
                name={`vas.${index}.extraCharge`}
                {...register(`vas.${index}.extraCharge`, {
                  required: {
                    value: true,
                    message: "This field is required!!",
                  },
                  pattern: {
                    value: NUMBER_TWO_DECIMAL,
                    message: "Accepted Decimal Place Should Be Two.",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than or equal to 0.",
                  },
                  max: {
                    value: 999.99,
                    message: "Value must less than or equal to 999.99",
                  },
                })}
              />
              {!!errors?.vas && errors?.vas[index]?.extraCharge && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.vas[index]?.extraCharge?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="form-label">Sub-break Threshold<span className="v-text"> (Pallets)</span></label>
              <input
                type="number"
                className="form-control"
                name={`vas.${index}.subBreakThreshold`}
                {...register(`vas.${index}.subBreakThreshold`, {
                  required: {
                    value: true,
                    message: "This field is required!!",
                  },
                  pattern: {
                    value: NUMBER_REGEX,
                    message: "whole Number is accepted only!!",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than or equal to 0",
                  },
                  max: {
                    value: 999,
                    message: "Value must less than or equal to 999",
                  },
                })}
              />
              {!!errors?.vas && errors?.vas[index]?.subBreakThreshold && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.vas[index]?.subBreakThreshold?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col md={6}>
              <label className="form-label">Sub-break Fee</label>
              <input
                type="number"
                className="form-control"
                name={`vas.${index}.subBreakFee`}
                {...register(`vas.${index}.subBreakFee`, {
                  required: {
                    value: true,
                    message: "This field is required!!",
                  },
                  pattern: {
                    value: NUMBER_TWO_DECIMAL,
                    message: "Accepted Decimal Place Should Be Two.",
                  },
                  min: {
                    value: 0,
                    message: "Value must be greater than or equal to 0.",
                  },
                  max: {
                    value: 999.99,
                    message: "Value must less than or equal to 999.99",
                  },
                })}
              />
              {!!errors?.vas && errors?.vas[index]?.subBreakFee && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.vas[index]?.subBreakFee?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Row>
    );
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5 className="modal-title section-title">{modalConfig?.title}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col md={12}>
                <label className="form-label">
                  Sales Agent
                  <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  name="customerId"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!!",
                    },
                    min: { value: 1, message: "This field is required!!" },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder="Select Sales Agent"
                      isSearchable={true}
                      isClearable={true}
                      className="form-control p-0"
                      options={agentList}
                      value={
                        !!value
                          ? agentList?.find((opt) => opt.value === value)
                          : value
                      }
                      onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                      isDisabled={!!selVas.id}
                      // isMulti={true}
                    />
                  )}
                />
                {errors?.customerId && (
                <div>
                  <span className="text-danger fw-bold font-12">
                    &#9888; {errors?.customerId.message}
                  </span>
                </div>
              )}
              </Col>
            </Row>
            {vas.map((item, index) => (
              // <ChargesComp
              //   register={register}
              //   errors={errors}
              //   getValues={getValues}
              //   index={index}
              //   item={item}
              //   key={index}
              // />
              ChargesComp(item,index)
            ))}
          </Container>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            className="theme-violet"
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit, onError)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VasChargeAddEdit;

import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import BASE_URL from "../Helper/serviceApi";
import Spinner from "../Layout/Spinner";

export default function ForgotPassword() {
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [submitStatusSuccess, setSubmitStatusSuccess] = useState(false);
  const [successMsg, SetSuccessMsg] = useState();
  const [failMsg, SetFailMsg] = useState();
  const cancelRef = useRef();
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setShow(true);
    await axios
      .post(`${BASE_URL}/forgot-password`, data)
      .then((res) => {
        const code = res.data.respCode;
        const status = res.data.respData;
        
        if (code === 200 && status) {
          clearData();

          setSubmitStatusSuccess(true);
          SetSuccessMsg(res.data.respMessage);
          setTimeout(() => {
            setSubmitStatusSuccess(false);
            cancelRef.current.click();
          }, 5000);
        } else {
          clearData();

          setSubmitStatusFail(true);
          SetFailMsg(
            "This email is either not registered with the system or not active yet. Try again with a valid email."
          );
          setTimeout(() => {
            setSubmitStatusFail(false);
          }, 5000);
        }
        setShow(false);
      })
      .catch(function (error) {
        clearData();
        setSubmitStatusFail(true);
        SetFailMsg(error.response.data.respMessage);
        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 5000);
        setShow(false);
      });
  };
  const clearData = () => {
    clearErrors();
    reset({ userEmail: "" });
  };
  return (
    <div
      className="modal fade"
      id="button-forgot-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Forgot Password
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearData}
              ref={cancelRef}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="w-100">
                <div className="col-md-6" style={{ alignItems: "center" }}>
                  <label
                    htmlFor="floatingInputGrid"
                    className="form-label theme-label"
                  >
                   Please key in your email address to receive reset password link
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="userEmail"
                      name="userEmail"
                      {...register("userEmail", {
                        required: true,
                        pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                      })}
                    />
                  </div>
                  {errors.userEmail && (
                    <div className="col-12 input-group mb-3">
                      <span className="badge bg-danger">
                        &#9888; This Field cannot be empty!
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {submitStatusFail && (
            <div id="errorMsg" className="alert alert-danger" role="alert">
              {failMsg}
            </div>
          )}
          {submitStatusSuccess && (
            <div id="errorMsg" className="alert alert-success" role="alert">
              {successMsg}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="reset"
              className="btn btn-default border"
              data-bs-dismiss="modal"
              onClick={clearData}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(onSubmit)}
              // data-bs-toggle="modal"
              // data-bs-target="#success-modal"
            >
              Validate
            </button>
          </div>
        </div>
      </div>
      <Spinner show={show} />
    </div>
  );
}

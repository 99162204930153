import React, { useEffect } from 'react'
import Layout from '../Layout/Layout'
import { useState } from 'react';
import DataTable from 'react-data-table-component-with-filter';
import { DATE_FORMAT_MOMENT, customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from '../Helper/Constants';
import moment from 'moment';
import { CallApiGet } from '../Helper/serviceApi';

export default function BillingList() {
  const [spinner, setSpinner] = useState(false);
  const pageTitle = `Billing List`;
  const [billingData, setBillingData] = useState([]);
  // const []
  const columns = [
    {
      name: "Account Code",
      selector: (row) => row.accontCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Bill No.",
      selector: (row) => row.billNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Bill Date",
      selector: (row) => moment(row.billDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      filterable: true,
    },
    {
      name: "Order No",
      selector: (row) => row.orderNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice No.",
      selector: (row) => (
        <>
          <div onClick={() => getOrderDtls(row.invoiceNo)} className="modal-links fw-bold">{row.invoiceNo}</div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate ? moment(row.invoiceDate).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
    },
  ]
  useEffect(() => {
    getAllBillingList()
  }, [])
  const getAllBillingList = async () => {
    let data = await CallApiGet("getAllBillingList");
    if (data.respCode === 200) {
      setBillingData(data.respData);
    }
  }
  const getOrderDtls = (orderData) => {

    console.log(orderData);
  }
  return (
    <>
      <Layout spinnershow={spinner}>
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
        </div>
        <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
          <DataTable
            columns={columns}
            data={billingData}
            customStyles={customDataTableStyles}
            pagination
            striped
            highlightOnHover
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        </div>
      </Layout>
    </>
  )
}

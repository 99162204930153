import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  SYS_PARTNER_TYPE,
} from "../../../ConfigConstants/ConfigConstants";
import logo from "../../../images/LinehaulLogo.png";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Footer from "../../Layout/Footer";
import Popup from "../../Layout/Popup";
import PartnerBusiness from "./PartnerBusiness";
import PartnerPersonal from "./PartnerPersonal";
import Spinner from "../../Layout/Spinner";

const initPartnerType = [
  {
    id: 0,
    optionValue: "",
  },
];

const initSelectedPartnerType = {
  id: 0,
  optionValue: "",
};

const PartnerMain = () => {
  const { state } = useLocation();
  const [show, setShow] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [partnerType, setPartnerType] = useState(initPartnerType);
  const [selectedPartnerType, setSelectedPartnerType] = useState(
    initSelectedPartnerType
  );
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    // loadPartnerType()
    setIsBusiness(state?.stateCustType === LINEHAUL_PARTNER_COMPANY_USER_TYPE);
    setSelectedPartnerType(
      SYS_PARTNER_TYPE.find((pt) => pt.id === state?.stateCustType)
    );
  }, []);

  const toggleIsBusiness = (event) => {
    // let temp = partnerType.filter(data => data.id === event.target.value)[0];
    let temp = partnerType.find(
      (data) => data.id === parseInt(event.target.value)
    );
    setSelectedPartnerType(temp);
    console.log(temp);
    setIsBusiness(temp.id === parseInt(LINEHAUL_PARTNER_COMPANY_USER_TYPE));
  };

  // const loadPartnerType = async () => {
  // 	let res = await CallApiGet("list-partner-type")
  // 	let data = res.respData;

  // 	setPartnerType(data)
  // 	setIsBusiness(data[0].id === parseInt(LINEHAUL_PARTNER_COMPANY_USER_TYPE))
  // 	setSelectedPartnerType(data[0]);
  // }

  const callback = () => {
    navigate("/home");
  };

  const onSuccess = () => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(
      `Thank you for registering with us! We have sent an email to your registered email address with a link to reset your password.`
    );
    modalRef.current.click();
  };

  const onError = (msg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(msg);
    modalRef.current.click();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const saveData = async (model) => {
    setShow(true);
    let res = await CallApiPost("register-partner", model);
    setShow(false);
    if (res.respCode === 200) {
      onSuccess();
    } else if (res.respCode === 400) {
      openPopup(false, "Error", res.respMessage);
    } else if (res.respCode === 401) {
      openPopup(false, "Unauthorized Access", "Your session has been expired.");
      localStorage.clear();
      navigate("/home");
    } else if (res.respCode === 409) {
      openPopup(false, "Error", res.respMessage);
    } else {
      onError("Something went wrong. Please try after sometime!!!");
    }
  };

  return (
    <div className="backdrop-image-register">
      <div className="container-fluid">
        <div className="row">
          <main className="col-md-12 p-3">
            <img
              src={logo}
              className="img-fluid mx-auto d-block"
              alt="LinehaulLogo"
              height="100px"
              width="=200px"
            />
            <div className="Signupbg Registerform m-auto py-4 col-md-8">
              <div className="px-4">
                <div className="Form-title mb-1">Register Here!</div>
                {/* <div className="row py-2">
									<div className="col-md-6">
										{
											partnerType.map((type, index) => (
												<div key={index} className="form-check form-check-inline">
													<input className="form-check-input radiobtnsty" type="radio"
														name="registrationType" id={"registrationType" + type.id}
														value={type.id}
														defaultChecked={type.optionValue === selectedPartnerType.optionValue}
														onChange={(event) => toggleIsBusiness(event)}
													/>
													<label className="Form-Lable" htmlFor={"registrationType" + type.id}>{type.optionDesc}</label>
												</div>
											))
										}

									</div>
								</div> */}
                {isBusiness ? (
                  <PartnerBusiness
                    partnerTypeData={selectedPartnerType}
                    saveData={saveData}
                  />
                ) : (
                  <PartnerPersonal
                    partnerTypeData={selectedPartnerType}
                    saveData={saveData}
                  />
                )}
              </div>
              <button
                data-bs-toggle="modal"
                data-bs-target="#success-modal"
                id="modalPopup"
                className="d-none"
                ref={modalRef}
              />
            </div>
            <Popup
              isSuccess={isSuccess}
              title={title}
              description={description}
              handler={callback}
            />
          </main>
        </div>
      </div>

      <Spinner show={show} />
      <Footer />
    </div>
  );
};

export default PartnerMain;

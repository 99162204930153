import React, { useEffect, useState } from 'react'
import ModalLayout from "../Layout/ModalLayout";
import { Col, Container, Row } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { DATE_FORMAT_SERVER,DATE_FORMAT_CLIENT } from '../Helper/Constants';
import Select from "react-select";
import moment from 'moment';

const FtlLastMileRePlanningModal = ({ closeReplanningModal, replanningModalConfig, vehicles, drivers,doRePlanning,showAlert,selectedRowData }) => {
    const [tripDate, setTripDate] = useState(selectedRowData?.shipment_date);
    const [tripTime, setTripTime] = useState(
        (selectedRowData?.vehicle_id === null ||
          selectedRowData?.vehicle_id === 0) &&
          (selectedRowData?.driver1 === null || selectedRowData?.driver1 === 0)
          ? moment().format("HH:mm")
          : selectedRowData?.trip_start_time
      );
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);

    useEffect(() => {
        setVehicle(selectedRowData?.vehicle_id)
        setDriver(selectedRowData?.driver1)
      }, [selectedRowData]);

    const rePlan = () =>{
        
        if(tripDate===null){
            showAlert({
                isSuccess: false,
                title: "ERROR",
                description: 'Select a trip date'
            })
        }else if(vehicle===null){
            showAlert({
                isSuccess: false,
                title: "ERROR",
                description: 'Choose one vehicle'
            })
        }else if(driver===null){
            showAlert({
                isSuccess: false,
                title: "ERROR",
                description: 'Choose one driver'
            })
        }else if(tripTime===null){
            showAlert({
                isSuccess: false,
                title: "ERROR",
                description: 'Select Trip Time'
            })
        }else{
            doRePlanning(vehicle,driver,tripDate,tripTime)
        }
        
    }

    const header = (
        <h5 className="modal-title section-title">Planning</h5>
    )

    const footer = (
        <button className="btn btn-outline-secondary theme-violet text-nowrap mr-5" type="button" 
            onClick={rePlan} disabled={(tripDate===null || vehicle===null || driver===null || tripTime===null)}>
            Submit
        </button>
    )

    return (

        <>
            {
                !!replanningModalConfig &&
                <ModalLayout
                    moadlConfig={replanningModalConfig}
                    header={header}
                    footer={footer}
                    handleMoalClose={closeReplanningModal}
                >
                    <Container>
                        <Row>
                        <Col md="6" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label"
                                    title="This field is mandatory">Vehicle {}<span
                                        className="text-danger">&#9733;</span></label>
                                <Select
                                    options={vehicles}
                                    placeholder="Select Vehicle"
                                    value={
                                        vehicle === ""
                                            ? ""
                                            : vehicles.find((opt) => opt.value === vehicle)
                                    }
                                    onChange={(d) => setVehicle(d ? d.value : d)}
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control  p-0"
                                />
                            </Col>
                            <Col md="6" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label"
                                    title="This field is mandatory">Driver<span
                                        className="text-danger">&#9733;</span></label>
                                <Select
                                    options={drivers}
                                    placeholder="Select Driver"
                                    value={
                                        driver === ""
                                            ? ""
                                            : drivers.find((opt) => opt.value === driver)
                                    }
                                    onChange={(d) => setDriver(d ? d.value : d)}
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control  p-0"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label"
                                    title="This field is mandatory">Trip Date<span
                                        className="text-danger">&#9733;</span></label>
                                <div>
                                <ReactDatePicker
                                    placeholderText="Select Date"
                                    className="form-control w-100"
                                    dateFormat={DATE_FORMAT_CLIENT}
                                    selected={
                                        tripDate ? new Date(tripDate) : tripDate
                                    }
                                    onFocus={e => e.target.blur()}
                                    minDate={new Date()}
                                    onChange={(d) =>
                                        setTripDate(
                                            d !== null
                                                ? moment(d).format(DATE_FORMAT_SERVER)
                                                : ""
                                        )
                                    }
                                    onSelect={(d) =>
                                        setTripDate(
                                            d !== null
                                                ? moment(d).format(DATE_FORMAT_SERVER)
                                                : ""
                                        )
                                    }
                                />
                                </div>
                                
                            </Col>
                            <Col md="3" className='mb-1'>
                                <label htmlFor="" className="form-label theme-label"
                                    title="This field is mandatory">Time<span
                                        className="text-danger">&#9733;</span></label>
                                <input type='time' className='form-control' defaultValue={tripTime}
                                    onChange={(e) => setTripTime(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ModalLayout>
            }
        </>
    )
}

export default FtlLastMileRePlanningModal
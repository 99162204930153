import React from "react";
import {
  REQUIRED_ERR_MSG,
  customReactSelectMenuStyle,
} from "../../Helper/Constants";
import { Controller } from "react-hook-form";
import Select from "react-select";

const CustomNotificationAdd = (props) => {
  const {
    handelClear,
    control,
    handleSubmit,
    register,
    onError,
    onSubmit,
    errors,
    sbuType,
    sbuOptions,
    roleOptions,
    getValues,
    setValue,
    watchVisibility,
    filterForSelectedSbuType,
  } = props;

  const handelRoleOnChange = (controlName) => {
    const roles = getValues(controlName);
    const newRole = roles[roles.length - 1];

    if (roles.length === 0) {
      setValue(controlName, [0]);
      if (controlName === "sbuType" && newRole === undefined) {
        setValue("sbuCode", [0]);
      }
    } else {
      if (roles.includes(0) && newRole !== 0) {
        const index = roles.indexOf(0);
        if (index > -1) roles.splice(index, 1);
        setValue(controlName, roles);
      } else if (roles.includes(0) && newRole === 0) {
        setValue(controlName, [0]);

        if (controlName === "sbuType") {
          setValue("sbuCode", [0]);
        }
      }
    }

    filterForSelectedSbuType();
  };

  return (
    <div className="col-md-6 m-auto mt-3">
      <div className="">
        <div className="form-check form-check-inline me-4">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="all"
            {...register("visibility")}
          />
          <label
            className="form-check-label form-label theme-label fw-bold"
            htmlFor="inlineRadio1"
          >
            All
          </label>
        </div>
        <div className="form-check form-check-inline me-4">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="byRole"
            {...register("visibility")}
          />
          <label
            className="form-check-label form-label theme-label fw-bold"
            htmlFor="inlineRadio2"
          >
            By Role
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="bySbu"
            {...register("visibility")}
          />
          <label
            className="form-check-label form-label theme-label fw-bold"
            htmlFor="inlineRadio3"
          >
            By SBU
          </label>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="my-3">
              <label
                className="form-check-label theme-label"
                htmlFor="inlineCheckbox2"
              >
                Notification
              </label>
              <input
                type="text"
                className="form-control"
                {...register("notification", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              {errors.notification && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.notification.message}
                  </span>
                </div>
              )}
            </div>
            {/* <div className="mb-2">
              <label className="form-check-label theme-label p-2">Date</label>
              <Controller
                control={control}
                name="date"
                rules={{
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <ReactDatePicker
                    placeholderText=" Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    // disabled={ptatus === "Published" ? true : false}
                    // minDate={new Date()}
                    // maxDate={new Date(watchEndDate)}
                    selected={value ? new Date(value) : value}
                    onChange={(d) =>
                      onChange(d !== null ? moment(d).format("YYYY-MM-DD") : "")
                    }
                  />
                )}
              />
              {errors.date && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.date.message}
                  </span>
                </div>
              )}
            </div> */}

            {watchVisibility === "byRole" && (
              <div className="mb-3">
                <label
                  className="form-check-label theme-label"
                  htmlFor="inlineCheckbox2"
                >
                  Role
                </label>
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={roleOptions}
                      placeholder="Select Role"
                      value={roleOptions?.filter((opt) =>
                        value.includes(opt.value)
                      )}
                      onChange={(d) => {
                        onChange(d ? d.map((opt) => opt.value) : []);
                        handelRoleOnChange("role");
                      }}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      className="form-control  p-0"
                      menuPortalTarget={document.body}
                      styles={customReactSelectMenuStyle}
                    />
                  )}
                />
                {errors.role && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.role.message}
                    </span>
                  </div>
                )}
              </div>
            )}

            {watchVisibility === "bySbu" && (
              <div>
                <div className="mb-3">
                  <label
                    className="form-check-label theme-label"
                    htmlFor="inlineCheckbox2"
                  >
                    SBU Type
                  </label>
                  <Controller
                    name="sbuType"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={sbuType}
                        placeholder="Select Role"
                        value={sbuType?.filter((opt) =>
                          value.includes(opt.value)
                        )}
                        onChange={(d) => {
                          onChange(d ? d.map((opt) => opt.value) : []);
                          handelRoleOnChange("sbuType");
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        className="form-control  p-0"
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors.sbuType && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.sbuType.message}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label
                    className="form-check-label theme-label"
                    htmlFor="inlineCheckbox2"
                  >
                    SBU Code
                  </label>
                  <Controller
                    name="sbuCode"
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={sbuOptions}
                        placeholder="Select Role"
                        value={sbuOptions?.filter((opt) =>
                          value.includes(opt.value)
                        )}
                        onChange={(d) => {
                          onChange(d ? d.map((opt) => opt.value) : []);
                          handelRoleOnChange("sbuCode");
                        }}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        className="form-control  p-0"
                        menuPortalTarget={document.body}
                        styles={customReactSelectMenuStyle}
                      />
                    )}
                  />
                  {errors.sbuCode && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors.sbuCode.message}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-3 text-end ">
        <button
          type="button"
          className="btn btn-default border me-2"
          onClick={handelClear}
        >
          Clear
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary theme-violet me-2"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Publish
        </button>
        {/* <button
          type="button"
          className="btn btn-outline-secondary theme-violet"
          
          onClick={handleSubmit(handelOnDraft, onError)}
        >
          Save as Draft
        </button> */}
      </div>
    </div>
  );
};

export default CustomNotificationAdd;

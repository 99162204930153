import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import GetSvgIcon from "../../images/svglist";
import {
  ALPHABET_REGEX,
  ALPHANUM_SPACE_SPCL_CHAR,
  PATTERN_ERR_MSG,
  SERVER_ERR_MSG,
} from "../Helper/Constants";
import { CallApiPost } from "../Helper/serviceApi";

const ContentDocAdd = (props) => {
  const {
    country,
    deliveryPort,
    contentData,
    openPopup,
    intiShipmentData,
    initDoc,
    clearShipment,
  } = props;
  const [isHubDisabled, setHubDisabled] = useState(false);
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [docAvl, setDocAvl] = useState(false);
  const [failMsg, setFailMsg] = useState();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: contentData,
  });
  const {
    fields: docs,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "contentDocs",
  });

  useEffect(() => {
    reset(contentData);
  }, [contentData]);

  const updateHubField = (selectedCountry) => {
    if (selectedCountry.value === "MY") {
      setHubDisabled(true);
      setValue("hubId", "");
    } else {
      setHubDisabled(false);
      setValue("hubId", "");
    }
  };

  const onSubmit = async (formData) => {
    if(formData.contentDocs.length!==0) {
    const data = await CallApiPost("saveShipment", formData);
    if (data.respCode === 200 || data.respCode === 0) {
      console.log("==SUCCESS, DATA SAVED==");
      openPopup(true, "SUCCESS", "Shipment data saved successfully!!!");
    } else if (data.respCode === 400) {
      setSubmitStatusFail(true);
      setFailMsg(data.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 5000);
    } else {
      console.error("==INTERNAL SERVER ERROR==");
      openPopup(false, "ERROR", SERVER_ERR_MSG);
    }
}else{
    setDocAvl(true)
}
  };

  const onFormErr = (err) => {
    console.log("==ERROR==");
    console.log(err);
    console.log("==INCOMING DATA==");
    console.log(getValues());
  };

  const clearData = () => {
    if (contentData.shipmentId === 0) {
      reset(intiShipmentData);
      setDocAvl(false)
    } else {
      reset(contentData);
      setDocAvl(false)
    }
  };

  return (
    <div
      className="modal fade"
      id="shipment-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              {contentData.shipmentId === 0 ? "Add" : "Edit"} Shipment
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearShipment}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              {submitStatusFail && (
                <div id="errorMsg" className="alert alert-danger" role="alert">
                  {" "}
                  {failMsg}{" "}
                </div>
              )}
              <div className="w-100">
                <div className="row">
                  <div className="col-md-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Destination Country
                    </label>
                  </div>
                  <div className="col-md-4 mb-2">
                    <Controller
                      name="countryCode"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select Destination Country",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={country}
                          placeholder="Select Country"
                          value={
                            value === ""
                              ? value
                              : country.find((con) => con.value === value)
                          }
                          onChange={(val) => {
                            onChange(val ? val.value : val);
                            updateHubField(val);
                          }}
                          className="form-control  p-0"
                        />
                      )}
                    />
                    {errors?.countryCode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888;{errors?.countryCode?.message}{" "}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Delivery Port
                    </label>
                  </div>
                  <div className="col-md-4 mb-2">
                    <Controller
                      name="hubId"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "Please select Delivery Port",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={deliveryPort}
                          placeholder="Select Delivery Port"
                          value={
                            value === ""
                              ? value
                              : deliveryPort.find((opt) => opt.value === value)
                          }
                          onChange={(val) => onChange(val ? val.value : val)}
                          className="form-control  p-0"
                          isDisabled={isHubDisabled}
                        />
                      )}
                    />
                    {errors?.hubId && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888;{errors?.hubId?.message}{" "}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Content
                    </label>
                  </div>
                  <div className="col-md-4 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Content Type"
                      {...register("contentType", {
                        required: {
                          value: true,
                          message: "Please Add Content",
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                    />
                    {errors?.contentType && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors?.contentType?.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Remarks
                    </label>
                  </div>
                  <div className="col-md-10 mb-2">
                    <input
                      type="text"
                      placeholder="Remarks"
                      className="form-control"
                      {...register("remarks", {
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="fw-bold mt-4 theme-violet-txt border-bottom">
                    Document Required &nbsp;
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Description</th>
                        <th>
                          <div className="px-2 text-success">
                            <div
                              onClick={() => (
                                append(JSON.parse(JSON.stringify(initDoc))),setDocAvl(false)
  )}
                            >
                              {GetSvgIcon("PlusCircleNoFill")}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {docs &&
                        docs.map((data, index) => {
                          let { id, documentName, documentRemarks } = data;
                          return (
                            <tr key={id}>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Document"
                                  name={`contentDocs.${index}.documentName`}
                                  {...register(
                                    `contentDocs.${index}.documentName`,
                                    {
                                        required: {
                                            value: true,
                                            message: "Document name is required",
                                          },
                                      pattern: {
                                        value: ALPHABET_REGEX,
                                        message: "Accepts Alphabets!",
                                      },
                                    }
                                  )}
                                  defaultValue={documentName}
                                  maxLength="40"
                                />
                                {errors.contentDocs &&
                                  errors?.contentDocs[index]?.documentName && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888;{" "}
                                        {
                                          errors?.contentDocs[index]
                                            ?.documentName?.message
                                        }
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="description"
                                  name="documentRemarks"
                                  {...register(
                                    `contentDocs.${index}.documentRemarks`,
                                    {
                                        required: {
                                            value: true,
                                            message: "Document description is required",
                                          },
                                      pattern: {
                                        value: ALPHABET_REGEX,
                                        message: "Accepts Alphabets!",
                                      },
                                    }
                                  )}
                                  defaultValue={documentRemarks}
                                  maxLength="100"
                                />
                                {errors.contentDocs &&
                                  errors?.contentDocs[index]
                                    ?.documentRemarks && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888;{" "}
                                        {
                                          errors?.contentDocs[index]
                                            ?.documentRemarks?.message
                                        }
                                      </span>
                                    </div>
                                  )}
                              </td>
                              <td>
                                <div
                                  className="px-2 text-danger"
                                  onClick={() => remove(index)}
                                >
                                  {GetSvgIcon("DashCircleNoFill")}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                        {docAvl && <tr><td><span className="badge bg-danger">Please Add Document</span></td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="reset"
              className="btn btn-default border"
              onClick={clearData}
            >
              {contentData.shipmentId === 0 ? "Clear" : "Reset"}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(onSubmit, onFormErr)}
              disabled={!isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentDocAdd;

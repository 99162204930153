 export const initValPartnerPrf ={
    userId : 0,
    partnerId : 0,
    companyName :'',  
    companyRegistrationNo : '',
    driverName : '',
    address : "",
    email : '',
    phone : "",
    bankName : "",
    beneficiaryName : '',
    bankAccount : '',
    bankDoc: {
      photo: "",
      photoFileName : "",
      photoFileType: "",
      photoBucketUrl: "",
    },
    postCode : '',
    partnerType : '',
    accountCode : '',
    debtorCode : '',
    ctosCheck : '',
    vehiclePhotoFront: {
      photo: null,
      photoFileName: null,
      photoFileType: null,
      photoBucketUrl: null
    },
    vehiclePhotoRight: {
      photo: null,
      photoFileName: null,
      photoFileType: null,
      photoBucketUrl: null
    },
    vehiclePhotoLeft: {
      photo: null,
      photoFileName: null,
      photoFileType: null,
      photoBucketUrl: null
    },
    vehiclePhotoBack: {
      photo: null,
      photoFileName: null,
      photoFileType: null,
      photoBucketUrl: null
    },
    userInfoList :[{
      infoCode : 0,
      infoName : '',
      infoValue : '',
      infoExpiry : '',
      infoAttachment: {
        photo: null,
        photoFileName: null,
        photoFileType: null,
        photoBucketUrl: null
    }
    }],
    vehicleList : [{
      vhclId : 0,
      vhclType : 0,
      vhclNo : '',
      vhclOwner : '',
      allocatedTo : 0,
      transferFrom : '',
      transferTo : '',
      transferSbu : 0,
      vhclImgF: {
        photo: null,
        photoFileName: null,
        photoFileType: null,
        photoBucketUrl: null
      },
      vhclImgB: {
        photo: null,
        photoFileName: null,
        photoFileType: null,
        photoBucketUrl: null
      },
      vhclImgL: {
        photo: null,
        photoFileName: null,
        photoFileType: null,
        photoBucketUrl: null
      },
      vhclImgR: {
        photo: null,
        photoFileName: null,
        photoFileType: null,
        photoBucketUrl: null
      },
      vhclInfos : [{
        id : 0,
        vhclId : 0,
        infoCode : '',
        infoDesc : '',
        infoValue : '',
        expyDate : '',
        attachment1 : '',
        remarks : '',
        attachmentUrl : ''
      }],
      maxWeight: 0
    }],
    remarks : '',
    phoneCode : ''
 }
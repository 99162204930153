import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";

const CreditNoteInvoiceDtls = ({ invCols, cnList, creditData }) => {
  return (
    <div>
      <div className="theme-blue border border-1 rounded-2 p-2 ">
        {/* <div className="text-center f"><h3>Current Statement Amount</h3></div> */}
        <div className="row">
          <div className="col my-2 ">
            <div className="fw-bold">
            Total CN Amount :{" RM "}
              <span className="font-18"> {creditData?.amount?.toFixed(2)}</span>
            </div>
          </div>
          <div className="col my-2 ">
            <div className="fw-bold">
              Total Invoice Amt. :{" RM "}
              <span className="font-18">
                {" "}
                {!!creditData.inv_amt ? creditData.inv_amt?.toFixed(2) : "0.00"}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col my-2 ">
            <div className="fw-bold">
            Utilized CN Amount :{" RM "}
              <span className="font-18">
                {" "}
                {!!creditData.utl_amt ? creditData.utl_amt?.toFixed(2) : "0.00"}
              </span>
            </div>
          </div>
          <div className="col my-2 ">
            <div className="fw-bold">
            Balance CN Amount :
              <span className="font-18">
                {" RM"}
                {!!creditData.avl_amount
                  ? creditData?.avl_amount?.toFixed(2)
                  : "0.00"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 table-responsive position-relative popup-data-table">
        <DataTable
          columns={invCols}
          data={cnList}
          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </div>
  );
};

export default CreditNoteInvoiceDtls;

import moment from "moment";
import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  ALPHANUM,
  COUNTRY_CODES,
  DATE_FORMAT_CLIENT,
  EMAIL,
  INVALID_NAME,
  NAME_REGEX,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  PHONE_NUM,
  REQUIRED_ERR_MSG,
} from "../../Helper/Constants";
import "../Partner/PartnerStyle.css";
import { CallApiGet } from "../../Helper/serviceApi";
import Select from "react-select";
import {
  GetSvgMail2,
  GetSvgUpload,
  GetSvgTruck,
  GetSvgCalendar,
} from "../../../images/svglist";
import { toBase64 } from "../../Helper/CommonMethods";

const companyDetails = {
  companyName: "",
  registrationNo: "",
  companyAddress: "",
  contactPersonName: "",
  password: "",
  pwdSalt: "",
};

const PartnerPersonal = ({ partnerTypeData, saveData }) => {
  const [stateCity, setStateCity] = useState();
  const [cityListData, setCityListData] = useState();
  const [stateData, setStateData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [cityData, setCityData] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [vhclTypes, setVhclTypes] = useState([]);
  const [photoImg, setPhotoImg] = useState();

  const dummyAttachment = {
    photo: "",
    photoFileName: "",
    photoFileType: "",
    photoBucketUrl: "",
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  useEffect(() => {
    fetchStatesAndCityList();
    loadVhclTypes();
  }, []);

  const fetchStatesAndCityList = async () => {
    let stateArrList = [];
    let stateArr = [];
    let cityArrList = [];
    let cityArr = [];
    let data = await CallApiGet("getStateAndCityList");
    setStateCity(data.respData);
    data.respData.forEach((element) => {
      if (!stateArr.includes(element.map_state)) {
        stateArrList.push({
          value: element.map_state,
          label: element.map_state,
        });
      }
      if (!cityArr.includes(element.map_city)) {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      }
      stateArr.push(element.map_state);
      cityArr.push(element.map_city);
    });
    setStateData(stateArrList);
    setCityData(cityArrList);
    setCityListData(cityArrList);
  };

  const filterCity = (state) => {
    let cityArrList = [];
    if (state) {
      let setCity = new Set(
        stateCity.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });
      setCityListData(cityArrList);
    } else {
      setCityListData(cityData);
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCity.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    } else {
      setValue("state", "");
    }
  };

  const onSubmit = async (data) => {
    data.isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
    data.phone = data.countryCode + data.phone;
    delete data.countryCode;
    data.ssmDoc = dummyAttachment;
    const reqData = {
      ...data,
      ...companyDetails,
      partnerTypeId: partnerTypeData.id,
      partnerType: partnerTypeData.optionValue,
    };
    console.log(JSON.stringify(reqData));
    saveData(reqData);
  };

  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      if (key === "profilePhoto") setPhotoImg(file[0]);
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsPrivacyPolicyAccepted(event.target.checked);
  };

  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");
    if (data.respCode === 200 || data.respCode === 0) {
      let tempArr = [];
      const vhclTypesArr = data.respData;
      vhclTypesArr.forEach((element) => {
        tempArr.push({
          label: element.vhclType,
          value: element.id,
        });
      });
      setVhclTypes(tempArr);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setVhclTypes([]);
    } else {
      console.error(data);
    }
  };

  const bankStatementPlaceHolder = watch(
    "bankStatement.photoFileName",
    "Upload Bank Statement"
  );
  const vhclPhotoFPlaceHolder = watch(
    "vehiclePhotoFront.photoFileName",
    "Upload Truck Front Photo"
  );
  const vhclPhotoRPlaceHolder = watch(
    "vehiclePhotoRight.photoFileName",
    "Upload Truck Right Photo"
  );
  const vhclPhotoLPlaceHolder = watch(
    "vehiclePhotoLeft.photoFileName",
    "Upload Truck Left Photo"
  );
  const vhclPhotoBPlaceHolder = watch(
    "vehiclePhotoBack.photoFileName",
    "Upload Truck Back Photo"
  );
  const vhclPhotoIPlaceHolder = watch(
    "vehiclePhotoInternal.photoFileName",
    "Upload Truck Internal Photo"
  );
  const roadTaxImgPlaceHolder = watch(
    "roadTaxImage.photoFileName",
    "Upload Road Tax"
  );
  const puspakomImgPlaceHolder = watch(
    "puspakomImage.photoFileName",
    "Upload Puspakom"
  );
  const insuranceImgPlaceHolder = watch(
    "insuranceImage.photoFileName",
    "Upload Insurance"
  );
  const drvrLicensePlaceHolder = watch(
    "driverLicenseImage.photoFileName",
    "Upload Driver License"
  );
  const drvrICPlaceHolder = watch(
    "driverICImage.photoFileName",
    "Upload Driver IC"
  );

  return (
    <div
      className="accordion form-register"
      id="accordionPanelsStayOpenExample"
    >
      <div>
        <h6 className="signin-link mb-3">Personal Details</h6>
        <div className="row">
          <div className="col mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Name"
                    aria-label="Name"
                    aria-describedby="basic-addon1"
                    name="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NAME_REGEX,
                        message: INVALID_NAME,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.name && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.name.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telephone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                      />
                    </svg>
                  </span>
                  <select
                    name="countryCode"
                    {...register("countryCode")}
                    defaultValue="+60"
                    className="form-control country-code-customize"
                  >
                    {COUNTRY_CODES.map((cc, index) => (
                      <option key={index} value={cc.value}>
                        {cc.label}
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    className="form-control border-end-0 pe-0"
                    placeholder="Phone"
                    aria-label="Phone"
                    aria-describedby="basic-addon1"
                    name="phone"
                    {...register("phone", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: PHONE_NUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                    maxLength="11"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.phone && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.phone.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgMail2({ required: false })}
                  </span>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={cityListData}
                        placeholder="Select City"
                        value={
                          value
                            ? cityListData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(
                            d ? d.value : d,
                            filterState(d ? d.value : d)
                          )
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control border-end-0 p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.city && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.city.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-building-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h3v-3.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V16h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H3Zm1 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Address"
                    aria-label="Address"
                    name="address"
                    {...register("address", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                    maxLength="500"
                  ></input>
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.address && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.address.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-envelope-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                    </svg>
                  </span>
                  <input
                    type="email"
                    className="form-control border-end-0 pe-0"
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon1"
                    name="email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: EMAIL,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.email && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.email.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgMail2({ required: false })}
                  </span>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={stateData}
                        placeholder="Select State"
                        value={
                          value
                            ? stateData.filter((opt) =>
                                value.includes(opt.value)
                              )
                            : value
                        }
                        onChange={(d) =>
                          onChange(d ? d.value : d, filterCity(d ? d.value : d))
                        }
                        isSearchable={true}
                        isClearable={true}
                        className="form-control border-end-0 p-0"
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.state && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.state.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mailbox2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                      <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0 "
                    placeholder="Postal Code"
                    aria-describedby="basic-addon1"
                    name="postcode"
                    {...register("postcode", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NUMBER_REGEX,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                    maxLength="20"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.postcode && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.postcode.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Driver IC"
                    aria-label="Driver IC"
                    aria-describedby="basic-addon1"
                    name="ic"
                    {...register("ic", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.ic && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.ic.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3 me-0">
            <div
              className="mb-1 file-placeholder w-100 d-flex justify-content-center"
              {...register("profilePhoto")}
            >
              <input
                type="file"
                accept="image/png, image/jpeg "
                onChange={(e) => selectFile("profilePhoto", e)}
                // onChange={selectPhotoFile}
              />
              {photoImg ? (
                <img src={URL.createObjectURL(photoImg)} alt="img" />
              ) : (
                <div>
                  {GetSvgUpload({ required: false })}
                  Upload Photo{" "}
                </div>
              )}
            </div>
            <div className="w-100 mb-2">
              <div className="input-group">
                <div
                  className="Upload-Photo w-100 mb-2"
                  {...register("driverICImage", {
                    validate: (v) =>
                      v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  })}
                >
                  <input
                    type="file"
                    onChange={(e) => selectFile("driverICImage", e)}
                  />
                  <div className="uploaded-text ms-2">
                    {GetSvgUpload({ required: false })}
                    {drvrICPlaceHolder ? drvrICPlaceHolder : "Upload Driver IC"}
                  </div>
                </div>
              </div>
              {errors.driverICImage && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.driverICImage.message}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <h6 className="signin-link mb-3">Driver Details</h6>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Driver License"
                    aria-label="Name"
                    aria-describedby="basic-addon1"
                    name="driverLicense"
                    {...register("driverLicense", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                    maxLength="50"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.driverLicense && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.driverLicense.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group position-relative">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgCalendar({ required: false })}
                  </span>
                  <Controller
                    control={control}
                    name="driverLicenseExpiryDate"
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        placeholderText="License Expiry Date"
                        className="form-control"
                        dateFormat={DATE_FORMAT_CLIENT}
                        selected={value ? new Date(value) : value}
                        minDate={new Date()}
                        onChange={(d) =>
                          onChange(
                            d !== null ? moment(d).format("YYYY-MM-DD") : ""
                          )
                        }
                        showYearDropdown
                      />
                    )}
                  />
                  <span className="position-absolute end-0 text-danger fw-bold py-1 me-1 fs-5">
                    *
                  </span>
                </div>
                {errors.driverLicenseExpiryDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.driverLicenseExpiryDate.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("driverLicenseImage", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("driverLicenseImage", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {drvrLicensePlaceHolder
                        ? drvrLicensePlaceHolder
                        : "Upload Driver License"}
                    </div>
                  </div>
                </div>
                {errors.driverLicenseImage && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.driverLicenseImage.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h6 className="signin-link mb-3">Vehicle Details</h6>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* TruckType */}
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgTruck({ required: false })}
                  </span>
                  <Controller
                    control={control}
                    name="vehicleTypeId"
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          options={vhclTypes}
                          placeholder="Select Truck Type"
                          onChange={(val) => onChange(val.value)}
                          className="form-control border-end-0 p-0"
                        />
                      );
                    }}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.vehicleTypeId && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehicleTypeId.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Poto_F */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("vehiclePhotoFront", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("vehiclePhotoFront", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {vhclPhotoFPlaceHolder
                        ? vhclPhotoFPlaceHolder
                        : "Upload Truck Front Photo"}
                    </div>
                  </div>
                </div>
                {errors.vehiclePhotoFront && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehiclePhotoFront.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Photo_R */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("vehiclePhotoRight", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("vehiclePhotoRight", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {vhclPhotoRPlaceHolder
                        ? vhclPhotoRPlaceHolder
                        : "Upload Truck Right Photo"}
                    </div>
                  </div>
                </div>
                {errors.vehiclePhotoRight && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehiclePhotoRight.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Photo_I */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("vehiclePhotoInternal", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("vehiclePhotoInternal", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {vhclPhotoIPlaceHolder
                        ? vhclPhotoIPlaceHolder
                        : "Upload Truck Internal Photo"}
                    </div>
                  </div>
                </div>
                {errors.vehiclePhotoInternal && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehiclePhotoInternal.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Truck No. */}
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgTruck({ required: false })}
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Truck Number"
                    {...register(`vehicleNumber`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.vehicleNumber && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehicleNumber.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Photo_L */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("vehiclePhotoLeft", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("vehiclePhotoLeft", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {vhclPhotoLPlaceHolder
                        ? vhclPhotoLPlaceHolder
                        : "Upload Truck Left Photo"}
                    </div>
                  </div>
                </div>
                {errors.vehiclePhotoLeft && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehiclePhotoLeft.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Photo_B */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("vehiclePhotoBack", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("vehiclePhotoBack", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {vhclPhotoBPlaceHolder
                        ? vhclPhotoBPlaceHolder
                        : "Upload Truck Back Photo"}
                    </div>
                  </div>
                </div>
                {errors.vehiclePhotoBack && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.vehiclePhotoBack.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Road Tax No. */}
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgMail2({ required: false })}
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Road Tax Number"
                    {...register(`roadTax`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.roadTax && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.roadTax.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Road Tax Exp. */}
                <div className="input-group position-relative">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgCalendar({ required: false })}
                  </span>
                  <Controller
                    control={control}
                    name={`roadTaxExpiryDate`}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactDatePicker
                          placeholderText="Road Tax Expiry Date"
                          className="form-control"
                          dateFormat={DATE_FORMAT_CLIENT}
                          selected={value ? new Date(value) : value}
                          minDate={new Date()}
                          onChange={(d) =>
                            onChange(
                              d !== null ? moment(d).format("YYYY-MM-DD") : ""
                            )
                          }
                          showYearDropdown
                        />
                      );
                    }}
                  />
                  <span className="position-absolute end-0 text-danger fw-bold py-1 me-1 fs-5">
                    *
                  </span>
                </div>
                {errors.roadTaxExpiryDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.roadTaxExpiryDate.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Road Tax Image */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("roadTaxImage", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("roadTaxImage", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {roadTaxImgPlaceHolder
                        ? roadTaxImgPlaceHolder
                        : "Upload Road Tax"}
                    </div>
                  </div>
                </div>
                {errors.roadTaxImage && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.roadTaxImage.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Puspakom */}
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgMail2({ required: false })}
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Puspakom Number"
                    {...register(`puspakom`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.puspakom && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.puspakom.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Puspakom Exp. */}
                <div className="input-group position-relative">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgCalendar({ required: false })}
                  </span>
                  <Controller
                    control={control}
                    name={`puspakomExpiryDate`}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactDatePicker
                          placeholderText="Puspakom Expiry Date"
                          className="form-control"
                          dateFormat={DATE_FORMAT_CLIENT}
                          selected={value ? new Date(value) : value}
                          minDate={new Date()}
                          onChange={(d) =>
                            onChange(
                              d !== null ? moment(d).format("YYYY-MM-DD") : ""
                            )
                          }
                          showYearDropdown
                        />
                      );
                    }}
                  />
                  <span className="position-absolute end-0 text-danger fw-bold py-1 me-1 fs-5">
                    *
                  </span>
                </div>
                {errors.puspakomExpiryDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.puspakomExpiryDate.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Puspakom image */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("puspakomImage", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("puspakomImage", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {puspakomImgPlaceHolder
                        ? puspakomImgPlaceHolder
                        : "Upload Puspakom"}
                    </div>
                  </div>
                </div>
                {errors.puspakomImage && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.puspakomImage.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Insurance */}
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgMail2({ required: false })}
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Insurance Number"
                    {...register(`insurance`, {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.insurance && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.insurance.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                {/* Insurance Exp. */}
                <div className="input-group position-relative">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    {GetSvgCalendar({ required: false })}
                  </span>
                  <Controller
                    control={control}
                    name={`insuranceExpiryDate`}
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactDatePicker
                          placeholderText="Insurance Expiry Date"
                          className="form-control"
                          dateFormat={DATE_FORMAT_CLIENT}
                          selected={value ? new Date(value) : value}
                          minDate={new Date()}
                          onChange={(d) =>
                            onChange(
                              d !== null ? moment(d).format("YYYY-MM-DD") : ""
                            )
                          }
                          showYearDropdown
                        />
                      );
                    }}
                  />
                  <span className="position-absolute end-0 text-danger fw-bold py-1 me-1 fs-5">
                    *
                  </span>
                </div>
                {errors.insuranceExpiryDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.insuranceExpiryDate.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                {/* Insurance Image */}
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("insuranceImage", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("insuranceImage", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {insuranceImgPlaceHolder
                        ? insuranceImgPlaceHolder
                        : "Upload Insurance"}
                    </div>
                  </div>
                </div>
                {errors.insuranceImage && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.insuranceImage.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h6 className="signin-link mb-3">Bank Details</h6>
        <div className="row clearfix ">
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-bank2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Bank Name"
                    aria-label="Phone"
                    aria-describedby="basic-addon1"
                    name="bankName"
                    {...register("bankName", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.bankName && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.bankName.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-mailbox2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9 8.5h2.793l.853.854A.5.5 0 0 0 13 9.5h1a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H9v1z" />
                      <path d="M12 3H4a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V7a4 4 0 0 0-4-4zM8 7a3.99 3.99 0 0 0-1.354-3H12a3 3 0 0 1 3 3v6H8V7zm-3.415.157C4.42 7.087 4.218 7 4 7c-.218 0-.42.086-.585.157C3.164 7.264 3 7.334 3 7a1 1 0 0 1 2 0c0 .334-.164.264-.415.157z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Account Number"
                    name="bankAccount"
                    {...register("bankAccount", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: ALPHANUM,
                        message: PATTERN_ERR_MSG,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.bankAccount && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.bankAccount.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div className="row">
              <div className="w-100 mb-2">
                <div className="input-group">
                  <span className="input-group-text inputsty" id="basic-addon1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control border-end-0 pe-0"
                    placeholder="Beneficiary Name"
                    aria-label="Phone"
                    aria-describedby="basic-addon1"
                    name="beneficiaryName"
                    {...register("beneficiaryName", {
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                      pattern: {
                        value: NAME_REGEX,
                        message: INVALID_NAME,
                      },
                    })}
                    maxLength="200"
                  />
                  <span className="input-group-text text-danger fw-bold py-0 px-1 bg-white fs-5 border-start-0">
                    *
                  </span>
                </div>
                {errors.beneficiaryName && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.beneficiaryName.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="w-100 mb-2">
                <div className="input-group">
                  <div
                    className="Upload-Photo w-100 mb-2"
                    {...register("bankStatement", {
                      validate: (v) =>
                        v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    })}
                  >
                    <input
                      type="file"
                      onChange={(e) => selectFile("bankStatement", e)}
                    />
                    <div className="uploaded-text ms-2">
                      {GetSvgUpload({ required: false })}
                      {bankStatementPlaceHolder
                        ? bankStatementPlaceHolder
                        : "Upload Bank Statement"}
                    </div>
                  </div>
                </div>
                {errors.bankStatement && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.bankStatement.message}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            checked={isPrivacyPolicyAccepted}
            onChange={handleCheckboxChange}
          />
          <label class="form-check-label" for="flexCheckDefault">
            <a href="/privacyPolicy" target="_blank" className="text-white">
              Please read and accept the privacy policy
            </a>
          </label>
        </div>
      </div>
      <div className="clearfix col-md-4 m-auto mt-3">
        <button
          className="btn-primary-search w-100 rounded-pill theme-violet"
          onClick={handleSubmit(onSubmit)}
          disabled={!isPrivacyPolicyAccepted}
        >
          Register
        </button>
        <div className="text-center my-2 signin-link">
          Already Registered? {/* <a href="Login.html">Sign In here</a> */}
          <Link to="/login">Sign In here</Link>
        </div>
      </div>
    </div>
  );
};

export default PartnerPersonal;

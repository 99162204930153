import React from "react";
import { Modal } from "react-bootstrap";

const FtlModal = ({ children, moadlConfig, handleMoalClose }) => {
  return (
    <Modal
      size={moadlConfig.size}
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      show={moadlConfig.show}
      onHide={handleMoalClose}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <label
          className="align-self-center fs-5 theme-blue-txt"
          htmlFor="inlineRadio1"
        >
          Full Truck Details
        </label>
      </Modal.Header>
      <Modal.Body className="p-0">{children}</Modal.Body>
    </Modal>
  );
};

export default FtlModal;

import React, { useEffect } from "react";
import Header from "../Layout/Header";
import InboundOrderList from "./InboundOrderList";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import InboundOrderUploadFile, { initInbOrd } from "./InboundOrderUploadFile";
import Popup from "../Layout/Popup";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { NET_ERR_MSG, SERVER_ERR_MSG } from "../Helper/Constants";
import Spinner from "../Layout/Spinner";
import InboundOrderEdit from "./InboundOrderEdit";
import ModalAlert from "../Layout/ModalAlert";
import {
  getRandomInteger,
  permissionCheck,
  toBase64,
} from "../Helper/CommonMethods";
import moment from "moment";
import { INBORDS } from "../../ConfigConstants/ConfigConstants";
import { InbStateContext } from "./InboundContext";
import { useContext } from "react";
import InboundBill from "./InboundBill";

const initModalConfig = {
  show: false,
  size: "",
  title: "",
};
const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

export const handleAttch = async (
  event,
  argRefId,
  attachId,
  attachType,
  callback
) => {
  const file = event.target.files;
  const photo = await toBase64(file[0]);
  const argFileName = file[0].name;
  var ext = argFileName.substring(argFileName.lastIndexOf(".") + 1);
  const fileName =
    "INB_" +
    attachType +
    "_" +
    getRandomInteger(1, 1000) +
    "_" +
    moment().format("YYYYMMDD") +
    moment().milliseconds() +
    "." +
    ext;

  if (!!photo) {
    let attchObj = {};
    attchObj.refId = argRefId;
    attchObj.attachName = fileName;
    attchObj.attchBase64 = photo;
    attchObj.attachUrl = "";
    attchObj.attachId = !!attachId ? attachId : 0;
    attchObj.attachType = !!attachType ? 700 : attachType;
    let attchArr = [attchObj];
    if (typeof callback === "function") {
      callback(attchArr);
    }
  }
};

const initDropdown = {
  supplierList: [],
  customerList: [],
};

export default function InboundOrderMain() {
  let navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const { order, setOrder } = useContext(InbStateContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [requestId, setRequestId] = useState();
  const [modalConfig, setModalConfig] = useState(
    JSON.parse(JSON.stringify(initModalConfig))
  );
  const [billConfig, setBillConfig] = useState(
    JSON.parse(JSON.stringify(initModalConfig))
  );
  const [billData, setBillData] = useState();
  const [dropdownData, setDropdownData] = useState(
    Object.assign({}, initDropdown)
  );

  const modalRef = useRef();

  useEffect(() => {
    fetchAllInbOrders();
    fetchDropdownData();
  }, []);

  const fetchAllInbOrders = async () => {
    setShowSpinner(true);
    const data = await CallApiGet("GetInboundOrderList");
    postAllOrderAPICall(data);
    setShowSpinner(false);
  };

  const fetchDropdownData = async () => {
    setShowSpinner(true);
    let dropData = {
      supplierList: [],
      customerList: [],
    };
    const resp = await CallApiGet("list-supplier?argPrflId=0&argStatus=721");
    if (
      resp.respCode === 200 &&
      !!resp.respData &&
      Array.isArray(resp.respData)
    ) {
      dropData.supplierList = resp.respData;
    }
    const dropdownModel = {
      argAllCust: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200 && !!data.respData) {
      dropData.customerList = data.respData.allCustArr;
    }
    setDropdownData(dropData);
    setShowSpinner(false);
  };

  const postAllOrderAPICall = (data) => {
    switch (data.respCode) {
      case 200:
      case 0:
        setOrderList(!!data.respData ? data.respData : []);
        break;
      case 500:
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        openPopup(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        openPopup(false, "ERROR", data.respMessage);
        break;
    }
  };

  const openPopup = (boolVal, title, desc) => {
    setIsSuccess(boolVal);
    setTitle(title);
    setDescription(desc);
    modalRef.current.click();
  };
  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    setRequestId();
    modalRef.current.click();
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (boolVal, title, desc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "md",
      show: true,
      title: title,
      description: desc,
    });
  };

  const handleClose = () => {
    setModalConfig({
      show: false,
      size: "",
      title: "",
    });
    setOrder(initInbOrd);
  };

  const handleBillClose = () => {
    setBillConfig({
      show: false,
      size: "",
      title: "",
    });
    // setOrder(initInbOrd);
  };

  const updateOrderState = (argOrder) => {
    let selOrder = Object.assign({}, argOrder);
    selOrder.attachments = JSON.parse(selOrder?.attachments);
    setOrder(selOrder);
  };

  const handleEdit = (argOrder) => {
    updateOrderState(argOrder);
    openEditModal();
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setOrder((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDateChange = (name, value) => {
    setOrder((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleFileAttach = (argAttach) => {
    setOrder((state) => ({
      ...state,
      attachments: argAttach,
    }));
  };

  const handleSubmit = async () => {
    setShowSpinner(true);
    order["items"] = [];
    const resp = await CallApiPost("UpdateInbOrder", order);
    handleClose();
    switch (resp.respCode) {
      case 200:
      case 0:
        fetchAllInbOrders();
        showAlert(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        showAlert(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };

  const openEditModal = () => {
    setModalConfig({
      show: true,
      size: "lg",
      title: "EDIT ORDER",
    });
  };

  // const openBillModal = () => {
  //   setBillConfig({
  //     show: true,
  //     size: "lg",
  //     title: "BILL",
  //   });
  // };

  const callback = () => {
    fetchAllInbOrders();
  };

  const handleRespPostApiCall = (
    resp,
    sucMsg = "Data uploaded successfully."
  ) => {
    switch (resp?.respCode) {
      case 200:
        setRequestId();
        openPopup(true, "SUCCESS", sucMsg);
        break;
      case 500:
        setRequestId(resp.respData);
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the write permission!!"
        );
        break;
      case 400:
        openPopup(false, "ERROR", resp.respMessage);
        break;
      case 99:
        setRequestId(resp.respData);
        openPopup(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        setRequestId();
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
    }
  };

  const openBillModal = () => {
    setBillConfig({
      show: true,
      size: "xl",
      title: "BILL",
    });
  };
  const handleManifestBill = async (row) => {
    setShowSpinner(true);
    let payload = {
      orderId: row.orderId,
      userId: row.custId,
    };
    const resp = await CallApiPost("getInboundBillDtls", payload);
    switch (resp.respCode) {
      case 200:
      case 0:
        if (!!resp.respData) {
          setBillData(resp.respData);
          openBillModal();
        } else {
          showAlert(false, "ERROR", resp.respMessage);
        }
        break;
      case 500:
        showAlert(false, "ERROR", resp.respMessage);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };
  const generateInvoice = async (data) => {
    setShowSpinner(true);
    handleBillClose();
    const resp = await CallApiPost("createInbInvoice", data);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "SUCCESS", "Invoice Generated successfully.");
        fetchAllInbOrders();
        break;
      case 500:
        showAlert(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };

  const invoiceDownload = async (orderData) => {
    document.getElementById(`row-action-button-${orderData?.orderId}`).click();
    setShowSpinner(true);
    //== This customer type is made false intentionally
    //== Invoice template of International Inbound Order is always of Personal Customer type
    let custType = false;
    const response = await CallApiGet(
      `generateAllInvoicePdf/${orderData.invoiceNo}/${custType}`
    );
    setShowSpinner(false);
    if (response.respCode === 200) {
      window.open(response.respData, "_blank");
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">
                  INTERNATIONAL INBOUND ORDERS
                </div>
              </div>
              {permissionCheck(INBORDS, "allowedWrite") && (
                <div className="float-end ">
                  <div className="input-group">
                    <button
                      className="btn btn-outline-secondary theme-violet text-nowrap"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#upload-file-modal"
                    >
                      Upload
                    </button>
                  </div>
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              )}
            </div>
            <InboundOrderList
              orderList={orderList}
              handleEdit={handleEdit}
              openBill={handleManifestBill}
              invoiceDownload={invoiceDownload}
            />
            <InboundOrderUploadFile
              handleRespPostApiCall={handleRespPostApiCall}
              dropdownData={dropdownData}
              setShowSpinner={setShowSpinner}
            />
          </main>
        </div>
        {modalConfig?.show && (
          <InboundOrderEdit
            modalConfig={modalConfig}
            handleClose={handleClose}
            order={order}
            handleOnChange={handleOnChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            handleFileAttach={handleFileAttach}
          />
        )}

        {billConfig?.show && (
          <InboundBill
            modalConfig={billConfig}
            handleClose={handleBillClose}
            billData={billData}
            generateInvoice={generateInvoice}
          />
        )}

        {alertConfig?.show && (
          <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
            <p>{alertConfig.description}</p>
          </ModalAlert>
        )}
        <Popup
          isSuccess={isSuccess}
          title={title}
          description={description}
          handler={callback}
          // requestId={requestId}
        />
        <Spinner show={showSpinner} />
      </div>
    </>
  );
}

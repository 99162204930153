import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";

export default function FeatureList(props) {
  const { register,setValue,watch, permissionsArr } = props;
  const columns = [
    {
      name: "Module",
      selector: (row) => row.module,
      sortable: true,
      filterable: true,
      width: "180px",
    },
    {
      name: "Feature",
      selector: (row) => row.featureName,
      sortable: true,
      filterable: true,
      width: "280px",
    },
    {
      name: "Read",
      cell: (row, index) => (
        <input
          className="form-check-input"
          type="checkbox"
          name={`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowRead`}
          {...register(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowRead`)}
          checked={ watch(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowRead`)}
          onChange={(e) => setValue(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowRead`, e.target.checked)}
        />
      ),
    },
    {
      name: "Write",
      cell: (row, index) => (
        <input
          className="form-check-input"
          type="checkbox"
          name={`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowWrite`}
          {...register(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowWrite`)}
          checked={watch(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowWrite`)}
          onChange={(e) => setValue(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowWrite`, e.target.checked)}
        />
      ),
    },
    {
      name: "Delete",
      cell: (row, index) => (
        <input
          className="form-check-input"
          type="checkbox"
          name={`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowDelete`}
          {...register(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowDelete`)}
          checked={watch(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowDelete`)}
          onChange={(e) => setValue(`permissions.${permissionsArr?.findIndex(obj => obj.featureId === row?.featureId)}.allowDelete`, e.target.checked)}
        />
      ),
    },
  ];
  return (
    <div className="col-md-12 mt-3 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={permissionsArr}
        pagination
        striped
        highlightOnHover
        customStyles={customDataTableStyles}
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
      />
    </div>
  );
}

import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

import DataTable from "react-data-table-component-with-filter";
import { useForm, Controller } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { GetSvgUpload } from "../../../../images/svglist";
import dummyImg from "../../../../images/user-dummy.jpg";
import { toBase64 } from "../../../Helper/CommonMethods";
import {
  ALPHANUM,
  ALPHANUM_NAME,
  COUNTRY_CODES,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  EMAIL,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  SERVER_ERR_MSG,
} from "../../../Helper/Constants";
import { DownloadFile, GetBase64Img } from "../../../Helper/FileStoreService";
import { CallApiPost, CallApiGet } from "../../../Helper/serviceApi";
import Spinner from "../../../Layout/Spinner";
import CustomAlert from "../../../Layout/CustomAlert/CustomAlert";
import Select from "react-select";

const IsrActivationDetails = (props) => {
  let {
    isrDtls,
    clear,
    status,
    costumerList,
    stateList,
    cityList,
    stateCityData,
  } = props;
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [custReassignRequired, setCustReassignRequired] = useState(false);
  const closeRef = useRef();
  const errorRef = useRef();
  const [statementFileLable, setStatementFileLabel] =
    useState("Choose a File ...");
  const [icFileLable, setIcFileLabel] = useState("Choose a File ...");
  const [photoFileLable, setPhotoFileLabel] = useState("Choose a File ...");
  const [photoImg, setPhotoImg] = useState();
  const [icImg, setIcImg] = useState();
  const [show, setShow] = useState(false);
  const [imageData, setImageData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [cityListData, setCityListData] = useState();
  const [failureMsg, setFailureMsg] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm(isrDtls);

  const columns = [
    {
      name: "Customer Code",
      selector: (row) => row.acct_code,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.cust_name,
      sortable: true,
    },
  ];

  useEffect(() => {
    setCityListData(cityList);
    updatePhoneCode();
    reset(isrDtls);
    setValue("city", isrDtls.city);
    setValue("state", isrDtls.state);
    if (isrDtls.bankStatement)
      setStatementFileLabel(
        isrDtls.bankStatement.photoBucketUrl === ""
          ? "Choose a File ..."
          : isrDtls.bankStatement.photoBucketUrl
      );
    if (isrDtls.isrPrflImg)
      setPhotoFileLabel(
        isrDtls.isrPrflImg.photoBucketUrl === ""
          ? "Choose a File ..."
          : isrDtls.isrPrflImg.photoBucketUrl
      );
    if (isrDtls.isrIcImg)
      setIcFileLabel(
        isrDtls.isrIcImg.photoBucketUrl === ""
          ? "Choose a File ..."
          : isrDtls.isrIcImg.photoBucketUrl
      );

    if (isrDtls.isrPrflImg && isrDtls.isrPrflImg.photoBucketUrl !== "") {
      setImageData(isrDtls.isrPrflImg.photoBucketUrl);
    } else {
      setImageData("");
    }

    if (stateCityData) filterCity(isrDtls.state);
    //
    // setStaffCode(isrDtls.staffId);
  }, [isrDtls]);

  const updatePhoneCode = () => {
    let phoneNo = isrDtls.phone;
    isrDtls.phone = phoneNo.substr(3);
    isrDtls.phoneCode = phoneNo.substr(0, 3);
  };
  const activateIsrApiCall = async () => {
    if (validStaffCode()) {
      setShow(true);
      let isrDtl = await CallApiGet(`activate-isr?isrId=${isrDtls.userId}`);
      setShow(false);

      if (isrDtl.respCode === 200 || isrDtl.respCode === 0) {
        closeRef.current.click();
        props.onSuccess(true, "Success", "Successfully Activated !!!");
      } else {
        setSubmitStatusFail(true);

        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 10000);
      }
    }
  };

  const deActivateIsrApiCall = async () => {
    if (costumerList.length !== 0) {
      setCustReassignRequired(true);
      setTimeout(() => {
        setCustReassignRequired(false);
      }, 10000);
    } else if (isrDtls.active && status === "DEACTIVATION APPROVED") {
      setShow(true);
      let isrDtl = await CallApiGet(`deactivate-isr?isrId=${isrDtls.userId}`);
      setShow(false);
      if (isrDtl.respCode === 200) {
        closeRef.current.click();
        props.onSuccess(true, "Success", "Successfully Deactivated !!!");
      } else {
        setSubmitStatusFail(true);
        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 10000);
      }
    }
  };

  const requestDeactivationApiCall = async () => {
    if (isrDtls.active && (status === "APPROVED" || status === "DEACTIVATION REJECTED")) {
      setShow(true);
      let isrDtl = await CallApiPost("request-user-deactivation", {
        profileId: isrDtls.userId,
        profileTypeId: isrDtls.staffTypeId,
        state: isrDtls.state,
        city: isrDtls.city,
        deactivationRequested: true,
        userName: isrDtls.name,
      });
      setShow(false);
      if (isrDtl.respCode === 200) {
        closeRef.current.click();
        props.onSuccess(
          true,
          "Success",
          "A request has been sent to HQ Manager to deactivate the ISR."
        );
      } else {
        setSubmitStatusFail(true);
        setTimeout(() => {
          setSubmitStatusFail(false);
        }, 10000);
      }
    }
  };

  const validStaffCode = () => {
    //  ;
    if (getValues("staffId") === "") {
      setError("staffId", { type: "focus" }, { shouldFocus: true });
      return false;
    } else {
      return true;
    }
  };

  const onSave = async (data) => {
    setShow(true);
    data.phone = data.phoneCode + data.phone;
    delete data.phoneCode;
    let resp = await CallApiPost("update-isr", data);
    setShow(false);

    if (resp.respCode === 200) {
      closeRef.current.click();
      props.onSuccess(true, "Success", "Successfully Saved !!!");
    } else if (resp.respCode === 500) {
      setSubmitStatusFail(true);
      setFailureMsg(SERVER_ERR_MSG);
      setTimeout(() => {
        setSubmitStatusFail(false);
        setFailureMsg("");
      }, 10000);
    } else {
      setSubmitStatusFail(true);
      setFailureMsg(resp.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
        setFailureMsg("");
      }, 10000);
    }
  };

  const onCancel = () => {
    reset(isrDtls);
    setStatementFileLabel("Choose a File ...");
    setIcFileLabel("Choose a File ...");
    setPhotoFileLabel("Choose a File ...");
    clear();
    closeRef.current.click();
  };

  const selectStatementFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      setStatementFileLabel("Choose a File ...");
    } else {
      var fileName = file[0].name;
      setStatementFileLabel(fileName);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";

      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      // setStatementFile(newAttachment);
      setValue("bankStatement", newAttachment);

      // console.log(newAttachment);
    }
  };

  const selectIcFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      setIcFileLabel("Choose a File ...");
    } else {
      var fileName = file[0].name;
      setIcFileLabel(fileName);
      setIcImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      // setIcFile(newAttachment);
      setValue("isrIcImg", newAttachment);

      // console.log(attachment);
    }
  };

  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      setPhotoFileLabel("Choose a File ...");
    } else {
      var fileName = file[0].name;
      setPhotoFileLabel(fileName);
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      // setPhotoFile(newAttachment);
      setValue("isrPrflImg", newAttachment);
      // clearErrors("isrPrflImg");
      // console.log(attachment);
    }
  };
  const onSuss = () => {

  }
  const download = (file) => {
    if (file) {
      let fileName = file.photoBucketUrl !== "" ? file.photoBucketUrl : "";
      if (fileName !== "") {
        setShow(true);
        DownloadFile(fileName, onSuss, onSuss);
        setShow(false);
      }
    }
  };

  const toggleReset = (bool) => {
    setIsOpen(bool);
  };

  const filterCity = (state) => {
    let cityArrList = [];

    if (state) {
      let setCity = new Set(
        stateCityData.filter((a) => {
          return a.map_state === state;
        })
      );

      setCity.forEach((element) => {
        cityArrList.push({
          value: element.map_city,
          label: element.map_city,
        });
      });

      setCityListData(cityArrList);
    } else {
      setCityListData(cityList);
    }
  };

  const filterState = (city) => {
    if (city) {
      let flt = stateCityData.filter((a) => {
        return a.map_city === city;
      });

      setValue("state", flt[0].map_state);
      clearErrors("state");
    } else {
      setValue("state", "");
      setCityListData(cityList);
    }
  };

  const checkError = (err) => {
    console.log("Errors ->", err);
  };

  return (
    <div ref={errorRef}>
      <div
        className="modal fade"
        id="button-modal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              {submitStatusFail && (
                <div
                  id="errorMsg"
                  className="alert alert-danger position-absolute w-100 top-0 start-0"
                  role="alert"
                >
                  {failureMsg}
                </div>
              )}
              {custReassignRequired && (
                <div
                  id="errorMsg"
                  className="alert alert-danger position-absolute w-100 top-0 start-0"
                  role="alert"
                >
                  There are customer(s) assigned to this ISR. Please reassign
                  them before deactivating.
                </div>
              )}
              <h5 className="modal-title section-title" id="exampleModalLabel">
                ISR Details
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
                ref={closeRef}
              ></button>
            </div>
            <div className="modal-body modal-scroll overflow-auto">
              <div className="row">
                <div>
                  <div className="w-100">
                    <div className="row col-md-12 g-2 mb-2">
                      <div className="col-md-6">
                        {/* <img
                          src={
                            imageData !== ""
                              ? "data:image/jpeg;base64," + imageData
                              : dummyImg
                          }
                          height="100px"
                          width="100px"
                          className="rounded-circle"
                          alt="img"
                          id="prfImg"
                        /> */}
                        <div
                          className="mb-1 file-placeholder thumbnail-pic"
                          {...register("prfImg")}
                        >
                          <input
                            type="file"
                            accept="image/png, image/jpeg "
                            onChange={selectPhotoFile}
                          />
                          {photoImg ? (
                            <img
                              src={URL.createObjectURL(photoImg)}
                              alt="img"
                            />
                          ) : imageData !== "" ? (
                            <img
                              src={
                                imageData !== ""
                                  ? imageData
                                  : ""
                              }
                              alt="img"
                            />
                          ) : (
                            <div>
                              Upload Photo{" "}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mt-3 text-center">
                        <span onClick={() => download(isrDtls.isrIcImg)}>
                          <span className="tooltip-customized theme-blue-txt">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-person-vcard-fill activation-details"
                              viewBox="0 0 16 16"
                              data-tooltip-id="ic-img"
                            >
                              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                            </svg>

                            {/* <span className="tooltiptext">View IC Image</span> */}

                            <Tooltip
                              id="ic-img"
                              content="Download IC Image"
                              place="left"
                            />
                          </span>
                        </span>
                      </div>
                      <div className="col-md-3 mt-3 text-start">
                        <span onClick={() => download(isrDtls.bankStatement)}>
                          <span className="tooltip-customized theme-blue-txt">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-card-checklist activation-details"
                              viewBox="0 0 16 16"
                              data-tooltip-id="statement"
                            >
                              <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                              <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                            </svg>
                            {/* <span className="tooltiptext">
                            View Bank Statement
                          </span> */}
                            <Tooltip
                              id="statement"
                              content="Download Bank Statement"
                              place="right"
                            />
                          </span>
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div>
                        <div className="w-100">
                          <div className="row">
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Name{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.name}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                {...register("name", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  maxLength: {
                                    value: 40,
                                    message: "Exceeded max limit!!!",
                                  },
                                })}
                                maxLength="40"
                              />
                              {errors.name && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.name.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Phone{" "}
                              </label>
                              <div className="input-group">
                                <select
                                  name="phoneCode"
                                  {...register("phoneCode")}
                                  defaultValue="+60"
                                  className="form-control country-code-customize"
                                >
                                  {COUNTRY_CODES.map((cc, index) => (
                                    <option key={index} value={cc.value}>
                                      {cc.label}
                                    </option>
                                  ))}
                                </select>
                                <input
                                  type="tel"
                                  className="form-control"
                                  // value={isrDtls.phone}
                                  aria-label="Disabled input example"
                                  {...register("phone", {
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                    minLength: {
                                      value: 8,
                                      message: "Not a Phone Number!!!",
                                    },
                                    pattern: {
                                      value: NUMBER_REGEX,
                                      message: "Incorrect Phone Number!!!",
                                    },
                                  })}
                                />
                                {errors.phone && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.phone.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Email{" "}
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                // value={isrDtls.email}
                                aria-label="Disabled input example"
                                {...register("email", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: EMAIL,
                                    message: "Incorrect Email!!!",
                                  },
                                })}
                              />
                              {errors.email && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.email.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Beneficiary Name{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.beneficiaryName}
                                aria-label="Disabled input example"
                                {...register("benificiaryName")}
                              />
                              {errors.benificiaryName && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.benificiaryName.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                State{" "}
                              </label>
                              <Controller
                                name="state"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={stateList}
                                    placeholder="Select State"
                                    value={
                                      value
                                        ? stateList.filter((opt) =>
                                          value.includes(opt.value)
                                        )
                                        : value
                                    }
                                    onChange={(d) =>
                                      onChange(
                                        d ? d.value : d,
                                        filterCity(d ? d.value : d)
                                      )
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control  p-0"
                                  />
                                )}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                              />
                              {errors.state && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.state.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                City{" "}
                              </label>
                              {/* <Controller
                                name="city"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <Select
                                    options={cityListData}
                                    placeholder="Select City"
                                    value={
                                      value
                                        ? cityListData.filter((opt) =>
                                          value.includes(opt.value)
                                        )
                                        : value
                                    }
                                    onChange={(d) =>
                                      onChange(
                                        d ? d.value : d,
                                        filterState(d ? d.value : d)
                                      )
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                    className="form-control  p-0"
                                  />
                                )}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                              /> */}
                              <input
                                type="text"
                                className="form-control border-end-0 pe-0"
                                placeholder="City"
                                aria-label="Name"
                                aria-describedby="basic-addon1"
                                {...register("city", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  maxLength: {
                                    value: 40,
                                    message: "Exceeded max limit!!!",
                                  },
                                })}
                                maxLength="40"
                              />
                              {errors.city && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.city.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Postal Code{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.postCode}
                                aria-label="Disabled input example"
                                {...register("postCode", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  // pattern: {
                                  //   value: POST_CODE,
                                  //   message: PATTERN_ERR_MSG,
                                  // },
                                })}
                              />
                              {errors.postCode && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.postCode.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                ISR IC{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.isrIc}
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                {...register("isrIc", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: ALPHANUM_NAME,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              />
                              {errors.isrIc && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.isrIc.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Bank Name{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.bankName}
                                aria-label="Disabled input example"
                                {...register("bankName", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              />
                              {errors.bankName && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.bankName.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                Account Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                // value={isrDtls.acNumber}
                                aria-label="Disabled input example"
                                {...register("acNumber", {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                  pattern: {
                                    value: ALPHANUM,
                                    message: PATTERN_ERR_MSG,
                                  },
                                })}
                              />
                              {errors.acNumber && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.acNumber.message}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label theme-label">
                                ISR Id{" "}
                              </label>

                              <input
                                type="text"
                                className="form-control "
                                aria-label="Disabled input example"
                                {...register("staffId", {
                                  onChange: (e) => {
                                    clearErrors("staffId");
                                  },
                                })}
                                disabled
                                readOnly
                              />
                              {errors.staffId && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; This field can not be empty !!!
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="col-md-12">
                              <div className=" mb-2">
                                <label className="form-label theme-label">
                                  Address{" "}
                                </label>
                                <textarea
                                  type="text"
                                  className="form-control txt-area-resize-0"
                                  // value={isrDtls.address}
                                  aria-label="Disabled input example"
                                  {...register("address", {
                                    required: {
                                      value: true,
                                      message: REQUIRED_ERR_MSG,
                                    },
                                  })}
                                ></textarea>
                                {errors.address && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.address.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                              >
                                Photo
                              </label>
                              <div
                                className="Upload-Photo mb-2 w-100"
                                {...register("isrPrflImg")}
                              >
                                <input
                                  type="file"
                                  onChange={selectPhotoFile}
                                  accept="image/png, image/jpeg "
                                />

                                {photoFileLable === "Choose a File ..." ? (
                                  <div className="uploaded-text ms-2">
                                    {GetSvgUpload({ required: false })}
                                    Upload Photo
                                  </div>
                                ) : (
                                  <div className="uploaded-text ms-2 ">
                                    {photoFileLable}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                              >
                                IC Image
                              </label>
                              <div
                                className="Upload-Photo mb-2 w-100"
                                {...register("isrIcImg")}
                              >
                                <input
                                  type="file"
                                  onChange={selectIcFile}
                                  accept="image/png, image/jpeg "
                                />

                                {icFileLable === "Choose a File ..." ? (
                                  <div className="uploaded-text ms-2">
                                    {GetSvgUpload({ required: false })}
                                    Upload IC
                                  </div>
                                ) : (
                                  <div className="uploaded-text ms-2 ">
                                    {icFileLable}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                              >
                                Bank Statement
                              </label>
                              <div
                                className="Upload-Photo mb-2 w-100"
                                {...register("bankStatement")}
                              >
                                <input
                                  type="file"
                                  onChange={selectStatementFile}
                                  accept=".pdf"
                                />

                                {statementFileLable === "Choose a File ..." ? (
                                  <div className="uploaded-text ms-2">
                                    {GetSvgUpload({ required: false })}
                                    Upload Bank Statement
                                  </div>
                                ) : (
                                  <div className="uploaded-text ms-2">
                                    {statementFileLable}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 customized-data-table position-relative">
                      <DataTable
                        columns={columns}
                        data={costumerList}
                        //  
                        pagination
                        onChangeRowsPerPage={props.handlePerPageChange}
                        onChangePage={props.handlePageClick}
                        striped
                        highlightOnHover
                        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                        paginationPerPage={defaultPageLimit}
                        customStyles={customDataTableStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default border"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                onClick={handleSubmit(onSave, checkError)}
              >
                Save
              </button>
              {status === "APPROVED" && !isrDtls.active && (
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet"
                  onClick={activateIsrApiCall}
                >
                  Activate
                </button>
              )}

              {isrDtls.active &&
                (status === "APPROVED" ||
                  status === "DEACTIVATION REJECTED") && (
                  <button
                    type="button"
                    className="btn btn-default border"
                    onClick={requestDeactivationApiCall}
                  >
                    Request to Deactivate
                  </button>
                )}

              {isrDtls.active && status === "DEACTIVATION APPROVED" && (
                <button
                  type="button"
                  className="btn btn-danger border"
                  onClick={deActivateIsrApiCall}
                >
                  Deactivate
                </button>
              )}

              {/* <button
                type="button"
                className="btn btn-default border"
                onClick={deActivateIsrApiCall}
                id="deactivate-btn"
              >
                Deactivate
              </button>

              {(!isrDtls.active || costumerList.length !== 0) && (
                <Tooltip
                  anchorId="deactivate-btn"
                  content="ISR cannot be deactivated if they are already inactive or they have customers assigned to them"
                  place="top"
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <CustomAlert
          toggleReset={toggleReset}
          alertTitle="Deactivate ISR"
          alertBody="There are customers assigned to this ISR.\nAre you sure you want to deactivate?"
          callback={deActivateIsrApiCall}
        />
      )}
      <Spinner show={show} />
    </div>
  );
};

export default IsrActivationDetails;

import React from 'react'
import GetSvgIcon from '../../images/svglist'
import { useNavigate } from 'react-router-dom'

export default function Unauthorized() {
  const navigate = useNavigate()

  const back = () => {
    navigate(-1)
  }
  return (
    <div className='vh-100 d-flex'>
      <div className='w-auto m-auto text-center border border-3 border-danger rounded-3 alert alert-danger p-5 align-self-center'>
        <h1 className='text-danger'>{GetSvgIcon("NotFound")} <br />
          <span className='text-dark'>Unauthorized
          </span></h1>
        <button type='button' className='mt-3  btn btn-outline-secondary theme-violet' onClick={back}>back</button>
      </div>
    </div>

  )
}

import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import RateChatList from "./RateChatList";
import "./RateChartStyle.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SERVER_ERR_MSG, DATE_FORMAT_CLIENT
} from "../../Helper/Constants";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import { Alert } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import CostumeModalConfirmAlert from "../../Layout/CostumeModalConfirmAlert";
import { intiRateChat } from "../LhService/initRateCode";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const RateChat = () => {
  const [show, setShow] = useState(false);
  const [rateChatDtls, setRateChatDtls] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedRate, setRateCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [confirmAlertConfig, setConfirmAlertConfig] = useState(initAlertConfig);
  const [selectedToDate, setSelectedToDate] = useState();
  const [selectedRateId, setSelectedRateId] = useState();
  const [selectedSlaRateCode, setSelectedSlaRateCode] = useState();
  const [conformCheckbox, setConformCheckbox] = useState(false);
  const modalRef = useRef();
  const [rateChatData, setRateChatData] = useState(intiRateChat);
  const [rateChatList, setRateChatList] = useState([]);

  const rateCodeColumns = [
    {
      name: "From Hub",
      selector: (row) => row.originSbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "To Hub",
      selector: (row) => row.destSbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Price per Pallet",
      selector: (row) => row.palletPrice,
      sortable: true,
    },
    {
      name: "Price per Trip",
      selector: (row) => row.tripPrice,
      sortable: true,
    },
    {
      name: "Price per Month",
      selector: (row) => row.monthlyPrice,
      sortable: true,
    },
    {
      name: "Price per Extra KG",
      selector: (row) => row.overweightPrice,
      sortable: true,
    },
  ];
  useEffect(() => {
    getAllRateChat();
  }, []);

  const getAllRateChat = async () => {
    setShow(true);
    rateChatData["ratechartId"] = searchParams.get("slaId");
    const data = await CallApiPost("ListRates", rateChatData);
    ;
    switch (data.respCode) {
      case 200:
      case 0:
        const rtcdOpts = data.respData;
        if (rtcdOpts.length > 0) {
          setRateChatList(rtcdOpts);
        }
        break;
      default:
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
    }
    setShow(false);
  };

  const selectRate = async (item) => {
    setShow(true);
    setSelectedSlaRateCode(item);
    const uri = "getRateChatDtls?code=" + item;
    const data = await CallApiGet(uri);
    switch (data.respCode) {
      case 200:
      case 0:
        setRateChatDtls(data.respData);
        break;
      default:
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
    }
    setShow(false);
  };

  const handleSelect = (opt) => {
    if (opt === null) {
      setRateCode("");
    } else {
      setRateCode(opt);
      selectRate(opt.value);
    }
  };

  const callback = () => {
    getAllRateChat();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const hideConfirmAlert = () => {
    setConfirmAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const openConfirmAlert = (row) => {
    setSelectedRateId(row.id);
    setSelectedToDate(row.validTo);
    setConfirmAlertConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Deactivate Rate",
      description: "Deactivate Rate",
    });
  };

  const confirmAlert = async () => {
    if (conformCheckbox) {
      let resp = await CallApiGet(
        "deactivateRate?id=" + selectedRateId + "&toDate=" + selectedToDate
      );

      if (resp.respCode === 200) {
        selectRate(selectedSlaRateCode);
        hideConfirmAlert();
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <button
                data-bs-toggle="modal"
                data-bs-target="#success-modal"
                id="modalPopup"
                className="d-none"
                ref={modalRef}
              />
              <div className="float-start">
                <div className="section-title mt-1">
                  Rate Chart For {searchParams.get("rateCode")}
                </div>
              </div>
              <div className="float-end">
                <button
                  type="button"
                  className="btn btn-default border"
                  onClick={() => navigate(-1)}
                >
                  BACK
                </button>
              </div>
            </div>
            {showAlert && (
              <div className="mt-2">
                <Alert key={alertMsg} variant={alertVariant}>
                  {alertMsg}
                </Alert>
              </div>
            )}
            <RateChatList rateList={rateChatList} columns={rateCodeColumns} />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <CostumeModalConfirmAlert
        confirmAlertConfig={confirmAlertConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={confirmAlert}
      >
        <div className="">
          <div>
            <div className="row">
              <div className="col">Valid To Date</div>
              <div className="col">
                <ReactDatePicker
                  placeholderText="Select date"
                  className="form-control"
                  dateFormat={DATE_FORMAT_CLIENT}
                  minDate={new Date()}
                  selected={
                    selectedToDate ? new Date(selectedToDate) : selectedToDate
                  }
                  onChange={(d) =>
                    setSelectedToDate(
                      d !== null ? moment(d).format("YYYY-MM-DD") : ""
                    )
                  }
                  showYearDropdown
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col">
                <input
                  type="checkbox"
                  className="form-check-input ms-2"
                  value="accept"
                  id="flexCheckDefault"
                  onChange={(e) => setConformCheckbox(e.target.checked)}
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="flexCheckDefault"
                  style={{ fontSize: "smaller" }}
                >
                  Are you certain about your intention to disable the rate code?
                </label>
              </div>
            </div>
          </div>
        </div>
      </CostumeModalConfirmAlert>
      <Spinner show={show} />
    </>
  );
};

export default RateChat;
